<template>
  <div>
    <h4>I want to receive email:</h4>
    <div class="row">
      <div class="col-md-8">
        <label class="hover mb-2 p-3"><input type="checkbox" class="hover" @change="changeSelectAll" /> Select
          All</label>
        <div class="card mb-2" v-for="(l,i) in settingList" :key=i>
            <div class="card-body">
              <h6 class="card-title">{{l.group}}</h6>
              <div v-for="(s,q) in l.notifications" :key=q>
                <label class="hover" >
                <input type="checkbox" class="hover" v-model="s.toReceiveEmail" />
                {{s.purpose}}
              </label>
              </div>
              
            </div>
        </div>

        <button class="btn btn-primary float-end" @click="saveSetting">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>

import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'UserNotificationSetting',
  data() {
    return {
      settingList: []
    }
  },

  beforeMount() {
  },

  destroyed() {
    this.clearBreadcrumb();
  },

  mounted() {
    this.setBreadcrumb([
      { name: 'Notification Setting' }
    ]);
    this.loadSettings();
  },

  methods: {
    loadSettings() {
      this.settingList = [];
      this.showLoadingScreen();
      api.regularGet(apiUrl.notification.getUserNotificationSettings, null).then((res) => {
        this.hideLoadingScreen();
        this.settingList = res.data;
        console.log(this.settingList);
      });
    },
    saveSetting() {
      api.regularPost(apiUrl.notification.updateNotificationSetting, this.settingList).then((res) => {
        if (res.status == 200) {
          this.showMessage("Successfully saved.", "success");
        }
        else if (res.status == 400 || res.errorText.response.status == 400) {
          this.showMessage(res.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    changeSelectAll(e) {
      if (e.target.checked) {
        this.settingList.forEach(x => x.notifications.forEach(y => 
          y.toReceiveEmail = true
        ));
      }
      else {
        this.settingList.forEach(x => x.notifications.forEach(y => 
          y.toReceiveEmail = false
        ));
      }
    }

  }
}
</script>
