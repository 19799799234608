import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const firstBreadcrumbItem = { name: 'Dashboard', url: '/dashboard' };

const store = new Vuex.Store({
  //plugins: [createPersistedState()],
  state: {
    loginUser: {},
    confirmDiaglog: {
      show: false,
      message: '',
      submitFunction: null
    },
    columnSortDir: '',
    columnSortGroup: '',
    breadcrumb: [firstBreadcrumbItem],
    loadingstatus: 'hide',
    unreadNotiCount: '',
    unreadNotiList: []
  },
  mutations: {
    toggleConfirmDiaglog(state, payload) {
      state.confirmDiaglog.show = payload.show;
      state.confirmDiaglog.message = payload.message;
      state.confirmDiaglog.submitFunction = payload.submitFunction;
    },
    resetSortDir(state, columnGroup) {
      state.columnSortDir = Math.random();
      state.columnSortGroup = columnGroup;
    },
    setBreadcrumb(state, breadcrumb) {
      state.breadcrumb = [firstBreadcrumbItem, ...breadcrumb];
    },
    clearBreadcrumb(state) {
      state.breadcrumb = [firstBreadcrumbItem];
    },
    changeLoadingStatus(state, status) {
      state.loadingstatus = status;
    },
  }
})

export default store;