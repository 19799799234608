<template>
  <v-date-picker
    v-model="range"
    :popover="{ visibility: readOnly ? 'hidden' : 'click' }"
    :masks="{ input: ['DD/MM/YYYY'] }"
    is-range
  >
    <template v-slot="{ inputValue, inputEvents, togglePopover }">
      <div class="d-flex flex-row">
        <div class="input-group" style="width: 150px;min-width: 150px;">
          <input
            :value="inputValue.start"
            v-on="inputEvents.start"
            class="form-control"
            
          />
          <span class="input-group-text hover" @click="togglePopover({ placement: 'bottom', transition: 'none', showDelay: 0, hideDelay: 0 })"><i class="bi bi-calendar"></i></span>
        </div>

        <div class="pt-1 ms-2 me-2" style="width:20px;">
          To
        </div>

        <div class="">
          <div class="input-group" style="width: 150px;min-width: 150px;">
            <input
              :value="inputValue.end"
              v-on="inputEvents.end"
              class="form-control"
            />
            <span class="input-group-text hover" @click="togglePopover({ placement: 'bottom', transition: 'none', showDelay: 0, hideDelay: 0 })">
              <i class="bi bi-calendar"></i>
            </span>
          </div>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  name: "DateRangePicker",
  props: {
    existing: Object,
    defaultSelect: String,
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      readOnly: false,
      selectedDate: null
    };
  },
  watch: {
    existing: {
      immediate: true,
      handler(value) {
        if (this.value)
          this.range = value;
      }
    },
    range: {
      immediate: true,
      handler(value) {
        //value.start = moment(value.start).format('DD/MM/YYYY');
        //value.end = moment(value.end).format('DD/MM/YYYY');
        if (value)
          this.$emit("updatedaterange", value);
      },
    },
  },
  beforeMount() {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(firstDay);
    lastDay.setMonth(lastDay.getMonth() + 1);
    lastDay.setDate(lastDay.getDate() - 1);

    if (this.defaultSelect == 'thisMonth') {
      this.range.start = firstDay;
      this.range.end = lastDay;
    }
    else if (this.defaultSelect == 'prevMonth') {
      let firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      let lastDay = new Date(firstDay);
      lastDay.setMonth(lastDay.getMonth() + 1);
      lastDay.setDate(lastDay.getDate() - 1);
      this.range.start = firstDay;
      this.range.end = lastDay;
    }
    
  },
  mounted() {
    this.loadDate();
  },
  methods: {
    loadDate() {
      if (this.existing) {
        this.range = this.existing;
        console.log('A');
      }
      if (!this.existing && !this.defaultSelect) {
        this.range.start = null;
        this.range.end = null;
        console.log('B');
      }
    }
  },
};
</script>
