<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Invoice Date
              </div>
              <div class="col-sm-8">
                <DateRangePicker @updatedaterange="dateRangeChangeInvoice"></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb-1">
      <div class="card-body">
         <div style="overflow-x: auto;">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th>Invoice Date</th>
              <th style="text-align: right;">Revenue</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in param.listData" :key="idx">
              <td>{{i.invoiceDate}}</td>
              <td style="text-align: right;">$ {{numberWithCommas(Number(i.revenueAmount).toFixed(4))}}</td>
            </tr>
          </tbody>
        </table>
         </div>
        
      </div>
    </div>

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'InvoiceList',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      param:{
          listData:[]
      }
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Revenue' }
    ]);
    this.param.invoice_Start = null;
    this.param.invoice_End = null;
    this.callTable();
  },
  methods: {
    numberWithCommas(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    dateRangeChangeInvoice(dateRange) {
      this.param.invoice_Start = this.convertDateObjToDateStr(dateRange.start);
      this.param.invoice_End = this.convertDateObjToDateStr(dateRange.end);
      this.callTable();
    },
    callTable() {
      this.showLoadingScreen();
      this.param.listData = [];
      api.regularPost(apiUrl.invoice.revenueReport, this.param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          console.log(response);
          this.param.listData = response.data;
        }
      });
    },
    
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.invoice.revenueDownload,this.param,"revenue.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
  }
}
</script>
