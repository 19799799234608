<template>
  <div>
    <h4>Radiography Test Report Entry</h4>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Performance Date<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <DatePicker :availableDates="availableDates" :cssClass="error.assignDateFrom && 'control-error'" @updatedate="changePerformanceDate" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Report Type<span class="text-danger">*</span> <i class="bi bi-info-circle text-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Click here for more info" @click="showTooltip"></i>
      </div>
      <div class="col-md-3">
         <input name="reportType" @change="checkReportType" type="radio" v-model="dataObj.reportType" value="PerformanceWorksite"> <span>Performance Update <br/> &nbsp;&nbsp; (Worksite)</span> 
      </div>
      <div class="col-md-3">
         <input name="reportType" @change="checkReportType" type="radio" v-model="dataObj.reportType" value="PerformanceWorkshopContractorTestCentre"><span>Performance Update <br/> &nbsp;&nbsp; (Workshop at Contractor Or TestCentre)</span> 
      </div>
      <div class="col-md-3">
         <input name="reportType" @change="checkReportType" type="radio" v-model="dataObj.reportType" value="SwsWelderQualifyingTest"> <span>SWS-WQT <br/> &nbsp;&nbsp; (Welder Qualifying Test)</span> 
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Industry<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="industries" value="oid" text="corpName" :existing="dataObj.industryOid" @change="changeIndustry" :cssClass="error.industryOid && 'control-error'" />
      </div>
      <div v-if="isShowOtherIndustry" class="col-md-3">
        <input placeholder="Enter Industry Name" type="text" v-model="dataObj.otherIndustry" class="form-control" :class="error.otherIndustry && 'control-error'" />
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Job Category<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="jobCategories" value="value" text="text" @change="dataObj.jobCategory=$event" :cssClass="error.jobCategory && 'control-error'" />
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Location<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="locatoins" value="oid" text="name" @change="dataObj.siteOid=$event" :cssClass="error.siteOid && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contractor<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="contractors" value="oid" text="corpName"  @change="dataObj.contractorOid=$event" :cssClass="error.contractorOid && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Centre<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" :existing="dataObj.testCentreOid" @change="changeTestCentre" :cssClass="error.testCentreOid && 'control-error'" />
      </div>
      <div v-if="isShowOtherTestCenter" class="col-md-3">
        <input placeholder="Enter Test Centre Name" type="text" v-model="dataObj.otherTestCentre" class="form-control" :class="error.otherTestCenter && 'control-error'" />
      </div>
    </div>
    <hr/>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Report No.<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.reportNo" class="form-control" :class="error.reportNo && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Code
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.code" class="form-control" :class="error.code && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Sensitivity
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.sensitivity" class="form-control" :class="error.sensitivity && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        I.Q.I
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.iqi" class="form-control" :class="error.iqi && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Source
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.source" class="form-control" :class="error.source && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Isotope/KV
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.isotape" class="form-control" :class="error.isotape && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Curies/MA
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.curies" class="form-control" :class="error.curies && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Film
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.film" class="form-control" :class="error.film && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Screen
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.screen" class="form-control" :class="error.screen && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Density
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.density" class="form-control" :class="error.density && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Developing
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.developing" class="form-control" :class="error.developing && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Exposure
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.exposure" class="form-control" :class="error.exposure && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Technique
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.technique" class="form-control" :class="error.technique && 'control-error'" />
      </div>
    </div>
    <hr/>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Radiographer<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.radiographer" class="form-control" :class="error.radiographer && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Interpreter<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.interpreter" class="form-control" :class="error.interpreter && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Q.C. Inspector
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.inspector" class="form-control" :class="error.inspector && 'control-error'" />
      </div>
    </div>
    <div class="mt-4">
      <div class="float-start">
        <div><span class="text-danger">* Denotes Mandatory Field</span></div>
        <div><span class="text-danger">Performance date must be within assignment period (for performance update)</span></div>
        <div><span class="text-danger">RT date must be the same as Test date (for welding test)</span></div>
      </div>
      <div>
        <button class="float-end btn btn-success" @click="clickSave">Save And Add Welder</button>
      </div>
    </div>
    <ModalDiaglog 
              :show="IsShowTooltip" :onClose="()=>{IsShowTooltip = false;}" :title="'Remarks'" :showSubmitBtn1="false"
              >
                <div class="text-primary">
                    <p>
                      Performance update (Worksite) – RT taken for the Production weld at the site for PU.<br/><br/>
                      Performance update (Workshop at contractor/test center) – Welder is sent back to the workshop for PU, RT taken at the workshop is used for PU.<br/><br/>
                      SWS-WQT – Welder Qualifying Test – RT taken for a welding test.<br/><br/>
                    </p>
                </div>
    </ModalDiaglog>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Pickers from '@/components/custom/pickers';
import * as Forms from '@/components/custom/forms';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
export default {
  name: 'AddRTReport',
  components: {
    ...Forms,
    ...Pickers,
    ModalDiaglog,
  },
  data() {
    return {
      IsShowTooltip:false,
      dataObj: {},
      error: {},
      availableDates: {},

      industries: [],
      jobCategories: [],
      locatoins: [],
      contractors: [],
      testCentres: [],
      isShowOtherIndustry:false,
      isShowOtherTestCenter:false,
      otherIndustry:'',
      otherTestCentre:'',
      isPerformanceIndustry:true
    }
  },
  beforeMount(){
    this.availableDates = { end: new Date() };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Performance Welder Report', url: '/rtreportlist' }, { name: 'Add New' }
    ]);

    this.jobCategories = [
      { value: this.$data._jobCategory.all.value, text: this.$data._jobCategory.all.text },
      { value: this.$data._jobCategory.construction.value, text: this.$data._jobCategory.construction.text },
      { value: this.$data._jobCategory.maintenance.value, text: this.$data._jobCategory.maintenance.text },
      { value: this.$data._jobCategory.shutdown.value, text: this.$data._jobCategory.shutdown.text },
    ];
    let currentUser = this.getLoggedInUser();
    api.getAllCorporates(null).then(response => {
      //if welding inspector, show only their own corporate
      if(currentUser.userGroupType == this.$data._userGroupType.weldingInsperctor.value){
        if(currentUser.wiType == this.$data._corporateType.industry.value){
          this.industries = response.data.filter(x => x.oid == currentUser.corporateOid &&  x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
          this.dataObj.industryOid = currentUser.corporateOid;

          this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
          this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
          this.testCentres.push({"oid":0,"corpName":'Other'});
        }else if (currentUser.wiType == this.$data._corporateType.testCenter.value){
          this.testCentres = response.data.filter(x => x.oid == currentUser.corporateOid && x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
          this.dataObj.testCentreOid = this.getLoggedInUser().corporateOid;

          this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
          this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
           this.industries.push({"oid":0,"corpName":'Other'});
        }
      }else{
         this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
         this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
         this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
      }
    });

   
    api.regularGet(apiUrl.site.getList).then(res => {
      this.locatoins = res.data;
    });
    this.dataObj.reportType = this.$data._rtReportType.performanceWorksite.value;

    if(this.getLoggedInUser().userGroupType == this.$data._userGroupType.testCenter.value){
      this.dataObj.testCentreOid = this.getLoggedInUser().corporateOid;
    }
  },
  methods: {
    showTooltip(){
      this.IsShowTooltip = true;
    },
    checkReportType(){
      if(this.dataObj.reportType != this.$data._rtReportType.performanceWorksite.value){
        this.isPerformanceIndustry = false;
        this.dataObj.industryOid = 0;
        this.dataObj.jobCategory = '';
        this.dataObj.siteOid =0;
      }else{
        this.isPerformanceIndustry = true;
      }
    },
    changeIndustry(e){
      console.log(e);
      if(e == 0){
        this.isShowOtherIndustry = true;
      }else{
        this.isShowOtherIndustry = false;
      }
      this.dataObj.industryOid = e;
    },
    changeTestCentre(e){
      if(e == 0){
        this.isShowOtherTestCenter = true;
      }else{
        this.isShowOtherTestCenter = false;
      }
      this.dataObj.testCentreOid = e;
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.performanceDate) { this.error.performanceDate = true; isValid = false; }
      if (!this.dataObj.industryOid && this.dataObj.reportType == this.$data._rtReportType.performanceWorksite.value) { this.error.industryOid = true; isValid = false; }
      if (!this.dataObj.jobCategory && this.dataObj.reportType == this.$data._rtReportType.performanceWorksite.value) { this.error.jobCategory = true; isValid = false; }
      if (!this.dataObj.siteOid && this.dataObj.reportType == this.$data._rtReportType.performanceWorksite.value) { this.error.siteOid = true; isValid = false; }
      if (!this.dataObj.contractorOid) { this.error.contractorOid = true; isValid = false; }
      if (!this.dataObj.testCentreOid) { this.error.testCentreOid = true; isValid = false; }
      if (!this.dataObj.reportNo) { this.error.reportNo = true; isValid = false; }
      if (!this.dataObj.radiographer) { this.error.radiographer = true; isValid = false; }
      if (!this.dataObj.interpreter) { this.error.interpreter = true; isValid = false; }

      this.$forceUpdate();
      if (!isValid) {
        this.showMessage("Please input mandatory fields.", "warning");
      }
      return isValid;
    },
    clickSave() {
      if (this.validate()) {
        this.showLoadingScreen();
        api.regularPost(apiUrl.performance.insertRTReport, this.dataObj).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.showMessage("Successfully Submitted.", "success");
            this.$router.push({ name: "EditRTReport", params: { id: response.data.data.oid } });
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });
      }
    },
    changePerformanceDate(date) {
      this.dataObj.performanceDate = this.convertDateObjToDateStr(date);
    }
  }
}
</script>
