<template>
  <div>
      
      <div class="card">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-6">
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Corporate:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.corporateName}}
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Name:
                          </div>
                          <div class="col-sm-6">
                              <input type="text" class="form-control" v-model="dataObj.name"
                                  :class="error.name && 'control-error'" maxlength="500" />
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              NRIC:
                          </div>
                          <div class="col-sm-6">
                              <input type="text" class="form-control" maxlength="9" v-model="dataObj.nricFin"
                                  :class="error.nricFin && 'control-error'" />
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Work Permit:
                          </div>
                          <div class="col-sm-6">
                              <input type="text" class="form-control" maxlength="12" v-model="dataObj.workPermit"
                                  :class="error.workPermit && 'control-error'" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Work Permit Valid From Date:
                          </div>
                          <div class="col-sm-6">
                              <DatePicker @updatedate="changeWorkPermitIssueDate"
                                  :cssClass="error.workPermitIssueDate && 'control-error'"
                                  :existing="workPermitIssueDate" :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Work Permit Expiry Date
                          </div>
                          <div class="col-sm-6">
                              <DatePicker @updatedate="changeWorkPermitExpiryDate" :availableDates="expAvailableDates"
                                  :cssClass="error.workPermitExpiryDate && 'control-error'"
                                  :existing="workPermitExpiryDate" :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Date of Birth<span class="text-danger">*</span>:
                          </div>
                          <div class="col-sm-6">
                              <DatePicker @updatedate="changeDob" :cssClass="error.dob && 'control-error'"
                                  :existing="dob" :disabled="!hasPermission" />
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Occupation<span class="text-danger">*</span>:
                          </div>
                          <div class="col-sm-6">
                              <FormSelect :cssClass="error.gender && 'control-error'" :list="occupations" value="oid"
                                  text="value" :existing="dataObj.occupationOid" @change="dataObj.occupationOid=$event"
                                  :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Gender<span class="text-danger">*</span>:
                          </div>
                          <div class="col-sm-6">
                              <FormSelect :cssClass="error.gender && 'control-error'" :list="genders" value="oid"
                                  text="value" :existing="dataObj.gender" @change="dataObj.gender=$event"
                                  :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Nationality<span class="text-danger">*</span>:
                          </div>
                          <div class="col-sm-6">
                              <FormSelect :cssClass="error.nationalityOid && 'control-error'" :list="nationalities"
                                  value="oid" text="value" :existing="dataObj.nationalityOid"
                                  @change="dataObj.nationalityOid=$event" :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Citizenship<span class="text-danger">*</span>:
                          </div>
                          <div class="col-sm-6">
                              <FormSelect :cssClass="error.citizenshipOid && 'control-error'" :list="citizenships"
                                  value="oid" text="value" :existing="dataObj.citizenshipOid"
                                  @change="dataObj.citizenshipOid=$event" :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Education<span class="text-danger">*</span>:
                          </div>
                          <div class="col-sm-6">
                              <FormSelect :cssClass="error.educationOid && 'control-error'" :list="educationLevels"
                                  value="oid" text="value" :existing="dataObj.educationOid"
                                  @change="dataObj.educationOid=$event" :disabled="!hasPermission" />
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Commencement Date<span class="text-danger">*</span>:
                              
                          </div>
                          <div class="col-sm-6">
                              <DatePicker @updatedate="changeCommenceDate"
                                  :cssClass="error.commenceDate && 'control-error'" :existing="commenceDate"
                                  :disabled="!hasPermission" />
                          </div>
                      </div>
                  </div>
              </div>

              <button class="btn btn-secondary m-1 float-end" @click="clickCancel">
                  Cancel
              </button>
              <button class="btn btn-success m-1 float-end" @click="clickSave">
                  Save
              </button>
              <!-- <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">
                  Delete
              </button> -->
          </div>
      </div>
  </div>
</template>

<script>
import apiUrl from "@/common/apiurl";
import api from "@/common/api";
import * as Forms from '@/components/custom/forms';
import perCode from '@/common/permissionCodes';
import * as Pickers from '@/components/custom/pickers';
export default {
  name: "AddEditUser",

  components: {
    ...Forms,
    ...Pickers,
  },

  props: {},

  data() {
    return {
      oid: 0,
      isNew: true,
      corpName: '',
      nricFin: '',
      workPermitNo: '',

      dob: null,
      workPermitIssueDate: null,
      workPermitExpiryDate: null,
      commenceDate: null,
      terminatedDate: null,

      error: {},
      dataObj: {},

      genders: [],
      nationalities: [],
      citizenships: [],
      occupations: [],
      educationLevels: [],

      saveBtnText: '',

      hasPermission: false,

      currentTab: 'details',
      employmentHistory: [],

      isExistingIsAlreadyTerminated: false,

      welderSkillOid: 0,
      expAvailableDates: {},
      terminateAvailableDates: {},
      disableWelderId: true,

      worksiteSummary: [],
      workshopSummary: [],

      isAuthorizedPage: true,
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
      this.saveBtnText = 'Update Staff';

      //Check Permisstion
      if(!this.isAuthorizedPermission(perCode.manageStaff)){
        this.showMessage("Forbidden!!!", "error");
        this.isAuthorizedPage = false;
      }

    }
    else {
      this.saveBtnText = 'Add Staff';
    }
    this.corpName = this.$route.params.corpName;
    this.dataObj.nricFin = this.$route.params.nricFin;
    this.dataObj.workPermit = this.$route.params.workPermitNo;
    if (this.$route.query.at) {
      this.isExistingIsAlreadyTerminated = this.$route.query.at;
    }

    this.expAvailableDates = { start: new Date() };
    this.terminateAvailableDates = { start: new Date() };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = this.isNew ? 'New Staff' : 'Edit Staff';
    this.setBreadcrumb([
      { name: 'Staff', url: '/staff' }, { name: pageName }
    ]);

    this.genders = [{ oid: 'M', value: 'Male' }, { oid: 'F', value: 'Female' }];
    api.getSettingsByGroup(this.$data._settingGroup.country).then((response) => {
      this.nationalities = response.data;
    });
    api.getSettingsByGroup(this.$data._settingGroup.citizenship).then((response) => {
      this.citizenships = response.data;
    });
    api.getSettingsByGroup(this.$data._settingGroup.staffOccupation).then((response) => {
      response.data.forEach(x => this.occupations.push({ oid: x.oid, value: x.value, selected: false }));
    });
    api.getSettingsByGroup(this.$data._settingGroup.educationLevel).then((response) => {
      this.educationLevels = response.data;
      if (this.oid > 0 && !this.dataObj.oid) {
        this.getData();
      }
    });
    this.hasPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageStaff);
  },

  methods: {
      validate() {
      let isValid = true;
      var msg = '';
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.name) { this.error.name = true; isValid = false; }
      if (!this.dataObj.dob) { this.error.dob = true; isValid = false; }
      if (!this.dataObj.gender) { this.error.gender = true; isValid = false; }
      if (!this.dataObj.nationalityOid) { this.error.nationalityOid = true; isValid = false; }
      if (!this.dataObj.citizenshipOid) { this.error.citizenshipOid = true; isValid = false; }
      if (!this.dataObj.educationOid) { this.error.educationOid = true; isValid = false; }
      if (!this.dataObj.commenceDate) { this.error.commenceDate = true; isValid = false; }

      if (this.dataObj.workPermit || this.dataObj.workPermitIssueDate || this.dataObj.workPermitExpiryDate) {
       
        if (!this.dataObj.workPermit) {
          this.error.workPermit = true;
          isValid = false;
        }
        if (!this.dataObj.workPermitIssueDate) {
          this.error.workPermitIssueDate = true;
          isValid = false;
        }
        if (!this.dataObj.workPermitExpiryDate) {
          
          this.error.workPermitExpiryDate = true;
          isValid = false;
        }
      }

      this.$forceUpdate();

      if (!isValid) {
        if (!msg) {
          msg = "Please input valid fields.";
        }

        this.showMessage(msg, "warning");
      }
      return isValid;
    },
    changeDob(date) {
      this.dataObj.dobDisplay = this.convertDateObjToDateStr(date);
    },
    changeWorkPermitIssueDate(date) {
      this.dataObj.workPermitIssueDateDisplay = this.convertDateObjToDateStr(date);
    },
    changeWorkPermitExpiryDate(date) {
      this.dataObj.workPermitExpiryDateDisplay = this.convertDateObjToDateStr(date);
    },
    changeCommenceDate(date) {
      this.dataObj.commenceDateDisplay = this.convertDateObjToDateStr(date);
    },
    getData() {
      let url = apiUrl.worker.getByOidStaff + "/" + this.oid;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        console.log(this.dataObj.gender);
        if (this.dataObj.dob)
          this.dob = this.convertDateStrToDateObj(this.dataObj.dob);
        if (this.dataObj.workPermitIssueDate){
          this.workPermitIssueDate = this.convertDateStrToDateObj(this.dataObj.workPermitIssueDate);
        }
        if (this.dataObj.commenceDate)
          this.commenceDate = this.convertDateStrToDateObj(this.dataObj.commenceDate);
        if (this.dataObj.workPermitExpiryDate){
          this.workPermitExpiryDate = this.convertDateStrToDateObj(this.dataObj.workPermitExpiryDate);
        }
      });
    },
    clickSave() {
        if (!this.validate())
        return;

      this.showLoadingScreen();
      let url = '';
      if (this.isNew) {
        url = apiUrl.worker.insert;
      }
      else {
        this.dataObj.isExistingIsAlreadyTerminated = this.isExistingIsAlreadyTerminated;
        url = apiUrl.worker.updateStaff;
      }
      this.dataObj.raceOid = 0;
      console.log(this.dataObj);
      api.regularPost(url, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "StaffList" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickCancel() {
      this.$router.push({ name: "ManageUsers" });
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    confirmRemove() {
      let param = {
        oid: this.dataObj.oid
      };
      this.showConfirmDiaglog({
        show: true,
        message: "Are you sure to remove " + this.dataObj.userName + "?",
        submitFunction: () => {
          api
            .regularPost(
              apiUrl.users.delete,
              param,
              this.$store.state.token
            )
            .then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "ManageUsers" });
              } else if (
                response.status == 400 ||
                response.errorText.response.status == 400
              ) {
                this.showMessage(
                  response.errorText.response.data.message,
                  "error"
                );
              } else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
        },
      });
    },
    showAudit() {
      this.showAuditDialog(true);
    }

  },
};
</script>
