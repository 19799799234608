<template>
  <div>

    <button v-show="false" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirmDialog" ref="myShowBtn">
      Launch demo modal
    </button>

    <div class="modal fade" id="confirmDialog" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirmation</h5>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
          </div>
          <div class="modal-body">
            <span v-html="$store.state.confirmDiaglog.message"></span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal" ref="myCloseBtn">Cancel</button>
            <button type="button" class="btn btn-primary" @click="submit">Yes</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ConfirmDiaglog',
  props: {
    showDiaglog: Boolean
  },
  watch: {
    showDiaglog(newVal) {
      if (newVal === true) {
        this.showModal();
      }
    }
  },
  methods: {
    showModal() {
      this.$refs.myShowBtn.click();
    },
    closeModal() {
      this.$store.commit('toggleConfirmDiaglog', { show: false, message: '', submitFunction: null });
    },
    submit() {
      this.$store.state.confirmDiaglog.submitFunction();
      this.$refs.myCloseBtn.click();
    }
  }
}
</script>