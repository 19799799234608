<template>
  <div>
    <div class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-9">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                ID:
              </div>
              <div class="col-sm-8">
                {{dataObj.oid}}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Purpose:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8 text-secondary">
                <input type="text" class="form-control" :class="error.purpose && 'control-error'" v-model="dataObj.purpose" />
              </div>
            </div>
           
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Subject:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control" :class="error.subjectTemplate && 'control-error'" v-model="dataObj.subjectTemplate" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Content:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <div>
                  <quillEditor
                  v-model="dataObj.messageTemplate"
                  ref="myQuillEditor"
                  :options="editorOption"
                  >
                  </quillEditor>
                </div>
                <!-- <div>
                  <textarea class="form-control" :class="error.subjectTemplate && 'control-error'" v-model="dataObj.messageTemplate" style="height:350px;">
                  </textarea>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="text-danger small">* Denotes Mandatory Field</span>
        </div>
        <div>
          <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
          <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        </div>
        
        
      </div>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Notification" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      

  </div>
</template>

<script>
// import 'quill/dist/quill.snow.css';
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'EditNotificationTemplate',

  components: {
    AuditLog,
    ModalDiaglog,
    quillEditor
  },

  props: {

  },

  data() {
    return {
      basicLogInfoUrl: apiUrl.notification.getBasicLogInfo,
      oid: 0,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      showAuditModal: false,
      expiryDate: null,
      terminateDate: null,
      hasPermission: false,
      statuses: [],
      error: {},

      editorOption: {
        // debug: 'info',
        // placeholder: 'Type your post...',
        // readOnly: true,
        // theme: 'snow'
        // modules: {
        //   toolbar: [
        //     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //     ['blockquote', 'code-block'],

        //     [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        //     [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        //     [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        //     [{ 'direction': 'rtl' }],                         // text direction

        //     [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //     [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //     [{ 'font': [] }],
        //     [{ 'align': [] }],

        //     ['clean']                                         // remove formatting button
        //   ]
        // }
      }
      
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  mounted() {
    let pageName = 'Edit Notification Template';
    this.setBreadcrumb([
      { name: 'Notification Templates', url: '/notificationtemplates' }, { name: pageName }
    ]);

    if (this.oid > 0) {
      this.getData();
    }

  },

  methods: {
    getData() {
      let url = apiUrl.notification.getNotificationTemplateDetails + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
      });
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = apiUrl.notification.updateNotificationTemplate;

      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "NotificationTemplates" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "NotificationTemplates" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.purpose) { this.error.purpose = true; isValid = false; }
      if (!this.dataObj.subjectTemplate) { this.error.subjectTemplate = true; isValid = false; }
      if (!this.dataObj.messageTemplate) { this.error.messageTemplate = true; isValid = false; }
      this.$forceUpdate();
      return isValid;
    },
    showAudit() {
      this.showAuditDialog(true);
    },
  }
}
</script>
