<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterName" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                SWS
              </div>
              <div class="col-sm-6">
                <v-select label="company" v-model="selectedSws" :options="swsMembers" @input="changeFilterSws"></v-select>
                <!-- <FormSelect :list="swsMembers" :existing="tableParam.swsOid" value="oid" text="company" @change="changeFilterSws" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="corpName" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="address1" colGroup="tbl1">Address</SortColumn>
          <SortColumn @click="sortColumn" sortBy="swsName" colGroup="tbl1">SWS ID</SortColumn>
          <SortColumn @click="sortColumn" sortBy="adminContact" colGroup="tbl1">Administrator Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="adminDesignation" colGroup="tbl1">Designation</SortColumn>
          <SortColumn @click="sortColumn" sortBy="telephone" colGroup="tbl1">Telephone</SortColumn>
          <SortColumn @click="sortColumn" sortBy="ext" colGroup="tbl1">Ext</SortColumn>
          <SortColumn @click="sortColumn" sortBy="corporateEmail" colGroup="tbl1">Email</SortColumn>
          <SortColumn @click="sortColumn" sortBy="createdDate" colGroup="tbl1">Submitted Date</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.corpName}}</td>
          <td>{{i.address1}}</td>
          <td>{{i.swsName}}</td>
          <td>{{i.adminContact}}</td>
          <td>{{i.adminDesignation}}</td>
          <td>{{i.telephone}}</td>
          <td>{{i.ext}}</td>
          <td>{{i.corporateEmail}}</td>
          <td>{{i.createdDate_String}}</td>
          <td><button class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button></td>
        </tr>
      </tbody>
    </table>
    </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import _ from "lodash";

export default {
  name: 'ActivateList',
  components: {
    ...TableAddons,
    ...Forms
  },
  props:{
    _type: String
  },
  data() {
    return {
      tableParam: this.dtoPaging(), 
      swsMembers: [],
      statuses: []
    }
  },
  beforeMount(){
    
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
     this.setBreadcrumb([
      { name: this.getCorporateTypeTextByValue(this._type) }
    ]);
    this.tableParam.type = this._type;

    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];

    api.regularGet(apiUrl.regIndustry.dropdowns, null).then((response) => {
      this.swsMembers = response.data.sws;
      this.swsMembers.unshift({ oid: 0, swsid: "All" });
    });

    this.tableParam.status = "";
    this.tableParam.swsOid = 0;

    this.callTable();
  },
  methods: {
    callTable() {
      this.tableParam.listData = [];
      this.showLoadingScreen();
      api.regularPost(apiUrl.activatecorporate.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      console.log(this._type);
      this.$router.push({ name: 'Activate Corporate', params: { id: data.oid, type: this._type } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    // changeTblFilter(text){
    //   this.tableParam.searchtext = text;
    //   this.callTable();
    // },

    changeFilterName: _.debounce(function (e) {
      this.tableParam.name = e.target.value;
      this.callTable();
    }, 500),

    changeFilterSws(e) {
      this.tableParam.swsOid = e;
      this.callTable();
    },

    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    }

  }
}
</script>
