<template>
  <div>
    <div v-if="!isNew" class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Category (max 200 characters):<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.category && 'control-error'" v-model="dataObj.category" maxlength="200"/>
              </div>
            </div>
             <div v-if="oid>0" class="row mb-3">
              <div class="col-sm-3 text-secondary">
                File Name:
              </div>
              <div class="col-sm-6">
                {{dataObj.fileName}}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                File:
              </div>
              <div class="col-sm-6">
                <button v-if="dataObj.oid != null" class="btn btn-link" title="download" @click="clickDownloadFile(dataObj.oid, dataObj.fileName)">
                  <i class="bi bi-cloud-download"></i> {{dataObj.fileName}} 
                </button>
                <file-input v-else :multiple="false"  :status="filestatus1" @selectFile="file1=$event" @done="filestatus1='stop'"/>
              </div>
            </div>
            <div v-if="oid>0" class="row mb-3">
              <div class="col-sm-3 text-secondary">
                File Path:
              </div>
              <div class="col-sm-6">
                {{dataObj.fullPath}}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">Active:</div>
              <div class="col-sm-3">
                <input type="checkbox" v-model="dataObj.status" checked/>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button>
      </div>
    </div>
    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="News Announcement" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import _ from 'lodash';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'AddEditCommonFile',
  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms,
  },

  props: {

  },

  data() {
    return {
      basicLogInfoUrl: apiUrl.roles.getBasicLogInfo,
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      alertCounter: 0,
      alertMessage: "",
      permissions: [],
      groupPermissions: {},
      showAuditModal: false,
      corporateTypes: [],
      file1: null,
      filestatus1: 'stop',

      error: {},
      editorOption: {}
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },

  mounted() {
    let pageName = this.isNew ? 'New Common File' : 'Edit Common File';
    this.setBreadcrumb([
      { name: 'Common Files', url: '/commonfiles' }, { name: pageName }
    ]);

    if (this.oid > 0) {
      this.getData();
    } 

  },

  methods: {
    getData() {
      let url = apiUrl.commonFiles.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
      });
    },
    
    getPermissions() {
      api.getAllPermissions(null, this.$store.state.token).then((response) => {
        if (response.status == 200) {
          this.permissions = response.data;
          this.permissions = this.permissions.sort(x => x.headerGroup);

          for (let i = 0; i < this.permissions.length; i++) {

            //render checked
            if (!this.isNew && this.dataObj.permissions) {
              let per = this.dataObj.permissions.find(x => x.oid == this.permissions[i].oid)
              if (per)
                this.permissions[i].checked = true;
            }
          }
          let headerList = _.uniqBy(this.permissions, "headerGroup").map(x => x.headerGroup);
          this.groupPermissions = [];
          for (let h = 0; h < headerList.length; h++) {
            let obj = {
              header: headerList[h],
              permissions: this.permissions.filter(x => x.headerGroup == headerList[h])
            };
            this.groupPermissions.push(obj);
          }
        }
      });
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.commonFiles.insert;
      }
      else {
        subUrl = apiUrl.commonFiles.update;
      }

      let formdata = new FormData();
      this.dataObj.status = this.dataObj.status == null ? true : this.dataObj.status;
      let data = { ...this.dataObj };
      if(this.file1){
        formdata.append("Category", data.category);
        formdata.append("File", this.file1);
        formdata.append("Status", data.status);
      }
      if (!this.isNew) {
        console.log("hi")
        formdata.append("Oid", this.dataObj.oid);
        formdata.append("File", this.dataObj.fileName);
        formdata.append("FullPath", this.dataObj.fullPath);
        formdata.append("Status", this.dataObj.status);
        formdata.append("Category", this.dataObj.category);
      }
      console.log(formdata)

      this.showLoadingScreen();
      api.regularPost(subUrl, formdata, "multipart/form-data").then((response) => {
      // api.regularPost(subUrl, this.dataObj, this.$store.state.token).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "ManageCommonFiles" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "ManageCommonFiles" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.category) { 
        this.error.category = true; isValid = false; 
      }

      if(this.file1 == null && this.isNew){
        this.error.file = true;
        isValid = false;
      }

      this.$forceUpdate();
      return isValid;
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.fileName + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.commonFiles.delete, this.dataObj, this.$store.state.token).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "ManageCommonFiles" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.commonfile.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    },
    clickDownloadFile(oid, fileName) {
      api.downloadFile(apiUrl.commonFiles.downloadFile, { oid }, fileName, () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
  }
}
</script>
