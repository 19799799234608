<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Welder ID:
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" :class="error.welderId && 'control-error'" v-model="welderId" />
      </div>
      <div class="col-md-3">
        <button class="btn btn-primary" @click="clickSearch">Search</button>
      </div>
    </div>
    <strong>Worksite Summary</strong>
    <div style="overflow-x: auto;">
        <table class="table table-sm mb-4">
      <thead>
        <tr>
          <th>Industry</th>
          <th>Location</th>
          <th>Contractor</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Shift</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(d,i) in worksiteSummary" :key="i">
          <td>{{d.industry}}</td>
          <td>{{d.location}}</td>
          <td>{{d.contractor}}</td>
          <td>{{d.startDate}}</td>
          <td>{{d.endDate}}</td>
          <td>
            <span v-if="d.morning">M </span>
            <span v-if="d.afternoon">A </span>
            <span v-if="d.night">N </span>
          </td>
          <td>{{d.statusDisplay}}</td>
        </tr>
      </tbody>
    </table>
    </div>
    
    <strong>Workshop Summary</strong>
    <div style="overflow-x: auto;">
        <table class="table table-sm mb-4">
      <thead>
        <tr>
          <th>Accessing Inspector</th>
          <th>Industry/Test Centre/Contractor</th>
          <th>StartDate</th>
          <th>EndDate</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(d,i) in workshopSummary" :key="i">
          <td>{{d.accessingInspector}}</td>
          <td>{{d.industry}}<span v-if="d.industry && d.contractor">/</span>{{d.contractor}}</td>
          <td>{{d.startDate}}</td>
          <td>{{d.endDate}}</td>
        </tr>
      </tbody>
    </table>
    </div>
    
    <strong>Test Sumamry</strong>
    <div style="overflow-x: auto;">
        <table class="table table-sm mb-4">
      <thead>
        <tr>
          <th>Skill</th>
          <th>Test No</th>
          <th>Test Position</th>
          <th>Description</th>
          <th>Test Date</th>
          <th>Result</th>
          <th>Expiry Date</th>
          <th>Last Perf Update</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l,i) in skillTestList" :key="i">
          <td>{{l.skill}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testPosition}}</td>
          <td>{{l.weldingProcess}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{getWorkerSkillTestResultTextByValue(l.result)}}</td>
          <td>{{l.expiryDateDisplay}}</td>
          <td>{{l.lastPerfDateDisplay}}</td>
          <td>
            <span v-if="l.status==$data._workerSkillTestStatus.active.value"
              class="badge bg-success">{{$data._workerSkillTestStatus.active.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.rejected.value"
              class="badge bg-danger">{{$data._workerSkillTestStatus.rejected.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.pending.value"
              class="badge bg-secondary">{{$data._workerSkillTestStatus.pending.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.suspended.value"
              class="badge bg-warning">{{$data._workerSkillTestStatus.suspended.text}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    
  </div>
</template>

<script>

import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'CheckWelderSummary',

  data() {
    return {
      worksiteSummary: [],
      workshopSummary: [],
      welderId: '',
      error: {},
    }
  },

  beforeMount() {
    let passedWelderId = this.$route.params.welderId;
    if (passedWelderId) {
      this.welderId = passedWelderId;
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.validate()) {

        this.worksiteSummary = [];
        this.workshopSummary = [];
        this.skillTestList = [];

        this.showLoadingScreen();
        api.regularGet(apiUrl.worker.getWorksiteSummaryWelder, { welderId: this.welderId }).then((response) => {
          this.hideLoadingScreen();
          this.worksiteSummary = response.data;
        });

        api.regularGet(apiUrl.worker.getWorkshopSummaryWelder, { welderId: this.welderId }).then((response) => {
          this.hideLoadingScreen();
          this.workshopSummary = response.data;
        });

        api.regularGet(apiUrl.worker.getWelderSkillTests, { welderId: this.welderId }).then((response) => {
          this.hideLoadingScreen();
          this.skillTestList = response.data;
          this.$forceUpdate();
        });

      }
    },

    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      if (!this.welderId) { this.error.welderId = true; isValid = false; }
      this.$forceUpdate();
      return isValid
    },

    clickSearch() {
      this.getData();
    },
  },
}
</script>
