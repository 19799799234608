<template>
  <div style="background: #f2f2f2; min-height: 100vh" id="main">
    <div class="container">
     
      <div class="row">
        <div class="offset-md-2 col-md-8">
          <div class="card" style="margin-top: 5%">
            <div class="card-body">
              <h5 class="card-title">Register as a WELDING INSPECTOR</h5>
              <div v-if="isMain">
                  <h6 class="card-subtitle mb-2 text-muted">Welder Information</h6>
              <div class="row">
                <div class="col-md-12">
                  <div v-if="paramlist.weldingInspectorList.length > 0" class="row mb-3">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8">
                      <span>Number of Welding Inspector(s) : {{paramlist.weldingInspectorList.length}}</span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Corporate Name:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <FormSelect :cssClass="error.corporateOid && 'control-error'" :disabled="paramlist.weldingInspectorList.length > 0" :list="corporates" value="oid" text="corpName" :existing="paramlist.corporateOid" @change="paramlist.corporateOid=$event"/>
                    </div>
                  </div>
                  <hr/>
                  <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Welding Inspector Name:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input :class="error.name && 'control-error'"
                        type="text"
                        class="form-control" maxlength="50"
                        v-model="dataObj.name" 
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Designation:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input :class="error.designation && 'control-error'"
                        type="text"
                        class="form-control"
                        v-model="dataObj.designation"
                        maxlength="50"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Qualification:</div>
                    <div class="col-sm-8">
                      <FormSelect :cssClass="error.qualification && 'control-error'" :list="qualifications" value="code" text="value" :existing="dataObj.qualification" @change="dataObj.qualification=$event"/>
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Certificate No.:</div>
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataObj.weldCertNo"
                        maxlength="50"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Qualification Expiry Date:</div>
                    <div class="col-sm-8">
                      <DatePicker @updatedate="changeQualificationExpiryDate"></DatePicker>
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Experience:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <textarea :class="error.experience && 'control-error'"
                      maxlength="200"
                  class="form-control"
                  rows="3"
                  v-model="dataObj.experience"
                />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Professional Membership:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input :class="error.profMember && 'control-error'"
                        type="text"
                        class="form-control"
                        v-model="dataObj.profMember"
                        maxlength="20"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Telephone No:<span class="text-danger">*</span></div>
                    <div class="col-sm-4">
                      <input :class="error.telephone && 'control-error'"
                        type="text"
                        class="form-control"
                        v-model="dataObj.telephone"
                        maxlength="8"
                      />
                      </div>
                      <div class="col-sm-1  text-secondary">Ext</div>
                      <div class="col-sm-3">
                        <input 
                        maxlength="5"
                        type="text"
                        class="form-control"
                        v-model="dataObj.ext"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Fax:</div>
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataObj.fax"
                        maxlength="8"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Email Address<span class="text-danger">*</span>:</div>
                    <div class="col-sm-8">
                      <input :class="error.email && 'control-error'"
                        type="text"
                        class="form-control"
                        maxlength="50"
                        v-model="dataObj.email"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4  text-secondary">Mobile Contact No:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input :class="error.mobileNo && 'control-error'"
                        type="text"
                        class="form-control"
                        maxlength="10"
                        v-model="dataObj.mobileNo"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                      <div class="col-sm-12">
                         <input type="checkbox" v-model="checkAgreement" @change="chkAgreeChange" /> I have read and agree with the <a href="javascript:;" @click="downloadFile()">Terms and Conditions</a>
                      </div>
                      <div class="col-sm-12">
                        <span class="text-danger">* Denotes Mandatory Field</span>
                      </div>
                  </div>
                </div>
              </div>
               <!-- <button :disabled="paramlist.weldingInspectorList.length == 0" class="btn btn-success m-1 float-end" @click="clickFinish">
                Finish
              </button> -->
              <button v-if="paramlist.weldingInspectorList.length > 0" class="btn btn-success m-1 float-end" @click="clickFinish(true)">
                Save Current Data
              </button>
               <button class="btn btn-success m-1 float-end" @click="clickFinish(false)">
                Finish
              </button>
              <button class="btn btn-secondary m-1 float-end" @click="clickNextWI">
                Next Welding Inspector
              </button>
             
              </div>
              <div v-if="!isMain">
                   <p>
                  Thank You for your application. The outcome will be emailed to your Admin Contact Person as
                  registered with PRiMOS. Any query can be directed to <a href="mailto:info@imos.com.sg">info@imos.com.sg</a>
                </p>
                <h6><i class="bi bi-app"></i> <a href="javascript:;" @click="clickWIApplicationForm()">Welding Inspector Form</a></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from "@/components/custom/forms";
import * as Pickers from "@/components/custom/pickers";
export default {
  name: "WeldingInspector",
  components: {
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      errMessage:"",
      error: {},
      isFirstWI:false,
      isMain:true,
      corporates:[],
      qualifications:[],
      checkAgreement:false,
      showModal: false,
      dataObj: {
      },
      paramlist:{
        corporateOid:0,
        weldingInspectorList:[]
      },
      oidList:[]
    };
  },
  mounted() {
    this.getDropdowns();
  },
  methods: {
    downloadFile() {
      api.downloadFile(apiUrl.corporate.downloadTAC, null, 'PRiMOS_TAC.pdf', () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    submit() {},
    modal() {
      this.showModal = true;
    },
    chkAgreeChange(e){
      this.chkAgreeChange = e;
    },
    getDropdowns() {
      let url = apiUrl.regWeldingInspector.dropdowns
      api.regularGet(url, null).then((response) => {
        let dropdowns = response.data;
        this.corporates = dropdowns.corporates;
        this.qualifications = dropdowns.qualifications;
      });
    },
    clickNextWI() {
      if (this.validate()) {
        this.clearWI();
      }else{
        
        if(this.errMessage)
            this.showMessage(this.errMessage, "warning");
        return;
      }
      
    },
    clickFinish(status){
      if(!status){
        if(this.paramlist.weldingInspectorList.length == 0){
        if (this.validate()) {
          this.clearWI();
        }else{
          if(this.errMessage)
            this.showMessage(this.errMessage, "warning");
          return;
        }
      }else{
        if(!this.validate()){
          return;
        }else{
          this.clearWI();
        }
      }
      }
      
      let subUrl = apiUrl.regWeldingInspector.insert;
      api.regularPost(subUrl, this.paramlist).then((response) => {
        if (response.status == 200) {
          this.isMain = false;
          this.showMessage("Successfully saved.", "success");
          this.oidList = response.data.data.currentWeldingInspectorList;
          window.scrollTo(0, 0);
          //this.$router.push({ name: 'CompleteRegister', query: { id: response.data.data.oid } });
        } else if (
          response.status == 400 ||
          response.errorText.response.status == 400
        ) {
          this.showMessage(response.errorText.response.data.message, "error");
        } else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickWIApplicationForm() {
           let routeData = this.$router.resolve({ name: 'WIApplicationForm', query: { oids: JSON.stringify(this.oidList) } });
           window.open(routeData.href, '_blank');
      },
    validate() {
      let isValid = true;
      this.errMessage = "";
      Object.keys(this.error).forEach(k => this.error[k] = false);
      if (!this.paramlist.corporateOid){
        this.error.corporateOid = true; 
        isValid = false;
      }
      if (!this.dataObj.name){
        this.error.name = true; 
        isValid = false;
      }
      if (!this.dataObj.designation){
        this.error.designation = true; 
        isValid = false;
      }
      if (!this.dataObj.experience){
        this.error.experience = true; 
        isValid = false;
      }
      if (!this.dataObj.profMember){
        this.error.profMember = true; 
        isValid = false;
      }
      if (!this.dataObj.telephone){
        this.error.telephone = true; 
        isValid = false;
      }
      if (!this.dataObj.email){
        this.error.email = true; 
        isValid = false;
      }
      if (!this.dataObj.mobileNo){
        this.error.mobileNo = true; 
        isValid = false;
      }

       if(!this.checkAgreement){
         this.errMessage += "Please accept Terms and Conditions";
         isValid = false;
       }
       this.$forceUpdate(); 
      return isValid;
    },
    //Clear form input for next welding inspector
    clearWI(){
      if(!this.isFirstWI){
        this.isFirstWI = true;
      }
      let wi = {
        name:this.dataObj.name,
        designation:this.dataObj.designation,
        qualification:this.dataObj.qualification,
        weldCertNo:this.dataObj.weldCertNo,
        weldCertExpiryDate:this.dataObj.weldCertExpiryDate,
        experience:this.dataObj.experience,
        profMember:this.dataObj.profMember,
        telephone:this.dataObj.telephone,
        ext:this.dataObj.ext,
        fax:this.dataObj.fax,
        email:this.dataObj.email,
        mobileNo:this.dataObj.mobileNo,
      }
      this.paramlist.weldingInspectorList.push(wi);

      this.dataObj.name = "";
      this.dataObj.designation = "";
      this.dataObj.qualification = "";
      this.dataObj.weldCertNo = "";
      this.dataObj.weldCertExpiryDate = "";
      this.dataObj.experience  = "";
      this.dataObj.profMember  = "";
      this.dataObj.telephone  = "";
      this.dataObj.ext = "";
      this.dataObj.fax = "";
      this.dataObj.email  = "";
      this.dataObj.mobileNo  = "";
    },

    changeQualificationExpiryDate(date){
      this.dataObj.weldCertExpiryDate = this.convertDateObjToDateStr(date);
    }
  },
};
</script>
