<template>
  <div>
    <h4>Endorsement History Viewing</h4>

    <div class="card">
      
      <div class="card-body">
        <div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Worker Name
            </div>
            <div class="col-md-3">
              {{dataObj.workerName}}
            </div>
            <div class="col-md-3 text-secondary">
            Contractor
            </div>
            <div class="col-md-3">
              {{dataObj.contractorName}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              NRIC/FIN
            </div>
            <div class="col-md-3">
              {{dataObj.nricFin}}
            </div>
            <div class="col-md-3 text-secondary">
              Work Permit
            </div>
            <div class="col-md-3">
               {{dataObj.workPermit}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Welder ID
            </div>
            <div class="col-md-3">
               {{dataObj.welderId}}
            </div>
            <div class="col-md-3 text-secondary">
              Qualification Expiry Date
            </div>
            <div class="col-md-3">
               {{dataObj.qualificationExpiryDate}}
            </div>
          </div>
          <h6>Test No: {{dataObj.testNumber}}</h6>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Endorsement Date
            </div>
            <div class="col-md-3">
              {{dataObj.endorsementDate}}
            </div>
             <div class="col-md-3 text-secondary">
              Endorse By
            </div>
            <div class="col-md-3">
              {{dataObj.endoseBy}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              No of Joint
            </div>
            <div class="col-md-3">
              {{dataObj.noOfJoint}}
            </div>
             <div class="col-md-3 text-secondary">
              Nof of Films Taken
            </div>
            <div class="col-md-3">
              {{dataObj.nofOfFilmsTaken}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              No of Films Rejected
            </div>
            <div class="col-md-3">
              {{dataObj.noOfFilmsRejected}}
            </div>
             <div class="col-md-3 text-secondary">
            Acceptance Ratio
            </div>
            <div class="col-md-3">
              {{dataObj.acceptanceRatio}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Total RT Length
            </div>
            <div class="col-md-3">
              {{dataObj.totalRTLength}}
            </div>
             <div class="col-md-3 text-secondary">
            Total RT Rejected Length
            </div>
            <div class="col-md-3">
              {{dataObj.totalRTRejectedLength}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Total Rejection Rate
            </div>
            <div class="col-md-3">
              {{dataObj.totalRejectRate}}
            </div>
             <div class="col-md-3 text-secondary">
              Result
            </div>
            <div class="col-md-3">
              {{dataObj.result}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              RT Report
            </div>
            <div class="col-md-3">
              {{dataObj.rtReportNo}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
               RT Report Date
            </div>
            <div class="col-md-3">
               {{dataObj.rtReportDate}}
            </div>
            <div class="col-md-3 text-secondary">
              
            </div>
            <div class="col-md-3 text-start">
              <button class="btn btn-secondary" @click="clickCancel">Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import api from '@/common/api';
import apiUrl from '@/common/apiurl';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'EndorsementHistoryDetail',
  components: {
    ...Forms,
  },
  data() {
    return {
      dataObj: {},
      oid:0,
      isContractor:false
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    console.log(this.$route.params);
    let passedId = this.$route.params.oid;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isContractor = this.$route.params.isContractor;
      console.log(this.isContractor);
    }
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Endorsement History Detail' }
    ]);

    this.getDetail();
  },
  methods:{
    getDetail(){
      this.showLoadingScreen();
      api.regularGet(apiUrl.assessment.getEndorsementHistoryDetail + "/" + this.oid).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.dataObj = response.data;
          console.log(this.dataObj);
        }
      });
    },
    clickCancel(){
      if(!this.isContractor)
        this.$router.push({ name: 'EndorsementHistory'});
      else
        this.$router.push({ name: 'EndorsementHistoryContractor'});
    },
  }
}
</script>
