<template>
<div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Settings</h5>
        <div>
        <div class="row">
            <div class="col-md-12">
            <div class="row mb-12 row-cols-5">
                <div class="col">
                    <button class="btn  btn-info m-1" style="width:100%;padding-top:40px;padding-bottom:40px;color:white;background-color:#1b7ced" @click="clickSetting($data._settingGroup.bank)"> 
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-bank2" style="font-size:40px;"></i> <br/>Bank</span> 
                    </button>
                </div>
                 <div class="col">
                    <button class="btn btn-success m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1870d5" @click="clickSetting($data._settingGroup.branchCode)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-building" style="font-size:40px;"></i> <br/>Bank Branch</span> </button>
                </div>
                 <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1663be" @click="clickSetting($data._settingGroup.country)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-globe" style="font-size:40px;"></i> <br/>Country</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1357a6" @click="clickSetting($data._settingGroup.bloodGroup)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-droplet-fill" style="font-size:40px;"></i> <br/>Blood Group</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#104a8e" @click="clickSetting($data._settingGroup.race)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-people-fill" style="font-size:40px;"></i> <br/>Race</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1b7ced" @click="clickSetting($data._settingGroup.sac)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-easel" style="font-size:40px;"></i> <br/>SAC</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1870d5" @click="clickSetting($data._settingGroup.ownership)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-clipboard-check" style="font-size:40px;"></i> <br/>Ownership</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1663be" @click="clickSetting($data._settingGroup.techSkill)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-person-check" style="font-size:40px;"></i> <br/>TechSkill</span></button>
                </div>

                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1357a6" @click="clickSetting($data._settingGroup.educationLevel)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-stack" style="font-size:40px;"></i> <br/>Education Level</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#104a8e" @click="clickSetting($data._settingGroup.skillSet)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-person-video2" style="font-size:40px;"></i> <br/>SkillSet</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1b7ced" @click="clickSetting($data._settingGroup.citizenship)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-flag-fill" style="font-size:40px;"></i> <br/>Citizenship</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1870d5" @click="clickSetting($data._settingGroup.occupation)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-person-badge-fill" style="font-size:40px;"></i> <br/>Occupation</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1663be" @click="clickSetting($data._settingGroup.qualification)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-book-fill" style="font-size:40px;"></i> <br/>Qualification</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1357a6" @click="clickSetting($data._settingGroup.paymentMethod)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-credit-card-2-front-fill" style="font-size:40px;"></i> <br/>Payment Method</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#104a8e" @click="clickSetting($data._settingGroup.emailSetting)">
                      <span style="font-weight:bold;font-size: large;"><i class="bi bi-envelope-fill" style="font-size:40px;"></i> <br/>Email</span></button>
                </div>
                <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1b7ced" @click="clickNotification()">
                    <span style="font-weight:bold;font-size: large;"><i class="bi bi-bell" style="font-size:40px;"></i> <br/>Notification</span></button>
                </div>
                 <div class="col">
                    <button class="btn  btn-primary m-1" style="width:100%;padding-top:40px;padding-bottom:40px;background-color:#1b7ced" @click="clickSkillTest()">
                    <span style="font-weight:bold;font-size: large;"><i class="bi bi-bell" style="font-size:40px;"></i> <br/>Skill Test</span></button>
                </div>

            </div>
            </div>
        </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import * as Forms from "@/components/custom/forms";
import * as Pickers from "@/components/custom/pickers";
export default {
  name: "ActivateWeldingInspector",
  components: {
    ...Forms,
    ...Pickers
  },
  props: {
    type: String
  },
  data() {
    return {
      
    };
  },
  beforeMount() {
    
  },
  mounted() {
    
  },
  methods: {
    clickSetting(e) {
      this.$router.push({ name: 'Manage Settings', params: { type: e } });
    },
    clickNotification(){
      this.$router.push({ name: 'NotificationTemplates', params: { } });
    },
    clickSkillTest(){
      this.$router.push({ name: 'SkillTests', params: { } });
    }
  },
}
</script>
