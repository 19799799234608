<template>
<div style="padding:10px;">
      <table
    border="1"
    width="100%"
    style="
      border-style: solid;
      border-width: 2;
      border-collapse: collapse;
      padding: 10px;
    "
  >
    <tbody>
      <tr>
        <td>
          <table
            border="1"
            cellpadding="0"
            cellspacing="0"
            style="
              border-collapse: collapse;
              border-left-width: 0;
              border-right-width: 0;
              border-top-width: 0;
            "
            bordercolor="#111111"
            width="100%"
            id="AutoNumber1"
          >
            <tbody>
              <tr>
                <td><img style="width:200px;" src="/img/PrimosLogo.png"/></td>
                <td
                  width="100%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                >
                  <p align="center" style="line-height:0.8;">
                    <font face="Times New Roman"
                      ><b
                        >Primos Singapore Pte Ltd<br />
                        <font size="1"
                          >61 Bukit Batok Crescent, #07-05 Heng Loong Building, Singapore 658078<br/>
                          Tel: (65) 6779 7706</font><br/><br/>
                        <font size="2"
                          >PRiMOS Welding Inspector Application Form</font
                        ></b
                      ></font
                    >
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          &nbsp;
          <!-- Corporate Information -->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="border-collapse: collapse"
            bordercolor="#111111"
            width="100%"
            id="AutoNumber2"
          >
            <tbody>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Date of Application</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">{{dateCreated}}</font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Registration / Reference Number</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">{{serialNo}}</font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <u
                    ><font face="Times New Roman" size="2"
                      ><b>Corporate Information</b></font
                    ></u
                  >
                </td>
                <td width="5%">&nbsp;</td>
                <td width="50%">&nbsp;</td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Corporate Name</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">
                    {{ corpName }}&nbsp;
                  </font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Corporate Address</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">{{ corpAddress }}</font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Corporate Country</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">{{ corpCountry }}</font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Corporate Postal Code</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">{{
                    corpPostalCode
                  }}</font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="5" width="100%">
                <table width="100%" v-for="(i, idx) in dataObj" :key="idx">
                  <tbody>
                    <tr>
                      <td width="3%">&nbsp;</td>
                      <td>
                        <u
                          ><b
                            ><font size="2" face="Times New Roman"
                              >Welding Inspector ({{idx+1}})</font
                            ></b
                          ><font face="Times New Roman"
                            ><b><font size="2"> Information</font></b></font
                          ></u
                        >
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <!-- ------ -->
                      <td>&nbsp;</td>
                      <td>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"><b>Name</b></font>
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          >{{i.name}}</font
                        >
                      </td>
                      <td width="3%">&nbsp;</td>

                      <!-- ---- -->
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"
                          ><b>Designation</b></font
                        >
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2">{{i.designation}}</font>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <!-- -- -->
                     <tr>
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"><b>Qualification</b></font>
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          >{{i.qualification}}</font
                        >
                      </td>
                      <td width="3%">&nbsp;</td>

                      <!-- ---- -->
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"
                          ><b>Certificate No.</b></font
                        >
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2">{{i.weldCertNo}}</font>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <!-- -- -->
                     <tr>
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"><b>Qualification Expiry Date</b></font>
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          >{{i.weldCertExpiryDate_String}}</font
                        >
                      </td>
                      <td width="3%">&nbsp;</td>

                      <!-- ---- -->
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"
                          ><b>Experience</b></font
                        >
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2">{{i.experience}}</font>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <!-- -- -->
                     <tr>
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"><b>Professional Membership</b></font>
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          >{{i.profMember}}</font
                        >
                      </td>
                      <td width="3%">&nbsp;</td>

                      <!-- ---- -->
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"
                          ><b>Telephone Number / Extension</b></font
                        >
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2">{{i.telephone}}</font>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <!-- -- -->
                     <tr>
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"><b>Fax Number</b></font>
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          >{{i.fax}}</font
                        >
                      </td>
                      <td width="3%">&nbsp;</td>

                      <!-- ---- -->
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"
                          ><b>Email Address</b></font
                        >
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          ><a href="mailto:uwu@mail.com">{{i.email}}</a></font>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <!-- -- -->
                     <tr>
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"><b>Mobile Number</b></font>
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b>:</b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"
                          >{{i.mobileNo}}</font
                        >
                      </td>
                      <td width="3%">&nbsp;</td>

                      <!-- ---- -->
                      <td width="3%">&nbsp;</td>
                      <td width="30%">
                        <font face="Times New Roman" size="2"
                          ><b></b></font
                        >
                      </td>
                      <td width="3%">
                        <font face="Times New Roman" size="2"><b></b></font>
                      </td>
                      <td width="10%">
                        <font face="Times New Roman" size="2"></font>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <!-- -- -->
                  </tbody>
                </table>
                </td>
              </tr>
            </tbody>
          </table>
          &nbsp;
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="border-collapse: collapse"
            bordercolor="#111111"
            width="100%"
            id="AutoNumber3"
          >
            <tbody>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="94%">
                  <font face="Times New Roman" size="1"
                    >I hereby submit this application for approval as a Welding
                    Inspector and agree to comply with the terms and conditions
                    for the participation in the CWQS. I declare that the
                    information given in this application is correct to the best
                    of my knowledge and belief.</font
                  >
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
            </tbody>
          </table>
          &nbsp;
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="border-collapse: collapse; border-width: 0"
            bordercolor="#111111"
            width="100%"
            id="AutoNumber4"
          >
            <tbody>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="37%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: solid;
                    border-top-width: 1;
                    border-bottom-style: none;
                    border-bottom-width: medium;
                    border-top: 1px solid;
                  "
                  valign="top"
                >
                  <font size="2" face="Times New Roman"
                    >Name &amp; Designation of Approving Officer<br />
                    Company Stamp of Employer (if any)</font
                  >
                </td>
                <td
                  width="3%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="25%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: solid;
                    border-top-width: 1;
                    border-bottom-style: none;
                    border-bottom-width: medium;
                    border-top: 1px solid;
                  "
                  valign="top"
                >
                  <p align="center">
                    <font face="Times New Roman" size="2"
                      >Authorized Signature</font
                    >
                  </p>
                </td>
                <td
                  width="3%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="22%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: solid;
                    border-top-width: 1;
                    border-bottom-style: none;
                    border-bottom-width: medium;
                    border-top: 1px solid;
                  "
                  valign="top"
                >
                  <p align="center">
                    <font face="Times New Roman" size="2">Date</font>
                  </p>
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            cellpadding="0"
            cellspacing="0"
            style="border-collapse: collapse; border-width: 0"
            bordercolor="#111111"
            width="100%"
            id="AutoNumber5"
          >
            <tbody>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="94%"
                  style="border-style: none; border-width: medium"
                >
                  <font face="Times New Roman" size="1"
                    >Note: Please submit this form together with all the
                    relevant documents for verification and approval.</font
                  >
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="94%"
                  style="border-style: none; border-width: medium"
                >
                  <font face="Times New Roman" size="1"
                    >For any assistance, please email us at
                    <a href="mailto:query@primos.com.sg">query@primos.com.sg</a>
                    / phone us at (65) 6779 7706 / Fax to us at (65) 6274
                    4055</font
                  >
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="border-collapse: collapse;margin-top:-30px;"
            bordercolor="#111111"
            width="100%"
            id="AutoNumber2"
          >
            <tbody>
              <tr>
                <td
                  width="3%"
                  style="border-top-style: solid; border-top-width: 1"
                >
                </td>
                <td
                  width="39%"
                  style="border-top-style: solid; border-top-width: 1"
                >
                  <font face="Times New Roman"><br /></font
                  ><u
                    ><b>
                      <font face="Times New Roman" size="2"
                        >For Official Use</font
                      ></b
                    ></u
                  >
                </td>
                <td
                  width="5%"
                  style="border-top-style: solid; border-top-width: 1"
                >
                  &nbsp;
                </td>
                <td
                  width="50%"
                  style="border-top-style: solid; border-top-width: 1"
                >
                  &nbsp;
                </td>
                <td
                  width="3%"
                  style="border-top-style: solid; border-top-width: 1"
                >
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Date of Application</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">{{dateCreated}}</font>
                </td>
                <td width="3%">&nbsp;</td>
                <!-- ------- -->
                
              </tr>
              <tr>
                <td width="3%">&nbsp;</td>
                <td width="39%">
                  <font face="Times New Roman" size="2"
                    ><b>Registration / Reference Number</b></font
                  >
                </td>
                <td width="5%">
                  <font face="Times New Roman" size="2"><b>:</b></font>
                </td>
                <td width="50%">
                  <font face="Times New Roman" size="2">W00000268</font>
                </td>
                <td width="3%">&nbsp;</td>
              </tr>
            </tbody>
          </table>
          &nbsp;
          <table
            border="1"
            cellpadding="0"
            cellspacing="0"
            style="border-collapse: collapse; border-width: 0;margin-top:-20px;"
            bordercolor="#111111"
            width="100%"
            id="AutoNumber5"
          >
            <tbody>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="94%"
                  style="border-style: none; border-width: medium"
                >
                  <b>
                    <font face="Times New Roman" size="2"
                      >This application is hereby ACCEPTED / REJECTED *</font
                    ></b
                  >
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="94%"
                  style="border-style: none; border-width: medium"
                >
                  <b><font face="Times New Roman" size="2">Reason:</font></b>
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="94%"
                  style="border-style: none; border-width: medium"
                >
                  <b><font face="Times New Roman" size="2">&nbsp; </font></b>
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          &nbsp;
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="
              margin-top:-20px;
              border-collapse: collapse;
              border-left-width: 0;
              border-right-width: 0;
              border-top-width: 0;
            "
            bordercolor="#111111"
            width="100%"
            id="AutoNumber4"
          >
            <tbody>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="37%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: solid;
                    border-top-width: 1;
                    border-bottom-style: none;
                    border-bottom-width: medium;border-top: 1px solid;
                  "
                  valign="top"
                >
                  <font size="2" face="Times New Roman"
                    >Name &amp; Designation of Approving Officer<br />
                    Company Stamp (if any)</font
                  >
                </td>
                <td
                  width="3%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="25%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: solid;
                    border-top-width: 1;
                    border-bottom-style: none;
                    border-bottom-width: medium;border-top: 1px solid;
                  "
                  valign="top"
                >
                  <p align="center">
                    <font face="Times New Roman" size="2"
                      >Authorized Signature</font
                    >
                  </p>
                </td>
                <td
                  width="3%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="22%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: solid;
                    border-top-width: 1;
                    border-bottom-style: none;
                    border-bottom-width: medium;border-top: 1px solid;
                  "
                  valign="top"
                >
                  <p align="center">
                    <font face="Times New Roman" size="2">Date</font>
                  </p>
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
                <td
                  width="37%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="3%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="25%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="3%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="22%"
                  style="border-style: none; border-width: medium"
                  valign="top"
                >
                  &nbsp;
                </td>
                <td width="3%" style="border-style: none; border-width: medium">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td
                  width="3%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                >
                  &nbsp;
                </td>
                <td
                  width="37%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                  valign="top"
                >
                  <font size="1" face="Times New Roman"
                    >* Please delete where inapplicable</font
                  >
                </td>
                <td
                  width="3%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="25%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="3%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="22%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                  valign="top"
                >
                  &nbsp;
                </td>
                <td
                  width="3%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                >
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>

</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: "WIApplicationForm",
  components: {},
  data() {
    return {
      serialNo:'',
      oidList: [],
      dataObj: {},
      corpName: "",
      corpAddress: "",
      corpCountry: "",
      corpPostalCode: "",
      dateCreated:"",
    };
  },
  beforeMount() {
    let oids = this.$route.query.oids;
    if (oids) {
      this.oidList = JSON.parse(oids);
      this.getData();
    }
  },
  mounted() {},
  methods: {
    getData() {
      let url = apiUrl.regWeldingInspector.getByOids;
      api.regularPost(url, this.oidList).then((response) => {
        this.dataObj = response.data;
        if (this.dataObj.length > 0) {
          this.corpName = this.dataObj[0].corporName;
          this.corpAddress = this.dataObj[0].corpAddress;
          this.corpCountry = this.dataObj[0].corpCountry;
          this.corpPostalCode = this.dataObj[0].corpPostalCode;
          this.dateCreated = this.dataObj[0].createdDate_String;
          this.serialNo = this.pad(this.dataObj[0].oid,8);
         
          
        }
         setTimeout(function (){
           console.log('flag1');
            window.print();
          }, 1500);
      });
    },
    pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return 'W' + num;
    }
  },
};
</script>