<template>
  <div>
      
      <div class="card">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-6">
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Corporate:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.corporateName}}
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Name:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.name}}
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              NRIC:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.nricFin}}
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Work Permit:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.workPermit}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Work Permit Valid From Date:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.workPermitIssueDateDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Work Permit Expiry Date
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.workPermitExpiryDateDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Date of Birth:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.dobDisplay}}
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Occupation:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.occupationDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Gender:
                          </div>
                          <div class="col-sm-6">
                              {{dataObj.genderDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Nationality:
                          </div>
                          <div class="col-sm-6">
                               {{dataObj.nationalityDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Citizenship:
                          </div>
                          <div class="col-sm-6">
                               {{dataObj.citizenshipDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Education:
                          </div>
                          <div class="col-sm-6">
                               {{dataObj.educationDisplay}}
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-sm-6 text-secondary">
                              Commencement Date:
                              
                          </div>
                          <div class="col-sm-6">
                               {{dataObj.commenceDateDisplay}}
                          </div>
                      </div>
                  </div>
              </div>

              <button class="btn btn-secondary m-1 float-end" @click="clickCancel">
                  Cancel
              </button>
          </div>
      </div>
  </div>
</template>

<script>
import apiUrl from "@/common/apiurl";
import api from "@/common/api";
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
export default {
  name: "ViewStaff",

  components: {
    ...Forms,
    ...Pickers,
  },

  props: {},

  data() {
    return {
      oid: 0,
      dataObj: {},

    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = 'View Staff';
    this.setBreadcrumb([
      { name: 'Staff', url: '/staff' }, { name: pageName }
    ]);
    this.getData();
  },

  methods: {
    getData() {
      let url = apiUrl.worker.getByOidStaff + "/" + this.oid;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        console.log(this.dataObj.gender);
        if (this.dataObj.dob)
          this.dob = this.convertDateStrToDateObj(this.dataObj.dob);
        if (this.dataObj.workPermitIssueDate){
          this.workPermitIssueDate = this.convertDateStrToDateObj(this.dataObj.workPermitIssueDate);
        }
        if (this.dataObj.commenceDate)
          this.commenceDate = this.convertDateStrToDateObj(this.dataObj.commenceDate);
        if (this.dataObj.workPermitExpiryDate){
          this.workPermitExpiryDate = this.convertDateStrToDateObj(this.dataObj.workPermitExpiryDate);
        }
      });
    },
    clickCancel() {
      this.$router.push({ name: "StaffList" });
    },

  },
};
</script>
