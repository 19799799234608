export default {
  /**
   *
   * Function Name: isValidWorkPermit
   * Input        : Work Permit Number
   * Output       : True/False
   * Purpose      : Checks for valid format of Work Permit Number
   * Note         :
   * Remarks      :
   * Last Modified:
   * Modified By  :
   *
   */
  isValidWorkPermit(workpermit) {
    let pass = false;

    if (workpermit) {//Check not NULL value
      if (workpermit.length == 12 || workpermit.length == 10) {//Check for Work Permit length ( '1 23456789 0') to 12 characters
        if (!isNaN(workpermit.charAt(0))) {//1st character must be a digit
          if (workpermit.charAt(1) == ' ') {//2nd character must be a space
            if (!isNaN(workpermit.substring(2, 8))) {//3rd to 9th character must be a digit
              if (workpermit.charAt(9) == '-' || !isNaN(workpermit.charAt(9))) {//10th character must be a "-" or digit
                if (workpermit.length == 12) {
                  if (workpermit.charAt(10) == ' ') {//11th character must be a space
                    if (!isNaN(workpermit.charAt(11))) {//12th character must be a digit
                      pass = true;
                    }
                  }
                }
                else {
                  pass = true;
                }
              }
            }
          }
        }
      }
    }
    return pass;
  },

  

}