<template>
  <div>
    <h4 class="mb-2">Worker File Upload</h4>
    <div v-show="step==1">
      <div class="row mb-2">
        <div class="col-sm-6">
          <file-input :status="filestatus" @selectFile="file=$event" @done="filestatus='stop'" accept=".xlsx" />
        </div>
      </div>
      <div class="mb-3">
        Click <a href="javascript:;" @click="clickDownloadSampleExcel">here</a> to download the Excel template.
      </div>
      <button class="btn btn-primary mt-3 mb-3" @click="previewUpload">Preview Upload</button>      
    </div>
    <div v-show="step==2">
      <button v-show="showBackBtn" class="btn btn-secondary mb-3" @click="clickBack">Back</button>
      <div >
        <div class="table-responsive small">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Error Message</th>
                <th>No.</th>
                <th>WorkPermit</th>
                <th>NRIC_FIN</th>
                <th>WorkPermitValidFromDate</th>
                <th>WorkPermitExpiryDate</th>
                <th>Welder ID</th>
                <th>Name</th>
                <th>DOB</th>
                <th>Gender</th>
                <th>Race</th>
                <th>Nationality</th>
                <th>Citizenship</th>
                <th>SkillSet</th>
                <th>Occupation</th>
                <th>Education</th>
                <th>CommenceDate</th>
                <th>Sponsor</th>
                <th>Sub-Contractor</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(l,i) in list" :key="i">
                <td><span class="text-danger" v-html="l.message"></span></td>
                <td>{{i+1}}</td>
                <td>{{l.workPermit}}</td>
                <td>{{l.nricFin}}</td>
                <td>{{l.workPermitIssueDate}}</td>
                <td>{{l.workPermitExpiryDate}}</td>
                <td>{{l.welderId}}</td>
                <td>{{l.name}}</td>
                <td>{{l.dob}}</td>
                <td>{{l.gender}}</td>
                <td>{{l.race}}</td>
                <td>{{l.nationality}}</td>
                <td>{{l.citizenship}}</td>
                <td>{{l.skillSet}}</td>
                <td>{{l.occupation}}</td>
                <td>{{l.education}}</td>
                <td>{{l.commenceDate}}</td>
                <td>{{l.sponsor}}</td>
                <td>{{l.subContractor}}</td>
                <td>{{l.remarks}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-show="canUpload">
         <button class="btn btn-primary mt-3 mb-3" @click="upload">Upload</button>
      </div>
    </div>
  </div>
</template>
<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';

export default {
  name: 'BatchUploadWorker',
  components: {
    ...Forms
  },
  data() {
    return {
      filestatus: 'stop',
      file: null,
      list: [],
      step: 1,
      canUpload: false,
      showBackBtn: true,
      message: ''
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Batch Upload' }
    ]);
  },
  methods: {
    clickDownloadSampleExcel() {
      api.downloadFile(apiUrl.worker.downloadBatchUploadSample, null, 'Template-WorkerInfo.xlsx', () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    previewUpload() {
      this.step = 2;
      this.message = "";
      let formdata = new FormData();
      formdata.append("File", this.file);
      formdata.append("IsPreviewOnly", true);
      console.log(this.file);
      api.regularPost(apiUrl.worker.uploadBatch, formdata, "multipart/form-data").then((response) => {
        if (response.status == 200) {
          this.list = response.data.data;
          this.message = response.data.message;
          if (!this.message)
            this.canUpload = true;
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    upload() {
      let formdata = new FormData();
      formdata.append("File", this.file);
      formdata.append("IsPreviewOnly", false);
      api.regularPost(apiUrl.worker.uploadBatch, formdata, "multipart/form-data").then((response) => {
        if (response.status == 200) {
          this.canUpload = false;
          this.showBackBtn = false;
          this.showMessage("Successfully uploaded.", "success");
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickBack() {
      this.step = 1;
      this.filestatus = 'reset';
    }
  }
}
</script>
