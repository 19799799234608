<template>
  <div style="background: #f2f2f2; min-height:100vh;">
    <div class="container">
      <div class="row">
        <div class="offset-md-4 col-md-4 ">
          <div class="text-center mb-3" style="margin-top:10%;">
            <!-- <img src="/img/PrimosLogo.png" style="width:300px;" /> -->
          </div>
          <div class="card">
            <div class="card-body">
              <div class="mb-3 text-secondary">
                <h3>New Password</h3>
                <span>Enter your new password</span>
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text"><i class="bi bi-envelope"></i></span>
                <input type="password" class="form-control" v-model="password" placeholder="Password" maxlength="20"/>
              </div>
               <div class="input-group mb-3">
                <span class="input-group-text"><i class="bi bi-envelope"></i></span>
                <input type="password" class="form-control" v-model="confirmpassword" placeholder="Confirm new password" maxlength="20"/>
              </div>
              <div class="mb-3">
                <button class="btn btn-primary float-end" style="width:100px;" @click="submit">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      password: '',
      confirmpassword: '',
      key:'',
      dataObj:{}
    }
  },
  beforeMount() {
    let passedKey = this.$route.query.key;
    if (passedKey) {
      this.key = passedKey;
    }
  },
  mounted(){
      let url =  apiUrl.users.checkForgotPasswordReqestValid + "/" + this.key;
      api.regularGet(url, null).then((response) => {
        if (response.status == 200) {
          this.dataObj = response.data.data;
          console.log(this.dataObj);
        }
        else {
          this.showMessage("Invalid Request", "error");
          this.$router.push({ name: "Login" });
        }
      });
  },
  methods: {
    //Testing method of Vuex
    testVuexStore() {
      //this.$store.state.testCount = 100; //OR
      this.$store.commit('testSet', 100);
      setTimeout(() => {
        console.log(this.$store.state.testCount);
      }, 500);
    },

    submit() {
      if(!this.password){
        this.showMessage("Enter new password.", "warning");
        return;
      }
      if(this.password != this.confirmpassword){
        this.showMessage("Password and confirm password don't match.", "warning");
        return;
      }
      let data = {
        oid:this.dataObj.oid,
        password:this.password
      }
      let url =  apiUrl.users.forgotPasswordChange 
      api.regularPost(url, data).then((response) => {
        if (response.status == 200) {
          this.showMessage("Password changed successfully.", "success");
          this.$router.push({ name: "Login" });
        }
        else {
          this.showMessage("Error occured", "error");
        }
      });
    },
    keypress(e){
      //Enter
      if(e.keyCode == 13){
        this.submit();
      }
    }
  }
}
</script>
