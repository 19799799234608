<template>
<div>
  <h4>Test Statistics Report</h4>
  <div>
    <div class="row mb-4" v-if="canViewAll">
      <div class="col-md-6">
        <select v-model="selectedCorporatOids" multiple class="form-control mb-2">
          <option v-for="(i, idx) in contractors" :key="idx" :value="i.oid">{{i.corpName}}</option>
        </select>
        <label class="hover text-secondary"><input type="checkbox" v-model="isAllCororate"> Select All</label>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        From Date
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <FormSelect :list="months" value="value" text="text" :existing="fromMonth"
          @change="fromMonth=$event" />
          <FormSelect :list="years" value="value" text="text" :existing="fromYear"
          @change="fromYear=$event" />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        To Date
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <FormSelect :list="months" value="value" text="text" :existing="toMonth"
          @change="toMonth=$event" />
          <FormSelect :list="years" value="value" text="text" :existing="toYear"
          @change="toYear=$event" />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">

      </div>
      <div class="col-md-3 text-end">
        <button class="btn btn-primary" @click="clickSearh">Submit</button>
      </div>
    </div>
  </div>
  <div class="mb-2 mt-2" v-for="(i, idx) in reportData" :key="idx">
    <hr/>
    <div class="mb-2"><strong>{{i.corporate}}</strong></div>
    <div class="mb-2 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="colwidth text-center">Test Number</th>
            <th class="colwidth text-center" v-for="(sd,di) in selectedDates" :key="di">
              <div>{{sd.text}}</div>
              <div class="textblock">P</div>
              <div class="textblock">F</div>
              <div class="textblock">A</div>
            </th>
            <th class="colwidth text-center">
              <div style="width:90px;">Total</div>
              <div class="textblock">P</div>
              <div class="textblock">F</div>
              <div class="textblock">A</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ld, di) in i.listData" :key="di">
            <td class="colwidth">{{ld.testNumber}}</td>
            <td class="colwidth" v-for="(sd,di1) in selectedDates" :key="di1">
              <div v-if="sd.month==0">
                <div class="textblock">{{ld.janPass}}</div>
                <div class="textblock">{{ld.janFail}}</div>
                <div class="textblock">{{ld.janAbsent}}</div>
              </div>
              <div v-else-if="sd.month==1">
                <div class="textblock">{{ld.febPass}}</div>
                <div class="textblock">{{ld.febFail}}</div>
                <div class="textblock">{{ld.febAbsent}}</div>
              </div>
              <div v-else-if="sd.month==2">
                <div class="textblock">{{ld.marPass}}</div>
                <div class="textblock">{{ld.marFail}}</div>
                <div class="textblock">{{ld.marAbsent}}</div>
              </div>
              <div v-else-if="sd.month==3">
                <div class="textblock">{{ld.aprPass}}</div>
                <div class="textblock">{{ld.aprFail}}</div>
                <div class="textblock">{{ld.aprAbsent}}</div>
              </div>
              <div v-else-if="sd.month==4">
                <div class="textblock">{{ld.mayPass}}</div>
                <div class="textblock">{{ld.mayFail}}</div>
                <div class="textblock">{{ld.mayAbsent}}</div>
              </div>
              <div v-else-if="sd.month==5">
                <div class="textblock">{{ld.junPass}}</div>
                <div class="textblock">{{ld.junFail}}</div>
                <div class="textblock">{{ld.junAbsent}}</div>
              </div>
              <div v-else-if="sd.month==6">
                <div class="textblock">{{ld.julPass}}</div>
                <div class="textblock">{{ld.julFail}}</div>
                <div class="textblock">{{ld.julAbsent}}</div>
              </div>
              <div v-else-if="sd.month==7">
                <div class="textblock">{{ld.augPass}}</div>
                <div class="textblock">{{ld.augFail}}</div>
                <div class="textblock">{{ld.augAbsent}}</div>
              </div>
              <div v-else-if="sd.month==8">
                <div class="textblock">{{ld.sepPass}}</div>
                <div class="textblock">{{ld.sepFail}}</div>
                <div class="textblock">{{ld.sepAbsent}}</div>
              </div>
              <div v-else-if="sd.month==9">
                <div class="textblock">{{ld.octPass}}</div>
                <div class="textblock">{{ld.octFail}}</div>
                <div class="textblock">{{ld.octAbsent}}</div>
              </div>
              <div v-else-if="sd.month==10">
                <div class="textblock">{{ld.novPass}}</div>
                <div class="textblock">{{ld.novFail}}</div>
                <div class="textblock">{{ld.novAbsent}}</div>
              </div>
              <div v-else-if="sd.month==11">
                <div class="textblock">{{ld.decPass}}</div>
                <div class="textblock">{{ld.decFail}}</div>
                <div class="textblock">{{ld.decAbsent}}</div>
              </div>
            </td>
            <td class="colwidth">
              <div class="textblock">{{ld.totalPass}}</div>
              <div class="textblock">{{ld.totalFail}}</div>
              <div class="textblock">{{ld.totalAbsent}}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>
<style scoped>
.textblock {
  display: inline-block;
  text-align: center;
  width: 20px;
}
.colwidth {
  width:100px;
  text-align: center;
}

</style>
<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';

export default {
  name: 'TestStatisticsReport',
  components: {
    ...Forms,
  },
  data() {
    return {
      canViewAll:true,
      reportData: [],
      selectedCorporatOids: [],
      contractors: [],
      isAllCororate: false,
      months: [],
      years: [],
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null,
      selectedDates: [],
      monthArr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Test Statistics Report' }
    ]);
    let corporateType = this.getLoggedInUser().userGroupType;
    api.getAllCorporates(null).then(response => {
      if(corporateType == this.$data._userGroupType.contractor.value){
         this.contractors = response.data.filter(x =>  x.oid == this.getLoggedInUser().corporateOid);
         this.canViewAll = false;
         this.selectedCorporatOids.push(this.getLoggedInUser().corporateOid);
      }else{
         this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      }
    });

    for (let i = 0; i < this.monthArr.length; i++) {
      this.months.push({ text: this.monthArr[i], value: i });
    }

    let thisYear = new Date().getFullYear();
    let startYear = thisYear - 10;
    for (let i = startYear; i <= thisYear; i++) {
      this.years.push({ text: i, value: i });
    }
    this.fromMonth = 0;
    this.toMonth = 11;
    this.fromYear = thisYear;
    this.toYear = thisYear;
  },

  methods: {
    getData() {
      var param = {
        corporateOids: this.selectedCorporatOids,
        isAllCorporate: this.isAllCororate,
      };
      var fromDate = new Date(this.fromYear, this.fromMonth, 1);
      var toDate = new Date(this.toYear, this.toMonth, 1);
      toDate.setMonth(toDate.getMonth() + 1);
      toDate.setDate(toDate.getDate() - 1);

      let monthDiff = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
      monthDiff -= fromDate.getMonth();
      monthDiff += toDate.getMonth();

      let isValid = true;
      if (monthDiff > 12) {
        this.showMessage("Allow only 12 months!", "warning");
        isValid = false;
      }
      else if (monthDiff < 1) {
        this.showMessage("Invalid months selected!", "warning");
        isValid = false;
      }
      console.log(param);

      if (isValid) {
        param.fromDate = this.convertDateObjToDateStr(fromDate);
        param.toDate = this.convertDateObjToDateStr(toDate);

        this.selectedDates = [];
        let tmpDate = new Date(fromDate);
        while (tmpDate.getTime() <= toDate.getTime()) {
          this.selectedDates.push({
            text: this.monthArr[tmpDate.getMonth()] + '/' + tmpDate.getFullYear(),
            month: tmpDate.getMonth()
          });
          tmpDate.setMonth(tmpDate.getMonth() + 1);
        }

        this.reportData = [];
        this.showLoadingScreen();
        api.regularPost(apiUrl.report.getTestStatisticsReport, param).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.reportData = response.data;
          }
        });
      }

    },
    clickSearh() {
      this.getData();
    }
  }
}
</script>
