<template>
  <div>
    <h4>Test Centre Delegation</h4>
    <div class="mb-4">
      Please select a Test Centre to delegates the Endorsement of Welder Performance Update.
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Delegated Test Centre 1
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" @change="testCentre1Oid=$event"
          :cssClass="error.testCentre1Oid && 'control-error'" :existing="testCentre1Oid" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Delegated Test Centre 2
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" @change="testCentre2Oid=$event"
          :cssClass="error.testCentre2Oid && 'control-error'" :existing="testCentre2Oid" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Delegated Test Centre 3
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" @change="testCentre3Oid=$event"
          :cssClass="error.testCentre3Oid && 'control-error'" :existing="testCentre3Oid" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Delegated Test Centre 4
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" @change="testCentre4Oid=$event"
          :cssClass="error.testCentre4Oid && 'control-error'" :existing="testCentre4Oid" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Delegated Test Centre 5
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" @change="testCentre5Oid=$event"
          :cssClass="error.testCentre5Oid && 'control-error'" :existing="testCentre5Oid" />
      </div>
    </div>
    <div class="text-end">
      <button class="btn btn-success" @click="clickSubmit">Submit</button>
    </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'UpdateTestCentreDelegate',
  components: {
    ...Forms,
  },
  data() {
    return {
      testCentre1Oid: null,
      testCentre2Oid: null,
      testCentre3Oid: null,
      testCentre4Oid: null,
      testCentre5Oid: null,

      testCentres: [],

      error: {},
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Test Centre Delegation' }
    ]);

    api.getAllCorporates(null).then(response => {
      this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
      this.testCentres.unshift({ oid: null, corpName: '--None--' });
    });

    this.getData();
  },
  methods: {
    getData() {
      api.regularGet(apiUrl.performance.getTestCentreDelegates, null).then(res => {
        let listData = res.data;
        if (listData && listData.length > 0) {
          this.testCentre1Oid = listData[0].testCentreOid;
          if (listData.length > 1) {
            this.testCentre2Oid = listData[1].testCentreOid;
            if (listData.length > 2) {
              this.testCentre3Oid = listData[2].testCentreOid;
              if (listData.length > 3) {
                this.testCentre4Oid = listData[3].testCentreOid;
                if (listData.length > 4) {
                  this.testCentre5Oid = listData[4].testCentreOid;
                }
              }
            }
          }
        }
      });
    },
    clickSubmit() {

      let postData = {
        testCentreOids: []
      };
      if (this.testCentre1Oid) {
        postData.testCentreOids.push(this.testCentre1Oid);
      }
      if (this.testCentre2Oid) {
        postData.testCentreOids.push(this.testCentre2Oid);
      }
      if (this.testCentre3Oid) {
        postData.testCentreOids.push(this.testCentre3Oid);
      }
      if (this.testCentre4Oid) {
        postData.testCentreOids.push(this.testCentre4Oid);
      }
      if (this.testCentre5Oid) {
        postData.testCentreOids.push(this.testCentre5Oid);
      }

      this.showLoadingScreen();
      api.regularPost(apiUrl.performance.updateTestCentreDelegate, postData).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully Submitted.", "success");
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.doctors.unable_delete, "error");
        }
      });
    }
  }
}
</script>
