<template>
  <div>
    <div>
      <button class="btn btn-primary m-1 float-end" style="width:100px;" @click="clickPrint(dataObj.oid)">Print</button>
    </div>
    <h4>Test Application</h4>
    
    <h5>CWQS Test</h5>
    
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Application No
      </div>
      <div class="col-md-3">
        {{dataObj.testApplicationId}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Centre
      </div>
      <div class="col-md-3">
        {{dataObj.testCentre}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Date
      </div>
      <div class="col-md-3">
        {{dataObj.testDateDisplay}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Time
      </div>
      <div class="col-md-3">
        {{dataObj.testTime}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Location
      </div>
      <div class="col-md-3">
        {{dataObj.testLocation}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Location Address
      </div> 
      <div class="col-md-3">
        {{dataObj.testLocationAddress}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contact Person
      </div> 
      <div class="col-md-3">
        {{dataObj.testContactPerson}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contact Number
      </div> 
      <div class="col-md-3">
        {{dataObj.testContactNumber}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Status
      </div> 
      <div class="col-md-3">
        <span v-if="dataObj.status == $data._testApplicationStatus.active.value" class="badge bg-secondary">{{dataObj.statusDisplay}}</span>
              <span v-else-if="dataObj.status == $data._testApplicationStatus.pending.value" class="badge bg-primary">{{dataObj.statusDisplay}}</span>
              <span v-else-if="dataObj.status == $data._testApplicationStatus.rejected.value" class="badge bg-danger">{{dataObj.statusDisplay}}</span>
              <span v-else-if="dataObj.status == $data._testApplicationStatus.modify.value" class="badge bg-warning">{{dataObj.statusDisplay}}</span>
              <span v-else-if="dataObj.status == $data._testApplicationStatus.updated.value" class="badge bg-success">{{dataObj.statusDisplay}}</span>
              <span v-else-if="dataObj.status == $data._testApplicationStatus.cancelled.value" class="badge bg-secondary">{{dataObj.statusDisplay}}</span>
              <span v-else>{{dataObj.statusDisplay}}</span>
      </div>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <th>Name</th>
          <th>NRIC/FIN</th>
          <th>Work Permit No.</th>
          <th>Welder ID</th>
          <th>Year Exp.</th>
          <th>Component</th>
          <th>Medium</th>
          <th style="width:200px;">Test No</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in selectedWorkers" :key="idx">
          <td>{{i.workerName}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.welderId}}</td>
          <td>
            {{i.yearExperience}}
          </td>
          <td>
            {{i.testComponent}}
          </td>
          <td>
            {{i.testMedium}}
          </td>
          <td>
            <div v-for="(ii, iidx) in i.testNumbersWithStatus" :key="iidx">
              {{ii.testNumber}} 
              <span v-if="ii.status==$data._testApplicationCandidateStatus.active.value" class="badge bg-primary">{{ii.statusDisplay}}</span>
              <span v-else-if="ii.status==$data._testApplicationCandidateStatus.pass.value" class="badge bg-success">{{ii.statusDisplay}}</span>
              <span v-else-if="ii.status==$data._testApplicationCandidateStatus.fail.value" class="badge bg-danger">{{ii.statusDisplay}}</span>
              <span v-else-if="ii.status==$data._testApplicationCandidateStatus.absent.value" class="badge bg-warning">{{ii.statusDisplay}}</span>
              <span v-else-if="ii.status==$data._testApplicationCandidateStatus.absent.pending" class="badge bg-secondary">{{ii.statusDisplay}}</span>
              <span v-else>{{ii.statusDisplay}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    

    <div v-if="isAfterInsert" class="text-info">
      <a href="javascript:;" @click="clickForNewApplication" style="text-decoration:none;">
        Test application submitted, pending for confirmation by Test Centre. Click here for new application
      </a>
    </div>
    <div v-else class="text-end">
      <button class="btn btn-secondary m-1" @click="clickCancel">Back</button>
    </div>

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'ViewTestApplication',
  components: {
  },
  data() {
    return {
      oid: 0,
      dataObj: {},
      selectedWorkers: [],
      isAfterInsert: false
    }
  },
  beforeMount() {
    this.isAfterInsert = this.$route.params.isAfterInsert;
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = 'View';
    this.setBreadcrumb([
      { name: 'Test Applications', url: '/testapplicationlist' }, { name: pageName }
    ]);

    if (this.oid > 0) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      let url = apiUrl.testApplication.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;

        this.dataObj.candidates.forEach(w => {
          this.selectedWorkers.push(w);
        });

      });
    },

    clickCancel() {
      history.back();
    },

    clickForNewApplication(){
      this.$router.push({ name: 'AddEditTestApplication' });
    },
    clickPrint(oid){
      let routeData = this.$router.resolve({ name: 'ViewTestApplicationPrint', params: { id: oid } });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
