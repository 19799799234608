<template>
  <th class="hover" @click="clickColumn">
    <slot></slot>
    <span class="ms-1">
      <i v-if="sortDir=='asc'" class="bi bi-caret-up-fill font-11"></i>
      <i v-else-if="sortDir=='desc'" class="bi bi-caret-down-fill font-11"></i>
    </span>
  </th>
</template>

<script>
export default {
  name: 'SortColumn',
  props: {
    sortBy: String,
    colGroup: {
      type: String,
      default: ''
    },
  },
  watch: {
    '$store.state.columnSortDir': {
      immediate: true,
      handler() {
        if (this.$store.state.columnSortGroup == this.colGroup)
          this.sortDir = '';
      }
    }
  },
  data() {
    return {
      sortDir: ''
    }
  },
  mounted() {

  },
  methods: {
    clickColumn() {
      let dir = '';
      if (this.sortDir == "asc")
        dir = "desc";
      else
        dir = "asc";

      this.$store.commit('resetSortDir', this.colGroup);

      setTimeout(() => {
        this.sortDir = dir;
        this.$emit('click', this.sortBy, this.sortDir)
      }, 100);

    }
  }
}
</script>
