import api from "@/common/api";
import * as axios from "axios";
import checkNric from "@/common/checkNric";
import checkWorkPermit from "@/common/checkWorkPermit";
import perCode from '@/common/permissionCodes';

export default {
  data() {
    return {
      table: {
        timezone: api.TIMEZONE,
        startdate: null,
        enddate: null,
        rowcount: 100,
        perPageDropdown: [100, 200, 300, 400],
        currentpage: 1,
        filter: null,
        pagescount: 1,
        isasc: false,
        data: null,
        orderby: null,
        status: null
      },
      _orderStatus: {
        received: { text: 'Received', value: 'r' },
        pendingForReport: { text: 'Pending for Report', value: 'p' },
        finalised: { text: 'Finalised', value: 'f' },
        all: { text: 'All', value: 'a' }
      },
      _corporateStatus: {
        active: { value: 'A', text: 'Active' },
        suspended: { value: 'S', text: 'Suspended' }
      },
      _corporateType: {
        industry: { value: 'I', text: 'Industry' },
        contractor: { value: 'C', text: 'Contractor' },
        testCenter: { value: 'T', text: 'Test Centre' },
        weldingInspector: { value: 'W', text: 'Welding Inspector' },
        admin: { value: 'A', text: 'Admin' },
        sws: { value: 'S', text: 'SWS' },
        mom: { value: 'M', text: 'MOM' },
      },
      _userGroupType: {
        industry: { value: 'I', text: 'Industry' },
        contractor: { value: 'C', text: 'Contractor' },
        testCenter: { value: 'T', text: 'Test Centre' },
        weldingInsperctor: { value: 'W', text: 'Welding Inspector' },
        admin: { value: 'A', text: 'Admin' },
        sws: { value: 'S', text: 'SWS' },
        mom: { value: 'M', text: 'MOM' },
      },
      _giroStatus: {
        active: { value: 'A', text: 'Active' },
        pending: { value: 'P', text: 'Pending' }
      },
      _settingGroup: {
        bank: 'Bank',
        ownership: 'Ownership',
        paymentMethod: 'PaymentMethod',
        country: 'Country',
        branchCode: 'BranchCode',
        sac: 'SAC',
        qualification: 'Qualification',
        race: 'Race',
        bloodGroup: 'BloodGroup',
        citizenship: 'Citizenship',
        educationLevel: 'EducationLevel',
        techSkill: 'TechSkill',
        skillSet: 'SkillSet',
        occupation: 'Occupation',
        staffOccupation: 'StaffOccupation',
        emailSetting: 'EmailSetting',
        testMedium: 'TestMedium',
      },
      _status: {
        active: { value: 'A', text: 'Active' },
        deleted: { value: 'X', text: 'Deleted' }
      },
      _defaultValue: {
        countrySingapore: 4008,
        payment_giro: 3901
      },
      _permissionCodes: perCode,
      _workerStatus: {
        active: { value: 'A', text: 'Active' },
        terminated: { value: 'T', text: 'Terminated' },
      },
      _workerTransferStatus: {
        pending: { value: 'K', text: 'Pending' },
        request: { value: 'L', text: 'Request' },
        approved: { value: 'T', text: 'Approved' },
        deleted: { value: 'X', text: 'Deleted' },
        rejected: { value: 'R', text: 'Rejected' },
      },
      _workerSkillTestStatus: {
        active: { value: 'A', text: 'Active' },
        rejected: { value: 'H', text: 'Rejected' },
        pending: { value: 'N', text: 'Pending' },
        suspended: { value: 'S', text: 'Suspended' },
        draft: { value: 'R', text: 'Draft' },
      },
      _quotaStatus: {
        New: { value: '1', text: 'New' },
        Pending: { value: '2', text: 'Pending' },
        Submitted: { value: '3', text: 'Submitted' },
        EndorsedbyMNC: { value: '4', text: 'Endorsed by MNC' },
        RejectedbyMNC: { value: '5', text: 'Rejected by MNC' },
        RejectedbySubContractor: { value: '7', text: 'Rejected by Sub Contractor' },
        EndorsedbySubContractor: { value: '8', text: 'Endorsed by Sub Contractor' },
        RejectedbyMainContractor: { value: '9', text: 'Rejected by Main Contractor' },
        EndorsedbyMainContractor: { value: '10', text: 'Endorsed by Main Contractor' },
        RejectedbyMOM: { value: '11', text: 'Rejected by IPS Officer' },
        EndorsedbyMOM: { value: '12', text: 'Endorsed by IPS Officer' },
        Withdraw: { value: '13', text: 'Withdraw' },
        Resubmitted: { value: '6', text: 'Resubmitted' },
      },
      _testLocation: {
        atTestCentre: { value: 'At TestCentre', text: 'At TestCentre' },
        atWorkShop: { value: 'At Work Shop', text: 'At Work Shop' },
        atOther: { value: 'Others', text: 'Others' },
      },
      _testComponent: {
        practical: 'Practical',
        theory: 'Theory',
        both: 'Both'
      },
      _testApplicationStatus: {
        active: { value: 'A', text: 'Active' },
        rejected: { value: 'H', text: 'Rejected' },
        pending: { value: 'N', text: 'Pending' },
        modify: { value: 'O', text: 'Modify' },
        updated: { value: 'Q', text: 'Updated' },
        cancelled: { value: 'C', text: 'Cancelled' },
      },
      _testApplicationCandidateStatus: {
        active: { value: 'A', text: 'Active' },
        pass: { value: 'P', text: 'Passed' },
        fail: { value: 'F', text: 'Failed' },
        absent: { value: 'B', text: 'Absent' },
        pending: { value: 'N', text: 'Pending' },
      },
      _visualTest: {
        pass: { value: 'P', text: 'Passed' },
        fail: { value: 'F', text: 'Failed' }
      },
      _radiographTest: {
        pass: { value: 'P', text: 'Passed' },
        fail: { value: 'F', text: 'Failed' },
        notApplicable: { value: 'N', text: 'Not Applicable' }
      },
      _mechanicalTest: {
        pass: { value: 'P', text: 'Passed' },
        fail: { value: 'F', text: 'Failed' },
        notApplicable: { value: 'N', text: 'Not Applicable' }
      },
      _workerSkillTestResult: {
        pass: { value: 'P', text: 'Passed' },
        fail: { value: 'F', text: 'Failed' },
        absent: { value: 'B', text: 'Absent' },

      },
      _paymentTypes:{
        byChequeBankTransfer:'By Cheque/Bank Transfer',
        byGiro:'By Giro'
      },
      _invoiceStatus: {
        active: { value: 'A', text: 'Active' },
        paid: { value: 'P', text: 'Paid' },
        new: { value: 'N', text: 'New' },
        confirmed: { value: 'C', text: 'Confirmed' },
        giroProcessing: { value: 'G', text: 'Giro Processing' },
        giroError: { value: 'E', text: 'Giro Error' },
        badDebt: { value: 'B', text: 'Bad Debt' },
        chequeProcessing: { value: 'Q', text: 'Cheque Processing' },
        revert: { value: 'R', text: 'Revert' },
      },
      _assignType: {
        job: { value: 'J', text: 'Job' },
        resident: { value: 'R', text: 'Resident' },
        workshop: { value: 'W', text: 'Workshop' },
      },
      _assignTo: {
        industry: { value: 'I', text: 'Industry' },
        contractor: { value: 'C', text: 'Contractor' },
        // getText: (value) => {
        //   switch (value) {
        //     case industry.value: return industry.text;
        //     case contractor.value: return contractor.text;
        //     default: return '';
        //   }
        // }
      },
      _jobCategory: {
        all: { value: 'A', text: 'All' },
        construction: { value: 'C', text: 'Construction' },
        maintenance: { value: 'M', text: 'Maintenance' },
        shutdown: { value: 'S', text: 'Shutdown' },
      },
      _jointType: {
        plate: 'Plate',
        pipe: 'Pipe',
        steelStructure: 'Steel Structure'
      },
      _sectionResult: {
        accept: { value: 'A', text: 'Accept' },
        reject: { value: 'R', text: 'Reject' },
      },
      _workerAssignmentStatus: {
        active: { value: 'A', text: 'Active' },
        pending: { value: 'N', text: 'Pending' },
        inactive: { value: 'Z', text: 'Inactive' },
      },
      _rtReportType: {
        performanceWorksite: { value: 'PerformanceWorksite', text: 'Performance Update (Worksite)' },
        performanceWorkshopContractorTestCentre: { value: 'PerformanceWorkshopContractorTestCentre', text: 'Performance Update (Workshop at Contractor Or TestCentre)' },
        swsWelderQualifyingTest: { value: 'SwsWelderQualifyingTest', text: 'SWS-WQT (Welder Qualifying Test)' },
      },
    }
  },
  methods: {
    showMessage(message, type) {
      this.$toast.open({
        message: message,
        type: type,
        duration: 5000,
        dismissible: true,
        position: "top"
      })
    },
    showConfirmDiaglog(obj) {
      this.$store.commit('toggleConfirmDiaglog', obj);
    },
    setBreadcrumb(crumbs) {
      this.$store.commit('setBreadcrumb', crumbs);
    },
    clearBreadcrumb() {
      this.$store.commit('clearBreadcrumb');
    },
    calculateAge(dob) {
      var today = new Date();
      var yearDiff;
      var tmpArr;
      if (dob.indexOf('-') > 0) {
        tmpArr = dob.split('-');
      } else {
        tmpArr = dob.split('/');
      }
      var year = parseInt(tmpArr[2]);
      var month = parseInt(tmpArr[1]);
      var day = parseInt(tmpArr[0]);
      yearDiff = today.getFullYear() - year;

      if ((month > today.getMonth() + 1) || (month == today.getMonth() + 1 && day > today.getDate())) {
        yearDiff--;
      }

      return yearDiff;
    },
    convertDateObjToDateStr(date) {
      if (date == null)
        return "";

      var year = date.getFullYear();
      var mon = date.getMonth();
      mon = mon + 1;
      var day = date.getDate();

      year = "" + year;

      if (mon < 10) {
        mon = "0" + mon;
      } else {
        mon = "" + mon;
      }
      if (day < 10) {
        day = "0" + day;
      } else {
        day = "" + day;
      }

      var dateStr = day + "-" + mon + "-" + year;
      return dateStr;
    },
    convertDateStrToDateObj(dateStr) {
      let date = null;
      let arr = [];
      if (dateStr.indexOf('-') > 0) {
        arr = dateStr.split('-');
      } else {
        arr = dateStr.split('/');
      }
      if (arr.length > 2) {
        date = new Date(parseInt(arr[2]), parseInt(arr[1]) - 1, parseInt(arr[0]), 0, 0, 0);
      }
      return date;
    },
    convertDateTimeStrToDateObj(dateTimeStr) {
      let date = null;
      let arr = [];
      let dateStr = dateTimeStr.split(' ')[0];
      let timeStr = dateTimeStr.split(' ')[1];
      if (dateStr.indexOf('-') > 0) {
        arr = dateStr.split('-');
      } else {
        arr = dateStr.split('/');
      }
      if (arr.length > 2) {
        let times = timeStr.split(':');
        date = new Date(parseInt(arr[2]), parseInt(arr[1]) - 1, parseInt(arr[0]), times[0], times[1], 0);
      }
      return date;
    },
    getOrderStatusText(value) {
      switch (value) {
        case this._orderStatus.received.value: return this._orderStatus.received.text;
        case this._orderStatus.pendingForReport.value: return this._orderStatus.pendingForReport.text;
        case this._orderStatus.finalised.value: return this._orderStatus.finalised.text;
      }
    },
    dtoPaging() {
      return {
        currentPage: 1,
        totalRow: 0,
        rowPerPage: 50,
        orderBy: '',
        orderDir: '',
        startRow: 0,
        endRow: 0,
        totalPage: 0,

        searchText: '',
        startDate: '',
        endDate: '',
        listData: [],
        groupData: [],
        //Extra Filters
        clinicOid: 0,
        exceptPatientOid: 0,
        groupTableName: '',
        orderType: ''
      };
    },
    async getAddressByPostalCode(postalCode) {
      let url = 'https://developers.onemap.sg/commonapi/search?searchVal=' + postalCode + '&returnGeom=Y&getAddrDetails=Y&pageNum=1';
      let response = await axios.get(url);
      return response;
    },
    calculateAndGetFileSize(byte) {
      let val = byte;
      let txt = val + " B";
      if (val > 1024) {
        val = Math.round(val / 1024);
        txt = val + " KB";
      }
      if (val > 1024) {
        val = Math.round(val / 1024);
        txt = val + " MB";
      }
      if (val > 1024) {
        val = Math.round(val / 1024);
        txt = val + " GB";
      }
      return txt;
    },

    getCorporateTypeTextByValue(val) {
      console.log(val);
      console.log(val == this.$data._corporateType.testCenter.value);
      switch (val) {
        case this.$data._corporateType.industry.value: return this.$data._corporateType.industry.text;
        case this.$data._corporateType.contractor.value: return this.$data._corporateType.contractor.text;
        case this.$data._corporateType.testCenter.value: return this.$data._corporateType.testCenter.text;
        case this.$data._corporateType.admin.value: return this.$data._corporateType.admin.text;
        case this.$data._corporateType.sws.value: return this.$data._corporateType.sws.text;
        case this.$data._corporateType.mom.value: return this.$data._corporateType.mom.text;
        case this.$data._corporateType.weldingInspector.value: return this.$data._corporateType.weldingInspector.text;
        default: return '';
      }
    },
    getQuotaTextByValue(val) {
      switch (val) {
        case this.$data._quotaStatus.New.value: return this.$data._quotaStatus.New.text;
        case this.$data._quotaStatus.Pending.value: return this.$data._quotaStatus.Pending.text;
        case this.$data._quotaStatus.Submitted.value: return this.$data._quotaStatus.Submitted.text;
        case this.$data._quotaStatus.EndorsedbyMNC.value: return this.$data._quotaStatus.EndorsedbyMNC.text;
        case this.$data._quotaStatus.Resubmitted.value: return this.$data._quotaStatus.Resubmitted.text;
        case this.$data._quotaStatus.RejectedbySubContractor.value: return this.$data._quotaStatus.RejectedbySubContractor.text;
        case this.$data._quotaStatus.EndorsedbySubContractor.value: return this.$data._quotaStatus.EndorsedbySubContractor.text;
        case this.$data._quotaStatus.RejectedbyMainContractor.value: return this.$data._quotaStatus.RejectedbyMainContractor.text;
        case this.$data._quotaStatus.EndorsedbyMainContractor.value: return this.$data._quotaStatus.EndorsedbyMainContractor.text;
        case this.$data._quotaStatus.RejectedbyMNC.value: return this.$data._quotaStatus.RejectedbyMNC.text;
        case this.$data._quotaStatus.EndorsedbyMOM.value: return this.$data._quotaStatus.EndorsedbyMOM.text;
        case this.$data._quotaStatus.RejectedbyMOM.value: return this.$data._quotaStatus.RejectedbyMOM.text;
        case this.$data._quotaStatus.Withdraw.value: return this.$data._quotaStatus.Withdraw.text;
        default: return '';
      }
    },
    isValidNric(nric) {
      return checkNric.isValidNRIC(nric);
    },

    isValidWorkPermit(workpermit) {
      return checkWorkPermit.isValidWorkPermit(workpermit);
    },

    isValidWelderId(welderId) {
      let pass = false;
      var specialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

      if (welderId) {
        if (!specialChar.test(welderId)) {
          if (welderId.length > 5) {
            if (welderId.substring(0, 3) == 'WEL') {
              pass = true;
            }
          }
        }
      }
      return pass;
    },

    isValidEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    getLoggedInUser() {
      let currentUser = {};
      let currentUserStr = localStorage.getItem('currentUser');
      if (currentUserStr) {
        currentUser = JSON.parse(currentUserStr);
      }
      return currentUser;
    },

    getAuthorizedPermissions() {
      let permissionCodesObj = [];

      let currentUser = this.getLoggedInUser();
      if (currentUser.permissions) {
        permissionCodesObj = currentUser.permissions;
      }
      return permissionCodesObj;
    },

    isAuthorizedPermission(permissionCode) {
      return this.getAuthorizedPermissions().indexOf(permissionCode) > -1;
    },

    showLoadingScreen() {
      this.$store.commit('changeLoadingStatus', "show");
    },

    hideLoadingScreen() {
      this.$store.commit('changeLoadingStatus', "hide");
    },

    getJobCategoryTextByValue(val) {
      switch (val) {
        case this.$data._jobCategory.all.value: return this.$data._jobCategory.all.text;
        case this.$data._jobCategory.construction.value: return this.$data._jobCategory.construction.text;
        case this.$data._jobCategory.maintenance.value: return this.$data._jobCategory.maintenance.text;
        case this.$data._jobCategory.shutdown.value: return this.$data._jobCategory.shutdown.text;
        default: return '';
      }
    },
    getAssignTypeTextByValue(val) {
      switch (val) {
        case this.$data._assignType.job.value: return this.$data._assignType.job.text;
        case this.$data._assignType.resident.value: return this.$data._assignType.resident.text;
        case this.$data._assignType.workshop.value: return this.$data._assignType.workshop.text;
        default: return '';
      }
    },
    getSectionResultTextByValue(val) {
      switch (val) {
        case this.$data._sectionResult.accept.value: return this.$data._sectionResult.accept.text;
        case this.$data._sectionResult.reject.value: return this.$data._sectionResult.reject.text;
      }
    },
    getAssignmentStausTextByValue(val) {
      switch (val) {
        case this.$data._workerAssignmentStatus.active.value: return this.$data._workerAssignmentStatus.active.text;
        case this.$data._workerAssignmentStatus.pending.value: return this.$data._workerAssignmentStatus.pending.text;
        case this.$data._workerAssignmentStatus.inactive.value: return this.$data._workerAssignmentStatus.inactive.text;
      }
    },
    getWorkerSkillTestResultTextByValue(val) {
      switch (val) {
        case this.$data._workerSkillTestResult.pass.value: return this.$data._workerSkillTestResult.pass.text;
        case this.$data._workerSkillTestResult.fail.value: return this.$data._workerSkillTestResult.fail.text;
        case this.$data._workerSkillTestResult.absent.value: return this.$data._workerSkillTestResult.absent.text;
      }
    },
    getWorkerSkillTestStatusTextByValue(val) {
      switch (val) {
        case this.$data._workerSkillTestStatus.active.value: return this.$data._workerSkillTestStatus.active.text;
        case this.$data._workerSkillTestStatus.rejected.value: return this.$data._workerSkillTestStatus.rejected.text;
        case this.$data._workerSkillTestStatus.pending.value: return this.$data._workerSkillTestStatus.pending.text;
        case this.$data._workerSkillTestStatus.suspended.value: return this.$data._workerSkillTestStatus.suspended.text;
        case this.$data._workerSkillTestStatus.draft.value: return this.$data._workerSkillTestStatus.draft.text;
      }
    },

    getTestCandidateStatusTextByValue(val) {
      switch (val) {
        case this.$data._testApplicationCandidateStatus.active.value: return this.$data._testApplicationCandidateStatus.active.text;
        case this.$data._testApplicationCandidateStatus.pass.value: return this.$data._testApplicationCandidateStatus.pass.text;
        case this.$data._testApplicationCandidateStatus.fail.value: return this.$data._testApplicationCandidateStatus.fail.text;
        case this.$data._testApplicationCandidateStatus.absent.value: return this.$data._testApplicationCandidateStatus.absent.text;
        case this.$data._testApplicationCandidateStatus.pending.value: return this.$data._testApplicationCandidateStatus.pending.text;
      }
    },

    getTestCandidateStatusColor(val){
      switch (val) {
        case this.$data._testApplicationCandidateStatus.active.value: return 'bg-secondary';
        case this.$data._testApplicationCandidateStatus.pass.value: return 'bg-success';
        case this.$data._testApplicationCandidateStatus.fail.value: return 'bg-danger';
        case this.$data._testApplicationCandidateStatus.absent.value: return 'bg-warning';
        case this.$data._testApplicationCandidateStatus.pending.value: return 'bg-primary';
      }
    }

  }
}