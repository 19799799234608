<template>
  <div>
    <ActivateList :_type="$data._corporateType.testCenter.value" />
  </div>
</template>

<script>
import ActivateList from './ActivateList'
export default {
  components: {
    ActivateList
  },
  name: 'ActivateTestCenters'
}
</script>