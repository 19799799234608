<template>
  <div>
    <!-- {{nav.mainObject.permissionCode}} -->
    <li class="mb-1" v-if="nav.id && nav.mainObject.isMenu && ((!nav.mainObject.url && childObjects.length > 0) || (nav.mainObject.url && checkPermission(nav.mainObject.permissionCode)))">
      <router-link :to="nav.mainObject.url" v-if="nav.mainObject.url" class="btn btn-toggle align-items-center rounded collapsed text-white w-100 no-arrow">
        <i class="bi" :class="nav.mainObject.icon" style="margin-right:10px;"></i> {{nav.mainObject.displayName}}
      </router-link>
      <button v-else class="btn btn-toggle align-items-center rounded collapsed text-white w-100"
        data-bs-toggle="collapse" :data-bs-target="'#'+nav.id" data-bs-parent="#div_mainmenu" aria-expanded="false">
        <i class="bi" :class="nav.mainObject.icon" style="margin-right:10px;"></i> {{nav.mainObject.displayName}}
      </button>
      
      <div v-if="childObjects.length > 0" class="collapse" :id="nav.id">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1" style="padding-left:25px;">
          <li v-for="(i,idx) in childObjects" :key="idx">            
            <router-link :to="i.url" class="text-white rounded"><div @click="clickNav">{{i.displayName}}</div></router-link>
          </li>
        </ul>
      </div>
    </li>
  </div>
</template>

<style scoped src="@/assets/bootstrap5/sidebars.css">
</style>

<script>

export default {
  name: 'SideBarNavItem',
  props: {
    nav: Object,
    closeSideBar: { type: Function },
    isMobileSize: { type: Boolean },
  },
  data() {
    return {
      childObjects: []
    }
  },
  mounted() {
    if (this.nav.childObject && this.nav.childObject.length > 0)
      this.childObjects = this.nav.childObject.filter(x => x.isMenu && this.checkPermission(x.permissionCode));
  },
  methods: {
    clickNav() {
      if (this.isMobileSize) {
        this.closeSideBar();
      }
    },
    checkPermission(permissionCode) {
      let authorizedPermissions = this.getAuthorizedPermissions();
      if (authorizedPermissions.indexOf(permissionCode) > -1)
        return true;
      else
        return false;
    }
  }
}
</script>
