<template>
  <div>
    <div v-show="!isTransfer && !isTerminated">
      <h4 class="mb-2">Search A Worker / Enter Worker Information</h4>
      <div class="row mb-1">
        <div class="col-md-4">
          NRIC / FIN No. <span class="txt-small">(E.g. S1234567D / F1234567N)</span>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" v-model="nricfin" @keypress="keypress" />
        </div>
      </div>
      <div class="text-center mb-1">
        OR
      </div>
      <div class="row mb-4">
        <div class="col-md-4">
          Work Permit No. <span class="txt-small">(E.g. 0 12345678 9 /0 12345678)</span>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" v-model="workPermitNo" @keypress="keypress" />
        </div>
      </div>
      <div class="mb-2 txt-small">
        Sample 1<br />
        Work permit No: 0 12345678 9<br />
        Work permit format to key in: 0(space)12345678(space)9<br />
      </div>
      <div class="mb-2 txt-small">
        Sample 2<br />
        Work permit No: 0 1234567 9<br />
        Work permit format key in: 0(space)1234567-(space)9<br />
      </div>
      <div class="mb-2 txt-small">
        Sample 3<br />
        Work permit No: 0 12345678<br />
        Work permit format to key in: 0(space)12345678<br />
      </div>
      <div class="mb-2 txt-small">
        Sample 4<br />
        Work permit No: 0 1234567<br />
        Work permit format key in: 0(space)1234567-<br />
      </div>
      <div class="mb-2 txt-small">
        Note that (space) cannot be "-" or "_" and digit "8" can be "-"
      </div>
      <div class="row mb-4">
        <div class="col-md-4">
        </div>
        <div class="col-md-4 text-end">
          <button class="btn btn-primary" style="width:150px;" @click="clickEnter">Enter</button>
        </div>
      </div>
    </div>
    <div class="text-danger">
      {{msg}}
    </div>

    <div v-show="isTransfer">
      <h4 class="mb-2">Worker Transfer</h4>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Name
        </div>
        <div class="col-md-3">
          {{transferData.name}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          NRIC/FIN
        </div>
        <div class="col-md-3">
          {{transferData.nricFin}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Work Permit
        </div>
        <div class="col-md-3">
          {{transferData.workPermit}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Welder ID
        </div>
        <div class="col-md-3">
          {{transferData.welderId}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Employment Date
        </div>
        <div class="col-md-3">
          <DatePicker :availableDates="availableDates" @updatedate="changeTransferDate" :existing="employDateObj"
            :cssClass="errorTransferDate && 'control-error'" />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-6 text-end">
          <button class="btn btn-secondary me-1" @click="clickTransferBack">Back</button>
          <button v-show="showSubmitBtn" class="btn btn-primary me-1" @click="clickTransferSubmit(true)">Submit</button>
          <button class="btn btn-danger me-1" @click="clickTransferSubmit(false)">Reject</button>
        </div>
      </div>
    </div>

    <div v-show="isTerminated">
      <h4 class="mb-2">Terminated Worker</h4>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Name
        </div>
        <div class="col-md-3">
          {{terminatedData.name}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          NRIC/FIN
        </div>
        <div class="col-md-3">
          {{terminatedData.nricFin}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Work Permit
        </div>
        <div class="col-md-3">
          {{terminatedData.workPermit}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Welder ID
        </div>
        <div class="col-md-3">
          {{terminatedData.welderId}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-3 text-secondary">
          Employment Date
        </div>
        <div class="col-md-3">
          <DatePicker @updatedate="changeEmployDate" :exisintg="null" :cssClass="errorEmployDate && 'control-error'" />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-md-6 text-end">
          <button class="btn btn-secondary me-1" @click="clickReactiveBack">Back</button>
          <button class="btn btn-primary me-1" @click="clickReactiveSubmit">Submit</button>
        </div>
      </div>
    </div>
    <div v-show="isTransfer || isTerminated" class="text-primary">
      <span>Remarks:</span><br />
      <ul>
        <li>The employment date is set as default 7 days from the request date; the worker’s record will be released to
          the requesting contractor on the 7th day i.e., request date is on 22/8/2022, the worker’s record will be
          released on the 29/08/2022. </li>
        <li><u>ONLY</u> the approving contractor can amend the employment date. Hence, the approving contractor can amend the
          employment date to an earlier date i.e., 23/8/2022.</li>
        <li>If approving contractor does not take any action on the worker transfer request, the worker’s record will be
          auto-released to the requesting contractor on the 7th date from the request date; i.e., request date on
          22/8/2022, worker record will be released on 29/08/2022.</li>
      </ul>
      <table class="table table-bordered" style="width:800px;">
        <tbody class="text-primary">
          <tr>
            <td><b>22/8/22</b></td>
            <td>23/8/22</td>
            <td>24/8/22</td>
            <td>25/8/22</td>
            <td>26/8/22</td>
            <td>27/8/22</td>
            <td>28/8/22</td>
            <td><b>29/8/22</b></td>
          </tr>
          <tr>
            <td><b>Today</b></td>
            <td>Day 1</td>
            <td>Day 2</td>
            <td>Day 3</td>
            <td>Day 4</td>
            <td>Day 5</td>
            <td>Day 6</td>
            <td><b>Day 7</b></td>
          </tr>
           <tr>
            <td><b>Request Date</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Release Date</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'CheckWorkerToAdd',
  components: {
    ...Pickers
  },
  data() {
    return {
      nricfin: '',
      workPermitNo: '',
      msg: '',

      isTransfer: false,
      transferData: {},
      transferDate: null,
      errorTransferDate: false,
      showSubmitBtn: true,

      availableDates: {},

      isTerminated: false,
      terminatedData: {},
      employDate: null,
      errorEmployDate: false,
      employDateObj: null,
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + window.appSetting.workerTransferMinDays);
    let maxDate = new Date(minDate);
    maxDate.setDate(maxDate.getDate() + window.appSetting.workerTransferMaxDays);
    this.availableDates = { start: minDate, end: maxDate };
    this.employDateObj = minDate;
    this.changeTransferDate(this.employDateObj);
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Administration' }
    ]);
  },
  methods: {
    clickEnter() {
      this.msg = '';
      if (this.nricfin) {
        if (!this.isValidNric(this.nricfin)) {
          this.showMessage("Invalid NRIC/FIN.", "warning");
          return;
        }
      }
      if (this.workPermitNo) {
        if (!this.isValidWorkPermit(this.workPermitNo)) {
          this.showMessage("Invalid work permit no.", "warning");
          return;
        }
      }
      let text = this.nricfin ? this.nricfin : this.workPermitNo;

      if (!text) {
        this.showMessage(window.message.worker.nricfinOrWorkPermitNo_required, 'warning');
        return;
      }

      this.showLoadingScreen();
      api.regularGet(apiUrl.worker.checkNricFinOrWorkPermit + "?text=" + text, null).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          if (response.data.isSuccess) {
            if (response.data.data.isEdit) {
              this.$router.push({ name: 'AddEditWorker', params: { id: response.data.data.oid,corpName: response.data.data.corporateName, nricFin: this.nricfin, workPermitNo: this.workPermitNo, at: response.data.data.isExistingAlreadyTerminated  } });
            }
            else {
              this.$router.push({ name: 'AddEditWorker', params: { corpName: response.data.data.corporateName, nricFin: this.nricfin, workPermitNo: this.workPermitNo, } });
            }
          }
          else {
            this.msg = response.data.message;
            if (response.data.data.isTransfer) {
              this.isTransfer = true;
              this.transferData = response.data.data;
              if (this.transferData.canTransfer) {
                this.showSubmitBtn = true;
              }
              else {
                this.showSubmitBtn = false;
              }
            }
            else if (response.data.data.isExistingAlreadyTerminated) {
              this.isTerminated = true;
              this.terminatedData = response.data.data;
            }
          }
        }
      });

    },
    keypress(e) {
      //Enter
      if (e.keyCode == 13) {
        this.clickEnter();
      }
    },
    changeTransferDate(date) {
      this.transferDate = this.convertDateObjToDateStr(date);
    },
    clickTransferSubmit(isConfirm) {
      let isValid = true;
      this.errorTransferDate = false;

      if (!this.transferDate) {
        this.errorTransferDate = true;
        isValid = false
      }

      if (isValid) {
        let data = [{
          transferRequestOid: this.transferData.transferRequestOid,
          employDate: this.transferDate,
          isConfirm: isConfirm
        }];
        this.showLoadingScreen();
        api.regularPost(apiUrl.worker.confirmWorkerTransferRequest, data).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            if (isConfirm)
              this.showMessage("Successfully requested to transfer.", "success");
            else
              this.showMessage("Successfully rejected the transfer.", "success");
            this.clickTransferBack();
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });
      }

    },
    clickTransferBack() {
      this.isTransfer = false;
      this.showSubmitBtn = true;
    },


    changeEmployDate(date) {
      this.employDate = this.convertDateObjToDateStr(date);
    },
    clickReactiveSubmit() {
      let isValid = true;
      this.errorEmployDate = false;

      if (!this.employDate) {
        this.errorEmployDate = true;
        isValid = false
      }

      if (isValid) {
        let data = {
          workerOid: this.terminatedData.oid,
          employDate: this.employDate
        };
        this.showLoadingScreen();
        api.regularPost(apiUrl.worker.reactiveTheTerminatedWorker, data).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.showMessage("Successfully updated.", "success");
            this.clickReactiveBack();
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });
      }
    },
    clickReactiveBack() {
      this.isTerminated = false;
    },


  }
}
</script>
