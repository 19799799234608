<template>
  <div>

    <!-- <ModalDiaglog :show="showAssignmentDetailsDiaglog" size="xl" :onClose="()=>{showAssignmentDetailsDiaglog=false}" title="Worker Assignment Details"> -->
      <div v-show="showAssignmentDetailsDiaglog" class="mb-4" style="padding:5px;border:1px solid gray;">
        <div class="text-end" style="margin-bottom: -15px;">
          <button class="btn btn-sm btn-secondary" @click="showAssignmentDetailsDiaglog=false">Close</button>
        </div>
        <strong>Worksite Summary</strong>
        <table class="table table-sm mb-3">
          <thead>
            <tr>
              <th>Industry</th>
              <th>Location</th>
              <th>Contractor</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Shift</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d,i) in worksiteSummary" :key="i">
              <td>{{d.industry}}</td>
              <td>{{d.location}}</td>
              <td>{{d.contractor}}</td>
              <td>{{d.startDate}}</td>
              <td>{{d.endDate}}</td>
              <td>
                <span v-if="d.morning">M </span>
                <span v-if="d.afternoon">A </span>
                <span v-if="d.night">N </span>
              </td>
              <td>{{d.statusDisplay}}</td>
            </tr>
          </tbody>
        </table>
        <strong>Workshop Summary</strong>
        <div style="overflow-x: auto;">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Accessing Inspector</th>
              <th>Industry/Test Centre/Contractor</th>
              <th>StartDate</th>
              <th>EndDate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d,i) in workshopSummary" :key="i">
              <td>{{d.accessingInspector}}</td>
              <td>{{d.industry}}<span v-if="d.industry && d.contractor">/</span>{{d.contractor}}</td>
              <td>{{d.startDate}}</td>
              <td>{{d.endDate}}</td>
            </tr>
          </tbody>
        </table>
         </div>
        
        <strong>Employment Summary</strong>
        <table class="table">
          <thead>
            <tr>
              <th>Contractor Name</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(e,i) in employmentHistory" :key="i">
              <td>{{e.corporateName}}</td>
              <td>{{e.commenceDateDisplay}}</td>
              <td>{{e.terminatedDateDisplay}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    <!-- </ModalDiaglog> -->
    
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Welder Ref<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <input type="text" v-model="dataObj.welderId" class="form-control" :class="error.welderId && 'control-error'" />
          <button class="btn btn-secondary btn-sm" @click="checkWelder">Check</button>
        </div>
        <a href="javascript:;" @click="checkAssignmentDeatils">Check Assignment Details</a>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Name
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.name" class="form-control" :class="error.name && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        NRIC/FIN<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.nricFin" class="form-control" :class="error.nricFin && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test No<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <v-select label="testNumber" :class="error.testNumber && 'control-error'" :multiple="false" v-model="dataObj.testNumber"
              :options="testNumbers" @input="changeTestNo"></v-select>
      </div>
      <div class="col-md-3 text-secondary">
        Joint Type
      </div>
      <div class="col-md-3">
        <FormSelect :list="jointTypes" value="value" text="text" @change="dataObj.jointType=$event" 
        :cssClass="error.jointType && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Weld Process
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.process" class="form-control" :class="error.process && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Position
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.testPosition" class="form-control" :class="error.testPosition && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Weld/Joint ID
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.weldId" class="form-control" :class="error.weldId && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        S.F.D<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.sfd" class="form-control" :class="error.sfd && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Wall Thicknes<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.wallThickness" class="form-control" :class="error.wallThickness && 'control-error'" />
      </div>
      <div v-if="isShowRdoLength" class="col-md-3 text-secondary">
        Radiography Length<span class="text-danger">*</span>
      </div>
      <div v-if="isShowRdoLength" class="col-md-3">
        <div class="input-group">
          <input type="number" v-model="dataObj.radiographyLength" class="form-control" :class="error.radiographyLength && 'control-error'" />
          <span class="input-group-text">mm</span>
        </div>
      </div>
      <div v-if="!isShowRdoLength" class="col-md-3 text-secondary">
        Diameter<span class="text-danger">*</span>
      </div>
      <div v-if="!isShowRdoLength" class="col-md-3">
        <div class="input-group">
          <input type="number" v-model="dataObj.diameter" class="form-control" :class="error.diameter && 'control-error'" />
          <span class="input-group-text">mm</span>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Material<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.material" class="form-control" :class="error.material && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        NPS
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.nps" class="form-control" :class="error.nps && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Length of Plate/Steel Structure
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.plateSize" class="form-control" :class="error.plateSize && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Equip/Line No
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.equipNo" class="form-control" :class="error.equipNo && 'control-error'" />
      </div>
    </div>
    <hr/>
    
    <div class="row mb-2">
      <div class="col-md-6 text-secondary">
        Sections<span class="text-danger">*</span>
      </div>
       <div class="col-md-6 text-end">
        <button class="btn btn-sm btn-primary" @click="clickAddSection">Add Section</button>
      </div>
    </div>

    <div class="card mb-2" v-for="(s,i) in dataObj.sections" :key="i">
      <div class="card-body">
        <div class="text-end">
          <button class="btn btn-sm btn-danger" @click="clickRemoveSection(s.tempId, s.oid)"><i class="bi bi-trash"></i></button>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="text-secondary">Section No<span class="text-danger">*</span></div>
            <div>
              <input type="text" v-model="s.sectionNo" class="form-control" :class="s.errorSectionNo && 'control-error'" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-secondary">Discontinuity Size<span class="text-danger">*</span></div>
            <div class="input-group">
              <input type="number" v-model="s.rejectLength" class="form-control" :class="s.errorRejectLength && 'control-error'" />
              <span class="input-group-text">mm</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-secondary">Result<span class="text-danger">*</span></div>
            <div>
              <FormSelect :list="sectionResults" value="value" text="text" @change="s.result=$event" 
                :cssClass="s.errorResult && 'control-error'" :existing="s.result" />
            </div>
          </div>
        </div>
        <div>
          <div class="text-secondary">Remark<span class="text-danger">*</span></div>
          <div class="row">
            <div class="col-md-2" v-for="(r,idx) in remarks" :key="idx">
              <label class="hover"><input type="checkbox" @change="changeRemarkCheckbox(r, s, $event)" :checked="checkRemarkSelected(r, s)" /> {{r.performanceRemarkShort}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <span class="text-danger">* Denotes Mandatory Field</span>
    </div>

    <div class="text-end">
      <button class="btn btn-secondary me-1" @click="clickCancel">Cancel</button>
      <button class="btn btn-success" @click="clickSave">
        <span v-if="isEdit">Update</span>
        <span v-else>Add</span>
      </button>
    </div>

  </div>
</template>
<script>

import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
// import ModalDiaglog from '@/components/custom/ModalDiaglog';

export default {
  name: 'AddEditRTReportWelder.vue',
  props: {
    rtReportOid: Number,
    dataObj: Object,
    isEdit: Boolean,
    reportType: String,
  },
  components: {
    ...Forms,
    // ModalDiaglog,
  },
  data() {
    return {
      error: {},
      workerOid: null,
      isShowRdoLength: true,

      allTestNumbers: [],
      testNumbers: [],
      jointTypes: [],
      sectionResults: [],
      remarks: [],

      showAssignmentDetailsDiaglog: false,

      worksiteSummary: [],
      workshopSummary: [],
      employmentHistory: [],
    }
  },
  mounted() {

    api.regularGet(apiUrl.workerSkill.getAllWeldingSkillTests, null).then((response) => {
      this.allTestNumbers = response.data.map(x => x.testNumber);
      this.testNumbers = this.allTestNumbers;
    });

    api.regularGet(apiUrl.performance.getAllPerformanceRemarks, null).then((response) => {
      this.remarks = response.data;
    });

    this.jointTypes.push({ value: '', text: '<None>' });
    this.jointTypes.push({ value: this.$data._jointType.plate, text: this.$data._jointType.plate });
    this.jointTypes.push({ value: this.$data._jointType.pipe, text: this.$data._jointType.pipe });
    this.jointTypes.push({ value: this.$data._jointType.steelStructure, text: this.$data._jointType.steelStructure });

    this.sectionResults.push({ value: this.$data._sectionResult.accept.value, text: this.$data._sectionResult.accept.text });
    this.sectionResults.push({ value: this.$data._sectionResult.reject.value, text: this.$data._sectionResult.reject.text });

    this.checkRadioLengthOrDiameter(this.dataObj.testNumber);

  },

  methods: {
    clickAddSection() {
      let tempId = 1;
      if (this.dataObj.sections.length > 0) {
        let tempIds = this.dataObj.sections.filter(x => x.tempId).map(x => x.tempId);
        if (tempIds.length > 0) {
          tempId = Math.max(...tempIds) + 1;
        }
      }
      this.dataObj.sections.push({
        tempId: tempId,
        sectionNo: '',
        rejectLength: '',
        result: this.$data._sectionResult.accept.value,
        remarkOids: []
      });
    },
    clickRemoveSection(tempId, oid) {
      let idx = -1;
      if (tempId) {
        for (let i = 0; i < this.dataObj.sections.length; i++) {
          if (this.dataObj.sections[i].tempId == tempId) {
            idx = i;
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.dataObj.sections.length; i++) {
          if (this.dataObj.sections[i].oid == oid) {
            idx = i;
            break;
          }
        }
      }
      if (idx > -1)
        this.dataObj.sections.splice(idx, 1);
    },
    changeRemarkCheckbox(remark, section, e) {
      let idx = section.remarkOids.indexOf(remark.oid);
      if (e.target.checked) {
        if (idx == -1) {
          section.remarkOids.push(remark.oid);
        }
      }
      else {
        if (idx > -1) {
          section.remarkOids.splice(idx, 1);
        }
      }
    },
    clickCancel() {
      this.$emit('onCancel');
    },
    clickSave() {
      let isValid = this.validate();
      if (isValid) {
        this.dataObj.performanceWelderReportOid = this.rtReportOid;

        //With Radiography Length
        if (this.isShowRdoLength) {
          this.dataObj.diameter = 0;
        }
        //With Diameter
        else {
          this.dataObj.radiographyLength = 0;
        }

        let url = apiUrl.performance.insertRTReportWeld;
        if (this.isEdit)
          url = apiUrl.performance.updateRTReportWeld;

        this.showLoadingScreen();
        api.regularPost(url, this.dataObj).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            if (this.isEdit) {
              this.showMessage("Successfully Updated worker.", "success");
            }
            else {
              this.showMessage("Successfully Added worker.", "success");
            }
            this.$emit('onSubmit');
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });

      }
    },
    validate() {
      let isValid = true;
      let msg = "";
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.welderId) { this.error.welderId = true; isValid = false; }
      if (!this.dataObj.testNumber) { this.error.testNumber = true; isValid = false; }
      if (!this.dataObj.nricFin) { this.error.nricFin = true; isValid = false; }
      if (!this.dataObj.wallThickness) { this.error.wallThickness = true; isValid = false; }

      //With Radiography Length
      if (this.isShowRdoLength) {
        if (!this.dataObj.radiographyLength || this.dataObj.radiographyLength <= 0) { this.error.radiographyLength = true; isValid = false; }
      }
      //With Diameter
      else {
        if (!this.dataObj.diameter || this.dataObj.diameter <= 0) { this.error.diameter = true; isValid = false; }
      }
      if (!this.dataObj.material) { this.error.material = true; isValid = false; }
      if (!this.dataObj.sfd) { this.error.sfd = true; isValid = false; }

      if (this.dataObj.sections.length == 0) {
        isValid = false;
        msg = "Please add section.";
      }
      else {
        this.dataObj.sections.forEach(x => {
          x.errorSectionNo = false;
          x.errorRejectLength = false;
          x.errorResult = false;

          if (!x.sectionNo) { x.errorSectionNo = true; isValid = false; }
          if (!x.rejectLength) { x.errorRejectLength = true; isValid = false; }
          if (!x.result) { x.errorResult = true; isValid = false; }
          if (x.remarkOids.length == 0) { msg = "Please select Remarks."; isValid = false; }
        })
      }

      this.$forceUpdate();
      if (!isValid) {
        if (msg)
          this.showMessage(msg, "warning");
        else
          this.showMessage("Please input mandatory fields.", "warning");
      }
      return isValid;
    },

    checkRemarkSelected(remark, section) {
      if (section.remarkOids && section.remarkOids.length > 0) {
        if (section.remarkOids.indexOf(remark.oid) > -1) {
          return true;
        }
      }
      return false;
    },

    checkWelder() {
      this.showLoadingScreen();
      if (!this.dataObj.welderId) {
        this.hideLoadingScreen();
        this.showMessage('Please enter Welder Id first.', "warning");
        return;
      }
      api.regularGet(apiUrl.performance.getWorkerByWelderId, { welderId: this.dataObj.welderId }).then((res) => {
        this.hideLoadingScreen();
        console.log(res.data);
        if (res.data) {
          this.workerOid = res.data.oid;
          this.dataObj.name = res.data.name;
          this.dataObj.nricFin = res.data.nricFin;

          if (this.reportType == this.$data._rtReportType.swsWelderQualifyingTest.value) {
            this.testNumbers = this.allTestNumbers;
          }
          else {
            this.testNumbers = res.data.activeSkillTestNumbers;
          }

          this.$forceUpdate();
        }
        else {
          this.showMessage("Welder not found!", "warning");
        }
      });
    },

    checkAssignmentDeatils() {
      if (this.workerOid) {

        this.showAssignmentDetailsDiaglog = true;

        api.regularGet(apiUrl.worker.workerEmployHistory + '/' + this.workerOid, null).then((response) => {
          this.employmentHistory = response.data;
        });

        api.regularGet(apiUrl.worker.getWorksiteSummary, { workerOid: this.workerOid }).then((response) => {
          this.worksiteSummary = response.data;
        });

        api.regularGet(apiUrl.worker.getWorkshopSummary, { workerOid: this.workerOid }).then((response) => {
          this.workshopSummary = response.data;
        });
      }
      else {
        this.showMessage('Please click "Check" welder id first.', "warning");
      }
    },

    changeTestNo(testNo) {
      this.checkRadioLengthOrDiameter(testNo);
    },

    checkRadioLengthOrDiameter(testNo) {
      if (testNo && testNo[0].toUpperCase() == 'P') {
        this.isShowRdoLength = false;
      }
      else {
        this.isShowRdoLength = true;
      }
    },

  }
}
</script>
