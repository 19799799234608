<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Current Password:<span class="text-danger">*</span></div>
              <div class="col-sm-6">
                <input
                  type="password"
                  class="form-control"
                  v-model="currentPassword"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">New Password:<span class="text-danger">*</span></div>
              <div class="col-sm-6">
                <input
                  type="password"
                  class="form-control"
                  v-model="newPassword"
                  maxlength="20"
                  placeholder="(max 20 characters)"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">New Password Confirm:<span class="text-danger">*</span></div>
              <div class="col-sm-6">
                <input
                  type="password"
                  class="form-control"
                  v-model="newPasswordConfirm"
                  placeholder="(max 20 characters)"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-success m-1 float-end" @click="clickSave">
          Update Password
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import apiUrl from "@/common/apiurl";
import api from "@/common/api";

export default {
  name: "Password",

  components: {},

  props: {},

  data() {
    return {
      oid: 0,
      currentPassword: "",
      newPasswordConfirm: "",
      newPassword: "",
      dataObj: {
        currentPassword: "",
        newPassword: ""
      },
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {

  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Password' }
    ]);
  },

  methods: {

    clickSave() {
      let msg = this.validate();
      if (msg) {
        this.showMessage(msg, "warning");
        return;
      }

      let subUrl = "";
      subUrl = apiUrl.users.saveUserPassword;
      this.dataObj.newPassword = this.newPassword;
      this.dataObj.currentPassword = this.currentPassword;
      api
        .regularPost(subUrl, this.dataObj, this.$store.state.token)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.isSuccess)
              this.showMessage("Successfully saved.", "success");
            else
              this.showMessage(response.data.message, "error");
          } else if (
            response.status == 400 ||
            response.errorText.response.status == 400
          ) {
            this.showMessage(response.errorText.response.data.message, "error");
          } else {
            this.showMessage(window.message.unable_proceed, "error");
          }
        });
    },
    validate() {
      let msg = "";
      if (!this.currentPassword) msg += window.message.users.current_password_blank;
      if (this.newPassword.length < 6) {
        msg += window.message.users.new_password_short;
      }
      if (this.newPasswordConfirm.length < 1) msg += window.message.users.password_confirm_blank;
      if ((this.newPassword && this.newPasswordConfirm) && (this.newPassword != this.newPasswordConfirm))
        msg += window.message.users.password_not_match;
      return msg;
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    showAudit() {
      this.showAuditDialog(true);
    },
  },
};
</script>
