<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterName" />
              </div>
            </div>
            <!-- <div class="row mb-2">
              <div class="col-sm-6">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text" @change="changeFilterStatus" />
              </div>
            </div> -->
          </div>
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                Corporation
              </div>
              <div class="col-sm-6">
                <FormSelect  :list="corporates" value="oid" text="corpName" :existing="tableParam.corp" @change="changeFilterStatus"/>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="corporate" colGroup="tbl1">Corporate</SortColumn>
          <SortColumn @click="sortColumn" sortBy="designation" colGroup="tbl1">Designation</SortColumn>
          <SortColumn @click="sortColumn" sortBy="telephone" colGroup="tbl1">Telephone</SortColumn>
          <SortColumn @click="sortColumn" sortBy="email" colGroup="tbl1">Email</SortColumn>
          <SortColumn @click="sortColumn" sortBy="createdDate" colGroup="tbl1">Submitted Date</SortColumn>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.name}}</td>
          <td>{{i.corporName}}</td>
          <td>{{i.designation}}</td>
          <td>{{i.telephone}}</td>
          <td>{{i.email}}</td>
          <td>{{i.createdDate_String}}</td>
          <td><button class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button></td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import _ from "lodash";

export default {
  name: 'ActivateWeldingInspectors',
  components: {
    ...TableAddons,
    ...Forms
  },
  props:{
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      corporates:[],
    }
  },
  beforeMount(){
    
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
     this.setBreadcrumb([
      { name: this.getCorporateTypeTextByValue(this.$data._corporateType.weldingInspector.value) }
    ]);
    this.tableParam.type = this.$data._corporateType.weldingInspector.value;
    this.getDropdowns();
    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];

    this.tableParam.status = "";
    this.tableParam.designation = "";
    this.callTable();
  },
  methods: {
    callTable() {
      this.tableParam.listData = [];
      this.showLoadingScreen();
      api.regularPost(apiUrl.activateweldinginspector.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'Activate Welding Inspector', params: { id: data.oid, type: this.$data._corporateType.weldingInspector.value } });
    },
    getDropdowns() {
      let url = apiUrl.regWeldingInspector.dropdowns
      api.regularGet(url, null).then((response) => {
        let dropdowns = response.data;
        this.corporates = dropdowns.corporates;
        this.qualifications = dropdowns.qualifications;
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    // changeTblFilter(text){
    //   this.tableParam.searchtext = text;
    //   this.callTable();
    // },

    changeFilterName: _.debounce(function (e) {
      this.tableParam.name = e.target.value;
      this.callTable();
    }, 500),

    changeFilterDesignation(e) {
      this.tableParam.designation = e;
      this.callTable();
    },

    changeFilterStatus(e) {
      this.tableParam.corp = e;
      this.callTable();
    }

  }
}
</script>
