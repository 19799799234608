<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4>Corporate Information</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Corporate Name:
              </div>
              <div class="col-sm-6">
                 <label class="control-label">{{dataObj.corpName}}</label>
               
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Building Name:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.buildingName && 'control-error'" v-model="dataObj.buildingName" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Block No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.blkNo && 'control-error'" v-model="dataObj.blkNo" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Unit No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.unitNo && 'control-error'" v-model="dataObj.unitNo" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Address:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.address1 && 'control-error'" v-model="dataObj.address1" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Address 2:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.address2 && 'control-error'" v-model="dataObj.address2" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Country:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="countries" value="oid" text="name" :cssClass="error.countryOid && 'control-error'"
                :existing="dataObj.countryOid" @change="dataObj.countryOid=$event" />
              </div>
            </div>            
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Postcode:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.postalCode && 'control-error'" v-model="dataObj.postalCode" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Administrator Name:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.adminContact && 'control-error'" v-model="dataObj.adminContact" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Administrator Designation:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.adminDesignation && 'control-error'" v-model="dataObj.adminDesignation" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Telephone No.:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <input type="text" class="form-control" maxlength="8" :class="error.telephone && 'control-error'" v-model="dataObj.telephone" />
                  <span class="input-group-text">Ext</span>
                  <input type="text" class="form-control" maxlength="5" :class="error.ext && 'control-error'" v-model="dataObj.ext" />
                </div>                
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Fax No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" maxlength="8" v-model="dataObj.fax" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Email:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" :class="error.corporateEmail && 'control-error'" class="form-control" maxlength="50" v-model="dataObj.corporateEmail" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Mobile Contact No.:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" :class="error.mobileNo && 'control-error'" class="form-control" maxlength="10" v-model="dataObj.mobileNo" />
              </div>
            </div>            
          </div>
        </div>
         <h4>Other Information (For Quota Application Only)</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Contact Person Name:
              </div>
              <div class="col-sm-6">
                 <input type="text" class="form-control" :class="error.buildingName && 'control-error'" v-model="dataObj.quotaContactPersonName" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Contact Person Designation:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.quotaContactPersonDesignation && 'control-error'" v-model="dataObj.quotaContactPersonDesignation" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Email Address:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.quotaEmail && 'control-error'" v-model="dataObj.quotaEmail" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Telephone:
              </div>
              <div class="col-sm-6">
                <input type="text" maxlength="8" class="form-control" :class="error.quotaTelephone && 'control-error'" v-model="dataObj.quotaTelephone" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Fax:
              </div>
              <div class="col-sm-6">
                <input type="text" maxlength="8" class="form-control" :class="error.quotaFax && 'control-error'" v-model="dataObj.quotaFax" />
              </div>
            </div>     
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                CPF Account No.:
              </div>
              <div class="col-sm-6">
                <input type="text" maxlength="20" class="form-control" :class="error.cpfAccountNo && 'control-error'" v-model="dataObj.cpfAccountNo" />
              </div>
            </div>     
          </div>
        </div>


        <span class="text-danger small">* Denotes Mandatory Field</span>

        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <!-- <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button> -->
      </div>
    </div>

    
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'CorporateProfile',

  components: {
    ...Forms
  },

  props: {
    type: String
  },

  data() {
    return {
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      countries: [],
      ownerships: [],
      sac: [],
      statuses: [],
      parentUrl: '',
      error: {},
      isContractor: false,
      isTestCentreCwqs: false,
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {

    //breadcrumbs
    this.setBreadcrumb([
      { name: 'Corporate Profile' }
    ]);


    api.regularGet(apiUrl.regIndustry.dropdowns, null).then((response) => {
      this.countries = response.data.countries;
      this.ownerships = response.data.ownerships;

      if (this.oid > 0) {
        this.getData();
      }
    });

  },

  methods: {
    getData() {
      let url = apiUrl.corporate.corpProfile + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;

        switch (this.dataObj.type) {
          case this.$data._corporateType.industry.value: {
            this.parentUrl = 'Industries';
            break;
          }
          case this.$data._corporateType.contractor.value: {
            this.parentUrl = 'Contractors';
            this.isContractor = true;
            break;
          }
          case this.$data._corporateType.testCenter.value: {
            this.parentUrl = 'TestCentreCWQS';
            this.isTestCentreCwqs = true;
            break;
          }
        }
      });
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = apiUrl.corporate.updateProfile;

      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "Dashboard" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "Dashboard" });
    },
    validate() {
      let isValid = true;
       Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.address1){
        this.error.address1 = true; 
        isValid = false;
        console.log(1);
      }
      if (!this.dataObj.countryOid){
        this.error.countryOid = true; 
        isValid = false;
        console.log(1);
      }
      if (!this.dataObj.postalCode){
        this.error.postalCode = true; 
        isValid = false;
        console.log(1);
      }
      if (!this.dataObj.adminContact){
        this.error.adminContact = true; 
        isValid = false;
        console.log(1);
      }
      if (!this.dataObj.adminDesignation){
        this.error.adminDesignation = true; 
        isValid = false;
        console.log(1);
      }
      if (!this.dataObj.telephone){
        this.error.telephone = true;  
        isValid = false;
        console.log(1);
      }
      if (!this.dataObj.corporateEmail){
       this.error.corporateEmail = true; 
        isValid = false;
        console.log(1);
      }
      if(!this.dataObj.mobileNo){
        this.error.mobileNo = true; 
        isValid = false;
        console.log(1);
      }
      console.log(isValid);
      this.$forceUpdate();
      return isValid;
    },
  }
}
</script>
