export default {
  /**
   *
   * Function Name: isValidNRIC
   * Input        : NRIC/FIN(String)
   * Output       : True/False
   * Purpose      : Checks whether NRIC/FIN is accroding to SIR algorithm or not.
   *
   */
  isValidNRIC(nric) {
    let pass = true;
    let num1 = 0;
    let num2 = 0;
    let num3 = 0;
    let num4 = 0;
    let num5 = 0;
    let num6 = 0;
    let num7 = 0;
    let prefix = 'A';

    if (nric != null) {
      if (nric.length == 9) {
        prefix = nric.toUpperCase().charAt(0);

        try {
          num1 = parseInt(nric.substring(1, 2));
        }
        catch (ne) {
          pass = false;
        }

        if (pass) {
          try {
            num2 = parseInt(nric.substring(2, 3));
          }
          catch (ne) {
            pass = false;
          }
        }

        if (pass) {
          try {
            num3 = parseInt(nric.substring(3, 4));
          }
          catch (ne) {
            pass = false;
          }
        }

        if (pass) {
          try {
            num4 = parseInt(nric.substring(4, 5));
          }
          catch (ne) {
            pass = false;
          }
        }

        if (pass) {
          try {
            num5 = parseInt(nric.substring(5, 6));
          }
          catch (ne) {
            pass = false;
          }
        }

        if (pass) {
          try {
            num6 = parseInt(nric.substring(6, 7));
          }
          catch (ne) {
            pass = false;
          }
        }

        if (pass) {
          try {
            num7 = parseInt(nric.substring(7, 8));
          }
          catch (ne) {
            pass = false;
          }
        }

        if (pass) {
          let lastalpha = nric.toUpperCase().charAt(8);

          let numa = num1 * 2;
          let numb = num2 * 7;
          let numc = num3 * 6;
          let numd = num4 * 5;
          let nume = num5 * 4;
          let numf = num6 * 3;
          let numg = num7 * 2;

          let totalnum = numa + numb + numc + numd + nume + numf + numg;

          if ((prefix == 'T') || (prefix == 'G')) {
            totalnum = totalnum + 4;
          }
          else if (prefix == 'M') {
            totalnum = totalnum + 3;
          }

          let remainder = totalnum % 11;
          let finalnum = 11 - remainder;

          switch (finalnum) {
            case 1:
              {
                if ((lastalpha == 'A') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'K') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 2:
              {
                if ((lastalpha == 'B') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'L') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 3:
              {
                if ((lastalpha == 'C') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'M') && ((prefix == 'F') || (prefix == 'G'))) {
                    return true;
                  }
                  else if ((lastalpha == 'J') && (prefix == 'M')) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 4:
              {
                if ((lastalpha == 'D') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'N') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 5:
              {
                if ((lastalpha == 'E') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'P') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 6:
              {
                if ((lastalpha == 'F') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'Q') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 7:
              {
                if ((lastalpha == 'G') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'R') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 8:
              {
                if ((lastalpha == 'H') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'T') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 9:
              {
                if ((lastalpha == 'I') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'U') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 10:
              {
                if ((lastalpha == 'Z') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'W') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
            case 11:
              {
                if ((lastalpha == 'J') && ((prefix == 'S') || (prefix == 'T'))) {
                  return true;
                }
                else {
                  if ((lastalpha == 'X') && ((prefix == 'F') || (prefix == 'G') || (prefix == 'M'))) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              }
          }
          return false;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  },

  /**
   *
   * Function Name: isSingaporean
   * Input        : NRIC/FIN(String)
   * Output       : True/False
   * Purpose      : Checks whether NRIC/FIN is Singaporean or not
   *
   */
  isSingaporean(nric) {
    let firstChar;

    if (!nric.equals("")) {
      firstChar = nric.toUpperCase().charAt(0);

      if (firstChar == ('S') || firstChar == ('T')) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  },

  /**
   *
   * Function Name: getNRICCheckDigit
   * Input        : NRIC/FIN without check-digit(String)
   * Output       : Check-Digit(Char)
   * Purpose      : Gets Check-Digit fo rNRIC/FIN entered
   *
   */
  // getNRICCheckDigit(nric) {
  //   let pass = true;
  //   let num1 = 0;
  //   let num2 = 0;
  //   let num3 = 0;
  //   let num4 = 0;
  //   let num5 = 0;
  //   let num6 = 0;
  //   let num7 = 0;
  //   let prefix = 'A';

  //   if (nric != null) {
  //     if (nric.length == 8) {
  //       prefix = nric.toUpperCase().charAt(0);

  //       try { num1 = Integer.parseInt(nric.substring(1, 2)); }
  //       catch (ne)
  //       { pass = false; }

  //       if (pass) {
  //         try { num2 = Integer.parseInt(nric.substring(2, 3)); }
  //         catch (ne)
  //         { pass = false; }
  //       }

  //       if (pass) {
  //         try { num3 = Integer.parseInt(nric.substring(3, 4)); }
  //         catch (ne)
  //         { pass = false; }
  //       }

  //       if (pass) {
  //         try { num4 = Integer.parseInt(nric.substring(4, 5)); }
  //         catch (ne)
  //         { pass = false; }
  //       }

  //       if (pass) {
  //         try { num5 = Integer.parseInt(nric.substring(5, 6)); }
  //         catch (ne)
  //         { pass = false; }
  //       }

  //       if (pass) {
  //         try { num6 = Integer.parseInt(nric.substring(6, 7)); }
  //         catch (ne)
  //         { pass = false; }
  //       }

  //       if (pass) {
  //         try { num7 = Integer.parseInt(nric.substring(7)); }
  //         catch (ne)
  //         { pass = false; }
  //       }

  //       if (pass) {
  //         let numa = num1 * 2;
  //         let numb = num2 * 7;
  //         let numc = num3 * 6;
  //         let numd = num4 * 5;
  //         let nume = num5 * 4;
  //         let numf = num6 * 3;
  //         let numg = num7 * 2;

  //         let totalnum = numa + numb + numc + numd + nume + numf + numg;

  //         if ((prefix == 'T') || (prefix == 'G')) {
  //           totalnum = totalnum + 4;
  //         }
  //         else if (prefix=='M')
  //         {
  //           totalnum=totalnum+3;
  //         }

  //         let remainder = totalnum % 11;
  //         let finalnum = 11 - remainder;

  //         switch (finalnum) {
  //           case 1:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'A'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'K'; }
  //             }
  //           case 2:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'B'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'L'; }
  //             }
  //           case 3:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'C'; }
  //               else if ((prefix == 'F') || (prefix == 'G')) { return 'M'; }
  //               else if (prefix=='M') {return 'J';}
  //             }
  //           case 4:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'D'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'N'; }
  //             }
  //           case 5:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'E'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'P'; }
  //             }
  //           case 6:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'F'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'Q'; }
  //             }
  //           case 7:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'G'; }
  //               else if ((prefix == 'F') || (prefix == 'G')) { return 'R'; }
  //             }
  //           case 8:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'H'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'T'; }
  //             }
  //           case 9:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'I'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'U'; }
  //             }
  //           case 10:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'Z'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'W'; }
  //             }
  //           case 11:
  //             {
  //               if ((prefix == 'S') || (prefix == 'T')) { return 'J'; }
  //               else if ((prefix == 'F') || (prefix == 'G') || (prefix=='M')) { return 'X'; }
  //             }
  //         }
  //         return '-';
  //       }
  //       else {
  //         return '-';
  //       }
  //     }
  //     else {
  //       return '-';
  //     }
  //   }
  //   else {
  //     return '-';
  //   }
  // }
}