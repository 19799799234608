<template>
<div>
  <h4>Worker Skill Information - Level 2 Report</h4>
  <div class="row mb-2">
    <div class="col-md-3 text-secondary">Corproate</div>
    <div class="col-md-3">{{reportData.corporate}}</div>
  </div>
  <div class="row mb-2">
    <div class="col-md-3 text-secondary">Place of Assignment</div>
    <div class="col-md-3">{{reportData.location}}</div>
  </div>
  <div class="row mb-2">
    <div class="col-md-3 text-secondary">Assignment Date</div>
    <div class="col-md-3">{{reportData.assignDate}}</div>
  </div>
  <div class="row mb-2">
    <div class="col-md-3 text-secondary">Skill Set</div>
    <div class="col-md-3">{{reportData.skill}}</div>
  </div>

  <div class="text-end">
    <button class="btn btn-success" @click="downloadExcel">Downlaod Excel</button>
  </div>

  <div class="mt-2 table-responsive">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>NRIC/FIN</th>
          <th>Work Permit No.</th>
          <th>Welder ID</th>
          <th>Skills Certified</th>
          <th>Assignment Start Date</th>
          <th>Assignment End Date</th>
          <th>Qualification Expiry Date</th>
          <th>SOC Expiry Date</th>
          <th>Work Permit Expiry Date</th>
          <th>Sponsor CPF A/C</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(d, idx) in reportData.listData" :key="idx">
          <td>{{d.name}}</td>
          <td>{{d.nricFin}}</td>
          <td>{{d.workPermit}}</td>
          <td>{{d.welderId}}</td>
          <td><span v-html="d.skillsCertified"></span></td>
          <td>{{d.assignmentStartDate}}</td>
          <td>{{d.assignmentEndDate}}</td>
          <td>{{d.qualificationExpiryDate}}</td>
          <td>{{d.socExpiryDate}}</td>
          <td>{{d.workPermitExpiryDate}}</td>
          <td>{{d.sponsor}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";

export default {
  name: 'WorkerSkillInformationDetailsReport',
  data() {
    return {
      skillOid: 0,
      locationOid: null,
      assignDate: '',
      corporateOid: 0,

      reportData: {}
    }
  },

  mounted() {
    this.skillOid = parseInt(this.$route.params.skillOid);
    this.corporateOid = parseInt(this.$route.params.corporateOid);
    if (this.$route.params.locationOid)
      this.locationOid = parseInt(this.$route.params.locationOid);
    this.assignDate = this.$route.params.assignDate;

    console.log("AAA", this.$route);

    this.getData();
  },

  methods: {
    getData() {
      let postData = {
        skillOid: this.skillOid,
        locationOid: this.locationOid,
        assignDate: this.assignDate,
        corporateOid: this.corporateOid
      };
      this.showLoadingScreen();
      api.regularPost(apiUrl.report.workerSkillInfoReportDetails, postData).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.reportData = response.data;
        }
      });
    },

    downloadExcel() {
      let postData = {
        skillOid: this.skillOid,
        locationOid: this.locationOid,
        assignDate: this.assignDate,
        corporateOid: this.corporateOid
      };
      this.showLoadingScreen();
      api.downloadFile(apiUrl.report.workerSkillInfoReportDetailsExcel, postData, 'WorkerSkillInfoDetailsReport.xlsx', () => {
        this.showMessage('Downloaded successfully.', 'success');
        this.hideLoadingScreen();
      });
    },

  }

}
</script>
