<template>
    <table border="1" width="100%" style="border-style: solid; border-width: 2; border-collapse: collapse">
        <tbody>
            <tr>
                <td>
                    <table border="1" cellpadding="0" cellspacing="0"
                        style="border-collapse: collapse; border-left-width:0; border-right-width:0; border-top-width:0"
                        bordercolor="#111111" width="100%" id="AutoNumber1">
                        <tbody>
                            <tr>
                                <td><img style="width:200px;" src="/img/PrimosLogo.png"/></td>
                <td
                  width="100%"
                  style="
                    border-left-style: none;
                    border-left-width: medium;
                    border-right-style: none;
                    border-right-width: medium;
                    border-top-style: none;
                    border-top-width: medium;
                    border-bottom-style: solid;
                    border-bottom-width: 1;
                  "
                >
                  <p align="center" style="line-height:0.8;">
                    <font face="Times New Roman"
                      ><b
                        >Primos Singapore Pte Ltd<br />
                        <font size="1"
                          >61 Bukit Batok Crescent, #07-05 Heng Loong Building, Singapore 658078<br/>
                          Tel: (65) 6779 7706</font><br/><br/>
                        <font size="2"
                          >PRiMOS Application Form</font
                        ></b
                      ></font
                    >
                  </p>
                </td>
                            </tr>
                        </tbody>
                    </table>
                    &nbsp;<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse;margin-top: -20px;"
                        bordercolor="#111111" width="100%" id="AutoNumber2">
                        <tbody>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Date of Application</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.createdDate_String}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Registration / Reference Number</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{serialNo}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Corporate Role</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.corporateType}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                          
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%"><u>
                                        <font face="Times New Roman" size="2"><b>Corporate Information</b></font>
                                    </u></td>
                                <td width="5%">&nbsp;</td>
                                <td width="50%">&nbsp;</td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Corporate Name</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.corpName}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Corporate Address</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.address1}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Corporate Country</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.countryName}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Corporate Postal Code</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.postalCode}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%"><u>
                                        <font face="Times New Roman" size="2"><b>Administrative Contact Information</b>
                                        </font>
                                    </u></td>
                                <td width="5%">&nbsp;</td>
                                <td width="50%">&nbsp;</td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Administrator Name</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.adminContact}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Designation of Administrator</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.adminDesignation}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Telephone Number / Extension</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.telephone}} / </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Fax Number</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.fax}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Email Address</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2"><a href="javascript:;">{{dataObj.corporateEmail}}</a>
                                    </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Mobile Number</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.mobileNo}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                           
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="94%" colspan="3"><u>
                                        <font face="Times New Roman" size="2"><b>Contractor Information (*** for
                                                Contractor role only ***)</b></font>
                                    </u></td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Ownership</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">&nbsp;{{dataObj.ownershipName}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%" valign="top">
                                    <font face="Times New Roman" size="2"><b>Principal Activities</b></font>
                                </td>
                                <td width="5%" valign="top">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">
                                        &nbsp;{{dataObj.principalActivities_String}}
                                    </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%" valign="top">
                                    <font face="Times New Roman" size="2"><b>Role of Contractor</b></font>
                                </td>
                                <td width="5%" valign="top">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">
                                        &nbsp;{{dataObj.roleOfContractor_String}}
                                    </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                          
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="94%" colspan="3"><u>
                                        <font face="Times New Roman" size="2"><b>Employment Strength (*** for Contractor
                                                role only ***)</b></font>
                                    </u></td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Total Company Strength</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">&nbsp;{{dataObj.companyStrength}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>No. of Management Staff</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">&nbsp;{{dataObj.managementStaff}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>No. of foreigner</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">&nbsp;{{dataObj.foreignerWorker}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>No. of Local</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">&nbsp;{{dataObj.localWorker}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Training Budget</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">&nbsp;{{dataObj.trainingBudget}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%" valign="top">
                                    <font face="Times New Roman" size="2"><b>Trade Skills</b></font>
                                </td>
                                <td width="5%" valign="top">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    &nbsp;
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                           
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>SWS Member ID</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">
                                        &nbsp;{{dataObj.swsName}}
                                    </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                             <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Payment</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">
                                        &nbsp;{{dataObj.paymentMethodName}}
                                    </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                    &nbsp;<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse;margin-top:-20px;"
                        bordercolor="#111111" width="100%" id="AutoNumber3">
                        <tbody>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="94%">
                                    <font face="Times New Roman" size="1">
                                        <p style="margin-bottom:-5px;">
                                             Upon acceptance of
                                        the registration, subject to approval, the following amount will
                                        be deducted from your account /<br>please make the payment by cheque while
                                        pending for Giro approval. <br>
                                        <i>** Please refer to pricing table **</i>
                                        </p>
                                       
                                    </font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                           
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="94%">
                                    <font face="Times New Roman" size="1">
                                        <p>I / We understand
                                        that the application is subjected to approval by PRiMOS Singapore. I / We have read and agree to bound by the terms and
                                        conditions of the agreement attached (appendix I). I / We agree that
                                        PRiMOS may at its sole discretion amend the Terms and Conditions as and
                                        when necessary, and such amendments shall be effective upon my / our
                                        receipt of the notification.</p></font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                    &nbsp;<table border="0" cellpadding="0" cellspacing="0"
                        style="border-collapse: collapse; border-width: 0" bordercolor="#111111" width="100%"
                        id="AutoNumber4">
                        <tbody>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="37%"
                                    style="border-bottom: 1pt solid black;border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="25%"
                                    style="border-bottom: 1pt solid black;border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="22%"
                                    style="border-bottom: 1pt solid black;border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="37%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:solid; border-top-width:1; border-bottom-style:none; border-bottom-width:medium"
                                    valign="top">
                                    <font size="2" face="Times New Roman">Name &amp; Designation of Approving
                                        Officer<br>
                                        Company Stamp of Employer (if any)</font>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="25%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:solid; border-top-width:1; border-bottom-style:none; border-bottom-width:medium"
                                    valign="top">
                                    <p align="center">
                                        <font face="Times New Roman" size="2">Authorized Signature</font>
                                    </p>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="22%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:solid; border-top-width:1; border-bottom-style:none; border-bottom-width:medium"
                                    valign="top">
                                    <p align="center">
                                        <font face="Times New Roman" size="2">Date</font>
                                    </p>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                    &nbsp;<table border="1" cellpadding="0" cellspacing="0"
                        style="border-collapse: collapse; border-width: 0;margin-top:-25px;" bordercolor="#111111" width="100%"
                        id="AutoNumber5">
                        <tbody>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="94%" style="border-style: none; border-width: medium">
                                    <font face="Times New Roman" size="1">For any assistance,
                                        please email us at <a href="mailto:info@imos.com.sg">info@imos.com.sg</a> /
                                        phone us at
                                        (65) 6779 7706 / Fax to us at (65) 6274 4055</font>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse"
                        bordercolor="#111111" width="100%" id="AutoNumber2">
                        <tbody>
                            <tr>
                                <td width="3%" style="border-top-style: solid; border-top-width: 1">&nbsp;</td>
                                <td width="39%" style="border-top-style: solid; border-top-width: 1">
                                    <font face="Times New Roman"><br></font><u><b>
                                            <font face="Times New Roman" size="2">For Official Use</font>
                                        </b></u>
                                </td>
                                <td width="5%" style="border-top-style: solid; border-top-width: 1">&nbsp;</td>
                                <td width="50%" style="border-top-style: solid; border-top-width: 1">&nbsp;</td>
                                <td width="3%" style="border-top-style: solid; border-top-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>To</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">&nbsp;</td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Date of Application</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{dataObj.createdDate_String}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%">&nbsp;</td>
                                <td width="39%">
                                    <font face="Times New Roman" size="2"><b>Registration / Reference Number</b></font>
                                </td>
                                <td width="5%">
                                    <font face="Times New Roman" size="2"><b>:</b></font>
                                </td>
                                <td width="50%">
                                    <font face="Times New Roman" size="2">{{serialNo}}</font>
                                </td>
                                <td width="3%">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                    &nbsp;<table border="1" cellpadding="0" cellspacing="0"
                        style="border-collapse: collapse; border-width: 0" bordercolor="#111111" width="100%"
                        id="AutoNumber5">
                        <tbody>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="94%" style="border-style: none; border-width: medium">
                                    <b>
                                        <font face="Times New Roman" size="2">This application is
                                            hereby ACCEPTED / REJECTED</font>
                                    </b>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="94%" style="border-style: none; border-width: medium">
                                    <b>
                                        <!-- <font face="Times New Roman" size="2">This application is
                                            hereby REJECTED by ASPRI / SWS *</font> -->
                                    </b>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="94%" style="border-style: none; border-width: medium">
                                    <b>
                                        <font face="Times New Roman" size="2">Reason:</font>
                                    </b>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="94%" style="border-style: none; border-width: medium">
                                    <b>
                                        <font face="Times New Roman" size="2">&nbsp; </font>
                                    </b>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                    &nbsp;<table border="0" cellpadding="0" cellspacing="0"
                        style="border-collapse: collapse; border-left-width:0; border-right-width:0; border-top-width:0"
                        bordercolor="#111111" width="100%" id="AutoNumber4">
                        <tbody>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="37%" style="border-style: none; border-width: medium" valign="top">&nbsp;
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="25%" style="border-style: none; border-width: medium" valign="top">&nbsp;
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="22%" style="border-style: none; border-width: medium" valign="top">&nbsp;
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="37%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="25%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="22%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="37%"
                                    style="border-top: 1pt solid black;border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:solid; border-top-width:1; border-bottom-style:none; border-bottom-width:medium"
                                    valign="top">
                                    <font size="2" face="Times New Roman">Name &amp; Designation of Approving
                                        Officer<br>
                                        Company Stamp of PRiMOS</font>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="25%"
                                    style="border-top: 1pt solid black;border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:solid; border-top-width:1; border-bottom-style:none; border-bottom-width:medium"
                                    valign="top">
                                    <p align="center">
                                        <font face="Times New Roman" size="2">Authorized Signature</font>
                                    </p>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="22%"
                                    style="border-top: 1pt solid black;border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:solid; border-top-width:1; border-bottom-style:none; border-bottom-width:medium"
                                    valign="top">
                                    <p align="center">
                                        <font face="Times New Roman" size="2">Date</font>
                                    </p>
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                                <td width="37%" style="border-style: none; border-width: medium" valign="top">&nbsp;
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="25%" style="border-style: none; border-width: medium" valign="top">&nbsp;
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium" valign="top">&nbsp;</td>
                                <td width="22%" style="border-style: none; border-width: medium" valign="top">&nbsp;
                                </td>
                                <td width="3%" style="border-style: none; border-width: medium">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1">
                                    &nbsp;</td>
                                <td width="37%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1"
                                    valign="top">
                                    <font size="1" face="Times New Roman">* Please delete where inapplicable</font>
                                </td>
                                <td width="3%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1"
                                    valign="top">&nbsp;</td>
                                <td width="25%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1"
                                    valign="top">&nbsp;</td>
                                <td width="3%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1"
                                    valign="top">&nbsp;</td>
                                <td width="22%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1"
                                    valign="top">&nbsp;</td>
                                <td width="3%"
                                    style="border-left-style:none; border-left-width:medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium; border-bottom-style:solid; border-bottom-width:1">
                                    &nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse"
                        bordercolor="#111111" width="100%" id="AutoNumber6">
                        <tbody>
                            <tr>
                                <td width="3%"
                                    style="border-bottom-style: none; border-bottom-width: medium; border-left-style:none; border-left-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                                <td width="94%" colspan="3" style="border-style:solid; border-width:1; ">
                                    <p align="center"><u><b>
                                                <font size="2" face="Times New Roman">** Pricing Table **</font>
                                            </b></u></p>
                                </td>
                                <td width="3%"
                                    style="border-bottom-style: none; border-bottom-width: medium; border-right-style:none; border-right-width:medium; border-top-style:none; border-top-width:medium">
                                    &nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%"
                                    style="border-top-style: none; border-top-width: medium; border-right-style:solid; border-right-width:1">
                                    &nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><u><b>
                                            <font size="2" face="Times New Roman">Item</font>
                                        </b></u></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-bottom-style: solid; border-bottom-width: 1">
                                    &nbsp;</td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <u><b>
                                            <font size="2" face="Times New Roman">Amount</font>
                                        </b></u></td>
                                <td width="3%"
                                    style="border-top-style: none; border-top-width: medium; border-left-style:solid; border-left-width:1">
                                    &nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">&nbsp;</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">&nbsp;</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1"><u><b>Subscription Fees - Monthly
                                                basis</b></u></font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Industry / MNC</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 200</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Contractor</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 95</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Test Centre (non-welding)</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 50</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Test Centre (welding)</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 150</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">User Access ID</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">Free of charge for the first 5 userid.
                                        Subsequent userid at $10 each / month</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Account Setup - one time
                                            basis</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 100</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">&nbsp;</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">&nbsp;</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1"><u><b>Transaction Fees</b></u></font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Worker Subcription</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 1.00 per worker per month</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">&nbsp;</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">&nbsp;</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1"><u><b>Others</b></u></font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Training and Technical
                                            Support</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 250 per trainee per class for
                                        all user groups (chargeable upon request)</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
                                <td width="39%" style="border-style: solid; border-width: 1"><b>
                                        <font face="Times New Roman" size="1">Training and Technical
                                            Support - Test Center</font>
                                    </b></td>
                                <td width="5%"
                                    style="border-left-style: solid; border-left-width: 1; border-right-style: none; border-right-width: medium; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <b>
                                        <font face="Times New Roman" size="1">:</font>
                                    </b></td>
                                <td width="50%"
                                    style="border-left-style: none; border-left-width: medium; border-right-style: solid; border-right-width: 1; border-top-style: solid; border-top-width: 1; border-bottom-style: solid; border-bottom-width: 1">
                                    <font face="Times New Roman" size="1">S$ 50 per trainee per class for
                                        non-welding test centre (chargeable upon request)</font>
                                </td>
                                <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
                            </tr>
                            <!--tr>
    <td width="3%" style="border-right-style: solid; border-right-width: 1">&nbsp;</td>
    <td width="94%" colspan="3" style="border-style: solid; border-width: 1">
    <font face="Times New Roman" size="1"><br><u><b>Top-up Fee (applicable to Contractors only)</b></u><br>In the event that the total monthly revenue is less than the monthly operating cost, with the monthly operating cost projected not to exceed S$ 20,000, the balance shall be divided by the total number of active workers in PRiMOS to derive a per worker fee. This per worker fee is then multiply by the number of workers belonging to the Contractor to derive the top-up fee payable by the Contractor.
	<br><br>For example, assuming:</font><br>

	<table border = 0>
	<tr><td><font face="Times New Roman" size="1">1. Monthly revenue collected is</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S$ 10,000</font></td><tr>
	<tr><td><font face="Times New Roman" size="1">2. Operating cost for that month is</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>S$ 20,000</u></font></td><tr>
	<tr><td><font face="Times New Roman" size="1">3. Deficit for that month is</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S$ 10,000</font></td><tr>
	<tr><td></td><td></td><tr>
	<tr><td><font face="Times New Roman" size="1">4. Total number of active workers</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5,000</font></td><tr>
	<tr><td><font face="Times New Roman" size="1">5. Per Worker fee</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S$ 2</font></td><tr>
	<tr><td></td><td></td><tr>
	<tr><td><font face="Times New Roman" size="1">6. Top-up Fee for Contractor A with 300 active workers</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S$ 600</font></td><tr>
	<tr><td><font face="Times New Roman" size="1">7. Top-up Fee for Contractor B with 150 active workers</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S$ 300</font></td><tr>
	<tr><td><font face="Times New Roman" size="1">8. Top-up Fee for Contractor C with 50 active workers</font></td><td><font face="Times New Roman" size="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S$ 100</font></td><tr>
	</table>
	</td>    
    <td width="3%" style="border-left-style: solid; border-left-width: 1">&nbsp;</td>
  </tr-->

                        </tbody>
                    </table>



                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: "ApplicationForm",
  components: {
  },
  data() {
    return {
      oid:0,
      dataObj: {},
      serialNo:''
    };
  },
  beforeMount() {
    let passedId = this.$route.query.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.getData();
    }
  },
  mounted() {
  },
  methods: {
    getData() {
      let url = apiUrl.regIndustry.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.serialNo = this.pad(this.dataObj.oid,8,this.dataObj.type)
        setTimeout(function (){
            window.print();
          }, 1500);
      });
    },
    pad(num, size,type) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return type + num;
    }
  },
};
</script>