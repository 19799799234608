<template>
  <div>
    <div v-if="!isNew" class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Service Code:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.serviceCode && 'control-error'" v-model="dataObj.serviceCode" :disabled="!hasPermission" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Description:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.description && 'control-error'" v-model="dataObj.description" :disabled="!hasPermission" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Unit Price:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6 text-secondary">
                <input type="number" class="form-control" :class="error.unitPrice && 'control-error'" v-model="dataObj.unitPrice" :disabled="!hasPermission" />
              </div>
            </div>
           
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Quantity:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="number" class="form-control" :class="error.quantity && 'control-error'" v-model="dataObj.quantity" :disabled="!hasPermission" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Corporate Type:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <FormSelect :list="corporateTypes" :cssClass="error.forCorporateType && 'control-error'" :existing="dataObj.forCorporateType" value="value" text="text" @change="dataObj.forCorporateType=$event" :disabled="!hasPermission"/>
              </div>
            </div>
              <div class="row mb-2">
              <div class="col-sm-6 text-secondary">
                Status:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :cssClass="error.status && 'control-error'" :existing="dataObj.status" value="value" text="text" @change="dataObj.status=$event" :disabled="!hasPermission"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="text-danger small">* Denotes Mandatory Field</span>
        </div>
        <div>
          <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button v-if="this.hasPermission" class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button>
        </div>
        
        
      </div>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Sw" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'AddEditServiceItem',

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms,
    ...Pickers
  },

  props: {

  },

  data() {
    return {
      basicLogInfoUrl: apiUrl.serviceItem.getBasicLogInfo,
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      showAuditModal: false,
      expiryDate: null,
      terminateDate: null,
      hasPermission: false,
      corporateTypes:[],
      statuses:[],
      error: {}
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {
    let pageName = this.isNew ? 'New Service Item' : 'Edit Service Item';
    this.setBreadcrumb([
      { name: 'Service Items', url: '/manageserviceitem' }, { name: pageName }
    ]);

    if (this.isNew) {
      this.dataObj.isActive = true;
    }
    if (this.oid > 0) {
      this.getData();
    }

    this.statuses = [
      { value: this.$data._status.active.value, text: this.$data._status.active.text },
      { value: this.$data._status.deleted.value, text: this.$data._status.deleted.text },
    ];

    this.corporateTypes = [
      { value: this.$data._corporateType.industry.value, text: this.$data._corporateType.industry.text },
      { value: this.$data._corporateType.contractor.value, text: this.$data._corporateType.contractor.text },
       { value: this.$data._corporateType.testCenter.value, text: this.$data._corporateType.testCenter.text },
    ];
    this.hasPermission =true; //this.isAuthorizedPermission(this.$data._permissionCodes.manageserviceItem);
  },

  methods: {
    getData() {
      let url = apiUrl.serviceItem.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
       
      });
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.serviceItem.insert;
      }
      else {
        subUrl = apiUrl.serviceItem.update;
      }

      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "ManageServiceItem" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "ManageServiceItem" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      if (!this.dataObj.serviceCode) { this.error.serviceCode = true; isValid = false; }
      if (!this.dataObj.description) { this.error.description = true; isValid = false; }
      if (!this.dataObj.unitPrice) { this.error.unitPrice = true; isValid = false; }
      if (!this.dataObj.quantity) { this.error.quantity = true; isValid = false; }
      if (!this.dataObj.forCorporateType) { this.error.forCorporateType = true; isValid = false; }
      if (!this.dataObj.status) { this.error.status = true; isValid = false; }
      this.$forceUpdate();
      return isValid;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.description + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.serviceItem.delete, this.dataObj).then((response) => {
              console.log(response);
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "ManageServiceItem" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    }
  }
}
</script>
