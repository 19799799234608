<template>
  <div style="background: #f2f2f2; min-height: 100vh">
    <div class="container">

      <div class="row">
        <div class="offset-md-2 col-md-8">
          <div class="card" style="margin-top: 5%">
            <div class="card-body">
              <h5 class="card-title">Register as an INDUSTRY</h5>
              <div v-show="isMain">
                <h6 class="card-subtitle mb-2 text-muted">Corporate Information</h6>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row mb-3">
                      <div class="col-sm-4 text-secondary">Corporate Name:<span class="text-danger">*</span></div>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" maxlength="50" v-model="dataObj.corpName"
                          :class="error.corpName && 'control-error'" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4 text-secondary">Building Name:</div>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="dataObj.buildingName"
                          :class="error.buildingName && 'control-error'" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4 text-secondary">Block No:</div>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="dataObj.blkNo"
                          :class="error.blkNo && 'control-error'" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4 text-secondary">Unit No:</div>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="dataObj.unitNo"
                          :class="error.unitNo && 'control-error'" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4 text-secondary">Address:<span class="text-danger">*</span></div>
                      <div class="col-sm-8">
                        <textarea maxlength="100" class="form-control" rows="3" v-model="dataObj.address1"
                          :class="error.address1 && 'control-error'" />
                        </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Address 2:</div>
                    <div class="col-sm-8">
                      <textarea maxlength="100"
                  class="form-control"
                  rows="3"
                  v-model="dataObj.address2"
                  :class="error.address2 && 'control-error'"
                />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Country:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <FormSelect :cssClass="error.countryOid && 'control-error'" :list="countries" value="oid" text="value" :existing="dataObj.countryOid" @change="dataObj.countryOid=$event"/>
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Postal Code:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                        maxlength="6"
                        type="text"
                        class="form-control"
                        v-model="dataObj.postalCode"
                        :class="error.postalCode && 'control-error'"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Administrator Name:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                        maxlength="50"
                        type="text"
                        class="form-control"
                        v-model="dataObj.adminContact"
                        :class="error.adminContact && 'control-error'"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Designation of Administrator:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                      maxlength="50"
                        type="text"
                        class="form-control"
                        v-model="dataObj.adminDesignation"
                        :class="error.adminDesignation && 'control-error'"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Telephone No:<span class="text-danger">*</span></div>
                    <div class="col-sm-4">
                      <input
                        maxlength="8"
                        type="text"
                        class="form-control"
                        v-model="dataObj.telephone"
                        :class="error.telephone && 'control-error'"
                      />
                      </div>
                      <div class="col-sm-1 text-secondary">Ext</div>
                      <div class="col-sm-3">
                        <input
                        maxlength="5"
                        type="text"
                        class="form-control"
                        v-model="dataObj.ext"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Fax:</div>
                    <div class="col-sm-8">
                      <input
                      maxlength="8"
                        type="text"
                        class="form-control"
                        v-model="dataObj.fax"
                         :class="error.fax && 'control-error'"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Email Address<span class="text-danger">*</span>:</div>
                    <div class="col-sm-8">
                      <input
                        maxlength="50"
                        type="text"
                        class="form-control"
                        v-model="dataObj.corporateEmail"
                        :class="error.corporateEmail && 'control-error'"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Mobile Contact No:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                        maxlength="10"
                        type="text"
                        class="form-control"
                        v-model="dataObj.mobileNo"
                        :class="error.mobileNo && 'control-error'"
                      />
                    </div>
                  </div>
                  <hr/>
                  
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">SWS Member Name:</div>  
                    <div class="col-sm-8">
                      <v-select label="company" :class="error.swsoid && 'control-error'" v-model="selectedSws" :options="swss" @input="$event==null?dataObj.swsoid=null:dataObj.swsoid = $event.oid"></v-select>
                      <!-- <FormSelect :cssClass="error.swsoid && 'control-error'" :list="swss" value="oid" text="company" :existing="dataObj.swsoid" @change="dataObj.swsoid=$event"/> -->
                    </div>
                  </div>
                   <!-- <div class="row mb-3">
                    <div class="col-sm-4">ASPRI Member Name:</div>
                    <div class="col-sm-8">
                      <FormSelect :list="parents" value="oid" text="name" :existing="dataObj.parentSettingOid" @change="dataObj.parentSettingOid=$event"/>
                    </div>
                  </div> -->
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Payment Method:</div>
                    <div class="col-sm-8">
                      <FormSelect :cssClass="error.paymentMethodOid && 'control-error'" :list="payments" value="oid" text="value" :existing="dataObj.paymentMethodOid" @change="changePayment"/>
                    </div>
                  </div>
                  <hr/>
                  <div v-if="isGIRO">
                    <h6 class="card-subtitle mb-2 text-muted" >GIRO Information</h6>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Name of Financial Institution:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <FormSelect :cssClass="error.bankOid && 'control-error'" :list="banks" value="oid" text="value" :existing="dataObj.giroTemp.bankOid" @change="changeBank"/>
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Branch:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <FormSelect :cssClass="error.branchCodeOid && 'control-error'" :list="branches" value="oid" text="value" :existing="dataObj.giroTemp.branchCodeOid" @change="dataObj.giroTemp.branchCodeOid=$event"/>
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Account Number:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                      maxlength="11"
                        type="text"
                        class="form-control"
                        :class="error.bankAccountNum && 'control-error'"
                        v-model="dataObj.giroTemp.bankAccountNum"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Account Holder Name:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                      maxlength="50"
                        type="text"
                        class="form-control"
                        :class="error.name && 'control-error'"
                        v-model="dataObj.giroTemp.name"
                      />
                    </div>
                  </div>
                   <div class="row mb-3">
                    <div class="col-sm-4 text-secondary">Account Holder Contact (Tel/Fax) Number:<span class="text-danger">*</span></div>
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        maxlength="10"
                        :class="error.telephone && 'control-error'"
                        v-model="dataObj.giroTemp.telephone"
                      />
                    </div>
                  </div>
                  </div>
                  <div class="row mb-3">
                      <div class="col-sm-12">
                         <input type="checkbox" v-model="checkAgreement" @change="chkAgreeChange" /> I have read and agree with the <a href="javascript:;" @click="downloadFile">Terms and Conditions</a>
                      </div>
                      <div class="col-sm-12">
                        <span class="text-danger">* Denotes Mandatory Field</span>
                      </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-secondary m-1 float-end" @click="clickCancel">
                Cancel
              </button>
              <button class="btn btn-success m-1 float-end" @click="clickSave">
                Register
              </button>
              </div>
              <div v-show="!isMain">
                  <p>PRiMOS Application Confirmation<br></p>
                 <p>
                <p></p>
                <div class="row mb-3">
                    <div class="col-sm-4">
                         <button class="btn btn-success" style="width:100%;" @click="clickAgree">Agree</button>
                    </div>
                    <div class="col-sm-4">
                         <button class="btn btn-secondary " style="width:100%;" @click="clickDisagree">Disagree</button>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-secondary" style="width:100%;" @click="clickBack">Back</button>
                    </div>
                </div>
                <p>
      Note:<br>
      
      
      * Please send all the application form to the following address: <br>
        iMOS Holdings Pte Ltd<br>
        61 Bukit Batok Crescent<br>
        #07-05 Heng Loong Building<br>
        Singapore 658078<br>
        Tel: (65) 6779 7706<br>
        Fax: (65) 6274 4055<br>
        Email: <a href="mailto:info@imos.com.sg">info@imos.com.sg</a><br>
      </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from "@/components/custom/forms";
export default {
  name: "RegisterIndustry",
  components: {
    ...Forms,
  },
  data() {
    return {
      isMain:true,
      isGIRO:false,
      countries:[],
      swss:[],
      payments:[],
      banks:[],
      branches:[],
      checkAgreement:false,
      showModal: false,
      dataObj: {
        giroTemp:{
        }
      },
      error: {},
      selectedSws: {},
    };
  },
  mounted() {
    this.getDropdowns();
  },
  methods: {
    downloadFile() {
      api.downloadFile(apiUrl.corporate.downloadTAC, null, 'PRiMOS_TAC.pdf', () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    submit() {},
    modal() {
      this.showModal = true;
    },
    chkAgreeChange(e){
      this.chkAgreeChange = e;
    },
    changeBank(e){
      this.dataObj.giroTemp.bankOid = e;
      let url = apiUrl.settings.getByParentOid + "/" + e
      api.regularGet(url, null).then((response) => {
        this.branches = response.data;
      });
    },
    changePayment(e){
      this.dataObj.paymentMethodOid = e;
      if(this.dataObj.paymentMethodOid == this.$data._defaultValue.payment_giro){
        this.isGIRO = true;
      }else{
        this.isGIRO = false;
      }
    },
    getDropdowns() {
      let url = apiUrl.regIndustry.dropdowns
      api.regularGet(url, null).then((response) => {
        let dropdowns = response.data;
        
        this.countries = dropdowns.countries;
        this.swss = dropdowns.sws;
        this.payments = dropdowns.payments;
        this.banks = dropdowns.banks;

        this.dataObj.countryOid = this.$data._defaultValue.countrySingapore;
      });
    },
    clickSave() {
      if (this.validate()) {
        this.isMain = false;
      }else{
        if(this.errMessage)
            this.showMessage(this.errMessage, "warning");
           return;
      }
      
    },
    clickAgree(){
      this.dataObj.type = 'I';
      this.dataObj.registerType = "industry";
      let subUrl = apiUrl.regIndustry.insert;
      api.regularPost(subUrl, this.dataObj).then((response) => {
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: 'CompleteRegister', query: { id: response.data.data.oid } });
        } else if (
          response.status == 400 ||
          response.errorText.response.status == 400
        ) {
          this.showMessage(response.errorText.response.data.message, "error");
        } else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickDisagree(){
      let warningMessage = 'INDUSTRY participating in PRiMOS must be member of ASPRI and/or SWS. Please refer to ASPRI Secretariat @64730458 and/or SWS Secretariat @62704730 should you requires assistance.';
      this.showConfirmDiaglog(
        {
          show: true,
          message: warningMessage,
          submitFunction: () => {
            this.$router.push({ name: "RegisterMain" });
          }
        });
    },
    clickBack(){
        this.isMain = true;
    },
    clickCancel() {
      this.$router.push({ name: "RegisterMain" });
    },
    validate() {
      let isValid = true;
       Object.keys(this.error).forEach(k => this.error[k] = false);
      this.errMessage = "";
      if (!this.dataObj.corpName){
        this.error.corpName = true; 
        isValid = false;
      }
      if (!this.dataObj.address1){
        this.error.address1 = true; 
        isValid = false;
      }
      if (!this.dataObj.countryOid){
        this.error.countryOid = true; 
        isValid = false;
      }
      if (!this.dataObj.paymentMethodOid){
        this.error.paymentMethodOid = true; 
        isValid = false;
      }else{
        if(this.dataObj.paymentMethodOid == this.$data._defaultValue.payment_giro){
          if (!this.dataObj.giroTemp.bankOid){
            this.error.bankOid = true; 
            isValid = false;
          }
          if (!this.dataObj.giroTemp.bankAccountNum){
            this.error.bankAccountNum = true; 
            isValid = false;
          }
          if (!this.dataObj.giroTemp.name){
            this.error.name = true; 
            isValid = false;
          }
          if (!this.dataObj.giroTemp.telephone){
            this.error.telephone = true; 
            isValid = false;
          }
        }
      }
      if (!this.dataObj.postalCode){
        this.error.postalCode = true; 
        isValid = false;
      }
      if (!this.dataObj.adminContact){
        this.error.adminContact = true; 
        isValid = false;
      }
      if (!this.dataObj.adminDesignation){
        this.error.adminDesignation = true; 
        isValid = false;
      }
      if (!this.dataObj.telephone){
        this.error.telephone = true;  
        isValid = false;
      }
      if (!this.dataObj.corporateEmail){
       this.error.corporateEmail = true; 
        isValid = false;
      }
      if (!this.dataObj.corporateEmail){
        this.error.corporateEmail = true; 
        isValid = false;
      }else{
        // if (!this.validateEmail()){
        //   msg += window.message.coroprateregister.email_invalid;
        // }
      }
      if(!this.dataObj.mobileNo){
        this.error.mobileNo = true; 
        isValid = false;
      }

       if(!this.checkAgreement){ 
        this.errMessage += "Please accept Terms and Conditions"+ "<br/>";
        isValid = false;
      }

      this.$forceUpdate();
      return isValid;
    },
    // validateEmail(email) {
    //   // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    //   //     return false
    //   // } else {
    //   //     return true;
    //   // }
    // }
  },
};
</script>
