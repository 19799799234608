<template>
  <div>

    <div class="card">
      <div class="card-header bg-secondary text-white hover" data-bs-toggle="collapse" href="#div_filter">
        Filter
      </div>
      <div class="card-body">
        <div id="div_filter">
          <div class="text-secondary">Assignment Status:</div>
          <div class="mb-2">
            <label class="hover me-5"><input type="checkbox" v-model="tableParam.isStatusActive" /> Active</label>
            <label class="hover me-5"><input type="checkbox" v-model="tableParam.isStatusInactive" /> Inactive</label>
            <label class="hover me-5"><input type="checkbox" v-model="tableParam.isStatusPendingIndustry" /> Pending(Industry)</label>
            <label class="hover me-5"><input type="checkbox" v-model="tableParam.isStatusPendingContractor" /> Pending(Main Contractor)</label>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              NRIC/FIN
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.nricFin" />
            </div>
            <div class="col-md-3 text-secondary">
              Name
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.name" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Work Permit
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.workPermit" />
            </div>
            <div class="col-md-3 text-secondary">
              Welder ID
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.welderId" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              AssignmentDate
            </div>
            <div class="col-md-3">
              <DatePicker :existing="assignmentDate" @updatedate="changeAssignmentDate" />
            </div>
            <div class="col-md-3 text-secondary">
              Site Location
            </div>
            <div class="col-md-3">
              <FormSelect :list="siteLocations" value="oid" text="name" @change="tableParam.siteLocationOid=$event" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              From Contractor
            </div>
            <div class="col-md-3">
              <FormSelect :list="contractors" value="oid" text="corpName" @change="tableParam.fromContractorOid=$event" />
            </div>
            <div class="col-md-3 text-secondary">
              Project Code
            </div>
            <div class="col-md-3">
              <FormSelect :list="projectCodes" value="oid" text="projectCode" @change="tableParam.projectCodeOid=$event" />
            </div>
          </div>
          <div class="row mb-1" v-if="!isLoggedInUserIndustry">            
            <div class="col-md-3 text-secondary">
              Industry
            </div>
            <div class="col-md-3">
              <FormSelect :list="industries" value="oid" text="corpName" @change="tableParam.industryOid=$event" />
            </div>
          </div>

          <div class="text-end">
            <button class="btn btn-primary" @click="clickSearch">Search</button>
          </div>

        </div>
      </div>
    </div>

    <div class="text-end mt-3">
      <button class="btn btn-success me-2" @click="clickEndorse">Endorse</button>
      <button class="btn btn-warning me-2" @click="clickDeassign">Deassign</button>
    </div>
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>NRIC/FIN</th>
            <th>Welder ID</th>
            <th>Work Permit</th>
            <th>From Contractor</th>
            <th v-if="!isLoggedInUserIndustry">Industry</th>
            <th>Job Category</th>
            <th>Project Code</th>
            <th>Site Location</th>
            <th>Assign Type</th>
            <th>Welding Inspector</th>
            <SortColumn @click="sortColumn" sortBy="StartDate" colGroup="tbl1">Start Date</SortColumn>
            <SortColumn @click="sortColumn" sortBy="EndDate" colGroup="tbl1">End Date</SortColumn>
            <th>Shift</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,i) in tableParam.listData" :key="i">
            <td>{{l.workerName}}</td>
            <td>{{l.nricFin}}</td>
            <td>{{l.welderId}}</td>
            <td>{{l.workPermit}}</td>
            <td>{{l.fromContractorName}}</td>
            <td v-if="!isLoggedInUserIndustry">{{l.industryName}}</td>
            <td>{{getJobCategoryTextByValue(l.jobCategory)}}</td>
            <td>{{l.industryProjectCode}}</td>
            <td>{{l.siteLocationName}}</td>
            <td>{{getAssignTypeTextByValue(l.assignType)}}</td>
            <td>{{l.weldingInspectorName}}</td>
            <td>{{l.startDateStr}}</td>
            <td>{{l.endDateStr}}</td>
            <td>
              <span v-if="l.morning">M </span>
              <span v-if="l.afternoon">A </span>
              <span v-if="l.night">N </span>
            </td>
            <td>{{getAssignmentStausTextByValue(l.status)}}</td>
            <td>
              <label class="hover"><input type="checkbox" v-model="l.selected" /></label>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :pagingParam="tableParam"
        @updatePaging="updatePaging"
      />
    </div>



  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
//import ModalDiaglog from '@/components/custom/ModalDiaglog';
import api from '@/common/api';
import * as TableAddons from "@/components/custom/table_addons";
import * as Pickers from '@/components/custom/pickers';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'WorkerAssignmentsApprove',
  components: {
    ...TableAddons,
    ...Pickers,
    ...Forms,
    //ModalDiaglog
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      assignmentDate: null,

      industries: [],
      contractors: [],
      projectCodes: [],
      siteLocations: [],

      showChangeWIDiaglog: false,
      isLoggedInUserIndustry: false,
    }
  },
  mounted() {
    let loggedInUser = this.getLoggedInUser();

    //Industry
    if (loggedInUser.userGroupType == this.$data._userGroupType.industry.value || loggedInUser.wiType == this.$data._corporateType.industry.value) {
      this.isLoggedInUserIndustry = true;
      this.tableParam.byFromIndustry = true;
    }
    this.tableParam.industry = null;
    this.tableParam.fromContractorOid = null;
    this.tableParam.projectCodeOid = null;
    this.tableParam.siteLocationOid = null;
    this.tableParam.isStatusPendingIndustry = true;

    api.getAllCorporates(null).then(response => {
      this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
      this.industries.unshift({ oid: null, corpName: "All" });
      this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      this.contractors.unshift({ oid: null, corpName: "All" });
    });
    api.regularGet(apiUrl.industryProject.getList).then(res => {
      this.projectCodes = res.data;
      this.projectCodes.unshift({ oid: null, projectCode: "All" });
    });
    api.regularGet(apiUrl.site.getList).then(res => {
      this.siteLocations = res.data;
      this.siteLocations.unshift({ oid: null, name: "All" });
    });



    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];

      if (!this.tableParam.industry)
        this.tableParam.industry = null;
      if (!this.tableParam.fromContractorOid)
        this.tableParam.fromContractorOid = null;
      if (!this.tableParam.projectCodeOid)
        this.tableParam.projectCodeOid = null;
      if (!this.tableParam.siteLocationOid)
        this.tableParam.siteLocationOid = null;

      api.regularPost(apiUrl.workerAssignment.getListByApprover, this.tableParam).then(response => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickSearch() {
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    clickEndorse() {
      let selectedList = this.tableParam.listData.filter(x => x.selected);
      if (selectedList.length == 0) {
        this.showMessage("Please select the records. ", "warning");
        return;
      }

      let postData = {
        blockIds: this.tableParam.listData.filter(x => x.selected).map(x => x.blockId)
      }
      this.showLoadingScreen();
      api.regularPost(apiUrl.workerAssignment.endorse, postData).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully Endorsed.", "success");
          this.callTable();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.doctors.unable_delete, "error");
        }
      });

    },
    clickDeassign() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure you want to deassign/reject the selected worker assignment??',
          submitFunction: () => {
            let selectedList = this.tableParam.listData.filter(x => x.selected);
      if (selectedList.length == 0) {
        this.showMessage("Please select the records. ", "warning");
        return;
      }

      let postData = {
        blockIds: this.tableParam.listData.filter(x => x.selected).map(x => x.blockId)
      }
      this.showLoadingScreen();
      api.regularPost(apiUrl.workerAssignment.deassign, postData).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully Deassigned.", "success");
          this.callTable();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.doctors.unable_delete, "error");
        }
      });
          }
        });
      

    },

    changeAssignmentDate(date) {
      this.tableParam.assignmentDate = this.convertDateObjToDateStr(date);
    },



  }
}
</script>
