<template>
<div>
  <div>
    
  </div>
  <div>
     <div class="card mb-1">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                User Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterName" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-6">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text" @change="changeFilterStatus" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                Group Type
              </div>
              <div class="col-sm-6">
                <FormSelect :list="userGroupTypes"  value="value" text="text" :existing="userGroupType" @change="changeUserGroupType" />
              </div>
            </div>
            <div v-if="getLoggedInUser().userGroupType == $data._userGroupType.admin.value" class="row mb-2">
              <div class="col-sm-6">
                Corporate Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeCorporateName" />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2">
          <div>
            <button class="btn btn-primary float-end mb-2" @click="addUser" style="margin-right:10px;">Add User</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="download">Download</button>            
          </div>
        </div>
      </div>
    </div> 
    <div>
       <table class="table table-sm">
      <thead>
      <tr>
          <SortColumn @click="sortColumn" sortBy="user" colGroup="tbl1">User Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="fullName" colGroup="tbl1">Full Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="lastSuccessLoginDateTimeStr" colGroup="tbl1">Last Login</SortColumn>
          <SortColumn @click="sortColumn" sortBy="lastPasswordChangedDateTimeStr" colGroup="tbl1">Last Password Change</SortColumn>
          <SortColumn @click="sortColumn" sortBy="corporateName" colGroup="tbl1">Corporate Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="userGroupType" colGroup="tbl1">Group Type</SortColumn>
          <SortColumn @click="sortColumn" sortBy="isActive" colGroup="tbl1">Status</SortColumn>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx"> 
          <td>{{i.userName}}</td>
          <td>{{i.fullName}}</td>
          <td>{{i.lastSuccessLoginDateTimeDisplay}}</td>
          <td>{{i.lastPasswordChangedDateTimeDisplay}}</td>
          <td>{{i.corporateName}} </td>
          <td>{{i.userGroupTypeDisplay}}  <span class="badge bg-warning" v-if="i.userGroupType == $data._userGroupType.weldingInsperctor.value">{{i.corporateTypeDisplay}}</span></td>
           <td><span class="badge bg-success" v-if="i.isActive">Active</span><span class="badge bg-secondary" v-else>Inactive</span> <span class="badge bg-danger" v-if="i.isLock">Locked</span></td>
          <td>
            <button class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button>
            <div class="dropdown" style="display:inline-block" v-if='getLoggedInUser().userGroupType == $data._userGroupType.admin.value'>
                <!-- <span class="dropdown-toggle" id="dropdownMenuButton1"><i class="bi bi-three-dots-vertical"></i></span> -->
                <button class="btn btn-secondary" type="button" style="background-color: transparent;color: black;border: none;"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" @click="clickNoti(i)" href="#">Notification Setting</a></li>
                  <li><a class="dropdown-item" @click="changePassword(i)" href="#">Change Password</a></li>
                  <li><a class="dropdown-item" @click="loginAsOther(i)" href="#">Login as this user</a></li>
                  <li v-if="i.isLock"><a class="dropdown-item" @click="clickUnlock(i)" href="#">Unlock</a></li>
                </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
   
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>
  <ModalDiaglog size="xl" :show="showModal" :onClose="()=>{showModal=false}" :hasHeader="true" :title="title"
      :showSubmitBtn1="false">
      <div v-if="showModal">
        <div class="row">
          <div class="col-sm-12">
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                New Password:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <input type="password" class="form-control" :class="error.password && 'control-error'"
                  v-model="password" maxlength="20"
                  placeholder="(max 20 characters)" autocomplete="off"/>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Confirm New Password:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <input type="password" class="form-control" :class="error.confirmPassword && 'control-error'"
                  v-model="confirmPassword" maxlength="20"
                  placeholder="(max 20 characters)"  autocomplete="off"/>
              </div>
            </div>
            <span class="text-danger">Minimum 6 characters for the new password.</span>         
            <button class="btn btn-primary m-1 float-end" style="width:100px;" @click="updatePassword">Update</button>
          </div>
        </div>
      </div>
    </ModalDiaglog>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import _ from "lodash";
import * as Forms from '@/components/custom/forms';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
export default {
  name: 'Users',
  components: {
    ModalDiaglog,
    ...TableAddons,
    ...Forms
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses:[],
      userGroupTypes: [],
      userGroupType:'',
      showModal:false,
      title:'Change Password',
      password:'',
      confirmPassword:'',
      oid:0,
       error: {},
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    
    this.setBreadcrumb([
      { name: 'Users' }
    ]);

    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];

    this.userGroupTypes = [
      { value: "", text: "All" },
      { value: this.$data._userGroupType.industry.value, text: this.$data._userGroupType.industry.text },
      { value: this.$data._userGroupType.contractor.value, text: this.$data._userGroupType.contractor.text },
      { value: this.$data._userGroupType.testCenter.value, text: this.$data._userGroupType.testCenter.text },
      { value: this.$data._userGroupType.weldingInsperctor.value, text: this.$data._userGroupType.weldingInsperctor.text },
      { value: this.$data._userGroupType.admin.value, text: this.$data._userGroupType.admin.text },
      { value: this.$data._userGroupType.sws.value, text: this.$data._userGroupType.sws.text },
      { value: this.$data._userGroupType.mom.value, text: this.$data._userGroupType.mom.text },
    ];

    this.callTable();
  },
  methods: {
    download() {
      let data = {
        userGroup: this.tableParam.userGroup,
        name: this.tableParam.name,
        status: this.tableParam.status
      }
      api.downloadFile(apiUrl.users.download,data,"user.xlsx",() =>{
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.users.getAll, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditUser', params: { id: data.oid } });
    },
    clickNoti(data) {
      this.$router.push({ name: 'UserNotificationSettingByAdmin', params: { id: data.oid } });
    },
    clickUnlock(data) {
      this.showLoadingScreen();
       let param = {
        oid:data.oid
      };
      api
        .regularPost(apiUrl.users.unlock, param, this.$store.state.token)
        .then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.callTable();
            this.showMessage("Successfully unlocked.", "success");
          } else if (
            response.status == 400 ||
            response.errorText.response.status == 400
          ) {
            this.showMessage(response.errorText.response.data.message, "error");
          } else {
            this.showMessage(window.message.unable_proceed, "error");
          }
        });
    },
    validate() {
      let msg = "";
      if (!this.password) msg += window.message.users.current_password_blank+ "<br/>";
      if (this.password.length < 6) {
        msg += window.message.users.new_password_short + "<br/>";
      }
      if (this.confirmPassword.length < 1) msg += window.message.users.password_confirm_blank + "<br/>";
      if ((this.password && this.confirmPassword) && (this.password != this.confirmPassword))
        msg += window.message.users.password_not_match + "<br/>";
      return msg;
    },
    updatePassword(){
      let msg = this.validate();
      if (msg) {
        this.showMessage(msg, "warning");
        return;
      }

      let data = {};
      data.newPassword = this.password;
      data.oid = this.oid;
      data.currentPassword = '';

      let subUrl = "";
      subUrl = apiUrl.users.saveUserPasswordByAdmin;

      api
        .regularPost(subUrl, data, this.$store.state.token)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.isSuccess){
              this.showMessage("Successfully saved.", "success");
              this.showModal = false;
            }
            else
              this.showMessage(response.data.message, "error");
          } else if (
            response.status == 400 ||
            response.errorText.response.status == 400
          ) {
            this.showMessage(response.errorText.response.data.message, "error");
          } else {
            this.showMessage(window.message.unable_proceed, "error");
          }
        });
    },
    changePassword(u){
      this.oid = u.oid;
      this.showModal = true;
    },
    loginAsOther(u) {
      let param = {
        oid: u.oid
      };
      this.showConfirmDiaglog({
        show: true,
        message: "Are you sure to login as " + u.fullName + "?",
        submitFunction: () => {

      this.showLoadingScreen();
      api.regularPost(apiUrl.account.loginAuthenticateByAdmin, param, this.$store.state.token).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          if (response.data.isSuccess) {
            
            api.setTokenToCookie(response.data.token);
            api.userClaims(null).then((response) => {
              if (response.status == 200) {
                this.$store.state.loginUser = response.data;
                localStorage.setItem('currentUser', JSON.stringify(this.$store.state.loginUser));
                //redirect
                //this.$router.push("/");
                location.href = "/dashboard";
              }
            });
          }
          else if (response.data.enable2FactorAuth) {
            this.showOtpCodeForm = true;
            this.resetOtpTimer();
          }
        }
        else {
          this.showMessage(response.errorText.response.data.message, "error");
        }
      });
        },
      });
    },
    addUser() {
      this.$router.push({ name: 'AddEditUser' });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeFilterName: _.debounce(function (e) {
      this.tableParam.name = e.target.value;
      this.callTable();
    }, 500),
    changeCorporateName: _.debounce(function (e) {
      this.tableParam.corporateName = e.target.value;
      this.callTable();
    }, 500),

    changeUserGroupType(e) {
      this.tableParam.userGroup = e;
      this.callTable();
    },

    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    }
  }
}
</script>
