<template>
  <div>
    <ActivateList :_type="$data._corporateType.contractor.value" />
  </div>
</template>

<script>
import ActivateList from './ActivateList'
export default {
  components: {
    ActivateList
  },
  name: 'ActivateContractors'
}
</script>