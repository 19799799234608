<template>
<div>
    <div>
        <div class="card mb-1">
            <div class="card-body">
                <button class="btn btn-primary m-1 float-end" @click="download">Download Portal Summary</button>
            </div>
            <div class="card-body">
                <h5>Registered MNC</h5>
            </div>
            <div class="card-body">
                <table class="table table-sm">
                    <thead class="table-light">
                        <tr>
                            <th>Company Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, idx) in dataObj.mncList" :key="idx">
                            <td>{{i.corpName}}</td>
                            <td>{{i.statusText}}</td>
                        </tr>
                        <tr>
                            <td>Total No. of Active MNC:</td>
                            <td>{{dataObj.mncActiveTotal}}</td>
                            
                        </tr>
                        <tr>
                            <td>Total No. of Non-Active MNC:</td>
                            <td>{{dataObj.mncNonActiveTotal}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-body">
                <h5>Registered Contractor</h5>
            </div>
            <div class="card-body">
                 <div style="overflow-x: auto;">
                    <table class="table table-sm">
                    <thead class="table-light">
                        <tr>
                            <th>Company Name</th>
                            <th>Total Worker</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, idx) in dataObj.contractorList" :key="idx">
                            <td>{{i.corpName}}</td>
                            <td>{{i.totalWorker}}</td>
                            <td>{{i.statusText}}</td>
                        </tr>
                         <tr>
                            <td>Total No. of Workers:</td>
                            <td>{{dataObj.contractorTotal}}</td>
                            <td></td>
                            
                        </tr>
                        <tr>
                            <td>Total No. of Active Contractor:</td>
                            <td>{{dataObj.contractorActiveTotal}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Total No. of Non-Active Contractor:</td>
                            <td>{{dataObj.contractorNonActiveTotal}}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="card-body">
                <h5>Registered Test Centre (CWQS)</h5>
            </div>
            <div class="card-body">
                 <div style="overflow-x: auto;">
                    <table class="table table-sm">
                    <thead class="table-light">
                        <tr>
                            <th>Company Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, idx) in dataObj.testCenterList" :key="idx">
                            <td>{{i.corpName}}</td>
                            <td>{{i.statusText}}</td>
                        </tr>
                        <tr>
                            <td>Total No. of Active Test Centre:</td>
                            <td>{{dataObj.testCenterActiveTotal}}</td>
                            
                        </tr>
                        <tr>
                            <td>Total No. of Non-Active Test Centre:</td>
                            <td>{{dataObj.testCenterNonActiveTotal}}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                
            </div>
            <div class="card-body">
                <h5>Registered Welding Inspector</h5>
            </div>
            <div class="card-body">
                 <div style="overflow-x: auto;">
                    <table class="table table-sm">
                    <thead class="table-light">
                        <tr>
                            <th>Name</th>
                            <th>Company Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, idx) in dataObj.weldingInspectorList" :key="idx">
                            <td>{{i.name}}</td>
                            <td>{{i.corporName}}</td>
                            <td>{{i.statusText}}</td>
                        </tr>
                        <tr>
                            <td>Total No. of Active Welding Inspector:</td>
                            <td>{{dataObj.weldingInspectorActiveTotal}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Total No. of Non-Active Welding Inspector:</td>
                            <td>{{dataObj.weldingInspectorNonActiveTotal}}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';
export default {
  name: 'PortalSummary',
  components: {
    ...Forms,
  },
  data() {
    return {
      dataObj:[]
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Portal Summary' }
    ]);
    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      api.regularPost(apiUrl.report.portalSummary, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.dataObj = response.data;
        }
      });
    },
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.report.portalSummarydownload,null,"portal_summary.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
  }
}
</script>
