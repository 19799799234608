<template>
  <div>
      <div class="card">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-6">
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Contractor:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.contractorName}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Application No:
                          </div>
                          <div class="col-sm-6 text-secondary">
                              <label class="control-label">{{dataObj.testApplicationId}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Test Date:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.testDateDisplay}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Test Time:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.testTime}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Test Location:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.testLocation}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Test Location Address:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.testLocationAddress}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Contact Person:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.testContactPerson}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Contact Number:
                          </div>
                          <div class="col-sm-6">
                              <label class="control-label">{{dataObj.testContactNumber}}</label>
                          </div>
                      </div>
                      <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Welding Inspector:
                          </div>
                          <div class="col-sm-6">
                              <!-- <FormSelect v-if="dataObj.status == $data._testApplicationStatus.active.value || dataObj.status == $data._testApplicationStatus.pending.value"  :list="weldingInspectors" :cssClass="error.weldingInspectorOid && 'control-error'"
                                  :existing="weldingInspectorOid" value="oid" text="name" @change="weldingInspectorOid=$event"
                                  />

                              <label v-else class="control-label">
                                  {{dataObj.weldingInspectorName}}
                              </label> -->
                              <FormSelect :list="weldingInspectors" :cssClass="error.weldingInspectorOid && 'control-error'"
                                  :existing="weldingInspectorOid" value="oid" text="name" @change="weldingInspectorOid=$event"
                                  />
                          </div>
                      </div>
                      <div v-if="dataObj.status != $data._testApplicationStatus.active.value || dataObj.status != $data._testApplicationStatus.modify.value" class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Reject Reason
                          </div>
                          <div v-if="dataObj.status == $data._testApplicationStatus.pending.value || dataObj.status == $data._testApplicationStatus.modify.value" class="col-sm-6">
                              <textarea class="form-control" :class="error.rejectReason && 'control-error'" v-model="dataObj.rejectReason"></textarea>
                          </div>
                          <div v-else class="col-sm-6">
                            <label class="control-label">{{dataObj.rejectReason}}</label>
                          </div>
                      </div>
                       <div class="row mb-3">
                          <div class="col-sm-6 text-secondary">
                              Status:
                          </div>
                          <div class="col-sm-6">
                            <span v-if="dataObj.status == $data._testApplicationStatus.active.value" class="badge bg-success">{{dataObj.statusDisplay}}</span>
                            <span v-else-if="dataObj.status == $data._testApplicationStatus.pending.value" class="badge bg-primary">{{dataObj.statusDisplay}}</span>
                            <span v-else-if="dataObj.status == $data._testApplicationStatus.rejected.value" class="badge bg-danger">{{dataObj.statusDisplay}}</span>
                            <span v-else-if="dataObj.status == $data._testApplicationStatus.modify.value" class="badge bg-warning">{{dataObj.statusDisplay}}</span>
                            <span v-else-if="dataObj.status == $data._testApplicationStatus.updated.value" class="badge bg-secondary">{{dataObj.statusDisplay}}</span>
                            <span v-else>{{dataObj.statusDisplay}}</span>
                          </div>
                      </div>
                      <div v-if="dataObj.IsTestDateOver" class="row mb-3">
                          <div class="col-sm-6 text-warning">
                              This test date has been over.
                          </div>
                      </div>      
                  </div>
              </div>
              <div style="overflow-x: auto;">
                <table class="table table-sm">
                <thead>
                  <th>NRIC/FIN</th>
                  <th>Work Permit</th>
                  <th>Welder ID</th>
                  <th>Name</th>
                  <th>Yrs Exp</th>
                  <th>Component</th>
                  <th>Medium</th>
                  <th>Test No</th>
                </thead>
                <tbody>
                  <tr v-for="(i, idx) in candidates" :key="idx">
                    <td>{{i.nricFin}}</td>
                    <td>{{i.workPermit}}</td>
                    <td>{{i.welderId}}</td>
                    <td>{{i.workerName}}</td>
                    <td>{{i.yearExperience}}</td>
                    <td>{{i.testComponent}}</td>
                    <td>
                      {{i.testMedium}}
                    </td>
                    <td>
                      {{i.testNumbers.toString()}}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div>
                  <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
                  <button v-if="dataObj.status == $data._testApplicationStatus.pending.value || dataObj.status == $data._testApplicationStatus.modify.value" class="btn btn-success m-1 float-end"
                      @click="submit($data._testApplicationStatus.active.value)">Accept</button>
                  <button v-if="dataObj.status == $data._testApplicationStatus.active.value" class="btn btn-success m-1 float-end"
                      @click="submit($data._testApplicationStatus.active.value)">Change Inspector</button>
                  <button v-if="dataObj.status == $data._testApplicationStatus.pending.value || dataObj.status == $data._testApplicationStatus.modify.value" class="btn btn-danger m-1 float-end" @click="submit($data._testApplicationStatus.rejected.value)">Reject</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'TestApplicationConfirm',

  components: {
    ...Forms,
    ...Pickers
  },

  props: {

  },

  data() {
    return {
      basicLogInfoUrl: apiUrl.sws.getBasicLogInfo,
      oid: 0,
      dataObj: {},
      weldingInspectors:[],
      error: {},
      weldingInspectorOid:0,
      candidates:[]
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  mounted() {
    let pageName = 'Test Application - Detail';
    this.setBreadcrumb([
      { name: 'List', url: '/testapplicationconfirmlist' }, { name: pageName }
    ]);
    this.getWeldingInspector();
    this.getData();
    
    this.hasPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageSws);
  },

  methods: {
    getWeldingInspector() {
      let url = apiUrl.testApplication.weldingInspectorGetAll
      api.regularGet(url, { corporateOid: this.getLoggedInUser().corporateOid }).then((response) => {
        
        this.weldingInspectors = response.data;
      });
    },
    getData() {
      let url = apiUrl.testApplication.getByOid + "/" + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.candidates = this.dataObj.candidates;
        if(this.dataObj.weldingInspectorOid){
          this.weldingInspectorOid = this.dataObj.weldingInspectorOid;
          console.log(this.weldingInspectorOid);
        }
      });
    },

    validate(e){
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.weldingInspectorOid && e != this.$data._testApplicationStatus.rejected.value) 
      { 
        this.error.weldingInspectorOid = true; 
        isValid = false; 
      }

      this.$forceUpdate();
      return isValid;
      
    },
    submit(e) {

      if (!this.validate(e))
        return;

      let subUrl = apiUrl.testApplication.updateStatus;

      if(e == this.$data._testApplicationStatus.rejected.value && !this.dataObj.rejectReason){
        this.showMessage("Enter reject reason", "warning");
        return;
      }
      this.showLoadingScreen();
      let data = {
        oid: this.dataObj.oid,
        rejectReason: this.dataObj.rejectReason,
        status: e,
        weldingInspectorOid:this.weldingInspectorOid
      }
      api.regularPost(subUrl, data).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "TestApplicationConfirmList" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "TestApplicationConfirmList" });
    }
  }
}
</script>
