<template>
  <div>
    <div class="my-tab">
      <div class="my-tab-item" :class="currentTab=='unread' && 'active'" @click="clickTab('unread')">Unread</div>
      <div class="my-tab-item" :class="currentTab=='read' && 'active'" @click="clickTab('read')">Read</div>
    </div>
    <div v-show="currentTab=='unread'">
      <div class="row">
        <div class="col-md-8">
          <div class="card mb-2" v-for="(l,i) in unreadList" :key=i>
            <div class="card-body">
              <p v-html="l.userMessage"></p>
              <div>
                <i class="bi bi-trash hover" @click="deleteNotification(l.oid)"></i>
                <div class="float-end text-secondary small">{{l.dateDisplay}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="currentTab=='read'">
      <div class="row">
        <div class="col-md-8">
          <div class="card mb-2" v-for="(l,i) in readList.listData" :key=i>
            <div class="card-body">
              <p v-html="l.userMessage"></p>
              <div>
                <i class="bi bi-trash hover" @click="deleteNotification(l.oid)"></i>
                <div class="float-end text-secondary small">{{l.dateDisplay}}</div>
              </div>
            </div>
          </div>
          <Pagination :pagingParam="readList" @updatePaging="updatePagingRead" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'UserNotification',
  components:{
    ...TableAddons
  },
  data() {
    return {
      currentTab: 'unread',

      unreadList: [],
      readList: this.dtoPaging(),
    }
  },

  beforeMount() {

  },

  mounted() {
    this.setBreadcrumb([
      { name: 'Notifications' }
    ]);

    this.clickTab(this.currentTab);
  },

  methods: {
    clickTab(tab) {
      this.currentTab = tab;
      if (this.currentTab == 'unread') {
        this.loadUnreadList();
      }
      else if (this.currentTab == 'read') {
        this.loadReadList();
      }
    },
    loadUnreadList() {
      this.unreadList = [];
      this.showLoadingScreen();
      api.regularGet(apiUrl.notification.getUnreadNotifications).then((res) => {
        this.hideLoadingScreen();
        if (res.status == 200) {
          this.unreadList = res.data;
          this.setAllUnreadAsRead();
        }
      });
    },
    loadReadList() {
      this.readList.listData = [];
      this.showLoadingScreen();
      api.regularPost(apiUrl.notification.getReadNotifications, this.readList).then((res) => {
        this.hideLoadingScreen();
        if (res.status == 200) {
          this.readList = res.data;
        }
      });
    },
    deleteNotification(oid) {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove?',
          submitFunction: () => {
            api.regularPost(apiUrl.notification.deleteNotificatoin, { oid: oid }).then((res) => {
              if (res.status == 200) {
                this.showMessage("Successfully deleted.", "success");
                this.clickTab(this.currentTab);
              }
              else if (res.status == 400 || res.errorText.response.status == 400) {
                this.showMessage(res.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.unable_proceed, "error");
              }
            });
          }
        });
    },
    setAllUnreadAsRead() {
      api.regularPost(apiUrl.notification.setAllUnreadAsRead, null).then((res) => {
        if (res.status == 200) {
          this.$store.state.unreadNotiCount = "";
          this.$store.state.unreadNotiList = [];
        }
        else if (res.status == 400 || res.errorText.response.status == 400) {
          console.log(res.errorText.response.data.message);
        }
        else {
          console.log(window.message.unable_proceed);
        }
      });
    },

    updatePagingRead(paramObj) {
      this.readList.currentpage = paramObj.currentPage;
      this.readList.rowcount = paramObj.rowPerPage;
      this.loadReadList();
    },

  }
}
</script>
