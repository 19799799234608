<template>
  <!--UPLOAD-->
  <div>
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div class="dropbox">
        <input type="file" :name="uploadFieldName" :disabled="isSaving" :multiple="multiple"
          @change="filesChange($event.target.name, $event.target.files);" :accept="accept" class="input-file">
        <p v-if="isInitial">
          <i class="h1 text-muted dripicons-cloud-upload"></i><br />
          Drag your file here <br> or click to upload
        </p>
        <p v-if="isSaving">
          Uploading file...
        </p>
      </div>
    </form>
    <div v-if="isSuccess" style="border:1px solid grey;padding:5px;">
      <div class="text-end" style="margin-top:-9px;margin-right:-6px;margin-bottom:5px;">
        <a href="javascript:void(0);" @click="reset"><button class="btn btn-secondary btn-sm" style="padding:0px;"><i class="bi bi-x"></i></button></a>
      </div>
      <table class="table" style="margin-bottom:0px;">
        <tbody>
          <tr v-for="(f, i) in selectedFiles" :key="i" :class="(i%2)==0?'table-success':'table-primary'">
            <td>
              {{f.name}}
            </td>
            <td class="text-end" v-if="multiple">
              <a href="javascript:void(0);" class="text-danger" @click="deleteEach(f.name)"><i class="bi bi-trash"></i></a>
            </td>
          </tr>
        </tbody>
      </table>    
    </div>
    <div>
      <small v-if="isShowMsg" class="text-danger">*File type allowed: <span>{{accept}}</span></small>
    </div>
  </div>
</template>

<style scoped>
.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: white;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #d7daff;
  cursor: pointer;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 10px 0;
}
</style>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  name: 'File-Input',
  props: {
    url: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: 'stop'
    },
    accept: {
      type: String,
      default: '.bmp,.jpg,.jpeg,.gif,.png,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.rtf'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isShowMsg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'file',
      selectedFiles: []
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  watch: {
    status(newValue) {
      if (newValue == "reset") {
        this.reset();
      }
      this.$emit('done');
    },
  },
  mounted() {
    this.reset();
  },
  methods: {

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.$emit('selectFile', null);
    },
    deleteEach(fName) {
      let idx = -1;
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].name == fName) {
          idx = i;
          break;
        }
      }
      console.log(idx);
      if (idx > -1) {
        this.selectedFiles.splice(idx, 1);
      }
      this.refreshSelectedFiles();

      if(this.selectedFiles.length <= 0){
        this.reset();
      }
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      if (!fileList.length) return;
      for (let i = 0; i < fileList.length; i++) {
        let fileName = fileList[i].name;
        let tmpArr = fileName.split('.');
        let extension = tmpArr[tmpArr.length - 1];
        //let allowedTypes = ["bmp", "jpg", "jpeg", "gif", "png", "pdf", "doc", "docx", "xls", "xlsx", "csv", "txt", "rtf"];
        let allowedTypes = this.accept.split(',').map(x => x.replace('.', '').toLowerCase());
        if (allowedTypes.indexOf(extension.toLowerCase()) == -1) {
          return;
        }
      }
      
      this.selectedFiles = [...fileList];
      this.refreshSelectedFiles();
      
    },
    refreshSelectedFiles(){
      if (this.multiple) {
        this.$emit('selectFile', this.selectedFiles);
      }
      else {
        this.$emit('selectFile', this.selectedFiles[0]);
      }
      this.currentStatus = STATUS_SUCCESS;
    }
  },
}

</script>
