<template>
  <div>
    <div v-if="isAbleToUpdate">
      <h4>Test Application Result Update Detail</h4>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Contractor
        </div>
        <div class="col-md-3">
          {{dataObj.contractorName}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Application No.
        </div>
        <div class="col-md-3">
          {{dataObj.testApplicationId}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Application Date
        </div>
        <div class="col-md-3">
          {{dataObj.createdDate}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          No. of Workers
        </div>
        <div class="col-md-3">
          {{dataObj.noOfWorkers}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Test Date
        </div> 
        <div class="col-md-3">
          {{dataObj.testDateDisplay}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Test Time
        </div> 
        <div class="col-md-3">
          {{dataObj.testTime}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Test Location
        </div> 
        <div class="col-md-3">
          {{dataObj.testLocation}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Test Location Address
        </div> 
        <div class="col-md-3">
          {{dataObj.testLocationAddress}}
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Welding Inspector
        </div> 
        <div class="col-md-3">
          {{dataObj.weldingInspectorName}}
        </div>
      </div>

      <div class="text-end">
        <button class="btn btn-primary" @click="clickResultTemplate">Result Template</button>
      </div>
      <div style="overflow-x: auto;">
        <table class="table table-sm">
        <thead class="table-light">
          <tr>
            <th>NRIC/FIN</th>
            <th>Work Permit</th>
            <th>Welder ID</th>
            <th>Name</th>
            <th>Yrs Exp.</th>
            <th>Component</th>
            <th>Medium</th>
            <th>Test No.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in selectedWorkers" :key="idx">
            <td>{{i.nricFin}}</td>
            <td>{{i.workPermit}}</td>
            <td>{{i.welderId}}</td>
            <td>{{i.workerName}}</td>
            <td>
              {{i.yearExperience}}
            </td>
            <td>
              {{i.testComponent}}
            </td>
            <td>
              {{i.testMedium}}
            </td>
            <td>
              <div v-for="(ii, iidx) in i.testNumbersWithStatus" :key="iidx">
                <button class="btn btn-xs btn-primary" @click="clickTestNumber(ii, i)">{{ii.testNumber}}</button> 
                <span :class="ii.status==$data._testApplicationCandidateStatus.pass.value?'text-success':ii.status==$data._testApplicationCandidateStatus.fail.value?'text-danger':''">
                  {{ii.statusDisplay}}
                </span>

                <span v-if="ii.workerSkillTestStatus"> / {{getWorkerSkillTestStatusTextByValue(ii.workerSkillTestStatus)}}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      

      <div class="text-end">
        <button class="btn btn-secondary m-1" @click="clickCancel">Back</button>
      </div>
    </div>
    <div v-else>
      <h3 class="text-danger text-center">[Data is no able to update!]</h3>
    </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'ViewTestApplication',
  components: {
  },
  data() {
    return {
      oid: 0,
      dataObj: {},
      selectedWorkers: [],
      isAbleToUpdate: true
    }
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = 'View';
    this.setBreadcrumb([
      { name: 'Test Applications', url: '/testapplicationlist' }, { name: pageName }
    ]);

    if (this.oid > 0) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      let url = apiUrl.testApplication.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.dataObj.candidates.forEach(w => {
          this.selectedWorkers.push(w);
        });

        //Check future date
        let today = new Date();
        let testDate = this.convertDateTimeStrToDateObj(this.dataObj.testDate);
        if (testDate.getTime() > today.getTime()) {
          this.isAbleToUpdate = false;
        }

      });
    },

    clickCancel() {
      history.back();
    },

    clickTestNumber(obj, parentObj) {

      this.$router.push({
        name: 'TestApplicationResultUpdate', params:
        {
          workerOid: parentObj.workerOid,
          skillOid: this.dataObj.skillOid,
          testDate: this.dataObj.testDate,
          testNumber: obj.testNumber,
          applicationOid: this.dataObj.oid,
          candidateOid: obj.candidateOid
        }
      });
    },

    clickResultTemplate() {
      this.$router.push({ name: 'TestApplicationResultUpdateBatch', params: { applicationOid: this.oid } });
    },

  }
}
</script>
