<template>
  <div>
    <h4>Welder Assessment Consolidation</h4>

    <div class="card">
      <div class="card-header bg-secondary text-white hover" data-bs-toggle="collapse" href="#div_filter">
        Filter
      </div>
      <div class="card-body">
        <div class="collapse" id="div_filter">
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              NRIC/FIN
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.nricFin" />
            </div>
            <div class="col-md-3 text-secondary">
              Welder ID
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.welderId" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Name
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.name" />
            </div>
            <div class="col-md-3 text-secondary">
              Contractor
            </div>
            <div class="col-md-3">
              <FormSelect :list="contractors" value="oid" text="corpName" :existing="tableParam.contractorOid" @change="tableParam.contractorOid=$event" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Industry
            </div>
            <div class="col-md-3">
              <FormSelect :list="industries" value="oid" text="corpName" :existing="tableParam.industryOid" @change="tableParam.industryOid=$event" />
            </div>
            <div class="col-md-3 text-secondary">
              Job Category
            </div>
            <div class="col-md-3">
              <FormSelect :list="jobCategories" value="value" text="text" :existing="tableParam.jobCategory" @change="tableParam.jobCategory=$event" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Location
            </div>
            <div class="col-md-3">
              <FormSelect :list="locations" value="oid" text="name" :existing="tableParam.locationOid" @change="tableParam.locationOid=$event" />
            </div>
            <div class="col-md-3 text-secondary">
              Performance Date
            </div>
            <div class="col-md-3">
              <DatePicker :existing="null" @updatedate="changePerformanceDate" />
            </div>
          </div>
          <div class="mb-1 text-end">
            <button class="btn btn-primary" @click="callTable">Search</button>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <th>NRIC/FIN</th>
          <th>WelderID</th>
          <th>Name</th>
          <th>Contractor</th>
          <th>Industry</th>
          <th>Job Category</th>
          <th>Location</th>
          <th>Performance Date</th>
          <th>RT Report No</th>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.nricFin}}</td>
          <td>{{i.welderId}}</td>
          <td>{{i.name}}</td>
          <td>{{i.contractorName}}</td>
          <td>{{i.industryName}}</td>
          <td>{{getJobCategoryTextByValue(i.jobCategory)}}</td>
          <td>{{i.locationName}}</td>
          <td>{{i.performanceDate}}</td>
          <td>{{i.rtReportNo}}</td>
          <td>
            <button class="btn btn-sm btn-primary btn-sm" @click="clickUpdate(i)">Update</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
    
    <Pagination :pagingParam="tableParam" @updatePaging="updatePaging" />
  </div>
</template>

<script>
import api from '@/common/api';
import apiUrl from '@/common/apiurl';
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'AssessmentList',
  components: {
    ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),

      contractors: [],
      industries: [],
      jobCategories: [],
      locations: [],

    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Assessment' }
    ]);

    this.tableParam.contractorOid = null;
    this.tableParam.industryOid = null;
    this.tableParam.jobCategory = '';
    this.tableParam.locationOid = null;

    this.jobCategories = [
      { value: '', text: '<All>' },
      //{ value: this.$data._jobCategory.all.value, text: this.$data._jobCategory.all.text },
      { value: this.$data._jobCategory.construction.value, text: this.$data._jobCategory.construction.text },
      { value: this.$data._jobCategory.maintenance.value, text: this.$data._jobCategory.maintenance.text },
      { value: this.$data._jobCategory.shutdown.value, text: this.$data._jobCategory.shutdown.text },
    ];

    api.getAllCorporates(null).then(response => {
      this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
      this.industries.unshift({ oid: null, corpName: '<All>' });
      this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      this.contractors.unshift({ oid: null, corpName: '<All>' });
    });

    api.regularGet(apiUrl.site.getList).then(res => {
      this.locations = res.data;
      this.locations.unshift({ oid: null, name: '<All>' });
    });

    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.assessment.getAssessmentList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickUpdate(i) {
      this.$router.push({ name: "AssessmentEntry", params: { blockId: i.blockId, workerOid: i.workerOid, rtReportNo: i.rtReportNo, rtReportOid: i.rtReportOid } });
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },

    changePerformanceDate(date) {
      this.tableParam.performanceDate = this.convertDateObjToDateStr(date);
    },
  }
}
</script>
