<template>
  <div>
    <div class="row mb-4">
      <div class="col-4">
        <button class="btn w-100" :class="currentStep==1?'btn-secondary':'btn-light'" disabled>Work Assignment Details</button>
      </div>
      <div class="col-4">
        <button class="btn w-100" :class="currentStep==2?'btn-secondary':'btn-light'" disabled>Select Workers</button>
      </div>
      <div class="col-4">
        <button class="btn w-100" :class="currentStep==3?'btn-secondary':'btn-light'" disabled>Review and Submit</button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">

        <div v-show="currentStep==1">
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Assign To
            </div>
            <div class="col-md-4">
              <FormSelect :list="assignToList" :cssClass="error.assignTo && 'control-error'" value="value" text="text"
                @change="changeAssignTo" />
            </div>
          </div>
          <div class="row mb-2" v-show="showContractor">
            <div class="col-md-3 text-secondary">
              Contractor
            </div>
            <div class="col-md-4">
              <FormSelect :list="contractors" value="oid" text="corpName" :cssClass="error.toContractorOid && 'control-error'"
              @change="changeToContractor" :existing="dataObj.toContractorOid" />
            </div>
          </div>
          <div class="row mb-2" v-show="showAssignType">
            <div class="col-md-3 text-secondary">
              Assign Type <i class="bi bi-info-circle text-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Click here for more info" @click="showAssignTypeTooltip"></i>
            </div>
            <div class="col-md-4">
              <FormSelect :list="assignTypes" value="value" text="text" @change="changeAssignType" :cssClass="error.assignType && 'control-error'"
              :existing="dataObj.assignType" />
            </div>
          </div>
          <div class="row mb-2" v-show="showIndustry">
            <div class="col-md-3 text-secondary">
              Industry
            </div>
            <div class="col-md-4">
              <FormSelect :list="industries" :cssClass="error.industryOid && 'control-error'" value="oid" text="corpName"
              @change="changeIndustry" :existing="dataObj.industryOid" />
            </div>
          </div>
          <div class="row mb-2" v-show="showProjectCode">
            <div class="col-md-3 text-secondary">
              Project Code
            </div>
            <div class="col-md-4">
              <div class="input-group">
                <FormSelect :list="projectCodes" :cssClass="error.industryProjectOid && 'control-error'" value="oid"
                  text="projectCode" :existing="dataObj.industryProjectOid" @change="dataObj.industryProjectOid=$event" />
                <button class="btn btn-secondary" @click="clickManageProjectCode"><i class="bi bi-plus"></i></button>
              </div>
              <ManageProjectCode :industryOid="dataObj.industryOid" :industryName="industryName" :showForm="showManageProjectCodeForm" @done="doneManageProjectCode" />
            </div>
          </div>
          <div class="row mb-2" v-show="showJobCategory">
            <div class="col-md-3 text-secondary">
              Job Category
            </div>
            <div class="col-md-4">
              <FormSelect :list="jobCategories" value="value" text="text" :cssClass="error.jobCategory && 'control-error'"
              @change="dataObj.jobCategory=$event" />
            </div>
          </div>
          <div class="row mb-2" v-show="showLocation">
            <div class="col-md-3 text-secondary">
              Location
            </div>
            <div class="col-md-4">
              <div class="input-group">
                <FormSelect :list="siteLocations" :cssClass="error.siteLocationOid && 'control-error'" value="oid"
                  text="name" :existing="dataObj.siteLocationOid" @change="dataObj.siteLocationOid=$event" />
                <button class="btn btn-secondary" @click="clickManageSiteLocation"><i class="bi bi-plus"></i></button>
              </div>
              <ManageSiteLocation :industryOid="dataObj.industryOid" :industryName="industryName" :showForm="showManageSiteLocationForm" @done="doneManageSiteLocation" />
            </div>
          </div>
          <div class="row mb-2" v-show="showWeldingInspector">
            <div class="col-md-3 text-secondary">
              Welding Inspector
            </div>
            <div class="col-md-4">
              <FormSelect :list="weldingInspectors" value="oid" text="nameWithCorpName" :cssClass="error.weldingInspectorOid && 'control-error'"
              @change="dataObj.weldingInspectorOid=$event" />
              <div class="text-secondary small" v-show="dataObj.assignType!=$data._assignType.workshop.value">
                For welder assignment, select a Welding Inspector from the list;<br/>
                For non-welder assignment, select "Assigned by Industry".
              </div>
            </div>
          </div>
          
          <div class="mb-2 mt-4 text-end">
            <button class="btn btn-primary" @click="clickNextSelectWorkers">Next <i class="bi bi-arrow-right-circle"></i></button>
          </div>
          
        </div>

        <div v-show="currentStep==2">
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Start Date
            </div>
            <div class="col-md-4">
              <DatePicker :cssClass="error.assignDateFrom && 'control-error'" 
              @updatedate="changeStartDate" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              End Date
            </div>
            <div class="col-md-4">
              <DatePicker :cssClass="error.assignDateTo && 'control-error'" 
              @updatedate="changeEndDate" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Shift
            </div>
            <div class="col-md-9">
              <label class="hover me-5"><input type="checkbox" v-model="dataObj.morning" /> M(Morning)</label>
              <label class="hover me-5"><input type="checkbox" v-model="dataObj.afternoon" /> A(Afternoon)</label>
              <label class="hover me-5"><input type="checkbox" v-model="dataObj.night" /> N(Night)</label>
            </div>
          </div>
          <div class="mb-2 text-end">
            <button class="btn btn-sm btn-primary" @click="addWorker">Select Worker</button>
          </div>
          <div class="mb-2">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>NRIC/FIN</th>
                  <th>Welder ID</th>
                  <th>Work Permit	</th>
                  <th>Name</th>
                  <th>Skill</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Shift</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l,i) in selectedWorkers" :key="i">
                  <td>{{l.nricFin}}</td>
                  <td>{{l.welderId}}</td>
                  <td>{{l.workPermit}}</td>
                  <td>{{l.name}}</td>
                  <td>{{l.techSkill}}</td>
                  <td>{{dataObj.assignDateFrom}}</td>
                  <td>{{dataObj.assignDateTo}}</td>
                  <td>
                    <span v-if="dataObj.morning">M </span>
                    <span v-if="dataObj.afternoon">A </span>
                    <span v-if="dataObj.night">N </span>
                  </td>
                  <td><button class="btn btn-sm" @click="removeWorker(i)"><i class="bi bi-trash-fill"></i></button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-2 mt-4">
            <button class="btn btn-primary me-1" @click="clickPrevWorkAssignmentDetails"><i class="bi bi-arrow-left-circle"></i> Work Assignment Details</button>
            <button class="btn btn-primary float-end" @click="clickNextReviewAndSubmit">Review and Submit <i class="bi bi-arrow-right-circle"></i></button>
          </div>

              <ModalDiaglog 
              :show="showWorkerList" 
              size="xl"
              :onClose="()=>{showWorkerList = false;}"
              :title="'Select Worker'"
              :onSubmit1="selectWorker"
              :submitBtn1Text="'Select'"
              >
                <div>
                  <div class="card">
                    <div class="card-body">
                      <div class="row mb-2">
                        <div class="col-md-6">
                          <div class="row mb-1">
                            <div class="col-sm-5">
                              NRIC/FIN
                            </div>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="tableParam.nricFin" />
                            </div>
                          </div>
                          <div class="row mb-1">
                            <div class="col-sm-5">
                              Work Permit No.
                            </div>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="tableParam.workPermit" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row mb-1">
                            <div class="col-sm-5">
                              Welder ID
                            </div>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="tableParam.welderId" />
                            </div>
                          </div>
                          <div class="row mb-1">
                            <div class="col-sm-5">
                              Name
                            </div>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="tableParam.name" />
                            </div>
                          </div>
                          <div class="text-end">
                            <button class="btn btn-primary me-1" @click="clickSearchWorker">Search</button>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
              <div class="text-secondary txt-small">
                Note: Workers must have (status=Active, skill set contains welding, work permit expiry date >= today, current user's corporate workers, 
                commence date less then and equal today, worker skill tests of welding skill have status=active result=pass skilltest's expirydate>=previous 5 days or have non-welding skill,
                , Work permit expiry date is valid (for non-singaporean), AWSHPP is valid for industry assignment.
              </div>
              <table class="table table-sm">
                <thead class="table-light">
                  <tr>
                    <th></th>
                    <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
                    <SortColumn @click="sortColumn" sortBy="nricFin" colGroup="tbl1">NRIC/FIN</SortColumn>
                    <SortColumn @click="sortColumn" sortBy="workPermit" colGroup="tbl1">Work Permit No.</SortColumn>
                    <SortColumn @click="sortColumn" sortBy="welderId" colGroup="tbl1">Welder ID</SortColumn>
                    <!-- <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, idx) in tableParam.listData" :key="idx">          
                    <td><input type="checkbox" v-model="i.selected" class="hover" /></td>
                    <td>{{i.name}}</td>
                    <td>{{i.nricFin}}</td>
                    <td>{{i.workPermit}}</td>
                    <td>{{i.welderId}}</td>
                    <!-- <td>
                      <span class="badge bg-success" v-if="i.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
                      <span class="badge bg-danger" v-else-if="i.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
                      <span class="badge" v-else>{{i.statusDisplay}}</span>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <Pagination
                :pagingParam="tableParam"
                @updatePaging="updatePaging"
              />

                </div>
              </ModalDiaglog>

              

        </div>

        <div v-show="currentStep==3">
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Assign To
            </div>
            <div class="col-md-4">
              {{summaryObj.assignTo}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Contractor
            </div>
            <div class="col-md-4">
              {{summaryObj.contractor}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Assign Type
            </div>
            <div class="col-md-4">
              {{summaryObj.assignType}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Welding Inspector
            </div>
            <div class="col-md-4">
              {{summaryObj.weldingInspector}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Industry
            </div>
            <div class="col-md-4">
              {{summaryObj.industry}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Project Code
            </div>
            <div class="col-md-4">
              {{summaryObj.projectCode}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Job Category
            </div>
            <div class="col-md-4">
              {{summaryObj.jobCategory}}
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-3 text-secondary">
              Location
            </div>
            <div class="col-md-4">
              {{summaryObj.location}}
            </div>
          </div>
          <h5>Selected Workers</h5>
          <div style="overflow-x: auto;">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>NRIC/FIN</th>
                  <th>Welder ID</th>
                  <th>Work Permit	</th>
                  <th>Name</th>
                  <th>Skill</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Shift</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l,i) in selectedWorkers" :key="i">
                  <td>{{l.nricFin}}</td>
                  <td>{{l.welderId}}</td>
                  <td>{{l.workPermit}}</td>
                  <td>{{l.name}}</td>
                  <td>{{l.techSkill}}</td>
                  <td>{{dataObj.assignDateFrom}}</td>
                  <td>{{dataObj.assignDateTo}}</td>
                  <td>
                    <span v-if="dataObj.morning">M </span>
                    <span v-if="dataObj.afternoon">A </span>
                    <span v-if="dataObj.night">N </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-2 mt-4">
            <button class="btn btn-primary me-1" @click="clickPrevSelectWorkers"><i class="bi bi-arrow-left-circle"></i> Select Workers</button>
            <button class="btn btn-primary float-end" @click="clickSubmitWorkerAssignment">Submit Worker Assignment <i class="bi bi-arrow-right-circle"></i></button>
          </div>
        </div>

      </div>
    </div>
    <div class="text-primary">
      <span>Remark:</span>
      <ol>
        <li>For welder performance update by a WI from a test center, please follow the steps below;<br/>
            Assign to: Choose “Contractor”<br/>
            Contractor: Select the name of your own corporate as contractor<br/>
            Assign type: Choose “Workshop”<br/><br/>      
        </li>
        <li>For welder performance at Worksite using production weld at the site, please follow the steps below;<br/>
              Assign to: Choose “Industry”<br/>
              Industry: Select an industry from the list<br/>
              Project code: Select from the list or create a new code<br/>
              Job category: Select from the list<br/>
              Welding Inspector: Select from the list – only WI from the selected industry & WI from delegated test center(s) by the selected industry will be shown.<br/>

        </li>
      </ol>
    </div>
    <ModalDiaglog 
              :show="showAssignTooltip" :onClose="()=>{showAssignTooltip = false;}" :title="'Assign Type'" :showSubmitBtn1="false"
              >
                <div class="text-primary">
                    <ul>
                      <li>Job - Assign to another contractor workplace for job basis and normally for a short period of time 1 – 6 months<br/></li>
                      <li>Resident - Assign to another contractor workplace for long term i.e., 1 – 2 years;<br/></li>
                      <li>Workshop - For welder performance update, to extend to welder qualification after the first six month qualifying period.<br/></li>
                    </ul>
                </div>
    </ModalDiaglog>
  </div>
</template>

<script>
import ModalDiaglog from "@/components/custom/ModalDiaglog";
import * as Forms from '@/components/custom/forms';
import ManageProjectCode from '@/components/custom/instant_setup_forms/ManageProjectCode';
import ManageSiteLocation from '@/components/custom/instant_setup_forms/ManageSiteLocation';
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Pickers from '@/components/custom/pickers';
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'AddWorkerAssignment',
  components: {
    ...Forms,
    ManageProjectCode,
    ManageSiteLocation,
    ...Pickers,
    ModalDiaglog,
    ...TableAddons,
  },
  data() {
    return {
      showAssignTooltip:false,
      isContractor: false,
      currentStep: 1,
      dataObj: {},
      assignToList: [],
      industries: [],
      projectCodes: [],
      assignTypes: [],
      siteLocations: [],
      jobCategories: [],
      weldingInspectors: [],
      contractors: [],

      showIndustry: false,
      showContractor: false,
      showAssignType: false,
      showProjectCode: false,
      showJobCategory: false,
      showLocation: false,
      showWeldingInspector: false,

      showManageProjectCodeForm: false,
      showManageSiteLocationForm: false,

      selectedWorkers: [],
      tableParam: this.dtoPaging(),
      showWorkerList: false,

      error: {},
      summaryObj: {},
      industryName: '',
    }
  },
  beforeMount() {
    this.tableParam.rowPerPage = 10;
  },
  mounted() {
    this.dataObj.morning = true;
    this.dataObj.afternoon = true;
    this.dataObj.night = true;
    //let currentUser = this.getLoggedInUser();

    this.assignToList = [
      { value: this.$data._assignTo.industry.value, text: this.$data._assignTo.industry.text },
      { value: this.$data._assignTo.contractor.value, text: this.$data._assignTo.contractor.text },
    ];

    this.assignTypes = [
      { value: this.$data._assignType.job.value, text: this.$data._assignType.job.text },
      { value: this.$data._assignType.resident.value, text: this.$data._assignType.resident.text },
      { value: this.$data._assignType.workshop.value, text: this.$data._assignType.workshop.text },
    ];

    this.jobCategories = [
      //{ value: this.$data._jobCategory.all.value, text: this.$data._jobCategory.all.text },
      { value: this.$data._jobCategory.construction.value, text: this.$data._jobCategory.construction.text },
      { value: this.$data._jobCategory.maintenance.value, text: this.$data._jobCategory.maintenance.text },
      { value: this.$data._jobCategory.shutdown.value, text: this.$data._jobCategory.shutdown.text },
    ];

    api.getAllCorporates(null).then(response => {
      this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
      this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
    });



  },
  methods: {
    showAssignTypeTooltip(){
      this.showAssignTooltip = true;
    },
    getProjectCodes() {
      this.projectCodes = [];
      api.regularGet(apiUrl.industryProject.getList, { corporateOid: this.dataObj.industryOid }).then(res => {
        this.projectCodes = res.data;
      });
    },
    getSiteLocations() {
      this.siteLocations = [];
      api.regularGet(apiUrl.site.getList, { corporateOid: this.dataObj.industryOid }).then(res => {
        this.siteLocations = res.data;
      })
    },
    getWeldingInspectors(corporateOid, isIndustry, isOnlyTestCentreWI) {
      this.weldingInspectors = [];
      let param = {};
      let url = '';
      if (isIndustry) {
        param = { industryOid: corporateOid }
        url = apiUrl.testApplication.getWeldingInspectorsWithTSDelegates;
      }
      else if (isOnlyTestCentreWI) {
        param = { isOnlyTestCentreWI: true }
        url = apiUrl.testApplication.weldingInspectorGetAll;
      }
      else {
        param = { corporateOid: corporateOid }
        url = apiUrl.testApplication.weldingInspectorGetAll;
      }
      api.regularGet(url, param).then((response) => {
        let weldingInspectors = response.data;
        weldingInspectors.forEach(x => x.nameWithCorpName = x.name + ' (' + x.corporName + ')');

        if (this.dataObj.assignTo != this.$data._assignTo.contractor.value || this.dataObj.assignType != this.$data._assignType.workshop.value) {
          this.weldingInspectors.push({ oid: -1, nameWithCorpName: 'Assigned by Industry' });
        }
        weldingInspectors.forEach(x => this.weldingInspectors.push(x));
      });
    },
    hideAllForm(clearToContractor) {
      this.showIndustry = false;
      this.showContractor = false;
      this.showAssignType = false;
      this.showProjectCode = false;
      this.showJobCategory = false;
      this.showLocation = false;
      this.showWeldingInspector = false;

      if (clearToContractor)
        this.dataObj.toContractorOid = null;
      this.dataObj.industryOid = null;
      this.dataObj.assignType = null;

    },
    changeAssignTo(val) {
      this.hideAllForm(true);
      if (val == this.$data._assignTo.industry.value) {
        this.showIndustry = true;
        this.showProjectCode = true;
        this.showJobCategory = true;
        this.showLocation = true;
        this.showWeldingInspector = true;
        this.isContractor = false;
      }
      if (val == this.$data._assignTo.contractor.value) {
        this.showContractor = true;
        this.showAssignType = true;
        this.isContractor = true;
      }
      this.dataObj.assignTo = val;
    },
    clickManageProjectCode() {
      if (!this.dataObj.industryOid) {
        this.showMessage('Please select industry.', 'warning');
        return;
      }
      this.showManageProjectCodeForm = false;
      setTimeout(() => {
        this.showManageProjectCodeForm = true;
      }, 100);
    },
    clickManageSiteLocation() {
      if (!this.dataObj.industryOid) {
        this.showMessage('Please select industry.', 'warning');
        return;
      }
      this.showManageSiteLocationForm = false;
      setTimeout(() => {
        this.showManageSiteLocationForm = true;
      }, 100);
    },
    changeToContractor(val) {
      this.dataObj.toContractorOid = val;

      //Assign to itself
      if (this.dataObj.toContractorOid == this.getLoggedInUser().corporateOid) {
        this.assignTypes = [
          { value: this.$data._assignType.workshop.value, text: this.$data._assignType.workshop.text },
        ];
        this.dataObj.assignType = null;
        this.changeAssignType();
      }
      else {
        this.assignTypes = [
          { value: this.$data._assignType.job.value, text: this.$data._assignType.job.text },
          { value: this.$data._assignType.resident.value, text: this.$data._assignType.resident.text },
        ];
      }

      this.getWeldingInspectors(this.dataObj.toContractorOid, false);
    },
    changeIndustry(val) {
      this.dataObj.industryOid = val;
      this.getProjectCodes();
      this.getSiteLocations();
      this.getWeldingInspectors(this.dataObj.industryOid, true);
      this.industryName = this.industries.find(x => x.oid == val).corpName;
    },
    changeAssignType(val) {
      this.hideAllForm();
      this.showContractor = true;
      this.showAssignType = true;

      if (val == this.$data._assignType.job.value) {
        this.showIndustry = true;
        this.showProjectCode = true;
        this.showJobCategory = true;
        this.showLocation = true;
      }
      //Workshop
      if (val == this.$data._assignType.workshop.value) {
        this.showWeldingInspector = true;
        this.getWeldingInspectors(null, false, true);
      }
      this.dataObj.assignType = val;
    },
    clickNextSelectWorkers() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.assignTo) {
        this.error.assignTo = true; isValid = false;
      }
      //Industry
      else if (this.dataObj.assignTo == this.$data._assignTo.industry.value) {
        if (!this.dataObj.industryOid) { this.error.industryOid = true; isValid = false; }
        if (!this.dataObj.industryProjectOid) { this.error.industryProjectOid = true; isValid = false; }
        if (!this.dataObj.jobCategory) { this.error.jobCategory = true; isValid = false; }
        if (!this.dataObj.siteLocationOid) { this.error.siteLocationOid = true; isValid = false; }
        if (!this.dataObj.weldingInspectorOid) { this.error.weldingInspectorOid = true; isValid = false; }

        this.dataObj.toContractorOid = null;
        this.dataObj.assignType = null;
      }
      //Contractor
      else if (this.dataObj.assignTo == this.$data._assignTo.contractor.value) {
        if (!this.dataObj.toContractorOid) { this.error.toContractorOid = true; isValid = false; }
        if (!this.dataObj.assignType) { this.error.assignType = true; isValid = false; }

        //Job
        if (this.dataObj.assignType == this.$data._assignType.job.value) {
          if (!this.dataObj.industryOid) { this.error.industryOid = true; isValid = false; }
          if (!this.dataObj.industryProjectOid) { this.error.industryProjectOid = true; isValid = false; }
          if (!this.dataObj.jobCategory) { this.error.jobCategory = true; isValid = false; }
          if (!this.dataObj.siteLocationOid) { this.error.siteLocationOid = true; isValid = false; }

          this.dataObj.weldingInspectorOid = null;
        }
        //Workshop
        else if (this.dataObj.assignType == this.$data._assignType.workshop.value) {
          if (!this.dataObj.weldingInspectorOid) { this.error.weldingInspectorOid = true; isValid = false; }

          this.dataObj.industryOid = null;
          this.dataObj.industryProjectOid = null;
          this.dataObj.jobCategory = null;
          this.dataObj.siteLocationOid = null;
        }
        //Resident
        else if (this.dataObj.assignType == this.$data._assignType.resident.value) {
          this.dataObj.industryOid = null;
          this.dataObj.industryProjectOid = null;
          this.dataObj.jobCategory = null;
          this.dataObj.siteLocationOid = null;
          this.dataObj.weldingInspectorOid = null;
        }
      }

      if (!isValid) {
        this.showMessage("Please select the required information.", "warning");
      }

      if (isValid) {
        this.currentStep = 2;
      }
      this.$forceUpdate();
    },
    clickPrevWorkAssignmentDetails() {
      this.currentStep = 1;
    },
    clickNextReviewAndSubmit() {
      if (this.selectedWorkers.length == 0) {
        this.showMessage("Please select workers.", "warning");
        return;
      }
      this.currentStep = 3;

      this.summaryObj.assignTo = '-';
      this.summaryObj.contractor = '-';
      this.summaryObj.assignType = '-';
      this.summaryObj.weldingInspector = '-';
      this.summaryObj.industry = '-';
      this.summaryObj.projectCode = '-';
      this.summaryObj.jobCategory = '-';
      this.summaryObj.location = '-';

      if (this.dataObj.assignTo) {
        if (this.dataObj.assignTo == this.$data._assignTo.industry.value)
          this.summaryObj.assignTo = this.$data._assignTo.industry.text;
        else if (this.dataObj.assignTo == this.$data._assignTo.contractor.value)
          this.summaryObj.assignTo = this.$data._assignTo.contractor.text;
      }
      if (this.dataObj.toContractorOid) {
        this.summaryObj.contractor = this.contractors.find(x => x.oid == this.dataObj.toContractorOid).corpName;
      }
      if (this.dataObj.assignType) {
        if (this.dataObj.assignType == this.$data._assignType.job.value)
          this.summaryObj.assignType = this.$data._assignType.job.text;
        else if (this.dataObj.assignType == this.$data._assignType.resident.value)
          this.summaryObj.assignType = this.$data._assignType.resident.text;
        else if (this.dataObj.assignType == this.$data._assignType.workshop.value)
          this.summaryObj.assignType = this.$data._assignType.workshop.text;
      }
      if (this.dataObj.weldingInspectorOid) {
        this.summaryObj.weldingInspector = this.weldingInspectors.find(x => x.oid == this.dataObj.weldingInspectorOid).name;
      }
      if (this.dataObj.industryOid) {
        this.summaryObj.industry = this.industries.find(x => x.oid == this.dataObj.industryOid).corpName;
      }
      if (this.dataObj.industryProjectOid) {
        this.summaryObj.projectCode = this.projectCodes.find(x => x.oid == this.dataObj.industryProjectOid).projectCode;
      }
      if (this.dataObj.jobCategory) {
        // if (this.dataObj.jobCategory == this.$data._jobCategory.all.value)
        //   this.summaryObj.jobCategory = this.$data._jobCategory.all.text;
        if (this.dataObj.jobCategory == this.$data._jobCategory.construction.value)
          this.summaryObj.jobCategory = this.$data._jobCategory.construction.text;
        else if (this.dataObj.jobCategory == this.$data._jobCategory.maintenance.value)
          this.summaryObj.jobCategory = this.$data._jobCategory.maintenance.text;
        else if (this.dataObj.jobCategory == this.$data._jobCategory.shutdown.value)
          this.summaryObj.jobCategory = this.$data._jobCategory.shutdown.text;
      }
      if (this.dataObj.siteLocationOid) {
        this.summaryObj.location = this.siteLocations.find(x => x.oid == this.dataObj.siteLocationOid).name;
      }

    },
    clickPrevSelectWorkers() {
      this.currentStep = 2;
    },
    addWorker() {
      this.showWorkerList = true;
      this.callWorkerTable();
    },

    callWorkerTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];

      this.tableParam.isOnlyWelder = false;
      this.tableParam.isOnlyNonWelder = false;

      if (this.dataObj.weldingInspectorOid && this.dataObj.weldingInspectorOid > -1) {
        this.tableParam.isOnlyWelder = true;
      }
      else if (this.dataObj.weldingInspectorOid == -1) {
        //this.tableParam.isOnlyNonWelder = true;
      }

      this.tableParam.filterType = this.dataObj.industryOid > 0 ? 'industry' : '';
      api.regularPost(apiUrl.worker.getListForAssignWorkers, this.tableParam).then(response => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callWorkerTable();
    },
    clickSearchWorker() {
      this.callWorkerTable();
    },
    selectWorker() {
      this.tableParam.listData.filter(x => x.selected).forEach(w => {
        if (!this.selectedWorkers.some(x => x.oid == w.oid)) {
          this.selectedWorkers.push({
            oid: w.oid,
            name: w.name,
            nricFin: w.nricFin,
            workPermit: w.workPermit,
            welderId: w.welderId,
            workerOid: w.oid,
            techSkill: w.techSkill,
            testComponent: null,
            yearExperience: null,
            testMediumOid: null,
            testNumbers: [],
          });
        }
      })
      this.showWorkerList = false;
    },
    doneManageProjectCode(data) {
      this.getProjectCodes();
      this.dataObj.industryProjectOid = data.oid;
    },
    doneManageSiteLocation(data) {
      this.getSiteLocations();
      this.dataObj.siteLocationOid = data.oid;
    },
    changeStartDate(date) {
      this.dataObj.assignDateFrom = this.convertDateObjToDateStr(date);
    },
    changeEndDate(date) {
      this.dataObj.assignDateTo = this.convertDateObjToDateStr(date);
    },
    removeWorker(index) {
      this.selectedWorkers.splice(index, 1);
    },
    clickSubmitWorkerAssignment() {
      this.dataObj.workerOids = this.selectedWorkers.map(x => x.oid);
      if (this.dataObj.weldingInspectorOid == -1) {
        this.dataObj.weldingInspectorOid = null;
      }

      this.showLoadingScreen();
      api.regularPost(apiUrl.workerAssignment.insert, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully Submitted.", "success");
          this.$router.push({ name: "WorkerAssignmentList" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.doctors.unable_delete, "error");
        }
      });
    },
  },
}
</script>
