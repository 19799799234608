<template>
  <div>
    <ModalDiaglog :show="showModal" :onClose="()=>{showModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div class="mb-2">
        <span class="text-secondary">Industry: </span>{{industryName}}
      </div>
      <div v-if="!isEdit">
        <div class="input-group">
          <input type="text" class="form-control" :class="errorCodeToInsert && 'control-error'" placeholder="New Code" v-model="dataObj.projectCode" />
          <button class="btn btn-success btn-sm" @click="clickInsert">Save New</button>
        </div>
      </div>
      <div v-if="isEdit">
        <div class="input-group">
          <input type="text" class="form-control" :class="errorCodeToEdit && 'control-error'" v-model="dataObj.projectCode" />
          <button class="btn btn-secondary btn-sm" @click="cancelEdit" style="border-right:2px solid #a7a7a7">Cancel</button>
          <button class="btn btn-success btn-sm" @click="clickUpdate">Update</button>
        </div>
      </div>
      <div style="overflow-x: auto;">
        <table class="table table-sm">
        <thead>
          <tr>
            <th>Code</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list" :key="i" :class="l.editMode && 'bg-light'">
            <td>{{l.projectCode}}</td>
            <td class="text-end"><button class="btn btn-sm btn-primary" @click="clickEdit(l)">Edit</button></td>
          </tr>
        </tbody>
      </table>
      </div>
      
    </ModalDiaglog>
  </div>
</template>

<script>

import ModalDiaglog from '@/components/custom/ModalDiaglog';
import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'ManageProjectCode',
  components: {
    ModalDiaglog
  },
  props: {
    showForm: Boolean,
    industryOid: String,
    industryName: String,
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      dataObj: {},
      errorCodeToEdit: false,
      errorCodeToInsert: false,
      list: [],
    }
  },
  watch: {
    showForm(newVal) {
      this.showModal = newVal
      if (newVal)
        this.getList();
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [];
      api.regularGet(apiUrl.industryProject.getList, { corporateOid: this.industryOid }).then(res => {
        if (res.status == 200) {
          this.list = res.data;
        }
      });
    },
    clickEdit(data) {
      this.isEdit = true;
      this.dataObj = data;
      this.list.forEach(x => { x.editMode = false; });
      data.editMode = true;
    },
    cancelEdit() {
      this.isEdit = false;
      this.dataObj = {};
      this.list.forEach(x => { x.editMode = false; });
    },
    clickInsert() {
      this.errorCodeToInsert = false;
      if (!this.dataObj.projectCode) {
        this.errorCodeToInsert = true;
        return;
      }

      this.dataObj.industryOid = this.industryOid;
      this.showLoadingScreen();
      api.regularPost(apiUrl.industryProject.insert, this.dataObj).then(res => {
        this.hideLoadingScreen();
        console.log(res);
        if (res.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.dataObj = {};
          this.list.push(res.data.data);
          this.backToParentForm(res.data.data);
        }
        else if (res.status == 400 || res.errorText.response.status == 400) {
          this.showMessage(res.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickUpdate() {
      this.errorCodeToEdit = false;
      if (!this.dataObj.projectCode) {
        this.errorCodeToEdit = true;
        return;
      }

      this.dataObj.industryOid = this.industryOid;
      this.showLoadingScreen();
      api.regularPost(apiUrl.industryProject.update, this.dataObj).then(res => {
        this.hideLoadingScreen();
        if (res.status == 200) {
          this.showMessage("Successfully updated.", "success");
          let idx = this.list.findIndex(x => x.oid == res.data.data.oid);
          this.list[idx] = res.data.data;
          this.$forceUpdate();
          this.cancelEdit();
          this.backToParentForm(res.data.data);
        }
        else if (res.status == 400 || res.errorText.response.status == 400) {
          this.showMessage(res.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },

    backToParentForm(data) {
      this.showModal = false;
      this.$emit('done', data);
    },

  }
}
</script>
