import Vue from 'vue'
import VueRouter from 'vue-router'
import nav from './nav';

Vue.use(VueRouter)

let routes = [];

for (let i = 0; i < nav.length; i++) {

  //URL without menu
  if (nav[i].url) {
    routes.push({
      path: nav[i].url,
      name: nav[i].name,
      component: nav[i].component,
      props: { noAuthorize: nav[i].noAuthorize, noNavi: nav[i].noNavi }
    });
  }

  //main menu with URL
  if (nav[i].mainObject && nav[i].mainObject.url) {
    routes.push({
      path: nav[i].mainObject.url,
      name: nav[i].mainObject.name,
      component: nav[i].mainObject.component,
      props: { noAuthorize: nav[i].noAuthorize }
    });
  }

  //sub menu
  if (nav[i].childObject) {
    for (let j = 0; j < nav[i].childObject.length; j++) {
      routes.push({
        path: nav[i].childObject[j].url,
        name: nav[i].childObject[j].name,
        component: nav[i].childObject[j].component,
        props: { noAuthorize: nav[i].noAuthorize }
      });
    }
  }

}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
