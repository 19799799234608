<template>
  <div>
    <div class="text-end mb-2">
      <button class="btn btn-primary" v-if="hasAddNewPermission" @click="clickAddNew">Add Test Application</button>
    </div>
    <div class="my-tab">
      <div class="my-tab-item" :class="currentTab=='app' && 'active'" @click="clickTab('app')">Test Booked (App)</div>
      <div class="my-tab-item" :class="currentTab=='date' && 'active'" @click="clickTab('date')">Test Booked (Date)</div>
    </div>

    <div v-show="currentTab=='app'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead class="table-light">
          <tr> 
            <SortColumn @click="sortColumn" sortBy="testApplicationId" colGroup="tbl1">Application ID</SortColumn>
            <SortColumn @click="sortColumn" sortBy="createdDate" colGroup="tbl1">Application Date</SortColumn>
            <SortColumn @click="sortColumn" sortBy="testCentre" colGroup="tbl1">Test Centre</SortColumn>
            <th>Skill Test</th>
            <SortColumn @click="sortColumn" sortBy="testDate" colGroup="tbl1">Test Date</SortColumn>
            <th>NoOfWorkers</th>
            <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
            <th style="width:115px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in tableParam.listData" :key="idx">
            <td>{{i.testApplicationId}}</td>
            <td>{{i.createdDate}}</td>
            <td>{{i.testCentre}}</td>
            <td>{{i.skill}}</td>
            <td>{{i.testDateDisplay}}</td>
            <td>{{i.noOfWorkers}}</td>
            <td>
              <span v-if="i.status == $data._testApplicationStatus.active.value" class="badge bg-secondary">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.pending.value" class="badge bg-primary">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.rejected.value" class="badge bg-danger">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.modify.value" class="badge bg-warning">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.updated.value" class="badge bg-success">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.cancelled.value" class="badge bg-secondary">{{i.statusDisplay}}</span>
              <span v-else>{{i.statusDisplay}}</span>
            </td>
            <td>
              <button v-if="i.status!=$data._testApplicationStatus.updated.value" class="btn btn-sm btn-primary" @click="clickEdit(i)">
                Edit
              </button>
              <button v-else class="btn btn-sm btn-primary" @click="clickView(i)">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
      />
    </div>

    <div v-show="currentTab=='date'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead class="table-light">
          <tr> 
            <th>Test Date</th>
            <th>Test Centre</th>
            <th>Skill Set</th>
            <th>No. of Workers</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in tableParam2.listData" :key="idx">
            <td>{{i.testDateDisplay}}</td>
            <td>{{i.testCentre}}</td>
            <td>{{i.skill}}</td>
            <td>{{i.noOfWorkers}}</td>
            <td><button class="btn btn-primary btn-xs" @click="clickViewByDate(i)">View</button></td>
          </tr>
        </tbody>
      </table>
      <Pagination
      :pagingParam="tableParam2"
      @updatePaging="updatePaging2"
      />
    </div>
    
  </div>
</template>

<script>
import perCode from '@/common/permissionCodes';
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'TestApplicationList',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      tableParam2: this.dtoPaging(),
      currentTab: '',
      hasAddNewPermission: false
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Test Applications' }
    ]);

    this.hasAddNewPermission = this.isAuthorizedPermission(perCode.newTestApplication);

    this.clickTab('app');
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      this.tableParam.forContractor = true;
      api.regularPost(apiUrl.testApplication.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    clickAddNew() {
      this.$router.push({ name: 'AddEditTestApplication' });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditTestApplication', params: { id: data.oid } });
    },

    clickTab(tab) {
      this.currentTab = tab;
      if (tab == 'app') {
        this.callTable();
      }
      else if (tab == 'date') {
        this.callTable2();
      }
    },

    callTable2() {
      this.showLoadingScreen();
      this.tableParam2.listData = [];
      this.tableParam2.forContractor = true;      
      api.regularPost(apiUrl.testApplication.getListGpByDate, this.tableParam2).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam2 = response.data;
        }
      });
    },
    sortColumn2(sortyBy, sortDir) {
      this.tableParam2.orderby = sortyBy;
      this.tableParam2.orderDir = sortDir;
      this.callTable2();
    },
    updatePaging2(paramObj) {
      this.tableParam2.currentpage = paramObj.currentPage;
      this.tableParam2.rowcount = paramObj.rowPerPage;
      this.callTabl2();
    },
    clickView(data) {
      this.$router.push({ name: 'ViewTestApplication', params: { id: data.oid } });
    },
    clickViewByDate(i) {
      this.$router.push({ name: 'TestApplicationCandidatesByDate', params: { testDate: i.testDateStr, testCentreOid: i.testCentreOid, skillOid: i.skillOid } });
    },

  }
}
</script>
