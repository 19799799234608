<template>
  <div style="background: #f2f2f2; min-height:50em;">
    <div class="container">
      <div class="row">
        <div class="offset-md-4 col-md-4 ">
          <div class="text-center mb-3" style="margin-top:10%;">
            <!-- <img src="/img/PrimosLogo.png" style="width:300px;" /> -->
          </div>
          <div class="card login">
            <div class="card-body">
              <div class="mb-3 text-secondary">
                <h3>Login</h3>
                <span>Sign In to your account</span>
              </div>

              <div v-show="!showOtpCodeForm && !isMultiUsers">
                <div class="input-group mb-3">
                  <span class="input-group-text"><i class="bi bi-person"></i></span>
                  <input type="text" class="form-control" v-model="user" placeholder="User name" @keypress="keypress" />
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text"><i class="bi bi-key"></i></span>
                  <input type="password" class="form-control" v-model="password" placeholder="Password"
                    @keypress="keypress" />
                </div>
                <div class="mb-3">
                  <button class="btn btn-primary" style="width:100px;" @click="submit">Login</button><a
                    @click="forgotpassword" href="javascript:;" class="float-end">Forgot password</a>
                </div>
                <div class="mb-3">
                  <div class="accordion-item">
    <h2 class="accordion-header" id="heading28">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
      data-bs-target="#collapse28"
        aria-expanded="false" aria-controls="collapse28">
        Need Help Logging In?
      </button>
    </h2>
    <div id="collapse28" class="accordion-collapse collapse" aria-labelledby="heading28"
      data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <ol>
          <li><b>What is username?</b> An email address registered with PRiMOS.</li>
          <li><b>What is password?</b> A password you use to access PRiMOS</li>
          <li><b>Cannot remember password?</b> Click on “forgot password” to reset it.</li>
          <li><b>Cannot remember username?</b> Please contact 6779 7706 or email helpdesk@imos.com.sg or admin@imos.com.sg for assistance.</li>
        </ol>
      </div>
    </div>
  </div>
                </div>
              </div>
              <div v-if="isMultiUsers">
                <div class="mb-1"><strong>{{multiUserMessage}} </strong></div>
                <div class="row mb-3" v-for="(i, idx) in userList" :key="idx">
                  <div class="col-sm-12">
                    <button class="btn btn-primary" @click="clickUser(i)" style="text-align:left;width:100%;"><span style="font-size: large;font-weight: 600;">{{i.corporateName}}</span><br/><span>({{i.fullName}})</span></button>
                  </div>
                  
                </div>
              </div>
              <div v-show="showOtpCodeForm">
                <div class="mb-1"><strong>OTP is sent to your email. Please enter TOP. </strong></div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" v-model="otpCode" placeholder="Enter OTP" @keypress="keypressOtp" />
                  <span class="input-group-text"><span>{{otpTimer}}</span>s</span>
                  <span class="input-group-button"><button class="btn btn-secondary" @click="resendOtp">Resend OTP</button></span>
                </div>
                <div class="mb-3">
                  <button class="btn btn-primary" style="width:150px;" @click="submitOtp">Submit OTP</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      user: '',
      password: '',
      otpCode: '',
      isMultiUsers:false,
      multiUserMessage:'',
      userList:[],
      showOtpCodeForm: false,
      otpTimer: 0,
      oid:0
    }
  },

  methods: {
    clickUser(user){
      this.oid = user.oid;
      if(user.enable2FactorAuth){
        this.submitOtp();
      }else{
        this.submit();
      }
    },
    //Testing method of Vuex
    testVuexStore() {
      //this.$store.state.testCount = 100; //OR
      this.$store.commit('testSet', 100);
      setTimeout(() => {
        console.log(this.$store.state.testCount);
      }, 500);
    },
    forgotpassword() {
      this.$router.push({ name: 'ForgotPassword' });
    },
    submit() {
      let data = {
        userName: this.user,
        password: this.password,
        oid: this.oid
      }
      this.showLoadingScreen();
      api.regularPost(apiUrl.account.loginAuthenticate, data, this.$store.state.token).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          console.log(response.data);
          if (response.data.isSuccess) {
            //this.$store.state.token = response.data.token;
            //this.$store.commit('setAccessToken', response.data.token);
            api.setTokenToCookie(response.data.token);
            api.userClaims(null).then((response) => {
              //console.log(response);
              if (response.status == 200) {
                this.$store.state.loginUser = response.data;
                localStorage.setItem('currentUser', JSON.stringify(this.$store.state.loginUser));
                //redirect
                //this.$router.push("/");
                location.href = "/dashboard";
              }
            });
          }else if(response.data.userList && response.data.userList.length > 0){
            
            this.isMultiUsers = true;
            this.userList = response.data.userList;
            this.multiUserMessage = "Multiple users with same email detected. Choose company.";
            console.log(this.userList);
          }
          else if (response.data.enable2FactorAuth) {
            this.showOtpCodeForm = true;
            this.resetOtpTimer();
          }
        }
        else {
          this.showMessage(response.errorText.response.data.message, "error");
        }
      });

    },
    submitOtp() {
      let data = {
        userName: this.user,
        password: this.password,
        otpCode: this.otpCode,
        oid:this.oid
      }
      this.showLoadingScreen();
      api.regularPost(apiUrl.account.loginAuthenticateWithOtpCode, data, this.$store.state.token).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          if (response.data.isSuccess) {
            //this.$store.state.token = response.data.token;
            //this.$store.commit('setAccessToken', response.data.token);
            api.setTokenToCookie(response.data.token);
            api.userClaims(null).then((response) => {
              //console.log(response);
              if (response.status == 200) {
                this.$store.state.loginUser = response.data;
                localStorage.setItem('currentUser', JSON.stringify(this.$store.state.loginUser));
                //redirect
                //this.$router.push("/");
                location.href = "/dashboard";
              }
            });
          }
          else {
            this.showMessage(response.data.message, "error");
          }
        } else {
          this.showMessage(response.errorText.response.data.message, "error");
        }
      });
    },
    resendOtp() {
      let data = {
        userName: this.user,
        password: this.password,
      }
      this.showLoadingScreen();
      api.regularPost(apiUrl.account.resendOTPCode, data, this.$store.state.token).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          if (response.data.isSuccess) {
            this.showMessage("Successfully resend OTP.", "success");
            this.resetOtpTimer();
          }
          else {
            this.showMessage(response.data.message, "error");
          }
        } else {
          this.showMessage(response.errorText.response.data.message, "error");
        }
      });
    },
    resetOtpTimer() {
      this.otpTimer = 299;
      var interval = setInterval(() => {
        if (this.otpTimer > 0) {
          this.otpTimer -= 1;
        }
        else {
          clearInterval(interval);
        }
      }, 1000);
    },
    keypress(e) {
      //Enter
      if (e.keyCode == 13) {
        this.submit();
      }
    },
    keypressOtp(e) {
      if (e.keyCode == 13) {
        this.submitOtp();
      }
    },
  }
}
</script>

<style>
  .login {
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);

  }

</style>