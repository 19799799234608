<template>
  <div>
    <Corporates :type="$data._corporateType.testCenter.value" />
  </div>
</template>

<script>
import Corporates from './Corporates'
export default {
  components: {
    Corporates
  },
  name: 'TestCentreCwqs'
}
</script>
