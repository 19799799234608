<template>
    <div style="background: #f2f2f2; min-height: 100vh">
        <div class="container">
            <div class="row">
                <div class="offset-md-2 col-md-10">
                <div class="card" style="margin-top: 5%">
                    <div class="card-body">
                        <h5 class="card-title">PRiMOS Registration</h5>
                        <h6 class="card-subtitle mb-2 text-muted">Completed registeration</h6>
                        <div class="row mb-3">
                          <div class="col-sm-12">
                            Thank You for your application. The outcome will be emailed to your Admin Contact Person as registered with PRiMOS. Any query can be directed to info@imos.com.sg
                          <br/>
                          Please print out the the following forms and send back to us.
                          </div>
                          
                        </div>
                        <h6><i class="bi bi-app"></i> <a href="javascript:;" @click="clickApplicationForm()">PRiMOS Application Form</a></h6>
                        <h6 v-if="isGiro"><i class="bi bi-app"></i> <a href="javascript:;" @click="clickGiroForm()">Giro Application Form</a></h6>
                        <h6><i class="bi bi-app"></i> <a href="javascript:;" @click="downloadFile()">Terms and Conditions</a></h6>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: "CompleteRegister",
  components: {
  },
  data() {
    return {
      oid:0,
      isGiro: false
    };
  },
   beforeMount() {
    let passedId = this.$route.query.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  mounted() {
      this.checkGiro();
  },
  methods: {
    downloadFile() {
      api.downloadFile(apiUrl.corporate.downloadTAC, null, 'PRiMOS_TAC.pdf', () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    clickApplicationForm() {
          let routeData = this.$router.resolve({ name: 'ApplicationForm', query: { id: this.oid } });
          window.open(routeData.href, '_blank');
      },

    clickGiroForm() {
          let routeData = this.$router.resolve({ name: 'GiroForm', query: { id: this.oid } });
          window.open(routeData.href, '_blank');
      },
      checkGiro(){
      let url = apiUrl.regIndustry.checkGiro + "/" + this.oid
      api.regularGet(url, null).then((response) => {
        console.log(response.data);
        this.isGiro = response.data;
      });
    }
  },
};
</script>