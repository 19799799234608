<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->

    <div class="row mb-2">
      <div class="col-md-6">
        <div class="row mb-1">
          <div class="col-sm-5 text-secondary">
            NRIC/FIN
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.nricFin" />
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-sm-5 text-secondary">
            Work Permit No.
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.workPermit" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row mb-1">
          <div class="col-sm-5 text-secondary">
            Welder ID
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.welderId" />
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-sm-5 text-secondary">
            Name
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.name" />
          </div>
        </div>        
      </div>      
    </div>
    <div class="text-end">
      <button class="btn btn-primary" @click="clickSearch">Search</button>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="nricFin" colGroup="tbl1">NRIC/FIN</SortColumn>
          <SortColumn @click="sortColumn" sortBy="workPermit" colGroup="tbl1">Work Permit No.</SortColumn>
          <SortColumn @click="sortColumn" sortBy="welderId" colGroup="tbl1">Welder ID</SortColumn>
          <SortColumn @click="sortColumn" sortBy="workPermitExpiryDate" colGroup="tbl1">Work Permit Expiry Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="awshppExpiryDate" colGroup="tbl1">AWSHPP Expiry Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="terminatedDate" colGroup="tbl1">Terminated Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.name}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.welderId}}</td>
          <td>{{i.workPermitExpiryDate}}</td>
          <td>{{i.awshppExpiryDate}}</td>
          <td>{{i.terminatedDate}}</td>
          <td>
            <span class="badge bg-success" v-if="i.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
            <span class="badge bg-danger" v-else-if="i.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
            <span class="badge" v-else>{{i.statusDisplay}}</span>
          </td>
          <td>
            <button v-if="isAuthorizedPermission($data._permissionCodes.viewWorker)" class="btn btn-sm btn-primary me-2 mb-1" @click="clickView(i)">
              View
            </button>       
          </td>
        </tr>
      </tbody>
    </table>
</div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'TerminatedWorkerList',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Terminated Workers' }
    ]);
    this.tableParam.isTerminated = true;
    this.callTable();
  },
  methods: {
    callTable() {
      this.tableParam.listData = [];
      this.showLoadingScreen();
      api.regularPost(apiUrl.worker.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickView(data){
      this.$router.push({ name: 'ViewWorker', params: { id: data.oid, corpName: data.corporateName, nricFin: data.nricFin, workPermitNo: data.workPermit } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text){
      this.tableParam.searchtext = text;
      this.callTable();
    },
    clickSearch(){
      this.callTable();
    }

  }
}
</script>
