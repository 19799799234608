<template>
  <div>

    <div class="row">
      <div class="col-6">
        <div class="d-flex">
          <div class="d-flex">
            <div style="margin-top:-19px;"><i class="bi bi-person-fill me-3" style="font-size:60px;"></i></div>
            <div>
              <div style="font-size:18px;">{{dataObj.name}}</div>
              <div style="font-size:16px;"><span v-if="dataObj.nricFin">{{dataObj.nricFin}}</span><span v-if="dataObj.nricFin && dataObj.workPermit"> / </span><span v-if="dataObj.workPermit">{{dataObj.workPermit}}</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="dropdown float-end" style="display:inline-block">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            More Actions
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" href="#" @click="()=>{showAuditModal=true;}">Audits</a></li>
            <li><a class="dropdown-item" href="#" @click="generatePdf()">Generate PDF</a></li>
          </ul>
        </div>        
        <button v-if="showEditButton" class="btn btn-primary float-end me-1" @click="clickEdit">Edit</button>
      </div>
      <!-- <div class="col-2">
        <div class="text-end">
          <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
        </div>
      </div> -->
    </div>   
      

    <div class="my-tab">
      <div class="my-tab-item" :class="currentTab=='details' && 'active'" @click="currentTab='details'">Details</div>
      <div class="my-tab-item" :class="currentTab=='testResultSummary' && 'active'" @click="currentTab='testResultSummary'">Test Result Summary</div>
      <div class="my-tab-item" :class="currentTab=='worksitetSummary' && 'active'" @click="currentTab='worksitetSummary'">Worksite Summary</div>
      <div class="my-tab-item" :class="currentTab=='workshopSummary' && 'active'" @click="currentTab='workshopSummary'">Workshop Summary</div>
      <div class="my-tab-item" :class="currentTab=='employmentSummary' && 'active'" @click="currentTab='employmentSummary'">Employment Summary</div>
    </div>

    <div v-show="currentTab=='details'">        
      <h5 class="mb-3">Personal Particulars</h5>
      <hr/>
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Corporate Name:
            </div>
            <div class="col-sm-6">
              {{dataObj.corporateName}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              NRIC / FIN No.:
            </div>
            <div class="col-sm-6">
              {{dataObj.nricFin}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Work Permit No.:
            </div>
            <div class="col-sm-6">
              {{dataObj.workPermit}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Work Permit Valid From Date:
              <div class="txt-small">(Foreigners Only)</div>
            </div>
            <div class="col-sm-6">
              {{dataObj.workPermitIssueDateDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Work Permit Expiry Date
              <div class="txt-small">(Foreigners Only)</div>
            </div>
            <div class="col-sm-6">
              {{dataObj.workPermitExpiryDateDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Welder ID:
            </div>
            <div class="col-sm-6">
              {{dataObj.welderId}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Name:
            </div>
            <div class="col-sm-6">
              {{dataObj.name}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Date of Birth:
            </div>
            <div class="col-sm-6">
              {{dataObj.dobDisplay}}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Gender:
            </div>
            <div class="col-sm-6">
              {{dataObj.genderDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Race:
            </div>
            <div class="col-sm-6">
              {{dataObj.race}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Nationality:
            </div>
            <div class="col-sm-6">
              {{dataObj.nationality}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Citizenship:
            </div>
            <div class="col-sm-6">
              {{dataObj.citizenship}}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h5 class="mb-3">Qualifications</h5>
      <strong>Skill Set</strong>
      <div class="row mb-3">
        <div v-for="(q,i) in skillSetDisplays" class="col-sm-4" :key="i">
          {{q}}
        </div>
      </div>
      <strong>Occupation</strong>
      <div class="row mb-3">
        <div v-for="(o,i) in occupationDisplays" class="col-sm-4" :key="i">
          {{o}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Highest Education Reached:
            </div>
            <div class="col-sm-6">
              {{dataObj.education}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Technical Skill Level:
            </div>
            <div class="col-sm-6">
              {{dataObj.techSkill}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              AWSHPP Certification No.:
              <div class="txt-small">(Oil/Petrochemical)</div>
            </div>
            <div class="col-sm-6">
              {{dataObj.awshppCertNo}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              AWSHPP Register Date:
              <div class="txt-small">(Oil/Petrochemical)</div>
            </div>
            <div class="col-sm-6">
              {{dataObj.awshppRegisterDateDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              AWSHPP Expiry Date:
              <div class="txt-small">(Oil/Petrochemical)</div>
            </div>
            <div class="col-sm-6">
              {{dataObj.awshppExpiryDateDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              SWSHPP Certification No.:
            </div>
            <div class="col-sm-6">
              {{dataObj.swshppCertNum}}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Sponsor:
            </div>
            <div class="col-sm-6">
              {{dataObj.sponsor}}
            </div>
          </div>
          <div class="row mb-2" v-if="dataObj.otherSponsor">
            <div class="col-sm-6 text-secondary">
              Other Sponsor:
            </div>
            <div class="col-sm-6">
              {{dataObj.otherSponsor}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Sub-Contractor:
            </div>
            <div class="col-sm-6">
              {{dataObj.subContractor}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Commencement Date:
              <div class="txt-small">(Note: A date in which the worker starts working with the company)</div>
            </div>
            <div class="col-sm-6">
              {{dataObj.commenceDateDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Terminated Date:
            </div>
            <div class="col-sm-6">
              {{dataObj.terminatedDateDisplay}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Status:
            </div>
            <div class="col-sm-6">
              <span class="badge bg-success" v-if="dataObj.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
              <span class="badge bg-danger" v-else-if="dataObj.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
              <span class="badge" v-else>{{dataObj.statusDisplay}}</span>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Remarks:
            </div>
            <div class="col-sm-6">
              {{dataObj.remarks}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentTab=='testResultSummary'">
      <ViewTestResult :workerOid="oid" />
      <p class="text-primary">Only 15 years of printable records are retrievable from the system since June 2007.<br/>Please contact portal administrator at info@imos.com.sg to request for test result details beyond the mentioned period.</p>
    </div>

    <div v-if="currentTab=='worksitetSummary'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Industry</th>
            <th>Location</th>
            <th>Contractor</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Shift</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d,i) in worksiteSummary" :key="i">
            <td>{{d.industry}}</td>
            <td>{{d.location}}</td>
            <td>{{d.contractor}}</td>
            <td>{{d.startDate}}</td>
            <td>{{d.endDate}}</td>
            <td>
              <span v-if="d.morning">M </span>
              <span v-if="d.afternoon">A </span>
              <span v-if="d.night">N </span>
            </td>
            <td>{{d.statusDisplay}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="currentTab=='workshopSummary'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Accessing Inspector</th>
            <th>Industry/Test Centre/Contractor</th>
            <th>StartDate</th>
            <th>EndDate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d,i) in workshopSummary" :key="i">
            <td>{{d.accessingInspector}}</td>
            <td>{{d.industry}}<span v-if="d.industry && d.contractor">/</span>{{d.contractor}}</td>
            <td>{{d.startDate}}</td>
            <td>{{d.endDate}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-show="currentTab=='employmentSummary'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Contractor Name</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,i) in employmentHistory" :key="i">
            <td>{{e.corporateName}}</td>
            <td>{{e.commenceDateDisplay}}</td>
            <td>{{e.terminatedDateDisplay}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Worker" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>  

  </div>
</template>

<style scoped>
</style>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
import ViewTestResult from './ViewTestResult';
import perCode from '@/common/permissionCodes';

export default {
  name: 'AddEditWorker',

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms,
    ...Pickers,
    ViewTestResult
  },

  data() {
    return {
      timezone: api.TIMEZONE,
      basicLogInfoUrl: apiUrl.worker.getBasicLogInfo,
      isNew: true,
      corpName: '',
      nricFin: '',
      workPermitNo: '',

      error: {},
      dataObj: {},


      skillSets: [],
      skillSetDisplays: [],
      occupations: [],
      occupationDisplays: [],

      hasPermission: false,

      currentTab: 'details',
      employmentHistory: [],

      loadedDataObj: false,
      showAuditModal: false,
      showEditButton: false,

      worksiteSummary: [],
      workshopSummary: [],
    }
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
    this.corpName = this.$route.params.corpName; 
    this.nricFin = this.$route.params.nricFin;
    this.workPermitNo = this.$route.params.workPermitNo;

    if (this.isAuthorizedPermission(perCode.manageWorker)) {
      this.showEditButton = true;
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Workers', url: '/workers' }, { name: 'Worker Details' }
    ]);

    api.getSettingsByGroup(this.$data._settingGroup.skillSet).then((response) => {
      response.data.forEach(x => this.skillSets.push({ oid: x.oid, value: x.value, selected: false }));
      if (!this.loadedDataObj) {
        this.getDataObj();
      }
    });
    api.getSettingsByGroup(this.$data._settingGroup.occupation).then((response) => {
      response.data.forEach(x => this.occupations.push({ oid: x.oid, value: x.value, selected: false }));
      if (!this.loadedDataObj) {
        this.getDataObj();
      }
    });

    this.getEmploymentHistory();
    this.getWorksiteSummary();
    this.getWorkshopSummary();

    this.hasPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageWorker);

  },
  methods: {
    getDataObj() {
      this.loadedDataObj = true;
      let url = apiUrl.worker.getByOid + '/' + this.oid;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;

        if (this.dataObj.skillSet) {
          JSON.parse(this.dataObj.skillSet).forEach(s => {
            let tmpObj = this.skillSets.find(x => x.oid == s);
            if (tmpObj)
              this.skillSetDisplays.push(tmpObj.value);
          });
        }
        if (this.dataObj.occupation) {
          JSON.parse(this.dataObj.occupation).forEach(o => {
            let tmpObj = this.occupations.find(x => x.oid == o);
            if (tmpObj)
              this.occupationDisplays.push(tmpObj.value);
          });
        }

        //Permission
        if (this.getLoggedInUser().corporateOid != this.dataObj.corporateOid) {
          if (this.getLoggedInUser().userGroupType != this.$data._userGroupType.admin.value) {
            this.hasPermission = false;
          }
        }

      });
    },
    getEmploymentHistory() {
      let url = apiUrl.worker.workerEmployHistory + '/' + this.oid;
      api.regularGet(url, null).then((response) => {
        this.employmentHistory = response.data;
      });
    },

    generatePdf() {
      let fileName = 'Worker_';
      if (this.dataObj.nricFin)
        fileName += this.dataObj.nricFin;
      else if (this.dataObj.workPermitNo)
        fileName += this.dataObj.workPermitNo;

      this.showLoadingScreen();
      api.downloadFile(apiUrl.pdf.worker, { oid: this.dataObj.oid, showHeader: false, showFooter: true }, fileName + '.pdf', () => {
        this.hideLoadingScreen();
        this.showMessage('Downloaded successfully.', 'success');
      });
    },

    getWorksiteSummary() {
      api.regularGet(apiUrl.worker.getWorksiteSummary, { workerOid: this.oid }).then((response) => {
        this.worksiteSummary = response.data;
      });
    },

    getWorkshopSummary() {
      api.regularGet(apiUrl.worker.getWorkshopSummary, { workerOid: this.oid }).then((response) => {
        this.workshopSummary = response.data;
      });
    },

    clickEdit(){
      this.$router.push({ name: 'AddEditWorker', params: { id: this.dataObj.oid, corpName: this.dataObj.corporateName, nricFin: this.dataObj.nricFin, workPermitNo: this.dataObj.workPermit } });
    },

  }
}
</script>
