<template>
<div>
  <div>
    <button class="btn btn-primary float-end mb-2" @click="addRole">Add Role</button>
  </div>
  <div>
     <TableFilter @changeText="changeTblFilter" />
     <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead>
      <tr>
          <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Role Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="corporateType" colGroup="tbl1">Corporate Type</SortColumn>
          <th>Is Welding Inspector</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.name}}</td>
          <td>{{i.corporateTypeDisplay}}</td>
          <td v-if="i.isWI">Yes</td>
          <td v-else>No</td>
          <td><button class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button></td>
        </tr>
      </tbody>
    </table>
         </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'Roles',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Roles' }
    ]);
    this.callTable();
  },
  methods: {
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.roles.getList, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditRole', params: { id: data.oid } });
    },
    addRole() {
      this.$router.push({ name: 'AddEditRole' });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text) {
      this.tableParam.searchtext = text;
      this.callTable();
    },
  }
}
</script>
