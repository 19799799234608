<template>
  <div>
    <h5>Process Construction & Maintenance Scheme - Form A</h5>
    <h6>Click <a href="javascript:;" @click="downloadFile">Here</a> for Explanatory Note</h6>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">(A) Company Profile</h5>
        <h6>Quota Application No.:</h6>
        <div class="row">
          <div class="col-sm-12">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Name of Company:
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control" :class="error.nameOfCompany && 'control-error'" v-model="dataObj.nameOfCompany" :disabled="true"/>
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Company Address:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control" :class="error.companyAddress && 'control-error'" v-model="dataObj.companyAddress" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
               Contact Person:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-4">
                <input type="text" class="form-control" :class="error.contactPerson && 'control-error'" v-model="dataObj.contactPerson" />
              </div>
              <div class="col-sm-4">
                <input type="text" class="form-control" :class="error.position && 'control-error'" v-model="dataObj.position" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
               Email:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control" :class="error.email && 'control-error'" v-model="dataObj.email" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
               Phone:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control" :class="error.phone && 'control-error'" v-model="dataObj.phone" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
               Fax:
              </div>
              <div class="col-sm-8">
                 <input type="text" class="form-control" :class="error.fax && 'control-error'" v-model="dataObj.fax" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row mb-3">
              <div class="col-sm-12 text-secondary">
              </div>
            </div>
            <div class="row mb-3">
              <h6>
                Maintenance CPF A/C No. & Date Approved.
              </h6>
            </div>
             <div class="row mb-3">
              <div class="col-sm-2 text-secondary">
                CPF A/C:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-4">
                <input type="text" class="form-control" :class="error.cpfAc && 'control-error'" v-model="dataObj.cpfAc" />
              </div>
               <div class="col-sm-2 text-secondary">
               Date:
              </div>
              <div class="col-sm-4">
                <DatePicker :cssClass="error.formDate && 'control-error'" @updatedate="changeFormDate" :existing="formDate" />
              </div>
            </div>
             <div class="row mb-3">
              <h6>
                Ownership (for process CPF A/C No.)
              </h6>
            </div>
            <div class="row mb-3">
              <div class="col-sm-1 text-secondary">
                Local :
              </div>
              <div class="col-sm-3">
                <div class="input-group mb-3">
                  <input type="number" class="form-control" :class="error.local && 'control-error'"
                    v-model="dataObj.local" aria-describedby="basic-addon1" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">%</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-1 text-secondary">
                Foreign :
              </div>
              <div class="col-sm-3">
                <div class="input-group mb-3">
                   <input type="number" class="form-control" :class="error.foreign && 'control-error'" v-model="dataObj.foreign" aria-describedby="basic-addon2" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2">%</span>
                  </div>
                </div>
              </div>
                <div class="col-sm-1 text-secondary">
               Country:
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control" :class="error.country && 'control-error'" v-model="dataObj.country" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 text-secondary">
            Key Process Activities
          </div>
          <div class="col-sm-8">
            <input type="text" class="form-control" :class="error.keyProcessActivities && 'control-error'" v-model="dataObj.keyProcessActivities" @readonly="true"/>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card" style="margin-top:10px;">
      <div class="card-body">
        <h5 class="card-title">(B1) Details of Contract(s) With Sponsoring MNC(s) / Contract(s) between SubCont &
          MainCont</h5>
          <h5 class="card-title">Please provide the contract information for the next 2-year and also furnish us with the copies of the
          contracts or relevant supporting documents.</h5>

        <div class="row" style="margin-top:20px;">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Client:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.clientName && 'control-error'" v-model="dataObj.clientName" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Contractor or PO Ref. No.:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.contractorOrPorefNo && 'control-error'" v-model="dataObj.contractorOrPorefNo" />
              </div>
            </div>
          </div>
           
        </div>
        <h6>Duration</h6>
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PO Start Date:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <DatePicker :cssClass="error.durationStartDate && 'control-error'" @updatedate="changeDurationStartDate" :existing="durationStartDate" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PO End Date:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <DatePicker :cssClass="error.durationEndDate && 'control-error'" @updatedate="changeDurationEndDate" :existing="durationEndDate" />
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>Contract Value for works undertaken by Applicant Company <i class="bi bi-info-circle text-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Click here for more info" @click="showTooltip"></i></h6>
         <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Total ($) per annum:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="number" class="form-control" :class="error.applicantTotal && 'control-error'" v-model="dataObj.applicantTotal" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Labour Cost ($) per annum:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="number" class="form-control" :class="error.applicantLabourCost && 'control-error'" v-model="dataObj.applicantLabourCost" />
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>Total no. of existing PM workers sponsored by plant owner</h6>
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PM Workers:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="number" class="form-control" :class="error.plantOwerTotal && 'control-error'" v-model="dataObj.plantOwerTotal" />
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>No. of workers Applicant Company is applying IPS for</h6>
        <div class="row">
          <div class="col-sm-6">
            <h6>New</h6>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PRC:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.applicantPrc && 'control-error'" v-model="dataObj.applicantPrc" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
               NTS:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.applicantNts && 'control-error'" v-model="dataObj.applicantNts" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h6>Renewal</h6>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PRC:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.renewalPrc && 'control-error'" v-model="dataObj.renewalPrc" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
               NTS:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.renewalNts && 'control-error'" v-model="dataObj.renewalNts" />
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>Contract Summary ({{dataObj.contractSummary.length}}/500)</h6>
        <div class="row">
          <div class="col-sm-12">
            <textarea maxlength="500" class="form-control" rows="6" :class="error.contractSummary && 'control-error'" v-model="dataObj.contractSummary"></textarea>
          </div>
        </div>
        <h6 style="margin-top:10px;">Brief Description of Workers’ Job Roles ({{dataObj.description.length}}/500)</h6>
        <div class="row">
          <div class="col-sm-12">
            <textarea maxlength="500" class="form-control" rows="6" :class="error.description && 'control-error'" v-model="dataObj.description"></textarea>
          </div>
        </div>
        <h6 style="margin-top:10px;">Contract Attachments (Maximum per File Size: 5 MB)</h6>
        <div class="row">
          <div class="col-sm-2">
            <file-input :multiple="false" :isShowMsg="false"  :status="filestatus1" @selectFile="file1=$event" @done="filestatus1='stop'"/>
          </div>
           <div class="col-sm-2">
            <file-input :multiple="false" :isShowMsg="false"  :status="filestatus2" @selectFile="file2=$event" @done="filestatus2='stop'"/>
          </div>
           <div class="col-sm-2">
            <file-input :multiple="false" :isShowMsg="false"  :status="filestatus3" @selectFile="file3=$event" @done="filestatus3='stop'"/>
          </div>
           <div class="col-sm-2">
            <file-input :multiple="false" :isShowMsg="false"  :status="filestatus4" @selectFile="file4=$event" @done="filestatus4='stop'"/>
          </div>
           <div class="col-sm-2">
            <file-input :multiple="false" :isShowMsg="false"  :status="filestatus5" @selectFile="file5=$event" @done="filestatus5='stop'"/>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-danger">
            *File type allowed:<br/>
            .bmp,.jpg,.jpeg,.gif,.png,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.rtf
          </div>
        </div>
        <div v-if="!isNew" class="row">
          <div class="col-sm-6" style="overflow-x: auto;">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>File Name</th>
                  <th style="width:115px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in dataObj.fileList" :key="idx">
                  <td>{{getFileDisplayName(i)}}</td>
                  <td style="white-space: nowrap;">
                    <button class="btn btn-sm btn-danger" @click="clickDelete(i)">
                      <span>Delete</span>
                    </button> &nbsp;
                    <button class="btn btn-sm btn-primary" @click="clickDownload(i)">
                      <span>Download</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

        <ModalDiaglog size="xl" :show="showModal" :onClose="()=>{showModal=false}" :hasHeader="true" :title="title"
          :showSubmitBtn1="false">
          <div v-if="showModal">
            <div class="row">
              <div class="col-sm-6">
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Plant Owner:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.plantOwerTotal && 'control-error'"
                      v-model="contractor.planOwner" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Contract/PO Start Date:
                  </div>
                  <div class="col-sm-8">
                    <DatePicker :cssClass="error.durationEndDate && 'control-error'" @updatedate="changeContractPostartDate" :existing="contractPostartDate" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Total Amount:<span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-8">
                    <input type="number" class="form-control" 
                      v-model="contractor.totalAmount" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Contract Summary:<br>
                    <span class="small" style="font-style: italic;">(Max 1000 characters)</span>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.contractSummary && 'control-error'"
                      v-model="contractor.contractSummary" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Description:<br>
                    <span class="small" style="font-style: italic;">(Max 1000 characters)</span>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.description && 'control-error'"
                      v-model="contractor.description" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Contract Po Ref:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.contractPoref && 'control-error'"
                      v-model="contractor.contractPoref" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-12 text-secondary">
                    <span class="text-danger small">* Denotes Mandatory Field</span>
                  </div>
                </div>
                 
                
              </div>
              <div class="col-sm-6">
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Main Contractor:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.mainContractorName && 'control-error'"
                      v-model="contractor.mainContractorName" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Contract/PO End Date:
                  </div>
                  <div class="col-sm-8">
                    <DatePicker :cssClass="error.durationEndDate && 'control-error'" @updatedate="changeContractPoendDate" :existing="contractPoendDate" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Labour Cost:<span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-8">
                    <input type="number" class="form-control"
                      v-model="contractor.labourCost" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    No. Of Existing Process Maint. Worker:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.existingProcess && 'control-error'"
                      v-model="contractor.existingProcess" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Additional Remarks:<br>
                    <span class="small" style="font-style: italic;">(Max 1000 characters)</span>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.additionalRemarks && 'control-error'"
                      v-model="contractor.additionalRemarks" />
                  </div>
                </div>
                
                <button class="btn btn-primary m-1 float-end" style="width:100px;" @click="saveContractor">Save</button>
                
              </div>
              
            </div>
            <div class="row mb-3">
                  <h6>Remarks</h6>
                  <div class="col-sm-12 text-default">
                    <div>
                      - Labour cost refers to actual\project amount that your company will spend on worker’s salary, levies, accommodation etc for workforce to be deployed for the specific contract/PO.
                    </div>
                    <div>
                      - Contract Value and Labour Cost cannot be Zero.
                    </div>
                  </div>
                </div>
          </div>
        </ModalDiaglog>   
    <div class="card" style="margin-top:10px;">
      <div class="card-body">
        <h5 class="card-title">(B2) To Add Additional Contract(s)</h5>
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-secondary m-1 float-end" style="width:100px;" @click="add">Add</button>
          </div>
          <div class="col-sm-12 overflow-auto">
            
            <table class="table table-sm" style="width:100%;text-align:center;">
              <thead class="table-light">
                <tr>
                  <th style="width:100px;" rowspan="2">Plant owner</th>
                  <th style="width:150px;" rowspan="2">Main Contractor (if any)</th>
                  <th rowspan="2">Contract/PO Ref</th>
                  <th rowspan="2">Contract/PO Start Date / End Date</th>
                  <th colspan="2">Contract value for works undertaken by applicant company (to exclude value subcontracted to 3rd party)</th>
                  <th rowspan="2">Contract summary</th>
                  <th rowspan="2">Brief Description of Workers' job Roles</th>
                  <th rowspan="2">No. of existing process maintenance worker sponsored by stated plant owner for stated job</th>
                  <th rowspan="2">Additional Remarks</th>
                  <th rowspan="2" style="width:115px;"></th>
                </tr>
                <tr>
                  <th>Total ($) - per annum</th>
                  <th>Labour Cost ($) - per annum</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in contractors" :key="idx">
                  <td>{{i.planOwner}}</td>
                  <td>{{i.mainContractorName}}</td>
                  <td>{{i.contractPoref}}</td>
                  <td>{{i.contractPostartDate}} / {{i.contractPoendDate}}</td>
                  <td>{{i.totalAmount}}</td>
                  <td>{{i.labourCost}}</td>
                  <td>{{i.contractSummary}}</td>
                  <td>{{i.description}}</td>
                  <td>{{i.existingProcess}}</td>
                  <td>{{i.additionalRemarks}}</td>
                  <td>
                    <button class="btn btn-sm btn-error" @click="clickRemove(idx)">
                      <span><i class="bi bi-trash-fill"></i></span>
                    </button>
                    <button class="btn btn-sm btn-error" @click="clickEdit(i)">
                      <span><i class="bi bi-pencil-fill"></i></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
    <div class="card" style="margin-top:10px;">
      <div class="card-body">
        <h5 class="card-title">(C) DECLARATION & ENDORSEMENT</h5>
        <div class="row">
          <div class="col-sm-12">
            <div class="row mb-3">
              <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                I, <input type="text" class="form-control" :class="error.contactPerson && 'control-error'" v-model="dataObj.contactPerson" style="display:inline-block;width:200px;" /> , in my capacity as <input type="text" class="form-control" :class="error.position && 'control-error'" v-model="dataObj.position"  style="display:inline-block;width:250px;"/>
                of the Applicant Company mentioned above, hereby declare that my company has been awarded a contract by <input type="text" class="form-control" :class="error.awardingCompany && 'control-error'" v-model="dataObj.awardingCompany"  style="display:inline-block;width:500px;"/>.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12"  style="text-align: justify;text-justify: inter-word;">I declare that the facts stated in this application are true and that the foreign workers requested under this application will serve the Process Industry maintenance activities only.</div>
              <div class="col-sm-12"  style="text-align: justify;text-justify: inter-word;">
                I further declare that the above Prior Approval application made by my company is meant for the recruitment of foreign workers for the purpose of the above contract. My company has read and understood all the work permit conditions in the enclosed guide and agrees to abide by them. We will also comply with the provisions of the Employment Act. I understand that failure to comply with the conditions, provisions and requirements will affect my company's present and future work permit applications. The Work Pass Division also reserves the right to revoke work permits issued if the work permit conditions are not compiled with or the documents submitted in supporting the applications are not valid.
              </div>
            </div>
            <div class="row mb-3">
              
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Name:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.contactPerson && 'control-error'" v-model="dataObj.contactPerson" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Email:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.email && 'control-error'" v-model="dataObj.email" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Fax:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.fax && 'control-error'" v-model="dataObj.fax" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Tel:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.phone && 'control-error'" v-model="dataObj.phone" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Submit To Sub Contractor:
              </div>
              <div class="col-sm-6">
                <v-select label="corpName" :class="error.subContractor && 'control-error'" v-model="selectedSubContractor" :options="sub_contractors" @input="changeSubContractor"></v-select>
                <!-- <FormSelect :list="sub_contractors" :cssClass="error.subContractor && 'control-error'" value="oid" text="corpName" :existing="dataObj.subContractor" @change="dataObj.subContractor=$event"/> -->
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Submit To Main Contractor:
              </div>
              <div class="col-sm-6">
                <v-select label="corpName" :class="error.mainContractor && 'control-error'" v-model="selectedMainContractor" :options="main_contractors" @input="changeMainContractor"></v-select>
                <!-- <FormSelect :list="main_contractors" :cssClass="error.mainContractor && 'control-error'" value="oid" text="corpName" :existing="dataObj.mainContractor" @change="dataObj.mainContractor=$event"/> -->
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Submit To MNC:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <v-select label="corpName" :class="error.industryMncoid && 'control-error'" v-model="selectedMNC" :options="mncList" @input="changeMNC"></v-select>
                <!-- <FormSelect :list="mncList" :cssClass="error.industryMncoid && 'control-error'" value="oid" text="corpName" :existing="dataObj.industryMncoid" @change="dataObj.industryMncoid=$event"/> -->
              </div>
            </div>
            <hr v-if="dataObj.status" />
            <div v-if="dataObj.status && oid" class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Application Status:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{getQuotaTextByValue(dataObj.status)}}</label>
                </div>
            </div>
            <div v-if="dataObj.rejectReason && oid" class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Reject Reason:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.rejectReason}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       <span class="text-danger small">* Denotes Mandatory Field</span>
      <button class="btn btn-secondary m-1 float-end" @click="cancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="click($data._quotaStatus.Pending)">Save</button>
        <button class="btn btn-primary m-1 float-end" @click="click($data._quotaStatus.Submitted)">Submit</button>
    </div> 
    <div style="height:200px;"></div>
    <ModalDiaglog 
              :show="IsShowTooltip" :onClose="()=>{IsShowTooltip = false;}" :title="'Remarks'" :showSubmitBtn1="false"
              >
                <div class="text-primary">
                    <p>
                      <b>What is contract cost?</b><br/>
                      The total value of the contract award to Contractor A by plant owner X.<br/><br/>
                      <b>What is labour cost?</b><br/>
                      Total Labour cost: projected cost for employing labour for this contract. It includes worker’s salaries, levies, accommodation etc.<br/><br/>
                      <b>Example:</b> <br/>
                      Plant owner X awards a $200,000 maintenance contract to main contractor A. Main contractor A takes on $150,000 worth of the work (of which $75,000 is projected labour cost), and awards remaining $50,000 to sub-contractor B (of which $35,000 is projected labour cost)<br/>
                      If you are main contractor A submitting this form, indicate “plant owner X” as the client. For contract value, indicate “$150,000 as total value and $75,000 as labour cost.<br/>
                      If you are sub-contractor B submitting this form, indicate “main contractor A as client”. For contract value, indicate “$50,000 as total value and $35,000 as labour cost.<br/>
                    </p>
                </div>
    </ModalDiaglog>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
export default {
  name: 'FormA',

  components: {
    ...Forms,
    ...Pickers,
    ModalDiaglog,
  },

  props: {

  },

  data() {
    return {
      IsShowTooltip:false,
      file1: null,
      filestatus1: 'stop',
      file2: null,
      filestatus2: 'stop',
      file3: null,
      filestatus3: 'stop',
      file4: null,
      filestatus4: 'stop',
      file5: null,
      filestatus5: 'stop',
      groupType:'',
      basicLogInfoUrl: '',
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {
        contractSummary:'',
        description:'',
        local:100,
        foreign:0
      },
      timezone: api.TIMEZONE,
      parents: [],
      showModal: false, 
      error: {},
      formDate:null,
      durationStartDate:null,
      durationEndDate:null,
      contractPostartDate:null,
      contractPoendDate:null,
      contractor:{},
      contractors:[],
      sub_contractors:[],
      main_contractors:[],
      mncList:[],
      errMessage:'',
      deleteList:[],
      title:'',
      editContractor:false,
      selectedSubContractor: {},
      selectedMainContractor: {},
      selectedMNC: {},
    };
  },
  beforeMount() {
    let passedGroupType = this.$route.params.type;
    this.groupType = passedGroupType;
    console.log(this.groupType);
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      console.log(1);
      this.oid = parseInt(passedId);
      this.isNew = false;
    }else{
      this.oid = 0;
      console.log(2);
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = "Online Quota App";
    this.setBreadcrumb([
      { name: 'List', url: '/quotalist'}, { name: pageName }
    ]);

    if (this.isNew) {
      //this.dataObj.isActive = true;
      this.getPreparedData();
    }
    if (this.oid > 0) {
      console.log('ggwp');
      this.getPreparedData();
      this.getData();  
    }
  },

  methods: {
    showTooltip(){
      this.IsShowTooltip = true;
    },
    clickDownload(e){
      this.showLoadingScreen();
      let data = {
        oid: this.dataObj.modifiedBy,
        fileName: e,
        corporateOid: this.dataObj.corporateOid
      }
      let displayFile = this.getFileDisplayName(e);
      api.downloadFile(apiUrl.quota.downloadFile,data,displayFile,() =>{
            this.hideLoadingScreen();
            this.showMessage("Successfully downloaded.", "success");
      });
    },
    getFileDisplayName(original_name){
      let nameByArray = original_name.split('_');
      console.log(nameByArray);
      let displayName = '';
      if(nameByArray.length >= 2 && nameByArray[0] != ''){
        displayName = original_name.replace(nameByArray[0]+"_",'');
      }else{
        displayName = original_name;
      }
      return displayName;
    },
    downloadFile() {
      api.downloadFile(apiUrl.quota.downloadProcessMaintenance, null, 'ProcessMaintenance.pdf', () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    getPreparedData() {
        let url = apiUrl.quota.prepareData 
        api.regularGet(url,null ).then((response) => {
          console.log(response);
          this.main_contractors = response.data.contractors;
          this.mncList = response.data.industries;
          this.sub_contractors = response.data.contractors;
          if(this.isNew){
          this.dataObj.nameOfCompany = response.data.nameOfCompany;
          this.dataObj.contactPerson = response.data.contactPerson;
          this.dataObj.email = response.data.email;
          this.dataObj.phone = response.data.phone;
          this.dataObj.fax = response.data.fax;
          this.dataObj.companyAddress = response.data.companyAddress;
          this.dataObj.position = response.data.position;
          this.dataObj.keyProcessActivities = response.data.keyProcessActivities;
          this.dataObj.country = response.data.country;
          this.dataObj.cpfAc = response.data.cpfAccountNo;
          }else{
            this.getData();
          }
          
        });
    },
    getData() {
      let url = apiUrl.quota.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        if(!response.data){
          this.showMessage("You have no permission to view this record.", "error");
          this.$router.push({ name: "FromContractors" });
        }
        this.dataObj = response.data;
        this.contractors = this.dataObj.nameOfContractorList;
        this.formDate = this.convertDateStrToDateObj(this.dataObj.formDate);
        this.durationStartDate = this.convertDateStrToDateObj(this.dataObj.durationStartDate);
        this.durationEndDate = this.convertDateStrToDateObj(this.dataObj.durationEndDate);
        this.selectedSubContractor = this.sub_contractors.find(x => x.oid == this.dataObj.subContractor);
        this.selectedMainContractor = this.main_contractors.find(x => x.oid == this.dataObj.mainContractor);
        this.selectedMNC = this.mncList.find(x => x.oid == this.dataObj.industryMncoid);
      });
    },
    changeFormDate(date) {
      this.dataObj.formDate = this.convertDateObjToDateStr(date);
    },
    changeDurationStartDate(date) {
      this.dataObj.durationStartDate = this.convertDateObjToDateStr(date);
    },
    changeDurationEndDate(date) {
      this.dataObj.durationEndDate = this.convertDateObjToDateStr(date);
    },
    changeContractPostartDate(date) {
      this.contractor.contractPostartDate = this.convertDateObjToDateStr(date);
    },
    changeContractPoendDate(date) {
      this.contractor.contractPoendDate = this.convertDateObjToDateStr(date);
    },
    cancel(){
      this.$router.push({ name: "FormAListing" });
    },
    clickEdit(e){
      this.editContractor = true;
      this.contractor = e;
      if(e.contractPostartDate){
        this.contractPostartDate = this.convertDateStrToDateObj(e.contractPostartDate);
      }
      if(e.contractPoendDate){
        this.contractPoendDate = this.convertDateStrToDateObj(e.contractPoendDate);
      }
      
      this.title = "Edit - Details of additional contract";
      this.showModal = true;
    },
    clickRemove(e){
      this.contractors.splice(e, 1);
    },
    add(){
      this.editContractor = false;
      this.contractor = {};
      this.title = "Add - Details of additional contract";
      this.showModal = true;
      this.contractPostartDate = null;
      this.contractPoendDate = null;
    },
    saveContractor(){
      if(this.editContractor == true){
        //this.contractor.contractPostartDate = this.convertDateObjToDateStr(this.contractPostartDate);
        //this.contractor.contractPoendDate = this.convertDateObjToDateStr(this.contractPoendDate);
      }else{
        if(!this.contractor.labourCost){
          this.showMessage("Labour Cost is required.", "warning");
          return;
        }else if(this.contractor.labourCost <= 0){
          this.showMessage("Enter valid Labour Cost.", "warning");
          return;
        }
        if(!this.contractor.totalAmount){
          this.showMessage("Total Amount is required.", "warning");
          return;
        }else if(this.contractor.totalAmount <= 0){
          this.showMessage("Enter valid Total Amount.", "warning");
          return;
        }
        //contractor.totalAmount

        this.contractors.push(this.contractor);
        this.contractPostartDate = null;
        this.contractPoendDate = null;
      }
      
      this.showModal = false;
      this.editContractor = false;
    },
    click(e){
      
      this.dataObj.status = e.value;
      if(this.validate()){
        this.dataObj.oid = 0;
        this.dataObj.nameOfContractorList = this.contractors;
        let subUrl ='';
        if(this.isNew){
          this.dataObj.oid = 0;
        }else{
          this.dataObj.oid = this.oid;
          this.dataObj.deleteList = this.deleteList;
        }
        
        if(this.isNew){
          subUrl = apiUrl.quota.insert;
        }else{
          subUrl = apiUrl.quota.update;
        }
        let formdata = new FormData();
        if(this.file1){
           formdata.append("Files", this.file1);
        }
        if(this.file2){
          formdata.append("Files", this.file2);
        }
        if(this.file3){
          formdata.append("Files", this.file3);
        }
        if(this.file4){
          formdata.append("Files", this.file4);
        }
        if(this.file5){
          formdata.append("Files", this.file5);
        }

        formdata.append("FormData", JSON.stringify(this.dataObj));
        this.showLoadingScreen();
        api.regularPost(subUrl, formdata, "multipart/form-data").then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "FormAListing"});
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
      } else {
        if(this.errMessage)
            this.showMessage(this.errMessage, "warning");
           return;
      }
    },
    clickDelete(e){
      //dataObj.fileList
      const index = this.dataObj.fileList.indexOf(e);
      if (index > -1) {
        this.dataObj.fileList.splice(index, 1);
        this.deleteList.push(e);
      }
    },
    validate() {
      this.errMessage = '';
      let isValid = true;
      if(this.dataObj.status == this.$data._quotaStatus.Pending.value){
        return isValid;
      }
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.nameOfCompany) { 
        this.error.nameOfCompany = true; isValid = false; 
      }
      if (!this.dataObj.companyAddress) { 
        this.error.companyAddress = true; isValid = false; 
      }

      //awardingCompany
      if (!this.dataObj.awardingCompany) { 
        this.error.awardingCompany = true; isValid = false; 
      }
      if (!this.dataObj.contactPerson) { 
        this.error.contactPerson = true; isValid = false; 
      }
      if (!this.dataObj.position) { 
        this.error.position = true; isValid = false; 
      }
      if (!this.dataObj.email) { 
        this.error.email = true; isValid = false; 
      }
      if (!this.dataObj.cpfAc) { 
        this.error.cpfAc = true; isValid = false; 
      }
      if (!this.dataObj.local) { 
        this.error.local = true; isValid = false; 
      }
      if (!this.dataObj.phone) { 
        this.error.phone = true; isValid = false; 
      }
      // if (!this.dataObj.formDate) { 
      //   this.error.formDate = true; isValid = false; 
      // }
      if (!this.dataObj.foreign) { 
        if(this.dataObj.foreign != '0'){
          this.error.foreign = true; isValid = false; 
        }
      }
      // if (!this.dataObj.fax) { 
      //   this.error.fax = true; isValid = false; 
      // }
      if (!this.dataObj.country) { 
        this.error.country = true; isValid = false; 
      }
      if (!this.dataObj.clientName) { 
        this.error.clientName = true; isValid = false; 
      }
      if (!this.dataObj.contractorOrPorefNo) { 
        this.error.contractorOrPorefNo = true; isValid = false; 
      }
      if (!this.dataObj.durationStartDate) { 
        this.error.durationStartDate = true; isValid = false; 
      }
      if (!this.dataObj.durationEndDate) { 
        this.error.durationEndDate = true; isValid = false; 
      }
      
      if (!this.dataObj.applicantTotal) { 
          this.error.applicantTotal = true; 
          isValid = false; 
      }
      if(this.dataObj.applicantTotal == 0){
          this.error.applicantTotal = true; 
          isValid = false; 
        }
      if (!this.dataObj.applicantLabourCost) { 
          this.error.applicantLabourCost = true; isValid = false; 
      }
      if(this.dataObj.applicantLabourCost == 0){
         this.errMessage += "Labour cost cannot be 0";
          this.error.applicantLabourCost = true; isValid = false; 
        }
      if (!this.dataObj.industryMncoid) { 
        this.error.industryMncoid = true; isValid = false; 
      }
      if (!this.dataObj.plantOwerTotal) { 
        this.error.plantOwerTotal = true; isValid = false; 
      }
     
      if((this.file1 == null && this.file2 == null && this.file3 == null && this.file4 == null && this.file5 == null)  && this.isNew){
        this.errMessage += "Please upload attachments";
        isValid = false;
      }else if(!this.isNew && (!this.dataObj.fileList && (this.file1 == null && this.file2 == null && this.file3 == null && this.file4 == null && this.file5 == null))){
        console.log('flag 1');
        console.log(this.dataObj.fileList);
        this.errMessage += "Please upload attachments";
        isValid = false;
      }

       if (this.dataObj.subContractor && !this.dataObj.mainContractor) { 
        this.error.mainContractor = true; isValid = false; 
      }
      this.$forceUpdate();
      return isValid;
    },
    changeSubContractor(obj) {
      if (obj)
        this.dataObj.subContractor = obj.oid;
      else
        this.dataObj.subContractor = null;
    },
    changeMainContractor(obj) {
      if (obj)
        this.dataObj.mainContractor = obj.oid;
      else
        this.dataObj.mainContractor = null;
    },
    changeMNC(obj) {
      if (obj)
        this.dataObj.industryMncoid = obj.oid;
      else
        this.dataObj.industryMncoid = null;
    },
    
  }
}
</script>
