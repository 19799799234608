<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Skill Set Name:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
               <FormSelect :list="skillSets" :cssClass="error.skillOid && 'control-error'" value="oid" text="name" :existing="dataObj.skillOid" @change="dataObj.skillOid=$event"/>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Test Number:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input maxlength="20" type="text" class="form-control" :class="error.testNumber && 'control-error'" v-model="dataObj.testNumber" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Process:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input maxlength="50" type="text" class="form-control" :class="error.process && 'control-error'" v-model="dataObj.process" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Filler:
              </div>
              <div class="col-sm-6">
                <input maxlength="100" type="text" class="form-control" :class="error.filler && 'control-error'" v-model="dataObj.filler" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                FNo.:
              </div>
              <div class="col-sm-6">
                <input maxlength="7" type="text" class="form-control" :class="error.fno && 'control-error'" v-model="dataObj.fno" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PNo.:
              </div>
              <div class="col-sm-6">
                <input maxlength="7" type="text" class="form-control" :class="error.pno && 'control-error'" v-model="dataObj.pno" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Material:
              </div>
              <div class="col-sm-6">
                <input maxlength="100" type="text" class="form-control" :class="error.material && 'control-error'" v-model="dataObj.material" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                NPS:
              </div>
              <div class="col-sm-6">
                <input maxlength="30" type="text" class="form-control" :class="error.nps && 'control-error'" v-model="dataObj.nps" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Plate Size:
              </div>
              <div class="col-sm-6">
                <input maxlength="30" type="text" class="form-control" :class="error.plateSize && 'control-error'" v-model="dataObj.plateSize" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Test Piece:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.testPiece && 'control-error'" v-model="dataObj.testPiece" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Exam Type:
              </div>
              <div class="col-sm-6">
                <input maxlength="50" type="text" class="form-control" :class="error.examType && 'control-error'" v-model="dataObj.examType" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Note:
              </div>
              <div class="col-sm-6">
                <input maxlength="50" type="text" class="form-control" :class="error.note && 'control-error'" v-model="dataObj.note" />
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Category:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="categoryList" :cssClass="error.category && 'control-error'" value="value" text="text" :existing="dataObj.category" @change="dataObj.category=$event"/>
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Status:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :cssClass="error.status && 'control-error'" value="value" text="text" :existing="dataObj.status" @change="dataObj.status=$event"/>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h6>Test Positions</h6>
            <div class="row mb-3">
            <div class="col-sm-6">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Enter test position" aria-label="Enter test position" aria-describedby="button-addon2" v-model="testPosition" :cssClass="error.testPosition && 'control-error'">
                  <button class="btn btn-primary" type="button" id="button-addon2"  @click="addPosition">Add</button>
                </div>
                <br/>
                <table class="table table-sm" style="width:100%;">
                  <thead>
                    <th>Test Position</th>
                    <th>Actions</th>
                  </thead>
                  <tr v-for="(i, idx) in dataObj.positions" :key="idx">
                    <td>{{i.testPosition}}</td>
                    <td>
                      <a href="javascript:;" @click="clickRemove(idx)">Delete</a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6">
                Group:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.group && 'control-error'" v-model="dataObj.group" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Parent:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="parents" :cssClass="error.parentSettingOid && 'control-error'" value="oid" text="name" :existing="dataObj.parentSettingOid" @change="dataObj.parentSettingOid=$event"/>
              </div>
            </div>
          </div> -->
        </div>
        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
export default {
  name: 'AddEditTenant',

  components: {
    ...Forms
  },

  props: {

  },

  data() {
    return {
      testPosition:'',
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {
        positions:[]
      },
      timezone: api.TIMEZONE,
      error: {},
      skillSets:[],
      statuses:[],
      categoryList:[]
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = this.isNew ? 'New Skill Test' : 'Edit Skill Test';
    this.setBreadcrumb([
      { name: 'Skill Tests', url: '/skilltests'}, { name: pageName }
    ]);
    this.statuses = [
      { value: "", text: "All" },
      { value: "A", text: "Active" },
      { value: "S", text: "Suspended" },
    ];
    this.categoryList = [
      { value: 0, text: "" },
      { value: 1, text: "1" },
      { value: 2, text: "2" },
      { value: 3, text: "3" },
      { value: 4, text: "4" },
      { value: 5, text: "5" },
      { value: 6, text: "6" },
      { value: 7, text: "7" },
      { value: 8, text: "8" },
      { value: 9, text: "9" }
    ];

    let url = apiUrl.report.workerSummaryPreparedReport 
        api.regularGet(url,null ).then((response) => {
          this.skillSets = response.data.skillTests;
        });

    if (this.oid > 0) {
      this.getData();
    }
  },

  methods: {
    addPosition(){
      if(this.testPosition){
        this.dataObj.positions.push({
          "testPosition" : this.testPosition
        });
        this.testPosition = "";
      }
    },
    clickRemove(e){
      this.dataObj.positions.splice(e, 1);
    },
    getData() {
      let url = apiUrl.skillTest.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
      });
    },
    redirectToParent(){
        this.$router.push({ name: "SkillTests", params: { } });
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.skillTest.insert;
      }
      else {
        subUrl = apiUrl.skillTest.update;
      }
      
      api.regularPost(subUrl, this.dataObj, this.$store.state.token).then((response) => {
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.redirectToParent();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.redirectToParent();
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.skillOid) { this.error.skillOid = true; isValid = false; }
       if (!this.dataObj.testNumber) { this.error.testNumber = true; isValid = false; }
        if (!this.dataObj.process) { this.error.process = true; isValid = false; }
      this.$forceUpdate();
      return isValid;
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.testNumber + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.skillTest.delete, this.dataObj, this.$store.state.token).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.redirectToParent();
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    }
  }
}
</script>
