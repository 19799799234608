<template>
<div>
  <div class="float-start">
    <select v-model="selectedRowPerPage" @change="changeRowPerPage">
      <option v-for="(i,idx) in rowPerPageArr" :key="idx" :value="i">{{i}}</option>
    </select>
  </div>
  <div class="float-end">
    <nav aria-label="...">
      <ul class="pagination pagination-sm">

        <li class="page-item">
          <button class="page-link" @click="clickFirst">First</button>
        </li>

        <li class="page-item" :class="currentPage <= 1 ?'disabled':''">
          <span v-if="currentPage <= 1" class="page-link"><i class="bi bi-chevron-left small"></i></span>
          <button v-else class="page-link" @click="clickPrev"><i class="bi bi-chevron-left small"></i></button>
        </li>

        <li v-for="(i,idx) in totalPage" v-show="showOrHideItem(i)" :key="idx" class="page-item" :class="currentPage==i?'active':''">
          <button class="page-link" @click="clickPage(i)">{{i}}</button>
        </li>

        <li class="page-item" :class="currentPage >= totalPage ?'disabled':''">
          <span v-if="currentPage >= totalPage" class="page-link"><i class="bi bi-chevron-right small"></i></span>
          <button v-else class="page-link" @click="clickNext"><i class="bi bi-chevron-right small"></i></button>
        </li>

        <li class="page-item">
          <button class="page-link" @click="clickLast">Last</button>
        </li>

      </ul>
    </nav>
  </div>
  <div class="float-end text-secondary small" style="padding:5px;">
    {{startRow}} to {{endRow}} of {{totalRow}}
  </div>
</div>
</template>

<script>

export default {
  name: 'PaginationForAudit',
  props: {
    totalRow: Number,
    startRow: Number,
    endRow: Number,
    currentPage: Number,
    totalPage: Number,
    rowPerPage: Number,
  },
  data() {
    return {
      rowPerPageArr: [10, 50, 100, 200],
      selectedRowPerPage: 0,
      paramObj: {}
    }
  },
  mounted() {
    this.selectedRowPerPage = this.rowPerPage;
  },
  methods: {
    getParamObj() {
      return {
        totalRow: this.totalRow,
        startRow: this.startRow,
        endRow: this.endRow,
        currentPage: this.currentPage,
        totalPage: this.totalPage,
        rowPerPage: this.selectedRowPerPage,
      };
    },
    updateParams(obj) {
      this.$emit('updatePaging', obj)
    },
    clickPrev() {
      if (this.currentPage > 1) {
        let obj = this.getParamObj();
        obj.currentPage = this.currentPage - 1;
        this.updateParams(obj);
      }
    },
    clickNext() {
      if (this.currentPage < this.totalPage) {
        let obj = this.getParamObj();
        obj.currentPage = this.currentPage + 1;
        this.updateParams(obj);
      }
    },
    clickPage(i) {
      if (this.currentPage != i) {
        let obj = this.getParamObj();
        obj.currentPage = i;
        this.updateParams(obj);
      }
    },
    clickFirst() {
      if (this.currentPage != 1) {
        let obj = this.getParamObj();
        obj.currentPage = 1;
        this.updateParams(obj);
      }
    },
    clickLast() {
      if (this.currentPage != this.totalPage) {
        let obj = this.getParamObj();
        obj.currentPage = this.totalPage;
        this.updateParams(obj);
      }
    },
    changeRowPerPage() {
      let obj = this.getParamObj();
      obj.rowPerPage = this.selectedRowPerPage;
      this.updateParams(obj);
    },
    showOrHideItem(i) {
      let from = this.currentPage - 3;
      let to = this.currentPage + 3;

      if (from < 1)
        to += 1 - from;
      if (to > this.totalPage)
        from += this.totalPage - to;

      if (i >= from && i <= to)
        return true;
      else
        return false;
    }
  }
}
</script>
