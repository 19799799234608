<template>
<div>
  <div>
    
  </div>
  <div>
     <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Purpose
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" v-model="tableParam.purpose" />
              </div>
            </div>            
          </div>
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Subject
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" v-model="tableParam.subject" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable">Search</button>
          </div>          
        </div>
      </div>
    </div>
     <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <SortColumn @click="sortColumn" sortBy="oid" colGroup="tbl1">ID</SortColumn>
          <SortColumn @click="sortColumn" sortBy="purpose" colGroup="tbl1">Purpose</SortColumn>
          <SortColumn @click="sortColumn" sortBy="subjectTemplate" colGroup="tbl1">Subject</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.oid}}</td>
          <td>{{i.purpose}}</td>
          <td>{{i.subjectTemplate}}</td>
          <td>
            <button class="btn btn-sm btn-primary" @click="clickDetails(i)">
              <span>Edit</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
     </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';

export default {
  name: 'NotificationTemplates',
  components: {
     ...TableAddons,
    ...Forms,
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount(){
    this.tableParam.rowPerPage = 200;
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Notification Template' }
    ]);
    this.tableParam.purpose = "";
    this.tableParam.subject = "";
    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      console.log
      api.regularPost(apiUrl.notification.getNotificationTemplateList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDetails(data) {
      this.$router.push({ name: 'EditNotificationTemplate', params: { id: data.oid } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      console.log(paramObj);
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
  }
}
</script>
