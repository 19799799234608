<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterName" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-6">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text" @change="changeFilterStatus" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                SWS ID
              </div>
              <div class="col-sm-6">
                <FormSelect :list="swsMembers" :existing="tableParam.swsOid" value="oid" text="company" @change="changeFilterSws" />
              </div>
            </div>
            <div class="row mb-2">
              <div>
                <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="download">Download</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="corpName" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="address1" colGroup="tbl1">Address</SortColumn>
          <SortColumn @click="sortColumn" sortBy="swsName" colGroup="tbl1">SWS ID</SortColumn>
          <SortColumn @click="sortColumn" sortBy="adminContact" colGroup="tbl1">Administrator Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="adminDesignation" colGroup="tbl1">Designation</SortColumn>
          <SortColumn @click="sortColumn" sortBy="telephone" colGroup="tbl1">Telephone</SortColumn>
          <SortColumn @click="sortColumn" sortBy="ext" colGroup="tbl1">Ext</SortColumn>
          <SortColumn @click="sortColumn" sortBy="corporateEmail" colGroup="tbl1">Email</SortColumn>
          <SortColumn @click="sortColumn" sortBy="Payment" colGroup="tbl1">Payment</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.corpName}}</td>
          <td>{{i.address1}}</td>
          <td>{{i.swsName}}</td>
          <td>{{i.adminContact}}</td>
          <td>{{i.adminDesignation}}</td>
          <td>{{i.telephone}}</td>
          <td>{{i.ext}}</td>
          <td>{{i.corporateEmail}}</td>
          <td>{{i.paymentMethodName}}</td>
           <td>
            <span v-if="i.statusText == $data._corporateStatus.active.text" class="badge bg-success">{{i.statusText}}</span>
            <span v-else class="badge bg-danger">{{i.statusText}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-primary" @click="clickView(i)">View</button> 
            <button v-if="isEditPermission" style="margin-left:5px;" class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import _ from "lodash";

export default {
  name: 'Corporates',
  components: {
    ...TableAddons,
    ...Forms
  },
  props:{
    type: String
  },
  data() {
    return {
      tableParam: this.dtoPaging(), 
      swsMembers: [],
      statuses: [],
      isEditPermission:false
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount(){
    
  },
  mounted() {
    this.isEditPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageCorporate)
    this.setBreadcrumb([
      { name: this.getCorporateTypeTextByValue(this.type) }
    ]);

    this.tableParam.type = this.type;

    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];
    
    api.regularGet(apiUrl.regIndustry.dropdowns, null).then((response) => {
      this.swsMembers = response.data.sws;
      this.swsMembers.unshift({ oid: 0, swsid: "All" });
    });

    this.tableParam.status = "";
    this.tableParam.swsOid = 0;

    this.tableParam.status = this.$data._corporateStatus.active.value;

    this.callTable();
  },
  methods: { 
    download() {
      let data = {
        swsOid: this.tableParam.swsOid,
        status: this.tableParam.status,
        name: this.tableParam.name,
        type: this.tableParam.type
      }
      api.downloadFile(apiUrl.corporate.download,data,this.getCorporateTypeTextByValue(this.tableParam.type)+".xlsx",() =>{
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    callTable() {
      console.log('flag');
      this.tableParam.listData = [];
      api.regularPost(apiUrl.corporate.getList, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'EditCorporate', params: { id: data.oid, type: this.type } });
    },
    clickView(data) {
      this.$router.push({ name: 'ViewCorporate', params: { id: data.oid, type: this.type } });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    // changeTblFilter(text){
    //   this.tableParam.searchtext = text;
    //   this.callTable();
    // },

    changeFilterName: _.debounce(function (e) {
      this.tableParam.name = e.target.value;
      this.callTable();
    }, 500),

    changeFilterSws(e) {
      this.tableParam.swsOid = e;
      this.callTable();
    },

    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    }

  }
}
</script>
