<template>
  <div>
    <div class="row">
        <div class="col-6">
          <div>
        <img src="/img/PrimosLogo.png" style="width:165px;" />
      </div>
      <div class="mb-4">
        Process Industry Manpower Optimisation System
      </div>
        </div>
        <div class="col-6">
          <img src="/img/SWS-Logo-with-name.png" style="width:70px;float:right;" />
        </div>
      </div>

    <div class="row mb-3">
              <div class="col-3 text-secondary">Test Date:</div>
              <div class="col-6">
                <label class="control-label">{{testDateDisplay}}</label>
              </div>
            </div>
    <div class="row mb-3">
              <div class="col-3 text-secondary">Test Centre:</div>
              <div class="col-6">
                <label class="control-label">{{testCentreDisplay}}</label>
              </div>
            </div>
    <table class="table">
      <thead>
        <tr>
          <th>NRIC/FIN</th>
          <th>Work Permit</th>
          <th>Worker Name</th>
          <th>Welder ID</th>
          <th>Skill Test</th>
          <th>Test No.</th>
          <th>Component</th>
          <th>Medium</th>
          <th>Year Exp.</th>
          <th>App ID</th>
          <th>App Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i,idx) in listData" :key="idx">
          <td v-if="i.rowSpan" :rowspan="i.rowSpan">{{i.nricFin}}</td>
          <td v-if="i.rowSpan" :rowspan="i.rowSpan">{{i.workPermit}}</td>
          <td v-if="i.rowSpan" :rowspan="i.rowSpan">{{i.workerName}}</td>
          <td v-if="i.rowSpan" :rowspan="i.rowSpan">{{i.welderId}}</td>

          <td v-if="!i.hideWorkerInfo && !i.rowSpan"><span>{{i.nricFin}}</span></td>
          <td v-if="!i.hideWorkerInfo && !i.rowSpan"><span>{{i.workPermit}}</span></td>
          <td v-if="!i.hideWorkerInfo && !i.rowSpan"><span>{{i.workerName}}</span></td>
          <td v-if="!i.hideWorkerInfo && !i.rowSpan"><span>{{i.welderId}}</span></td>

          <td>{{i.skill}}</td>
          <td>{{i.testNumber}}</td>
          <td>{{i.testComponent}}</td>
          <td>{{i.testMedium}}</td>
          <td>{{i.yearExperience}}</td>
          <td>{{i.testApplicationId}}</td>
          <td>
            <span class="badge" :class="getTestCandidateStatusColor(i.status)">{{getTestCandidateStatusTextByValue(i.status)}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
export default {
  name: 'TestApplicationCandidatesByDatePrint',
  props: {
  },
  data() {
    return {
      listData: [],
      testDate: '',
      testCentreOid: 0,
      skillOid: 0,
      testDateDisplay:'',
      testCentreDisplay:''
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {

  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Test Applications' }
    ]);

    this.testDate = this.$route.params.testDate;
    this.testCentreOid = this.$route.params.testCentreOid;
    this.skillOid = this.$route.params.skillOid;

    if (this.testDate)
      this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.listData = [];
      api.regularGet(apiUrl.testApplication.getCandidatesByDate, { testDate: this.testDate, testCentreOid: this.testCentreOid, skillOid: this.skillOid }).then(res => {
        this.hideLoadingScreen();
        let list = res.data;
        let firstFoundIndex = -1;
        for (let i = 0; i < list.length; i++) {
          
          //Add show or hide worker info in row
          if(i > 0 && list[i].nricFin == list[i-1].nricFin){
            list[i].hideWorkerInfo = true;
          }

          //Add row span in row
          if (i < list.length - 1 && list[i].nricFin == list[i + 1].nricFin) {
            if (firstFoundIndex == -1)
              firstFoundIndex = i;
            if (list[firstFoundIndex].rowSpan > 0) {
              list[firstFoundIndex].rowSpan++;
            }
            else {
              list[firstFoundIndex].rowSpan = 2;
            }
          }
          else {
            firstFoundIndex = -1;
          }

        }
        this.listData = list;
        this.testDateDisplay = this.listData[0].testDate;
        this.testCentreDisplay = this.listData[0].testCentreName;
        setTimeout(() => {
          window.print();
        }, 2000)
      })
    }
  }
}
</script>
