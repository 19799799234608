<template>
<div>
    <div class="card">
      <div class="card-body">
        <div>
            <h6 class="card-subtitle mb-2 text-muted">Welder Information</h6>
        <div class="row">
            <div class="col-md-12">
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Corporate Name:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <FormSelect :list="corporates" value="oid" text="corpName" :existing="dataObj.corporateOid" @change="dataObj.corporateOid=$event"/>
                </div>
            </div>
            <hr/>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Welding Inspector Name:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control" maxlength="50"
                    v-model="dataObj.name"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Designation:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.designation"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Qualification:</div>
                <div class="col-sm-8">
                <FormSelect :list="qualifications" value="code" text="value" :existing="dataObj.qualification" @change="dataObj.qualification=$event"/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Certificate No.:</div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.weldCertNo"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Qualification Expiry Date:</div>
                <div class="col-sm-8">
                <DatePicker @updatedate="changeQualificationExpiryDate" :existing="expiryDate"></DatePicker>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Experience:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <textarea
            class="form-control"
            rows="3"
            v-model="dataObj.experience"
            />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Professional Membership:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.profMember"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Telephone No:<span class="text-danger">*</span></div>
                <div class="col-sm-4">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.telephone"
                />
                </div>
                <div class="col-sm-1 text-secondary">Ext</div>
                <div class="col-sm-3">
                    <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.ext"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Fax:</div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.fax"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Email Address<span class="text-danger">*</span>:</div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.email"
                />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Mobile Contact No:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.mobileNo"
                />
                </div>
            </div>
            <!-- <div class="row mb-3">
                <div class="col-sm-4">Status:<span class="text-danger">*</span></div>
                <div class="col-sm-8">
                <FormSelect :list="statuses" value="value" text="text" :existing="dataObj.status" @change="dataObj.status=$event" />
                </div>
            </div> -->
            </div>
        </div>
        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Activate</button>
         <button class="btn btn-danger m-1" @click="confirmRemove">
          Delete
        </button>
        </div>
      </div>
    </div>
    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
    <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="WeldingInspector" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
    </div>
</ModalDiaglog>   
</div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from "@/components/custom/forms";
import * as Pickers from "@/components/custom/pickers";
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
export default {
  name: "ActivateWeldingInspector",
  components: {
      AuditLog,
    ModalDiaglog,
    ...Forms,
    ...Pickers
  },
  props: {
    type: String
  },
  data() {
    return {
      basicLogInfoUrl: apiUrl.weldinginspector.getBasicLogInfo,
      oid: 0,
      expiryDate: null,
      isNew: true,
      confirmObj: {},
      dataObj: { giro: {} },
      timezone: api.TIMEZONE,
      corporates:[],
      qualifications:[],
      statuses: [],
      showAuditModal: false,
      parentUrl: ''
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {
    let pageName =  'Activate ';
    pageName += this.getCorporateTypeTextByValue(this.$route.params.type);
    let prevLink = { name: this.$data._corporateType.weldingInspector.text, url: '/activateweldinginspectors' };
    this.setBreadcrumb([
      prevLink, { name: pageName }
    ]);
    this.statuses = [
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];
    
    if (this.oid > 0) {
      this.getData();
    }else{
        this.getDropdowns();
    }
    
  },
  methods: {
    submit() {},
    modal() {
      this.showModal = true;
    },
    getData() {
      let url = apiUrl.activateweldinginspector.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        console.log(this.dataObj.weldCertExpiryDate_String);
        if (this.dataObj.weldCertExpiryDate_String)
          this.expiryDate = this.convertDateStrToDateObj(this.dataObj.weldCertExpiryDate_String);
        this.getDropdowns();
      });
    },
    getDropdowns() {
      let url = apiUrl.regWeldingInspector.dropdowns
      api.regularGet(url, null).then((response) => {
        let dropdowns = response.data;
        this.corporates = dropdowns.corporates;
        this.qualifications = dropdowns.qualifications;
      });
    },
    clickSave() {
      let msg = this.validate();
      if (msg) {
        this.showMessage(msg, "warning");
        return;
      }else{
        let subUrl = apiUrl.activateweldinginspector.activate;
      this.dataObj.weldCertExpiryDate = null;
      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.isMain = false;
          this.showMessage("Successfully updated.", "success");
          this.$router.push({ name: "Activate Welding Inspectors" });
        } else if (
          response.status == 400 ||
          response.errorText.response.status == 400
        ) {
          this.showMessage(response.errorText.response.data.message, "error");
        } else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
      }
      
    },
    clickCancel() {
      this.$router.push({ name: "Activate Welding Inspectors" });
    },
    validate() {
      let msg = "";
      if (!this.dataObj.corporateOid){
        msg += window.message.weldertemp.corp_blank;
      }
      if (!this.dataObj.designation){
        msg += window.message.weldertemp.designation_blank;
      }
      if (!this.dataObj.experience){
        msg += window.message.weldertemp.experience_blank;
      }
      if (!this.dataObj.profMember){
        msg += window.message.weldertemp.membership_blank;
      }
      if (!this.dataObj.telephone){
        msg += window.message.weldertemp.telephone_blank;
      }
      if (!this.dataObj.email){
        msg += window.message.weldertemp.email_blank;
      }
      if (!this.dataObj.mobileNo){
        msg += window.message.weldertemp.mobile_blank;
      }

       
      return msg;
    },

    changeQualificationExpiryDate(date){
      this.dataObj.weldCertExpiryDate = this.convertDateObjToDateStr(date);
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.name + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.activateweldinginspector.delete, this.dataObj).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "Activate Welding Inspectors" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    }
  },
}
</script>
