<template>
<div>
  <div>
    
  </div>
  <div>
     <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Year
              </div>
              <div class="col-sm-6">
                <FormSelect :list="years" :existing="tableParam.year" value="value" text="text" @change="changeFilterYear" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable">Search</button>
          </div>
          
        </div>
      </div>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <SortColumn @click="sortColumn" sortBy="quotaApplicationNo" colGroup="tbl1">Quota Application No</SortColumn>
          <SortColumn @click="sortColumn" sortBy="mncName" colGroup="tbl1">MNC Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="mainContractorName" colGroup="tbl1">Main Contractor Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="subContractorName" colGroup="tbl1">Sub Contractor Name</SortColumn>
          <th>Last Modified Date</th>
          <th>Status</th>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.quotaApplicationNo}}</td>
          <td>{{i.mncName}}</td>
          <td>{{i.mainContractorName}}</td>
          <td>{{i.subContractorName}}</td>
          <td>{{i.modifiedDate}}</td>
          <td>
            {{i.statusDisplay}}
          </td>
          <td>
             <button v-if="i.canEdit" class="btn btn-sm btn-primary" @click="clickEdit(i)">
              <span>Edit</span>
            </button>
            <button v-else class="btn btn-sm btn-primary" @click="clickDetails(i)">
              <span>View</span>
            </button>
            <div v-if="i.status == $data._quotaStatus.Pending.value" class="dropdown float-end" style="display:inline-block">
              <button style="background: transparent;color: black;border: none;" class="btn btn-primary" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                :
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="#" @click="deleteQuota(i.oid)">Delete</a></li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'FormAListing',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: [],
      years:[]
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Form A Listing' }
    ]);
    this.years = [];

    let currentYear = new Date().getFullYear();
    this.years.push({ value: "", text: "All" });
    this.years.push({ value: currentYear, text: currentYear });
    for (let index = 1; index < 11; index++) {
      var year = currentYear-index;
      this.years.push({ value: year, text: year });
    }
    // this.tableParam.status = this.$data._status.active.value;
    // this.tableParam.expiredDate_Start = null;
    // this.tableParam.expiredDate_End = null;
    // this.tableParam.terminatedDate_Start = null;
    // this.tableParam.terminatedDate_End = null;
    // this.tableParam.company = "";
    // this.tableParam.swsId = "";
    this.tableParam.years = "";
     this.tableParam.type = "list";
    this.callTable();
  },
  methods: {
    //  downloadSetting() {
    //   this.showLoadingScreen();
    //   let data = {
    //     expiredDate_Start: this.tableParam.expiredDate_Start,
    //     expiredDate_End: this.tableParam.expiredDate_End,
    //     terminatedDate_Start: this.tableParam.terminatedDate_Start,
    //     terminatedDate_End: this.tableParam.terminatedDate_End,
    //     company: this.tableParam.company,
    //     swsId:  this.tableParam.swsId,
    //     status: this.tableParam.status
    //   }
    //   api.downloadFile(apiUrl.sws.download,data,"sws.xlsx",() =>{
    //     this.hideLoadingScreen();
    //     this.showMessage("Successfully downloaded.", "success");
    //   });
    // },
    
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.quota.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDetails(data) {
      this.$router.push({ name: 'ViewQuota', params: { id: data.oid } });
    },
    clickEdit(data) {
      this.$router.push({ name: 'FormA', params: { id: data.oid } });
    },
    addNew() {
      this.$router.push({ name: 'AddEditSWS' });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeFilterYear(e) {
      this.tableParam.year = e;
      this.callTable();
    },
    deleteQuota(e){
      console.log(e);
      let data = {
        oid: e
      };
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ?',
          submitFunction: () => {
            this.showLoadingScreen();
            api.regularPost(apiUrl.quota.delete, data).then((response) => {
              this.hideLoadingScreen();
              console.log(response);
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.callTable();
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    }
  }
}
</script>
