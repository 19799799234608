<template>
  <div>
    <div class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <h4>Corporate Information</h4>
        <div class="row">
          <div class="col-md-6">
            <div v-show="isTestCentreCwqs" class="row mb-3">
              <div class="col-sm-6  text-secondary">
                SAC Certificate Number:
              </div>
              <div class="col-sm-6">
                <FormSelect :cssClass="error.saccertNumber && 'control-error'" :list="sac" value="code" text="value" :existing="dataObj.saccertNumber" @change="dataObj.saccertNumber=$event"/>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Corporate Name:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.corpName && 'control-error'" v-model="dataObj.corpName" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Building Name:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.buildingName && 'control-error'" v-model="dataObj.buildingName" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Block No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.blkNo && 'control-error'" v-model="dataObj.blkNo" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Unit No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.unitNo && 'control-error'" v-model="dataObj.unitNo" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Address:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.address1 && 'control-error'" v-model="dataObj.address1" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Address 2:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.address2 && 'control-error'" v-model="dataObj.address2" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6  text-secondary">
                Country:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <FormSelect :list="countries" value="oid" text="name" :cssClass="error.countryOid && 'control-error'"
                :existing="dataObj.countryOid" @change="dataObj.countryOid=$event" />
              </div>
            </div>            
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Postcode:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.postalCode && 'control-error'" v-model="dataObj.postalCode" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Administrator Name:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.adminContact && 'control-error'" v-model="dataObj.adminContact" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Administrator Designation:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.adminDesignation && 'control-error'" v-model="dataObj.adminDesignation" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Telephone No.:
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <input type="text" class="form-control" maxlength="8" :class="error.telephone && 'control-error'" v-model="dataObj.telephone" />
                  <span class="input-group-text">Ext</span>
                  <input type="text" class="form-control" maxlength="5" :class="error.ext && 'control-error'" v-model="dataObj.ext" />
                </div>                
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Fax No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" maxlength="8" v-model="dataObj.fax" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Email:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" maxlength="50" v-model="dataObj.corporateEmail" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Mobile Contact No.:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" maxlength="10" v-model="dataObj.mobileNo" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Auto Invoice:
              </div>
              <div class="col-sm-6">
                <input type="checkbox" v-model="dataObj.isAutoInvoice" />
              </div>
            </div>                
          </div>
        </div>

        <div v-show="isContractor">
          <hr />

          <div class="row mb-3">
            <div class="col-md-3 text-secondary">Ownership(Contractors Only)<span class="text-danger">*:</span></div>
            <div class="col-md-9">
              <FormSelect :cssClass="error.ownershipOid && 'control-error'" :list="ownerships" value="oid" text="value"
                :existing="dataObj.ownershipOid" @change="dataObj.ownershipOid=$event" />
            </div>
          </div>
          <h6 class="card-subtitle mb-2 text-muted mt-4">Principal Activities <span class="text-danger">*</span></h6>
          <div class="row mb-1">
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="principaleActivities.plantConstruction" /> Plant Construction</label>
            </div>
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="principaleActivities.plantMaintenance" /> Plant Maintenance</label>
            </div>
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="principaleActivities.processDesign" /> Process Design</label>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="principaleActivities.buildingConstruction" /> Building Construction</label>
            </div>
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="principaleActivities.equipmentInstallation" /> Equipment Installation</label>
            </div>
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="principaleActivities.shopFabrication" /> Shop Fabrication</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">
              Others, please specify
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control" :class="error.others && 'control-error'"
                v-model="principaleActivities.others" />
            </div>
          </div>
          <h6 class="card-subtitle mb-2 text-muted mt-4">Role of Contractor(Contactors only) 1 or more selections:</h6>
          <div class="row mb-3">
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="roleOfContractor.turnkeyContractor" /> Turnkey
                Contractor</label>
            </div>
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="roleOfContractor.mainContractor" /> Main
                Contractor</label>
            </div>
            <div class="col-sm-4">
              <label class="hover"><input type="checkbox" v-model="roleOfContractor.subContractor" /> Sub
                Contractor</label>
            </div>
          </div>
          <h6 class="card-subtitle mb-2 text-muted mt-4">Employment Strength (Contractors Only)</h6>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">Total company strength<span class="text-danger">*</span>:</div>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="dataObj.companyStrength"
                :class="error.companyStrength && 'control-error'" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">No. of management staff:<span class="text-danger">*</span></div>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="dataObj.managementStaff"
                :class="error.managementStaff && 'control-error'" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">No. of foreigner:<span class="text-danger">*</span></div>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="dataObj.foreignerWorker"
                :class="error.foreignerWorker && 'control-error'" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">No. of local:<span class="text-danger">*</span></div>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="dataObj.localWorker"
                :class="error.localWorker && 'control-error'" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">Training budget(%)<span class="text-danger">*</span>:</div>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="dataObj.trainingBudget"
                :class="error.trainingBudget && 'control-error'" />
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6">
            <div v-show="isContractor" class="row mb-3">
              <div class="col-sm-6 text-secondary">Customer Account No:</div>
              <div class="col-sm-6">
                {{dataObj.refGiro}}
              </div>
            </div>
            <div v-show="isContractor" class="row mb-3">
              <div class="col-sm-6 text-secondary">CPF Account No.:<span class="text-danger">*</span></div>
              <div class="col-sm-6">
                <input type="text" class="form-control" v-model="dataObj.cpfAccountNo"
                  :class="error.cpfAccountNo && 'control-error'"  maxlength="20" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                SWS Member Name:<span v-if="isTestCentreCwqs" class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <v-select label="company" :class="error.swsoid && 'control-error'" v-model="selectedSws"
                  :options="swsMembers" @input="$event==null?dataObj.swsoid=null:dataObj.swsoid = $event.oid">
                </v-select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Payment Method:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="payments" value="oid" text="name"
                  :cssClass="error.paymentMethodOid && 'control-error'" :existing="dataObj.paymentMethodOid"
                  @change="changePayment" />
              </div>
            </div>
          </div>
        </div>
        
        <div v-show="isGiro">
          <hr/>
          <h4>GIRO Information</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">
                  Name of Financial Institution:
                </div>
                <div class="col-sm-6">
                  <FormSelect :list="banks" value="oid" text="nameCode" :existing="giro.bankOid" @change="changeBank" />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">
                  Branch:
                </div>
                <div class="col-sm-6">
                  <FormSelect :list="bankBranches" value="oid" text="nameCode" :existing="giro.branchCodeOid" @change="giro.branchCodeOid=$event" />
                </div>
              </div>              
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">
                  Account Number:
                </div>
                <div class="col-sm-6">
                  <input type="text"
                  maxlength="11" class="form-control" v-model="giro.bankAccountNum" />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">
                  Account Holder Name:
                </div>
                <div class="col-sm-6">
                  <input type="text" class="form-control" v-model="giro.name" />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">
                  Account Holder Contact (Tel/Fax):
                </div>
                <div class="col-sm-6">
                  <input type="text" class="form-control"
                  maxlength="8"
                   v-model="giro.telephone" />
                </div>
              </div>
              <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Giro Status:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="giroStatuses" value="value" text="text" :existing="dataObj.giroStatus" @change="dataObj.giroStatus=$event" />
              </div>
            </div>
            </div>
          </div>
        </div>

        <hr/>

        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Status:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" value="value" text="text" :existing="dataObj.status" @change="dataObj.status=$event" />
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <span class="text-danger small">* Denotes Mandatory Field</span>

        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <!-- <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button> -->
      </div>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Corporate" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'EditCorporate',

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms
  },

  props: {
    type: String
  },

  data() {
    return {
      selectedSws: {},
      basicLogInfoUrl: apiUrl.corporate.getBasicLogInfo,
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      giro: {},
      giroStatuses:[],
      principaleActivities: {
        plantConstruction: false,
        plantMaintenance: false,
        processDesign: false,
        buildingConstruction: false,
        equipmentInstallation: false,
        shopFabrication: false,
        others: ""
      },
      roleOfContractor: {
        turnkeyContractor: false,
        mainContractor: false,
        subContractor: false
      },
      timezone: api.TIMEZONE,
      countries: [],
      swsMembers: [],
      ownerships: [],
      banks: [],
      branches: [],
      bankBranches: [],
      sac: [],
      payments: [],
      statuses: [],
      showAuditModal: false,
      parentUrl: '',
      error: {},
      isContractor: false,
      isTestCentreCwqs: false,
      isGiro: false,
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {

    //breadcrumbs
    let pageName = this.isNew ? 'New ' : 'Edit ';
    pageName += this.getCorporateTypeTextByValue(this.$route.params.type);
    let prevLink = {};
    switch (this.$route.params.type) {
      case this.$data._corporateType.industry.value: { prevLink = { name: this.$data._corporateType.industry.text, url: '/industries' }; break; }
      case this.$data._corporateType.contractor.value: { prevLink = { name: this.$data._corporateType.contractor.text, url: '/contractors' }; break; }
      case this.$data._corporateType.testCenter.value: { prevLink = { name: this.$data._corporateType.testCenter.text, url: '/testcentrecwqs' }; break; }
    }
    this.setBreadcrumb([
      prevLink, { name: pageName }
    ]);


    api.regularGet(apiUrl.regIndustry.dropdowns, null).then((response) => {
      this.countries = response.data.countries;
      this.swsMembers = response.data.sws;
      this.banks = response.data.banks;
      this.branches = response.data.branches;
      this.ownerships = response.data.ownerships;
      for (let index = 0; index < response.data.sac.length; index++) {
        let data = response.data.sac[index];
        this.sac.push({"code": data.code,"value": data.value + " [" + data.code + "]"});
      }
      this.payments = response.data.payments;

      if (this.oid > 0) {
        this.getData();
      }
    });

    this.statuses = [
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];
    this.giroStatuses = [
      { value: this.$data._giroStatus.active.value, text: this.$data._giroStatus.active.text },
      { value: this.$data._giroStatus.pending.value, text: this.$data._giroStatus.pending.text },
    ];
  },

  methods: {
    getData() {
      let url = apiUrl.corporate.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;

        this.changePayment(this.dataObj.paymentMethodOid);
        this.selectedSws = this.swsMembers.find(x => x.oid == this.dataObj.swsoid);
        if (this.dataObj.giro) {
          this.giro = this.dataObj.giro;
          this.changeBank(this.giro.bankOid);
        }

        switch (this.dataObj.type) {
          case this.$data._corporateType.industry.value: {
            this.parentUrl = 'Industries';
            break;
          }
          case this.$data._corporateType.contractor.value: {
            this.parentUrl = 'Contractors';
            this.isContractor = true;

            if (this.dataObj.principalActivities) {
              this.principaleActivities = JSON.parse(this.dataObj.principalActivities);
            }
            if (this.dataObj.roleOfContractor) {
              this.roleOfContractor = JSON.parse(this.dataObj.roleOfContractor);
            }

            break;
          }
          case this.$data._corporateType.testCenter.value: {
            this.parentUrl = 'TestCentreCWQS';
            this.isTestCentreCwqs = true;
            break;
          }
        }
      });
    },
    clickSave() {
      if (!this.validate())
        return;

      if (this.isContractor) {
        this.dataObj.principalActivities = JSON.stringify(this.principaleActivities);
        this.dataObj.roleOfContractor = JSON.stringify(this.roleOfContractor);
      }

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.corporate.insert;
      }
      else {
        subUrl = apiUrl.corporate.update;
      }

      if (this.isPaymentMethodGiro(this.dataObj.paymentMethodOid)) {
        this.dataObj.giro = this.giro;
      }
      else {
        this.dataObj.giro = null;
      }

      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: this.parentUrl });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: this.parentUrl });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      if (this.isContractor && !this.dataObj.cpfAccountNo) { this.error.cpfAccountNo = true; isValid = false; }
      if (!this.dataObj.corpName) { this.error.corpName = true; isValid = false; }
      if (!this.dataObj.countryOid) { this.error.countryOid = true; isValid = false; }
      if (!this.dataObj.swsoid && this.isTestCentreCwqs) 
      { 
        this.error.swsoid = true; 
        isValid = false; 
      }
      this.$forceUpdate();

      return isValid;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.name + '?',
          submitFunction: () => {
            this.showLoadingScreen();
            api.regularPost(apiUrl.corporate.delete, this.dataObj).then((response) => {
              this.hideLoadingScreen();
              console.log(response);
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: this.parentUrl });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    changePayment(e) {
      this.dataObj.paymentMethodOid = e;
      let isPaymentGiro = this.isPaymentMethodGiro(this.dataObj.paymentMethodOid);
      if (isPaymentGiro) {
        this.isGiro = true;
      }
      else {
        this.isGiro = false;
      }
    },
    showAudit() {
      this.showAuditDialog(true);
    },
    isPaymentMethodGiro(oid) {
      let tmpObj = this.payments.find(x => x.oid == oid);
      if (tmpObj && tmpObj.name.toLowerCase() == 'giro') {
        return true;
      }
      else {
        return false;
      }
    },
    changeBank(e) {
      this.giro.bankOid = e;
      this.bankBranches = this.branches.filter(x => x.parentSettingOid == e);
    }
  }
}
</script>
