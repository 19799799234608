<template>
  <div>
    <hr />
    <!-- Header with full-height image -->
    <header class="bgimg-1">
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="4"
            aria-label="Slide 4"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="img/DSC03104.JPG" class="d-block w-100" alt="..." />
            <div class="carousel-caption d-none d-md-block">
              <h2 class="carousel-text">
                PRiMOS offers you a user friendly web-based workforce management
                system to monitor the worker's details, job performance,
                worksite assignment and more.
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="img/DSC07475.JPG"
              class="d-block w-100"
              alt="..."
              style="background-position: 30% 50%"
            />
            <div class="carousel-caption d-none d-md-block">
              <h2 class="carousel-text">
                PRiMOS offers you a user friendly web-based workforce management
                system to monitor the worker's details, job performance,
                worksite assignment and more.
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <img src="img/DSC00851.JPG" class="d-block w-100" alt="..." />
            <div class="carousel-caption d-none d-md-block">
              <h2 class="carousel-text">
                PRiMOS offers you a user friendly web-based workforce management
                system to monitor the worker's details, job performance,
                worksite assignment and more.
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <img src="img/DSC_0488.JPG" class="d-block w-100" alt="..." />
            <div class="carousel-caption d-none d-md-block">
              <h2 class="carousel-text">
                PRiMOS offers you a user friendly web-based workforce management
                system to monitor the worker's details, job performance,
                worksite assignment and more.
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <img src="img/DSC03113.JPG" class="d-block w-100" alt="..." />
            <div class="carousel-caption d-none d-md-block">
              <h2 class="carousel-text">
                PRiMOS offers you a user friendly web-based workforce management
                system to monitor the worker's details, job performance,
                worksite assignment and more.
              </h2>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </header>

    <br />

    <div class="container">
      <div>
        <div class="d-flex">
          <div style="font-size: 28px; width: 348px" class="mb-2">
            New Announcements
          </div>
          <div
            style="
              width: 100%;
              border-bottom: 2px solid #bababa;
              margin-bottom: 25px;
            "
          ></div>
        </div>
        <div class="card" style="">
          <ul class="list-group list-group-flush">
            <span v-for="(i, idx) in newsParam.listData" :key="idx">
              <li class="list-group-item">
                <b>{{ i.title }}</b
                ><i
                  class="position-absolute end-0"
                  style="padding-right: 15px"
                  >{{ i.createdDate }}</i
                ><br /><span v-html="i.detail"></span>
              </li>
            </span>
          </ul>
        </div>
      </div>
      <div class="d-flex  mt-5">
        <div style="font-size: 28px; width: 121px" class="mb-2">In Focus</div>
        <div
          style="
            width: 100%;
            border-bottom: 2px solid #bababa;
            margin-bottom: 25px;
          "
        ></div>
      </div>
      <div class="card-group">
        <div class="card">
          <a
            style="color: black; text-decoration: none"
            :href="info[0].herf"
            >
          <img
            class="card-img-top center-3"
            :src="info[0].imageUrl"
            alt="Card image cap"
            style="width: 100%"
          />
          </a>
          <div class="card-body">
            <a
            style="color: black; text-decoration: none"
            :href="info[0].herf"
            ><p class="card-text">{{info[0].caption}}</p></a>
          </div>
        </div>
        <div class="card">
          <a
            style="color: black; text-decoration: none"
            :href="info[1].herf"
            >
          <img
            class="card-img-top center-3"
            :src="info[1].imageUrl"
            alt="Card image cap"
            style="width: 100%"
          /></a>
          <div class="card-body">
            <a
            style="color: black; text-decoration: none"
            :href="info[1].herf"
            ><p class="card-text">{{info[1].caption}}</p></a>
          </div>
        </div>
        <div class="card">
          <a style="color: black; text-decoration: none" href="/pages/subfee"
            ><img
              class="card-img-top center-3"
              :src="info[2].imageUrl"
              alt="Card image cap"
              style="width: 100%"
          /></a>
          <div class="card-body">
            <p class="card-text">
              <a
                style="color: black; text-decoration: none"
                href="/pages/subfee"
                >{{info[2].caption}}</a
              >
            </p>
          </div>
        </div>
        <div class="card">
          <a
            style="color: black; text-decoration: none"
            :href="info[3].herf"
            ><img
              class="card-img-top center-3"
              :src="info[3].imageUrl"
              alt="Card image cap"
              style="width: 100%"
          /></a>
          <div class="card-body">
            <p class="card-text">
              <a
                style="color: black; text-decoration: none"
                :href="info[3].herf"
                >{{info[3].caption}}</a
              >
            </p>
          </div>
        </div>
        <div class="card">
          <a
            style="color: black; text-decoration: none"
           :href="info[4].herf"
            ><img
              class="card-img-top center-3"
              :src="info[4].imageUrl"
              alt="Card image cap"
              style="width: 100%"
          /></a>
          <div class="card-body">
            <p class="card-text">
              <a
                style="color: black; text-decoration: none"
                :href="info[4].herf"
              >
                {{info[4].caption}}</a
              >
            </p>
          </div>
        </div>
        <div class="card">
          <a
            style="color: black; text-decoration: none"
            :href="info[5].herf"
            ><img
              class="card-img-top center-3"
              :src="info[5].imageUrl"
              alt="Card image cap"
              style="width: 100%"
          /></a>
          <div class="card-body">
            <p class="card-text">
              <a
                style="color: black; text-decoration: none"
                :href="info[5].herf"
                >{{info[5].caption}}</a
              >
            </p>
          </div>
        </div>
      </div>
      
      <div class="d-flex mt-5">
        <div style="font-size: 28px; width: 170px" class="mb-2">
          Quick Links
        </div>
        <div
          style="
            width: 100%;
            border-bottom: 2px solid #bababa;
            margin-bottom: 25px;
          "
        ></div>
      </div>
      <div class="card-group">
        <div class="card" v-for="(i, idx) in linksParam.listData" :key="idx">
          <a
            :href="i.url"
            target="_blank"
            style="font-size: 18px; text-decoration: none"
          >
            <img
              class="card-img-top center-3"
              :src="i.imageUrl"
              alt="Card image cap"
              style="width: 100%"
            />
            <div class="card-body">
              {{ i.title }}
            </div>
          </a>
        </div>
      </div>

      <!-- <h2 style="padding-left: 55px; padding-right: 55px; ">Quick Links</h2> -->
      <!-- <div class="card-group" style="padding-left: 55px; padding-right: 55px;">
        <div class="card">
          <br>
          <img class="card-img-top center-3" src="https://www.edb.gov.sg/content/dam/global/logos/edb/edb-logo.svg"
            alt="Card image cap">
          <div class="card-body">
            <br>
            <h5 class="card-title">EDB Singapore</h5>
            <p class="card-text">The Singapore Economic Development Board (EDB), a government agency under the
              Ministry of Trade and Industry, is responsible for strategies that enhance Singapore’s position as a
              global centre for business, innovation, and talent.</p>
          </div>
          <a class="loginButton btn btn-outline-primary" href="http://www.sedb.com/" target="_blank">Visit Now</a>
        </div>
        <div class="card">
          <br>
          <img class="card-img-top center-4"
            src="https://d33wubrfki0l68.cloudfront.net/9a63551abab9ead2287b37ec12988d4df36cf67e/4eeb3/images/homepage/sac-esg-logo.png"
            alt="Card image cap">
          <div class="card-body">
            <br>
            <h5 class="card-title">Singapore Accreditation Council</h5>
            <p class="card-text">Managed by Enterprise Singapore, SAC maintains the integrity and impartiality of
              conformity assessment practices.</p>
          </div>
          <a class="loginButton btn btn-outline-primary" href="http://www.sac-accreditation.org.sg/"
            target="_blank">Visit Now</a>
        </div>
      </div> -->

      <br /><br />
    </div>

    <!-- <div>
      <h2 style="padding-left: 55px; padding-right: 55px;">Quick Links</h2>
      <div class="card" style="margin-left: 55px; margin-right: 55px;">
        <ul class="list-group list-group-flush">
          <span v-for="(i, idx) in linksParam.listData" :key="idx">
            <li class="list-group-item">
              <a class="link-primary" @click="handleQuickLink(i.url)" target="_blank">{{i.title}}</a>
            </li>
          </span>
        </ul>
      </div>
    </div> -->

    <br />
    <br />
  </div>
</template>

<!-- <button class="btn btn-primary btn-xs ms-2" @click="clickPrintInvoice">Print Invoice</button> -->

<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: "Home",
  data() {
    return {
      info:[
        {},{},{},{},{},{}
      ],
      newsParam: this.dtoPaging(),
      linksParam: this.dtoPaging(),
      tempImages: [
        "/img/tmpimg_1.png",
        "/img/tmpimg_2.png",
        "/img/tmpimg_3.png",
        "/img/tmpimg_4.png",
        "/img/tmpimg_5.png",
      ],
    };
  },
  mounted() {
    this.callNewsTable();
    this.callLinksTable();
    this.info = window.infocus;
  },
  methods: {
    handleQuickLink(url) {
      window.open(url, "_blank");
    },
    callNewsTable() {
      this.newsParam.listData = [];
      api
        .regularPost(
          apiUrl.newsAnnouncements.getUnauthorizedList,
          this.newsParam
        )
        .then((response) => {
          if (response.status == 200) {
            this.newsParam = response.data;
          }
        });
    },
    callLinksTable() {
      this.linksParam.listData = [];
      api
        .regularPost(apiUrl.links.getUnauthorizedList, this.linksParam)
        .then((response) => {
          if (response.status == 200) {
            this.linksParam = response.data;
          }
        });
    },
    clickPrintInvoice() {
      api.downloadFile(
        apiUrl.pdf.invoice,
        {
          oid: 1,
        },
        "Invoice.pdf",
        () => {
          this.showMessage("Downloaded successfully.", "success");
        }
      );
    },
    getRandomTempImage() {
      let randomIdx = Math.floor(Math.random() * this.tempImages.length);
      let imgUrl = this.tempImages[randomIdx];
      console.log(imgUrl);
      return imgUrl;
    },
  },
};
</script>

<style scoped>
.carousel-text {
  margin-left: 30rem;
  text-align: left;
  padding: 15px;
  background-color: black;
  opacity: 0.85;
}

.carousel-item {
  width: 100%;
  max-height: 60vh !important;
}

.bgimg-1 {
  background-position: center;
  background-size: cover;
  /* background-image: url("/img/welder.png"); */
  min-height: 100%;
}

.loginButton {
  padding: 12px 24px !important;
  opacity: 0.8;
  border-radius: 10rem;
  display: inline-block;
  width: 40%;
  margin: auto;
  margin-bottom: 20px;
}

.loginButton:hover {
  opacity: 1;
}

.center-1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.center-2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 59%;
}

.center-3 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}

.center-4 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
</style>