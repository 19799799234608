<template>
  <div class="input-group mb-1">
    <span class="input-group-text"><i class="bi bi-search"></i></span>
    <input type="text" class="form-control" placeholder="Search..." @keyup="typeText" />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: 'TableFilter',

  methods: {
    typeText: _.debounce(function (e) {
      this.$emit('changeText', e.target.value);
   }, 500),
  }
}
</script>
