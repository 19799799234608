<template>
<div>
  <h4>Welder Qualification Monitoring Report</h4>
  <div>
    <div class="row mb-4" v-if="canViewAll">
      <div class="col-md-6">
        <select v-model="selectedCorporatOids" multiple class="form-control mb-2">
          <option v-for="(i, idx) in contractors" :key="idx" :value="i.oid">{{i.corpName}}</option>
        </select>
        <label class="hover text-secondary"><input type="checkbox" v-model="isAllCororate"> Select All</label>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        
      </div>
      <div v-if="canViewAll" class="col-md-3 text-end">
        <button class="btn btn-primary" @click="clickSearh">Submit</button>
      </div>
    </div>
  </div>

  <div class="mb-2 mt-2" v-for="(i, idx) in reportData" :key="idx">
    <hr/>
    <div class="mb-2"><strong>{{i.contractorName}}</strong></div>
    <div class="mb-2 table-responsive">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>NRIC/FIN</th>
            <th>Work Permit No.</th>
            <th>WelderID</th>
            <th>Name</th>
            <th>Test Number</th>
            <th>Qualification Expiry Date</th>
            <th>Education Level</th>
            <th>Work Permit Expiry Date</th>
            <th>AWSHPP Expiry Date</th>
            <th>Contractor</th>
            <th>Sub Contractor</th>
            <th>Current Location</th>
            <th>Sponsor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,idxx) in i.listData" :key="idxx">
            <td>{{l.nricFin}}</td>
            <td>{{l.workPermit}}</td>
            <td>{{l.welderId}}</td>
            <td>{{l.name}}</td>
            <td>
              <div style="height:75px;" v-for="(t,i) in l.testNumbersWithExpDate" :key="i">{{t.testNumber}}</div>
            </td>
            <td>
              <div style="height:75px;" v-for="(t,i) in l.testNumbersWithExpDate" :key="i">{{t.expiryDateDisplay}}</div>
            </td>
            <td>{{l.educationLevel}}</td>
            <td>{{l.workPermitExpiryDateDisplay}}</td>
            <td>{{l.awshppExpiryDateDisplay}}</td>
            <td>{{l.contractor}}</td>
            <td>{{l.subContractor}}</td>
            <td>{{l.currentLocation}}</td>
            <td>{{l.sponsor}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="mb-2"><strong>Statistics</strong></div>
      <div style="overflow-x: auto;">
        <table class="table table-sm table-bordered">
        <tbody>
          <tr>
            <td class="text-secondary">Total number of workers processed</td>
            <td>{{i.noOfWorkerProcessed}}</td>
          </tr>

          <tr>
            <td class="text-secondary">Number of workers by each Test No.</td>
            <td></td>
          </tr>
          <tr v-for="(e, ei) in i.noOfWorkerByEachTestNo" :key="ei">
            <td class="text-secondary">{{e.testNumber}}</td>
            <td>{{e.count}}</td>
          </tr>

          <tr>
            <td class="text-secondary">Number of workers with Qualification expiring within 1 month</td>
            <td>{{i.noOfWorkerQualiExpiring1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with Qualification expiring within the 2nd month</td>
            <td>{{i.noOfWorkerQualiExpiring2Month}}</td>
          </tr>

          <tr>
            <td class="text-secondary">Number of workers by each Education Level</td>
            <td></td>
          </tr>
          <tr v-for="(e, ei) in i.noOfWorkerByEachEduLvl" :key="ei">
            <td class="text-secondary">{{e.eduLvl}}</td>
            <td>{{e.count}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with WP expiring within 1 month</td>
            <td>{{i.noOfWorkerWPExpiring1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with WP expiring within the 2nd month</td>
            <td>{{i.noOfWorkerWPExpiring2Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with WP expired within 1 month</td>
            <td>{{i.noOfWorkerWPExpired1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expiring within 1 month</td>
            <td>{{i.noOfWorkerAwshppExpiring1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expiring within the 2nd month</td>
            <td>{{i.noOfWorkerAwshppExpiring2Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expired within 1 month</td>
            <td>{{i.noOfWorkerAwshppExpired1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expired for > 1 month</td>
            <td>{{i.noOfWorkerAwshppExpiredOver1Month}}</td>
          </tr>

          <tr>
            <td class="text-secondary">Number of workers by each Subcontractor</td>
            <td></td>
          </tr>
          <tr v-for="(e, ei) in i.noOfWorkerByEachSubContractor" :key="ei">
            <td class="text-secondary">{{e.contractor}}</td>
            <td>{{e.count}}</td>
          </tr>

          <tr>
            <td class="text-secondary">Number of workers by each Location</td>
            <td></td>
          </tr>
          <tr v-for="(e, ei) in i.noOfWorkerByEachLocation" :key="ei">
            <td class="text-secondary">{{e.location}}</td>
            <td>{{e.count}}</td>
          </tr>

          <tr>
            <td class="text-secondary">Number of workers by each Sponsor</td>
            <td></td>
          </tr>
          <tr v-for="(e, ei) in i.noOfWorkerByEachSponsor" :key="ei">
            <td class="text-secondary">{{e.sponsor}}</td>
            <td>{{e.count}}</td>
          </tr>

        </tbody>
      </table>
    </div>
         </div>
      
  </div>

</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: 'WorkerQualificationReport',
  components: {
  },
  data() {
    return {
      reportData: [],
      selectedCorporatOids: [],
      isAllCororate: false,
      canViewAll:true,
      contractors: [],
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Assignment Report' }
    ]);

    api.getAllCorporates(null).then(response => {
      console.log(this.getLoggedInUser().corporateOid);
      let corporateType = this.getLoggedInUser().userGroupType;
      if(corporateType == this.$data._userGroupType.contractor.value){
         this.contractors = response.data.filter(x =>  x.oid == this.getLoggedInUser().corporateOid);
         this.canViewAll = false;
         this.selectedCorporatOids.push(this.getLoggedInUser().corporateOid);
         this.getData();
      }else{
         this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      }  
    });

  },
  methods: {
    getData() {
      this.showLoadingScreen();
      var param = {
        corporateOids: this.selectedCorporatOids,
        isAllCorporate: this.isAllCororate,
      };
      this.reportData = [];
      console.log(param);
      api.regularPost(apiUrl.report.getWorkerQualificationReport, param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.reportData = response.data;
        }
      });
    },
    clickSearh(){
      this.getData();
    }
  }
}
</script>
