<template>

<div>
  <div class="row" v-if="userGroup == $data._userGroupType.admin.value">
    <div class="col-sm-12">
      <div class="row mb-3">
        <div class="col-sm-3">
          <div @click="goToCorporate($data._corporateType.contractor.value)" class="card shadow-sm p-1 mb-1"
            style="border-radius: 1.0rem;color: white;background-color: cornflowerblue;">
            <div class="card-body">
              <h6 class="card-title">Total {{corpTypeCountList[0].typeDisplay}}</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a
                  style="text-decoration: none;color: white;" href="javascript:;"
                  @click="goToCorporate($data._corporateType.contractor.value)">{{corpTypeCountList[0].totalCount}}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: crimson;">
            <div class="card-body">
              <h6 class="card-title">Total {{corpTypeCountList[1].typeDisplay}}</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a
                  style="text-decoration: none;color: white;" href="javascript:;"
                  @click="goToCorporate($data._corporateType.testCenter.value)">{{corpTypeCountList[1].totalCount}}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: blueviolet;">
            <div class="card-body">
              <h6 class="card-title">Total {{corpTypeCountList[2].typeDisplay}}</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a
                  style="text-decoration: none;color: white;" href="javascript:;"
                  @click="goToCorporate($data._corporateType.industry.value)">{{corpTypeCountList[2].totalCount}}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: darkcyan;">
            <div class="card-body">
              <h6 class="card-title">Total {{corpTypeCountList[3].typeDisplay}}</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a
                  style="text-decoration: none;color: white;" href="javascript:;"
                  @click="goToCorporate('')">{{corpTypeCountList[3].totalCount}}</a></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: darkslateblue;">
            <div class="card-body">
              <h6 class="card-title">Pending Pre-Qualification</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToPreQ">{{pendingPreQualification}}</a></p>
            </div>
          </div>
        </div>

      </div>
      <!-- <div class="row mb-3">
        <div class="col-sm-4">
          <div class="card">
            <div class="card-header">Total Corporates</div>
            <ul class="list-group list-group-flush">
              <li v-for="(i, idx) in corpTypeCountList" :key="idx" class="list-group-item">
                {{ i.typeDisplay }} ({{ i.totalCount }})
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-header">Pending Items</div>
            <div class="card-body">
              <table class="table table-sm">
                <tbody>
                  <tr v-for="(i, idx) in notiActionList" :key="'idx5' + idx">
                    <td>{{ i.description }} ({{ i.totalCount }})</td>
                    <td>
                      <button class="btn btn-sm btn-primary" @click="clickPendingItemView(i.index)">
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="col-sm-12">
      <div class="row mb-3">
        <div class="col-sm-4">
          <div class="card mb-1" style="">
            <div class="card-header" style="background-color: darkturquoise;color:white;">Pending Items</div>
            <div class="card-body">
              <table class="table table-sm">
                <tbody>
                  <tr v-for="(i, idx) in notiActionList" :key="'idx5' + idx">
                    <td>
                      {{ i.description }} <span class="badge bg-danger rounded-circle">{{ i.totalCount }}</span>
                    </td>
                    <td>
                      <button class="btn btn-sm btn-primary" @click="clickPendingItemView(i.index)">
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header" style="background-color: darkturquoise;color:white;">System Activity Log</div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Last Activity:</div>
                <div class="col-sm-4">
                  <FormSelect :list="activityRowCountList" :existing="activityRowCount" value="value" text="text"
                    @change="changeActivityRowCount" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Activity Date</th>
                  <th>Category</th>
                  <th>Message</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in activityList" :key="'idx5' + idx">
                  <td>{{ i.activityDate }}</td>
                  <td>{{ i.category }}</td>
                  <td>{{ i.message }}</td>
                  <td>{{ i.userId }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    </div>
  <div class="row" v-if="userGroup == $data._userGroupType.sws.value">
    <div class="col-sm-12">
      <div class="row mob-3">
        <div class="col-sm-4">
          <div class="card" style="background-color: darkturquoise;color:white;">
            <div class="card-header">Total Sws Corporates</div>
            <ul class="list-group list-group-flush">
              <li v-for="(i, idx) in corpswsTypeCountList" :key="idx" class="list-group-item">
                {{ i.typeDisplay }} <span class="badge bg-danger rounded-circle">{{ i.totalCount }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: darkslateblue;">
            <div class="card-body">
              <h6 class="card-title">Pending Pre-Qualification</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToPreQ">{{pendingPreQualification}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="swsMemberExpiringList.length > 0 " class="col-sm-12" style="margin-top:5px;background-color: darkturquoise;color:white;">
      <div class="card mb-1">
        <div class="card-header">Members expiring in 6 months</div>
        <div class="card-body">
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Company</th>
                  <th>SWS ID</th>
                  <th>Terminate Date</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in swsMemberExpiringList" :key="idx">
                  <td>{{i.company}}</td>
                  <td>{{i.swsid}}</td>
                  <td>{{i.terminateDateDisplay}}</td>
                  <td>{{i.expiryDateDisplay}}</td>
                  <td>
                    <span v-if="i.statusText == $data._status.active.text"
                      class="badge bg-success">{{i.statusText}}</span>
                    <span v-else class="badge bg-danger">{{i.statusText}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="userGroup == $data._userGroupType.mom.value">
    <div class="col-sm-12">
      <div class="card mb-1" >
        <div class="card-header" style="margin-top:5px;background-color: darkturquoise;color:white;">Reject Vs Approve in last 3 months</div>
        <div class="card-body">
          <table class="table table-sm">
            <thead class="table-light">
              <tr>
                <th colspan="2">{{momApprovedRejectedList[0].monthDisplay}}</th>
                <th colspan="2">{{momApprovedRejectedList[1].monthDisplay}}</th>
                <th colspan="2">{{momApprovedRejectedList[2].monthDisplay}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Approved</td>
                <td>Rejected</td>
                <td>Approved</td>
                <td>Rejected</td>
                <td>Approved</td>
                <td>Rejected</td>
              </tr>
              <tr>
                <td>{{momApprovedRejectedList[0].approvedCount}}</td>
                <td>{{momApprovedRejectedList[0].rjectCount}}</td>
                <td>{{momApprovedRejectedList[1].approvedCount}}</td>
                <td>{{momApprovedRejectedList[1].rjectCount}}</td>
                <td>{{momApprovedRejectedList[2].approvedCount}}</td>
                <td>{{momApprovedRejectedList[2].rjectCount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header" style="margin-top:5px;background-color: darkslateblue;color:white;">Application(s) For Approval</div>
        <div class="card-body">
          <table class="table table-sm">
            <thead class="table-light">
              <tr>
                <th>Quota Application No</th>
                <th>Applicant Name</th>
                <th>MNC Name</th>
                <th>Main Contractor Name</th>
                <th>Sub Contractor Name</th>
                <th>Last Modified Date</th>
                <th>Status</th>
                <th style="width:115px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, idx) in pendingQuotaListMOM" :key="idx">
                <td>{{i.quotaApplicationNo}}</td>
                <td>{{i.nameOfCompany}}</td>
                <td>{{i.mncName}}</td>
                <td>{{i.mainContractorName}}</td>
                <td>{{i.subContractorName}}</td>
                <td>{{i.modifiedDate}}</td>
                <td>
                  {{i.statusDisplay}}
                </td>
                <td>
                  <button v-if="i.canEdit" class="btn btn-sm btn-primary" @click="clickEditMOM(i)">
                    <span>Edit</span>
                  </button>
                  <button v-else class="btn btn-sm btn-primary" @click="clickDetailsMOM(i)">
                    <span>View</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header" style="margin-top:5px;background-color: darkmagenta;color:white;">View MNC Pending Application(s)</div>
        <div class="card-body">
          <table class="table table-sm">
            <thead class="table-light">
              <tr>
                <th>Quota Application No</th>
                 <th>Applicant Name</th>
                <th>MNC Name</th>
                <th>Main Contractor Name</th>
                <th>Sub Contractor Name</th>
                <th>Last Modified Date</th>
                <th>Status</th>
                <th style="width:115px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, idx) in pendingMNCQuotaListMOM" :key="idx">
                <td>{{i.quotaApplicationNo}}</td>
                 <td>{{i.nameOfCompany}}</td>
                <td>{{i.mncName}}</td>
                <td>{{i.mainContractorName}}</td>
                <td>{{i.subContractorName}}</td>
                <td>{{i.modifiedDate}}</td>
                <td>
                  {{i.statusDisplay}}
                </td>
                <td>
                  <button v-if="i.canEdit" class="btn btn-sm btn-primary" @click="clickEditMOM(i)">
                    <span>Edit</span>
                  </button>
                  <button v-else class="btn btn-sm btn-primary" @click="clickDetailsMOM(i)">
                    <span>View</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="userGroup == $data._userGroupType.industry.value">
    <div class="col-sm-12" style="display:none;">
      <div class="card mb-1">
        <div class="card-header" style="margin-top:5px;background-color: darkslateblue;color:white;">Number of workers by Project</div>
        <div class="card-body">
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Project Code</th>
                  <th>Total Worker</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, outg) in workersByProject" :key="outg">
                  <td>{{i.projectCode}}</td>
                  <td>{{i.noOfWorkers}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header" style="margin-top:5px;background-color: cornflowerblue;color:white;">Pending Quota application approval</div>
        <div class="card-body">
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Quota Application No</th>
                  <th>MNC Name</th>
                  <th>Main Contractor Name</th>
                  <th>Sub Contractor Name</th>
                  <th>Last Modified Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, out) in pendingQuotaListMNC" :key="out">
                  <td>{{i.quotaApplicationNo}}</td>
                  <td>{{i.mncName}}</td>
                  <td>{{i.mainContractorName}}</td>
                  <td>{{i.subContractorName}}</td>
                  <td>{{i.modifiedDate}}</td>
                  <td>
                    {{i.statusDisplay}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header" style="margin-top:5px;background-color: darkturquoise;color:white;">Outstanding Invoices</div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Last Record:</div>
                <div class="col-sm-4">
                  <FormSelect :list="activityRowCountList" :existing="activityRowCount" value="value" text="text"
                    @change="changeOutstandingInvoiceLastRowCount" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>For Month</th>
                  <th>Status</th>
                  <th>Payment Date</th>
                  <th style="text-align: right;">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, ivo) in outstandInvoiceIndustry" :key="ivo">
                  <td>{{i.invoiceNumber}}</td>
                  <td>{{i.invoiceDate}}</td>
                  <td>{{i.forMonthDisplay}}</td>
                  <td>
                    <span v-if="i.status == $data._invoiceStatus.new.value" class="badge bg-warning">{{i.statusDisplay}}
                    </span>
                    <span v-else-if="i.status == $data._invoiceStatus.confirmed.value"
                      class="badge bg-info text-dark">{{i.statusDisplay}}
                    </span>
                    <span v-else-if="i.status == $data._invoiceStatus.chequeProcessing.value "
                      class="badge bg-info text-white">{{i.statusDisplay}}
                    </span>
                    <span v-else-if="i.status == $data._invoiceStatus.paid.value"
                      class="badge bg-success text-white">{{i.statusDisplay}}
                    </span>
                    <span v-else class="badge bg-secondary">{{i.statusDisplay}}</span>
                  </td>
                  <td>{{i.paymentDate}}</td>
                  <td style="text-align: right;">{{numberWithCommas(Number(i.totalAmount).toFixed(2))}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="userGroup == $data._userGroupType.contractor.value">
    <div class="col-sm-12">
      <div class="row mb-3">
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: cornflowerblue;">
            <div class="card-body">
              <h6 class="card-title">Outstanding Form A</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToFormA">{{pendingQuotaListContractor.length}}</a></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: crimson;">
            <div class="card-body">
              <h6 class="card-title">Worker Permit Expiring in 30 days</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToWorker('WO')">{{woExpiringList.length}}</a></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: blueviolet;">
            <div class="card-body">
              <h6 class="card-title">AWSHPP Expiring in 30 days</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToWorker('AWSHPP')">{{awshppExpiringList.length}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header">Outstanding Form A</div>
        <div class="card-body">
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Quota Application No</th>
                  <th>MNC Name</th>
                  <th>Main Contractor Name</th>
                  <th>Sub Contractor Name</th>
                  <th>Last Modified Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in pendingQuotaListContractor" :key="idx">
                  <td>{{i.quotaApplicationNo}}</td>
                  <td>{{i.mncName}}</td>
                  <td>{{i.mainContractorName}}</td>
                  <td>{{i.subContractorName}}</td>
                  <td>{{i.modifiedDate}}</td>
                  <td>
                    {{i.status}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="col-sm-6">
      <div class="card mb-1">
        <div class="card-header">Worker Permit Expiring in 30 days</div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Last Record:</div>
                <div class="col-sm-4">
                  <FormSelect :list="activityRowCountList" :existing="activityRowCount" value="value" text="text"
                    @change="changeWOLastRowCount" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Name</th>
                  <th>Nric/Fin</th>
                  <th>Work Permit</th>
                  <th>Work Permit Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in woExpiringList" :key="idx">
                  <td>{{i.name}}</td>
                  <td>{{i.nricFin}}</td>
                  <td>{{i.workPermit}}</td>
                  <td>{{i.workPermitExpiryDateDisplay}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card mb-1">
        <div class="card-header">AWSHPP Expiring in 30 days</div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Last Record:</div>
                <div class="col-sm-4">
                  <FormSelect :list="activityRowCountList" :existing="activityRowCount" value="value" text="text"
                    @change="changeAwshppLastRowCount" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Name</th>
                  <th>Nric/Fin</th>
                  <th>Work Permit</th>
                  <th>Work Permit Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in awshppExpiringList" :key="idx">
                  <td>{{i.name}}</td>
                  <td>{{i.nricFin}}</td>
                  <td>{{i.awshppCertNo}}</td>
                  <td>{{i.awshppExpiryDateDisplay}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-sm-12" style="display:none;">
      <div class="card mb-1">
        <div class="card-header" style="color:white;background-color: darkturquoise;">Quota Status</div>
        <div class="card-body">
          <div class="row">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>Quota Application No</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in quotaStatusList" :key="idx">
                  <td>{{i.quotaApplicationNo}}</td>
                  <td> {{i.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card mb-1">
        <div class="card-header">Welders with qualification expiring in 30days</div>
        <div class="card-body">
          <div class="row">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>NRIC/FIN</th>
                  <th>Work Permit</th>
                  <th>Welder Id</th>
                  <th>Name</th>
                  <th>Corporate</th>
                  <th>Test No.</th>
                  <th>Test Date</th>
                  <th>Last Perf Date</th>
                  <th>Expiry Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, i) in welderExpiringList" :key="i">
                  <td>{{l.nricFin}}</td>
                  <td>{{l.workPermit}}</td>
                  <td>{{l.welderId}}</td>
                  <td>{{l.name}}</td>
                  <td>{{l.corporate}}</td>
                  <td>{{l.testNumber}}</td>
                  <td>{{l.testDateDisplay}}</td>
                  <td>{{l.lastPerfDateDisplay}}</td>
                  <td>{{l.expiryDate}}</td>
                  <td><button class="btn btn-sm btn-primary" @click="clickUpdateWorker(l)">Update</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="userGroup == $data._userGroupType.testCenter.value">
    <div class="col-sm-12">
      <div class="row mb-3">
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: cornflowerblue;">
            <div class="card-body">
              <h6 class="card-title">Pending Test Application Result</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToTestResultUpdate()">{{testApplicationResult}}</a></p>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: crimson;">
            <div class="card-body">
              <h6 class="card-title">Pending for Test Confirmation</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToTestApplicationConfirm()">{{testApplicationConfrim}}</a></p>
            </div>
          </div>
        </div>        
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: darkslateblue;">
            <div class="card-body">
              <h6 class="card-title">Pending Pre-Qualification</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToPreQ">{{pendingPreQualification}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="userGroup == $data._userGroupType.weldingInsperctor.value && this.getLoggedInUser().wiType != 'I'">
    <div class="col-sm-12">
      <div class="row mb-3">
        <div class="col-sm-3">
          <div class="card shadow-sm p-1 mb-1" style="border-radius: 1.0rem;color: white;background-color: crimson;">
            <div class="card-body">
              <h6 class="card-title">Pending Test Application</h6>
              <p class="card-text h1" style="font-size: 4.5rem;text-align: right;"><a style="text-decoration: none;color: white;" href="javascript:;" @click="goToCorporate($data._corporateType.industry.value)">{{testApplicationResultWI}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
</template>

<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from "@/components/custom/forms";
export default {
  name: "Dashboard",
  components: {
    ...Forms,
  },
  data() {
    return {
      pendingPreQualification:0,
      testApplicationResult:0,
      testApplicationConfrim:0,
      testApplicationResultWI:0,
      //For PortalAdmins
      corpswsTypeCountList:[],
      corpTypeCountList: [
        {
          typeDisplay:'',
          totalCount:0
        },
        {
          typeDisplay:'',
          totalCount:0
        },
        {
          typeDisplay:'',
          totalCount:0
        },
        {
          typeDisplay:'',
          totalCount:0
        }
      ],
      activityList: [],
      activityRowCountList: [],
      activityRowCount: 10,
      notiActionList: [],
      userGroup: "",
      swsMemberExpiringList:[],
      momApprovedRejectedList:[
        {monthDisplay:''},
        {monthDisplay:''},
        {monthDisplay:''}
      ],
      pendingQuotaListMOM:[],
      pendingMNCQuotaListMOM:[],
      woExpiringList:[],
      awshppExpiringList:[],
      pendingQuotaListContractor:[],
      quotaStatusList:[],
      welderExpiringList:[],
      pendingQuotaListMNC:[],
      outstandInvoiceIndustry:[],
      workersByProject:[]
    };
  },
  mounted() {
    
    this.activityRowCountList = [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
      ];
    this.userGroup = this.getLoggedInUser().userGroupType;
    //Admin
    if (this.userGroup == this.$data._userGroupType.admin.value) {
      this.getCorpTypeCount();

      this.getPreQ();
      this.getSystemActivity();
      this.getNotiActions();
    }

    //SWS
     if (this.userGroup == this.$data._userGroupType.sws.value) {
      this.getSwsExpiringList();
      this.getSwsCorpTypeCount();
      this.getPreQ();
    }

    //MOM
    if (this.userGroup == this.$data._userGroupType.mom.value) {
      this.getQuotaApprovedVsRejectedForMOM();
      this.getPendingQuotaForMOM();
      this.getPendingMNCQuotaForMOM();
    }

    //Contractor
    if (this.userGroup == this.$data._userGroupType.contractor.value) {
      this.getWelderTestListForExpiringDashboard();
      this.getPendingQuotaForContractor();
      this.getAwshppExpiring();
      this.getWOExpiring();
      this.getQuotaStatus();
    }

    //Industry
    if(this.userGroup == this.$data._userGroupType.industry.value){
      this.getPendingQuotaForMNC();
      this.getOutstandingInvoiceIndustry();
      this.getNumberofWorkersbyProject();
    }

    //Test Center
    if(this.userGroup == this.$data._userGroupType.testCenter.value){
      this.getTestApplicationResult();
      this.getTestApplicationConfrim();
      this.getPreQ();
    }

    //Welding Inspector
    if(this.userGroup == this.$data._userGroupType.weldingInsperctor.value){
      this.getTestApplicationResultWI();
      this.getPreQ();
    }
  },
  methods: {
    goToCorporate(e){
      let parentUrl = '';
      switch (e) {
      case this.$data._corporateType.industry.value: parentUrl = 'Industries'; break; 
      case this.$data._corporateType.contractor.value: parentUrl = 'Contractors'; break; 
      case this.$data._corporateType.testCenter.value: parentUrl = 'TestCentreCWQS'; break;
      default: parentUrl = 'WeldingInspectors';break;
    }
    this.$router.push({ name: parentUrl });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDateTime(date) {
      return api.getFormattedDateTime(date);
    },
    getCorpTypeCount() {
      api.regularGet(apiUrl.dashboard.getCorpTypeCount).then((response) => {
        if (response.status == 200) {
          this.corpTypeCountList = response.data;
        }
      });
    },
    getSwsCorpTypeCount() {
      api.regularGet(apiUrl.dashboard.getSwsCorpTypeCount).then((response) => {
        if (response.status == 200) {
          this.corpswsTypeCountList = response.data;
        }
      });
    },
    getSystemActivity() {
      api
        .regularGet(apiUrl.dashboard.activityLog + "/" + this.activityRowCount)
        .then((response) => {
          if (response.status == 200) {
            this.activityList = response.data;
          }
        });
    },
    changeActivityRowCount(e) {
      this.activityRowCount = e;
      this.getSystemActivity();
    },
    getNotiActions() {
      api.regularGet(apiUrl.dashboard.getNotiAction).then((response) => {
        if (response.status == 200) {
          this.notiActionList = response.data;
        }
      });
    },
    clickPendingItemView(index) {
      if (index == 1) {
        this.$router.push({ name: "Invoices", params:{filter:'new'} });
      } else if (index == 2) {
        this.$router.push({ name: "Activate Industries" });
      } else if (index == 3) {
        this.$router.push({ name: "Activate Contractors" });
      } else if (index == 4) {
        this.$router.push({ name: "Activate Test Centers" });
      } else if (index == 5) {
        this.$router.push({ name: "Activate Welding Inspectors" });
      } else {
        console.log(index);
      }
    },
    getSwsExpiringList() {
      api.regularGet(apiUrl.dashboard.swsMemberExpiringInSixMonthDashboard).then((response) => {
        if (response.status == 200) {
          this.swsMemberExpiringList = response.data;
        }
      });
    },
    getQuotaApprovedVsRejectedForMOM() {
      api.regularGet(apiUrl.dashboard.quotaApprovedVsRejectedForMOM).then((response) => {
        if (response.status == 200) {
          this.momApprovedRejectedList = response.data;
        }
      });
    },
    getPendingQuotaForMOM() {
      api.regularGet(apiUrl.dashboard.pendingQuotaForMOM).then((response) => {
        if (response.status == 200) {
          this.pendingQuotaListMOM = response.data;
        }
      });
    },
    getPendingMNCQuotaForMOM() {
      api.regularGet(apiUrl.dashboard.pendingMNCQuotaForMOM).then((response) => {
        if (response.status == 200) {
          this.pendingMNCQuotaListMOM = response.data;
        }
      });
    },
    clickDetailsMOM(data) {
      this.$router.push({ name: 'ViewQuota', params: { id: data.oid } });
    },
    clickEditMOM(data) {
      this.$router.push({ name: 'FormA', params: { id: data.oid } });
    },
    goToPreQ(){
      this.$router.push({ name: 'PreQualificationConfirm'});
    },
    getPendingQuotaForContractor() {
      api.regularGet(apiUrl.dashboard.pendingQuotaForContractorDashboard).then((response) => {
        if (response.status == 200) {
          this.pendingQuotaListContractor = response.data;
        }
      });
    },
    getQuotaStatus() {
      api.regularGet(apiUrl.dashboard.quotaStatus).then((response) => {
        if (response.status == 200) {
          this.quotaStatusList = response.data;
        }
      });
    },
    getWOExpiring() {
      api
        .regularGet(apiUrl.dashboard.workerExpiringWO + "/" + this.activityRowCount)
        .then((response) => {
          if (response.status == 200) {
            this.woExpiringList = response.data;
          }
        });
    },
    getAwshppExpiring() {
      api
        .regularGet(apiUrl.dashboard.workerExpiringAWSHPP + "/" + this.activityRowCount)
        .then((response) => {
          if (response.status == 200) {
            this.awshppExpiringList = response.data;
          }
        });
    },
    changeWOLastRowCount(e) {
      this.activityRowCount = e;
      this.getWOExpiring();
    },
    changeAwshppLastRowCount(e) {
      this.activityRowCount = e;
      this.getAwshppExpiring();
    },
    changeOutstandingInvoiceLastRowCount(e){
      this.activityRowCount = e;
      this.getOutstandingInvoiceIndustry();
    },
    getWelderTestListForExpiringDashboard() {
      api
        .regularGet(apiUrl.dashboard.getWelderTestListForExpiringDashboard)
        .then((response) => {
          if (response.status == 200) {
            this.welderExpiringList = response.data;
          }
        });
    },
    getPendingQuotaForMNC() {
      api.regularGet(apiUrl.dashboard.pendingQuotaForMNCDashboard).then((response) => {
        if (response.status == 200) {
          this.pendingQuotaListMNC = response.data;
        }
      });
    },
    getOutstandingInvoiceIndustry(){
      api.regularGet(apiUrl.dashboard.outstandingInvoiceIndustry + "/" + this.activityRowCount).then((response) => {
        if (response.status == 200) {
          this.outstandInvoiceIndustry = response.data;
        }
      });
    },
    goToFormA(){
      this.$router.push({ name: "FromContractors" });
    },
    goToWorker(e){
      this.$router.push({ name: "Workers", params:{filter:e} });
    },
    getTestApplicationResult() {
      api.regularGet(apiUrl.dashboard.testApplicationResult).then((response) => {
        if (response.status == 200) {
          this.testApplicationResult = response.data;
        }
      });
    },
    getTestApplicationConfrim() {
      api.regularGet(apiUrl.dashboard.testApplicationConfrim).then((response) => {
        if (response.status == 200) {
          this.testApplicationConfrim = response.data;
        }
      });
    },
    getTestApplicationResultWI() {
      api.regularGet(apiUrl.dashboard.testApplicationResultWI).then((response) => {
        if (response.status == 200) {
          this.testApplicationResultWI = response.data;
        }
      });
    },
    getPreQ() {
      api.regularGet(apiUrl.workerSkill.getWorkerSkillTestsToConfirm).then((response) => {
        if (response.status == 200) {
          this.pendingPreQualification = response.data.length;
        }
      });
    },
    getNumberofWorkersbyProject() {
      api.regularGet(apiUrl.dashboard.GetNoOfWorkerByProjects).then((response) => {
        if (response.status == 200) {
          this.workersByProject = response.data;
        }
      });
    },
    goToTestResultUpdate(){
      this.$router.push({ name: "TestApplicationUpdateList" });
    },
    goToTestApplicationConfirm(){
      this.$router.push({ name: "TestApplicationConfirmList",params: { status: this.$data._testApplicationStatus.pending.value } });
    },
    clickUpdateWorker(data){
      this.$router.push({ name: 'AddEditWorker', params: { id: data.workerOid, corpName: data.corporate, nricFin: data.nricFin, workPermitNo: data.workPermit } });
    },
  },
};
</script>
