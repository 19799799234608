<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-light" style="height:50px;">
      <div class="container-fluid">
        <div>
          <a href="javascript:;" class="me-2 h5" @click="toggleSideBar"><i class="bi bi-list"></i></a>

          <span class="small" v-for="(b,idx) in $store.state.breadcrumb" :key="idx">
            <span v-if="b.url"><router-link :to="b.url" class="text-decoration-none">{{b.name}}</router-link></span>
            <span v-else>{{b.name}}</span>
            <span v-if="!isBreadcrumbLastIndex(idx)"> / </span>
          </span>
        </div>
        <div style="position:absolute;right:10px;">
          <div class="btn-group">
            <div>
              <a href="javascript:;" class="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                <div style="position:relative;">
                  <div><i class="bi bi-bell h5" style="color:black;"></i></div>
                  <div style="position:absolute; top: -6px;right:-7px;font-size:10px;color: white;background: red;padding: 0px 5px;border-radius: 8px">
                    <span>{{$store.state.unreadNotiCount}}</span>
                  </div>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end" style="padding:10px;min-width:300px;">
                <div>
                  <div>
                    <router-link class="float-start" to="/usernotification" style="text-decoration:none;">Notifications</router-link>
                    <router-link class="float-end" to="/usernotificationsetting" style="text-decoration:none;">Settings</router-link>
                  </div>
                </div>
                <router-link to="/usernotification" type="button" style="text-decoration:none;width:100%;" class="text-secondary">
                  <div v-for="(l,i) in $store.state.unreadNotiList" :key="i">
                    <hr/>
                    <div style="white-space: nowrap;max-width: 300px;overflow: hidden;text-overflow: ellipsis;">
                      {{l.title}}
                    </div>
                  </div>
                </router-link>
                <div v-show="!$store.state.unreadNotiCount" class="text-center text-secondary">
                  No notifications.
                </div>
              </div>
            </div>
            </div>
            <div class="btn-group">

            <div>
              <a href="javascript:;" class="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                <span><i class="bi bi-person-circle h5" style="color:black;"></i></span> 
                <span v-if="$store.state.loginUser" class="ms-1">{{$store.state.loginUser.fullName}}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li><a href="/profile" class="dropdown-item" type="button"><i class="bi bi-person"></i> Profile</a></li>
                <li><a href="/password" class="dropdown-item" type="button"><i class="bi bi-lock"></i> Change Password</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a href="javascript:;" class="dropdown-item" type="button" @click="clickLogout"><i class="bi bi-box-arrow-right"></i> Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import api from "@/common/api";
import apiUrl from '@/common/apiurl';

export default {
  name: 'HeaderBar',
  props: {
    toggleSideBar: { type: Function }
  },
  data() {
    return {

    }
  },
  mounted() {
    api.regularGet(apiUrl.notification.getUnreadNotifications, null).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].userMessage = res.data[i].userMessage.replace('<br/>', ' ').replace('<br/>', ' ').replace('<br/>', ' ');
      }
      this.$store.state.unreadNotiList = res.data;
      let count = res.data.length;
      if (count == 0) {
        this.$store.state.unreadNotiCount = "";
      }
      else {
        this.$store.state.unreadNotiCount = count;
      }
    });
  },
  methods: {
    clickLogout() {
      api.deleteTokenFromCookie();
      localStorage.removeItem('currentUser');
      api.logOut(
        {
          oId: this.$store.state.loginUser.oId,
        },
        this.$store.state.token
      )
        .then((response) => {
          console.log(response);
          //this.$router.push({name: 'Login'});
          location.href = "/";
        });
    },
    isBreadcrumbLastIndex(idx) {
      return idx == this.$store.state.breadcrumb.length - 1;
    }
  }
}
</script>
