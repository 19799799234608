import { render, staticRenderFns } from "./IndustryWorkerList.vue?vue&type=template&id=165cf880&"
import script from "./IndustryWorkerList.vue?vue&type=script&lang=js&"
export * from "./IndustryWorkerList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports