<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Credit Note Date
              </div>
              <div class="col-sm-8">
                <DateRangePicker @updatedaterange="dateRangeChange"></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb-1">
      <div class="card-body">
      <div style="overflow-x: auto;">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th>Credit Note Number</th>
              <th>Invoice Number</th>
              <th>Credit Note Date</th>
              <th>Corporate Name</th>
              <th>Entity Type</th>
              <th style="text-align: right;">Credit Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in param.listData" :key="idx">
              <td>{{i.creditNoteNumber}}</td>
              <td>{{i.invoiceNo}}</td>
              <td>{{i.creditNoteDate}}</td>
              <td>{{i.corporateName}}</td>
              <td>{{i.corporateDisplay}}</td>
              <td style="text-align: right;">{{numberWithCommas(Number(i.totalAmount).toFixed(2))}}</td>
            </tr>
          </tbody>
        </table>
     </div>
        
      </div>
    </div>

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'CreditNotes',
  components: {
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      param:{
          listData:[]
      },
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Credit Notes' }
    ]);
    this.callTable();
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    callTable() {
      this.showLoadingScreen();
      this.param.listData = [];
      api.regularPost(apiUrl.creditNote.report, this.param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.param.listData = response.data;
        }
      });
    },
    dateRangeChange(dateRange) {
      this.param.creditNote_Start = this.convertDateObjToDateStr(dateRange.start);
      this.param.creditNote_End = this.convertDateObjToDateStr(dateRange.end);
      this.callTable();
    },
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.creditNote.download,this.param,"creditNote_detail.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    }
  }
}
</script>
