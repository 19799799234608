<template>
  <div>
    <Corporates :type="$data._corporateType.contractor.value" />
  </div>
</template>

<script>
import Corporates from './Corporates' 
export default {
  components: {
    Corporates
  },
  name: 'Contractors'
}
</script>