<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <SortColumn @click="sortColumn" sortBy="creditNoteNumber" colGroup="tbl1">Credit Note Number</SortColumn>
              <SortColumn @click="sortColumn" sortBy="invoiceNo" colGroup="tbl1">Invoice Number</SortColumn>
              <SortColumn @click="sortColumn" sortBy="creditNoteDate" colGroup="tbl1">Credit Note Date</SortColumn>
              <th>Corporate Name</th>
              <th>Entity Type</th>
              <th style="text-align: right;">Credit Total Amount</th>
              <th style="width:115px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in tableParam.listData" :key="idx">
              <td>{{i.creditNoteNumber}}</td>
              <td>{{i.invoiceNo}}</td>
              <td>{{i.creditNoteDate}}</td>
              <td>{{i.corporateName}}</td>
              <td>{{i.corporateDisplay}}</td>
              <td style="text-align: right;">{{numberWithCommas(Number(i.totalAmount).toFixed(2))}}</td>
              <td>
                <button class="btn btn-sm btn-primary" @click="clickDetails(i)">
                  <span v-if="isAuthorizedPermission($data._permissionCodes.viewInvoice)">View</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination :pagingParam="tableParam" @updatePaging="updatePaging" />

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'CreditNotes',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Credit Notes' }
    ]);
    this.callTable();
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.creditNote.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDetails(data) {
      this.$router.push({ name: 'ViewCreditNote', params: { id: data.oid } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    }
  }
}
</script>
