<template>
<table width="95%" border="0" cellspacing="0" cellpadding="0" align="center">
        <tbody>
            <tr>
                <td colspan="2">
                    <font face="Arial, Helvetica, sans-serif" size="2"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;APPLICATION
                            FORM FOR INTERBANK GIRO</b></font>
                </td>
            </tr>
            <tr>
                <td colspan="2" height="12">&nbsp;</td>
            </tr>
            <tr valign="top">
                <td colspan="2" height="685">
                    <table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr bordercolor="#000000">
                                <td style="border: 1px solid;" colspan="43" height="2">
                                    <font face="Arial, Helvetica, sans-serif" size="2"><b>&nbsp;&nbsp;&nbsp;&nbsp;PART
                                            1: FOR APPLICANT'S COMPLETION</b></font>
                                </td>
                            </tr>
                            <tr bordercolor="#000000">
                                <td colspan="43" height="155">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                        bordercolor="#000000">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;Date:</font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;Name
                                                                        of Billing Organisation ("BO"):</font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;{{dataObj.createdDate_String}}
                                                                    </font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;Primos Singapore Pte Ltd</font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;To: Name of Financial Institution:
                                                                    </font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;Billing Organisation's Customer's
                                                                        Name:</font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;<u><i>{{dataObj.giroTemp.bankName}}</i></u>
                                                                    </font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        &nbsp;&nbsp;<u><i>{{dataObj.giroTemp.name}}</i></u>
                                                                    </font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="2">
                                                                    <p>
                                                                        <font face="Arial, Helvetica, sans-serif"
                                                                            size="2">&nbsp;&nbsp;Branch:</font>
                                                                    </p>
                                                                </td>
                                                                <td height="2">
                                                                    <p>
                                                                        <font face="Arial, Helvetica, sans-serif"
                                                                            size="2">&nbsp;&nbsp;Billing Organisation's Customer's Reference Number:
                                                                        </font>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td height="2">&nbsp;&nbsp;<u><i>{{dataObj.giroTemp.branchName}}</i></u></td>
                                                                <td height="2">
                                                                    &nbsp;&nbsp;______________________________________
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="2">&nbsp;</td>
                                                                <td height="2">&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr bordercolor="#000000">
                                <td colspan="43" height="16">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                        bordercolor="#000000">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                            <tr valign="top">
                                                                <td width="5%" height="2">&nbsp;</td>
                                                                <td width="3%" height="2">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        (a)</font>
                                                                </td>
                                                                <td width="92%" height="2">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        I/We
                                                                        hereby instruct you to process the BO's
                                                                        instructions to
                                                                        debit my/our account. </font>
                                                                </td>
                                                            </tr>
                                                            <tr valign="top">
                                                                <td width="5%" height="8">&nbsp;</td>
                                                                <td width="3%" height="8">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        (b)</font>
                                                                </td>
                                                                <td width="92%" height="8">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        You
                                                                        are entitled to reject the BO's ebit instruction
                                                                        if my/our
                                                                        account does not have sufficient funds and
                                                                        charge me/us
                                                                        a fee for this. You may also at your discretion
                                                                        allow
                                                                        the debit even if this results in an overdraft
                                                                        on the
                                                                        account and impose charges accordingly.</font>
                                                                </td>
                                                            </tr>
                                                            <tr valign="top">
                                                                <td width="5%" height="8">&nbsp;</td>
                                                                <td width="3%" height="8">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        (c)</font>
                                                                </td>
                                                                <td width="92%" height="8">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        This
                                                                        authorisation wil remain in force until
                                                                        terminated by
                                                                        your written notice sent to my/our address last
                                                                        known
                                                                        to you or upon receipt of my/our written
                                                                        revocation through
                                                                        the BO.</font>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr bordercolor="#000000">
                                <td height="75" colspan="43">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td width="46%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;&nbsp;
                                                        Name:</font>
                                                </td>
                                                <td width="54%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;&nbsp;
                                                        Contact (Tel/Fax) Number:</font>
                                                </td>
                                            </tr>
                                           
                                            <tr>
                                                <td width="46%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                        &nbsp;&nbsp;<u><i>{{dataObj.giroTemp.name}}</i></u>
                                                    </font>
                                                </td>
                                                <td width="54%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                        &nbsp;&nbsp;<u><i>{{dataObj.giroTemp.telephone}}</i></u>
                                                    </font>
                                                </td>
                                            </tr>
                                           
                                            <tr>
                                                <td width="46%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;&nbsp;
                                                        Account Number:</font>
                                                </td>
                                                <td width="54%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;&nbsp;
                                                        Company Stamp/Signature/Thumbprint*:</font>
                                                </td>
                                            </tr>
                                           
                                            <tr>
                                                <td width="46%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                        &nbsp;&nbsp;<u><i>{{dataObj.giroTemp.bankAccountNum}}</i></u>
                                                    </font>
                                                </td>
                                                <td width="54%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                        &nbsp;&nbsp;___________________________________________</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="46%">&nbsp;</td>
                                                <td width="54%" align="center">
                                                    <font size="1">(As in Financial Institution's record)</font>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr bordercolor="#000000" align="center">
                                <td style="border: 1px solid;" colspan="43" height="2">
                                    <font face="Arial, Helvetica, sans-serif" size="2"><b>PART 2: FOR BILLING
                                            ORGANISATION'S COMPLETION</b></font>
                                </td>
                            </tr>
                            <tr bordercolor="#000000">
                                <td style="border: 1px solid;" colspan="4" height="15">
                                    <font face="Arial, Helvetica, sans-serif" size="1">&nbsp;&nbsp;Bank</font>
                                </td>
                                <td style="border: 1px solid;" colspan="3" height="15">
                                    <font face="Arial, Helvetica, sans-serif" size="1">&nbsp;&nbsp;Branch</font>
                                </td>
                                <td style="border: 1px solid;" colspan="11" height="15">
                                    <font face="Arial, Helvetica, sans-serif" size="1">&nbsp;&nbsp;Billing
                                        Organisation's Account No.</font>
                                </td>
                                <td style="border: 1px solid;" colspan="25" height="15">
                                    <font face="Arial, Helvetica, sans-serif" size="1">&nbsp;&nbsp;Billing
                                        Organisation's Customer Reference</font>
                                </td>
                            </tr>
                            <tr bordercolor="#000000" height="30">
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                                <td style="border: 1px solid;" width="6%" height="30">
                                    <font face="Arial, Helvetica, sans-serif" size="2">&nbsp;</font>
                                </td>
                            </tr>
                            <tr bordercolor="#000000">
                                <td style="border: 1px solid;" colspan="4" height="4" valign="bottom">
                                    <font face="Arial, Helvetica, sans-serif" size="1">&nbsp;&nbsp;Bank</font>
                                </td>
                                <td style="border: 1px solid;" colspan="3" height="4" valign="bottom">
                                    <font face="Arial, Helvetica, sans-serif" size="1">&nbsp;&nbsp;Branch
                                    </font>
                                </td>
                                <td style="border: 1px solid;" colspan="11" height="4" valign="bottom">
                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                        &nbsp;&nbsp;Account No. to be debited.</font>
                                </td>
                                <td colspan="25" rowspan="2">&nbsp;</td>
                            </tr>
                            <tr bordercolor="#000000">
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                                <td style="border: 1px solid;" width="6%" height="30">&nbsp;</td>
                            </tr>
                            <tr bordercolor="#000000" align="center">
                                <td style="border: 1px solid;" colspan="43" height="2">
                                    <font face="Arial, Helvetica, sans-serif" size="2"><b>PART
                                            3: FOR FINANCIAL INSTITUTION'S COMPLETION</b></font>
                                </td>
                            </tr>
                            <tr bordercolor="#000000" align="center">
                                <td colspan="43" height="152">
                                    <table width="95%" border="0" cellspacing="0" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td colspan="4">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">To:</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">This
                                                        Application is hereby REJECTED (please tick) for the following
                                                        reason(s):</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <font size="2">&nbsp;</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="0%">
                                                    <font size="2"></font>
                                                </td>
                                                <td width="61%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                        Signature/Thumbprint#
                                                        differs from Financial Institution's records </font>
                                                </td>
                                                <td width="2%">
                                                    <font size="2"></font>
                                                </td>
                                                <td width="37%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">Wrong
                                                        Account number</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="0%">
                                                    <font size="2"></font>
                                                </td>
                                                <td width="61%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                        Signature/Thumbprint#
                                                        incomplete/unclear#</font>
                                                </td>
                                                <td width="2%">
                                                    <font size="2"></font>
                                                </td>
                                                <td width="37%">
                                                    <font face="Arial, Helvetica, sans-serif" size="1">Ammendments
                                                        not countersigned by customer</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="0%">
                                                    <font size="2"></font>
                                                </td>
                                                <td width="61%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">Account
                                                        operated by signature/thumbprint#</font>
                                                </td>
                                                <td width="2%">
                                                    <font size="2"></font>
                                                </td>
                                                <td width="37%">
                                                    <font face="Arial, Helvetica, sans-serif" size="2">Others:
                                                        ________________</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <font size="2">&nbsp;</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <font size="2">&nbsp;</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                            <tr align="center">
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        ___________________________</font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        _____________________</font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        _____________________</font>
                                                                </td>
                                                            </tr>
                                                            <tr align="center">
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        Name of Approving Officer</font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        Authorised Signature </font>
                                                                </td>
                                                                <td>
                                                                    <font face="Arial, Helvetica, sans-serif" size="2">
                                                                        Date</font>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="2">&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="59%">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        &nbsp;&nbsp;*For
                                                                        thumbprints, please go to the branch with your
                                                                        identification</font>
                                                                </td>
                                                                <td width="41%">
                                                                    <font face="Arial, Helvetica, sans-serif" size="1">
                                                                        #Please
                                                                        delete where inapplicable</font>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: "GIROForm",
  components: {
  },
  data() {
    return {
      oid:0,
      dataObj: {
          giroTemp:{}
      }
    };
  },
  beforeMount() {
    let passedId = this.$route.query.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.getData();
    }
  },
  mounted() {
  },
  methods: {
    getData() {
      let url = apiUrl.regIndustry.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        setTimeout(function (){
            window.print();
          }, 1500);
      });
    },
  },
};
</script>