<template>
<div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Activity Date
              </div>
              <div class="col-sm-8">
                <DateRangePicker @updatedaterange="dateRangeChangeActivity"></DateRangePicker>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="activityDate" colGroup="tbl1">Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="code" colGroup="tbl1">Code</SortColumn>
          <SortColumn @click="sortColumn" sortBy="category" colGroup="tbl1">Category</SortColumn>
          <SortColumn @click="sortColumn" sortBy="message" colGroup="tbl1">Message</SortColumn>
          <SortColumn @click="sortColumn" sortBy="user" colGroup="tbl1">User</SortColumn>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{getDateTime(i.activityDate)}}</td>
          <td>{{i.code}}</td>
          <td>{{i.category}}</td>
          <td>{{i.message}}</td>
          <td>{{i.userId}}</td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
        </div>
      </div>
    </div> 
    
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";

export default {
  name: 'ActivityLog',
  components: {
    ...TableAddons,
    ...Forms,
    ...Pickers
  },
  props:{
    type: String
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount(){
    
  },
  mounted() {
    this.setBreadcrumb([
      { name: "Activity Log" }
    ]);
    this.tableParam.status = "";
    this.callTable();
  },
  methods: { 
    dateRangeChangeActivity (dateRange) {
      this.tableParam.activity_Start = this.convertDateObjToDateStr(dateRange.start);
      this.tableParam.activity_End = this.convertDateObjToDateStr(dateRange.end);
      this.callTable();
    },
    getDateTime(date) {
      console.log(date)
      return api.getFormattedExactDateTime(date);
    },
    download() {
      this.showLoadingScreen();
      console.log(this.tableParam);
      api.downloadFile(apiUrl.report.systemActivityLogDownload, this.tableParam, "activity_log.xlsx",() =>{
         this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.report.systemActivityLog, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    }

  }
}
</script>
