<template>
<div>
  <h4>Worker Assignment Report</h4>
  <div>
    <div v-if='canViewAll' class="row mb-4">
      <div class="col-md-6">
        <select v-model="selectedCorporatOids" multiple class="form-control mb-2">
          <option v-for="(i, idx) in corporates" :key="idx" :value="i.oid">{{i.corpName}}</option>
        </select>
        <label class="hover text-secondary"><input type="checkbox" v-model="isAllCororate"> Select All</label>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Project Code
      </div>
      <div class="col-md-3">
        <FormSelect :list="projectCodes" value="oid" text="projectCode" :existing="projectCodeOid" @change="projectCodeOid=$event" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Location
      </div>
      <div class="col-md-3">
        <FormSelect :list="siteLocations" value="oid" text="name" :existing="locatonOid" @change="locatonOid=$event" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Industry
      </div>
      <div class="col-md-3">
        <FormSelect :list="industries" value="oid" text="corpName" :existing="industryOid" @change="industryOid=$event" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        
      </div>
      <div class="col-md-3 text-end">
        <button class="btn btn-primary" @click="clickSearh">Submit</button>
      </div>
    </div>
  </div>

  <div class="mb-2 mt-2" v-for="(i, idx) in reportData" :key="idx">
    <hr/>
    <div class="mb-2"><strong>{{i.contractorName}}</strong></div>
    <div class="mb-2 table-responsive">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>NRIC/FIN</th>
            <th>Work Permit No.</th>
            <th>WelderID</th>
            <th>Name</th>
            <th>From Contractor</th>
            <th>Current Location and Industry / To Contractor</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Shift</th>
            <th>Status</th>
            <th>Employer</th>
            <th>Qualification Expiry Date</th>
            <th>Work Permit Expiry Date</th>
            <th>SOC Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,idx) in i.listData" :key="idx">
            <td>{{l.nricFin}}</td>
            <td>{{l.workPermit}}</td>
            <td>{{l.welderId}}</td>
            <td>{{l.name}}</td>
            <td>{{l.fromContractor}}</td>
            <td>
              <span v-if="l.location">{{l.location}}</span> 
              <span v-if="l.industry">{{l.industry}}</span> 
              <span v-if="l.toContractor">{{l.toContractor}}</span>
            </td>
            <td>{{l.startDate}}</td>
            <td>{{l.endDate}}</td>
            <td>
              <span v-if="l.morning">M </span>
              <span v-if="l.afternoon">A </span>
              <span v-if="l.night">N </span>
            </td>
            <td>{{l.status}}</td>
            <td>{{l.employer}}</td>
            <td>{{l.qualificationExpiryDateDisplay}}</td>
            <td>{{l.workPermitExpiryDateDisplay}}</td>
            <td>{{l.awshppExpiryDateDisplay}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="mb-2"><strong>Statistics</strong></div>
      <div style="overflow-x: auto;">
        <table class="table table-sm table-bordered">
        <tbody>
          <tr>
            <td class="text-secondary">Total number of worker assignments processed</td>
            <td>{{i.noOfWorkerAssign}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of worker assignments by each Location and Industry / To Contractor</td>
            <td></td>
          </tr>
          <tr v-for="(e, ei) in i.noOfWorkerAssignByEachLocIndusToContrac" :key="ei">
            <td class="text-secondary">{{e.contractor}}</td>
            <td>{{e.count}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of worker assignments pending Confirmation</td>
            <td>{{i.noOfWorkerAssignPending}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Total number of workers processed</td>
            <td>{{i.noOfWorkerProcessed}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with Qualification expiring within 1 month</td>
            <td>{{i.noOfWorkerQualiExpiring1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with Qualification expiring within the 2nd month</td>
            <td>{{i.noOfWorkerQualiExpiring2Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with WP expiring within 1 month</td>
            <td>{{i.noOfWorkerWPExpiring1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with WP expiring within the 2nd month</td>
            <td>{{i.noOfWorkerWPExpiring2Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with WP expired within 1 month</td>
            <td>{{i.noOfWorkerWPExpired1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expiring within 1 month</td>
            <td>{{i.noOfWorkerAwshppExpiring1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expiring within the 2nd month</td>
            <td>{{i.noOfWorkerAwshppExpiring2Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expired within 1 month</td>
            <td>{{i.noOfWorkerAwshppExpired1Month}}</td>
          </tr>
          <tr>
            <td class="text-secondary">Number of workers with SOC expired for > 1 month</td>
            <td>{{i.noOfWorkerAwshppExpiredOver1Month}}</td>
          </tr>
        </tbody>
      </table>
         </div>
      
    </div>
  </div>

</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';
export default {
  name: 'WorkerAssignmentReport',
  components: {
    ...Forms,
  },
  data() {
    return {
      reportData: [],
      selectedCorporatOids: [],
      projectCodeOid: null,
      locatonOid: null,
      industryOid: null,
      isAllCororate: false,
      canViewAll:true,
      corporates: [],
      industries: [],
      projectCodes: [],
      siteLocations: [],
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Assignment Report' }
    ]);
    let corporateType = this.getLoggedInUser().userGroupType;
    api.getAllCorporates(null).then(response => {
      if(corporateType == this.$data._userGroupType.contractor.value){
         this.corporates = response.data.filter(x => x.oid == this.getLoggedInUser().corporateOid);
         this.canViewAll = false;
         this.selectedCorporatOids.push(this.getLoggedInUser().corporateOid);
         
      }else{
         this.corporates = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      }
     
      this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
      this.industries.unshift({oid:null, corpName: '<All>'});
    });
    api.regularGet(apiUrl.industryProject.getList).then(res => {
      this.projectCodes = res.data;
      this.projectCodes.unshift({oid:null, projectCode: '<All>'});
    });
    api.regularGet(apiUrl.site.getList).then(res => {
      this.siteLocations = res.data;
      this.siteLocations.unshift({oid:null, name: '<All>'});
    });
  },
  methods: {
    getData() {
      this.showLoadingScreen();
      var param = {
        corporateOids: this.selectedCorporatOids,
        isAllCorporate: this.isAllCororate,
        projectOid: this.projectCodeOid,
        locationOid: this.locatonOid,
        industryOid: this.industryOid,
      };
      this.reportData = [];
      console.log(param);
      api.regularPost(apiUrl.report.getWorkerAssignmentReport, param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.reportData = response.data;
          console.log(this.reportData);
        }
      });
    },
    clickSearh(){
      this.getData();
    }
  }
}
</script>
