<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <button class="btn btn-primary m-1 float-end" @click="clickPrintInvoice">Download Invoice</button>
          <button v-if='getLoggedInUser().userGroupType == $data._userGroupType.admin.value' class="btn btn-primary m-1 float-end" @click="clickCreditNote">Add Credit
            Note</button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Invoice No:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.invoiceNumber}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Invoice Date:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.invoiceDate}}</label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Entity Name:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.corporateName}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Entity Type:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.corporateTypeDisplay}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                GST Percentage
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.gstPercentage}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                For Month
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.forMonthDisplay}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Status
              </div>
              <div class="col-sm-6">
                <span v-if="dataObj.status == $data._invoiceStatus.new.value" class="badge bg-warning">{{dataObj.statusDisplay}}
                </span>
                <span v-else-if="dataObj.status == $data._invoiceStatus.confirmed.value" class="badge bg-info text-dark">{{dataObj.statusDisplay}}
                </span>
                 <span v-else-if="dataObj.status == $data._invoiceStatus.chequeProcessing.value " class="badge bg-info text-white" >{{dataObj.statusDisplay}}
                </span>
                 <span v-else-if="dataObj.status == $data._invoiceStatus.paid.value" class="badge bg-success text-white" >{{dataObj.statusDisplay}}
                </span>
                <span v-else class="badge bg-secondary">{{dataObj.statusDisplay}}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <span v-if="dataObj.status == $data._invoiceStatus.paid.value && dataObj.chequeNo">
                By Cheque/Bank Transfer, {{dataObj.paymentDate}}, {{dataObj.chequeNo}}
              </span>
              <span v-else-if="dataObj.status == $data._invoiceStatus.paid.value && !dataObj.chequeNo">
                By Giro, {{dataObj.paymentDate}}
              </span>
              <span v-else>

              </span>
              </div>
              
            </div>
            <!-- <div v-if="dataObj.corporateType == $data._corporateType.contractor" class="row mb-3">
              <a href="javascript:;" @click="showWorkerList">Click here to see worker list</a>
            </div> -->
            <div class="row mb-3">
              <h5>Item Details</h5>
              <div class="col-sm-12">
                <button v-if="canEdit" class="btn btn-secondary m-1 float-end" style="width:100px;"
                  @click="addItem">Add</button>
              </div>
              <div class="col-sm-12" style="overflow-x: auto;">
                <table class="table table-sm">
                  <thead class="table-light">
                    <tr>
                      <th>Description</th>
                      <th style="text-align:right;">Unit Price</th>
                      <th style="text-align:center;">Quantity</th>
                      <th style="text-align: right;">Amount</th>
                      <th v-if="isCreditNote" style="text-align: right;">Credit Amount</th>
                      <th v-if="canEdit" style="width:115px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, idx) in invoiceItems" :key="idx">
                      <td v-if="i.isLink"><a href="javascript:;" @click="showWorkerList">{{i.description}}</a></td>
                      <td v-else>{{i.description}}</td>
                      <td style="text-align: right;">{{numberWithCommas(Number(i.unitPrice).toFixed(2))}}</td>
                      <td style="text-align: center;">{{i.quantity}}</td>
                      <td style="text-align: right;">{{numberWithCommas(Number(i.totalAmount).toFixed(2))}}</td>
                      <td v-if="isCreditNote">
                        <input type="text" class="form-control" v-model="i.creditAmount" placeholder="Enter Credit Amount" />
                      </td>
                      <td v-if="canEdit">
                        <button class="btn btn-sm btn-error" @click="clickRemove(idx,i)">
                          <span><i class="bi bi-trash-fill"></i></span>
                        </button>
                        <button class="btn btn-sm btn-error" @click="clickEdit(i)">
                          <span><i class="bi bi-pencil-fill"></i></span>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"><span class="fw-bold text-info">Total Amount:</span> </td>
                      <td style="text-align: right;">{{numberWithCommas(Number(allTotalAmount).toFixed(2))}}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
             <div v-if="dataObj.creditNoteOid > 0" class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Credit Note
              </div>
              <div class="col-sm-6">
                <a href="javascript:;" @click="gotoCreditNoteDetail">{{dataObj.creditNoteNo}}</a>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                <b>Remark(s)</b> <br/>
                <p v-if="dataObj.status != $data._invoiceStatus.new.value" style="white-space: pre-line">{{dataObj.reason}}</p>
              </div>
              <div class="col-sm-6" v-if="dataObj.status == $data._invoiceStatus.new.value">
                <textarea maxlength="500" class="form-control" rows="6" :class="error.reason && 'control-error'" v-model="dataObj.reason"></textarea>
              </div>
              <div class="col-sm-6" v-else>
                
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Back</button>
          <button v-if="getLoggedInUser().userGroupType == $data._userGroupType.admin.value && (dataObj.status == $data._invoiceStatus.paid.value || dataObj.status == $data._invoiceStatus.giroProcessing.value|| dataObj.status == $data._invoiceStatus.chequeProcessing.value)" class="btn btn-warning m-1 float-end"
            @click="clickRevert($data._invoiceStatus.revert.value)">Revert To "Confirmed"</button>
          <button v-if="canEdit && !isNew" class="btn btn-success m-1 float-end"
            @click="clickSave($data._invoiceStatus.confirmed.value)">Confirm</button>
          <button v-if="canEdit" class="btn btn-primary m-1 float-end"
            @click="clickSave($data._invoiceStatus.new.value)">Save</button>
          <button v-if="isCreditNote" class="btn btn-primary m-1 float-end"
            @click="clickSaveCreditNote">Save Credit Note</button>
          <!-- <button v-if="canEdit && !isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button> -->
        </div>

      </div>
    </div>
    <ModalDiaglog size="xl" :show="showModal" :onClose="()=>{showModal=false}" :hasHeader="true" :title="title"
      :showSubmitBtn1="false">
      <div v-if="showModal">
        <div class="row">
          <div class="col-sm-12">
            <div v-if="isDuplicated" class="row mb-3">
              <div class="col-sm-4"></div>
              <div class="col-sm-8">
                <span class="text-danger">This item is already in list.</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Service Item:
              </div>
              <div class="col-sm-8">
                <v-select label="description" :class="error.itemDescription && 'control-error'"
                  v-model="selectedService" :options="serviceItems" @input="changeService"></v-select>
                <!-- <input type="text" class="form-control" :class="error.itemDescription && 'control-error'"
                      v-model="item.itemDescription" /> -->
              </div>
            </div>
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Description:
              </div>
              <div class="col-sm-8">
                <input type="text" class="form-control" :class="error.description && 'control-error'"
                  v-model="item.description" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Unit Price:
              </div>
              <div class="col-sm-8">
                <input type="number" class="form-control" :class="error.unitPrice && 'control-error'"
                  v-model="item.unitPrice" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Quantity:
              </div>
              <div class="col-sm-8">
                <input value="0" type="number" class="form-control" :class="error.quantity && 'control-error'"
                  v-model="item.quantity" />
              </div>
            </div>
            <button class="btn btn-primary m-1 float-end" style="width:100px;" @click="saveItem">Save</button>
          </div>
        </div>
      </div>
    </ModalDiaglog>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
export default {
  name: 'EditInvoice',

  components: {
    ModalDiaglog,
    ...Forms,
    ...Pickers
  },
  watch:{
    invoiceItems(){
      this.allTotalAmount = 0;
      this.invoiceItems.forEach(x => this.allTotalAmount += x.totalAmount);
    }
  },
  props: {

  },
  
  data() {
    return {
      oid: 0,
      confirmObj: {},
      dataObj: {},
      error: {},
      showModal: false,
      title:'Item Detail',
      invoiceItems:[],
      item:{},
      editItem:false,
      deletedItems:[],
      totalAmount:0,
      canEdit:true,
      isCreditNote:false,
      canAddCreditNote: false,
      serviceItems:[],
      selectedService:{},
      isDuplicated: false,
      allTotalAmount:0,
      isNew:false,
      corpOid:0
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedCorpOid = this.$route.params.corpOid;
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
    if (passedCorpOid && passedCorpOid > 0) {
      this.isNew = true;
      this.corpOid = parseInt(passedCorpOid);
    }
  },
  mounted() {
    let pageName = 'Invoice Details';
    this.setBreadcrumb([
      { name: 'List', url: '/invoices' }, { name: pageName }
    ]);
    if (this.oid > 0) {
      this.getData();
    }else if(this.corpOid > 0){
      this.getPreparedData();
    }
    console.log(this.getLoggedInUser());
  },

  methods: {
    showWorkerList(){
      this.$router.push({ name: 'InvoiceWorker', params: { id: this.oid } });
    },
    getDropdowns() {
        api.regularGet(apiUrl.serviceItem.getAll, null).then((response) => {
        this.serviceItems = response.data;
      });
      
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addItem(){
      this.selectedService = null;
      this.isDuplicated = false;
      this.item = {};
      this.showModal = true;
    },
    clickEdit(e){
      this.isDuplicated = false;
      this.editItem = true;
      this.selectedService = this.serviceItems.find(x => e.itemDescription == x.description)
      this.item = e;
      this.title = "Update Item";
      this.showModal = true;
    },
    clickRemove(e,item){
      this.deletedItems.push(item.oid);
      this.invoiceItems.splice(e, 1);
    },
    saveItem(){
      if(!this.validate())
        return;
      if(this.editItem == true){
        this.item.totalAmount = this.item.quantity * this.item.unitPrice;
      }else{
        let newItem = this.invoiceItems.find(x => this.item.itemDescription == x.itemDescription)
        if(newItem){
          this.isDuplicated = true;
          return;
        }
        this.item.totalAmount = this.item.quantity * this.item.unitPrice;
        this.invoiceItems.push(this.item);
      }
      this.allTotalAmount = 0;
      this.invoiceItems.forEach(x => this.allTotalAmount += x.totalAmount);

      this.isDuplicated = false;
      this.showModal = false;
      this.editItem = false;
    },
    getPreparedData(){
      let url = apiUrl.invoice.getPreparedData + '/' + this.corpOid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.invoiceItems = this.dataObj.invoiceItems;
        if(this.dataObj.status == this.$data._invoiceStatus.confirmed.value){
          this.canEdit = false;
        }
        if(!this.isAuthorizedPermission(this.$data._permissionCodes.manageInvoice)){
          this.canEdit = false;
        }
         this.getDropdowns();
      });
    },
    getData() {
      let url = apiUrl.invoice.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        if(!response.data){
          this.showMessage("Invalid Request", "error");
          this.$router.push({ name: "Invoices" });
        }
        this.dataObj = response.data;
        this.invoiceItems = this.dataObj.invoiceItems;
        this.canEdit = this.dataObj.canEdit;
        if(this.dataObj.status == this.$data._invoiceStatus.confirmed.value){
          this.canEdit = false;
        }
        this.getDropdowns();
      });
    },
    clickSaveCreditNote(){
      let isCreditAmountValid = true;
      this.invoiceItems.forEach(x => {
        if(x.creditAmount && x.totalAmount < x.creditAmount){
          isCreditAmountValid = false;
        }
      });
      if(!isCreditAmountValid){
         this.showMessage("Credit amount is more than actual amount.", "error");
         return;
      }
      let subUrl = apiUrl.creditNote.insert;
      let data = {
          oid: this.dataObj.oid,
          status: "",
          invoiceItems: this.invoiceItems,
          isNew: this.isNew,
          corpOid: this.dataObj.corporateOid,
          corporateType: this.dataObj.corporateType
      }
      this.showLoadingScreen();
      api.regularPost(subUrl, data).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "Invoices" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickSave(e) {
    
      let subUrl = apiUrl.invoice.update;
      let data = {
          oid: this.dataObj.oid,
          status: e,
          deletedItems: this.deletedItems,
          invoiceItems: this.invoiceItems,
          isNew: this.isNew,
          corpOid: this.corpOid,
          reason: this.dataObj.reason
      }
      this.showLoadingScreen();
      api.regularPost(subUrl, data).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "Invoices" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage("Error occured", "error");
        }
        else {
          this.showMessage("Error occured", "error");
        }
      });

    },
    clickRevert(e) {
    
      let subUrl = apiUrl.invoice.update;
      let data = {
          oid: this.dataObj.oid,
          status: e,
          deletedItems: this.deletedItems,
          invoiceItems: this.invoiceItems,
          isNew: this.isNew,
          corpOid: this.corpOid,
          reason: this.dataObj.reason
      }
      this.showConfirmDiaglog({
        show: true,
        message: "Are you sure to revert " + this.dataObj.invoiceNumber + "?",
        submitFunction: () => {
          api
            .regularPost(
              subUrl,
              data,
              this.$store.state.token
            )
            .then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Reverted.", "success");
                this.$router.push({ name: "Invoices" });
              } else if (
                response.status == 400 ||
                response.errorText.response.status == 400
              ) {
                this.showMessage(
                  response.errorText.response.data.message,
                  "error"
                );
              } else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
        },
      });
    },
    clickCancel() {
      this.$router.push({ name: "Invoices" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.item.quantity) { this.error.quantity = true; isValid = false; }
      if (!this.item.unitPrice) { this.error.unitPrice = true; isValid = false; }
      if (!this.item.itemDescription) { this.error.itemDescription = true; isValid = false; }
       if (!this.item.description) { this.error.description = true; isValid = false; }
      this.$forceUpdate();
      return isValid;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove this item?',
          submitFunction: () => {
            let data = {
                oid: this.dataObj.oid
            }
            api.regularPost(apiUrl.invoice.delete, data).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "Invoices" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    },
    changeExpiryDate(date) {
      this.dataObj.expiryDate = this.convertDateObjToDateStr(date);
    },
    changeTerminateDate(date) {
      this.dataObj.terminateDate = this.convertDateObjToDateStr(date);
    },
    changeService(obj) {
      if (obj){
        this.item.itemDescription = obj.description;
        this.item.description = obj.description;
        this.item.unitPrice = obj.unitPrice;
      }
      else
         this.item.itemDescription = null;
    },
    clickPrintInvoice() {
      let fileName = this.dataObj.invoiceNumber.replace('/','_');
      api.downloadFile(apiUrl.pdf.invoice, { oid: this.dataObj.oid }, fileName + '.pdf', () => {
        this.showMessage('Downloaded successfully.', 'success');
       
      });
    },
    clickCreditNote(){
      this.isCreditNote = true;
    },
    gotoCreditNoteDetail(){
      this.$router.push({ name: 'ViewCreditNote', params: { id: this.dataObj.creditNoteOid } });
    }
  }
}
</script>
