<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text"
                  @change="changeFilterStatus" />
              </div>
            </div>
          </div>
           <div v-if="canListEdit" class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Corporates
              </div>
              <div class="col-sm-6">
                <v-select label="corpName" v-model="selectedCorporate" :options="corporates" @input="changeCorporate"></v-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row mb-2">
              <div v-if="tableParam.status != $data._invoiceStatus.paid.value" class="col-sm-4">
                Invoice Date
              </div>
              <div v-else class="col-sm-4">
                Payment Date
              </div>
              <div class="col-sm-8">
                <DateRangePicker @updatedaterange="dateRangeChangeInvoice"></DateRangePicker>
              </div>
            </div>
            
          </div>
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Invoice No.
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeInvoiceNo" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="callTable">Search</button>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb-1">
      <div class="card-body">
        <div v-if="tableParam.status == $data._invoiceStatus.new.value && canListEdit" class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.confirmed.value)">Confirm
              Selected Invoices</button>
          </div>
        </div>
        <div v-if="tableParam.status == $data._invoiceStatus.confirmed.value && canListEdit" class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.new.value)">Remove Payment
              Date and Cheque Number</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.badDebt.value)">Bad
              Debt</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.chequeProcessing.value)">Cheque Payment</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickSendInvoice">Send Invoice</button>
          </div>
        </div>
        <div v-if="tableParam.status == $data._invoiceStatus.chequeProcessing.value && canListEdit" class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.paid.value)">Paid</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.badDebt.value)">Bad Debt</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickSendInvoice">Send Invoice</button>
          </div>
        </div>
        <div v-if="tableParam.status == $data._invoiceStatus.giroProcessing.value && canListEdit" class="row">
          <div class="col-sm-12">
            
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.paid.value)">Giro Success</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickUpdate($data._invoiceStatus.giroError.value)">Giro Error</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="clickSendInvoice">Send Invoice</button>
            <input type="text" style="width:200px;margin-right:10px;" class="form-control float-end  mb-2" placeholder="(dd-MM-yyyy)" @keyup="changeDateValue"/>
          </div>
        </div>
        <div style="overflow-x: auto;">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <SortColumn @click="sortColumn" sortBy="invoiceNumber" colGroup="tbl1">Invoice Number</SortColumn>
              <SortColumn @click="sortColumn" sortBy="invoiceDate" colGroup="tbl1">Invoice Date</SortColumn>
              <th>Corporate Name</th>
              <th>Entity Type</th>
              <th style="text-align: right;">Amount</th>
              <SortColumn style="text-align: center;" @click="sortColumn" sortBy="forMonth" colGroup="tbl1">For Month
              </SortColumn>
              <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
              <th>Payment</th>
              <SortColumn @click="sortColumn" sortBy="paymentDate" colGroup="tbl1">Payment Date</SortColumn>
              <SortColumn @click="sortColumn" sortBy="chequeNo" colGroup="tbl1">Cheque Number</SortColumn>
              <th v-if="tableParam.status != '' && canListEdit"><input type="checkbox" @change="checkHeader" v-model="checkedAll"/></th>
              <th style="width:115px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in tableParam.listData" :key="idx">
              <td>{{i.invoiceNumber}}</td>
              <td>{{i.invoiceDate}}</td>
              <td>{{i.corporateName}}</td>
              <td>{{i.corporateTypeDisplay}}</td>
              <td style="text-align: right;">{{numberWithCommas(Number(i.totalAmount).toFixed(2))}}</td>
              <td style="text-align: center;">{{i.forMonth}}</td>
              <td>
                <span v-if="i.status == $data._invoiceStatus.new.value" class="badge bg-warning">{{i.statusDisplay}}
                </span>
                <span v-else-if="i.status == $data._invoiceStatus.confirmed.value" class="badge bg-info text-dark">{{i.statusDisplay}}
                </span>
                 <span v-else-if="i.status == $data._invoiceStatus.chequeProcessing.value " class="badge bg-info text-white" >{{i.statusDisplay}}
                </span>
                 <span v-else-if="i.status == $data._invoiceStatus.paid.value" class="badge bg-success text-white" >{{i.statusDisplay}}
                </span>
                <span v-else class="badge bg-secondary">{{i.statusDisplay}}</span>
              </td>
              <td v-if="i.status == $data._invoiceStatus.paid.value && i.chequeNo">
                By Cheque/Bank Transfer
              </td>
              <td v-else-if="i.status == $data._invoiceStatus.paid.value && !i.chequeNo">
                By Giro
              </td>
              <td v-else>

              </td>
              <td v-if="(tableParam.status == $data._invoiceStatus.chequeProcessing.value|| tableParam.status == $data._invoiceStatus.giroProcessing.value) && canListEdit">
                <!-- <DatePicker @updatedate="changeInvoiceDate" :existing="pickDate" v-model="pDate"></DatePicker> -->
                <input
                        type="text"
                        class="form-control"
                        v-model="i.paymentDate"
                        placeholder="(dd-MM-yyyy)"
                      />
              </td>
              <td v-else>{{i.paymentDate}}</td>
              <td v-if="(tableParam.status == $data._invoiceStatus.chequeProcessing.value) && canListEdit">
                <input
                        type="text"
                        class="form-control"
                        v-model="i.chequeNo"
                      /> 
              </td>
              <td v-else>{{i.chequeNo}}</td>
              <td  v-if="tableParam.status != '' && canListEdit"><input type="checkbox" v-model="i.isChecked" /></td>
              <td>
                <button class="btn btn-sm btn-primary" @click="clickDetails(i)">
                  <span v-if="isAuthorizedPermission($data._permissionCodes.viewInvoice)">View</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        
      </div>
    </div>
    <Pagination :pagingParam="tableParam" @updatePaging="updatePaging" />

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
import _ from "lodash";
export default {
  name: 'InvoiceList',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: [],
      pickDate:null,
      pDate:null,
      canListEdit: true,
      selectedCorporate:null,
      corporates:[],
      checkedAll:false,
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
     let passedValue = this.$route.params.filter;
     if(passedValue){
       this.tableParam.status = this.$data._invoiceStatus.new.value;
     }
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Invoices' }
    ]);
    let url = apiUrl.corporate.getAll 
        api.regularGet(url,null ).then((response) => {
          this.corporates = response.data;
        });
    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._invoiceStatus.new.value, text: this.$data._invoiceStatus.new.text },
      { value: this.$data._invoiceStatus.paid.value, text: this.$data._invoiceStatus.paid.text },
      { value: this.$data._invoiceStatus.confirmed.value, text: this.$data._invoiceStatus.confirmed.text },
      { value: this.$data._invoiceStatus.giroProcessing.value, text: this.$data._invoiceStatus.giroProcessing.text },
      { value: this.$data._invoiceStatus.giroError.value, text: this.$data._invoiceStatus.giroError.text },
      { value: this.$data._invoiceStatus.badDebt.value, text: this.$data._invoiceStatus.badDebt.text },
       { value: this.$data._invoiceStatus.chequeProcessing.value, text: this.$data._invoiceStatus.chequeProcessing.text },
    ];
    this.tableParam.invoice_Start = null;
    this.tableParam.invoice_End = null;
    this.tableParam.orderBy = 'invoiceDate';
    this.tableParam.orderDir = 'desc';
    this.tableParam.invoiceNo = '';
    this.callTable();
  },
  methods: {
    changeDateValue: _.debounce(function (e) {
      this.tableParam.listData.forEach(x => {
        x.paymentDate = e.target.value;
      });
    }, 500),
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateRangeChangeInvoice(dateRange) {
      this.tableParam.invoice_Start = this.convertDateObjToDateStr(dateRange.start);
      this.tableParam.invoice_End = this.convertDateObjToDateStr(dateRange.end);
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.invoice.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
          if(this.tableParam.listData.length > 0){
            this.canListEdit = this.tableParam.listData[0].canListEdit;
          }
          console.log(this.canListEdit);
        }
      });
    },
    clickDetails(data) {
      this.$router.push({ name: 'EditInvoice', params: { id: data.oid } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    },
    clickSendInvoice(){
      let subUrl = apiUrl.invoice.sendInvoice;
      let selectedList = this.tableParam.listData.filter(i => i.isChecked);
      let invoice_oids = [];
      selectedList.forEach(x => {
        invoice_oids.push(x.oid);
      }); 
      this.showLoadingScreen();
      api.regularPost(subUrl, invoice_oids).then((response) => {
        console.log(response);
        this.hideLoadingScreen();
        if (response.status == 200) {
           this.showMessage("Successfully sent invoice(s).", "success");
        }else{
           this.showMessage("Failed to send invoice(s).", "error");
        }
      });
    },
    clickUpdate(s){
      let subUrl = apiUrl.invoice.updateStatus;
      let status = s;
      let selectedList = this.tableParam.listData.filter(i => i.isChecked);
      console.log(selectedList);
      if(selectedList.length == 0){
        this.showMessage("Select invoice", "error");
        return;
      }
      let data = {
        invoices: selectedList,
        status: status
      }
      this.showLoadingScreen();
      api.regularPost(subUrl, data).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.tableParam.status = "";
          this.callTable();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    changeInvoiceDate(date){
      console.log(this.convertDateObjToDateStr(date));
      
    },
    changeCorporate(obj) {
      if(obj == null){
        this.tableParam.corpOid = 0;
      }else{
        this.tableParam.corpOid = obj.oid;
      }
      
    },
    checkHeader(){
      this.tableParam.listData.forEach(x => {
        x.isChecked = this.checkedAll;
      });
    },
    changeInvoiceNo: _.debounce(function (e) {
      this.tableParam.invoiceNo = e.target.value;
      this.callTable();
    }, 500),
  }
}
</script>
