<template>
  <div>
    <div style="overflow-x: auto;">
      <table class="table table-sm">
        <thead class="table-light">
          <tr>
            <SortColumn @click="sortColumn" sortBy="testDate" colGroup="tbl1">Test Date</SortColumn>
            <SortColumn @click="sortColumn" sortBy="testCentre" colGroup="tbl1">Test Centre</SortColumn>
            <th>Skill Test</th>
            <th>NoOfWorkers</th>
            <th style="width:115px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in tableParam.listData" :key="idx">
            <td>{{i.testDateDisplay}}</td>
            <td>{{i.testCentre}}</td>
            <td>{{i.skill}}</td>
            <td>{{i.noOfWorkers}}</td>
            <td>
              <button class="btn btn-sm btn-primary" @click="clickView(i)">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
      />
    </div>
    
  </div>
</template>

<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'TestCandidateHistory',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Test Candidates' }
    ]);
    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      this.tableParam.forTestCentre = true;
      api.regularPost(apiUrl.testApplication.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },    
    clickView(data) {
      this.$router.push({ name: 'ViewTestApplication', params: { id: data.oid } });
    },

  }
}
</script>
