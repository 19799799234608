<template>
  <div style="background: #f2f2f2; min-height:100vh;">
    <div class="container">
      <div class="row">
        <div class="offset-md-4 col-md-4 ">
          <div class="text-center mb-3" style="margin-top:10%;">
            <!-- <img src="/img/PrimosLogo.png" style="width:300px;" /> -->
          </div>
          <div class="card">
            <div class="card-body">
              <div class="mb-3 text-secondary">
                <h3>Reset Password</h3>
                <span>Enter your user name or email address</span>
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text"><i class="bi bi-envelope"></i></span>
                <input type="text" class="form-control" v-model="email" placeholder="Email" @keypress="keypress" />
              </div>
              <div class="mb-3">
                <button class="btn btn-primary float-end" style="width:100px;" @click="submit">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },

  methods: {
    //Testing method of Vuex
    testVuexStore() {
      //this.$store.state.testCount = 100; //OR
      this.$store.commit('testSet', 100);
      setTimeout(() => {
        console.log(this.$store.state.testCount);
      }, 500);
    },

    submit() {
      let data = {
        oid:0,
        password:'',
        email: this.email,
      }
      let url =  apiUrl.users.reqestForgotPassword 
      api.regularPost(url, data).then((response) => {
        if (response.status == 200) {
          this.showMessage("Password reset link sent to your email.", "success");
          this.$router.push({ name: "Login" });
        }
        else {
          this.showMessage("Invalid Request", "error");
        }
      });
    },
    keypress(e){
      //Enter
      if(e.keyCode == 13){
        this.submit();
      }
    }
  }
}
</script>
