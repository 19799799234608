<template>
  <div style="background: #303C54;">
    <div class="flex-shrink-0 p-3 text-white" style=" padding-left:0px !important; padding-right:5px !important;">
      <div class="text-center">
        <a href="/dashboard" class="text-white text-decoration-none">
          <!-- <svg class="bi me-2" width="30" height="24">
            <use xlink:href="#bootstrap" />
          </svg>
          <span class="fs-5 fw-semibold">PRiMOS</span> -->
          <img src="/img/PrimosLogo.png" class="mb-3" style="width:165px;" />
        </a>
        <div v-if="isMobileSize && !isSideBarClose" style="position:absolute;right:10px;top:10px;">
          <a href="javascript:;" @click="closeSideBar" class="h4">
            <i class="bi bi-x"></i>
          </a>
        </div>
      </div>
      <div>
        <ul class="list-unstyled ps-0" id="div_mainmenu">
          <SideBarNavItem :closeSideBar="closeSideBar" :isMobileSize="isMobileSize" v-for="(m, idx) in navs" :key="idx" :nav="m" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import SideBarNavItem from '@/components/layout/SideBarNavItem';
import nav from '@/router/nav';

export default {
  name: 'SideBarNav',
  components: { SideBarNavItem },
  props: {
    closeSideBar: { type: Function },
    isMobileSize: { type: Boolean },
    isSideBarClose: { type: Boolean }
  },
  data() {
    return {
      navs: nav
    }
  },
}
</script>
