<template>
  <div v-if="isAuthorizedPage">

    <div class="row">
      <div class="col-10">
        <div class="d-flex">
          <div class="d-flex">
            <div style="margin-top:-19px;"><i class="bi bi-person-fill me-3" style="font-size:60px;"></i></div>
            <div>
              <div style="font-size:18px;">{{dataObj.name}}</div>
              <div style="font-size:16px;"><span v-if="dataObj.nricFin">{{dataObj.nricFin}}</span><span v-if="dataObj.nricFin && dataObj.workPermit"> / </span><span v-if="dataObj.workPermit">{{dataObj.workPermit}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    <div class="my-tab">
      <div class="my-tab-item" :class="currentTab=='details' && 'active'" @click="currentTab='details'">Details</div>
      <div class="my-tab-item" :class="currentTab=='testResultSummary' && 'active'" @click="currentTab='testResultSummary'">Test Result Summary</div>
      <div class="my-tab-item" :class="currentTab=='worksitetSummary' && 'active'" @click="currentTab='worksitetSummary'">Worksite Summary</div>
      <div class="my-tab-item" :class="currentTab=='workshopSummary' && 'active'" @click="currentTab='workshopSummary'">Workshop Summary</div>
      <div class="my-tab-item" :class="currentTab=='employmentSummary' && 'active'" @click="currentTab='employmentSummary'">Employment Summary</div>
    </div>

    <div v-show="currentTab=='details'">

      <h5 class="mb-3">Personal Particulars</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Corporate Name:
            </div>
            <div class="col-sm-6">
              {{corpName}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              NRIC / FIN No.:
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.nricFin && 'control-error'"
                v-model="dataObj.nricFin" :disabled="!hasPermission" maxlength="9" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Work Permit No.:
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.workPermit && 'control-error'"
                v-model="dataObj.workPermit" :disabled="!hasPermission" maxlength="12" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Work Permit Valid From Date:
              <div class="txt-small">(Foreigners Only)</div>
            </div>
            <div class="col-sm-6">
              <DatePicker @updatedate="changeWorkPermitIssueDate"
                :cssClass="error.workPermitIssueDate && 'control-error'" :existing="workPermitIssueDate"
                :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Work Permit Expiry Date
              <div class="txt-small">(Foreigners Only)</div>
            </div>
            <div class="col-sm-6">
              <DatePicker @updatedate="changeWorkPermitExpiryDate" :availableDates="expAvailableDates"
                :cssClass="error.workPermitExpiryDate && 'control-error'" :existing="workPermitExpiryDate"
                :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Welder ID:
              <div class="txt-small">(E.g. WEL0004340) Leave blank if not applicable</div>
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.welderId && 'control-error'"
                v-model="dataObj.welderId" :disabled="!hasPermission || disableWelderId" maxlength="20" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Name<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.name && 'control-error'" v-model="dataObj.name"
                :disabled="!hasPermission" maxlength="50" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Date of Birth<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <DatePicker @updatedate="changeDob" :cssClass="error.dob && 'control-error'" :existing="dob"
                :disabled="!hasPermission" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Gender<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.gender && 'control-error'" :list="genders" value="oid" text="value"
                :existing="dataObj.gender" @change="dataObj.gender=$event" :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Race<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.raceOid && 'control-error'" :list="races" value="oid" text="value"
                :existing="dataObj.raceOid" @change="dataObj.raceOid=$event" :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Nationality<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.nationalityOid && 'control-error'" :list="nationalities" value="oid"
                text="value" :existing="dataObj.nationalityOid" @change="dataObj.nationalityOid=$event"
                :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Citizenship<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.citizenshipOid && 'control-error'" :list="citizenships" value="oid"
                text="value" :existing="dataObj.citizenshipOid" @change="dataObj.citizenshipOid=$event"
                :disabled="!hasPermission" />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h5 class="mb-3">Qualifications</h5>
      <strong>Skill Set</strong>
      <div class="row mb-3">
        <div v-for="(q,i) in skillSets" class="col-sm-4" :key="i">
          <label class="hover">
            <input type="checkbox" v-model="q.selected" :disabled="!hasPermission" @change="changeChkSkillSet" /> {{q.value}}
          </label>
        </div>
      </div>
      <strong>Occupation</strong>
      <div class="row mb-3">
        <div v-for="(o,i) in occupations" class="col-sm-4" :key="i">
          <label class="hover">
            <input type="checkbox" v-model="o.selected" :disabled="!hasPermission" /> {{o.value}}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Highest Education Reached<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.educationOid && 'control-error'" :list="educationLevels" value="oid"
                text="value" :existing="dataObj.educationOid" @change="dataObj.educationOid=$event"
                :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Technical Skill Level:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.techSkillOid && 'control-error'" :list="techSkills" value="oid"
                text="value" :existing="dataObj.techSkillOid" @change="dataObj.techSkillOid=$event"
                :disabled="true" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              AWSHPP Certification No.:
              <div class="txt-small">(Oil/Petrochemical)</div>
              <div class="txt-small">(e.g B06933 or OSHA123)</div>
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.awshppCertNo && 'control-error'"
                v-model="dataObj.awshppCertNo" :disabled="!hasPermission" maxlength="50" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              AWSHPP Expiry Date:
              <div class="txt-small">(Oil/Petrochemical)</div>
            </div>
            <div class="col-sm-6">
              <DatePicker @updatedate="changeAwshppExpiryDate" :cssClass="error.awshppExpiryDate && 'control-error'"
                :availableDates="expAvailableDates" :existing="awshppExpiryDate" :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              SWSHPP Certification No.:
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.swshppCertNum && 'control-error'"
                v-model="dataObj.swshppCertNum" :disabled="!hasPermission" maxlength="50" />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Sponsor<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <FormSelect :cssClass="error.sponsorOid && 'control-error'" :list="industries" value="oid" text="corpName"
                :existing="dataObj.sponsorOid" @change="changeSponsor" :disabled="!hasPermission" />                
            </div>
          </div>
          <div class="row mb-2" v-show="dataObj.sponsorOid==-1">
            <div class="col-sm-6 text-secondary">
              Other Sponsor<span class="text-danger">*</span>:
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.otherSponsor && 'control-error'"
                v-model="dataObj.otherSponsor" :disabled="!hasPermission" placeholder="Other Sponsor" maxlength="50" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Sub-Contractor:
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" :class="error.subContractor && 'control-error'"
                v-model="dataObj.subContractor" :disabled="!hasPermission" maxlength="50" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Commencement Date<span class="text-danger">*</span>:
              <div class="txt-small">(Note: A date in which the worker starts working with the company)</div>
            </div>
            <div class="col-sm-6">
              <DatePicker @updatedate="changeCommenceDate" :cssClass="error.commenceDate && 'control-error'"
                :existing="commenceDate" :disabled="!hasPermission" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Termination Date:
            </div>
            <div class="col-sm-6">
              <DatePicker @updatedate="changeTerminatedDate" :cssClass="error.terminatedDate && 'control-error'"
                :availableDates="terminateAvailableDates" :existing="terminatedDate" :disabled="!hasPermission" />
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Remarks:
            </div>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" :class="error.remarks && 'control-error'"
                v-model="dataObj.remarks" :disabled="!hasPermission"></textarea>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 text-secondary">
              Status:
            </div>
            <div class="col-sm-6">
              <span class="badge bg-success" v-if="dataObj.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
              <span class="badge bg-danger" v-else-if="dataObj.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
              <span class="badge" v-else>{{dataObj.statusDisplay}}</span>
            </div>
          </div>
        </div>
      </div>
      <span class="text-danger small">* Denotes Mandatory Field</span>
      <button v-if="this.hasPermission" class="btn btn-success m-1 float-end"
        @click="clickSave">{{saveBtnText}}</button>
    </div>

    <div v-if="currentTab=='testResultSummary'">
      <AddEditTestResult :workerOid="oid" />
    </div>

    <div v-if="currentTab=='worksitetSummary'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Industry</th>
            <th>Location</th>
            <th>Contractor</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Shift</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d,i) in worksiteSummary" :key="i">
            <td>{{d.industry}}</td>
            <td>{{d.location}}</td>
            <td>{{d.contractor}}</td>
            <td>{{d.startDate}}</td>
            <td>{{d.endDate}}</td>
            <td>
              <span v-if="d.morning">M </span>
              <span v-if="d.afternoon">A </span>
              <span v-if="d.night">N </span>
            </td>
            <td>{{d.statusDisplay}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="currentTab=='workshopSummary'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Accessing Inspector</th>
            <th>Industry/Test Centre/Contractor</th>
            <th>StartDate</th>
            <th>EndDate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d,i) in workshopSummary" :key="i">
            <td>{{d.accessingInspector}}</td>
            <td>{{d.industry}}<span v-if="d.industry && d.contractor">/</span>{{d.contractor}}</td>
            <td>{{d.startDate}}</td>
            <td>{{d.endDate}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-show="currentTab=='employmentSummary'">
      <table class="table">
        <thead>
          <tr>
            <th>Contractor Name</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,i) in employmentHistory" :key="i">
            <td>{{e.corporateName}}</td>
            <td>{{e.commenceDateDisplay}}</td>
            <td>{{e.terminatedDateDisplay}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<style scoped>
</style>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
// import AuditLog from '@/components/custom/AuditLog';
// import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
import AddEditTestResult from './AddEditTestResult';
import perCode from '@/common/permissionCodes';

export default {
  name: 'AddEditWorker',

  components: {
    // AuditLog,
    // ModalDiaglog,
    ...Forms,
    ...Pickers,
    AddEditTestResult
  },

  data() {
    return {
      oid: 0,
      isNew: true,
      corpName: '',
      nricFin: '',
      workPermitNo: '',

      dob: null,
      workPermitIssueDate: null,
      workPermitExpiryDate: null,
      awshppExpiryDate: null,
      commenceDate: null,
      terminatedDate: null,

      error: {},
      dataObj: {},

      genders: [],
      races: [],
      nationalities: [],
      citizenships: [],
      skillSets: [],
      occupations: [],
      educationLevels: [],
      corporates: [],
      techSkills: [],
      industries: [],

      saveBtnText: '',

      hasPermission: false,

      currentTab: 'details',
      employmentHistory: [],

      isExistingIsAlreadyTerminated: false,

      welderSkillOid: 0,
      expAvailableDates: {},
      terminateAvailableDates: {},
      disableWelderId: true,

      worksiteSummary: [],
      workshopSummary: [],

      isAuthorizedPage: true,
    }
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
      this.saveBtnText = 'Update Worker';

      //Check Permisstion
      if (!this.isAuthorizedPermission(perCode.manageWorker)) {
        this.showMessage("Forbidden!!!", "error");
        this.isAuthorizedPage = false;
      }

    }
    else {
      this.saveBtnText = 'Add Worker';
    }
    this.corpName = this.$route.params.corpName;
    this.dataObj.nricFin = this.$route.params.nricFin;
    this.dataObj.workPermit = this.$route.params.workPermitNo;
    if (this.$route.query.at) {
      this.isExistingIsAlreadyTerminated = this.$route.query.at;
    }

    //this.expAvailableDates = { start: new Date() };
    this.terminateAvailableDates = { start: new Date() };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = this.isNew ? 'New Worker' : 'Edit Worker';
    this.setBreadcrumb([
      { name: 'Workers', url: '/workers' }, { name: pageName }
    ]);

    this.genders = [{ oid: 'M', value: 'Male' }, { oid: 'F', value: 'Female' }];
    api.getSettingsByGroup(this.$data._settingGroup.race).then((response) => {
      this.races = response.data;
    });
    api.getSettingsByGroup(this.$data._settingGroup.country).then((response) => {
      this.nationalities = response.data;
    });
    api.getSettingsByGroup(this.$data._settingGroup.citizenship).then((response) => {
      this.citizenships = response.data;
    });
    api.getSettingsByGroup(this.$data._settingGroup.skillSet).then((response) => {
      response.data.forEach(x => {
        this.skillSets.push({ oid: x.oid, value: x.value, selected: false })
        if (x.value == 'Welding') {
          this.welderSkillOid = x.oid;
        }
      });
    });
    api.getSettingsByGroup(this.$data._settingGroup.occupation).then((response) => {
      response.data.forEach(x => this.occupations.push({ oid: x.oid, value: x.value, selected: false }));
    });
    api.getSettingsByGroup(this.$data._settingGroup.educationLevel).then((response) => {
      this.educationLevels = response.data;
      if (this.oid > 0 && !this.dataObj.oid) {
        this.getDataObj();
      }
    });
    api.getSettingsByGroup(this.$data._settingGroup.techSkill).then((response) => {
      this.techSkills = response.data;
      if (this.techSkills.length > 0) {
        if (this.isNew) {
          let unskilled = this.techSkills.find(x => x.name == "UnSkilled");
          if (unskilled)
            this.dataObj.techSkillOid = unskilled.oid;
        }
      }
    });
    api.getAllCorporates(null).then(response => {
      this.corporates = response.data;
      this.industries = this.corporates.filter(x => x.type == this.$data._corporateType.industry.value && x.status == this.$data._corporateStatus.active.value);
      this.industries.unshift({ oid: '', corpName: '<None>' });
      this.industries.push({ oid: -1, corpName: 'Others' });
      if (this.oid > 0 && !this.dataObj.oid) {
        this.getDataObj();
        this.getEmploymentHistory();
      }
    });

    this.getWorksiteSummary();
    this.getWorkshopSummary();

    this.hasPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageWorker);

    if (this.isNew) {
      this.sponsorOid = '';
    }

  },
  methods: {
    getDataObj() {
      let url = apiUrl.worker.getByOid + '/' + this.oid;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        if (this.dataObj.dob)
          this.dob = this.convertDateStrToDateObj(this.dataObj.dob);
        if (this.dataObj.workPermitIssueDate) {
          this.workPermitIssueDate = this.convertDateStrToDateObj(this.dataObj.workPermitIssueDate);
        }

        if (this.dataObj.workPermitExpiryDate) {
          this.workPermitExpiryDate = this.convertDateStrToDateObj(this.dataObj.workPermitExpiryDate);
        }

        if (this.dataObj.awshppExpiryDate)
          this.awshppExpiryDate = this.convertDateStrToDateObj(this.dataObj.awshppExpiryDate);
        if (this.dataObj.commenceDate)
          this.commenceDate = this.convertDateStrToDateObj(this.dataObj.commenceDate);
        if (this.isExistingIsAlreadyTerminated)
          this.dataObj.terminatedDate = null;
        if (this.dataObj.terminatedDate)
          this.terminatedDate = this.convertDateStrToDateObj(this.dataObj.terminatedDate);

        if (this.dataObj.sponsorOid == null) {
          if (this.dataObj.otherSponsor)
            this.dataObj.sponsorOid = -1;
        }

        if (this.dataObj.skillSet) {
          JSON.parse(this.dataObj.skillSet).forEach(s => {
            let tmpObj = this.skillSets.find(x => x.oid == s);
            if (tmpObj)
              tmpObj.selected = true;
          });
        }
        if (this.dataObj.occupation) {
          JSON.parse(this.dataObj.occupation).forEach(o => {
            let tmpObj = this.occupations.find(x => x.oid == o);
            if (tmpObj)
              tmpObj.selected = true;
          });
        }

        //Permission
        if (this.getLoggedInUser().corporateOid != this.dataObj.corporateOid) {
          if (this.getLoggedInUser().userGroupType != this.$data._userGroupType.admin.value) {
            this.hasPermission = false;
          }
        }

      });
    },
    getEmploymentHistory() {
      let url = apiUrl.worker.workerEmployHistory + '/' + this.oid;
      api.regularGet(url, null).then((response) => {
        this.employmentHistory = response.data;
      });
    },
    changeDob(date) {
      this.dataObj.dob = this.convertDateObjToDateStr(date);
    },
    changeWorkPermitIssueDate(date) {
      this.dataObj.workPermitIssueDate = this.convertDateObjToDateStr(date);
    },
    changeWorkPermitExpiryDate(date) {
      this.dataObj.workPermitExpiryDate = this.convertDateObjToDateStr(date);
    },
    changeAwshppExpiryDate(date) {
      this.dataObj.awshppExpiryDate = this.convertDateObjToDateStr(date);
    },
    changeCommenceDate(date) {
      this.dataObj.commenceDate = this.convertDateObjToDateStr(date);
    },
    changeTerminatedDate(date) {
      this.dataObj.terminatedDate = this.convertDateObjToDateStr(date);
    },

    clickSave() {
      if (!this.validate())
        return;

      this.dataObj.skillSet = JSON.stringify(this.skillSets.filter(x => x.selected).map(x => x.oid));
      this.dataObj.occupation = JSON.stringify(this.occupations.filter(x => x.selected).map(x => x.oid));

      //None Sponsor
      if (!this.dataObj.sponsorOid) {
        this.dataObj.sponsorOid = null;
      }

      //Other Sponsor
      if (this.dataObj.sponsorOid == -1) {
        this.dataObj.sponsorOid = null;
      }

      let url = '';
      if (this.isNew) {
        url = apiUrl.worker.insert;
      }
      else {
        this.dataObj.isExistingIsAlreadyTerminated = this.isExistingIsAlreadyTerminated;
        url = apiUrl.worker.update;
      }

      this.showLoadingScreen();
      api.regularPost(url, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "Workers" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },

    validate() {
      let isValid = true;
      var msg = '';
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.name) { this.error.name = true; isValid = false; }
      if (!this.dataObj.dob) { this.error.dob = true; isValid = false; }
      if (!this.dataObj.gender) { this.error.gender = true; isValid = false; }
      if (!this.dataObj.raceOid) { this.error.raceOid = true; isValid = false; }
      if (!this.dataObj.nationalityOid) { this.error.nationalityOid = true; isValid = false; }
      if (!this.dataObj.citizenshipOid) { this.error.citizenshipOid = true; isValid = false; }
      if (!this.dataObj.educationOid) { this.error.educationOid = true; isValid = false; }
      if (!this.dataObj.commenceDate) { this.error.commenceDate = true; isValid = false; }
      //if (!this.dataObj.sponsorOid) { this.error.sponsorOid = true; isValid = false; }

      //Other Sponsor
      if (this.dataObj.sponsorOid == -1 && !this.dataObj.otherSponsor) {
        this.error.otherSponsor = true; isValid = false;
      }

      if (this.dataObj.workPermit || this.dataObj.workPermitIssueDate || this.dataObj.workPermitExpiryDate) {
        if (!this.dataObj.workPermit) {
          this.error.workPermit = true;
          isValid = false;
        }
        if (!this.dataObj.workPermitIssueDate) {
          this.error.workPermitIssueDate = true;
          isValid = false;
        }
        if (!this.dataObj.workPermitExpiryDate) {
          this.error.workPermitExpiryDate = true;
          isValid = false;
        }
      }

      if (this.dataObj.awshppCertNo || this.dataObj.awshppExpiryDate) {
        if (!this.dataObj.awshppCertNo) {
          this.error.awshppCertNo = true;
          isValid = false;
        }
        if (!this.dataObj.awshppExpiryDate) {
          this.error.awshppExpiryDate = true;
          isValid = false;
        }
      }

      if (this.dataObj.welderId) {
        if (!this.isValidWelderId(this.dataObj.welderId)) {
          this.error.welderId = true;
          isValid = false;
        }

        if (!this.skillSets.some(x => x.selected && x.oid == this.welderSkillOid)) {
          isValid = false;
          msg = "Need to select welding skill if welder id is not empty.";
        }
      }

      if (!this.dataObj.terminatedDate) {
        var today = new Date();
        if (this.dataObj.workPermitExpiryDate) {
          let d = this.convertDateStrToDateObj(this.dataObj.workPermitExpiryDate);

          if ((today.getTime() - d.getTime()) > 0) {
            isValid = false;
            msg += "Work permit expiry date can't be passed date. ";
          }
        }
        if (this.dataObj.awshppExpiryDate) {
          let d = this.convertDateStrToDateObj(this.dataObj.awshppExpiryDate);
          if ((today.getTime() - d.getTime()) > 0) {
            isValid = false;
            msg += "AWSHPP expiry date can't be passed date. ";
          }
        }
      }

      this.$forceUpdate();

      if (!isValid) {
        if (!msg) {
          msg = "Please input valid fields.";
        }

        this.showMessage(msg, "warning");
      }
      return isValid;
    },

    changeChkSkillSet() {
      if (this.isNew && this.skillSets.some(x => x.oid == this.welderSkillOid && x.selected)) {
        this.disableWelderId = false;
      }
      else {
        this.disableWelderId = true;
      }
    },

    changeSponsor(val) {
      this.dataObj.sponsorOid = val;

      //Other Sponsor
      if (this.dataObj != -1) {
        this.dataObj.otherSponsor = '';
      }
      this.$forceUpdate();
    },

    getWorksiteSummary() {
      api.regularGet(apiUrl.worker.getWorksiteSummary, { workerOid: this.oid }).then((response) => {
        this.worksiteSummary = response.data;
      });
    },

    getWorkshopSummary() {
      api.regularGet(apiUrl.worker.getWorkshopSummary, { workerOid: this.oid }).then((response) => {
        this.workshopSummary = response.data;
      });
    },

  }
}
</script>
