<template>
  <div>
    <h4>Welder Assessment Consolidation - Test No Detail</h4>

    <div class="mb-2">
      <strong>Welder Details</strong>
    </div>
    <div class="row mb-2">
      <div class="col-md-3">
        NRIC/FIN:
      </div>
      <div class="col-md-3">
        {{nricFin}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3">
        Welder ID:
      </div>
      <div class="col-md-3">
        {{welderId}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3">
        Name:
      </div>
      <div class="col-md-3">
        {{name}}
      </div>
    </div>

    <div>
      <table class="table table-sm">
        <thead class="table-light">
          <tr>
            <th>Test No</th>
            <th>No of Joints</th>
            <th>No of Film (Taken/Rejected)</th>
            <th>Acceptance Ratio (By Film)</th>
            <th>Breakdown of Defects</th>
            <th>Total RT Length (mm)</th>
            <th>Total RT Rejected Length (mm)</th>
            <th>Total Rejection Rate (in %)</th>         
            <th style="width:115px;">Retraining / Endorsement</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in listData" :key="idx">
            <td>{{i.testNumber}}</td>
            <td>{{i.noOfJoints}}</td>
            <td>{{i.noOfFilmsTaken}} / {{i.noOfFilmsRejected}}</td>
            <td>{{i.acceptanceRatio}}</td>
            <td>{{i.breakdownOfDefects}}</td>
            <td>{{i.totalRTLength}}</td>
            <td>{{i.totalRTRejectedLength}}</td>
            <td>{{i.totalRejectionRate.toFixed(1)}}%</td>
            <td>
              <FormSelect :list="actionTypes" value="value" text="text" :existing="i.actionType" @change="i.actionType=$event" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-end">
      <button class="btn btn-success" @click="clickUpdate">Update</button>
    </div>
  </div>
</template>

<script>
import api from '@/common/api';
import apiUrl from '@/common/apiurl';
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';

export default {
  name: 'AssessmentEntry',
  components: {
    ...TableAddons,
    ...Forms,
  },
  data() {
    return {
      nricFin: '',
      welderId: '',
      name: '',
      blockId: '',
      workerOid: 0,
      actionTypes: [],
      listData: [],
      rtReportNo:'',
      rtReportOid:null
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    this.blockId = this.$route.params.blockId;
    this.workerOid = parseInt(this.$route.params.workerOid);
    this.rtReportNo = this.$route.params.reportNo;
    this.rtReportOid = parseInt(this.$route.params.rtReportOid);
    console.log(this.rtReportOid);
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Assessment Entry' }
    ]);

    this.actionTypes.push({ value: '', text: '<Select One>' });
    this.actionTypes.push({ value: 'r', text: 'Retraining' });
    this.actionTypes.push({ value: 'e', text: 'Endorse' });

    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.listData = [];
      api.regularGet(apiUrl.assessment.getAssessmentEntryList, { workerOid: this.workerOid, performanceWeldReportOid: this.rtReportOid }).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.nricFin = response.data.nricFin;
          this.welderId = response.data.welderId;
          this.name = response.data.name;
          this.listData = response.data.testDetails;
          this.listData.forEach(e => {
            e.actionType = '';
          });
        }
      });
    },
    clickUpdate() {
      let postData = {
        blockId: this.blockId,
        workerOid: this.workerOid,
        rtReportNo:this.rtReportNo,
        rtReportOid:this.rtReportOid,
        list: []
      };
      this.listData.forEach(e => {
        if (e.actionType) {
          postData.list.push({
            welderWeldOid: e.welderWeldOid,
            isRetraining: e.actionType == 'r',
            isEndorse: e.actionType == 'e',
            noOfJoints: e.moOfJoints,
            noOfFilmsTaken: e.noOfFilmsTaken,
            noOfFilmsRejected: e.noOfFilmsRejected,
            acceptanceRatio: e.acceptanceRatio,
            nreakdownOfDefects: e.breakdownOfDefects,
            totalRTLength: e.totalRTLength,
            totalRTRejectedLength: e.totalRTRejectedLength,
            totalRejectionRate: e.totalRejectionRate,
          });
        }
      });

      this.showLoadingScreen();
        api.regularPost(apiUrl.assessment.endorsementProcess, postData).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.showMessage("Successfully Updated.", "success");
            this.$router.push({ name: "AssessmentList"});
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });

    }
  }
}
</script>
