<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-8">
        <h4>Radiography Test Report View</h4>
      </div>
      <div class="col-md-4 text-end">
        <button class="btn btn-primary" @click="clickPrint">Print</button>
      </div>
    </div>
    
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Performance Date
      </div>
      <div class="col-md-3">
        {{dataObj.performanceDateDisplay}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Report Type
      </div>
      <div class="col-md-6">
         {{dataObj.reportTypeDisplay}}
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Industry
      </div>
      <div class="col-md-3">
        <span v-if="dataObj.industryName">{{dataObj.industryName}}</span>
        <span v-else>{{dataObj.otherIndustry}}</span>
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Job Category
      </div>
      <div class="col-md-3">
        {{getJobCategoryTextByValue(dataObj.jobCategory)}}
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Location
      </div>
      <div class="col-md-3">
        {{dataObj.siteName}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contractor
      </div>
      <div class="col-md-3">
        {{dataObj.contractorName}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Centre
      </div>
      <div class="col-md-3">
        <span v-if="dataObj.testCentreName">{{dataObj.testCentreName}}</span>
        <span v-else>{{dataObj.otherTestCenter}}</span>
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Report No.
      </div>
      <div class="col-md-3">
        <b>{{dataObj.reportNo}}</b>
      </div>
      <div class="col-md-3 text-secondary">
        Code
      </div>
      <div class="col-md-3">
        {{dataObj.code}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Sensitivity
      </div>
      <div class="col-md-3">
        {{dataObj.sensitivity}}
      </div>
      <div class="col-md-3 text-secondary">
        I.Q.I
      </div>
      <div class="col-md-3">
        {{dataObj.iqi}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Source
      </div>
      <div class="col-md-3">
        {{dataObj.source}}
      </div>
      <div class="col-md-3 text-secondary">
        Isotope/KV
      </div>
      <div class="col-md-3">
        {{dataObj.isotape}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Curies/MA
      </div>
      <div class="col-md-3">
        {{dataObj.curies}}
      </div>
      <div class="col-md-3 text-secondary">
        Film
      </div>
      <div class="col-md-3">
        {{dataObj.film}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Screen
      </div>
      <div class="col-md-3">
        {{dataObj.screen}}
      </div>
      <div class="col-md-3 text-secondary">
        Density
      </div>
      <div class="col-md-3">
        {{dataObj.density}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Developing
      </div>
      <div class="col-md-3">
        {{dataObj.developing}}
      </div>
      <div class="col-md-3 text-secondary">
        Exposure
      </div>
      <div class="col-md-3">
        {{dataObj.exposure}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Technique
      </div>
      <div class="col-md-3">
        {{dataObj.technique}}
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Radiographer
      </div>
      <div class="col-md-3">
        {{dataObj.radiographer}}
      </div>
      <div class="col-md-3 text-secondary">
        Interpreter
      </div>
      <div class="col-md-3">
        {{dataObj.interpreter}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Q.C. Inspector
      </div>
      <div class="col-md-3">
        {{dataObj.inspector}}
      </div>
    </div>

    <hr />

    <h5>Welders</h5>
    <div class="card mb-2" v-for="(w,i) in dataObj.welders" :key="i">
      <div class="card-body">
        <button class="btn btn-primary btn-sm mb-2" @click="clickCheckAssignmentDetails(w.welderId)">Check Assignment Details</button>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Welder Ref
          </div>
          <div class="col-md-3">
            {{w.welderId}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Name
          </div>
          <div class="col-md-3">
            {{w.name}}
          </div>
          <div class="col-md-3 text-secondary">
            NRIC/FIN
          </div>
          <div class="col-md-3">
            {{w.nricFin}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Test No
          </div>
          <div class="col-md-3">
            {{w.testNumber}}
          </div>
          <div class="col-md-3 text-secondary">
            Joint Type
          </div>
          <div class="col-md-3">
            {{w.jointType}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Weld Process
          </div>
          <div class="col-md-3">
            {{w.process}}
          </div>
          <div class="col-md-3 text-secondary">
            Position
          </div>
          <div class="col-md-3">
            {{w.testPosition}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Weld/Joint ID
          </div>
          <div class="col-md-3">
            {{w.weldId}}
          </div>
          <div class="col-md-3 text-secondary">
            S.F.D
          </div>
          <div class="col-md-3">
            {{w.sfd}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Wall Thicknes
          </div>
          <div class="col-md-3">
            {{w.wallThickness}}
          </div>
          <div v-if="isShowRadiographyLength(w.testNumber)" class="col-md-3 text-secondary">
            Radiography Length
          </div>
          <div v-if="isShowRadiographyLength(w.testNumber)" class="col-md-3">
            {{w.radiographyLength}} mm
          </div>
          <div v-if="!isShowRadiographyLength(w.testNumber)" class="col-md-3 text-secondary">
            Diameter
          </div>
          <div v-if="!isShowRadiographyLength(w.testNumber)" class="col-md-3">
            {{w.diameter}} mm
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Material
          </div>
          <div class="col-md-3">
            {{w.material}}
          </div>
          <div class="col-md-3 text-secondary">
            NPS
          </div>
          <div class="col-md-3">
            {{w.nps}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Length of Plate/Steel Structure
          </div>
          <div class="col-md-3">
            {{w.plateSize}}
          </div>
          <div class="col-md-3 text-secondary">
            Equip/Line No
          </div>
          <div class="col-md-3">
            {{w.equipNo}}
          </div>
        </div>

        <div class="mb-2 mt-2 text-secondary">
          Sections
        </div>
        <div style="overflow-x: auto;">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Section No.</th>
              <th>Discontinuity Size</th>
              <th>Result</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s,i) in w.sections" :key="i">
              <td>{{s.sectionNo}}</td>
              <td>{{s.rejectLength}} mm</td>
              <td>{{getSectionResultTextByValue(s.result)}}</td>
              <td>
                <span v-for="(r,idx) in s.remarks" :key="idx">
                  {{r.performanceRemarkShort}}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        


      </div>
    </div>

    <div class="text-end">
      <button class="btn btn-secondary" @click="clickBack">Back</button>
    </div>

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'ViewRTReport',
  components: {
  },
  data() {
    return {
      oid: 0,
      dataObj: {
        welders: []
      },
      isPerformanceIndustry: true
    }
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }

  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Performance Welder Report', url: '/rtreportlist' }, { name: 'View' }
    ]);

    if (this.oid > 0) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      api.regularGet(apiUrl.performance.getRTReportByOid, { oid: this.oid }).then(res => {
        this.dataObj = res.data;
        if (this.dataObj.reportType != this.$data._rtReportType.performanceWorksite.value) {
          this.isPerformanceIndustry = false;
        }
      });
    },
    clickBack() {
      history.back();
    },
    clickPrint() {
      this.showLoadingScreen();
      api.downloadFile(apiUrl.pdf.rtReport, { oid: this.oid }, 'RTReport.pdf', () => {
        this.hideLoadingScreen();
        this.showMessage('Downloaded successfully.', 'success');
      });
    },

    isShowRadiographyLength(testNo) {
      if (testNo && testNo[0].toUpperCase() == 'P') {
        return false;
      }
      else {
        return true;
      }
    },

    clickCheckAssignmentDetails(welderId) {
      let routeData = this.$router.resolve({ name: 'CheckWelderSummary', params: { welderId: welderId } });
      window.open(routeData.href, '_blank');
    },

  }
}
</script>
