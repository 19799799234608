<template>
  <v-date-picker
    v-model="selectedDate"
    :popover="{ visibility: readOnly ? 'hidden' : 'click' }"
    :masks="{ input: ['DD/MM/YYYY'] }"
    :available-dates="availableDates"
  >
    <template #default="{ inputValue, inputEvents, togglePopover }">
      <div class="d-flex flex-row">
        <div class="input-group" >
          <input
            class="form-control" :class="cssClass"
            :value="inputValue"
            v-on="inputEvents"
            isExpended="true"
            :disabled="disabled"
          />
          <span v-if="disabled" class="input-group-text" style="cursor: pointer;">
            <i class="bi bi-calendar"></i>
          </span>
          <span v-else class="input-group-text" style="cursor: pointer;" @click="togglePopover({ placement: 'bottom', transition: 'none', showDelay: 0, hideDelay: 0 })">
            <i class="bi bi-calendar"></i>
          </span>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    existing: Date,
    cssClass: [String, Boolean],
    disabled: Boolean,
    availableDates: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedDate: null,
      readOnly: false,
    };
  },
  watch: {
    existing: {
      immediate: true,
      handler(value) {
        this.selectedDate = value;
      },
    },
    selectedDate(value) {
      this.$emit("updatedate", value);
    },
  },
  mounted() {
    this.loadDate();
  },
  methods: {
    loadDate() {
      if (this.existing === undefined) this.selectedDate = new Date();
    },
  },
};
</script>
