<template>
  <div>
    <nav class="navbar fixed-top navbar-expand-md navbar-light shadow" style="padding-top:0px;padding-bottom:0px;background-color: white;">
        <span class="navbar-brand" style="padding-left:55px;">
          <router-link to="/" class="nav-link" >
            <img src="/img/PrimosLogo.png" style="width:165px;" />
          </router-link>
        </span>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarCollapse" class="collapse navbar-collapse " style="font-size: 17px; color: black; padding-right: 55px;">
          <ul class="navbar-nav me-auto mb-2 mb-md-0 unauthorized-nav" style="margin: auto;">
            <li v-for="(l,i) in this.homeMenus" class="nav-item" :key="i" >
              <a v-if="!l.isHide" :href="'/pages/' + l.name" class="nav-link navbar-hover" style="">{{l.menuDisplay}}</a>
            </li>
          </ul>
          <ul class="navbar-nav unauthorized-nav-right">
            <li class="nav-item" >
              <a target="_blank" href="https://sws.org.sg/" class="nav-link" >
                <img src="/img/SWS-Logo-with-name.png" style="width: 30%; float: right;" />
              </a>
            </li>
            <li class="nav-item">
              <router-link to="/login" class="nav-link navbar-hover">LOGIN</router-link>
            </li>
            <li class="nav-item dropdown">
             <a class="nav-link dropdown-toggle navbar-hover" href="#" id="dropdown01" data-bs-toggle="dropdown"
               aria-expanded="false">REGISTER</a>
             <ul class="dropdown-menu" aria-labelledby="dropdown01">
               <li><router-link to="/regIndustry" class="dropdown-item">INDUSTRY</router-link></li>
               <li><router-link to="/regContractor" class="dropdown-item">CONTRACTOR</router-link></li>
               <li><router-link to="/regTestCenter" class="dropdown-item">TEST CENTRE</router-link></li>
               <li><router-link to="/regWeldingInspector" class="dropdown-item">WELDING INSPECTOR</router-link></li>
             </ul>
           </li>
          </ul>
        </div>
    </nav>
    <div style="margin-top: 45px;">
      <slot></slot>
    </div>
    <footer class="container small text-secondary" >
      <br><br>
      <div class="mb-2" style="text-align: center; color: darkgrey;">
        © 2003 - 2024. Primos Singapore Pte. Ltd. All rights reserved.<br/> 
        Terms & Conditions designed and developed by <a href="http://www.imos.com.sg/" target="_blank">iMOS Holdings Pte. Ltd</a>. 
      </div>
      <br><br>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'NonAuthorizeLayout',
  data() {
    return {
      homeMenus: []
    }
  },
  mounted() {
    this.homeMenus = window.homePagesMenu;
  }
}
</script>

<style scoped>
  .navbar-hover:hover {
    background-color: lightblue;
    border-radius: 3px;
  }

  .bg-light {
    background-color: white;
  }

  footer {
    background-color: #212529; 
    margin: 0px; max-width: 100%; 
    padding: 20px; 
    position: initial;
    bottom: 0;
  }

  .nav-item {
    margin-left: 10px;
    margin-right: 10px;
  }

  .navbar-nav {
    font-size: 20px;
  }

  .unauthorized-nav {
    margin: auto;
  }
</style>

