<template>
  <div id="app">
    <div v-if="isNoNaviPath">
      <router-view />
    </div>
    <div v-else-if="isNoAuthorizePath">
      <NonAuthorizeLayout>
        <router-view />
      </NonAuthorizeLayout>
    </div>
    <div v-else style="display:flex;">
      <SideBarNav class="my-sidebar my-animate"
        :class="getSideBarClass" 
        :closeSideBar="closeSideBar"
        :isMobileSize="isMobileSize" :isSideBarClose="isSideBarClose" />

      <!-- <div class="my-body-margin my-animate" :class="isSideBarClose || isMobileSize ?'my-body-nomargin':''"> -->
      <div class="my-animate" style="width:100%;max-width:100%;overflow:hidden;">
        <HeaderBar :toggleSideBar="toggleSideBar" />
        <div class="body-content">
          <div class="card">
            <div class="card-body">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDiaglog :showDiaglog="$store.state.confirmDiaglog.show" />
    <BlockUI v-if="$store.state.loadingstatus=='show'" :message="'Processing...'" 
    html='<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>'>
    </BlockUI>

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/assets/custom.css"></style>

<script>
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import ConfirmDiaglog from '@/components/custom/ConfirmDiaglog'

import SideBarNav from '@/components/layout/SideBarNav';
import HeaderBar from '@/components/layout/HeaderBar';
import NonAuthorizeLayout from '@/components/layout/NonAuthorizeLayout';

import api from "@/common/api";
import "vue-select/dist/vue-select.css";

//import nav from '@/router/nav';

export default {
  name: 'App',
  components: {
    SideBarNav,
    HeaderBar,
    ConfirmDiaglog,
    NonAuthorizeLayout
  },
  data() {
    return {
      isSideBarClose: false,
      isMobileSize: false,
      sideBarClass: '',
      isNoAuthorizePath: false,
      isNoNaviPath: false,
    }
  },
  beforeMount() {
    this.isNoAuthorizePath = this.$route.matched[0].props.default.noAuthorize;
    this.isNoNaviPath = this.$route.matched[0].props.default.noNavi;

    if (!this.isNoAuthorizePath)
      api.checkLogin(this);
  },
  mounted() {

    window.addEventListener('resize', this.handleScreenWidth);
    this.handleScreenWidth();
  },
  computed: {
    getSideBarClass() {
      let className = '';
      if (this.isMobileSize)
        className += ' my-sidebar-mobile';
      if (this.isSideBarClose)
        className += ' my-sidebar-close';
      return className;
    },
  },
  methods: {
    toggleSideBar() {
      this.isSideBarClose = !this.isSideBarClose;
    },
    closeSideBar() {
      this.isSideBarClose = true;
    },
    handleScreenWidth() {
      if (window.innerWidth <= 1100) {
        this.isSideBarClose = true;
        this.isMobileSize = true;
      }
      else {
        this.isSideBarClose = false;
        this.isMobileSize = false;
      }
    }
  }
}
</script>
