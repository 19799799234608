<template>
<div class="container">
  <br>
  <br>
  <div v-for="(i, idx) in tableParam.listData" :key="idx" >
    <div v-if="idx == 0">
      <strong>{{i.category}}</strong>
    </div>
    <div v-else-if="idx > 0 && i.category != tableParam.listData[idx-1].category">
      <br>
      <strong>{{i.category}}</strong>
    </div>
    <li>
    <button v-if="i.oid != null" class="btn btn-link" title="download" @click="clickDownloadFile(i.oid, i.fileName)" style="padding: 0px;">
      {{i.fileName}}
    </button>
    </li>
  </div>
</div>
</template>

<!-- <button class="btn btn-primary btn-xs ms-2" @click="clickPrintInvoice">Print Invoice</button> -->

<script>
import api from "@/common/api"
import apiUrl from '@/common/apiurl'

export default {
  name: 'Link',
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },

  mounted() {
    this.callTable();
  },

  methods: {
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.commonFiles.getUnauthorizedList, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDownloadFile(oid, fileName) {
      api.downloadFile(apiUrl.commonFiles.downloadFile, { oid }, fileName, () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
  }
}
</script>
