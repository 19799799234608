import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import common from './common/common'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VCalendar from 'v-calendar';
import BlockUI from 'vue-blockui';
import vSelect from "vue-select";

Vue.config.productionTip = false

Vue.use(VueToast, {
  //global options
});
Vue.use(VCalendar);
Vue.use(BlockUI);
Vue.mixin(common);
Vue.component("v-select", vSelect);

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
