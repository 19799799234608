<template>
    <div style="background: #f2f2f2; min-height: 100vh">
        <div class="container">
            <div class="row">
                <div class="offset-md-2 col-md-10">
                <div class="card" style="margin-top: 5%">
                    <div class="card-body">
                        <h5 class="card-title">PRiMOS Registration</h5>
                        <h6 class="card-subtitle mb-2 text-muted">Choose A Role</h6>
                        <h6><i class="bi bi-app"></i> <router-link to="/regIndustry">INDUSTRY</router-link> (Plant Owners. e.g. Singapore Refining Co., Exxon Mobil, Invista, Shell.)</h6>
                        <h6><i class="bi bi-app"></i> <router-link to="/regContractor">CONTRACTOR</router-link> (Service Providers. e.g. Rotary Engrg Ltd, PEC Ltd, Mun Siong Engrg Pte Ltd.)</h6>
                        <h6><i class="bi bi-app"></i> <router-link to="/regTestCenter">TEST CENTRE</router-link></h6>
                        <h6><i class="bi bi-app"></i> <router-link to="/regWeldingInspector">WELDING INSPECTOR</router-link></h6>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "RegisterMain",
  components: {
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>