<style scoped>
  .text-secondary{
    font-weight: 500;
    color: grey !important;
    font-size: 13px;
  }
  .text-bolder{
    font-size: 13px;
  }
  .mb-2{
    margin-bottom: -0.2rem!important;
  }
  .mb-1{
    margin-bottom: -0.2rem!important;
  }
</style>
<template>

  <div class="my-main-content" style="font-size:10px;padding:0px 5px;">
    
    <div>
      <div class="row">
        <div class="col-6">
          <div>
        <img src="/img/PrimosLogo.png" style="width:165px;" />
      </div>
      <div class="mb-1">
        Process Industry Manpower Optimisation System
      </div>
        </div>
        <div class="col-6">
          <img src="/img/SWS-Logo-with-name.png" style="width:115px;float:right;" />
        </div>
      </div>
      
      <div class="row" style="border:1px solid #dadada; border-bottom:none;border-top: 1px solid rgb(218, 218, 218);margin-top: 10px;">
        <div class="col-7">
          <span style="font-size: 15px;"><b>Welder Test Result Report</b></span>
        </div>
        <div class="col-5 text-end">
          <div><span class="text-secondary">Cert No:</span> <span class="text-bolder">{{dataObj.certNum}}</span></div>
          <div><span class="text-secondary">Date of issue:</span> <span class="text-bolder">{{dataObj.createdDateDisplay}}</span></div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Application No.:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.applicationNo}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Application Date:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.applicationDate}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Test Centre:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.testCentre}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Contractor:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.contractor}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Skill Test:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.skill}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Test Date:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.testDateDisplay}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Inspector:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.inspector}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Worker Name:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.workerName}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          NRIC/FIN:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.nricFin}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Work Permit:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.workPermit}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Welder ID:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.welderId}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Test No.:
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.testNumber}}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3 text-secondary">
          Work Exp (Yrs):
        </div>
        <div class="col-6 text-bolder">
          {{dataObj.yearExperience}}
        </div>
      </div>
      <div style="border:1px solid #dadada; border-bottom:none;border-bottom: 1px solid rgb(218, 218, 218);" class="row mb-1">
        <div class="col-3 text-secondary" style="color:red;">
          Final Result:
        </div>
        <div class="col-6 text-bolder">
          <span v-if="dataObj.result==$data._workerSkillTestResult.pass.value" class="text-bolder">
            <b>{{$data._workerSkillTestResult.pass.text}}</b>
          </span>
          <span v-else-if="dataObj.result==$data._workerSkillTestResult.fail.value" class="text-bolder">
            <b>{{$data._workerSkillTestResult.fail.text}}</b>
          </span>
          <span v-else-if="dataObj.result==$data._workerSkillTestResult.absent.value" class="text-bolder">
            <b>{{$data._workerSkillTestResult.absent.text}}</b>
          </span>
          <span v-else>
            <b>{{dataObj.result}}</b>
          </span>

          <button v-if="dataObj.result==$data._workerSkillTestResult.pass.value && !isContractor"
            class="btn btn-primary btn-xs ms-2" @click="clickPrintCert">Print Cert</button>
        </div>
      </div>
      <div class="text-center mb-2 text-bolder"><b>WELDING DATA</b></div>

      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Welding Process(es)
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.weldingProcess}}
        </div>
        <div class="col-3 text-secondary">
          Welding Position
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.testPosition}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Welding Progression
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.progression}}
        </div>
        <div class="col-3 text-secondary">
          Type of Backing
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.backing}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Welding Voltage/Polarity
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.voltage}}
        </div>
        <div class="col-3 text-secondary">
          Welding Current
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.weldingCurrent}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Welding Voltage/Polarity(2)
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.voltage2}}
        </div>
        <div class="col-3 text-secondary">
          Welding Current(2)
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.weldingCurrent2}}
        </div>
      </div>
      <div style="border:1px solid #dadada; border-bottom:none; padding:8px;padding-bottom:0px; margin-left:-8px; margin-right:-8px;">
        <div class="row mb-2">
          <div class="col-3 text-secondary">
            Electrode Type/Size
          </div>
          <div class="col-5 text-bolder">
            {{dataObj.electrode}}
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-7 text-secondary">
                F No.
              </div>
              <div class="col-5 text-bolder">
                {{dataObj.electrodeFno}}
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-7 text-secondary">
                A No.
              </div>
              <div class="col-5 text-bolder">
                {{dataObj.electrodeAno}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 text-secondary">
            Electrode Type/Size(2)
          </div>
          <div class="col-5 text-bolder">
            {{dataObj.electrode2}}
          </div>
        </div>
      </div>
      <div style="border:1px solid #dadada; padding:8px;padding-bottom:0px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
        <div class="row mb-2">
          <div class="col-3 text-secondary">
            Filler Material Type/Size
          </div>
          <div class="col-5 text-bolder">
            {{dataObj.fillerMaterial}}
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-7 text-secondary">
                F No.
              </div>
              <div class="col-5 text-bolder">
                {{dataObj.fillerMaterialFno}}
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-7 text-secondary">
                A No.
              </div>
              <div class="col-5 text-bolder">
                {{dataObj.fillerMaterialAno}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 text-secondary">
            Filler Material Type/Size(2)
          </div>
          <div class="col-5 text-bolder">
            {{dataObj.fillerMaterial2}}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Electrode Covering/Flux
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.electrodeCovering}}
        </div>
        <div class="col-3 text-secondary">

        </div>
        <div class="col-3">

        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Sheilding Gas Composition
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.sheildingGasComposition}}
        </div>
        <div class="col-3 text-secondary">
          Flow Rate
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.sheildingGasCompositionFlowRate}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Backing Gas Composition
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.backingGasComposition}}
        </div>
        <div class="col-3 text-secondary">
          Flow Rate
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.backingGasCompositionFlowRate}}
        </div>
      </div>
      <div style="border:1px solid #dadada; padding:8px;padding-bottom:0px; margin-left:-8px; margin-right:-8px;margin-bottom:5px;">
        <div class="row mb-2">
          <div class="col-3 text-secondary">
            Base Material From
          </div>
          <div class="col-3 text-bolder">
            {{dataObj.baseMaterialFrom}}
          </div>
          <div class="col-3 text-secondary">
            Base Material To
          </div>
          <div class="col-3 text-bolder">
            {{dataObj.baseMaterialTo}}
          </div>
        </div>
        <div class="row">
          <div class="col-3 text-secondary">
            Base Material From P No.
          </div>
          <div class="col-3 text-bolder">
            {{dataObj.baseMaterialPnoFrom}}
          </div>
          <div class="col-3 text-secondary">
            Base Material To P No.
          </div>
          <div class="col-3 text-bolder">
            {{dataObj.baseMaterialPnoTo}}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Base Material Thickness
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.baseMaterialThickness}}
        </div>
        <div class="col-3 text-secondary">

        </div>
        <div class="col-3">

        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Weld Thickness
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.weldThickness}}
        </div>
        <div class="col-3 text-secondary">
          Pipe Diameter
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.pipeDiameter}}
        </div>
      </div>
      <div class="row mb-2" style="border-bottom:1px solid #dadada;padding-bottom:5px;">
        <div class="col-3 text-secondary">
          Weld Thickness(2)
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.weldThickness2}}
        </div>
        <div class="col-3 text-secondary">
          Plate Size
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.plateSize}}
        </div>
      </div>
      
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Visual Test
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.visualResultDisplay}}
        </div>
        <div class="col-3 text-secondary">
          Radiograph Test
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.radioResultDisplay}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          RT Reports
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.rt}}
        </div>
        <div class="col-3 text-secondary">
          Mechanical Test
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.mechanicalResultDisplay}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Test Witnessed By
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.witness}}
        </div>
        <div class="col-3 text-secondary">
          Date of Test
        </div>
        <div class="col-3 text-bolder">
          <span v-if="dataObj.dateTestDisplay">
            {{dataObj.dateTestDisplay}}
          </span>
          <span v-else>
            {{dataObj.dateTest}}
          </span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-3 text-secondary">
          Officer Title
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.officerTitle}}
        </div>
        <div class="col-3 text-secondary">
          Officer Name
        </div>
        <div class="col-3 text-bolder">
          {{dataObj.officerName}}
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
hr {
  margin: 10px 0px;
}
@media print {
}
</style>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';

export default {
  name: 'ViewTestApplicationResult',
  components: {
  },
  data() {
    return {
      workerOid: 0,
      skillOid: 0,
      testDate: '',
      testNumber: '',
      applicationOid: 0,
      candidateOid: 0,

      weldingPositions: [],
      visualTests: [],
      radiographTests: [],
      mechanicalTests: [],
      dateOfTest: null,
      isContractor: false,
      dataObj: {},
      error: {},
    }
  },
  beforeMount() {

    this.workerOid = parseInt(this.$route.params.workerOid);
    this.skillOid = parseInt(this.$route.params.skillOid);
    this.testDate = this.$route.params.testDate;
    this.testNumber = this.$route.params.testNumber;
    this.applicationOid = parseInt(this.$route.params.applicationOid);
    this.candidateOid = parseInt(this.$route.params.candidateOid);

    let userType = this.getLoggedInUser().userGroupType;
    if (userType == this.$data._userGroupType.contractor.value) {
      this.isContractor = true;
      console.log(2)
    } else {
      console.log(userType)
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      let url = apiUrl.testApplication.getTestCandidateDetails;
      let param = {
        workerOid: this.workerOid,
        skillOid: this.skillOid,
        testDate: this.testDate,
        testNumber: this.testNumber,
        applicationOid: this.applicationOid,
        candidateOid: this.candidateOid
      };
      api.regularGet(url, param).then((response) => {
        this.dataObj = response.data;
        if (!this.dataObj.witness) {
          this.dataObj.witness = this.dataObj.inspector;
        }
        if (this.dataObj.dateTest) {
          this.dateOfTest = this.convertDateStrToDateObj(this.dataObj.dateTest);
        }
        setTimeout(() => {
          window.print();
        }, 2000)
      });
    },
    clickBack() {
      history.back();
    },
    clickPrintCert() {
      this.showLoadingScreen();
      api.downloadFile(apiUrl.pdf.testApplicationResultPrintCert, { oid: this.dataObj.oid }, 'TestApplicationResultCert.pdf', () => {
        this.hideLoadingScreen();
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
  }
}
</script>
