<template>
  <div>
    <div class="row">
      <div class="col-lg-3 mb-2">
        <input type="text" class="form-control" @keyup="onSearchSubmit" placeholder="Category/Message/User" />
      </div>
      <div class="col-lg-6 mb-2">
        <div class="float-end">
          <DateRangePicker @updatedaterange="dateRangeChange"></DateRangePicker>
        </div>
      </div>
      <div class="col-lg-3 mb-2">        
        <select class="form-select" @change="categoryChange">
          <option v-for="(i,idx) in categories" :key="idx" :value="i">{{i}}</option>
        </select>
      </div> 
    </div>

    <div>
      <!-- <TableFilter @changeText="changeTblFilter" /> -->
      <table class="table table-hover table-sm">
        <thead class="table-light">
          <tr>
            <SortColumn @click="sortColumn" :sortBy="'activityDate'" :colGroup="'tbl1'">Date Time</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'userId'" :colGroup="'tbl1'">User</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'category'" :colGroup="'tbl1'">Category</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'code'" :colGroup="'tbl1'">Code</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'message'" :colGroup="'tbl1'">Message</SortColumn>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td>{{getDateTime(item.activityDate)}}</td>
            <td>{{item.userId}}</td>
            <td>{{item.category}}</td>
            <td>{{item.code}}</td>
            <td>{{item.message}}</td>
          </tr>
        </tbody>
      </table>
      <PaginationForAudit
        :totalRow="table.rowtotal"
        :startRow="table.startrow"
        :endRow="table.endrow"
        :currentPage="table.currentpage"
        :totalPage="table.pagescount"
        :rowPerPage="table.rowcount"
        @updatePaging="updatePaging"
       />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import * as TableAddons from "@/components/custom/table_addons";
import api from "@/common/api";
import * as Pickers from "@/components/custom/pickers";

export default {
  name: "ActivityLog",
  components: {
    ...TableAddons,
    ...Pickers
  },
  data() {
    return {
      categories: ['All', 'Security', 'Settings', 'Tenant Settings', 'Users', 'Tenants', 'Tenant Locations', 'Roles'],
      show: false,
      items: [],//[{ activityDate: "134", category: "cat test", code: "C235", message: "Message test", userId: "Admin" }], // default test item
      table: {
        timezone: api.TIMEZONE,
        startdate: null,
        enddate: null,
        currentpage: 1,
        filter: '',
        pagescount: 1,
        isasc: false,
        data: null,
        searchtext: '',
        rowcount: 10,
        rowtotal: 0,
        startrow: 0,
        endrow: 0
      },
      masks: {
        input: "DD/MM/YYYY",
      },
      collapseDuration: 0,
    };
  },

  destroyed() {
    this.clearBreadcrumb();
  },

  mounted(){
    this.setBreadcrumb([
      { name: 'Activity Log' }
    ]);
  },

  methods: {
    dateRangeChange(dateRange) {
      this.table.startdate = this.convertDateObjToDateStr(dateRange.start);
      this.table.enddate = this.convertDateObjToDateStr(dateRange.end);
      this.callTable();
    },
    onSearchSubmit: _.debounce(function (e) {
      this.table.searchtext = e.target.value;
      this.callTable();
    }, 500),

    categoryChange(e) {
      this.table.filter = e.target.value;
      this.callTable();
    },

    callTable() {
      this.table.data = null;
      api.getActivityLog(this.table, this.$store.state.token).then((response) => {
        if (response.status == 200) {
          this.table = response.data;
          this.items = this.table.data;
          this.show = true;
        }
      });
    },

    getDateTime(date) {
      return api.getFormattedDateTime(date);
    },

    sortColumn(sortyBy, sortDir) {
      this.table.orderby = sortyBy;
      this.table.isasc = sortDir == 'asc' ? true : false;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.table.currentpage = paramObj.currentPage;
      this.table.rowcount = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text) {
      this.table.searchtext = text;
      this.callTable();
    },
  },

  beforeMount() {
    this.table.filter = this.categories[0];
  },
};
</script>