<template>
  <div>

    <div class="card">
      <div class="card-body">
        <h4>Corporate Information</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3" v-show="type == 'T'">
              <div class="col-sm-4 text-secondary">SAC Certificate Number:</div>
              <div class="col-sm-8">
                <FormSelect
                  :cssClass="error.saccertNumber && 'control-error'"
                  :list="sac"
                  value="code"
                  text="value"
                  :existing="dataObj.saccertNumber"
                  @change="dataObj.saccertNumber = $event"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Corporate Name:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.corpName && 'control-error'"
                  v-model="dataObj.corpName"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Building Name:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.buildingName && 'control-error'"
                  v-model="dataObj.buildingName"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Block No.:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.blkNo && 'control-error'"
                  v-model="dataObj.blkNo"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Unit No.:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.unitNo && 'control-error'"
                  v-model="dataObj.unitNo"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Address:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.address1 && 'control-error'"
                  v-model="dataObj.address1"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Address 2:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.address2 && 'control-error'"
                  v-model="dataObj.address2"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Country:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <FormSelect
                  :list="countries"
                  value="oid"
                  text="name"
                  :cssClass="error.countryOid && 'control-error'"
                  :existing="dataObj.countryOid"
                  @change="dataObj.countryOid = $event"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Postcode:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.postalCode && 'control-error'"
                  v-model="dataObj.postalCode"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Administrator Name:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.adminContact && 'control-error'"
                  v-model="dataObj.adminContact"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Administrator Designation:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :class="error.adminDesignation && 'control-error'"
                  v-model="dataObj.adminDesignation"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Telephone No.:</div>
              <div class="col-sm-6">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :class="error.telephone && 'control-error'"
                    v-model="dataObj.telephone"
                  />
                  <span class="input-group-text">Ext</span>
                  <input
                    type="text"
                    class="form-control"
                    :class="error.ext && 'control-error'"
                    v-model="dataObj.ext"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Fax No.:</div>
              <div class="col-sm-6">
                <input type="text" class="form-control" v-model="dataObj.fax" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Email:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="dataObj.corporateEmail"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Mobile Contact No.:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="dataObj.mobileNo"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row"  v-show="this.type == 'C'">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-4">
                Ownership(Contractors Only)<span class="text-danger">*:</span>
              </div>
              <div class="col-sm-8">
                <FormSelect
                  :cssClass="error.ownershipOid && 'control-error'"
                  :list="ownerships"
                  value="oid"
                  text="value"
                  :existing="dataObj.ownershipOid"
                  @change="dataObj.ownershipOid = $event"
                />
              </div>
              <h6 class="card-subtitle mb-2 text-muted">
                Principal Activities <span class="text-danger">*</span>
              </h6>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="principaleActivities.plantConstruction"
                  /> Plant Construction</label>
                </div>
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="principaleActivities.plantMaintenance"
                  /> Plant Maintenance</label>
                </div>
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="principaleActivities.processDesign"
                  /> Process Design </label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="principaleActivities.buildingConstruction"
                  /> Building Construction </label>
                  
                </div>
                <div class="col-sm-4">
                  <label> <input
                    type="checkbox"
                    v-model="principaleActivities.equipmentInstallation"
                  /> Equipment Installation </label>
                 
                </div>
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="principaleActivities.shopFabrication"
                  /> Shop Fabrication</label>
                  
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-3">Others, please specify</div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    :class="error.others && 'control-error'"
                    v-model="principaleActivities.others"
                  />
                </div>
              </div>
              <h6 class="card-subtitle mb-2 text-muted">
                Role of Contractor(Contactors only) 1 or more selections:
              </h6>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="roleOfContractor.turnkeyContractor"
                  /> Turnkey Contractor</label>
                </div>
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="roleOfContractor.mainContractor"
                  /> Main Contractor</label>
                </div>
                <div class="col-sm-4">
                  <label><input
                    type="checkbox"
                    v-model="roleOfContractor.subContractor"
                  /> Sub Contractor</label>
                </div>
              </div>
              <h6 class="card-subtitle mb-2 text-muted">
                Employment Strength (Contractors Only)
              </h6>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Total company strength<span class="text-danger">*</span>:
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  class="form-control"
                  v-model="dataObj.companyStrength"
                  :class="error.companyStrength && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                No. of management staff:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  class="form-control"
                  v-model="dataObj.managementStaff"
                  :class="error.managementStaff && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                No. of foreigner:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  class="form-control"
                  v-model="dataObj.foreignerWorker"
                  :class="error.foreignerWorker && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                No. of local:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  class="form-control"
                  v-model="dataObj.localWorker"
                  :class="error.localWorker && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Training budget(%)<span class="text-danger">*</span>:
              </div>
              <div class="col-sm-6">
                <input
                  type="number"
                  class="form-control"
                  v-model="dataObj.trainingBudget"
                  :class="error.trainingBudget && 'control-error'"
                />
              </div>
            </div>
          </div>
          <hr />
        </div>

        

        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                SWS Member Name:<span v-if="type == $data._corporateType.testCenter.value" class="text-danger">*</span>
              </div>
              <div class="col-sm-6"> 
                <v-select label="company" :class="error.swsoid && 'control-error'" v-model="selectedSws" :options="swsMembers" @input="$event==null?dataObj.swsoid=null:dataObj.swsoid = $event.oid"></v-select>
              </div>
            </div>
          </div>
        </div>

        <div v-show="isGIRO">
          <hr />
          <h4>GIRO Information</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">Name of Financial Institution:</div>
                <div class="col-sm-6">
                  <FormSelect
                    :list="banks"
                    value="oid"
                    text="name"
                    :existing="dataObj.giroTemp.bankOid"
                    @change="changeBank"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">Branch:</div>
                <div class="col-sm-6">
                  <FormSelect
                    :list="branches"
                    value="oid"
                    text="name"
                    :existing="dataObj.giroTemp.branchCodeOid"
                    @change="dataObj.giroTemp.branchCodeOid = $event"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">Account Number:</div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.giroTemp.bankAccountNum"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">Account Holder Name:</div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.giroTemp.name"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6 text-secondary">Account Holder Contact (Tel/Fax):</div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.giroTemp.telephone"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <hr />

        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6">Status:</div>
              <div class="col-sm-6">
                <FormSelect
                  :list="statuses"
                  value="value"
                  text="text"
                  :existing="dataObj.status"
                  @change="dataObj.status = $event"
                  :cssClass="error.status && 'control-error'"
                />
              </div>
            </div>
          </div>
        </div> -->

        <hr />

        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">
          Cancel
        </button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">
          Activate
        </button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">
          Delete
        </button>
      </div>
    </div>

    <ModalDiaglog
      size="xl"
      :show="showAuditModal"
      :onClose="
        () => {
          showAuditModal = false;
        }
      "
      :hasHeader="false"
      :showSubmitBtn1="false"
    >
      <div v-if="showAuditModal">
        <AuditLog
          :timezone="timezone"
          tablename="Corporate"
          :primarykey="oid"
          :categories="[]"
          :defaultCategory="'All'"
          :basicLogInfoOid="oid"
          :basicLogInfoUrl="basicLogInfoUrl"
        />
      </div>
    </ModalDiaglog>
  </div>
</template>

<script>
import apiUrl from "@/common/apiurl";
import api from "@/common/api";
import AuditLog from "@/components/custom/AuditLog";
import ModalDiaglog from "@/components/custom/ModalDiaglog";
import * as Forms from "@/components/custom/forms";

export default {
  name: "ActivateCorporate",

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms,
  },
  props:{
    _type: String
  },
  data() {
    return {
      selectedSws: {},
      isGIRO:false,
      basicLogInfoUrl: apiUrl.corporate.getBasicLogInfo,
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: { giroTemp: {} },
      timezone: api.TIMEZONE,
      countries: [],
      swsMembers: [],
      ownerships: [],
      banks: [],
      branches: [],
      statuses: [],
      showAuditModal: false,
      parentUrl: "",
      error: {},
      errMessage: "",
      sac: [],
      type: "",
      principaleActivities: {
        plantConstruction: false,
        plantMaintenance: false,
        processDesign: false,
        buildingConstruction: false,
        equipmentInstallation: false,
        shopFabrication: false,
        others: "",
      },
      roleOfContractor: {
        turnkeyContractor: false,
        mainContractor: false,
        subContractor: false,
      },
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {
    let pageName =  'Activate ';
    pageName += this.getCorporateTypeTextByValue(this.$route.params.type);
    let prevLink = {};
    console.log(this.$route.params);
    switch (this.$route.params.type) {
      case this.$data._corporateType.industry.value: { prevLink = { name: this.$data._corporateType.industry.text, url: '/activateindustries' }; break; }
      case this.$data._corporateType.contractor.value: { prevLink = { name: this.$data._corporateType.contractor.text, url: '/activatecontractors' }; break; }
      case this.$data._corporateType.testCenter.value: { prevLink = { name: this.$data._corporateType.testCenter.text, url: '/activatetestcenters' }; break; }
    }
    this.setBreadcrumb([
      prevLink, { name: pageName }
    ]);
    if (this.oid > 0) {
      this.getDropdowns();
      
    }

    this.statuses = [
      {
        value: this.$data._corporateStatus.active.value,
        text: this.$data._corporateStatus.active.text,
      },
      {
        value: this.$data._corporateStatus.suspended.value,
        text: this.$data._corporateStatus.suspended.text,
      },
    ];
  },

  methods: {
    changeBank(e){
      console.log(e);
      this.dataObj.giroTemp.bankOid = e;
      let url = apiUrl.settings.getByParentOid + "/" + e
      api.regularGet(url, null).then((response) => {
        this.branches = response.data;
      });
    },
    getDropdowns() {
      api.regularGet(apiUrl.regIndustry.dropdowns, null).then((response) => {
        this.countries = response.data.countries;
        this.swsMembers = response.data.sws;
        this.banks = response.data.banks;
        this.branches = response.data.branches;
        for (let index = 0; index < response.data.sac.length; index++) {
        let data = response.data.sac[index];
        this.sac.push({"code": data.code,"value": data.value + " [" + data.code + "]"});
      }
        this.ownerships = response.data.ownerships;
        this.getData();
      });
    },
    getData() {
      let url = apiUrl.activatecorporate.getByOid + "/" + this.oid;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.type = this.dataObj.type;
        this.selectedSws = this.swsMembers.find(x => x.oid == this.dataObj.swsoid);
        if(this.type == 'C'){
            this.principaleActivities = this.dataObj.principalActivity;
            this.roleOfContractor = this.dataObj.roleOfContractors;
        }
        
        if(this.dataObj.paymentMethodOid == this.$data._defaultValue.payment_giro){
            this.isGIRO = true;
        }else{
            this.isGIRO = false;
        }
        if(!this.dataObj.giroTemp){
            this.dataObj.giroTemp = {};
        }
            
        
        switch (this.dataObj.type) {
          case this.$data._corporateType.industry.value:
            this.parentUrl = "Activate Industries";
            break;
          case this.$data._corporateType.contractor.value:
            this.parentUrl = "Activate Contractors";
            break;
          case this.$data._corporateType.testCenter.value:
            this.parentUrl = "Activate Test Centers";
            break;
        }
      });
    },
    clickSave() {
      if (!this.validate()) {
        if (this.errMessage) this.showMessage(this.errMessage, "warning");
        return;
      }

      let subUrl = "";
      if (this.isNew) {
        subUrl = apiUrl.activatecorporate.insert;
      } else {
        subUrl = apiUrl.activatecorporate.activate;
      }
      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: this.parentUrl });
        } else if (
          response.status == 400 ||
          response.errorText.response.status == 400
        ) {
          this.showMessage(response.errorText.response.data.message, "error");
        } else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickCancel() {
      this.$router.push({ name: this.parentUrl });
    },
    validate() {
      let isValid = true;
      this.errMessage = "";
      Object.keys(this.error).forEach((k) => (this.error[k] = false));
      if (!this.dataObj.corpName) {
        this.error.corpName = true;
        isValid = false;
        
      }
      if (!this.dataObj.address1) {
        this.error.address1 = true;
        isValid = false;
        
      }
      if (!this.dataObj.countryOid) {
        this.error.countryOid = true;
        isValid = false;
        
      }
      if (!this.dataObj.paymentMethodOid) {
        this.error.paymentMethodOid = true;
        isValid = false;
      } else {
        if (this.dataObj.paymentMethodOid == this.$data._defaultValue.payment_giro) {
          if (!this.dataObj.giroTemp.bankOid) {
            this.error.bankOid = true;
            isValid = false;
          }
          if (!this.dataObj.giroTemp.bankAccountNum) {
            this.error.bankAccountNum = true;
            isValid = false;
          }
          if (!this.dataObj.giroTemp.name) {
            this.error.gironame = true;
            isValid = false;
          }
          if (!this.dataObj.giroTemp.telephone) {
            this.error.girotelephone = true;
            isValid = false;
          }
        }
      }
      if (!this.dataObj.postalCode) {
        this.error.postalCode = true;
        isValid = false;
      }
      if (!this.dataObj.adminContact) {
        this.error.adminContact = true;
        isValid = false;
      }
      if (!this.dataObj.adminDesignation) {
        this.error.adminDesignation = true;
        isValid = false;
      }
      if (!this.dataObj.telephone) {
        this.error.telephone = true;
        isValid = false;
      }
      if (!this.dataObj.corporateEmail) {
        this.error.corporateEmail = true;
        isValid = false;
      }

      if (this.type == "C") {
        console.log(this.dataObj.ownershipOid);
        if (!this.dataObj.ownershipOid) {
          this.error.ownershipOid = true;
          isValid = false;
        }

        if (
          !this.principaleActivities.plantConstruction &&
          !this.principaleActivities.plantMaintenance &&
          !this.principaleActivities.processDesign &&
          !this.principaleActivities.buildingConstruction &&
          !this.principaleActivities.equipmentInstallation &&
          !this.principaleActivities.shopFabrication &&
          !this.principaleActivities.others
        ) {
          this.errMessage += window.message.coroprateregister.principal_blank;
          isValid = false;
        }
        if (
          !this.roleOfContractor.turnkeyContractor &&
          !this.roleOfContractor.mainContractor &&
          !this.roleOfContractor.subContractor
        ) {
          this.errMessage +=
            window.message.coroprateregister.roleOfContractor_blank;
          isValid = false;
        }
        if (!this.dataObj.companyStrength) {
          this.error.companyStrength = true;
          isValid = false;
        }

        if (!this.dataObj.managementStaff) {
          this.error.managementStaff = true;
          isValid = false;
        }
        if (!this.dataObj.foreignerWorker) {
          this.error.foreignerWorker = true;
          isValid = false;
        }
        if (!this.dataObj.localWorker) {
          this.error.localWorker = true;
          isValid = false;
        }
        if (!this.dataObj.trainingBudget) {
          this.error.trainingBudget = true;
          isValid = false;
        }
      }

      if (this.type == "T") {
        if (!this.dataObj.saccertNumber) {
          this.error.saccertNumber = true;
          isValid = false;
        }
      }
       if (!this.dataObj.swsoid && this.type == this.$data._corporateType.testCenter.value) 
       { 
         this.error.swsoid = true; isValid = false; 
       }
      if (!this.dataObj.mobileNo) {
        this.error.mobileNo = true;
        isValid = false;
      }
      this.$forceUpdate();
      console.log(isValid);
      return isValid;
    },
    confirmRemove() {
      this.showConfirmDiaglog({
        show: true,
        message: "Are you sure to remove " + this.dataObj.corpName + "?",
        submitFunction: () => {
          api
            .regularPost(apiUrl.activatecorporate.delete, this.dataObj)
            .then((response) => {
              console.log(response);
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: this.parentUrl });
              } else if (
                response.status == 400 ||
                response.errorText.response.status == 400
              ) {
                this.showMessage(
                  response.errorText.response.data.message,
                  "error"
                );
              } else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
        },
      });
    },
    showAudit() {
      this.showAuditDialog(true);
    },
  },
};
</script>
