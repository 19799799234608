<template>
<div>
  <div>
    <button class="btn btn-primary float-end mb-2" @click="addCommonFile">Add Common Files</button>
  </div>
  <div>
     <TableFilter @changeText="changeTblFilter" />
    <table class="table table-sm">
      <thead>
      <tr>
          <SortColumn @click="sortColumn" sortBy="category" colGroup="tbl1">Category</SortColumn>
          <SortColumn @click="sortColumn" sortBy="fileName" colGroup="tbl1">Filename</SortColumn>
          <SortColumn @click="sortColumn" sortBy="fileSize" colGroup="tbl1">File Size</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th>Uploaded Date</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.category}}</td>
          <td>{{i.fileName}}</td>
          <td>{{i.fileSizeDisplay}}</td>
          <td><span v-if="i.status == true" class="badge bg-success">Active</span>
            <span v-else class="badge bg-danger">Inactive</span></td>
          <td>{{i.createdDate}}</td>
          <td><button class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button></td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'CommonFiles',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Common Files' }
    ]);
    this.callTable();
  },
  methods: {
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.commonFiles.getList, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditCommonFile', params: { id: data.oid } });
    },
    addCommonFile() {
      this.$router.push({ name: 'AddEditCommonFile' });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text) {
      this.tableParam.searchtext = text;
      this.callTable();
    },
  }
}
</script>
