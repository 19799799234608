<template>
<div>
    <div>

    </div>
    <div>
        <div class="card mb-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row mb-2">
                            <div class="col-sm-4">
                                Year
                            </div>
                            <div class="col-sm-6">
                                <FormSelect :list="years" :existing="param.year" value="value" text="text"
                                    @change="changeFilterYear" />
                            </div>
                        </div>
                         <div class="row mb-2">
                            <div class="col-sm-4">
                                Order By:
                            </div>
                            <div class="col-sm-6">
                                <FormSelect :list="orders" :existing="param.order" value="value" text="text"
                                    @change="changeFilterOrder" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row mb-2">
                            <div class="col-sm-4">
                                Month
                            </div>
                            <div class="col-sm-6">
                                <FormSelect :list="months" :existing="param.month" value="value" text="text"
                                    @change="changeFilterMonth" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
                        <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
                            @click="generate">Generate</button>
                            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
                            @click="downloadexpiry">Download</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="card mb-1">
            
            <div class="row" v-if="data.list.length > 0" style="padding-left:10px;">
                <span>Total Number of welders with qualification expiring in {{month}},{{year}}: <b>{{total}}</b></span>
            </div> 
            
            <div class="card-body">
                
                <div>
                    <table class="table table-sm">
                        <thead class="table-light">
                            <tr>
                                <th>NRIC/Fin</th>
                                <th>WP No</th>
                                <th>Welder ID</th>
                                <th>Name</th>
                                <th>Test No.</th>
                                <th>Last Perf. Update</th>
                                <th>Expiry Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(i, idx) in data.list" :key="idx">
                                <td v-if="i.isShow">{{i.workerNricFin}}</td><td v-else></td>
                                <td v-if="i.isShow">{{i.workerWorkPermit}}</td><td v-else></td>
                                <td v-if="i.isShow">{{i.welderId}}</td><td v-else></td>
                                <td v-if="i.isShow">{{i.workerName}}</td><td v-else></td>
                                <td>{{i.testNumber}}</td>
                                <td>{{i.lastPerfDateDisplay}}</td>
                                <td>{{i.expiryDateDisplay}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'WelderQualificationExpiryReport',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      data:{
        list:[]
      },
      years:[],
      months:[],
      param:{},
      month:'',
      year:0,
      total:0,
      orders:[]
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Welder Qualification Expiry Report' }
    ]);
    this.years = [];

    let currentYear = new Date().getFullYear();
    currentYear = currentYear + 1;
    for (let index = 0; index < 6; index++) {
      var year = currentYear-index;
      this.years.push({ value: year, text: year });
    }
    this.months.push({value:0,text:'All'});
    this.months.push({value:1,text:'Jan'});
    this.months.push({value:2,text:'Feb'});
    this.months.push({value:3,text:'Mar'});
    this.months.push({value:4,text:'Apr'});
    this.months.push({value:5,text:'May'});
    this.months.push({value:6,text:'Jun'});
    this.months.push({value:7,text:'Jul'});
    this.months.push({value:8,text:'Aug'});
    this.months.push({value:9,text:'Sep'});
    this.months.push({value:10,text:'Oct'});
    this.months.push({value:11,text:'Nov'});
    this.months.push({value:12,text:'Dec'});

    this.orders.push({value:'nric',text:'NRIC/Fin'});
    this.orders.push({value:'wpno',text:'WP No'});
    this.orders.push({value:'welderid',text:'Welder ID'});
    this.orders.push({value:'name',text:'Name'});

    const d = new Date();
    this.param.year = d.getFullYear();
    this.param.month = d.getMonth() + 1;
    this.param.order = 'welderid';
    this.generate();
  },
  methods: {
    generate() {
      this.showLoadingScreen();
      api.regularPost(apiUrl.workerSkill.getWelderQualificationExpiryReport, this.param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.data = response.data;
          if(this.data.list.length > 0){
            this.month = this.data.monthDisplay;
            this.year = this.param.year;
            this.total = this.data.total;
          }
        }
      });
    },
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.report.quotaDownload,this.param,"online_quota_report.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    changeFilterYear(e) {
      this.param.year = e;
    },
    changeFilterMonth(e) {
      this.param.month = e;
    },
    changeFilterOrder(e) {
      this.param.order = e;
    },
    downloadexpiry() {
      this.showLoadingScreen();
      api.downloadFile(apiUrl.workerSkill.welderExpiryDownload,this.param,"WelderExpiry.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    }
  }
}
</script>
