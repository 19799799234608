<template>
  <div>
    <table class="table mb-3">
      <thead>
        <tr>
          <th>NRIC/FIN / WP</th>
          <th>Name</th>
          <th>Corpporate</th>
          <th>Skill</th>
          <th>Test No.</th>
          <th>Test Date</th>
          <th>Cert No.</th>
          <th>Issue Date</th>
          <th>Perf Date</th>
          <th>File</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l,i) in list" :key="i">
          <td><span v-if="l.workerNricFin">{{l.workerNricFin}}</span><span v-if="l.workerNricFin && l.workerWorkPermit"> / </span><span v-if="l.workerWorkPermit">{{l.workerWorkPermit}}</span></td>
          <td>{{l.workerName}}</td>
          <td>{{l.workerCorporateName}}</td>
          <td>{{l.skill}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{l.certNum}}</td>
          <td>{{l.issueDateDisplay}}</td>
          <td>{{l.perfDateDisplay}}</td>
          <td>
            <button class="btn btn-sm btn-secondary" title="download" v-if="l.fileName" @click="clickDownloadFile(l.oid,l.fileName)">
              <i class="bi bi-cloud-download"></i>
            </button>
          </td>
          <td>
            <FormSelect :list="statusList" value="value" text="text" :existing="l.status" @change="l.status=$event" />
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <button class="btn btn-success float-end" @click="clickSubmit">Submit</button>
    </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';

export default {
  name: 'PreQualificationConfirm',
  components: {
    ...Forms
  },
  data() {
    return {
      list: [],
      statusList: []
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Pre-Qualification Confirmation' }
    ]);
    this.getList();
    this.statusList = [
      { text: 'N/A', value: '' },
      { text: 'Approve', value: this.$data._workerSkillTestStatus.active.value },
      { text: 'Reject', value: this.$data._workerSkillTestStatus.rejected.value },
    ]
  },
  methods: {
    getList() {
      this.list = [];
      this.showLoadingScreen();
      api.regularGet(apiUrl.workerSkill.getWorkerSkillTestsToConfirm).then(response => {
        this.hideLoadingScreen();
        response.data.forEach(x => {
          this.list.push({
            ...x,
            status: ''
          })
        });
      });
    },
    clickSubmit() {
      let data = [];
      this.list.filter(x => x.status).forEach(l => {
        data.push({
          workerSkillTestOid: l.oid,
          status: l.status
        });
      });

      this.showLoadingScreen();
      api.regularPost(apiUrl.workerSkill.confirmWorkerSkillTests, data).then(response => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully submitted.", "success");
          this.getList();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          console.log(response);
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    clickDownloadFile(oid, fileName) {
      api.downloadFile(apiUrl.workerSkill.downloadWorkerSkillTestFile, { oid }, fileName, () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
  }
}
</script>
