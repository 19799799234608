<template>
<div>
  <div v-if="hasManagePermission">
    <h4>Radiography Test Report Edit</h4>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Performance Date<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <DatePicker :availableDates="availableDates" :cssClass="error.assignDateFrom && 'control-error'" 
        @updatedate="changePerformanceDate" :existing="performanceDate" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Report Type<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
         <input name="reportType" @change="checkReportType" type="radio" v-model="dataObj.reportType" value="PerformanceWorksite"> Performance Update - Worksite
      </div>
      <div class="col-md-3">
         <input name="reportType" @change="checkReportType" type="radio" v-model="dataObj.reportType" value="PerformanceWorkshopContractorTestCentre"> Performance Update - Workshop at Contractor Or TestCentre
      </div>
      <div class="col-md-3">
         <input name="reportType" @change="checkReportType" type="radio" v-model="dataObj.reportType" value="SwsWelderQualifyingTest"> SWS-WQT (Welder Qualifying Test)
      </div>
    </div>
    <div class="row mb-2" v-if="isPerformanceIndustry" >
      <div class="col-md-3 text-secondary">
        Industry<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="industries" value="oid" text="corpName" @change="changeIndustry"
          :cssClass="error.industryOid && 'control-error'" :existing="dataObj.industryOid" />
      </div>
       <div v-if="isShowOtherIndustry" class="col-md-3">
        <input placeholder="Enter Industry Name" type="text" v-model="dataObj.otherIndustry" class="form-control" :class="error.otherIndustry && 'control-error'" />
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Job Category<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="jobCategories" value="value" text="text" @change="dataObj.jobCategory=$event"
          :cssClass="error.jobCategory && 'control-error'" :existing="dataObj.jobCategory" />
      </div>
    </div>
    <div v-if="isPerformanceIndustry" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Location<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="locatoins" value="oid" text="name" @change="dataObj.siteOid=$event"
          :cssClass="error.siteOid && 'control-error'" :existing="dataObj.siteOid" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contractor<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="contractors" value="oid" text="corpName" @change="dataObj.contractorOid=$event"
          :cssClass="error.contractorOid && 'control-error'" :existing="dataObj.contractorOid" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Centre<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :list="testCentres" value="oid" text="corpName" @change="changeTestCentre"
          :cssClass="error.testCentreOid && 'control-error'" :existing="dataObj.testCentreOid" />
      </div>
      <div v-if="isShowOtherTestCenter" class="col-md-3">
        <input placeholder="Enter Test Centre Name" type="text" v-model="dataObj.otherTestCentre" class="form-control" :class="error.otherTestCenter && 'control-error'" />
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Report No.<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.reportNo" class="form-control" :class="error.reportNo && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Code
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.code" class="form-control" :class="error.code && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Sensitivity
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.sensitivity" class="form-control"
          :class="error.sensitivity && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        I.Q.I
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.iqi" class="form-control" :class="error.iqi && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Source
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.source" class="form-control" :class="error.source && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Isotope/KV
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.isotape" class="form-control" :class="error.isotape && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Curies/MA
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.curies" class="form-control" :class="error.curies && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Film
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.film" class="form-control" :class="error.film && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Screen
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.screen" class="form-control" :class="error.screen && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Density
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.density" class="form-control" :class="error.density && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Developing
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.developing" class="form-control"
          :class="error.developing && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Exposure
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.exposure" class="form-control" :class="error.exposure && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Technique
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.technique" class="form-control"
          :class="error.technique && 'control-error'" />
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Radiographer<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.radiographer" class="form-control"
          :class="error.radiographer && 'control-error'" />
      </div>
      <div class="col-md-3 text-secondary">
        Interpreter<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.interpreter" class="form-control"
          :class="error.interpreter && 'control-error'" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Q.C. Inspector
      </div>
      <div class="col-md-3">
        <input type="text" v-model="dataObj.inspector" class="form-control"
          :class="error.inspector && 'control-error'" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-9">
        <div><span class="text-danger">* Denotes Mandatory Field</span></div>
        <div><span class="text-danger">Performance date must be within assignment period (for performance update)</span>
        </div>
        <div><span class="text-danger">RT date must be the same as Test date (for welding test)</span></div>
      </div>
      <div class="col-md-3 text-end">
        <button class="btn btn-danger me-1" @click="clickDeleteReport">Delete Report</button>
        <button class="btn btn-success" @click="clickUpdateReport">Update Report</button>
      </div>
    </div>

    <hr />

    <div class="row mb-3">
      <div class="col-6">
        <h5>Welders</h5>
      </div>
      <div class="col-6 text-end">
        <button class="btn btn-primary me-1" @click="clickAddWelder">Add Welder</button>
      </div>
    </div>

    <div class="card mb-2" v-for="(w,i) in dataObj.welders" :key="i">
      <div class="card-body">
        
        <div class="row">
          <div class="col-6">
            <button class="btn btn-primary btn-sm" @click="clickCheckAssignmentDetails(w.welderId)">Check Assignment Details</button>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-danger me-1" @click="clickDeleteWelder(w)">Delete</button>
          <button class="btn btn-primary" @click="clickEditWelder(w)">Edit</button>
          </div>
        </div>
        
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Welder Ref
          </div>
          <div class="col-md-3">
            {{w.welderId}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Name
          </div>
          <div class="col-md-3">
            {{w.name}}
          </div>
          <div class="col-md-3 text-secondary">
            NRIC/FIN<span class="text-danger">*</span>
          </div>
          <div class="col-md-3">
            {{w.nricFin}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Test No<span class="text-danger">*</span>
          </div>
          <div class="col-md-3">
            {{w.testNumber}}
          </div>
          <div class="col-md-3 text-secondary">
            Joint Type
          </div>
          <div class="col-md-3">
            {{w.jointType}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Weld Process
          </div>
          <div class="col-md-3">
            {{w.process}}
          </div>
          <div class="col-md-3 text-secondary">
            Position
          </div>
          <div class="col-md-3">
            {{w.testPosition}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Weld/Joint ID
          </div>
          <div class="col-md-3">
            {{w.weldId}}
          </div>
          <div class="col-md-3 text-secondary">
            S.F.D
          </div>
          <div class="col-md-3">
            {{w.sfd}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Wall Thicknes
          </div>
          <div class="col-md-3">
            {{w.wallThickness}}
          </div>
          <div v-if="isShowRadiographyLength(w.testNumber)" class="col-md-3 text-secondary">
            Radiography Length
          </div>
          <div v-if="isShowRadiographyLength(w.testNumber)" class="col-md-3">
            {{w.radiographyLength}} mm
          </div>
          <div v-if="!isShowRadiographyLength(w.testNumber)" class="col-md-3 text-secondary">
            Diameter
          </div>
          <div v-if="!isShowRadiographyLength(w.testNumber)" class="col-md-3">
            {{w.diameter}} mm
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Material
          </div>
          <div class="col-md-3">
            {{w.material}}
          </div>
          <div class="col-md-3 text-secondary">
            NPS
          </div>
          <div class="col-md-3">
            {{w.nps}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Length of Plate/Steel Structure
          </div>
          <div class="col-md-3">
            {{w.plateSize}}
          </div>
          <div class="col-md-3 text-secondary">
            Equip/Line No
          </div>
          <div class="col-md-3">
            {{w.equipNo}}
          </div>
        </div>

        <div class="mb-2 mt-2 text-secondary">
          Sections
        </div>
        <div style="overflow-x: auto;">
         <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Section No.</th>
              <th>Discontinuity Size</th>
              <th>Result</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s,i) in w.sections" :key="i">
              <td>{{s.sectionNo}}</td>
              <td>{{s.rejectLength}} mm</td>
              <td>{{getSectionResultTextByValue(s.result)}}</td>
              <td>
                <span v-for="(r,idx) in s.remarks" :key="idx">
                  {{r.performanceRemarkShort}}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
       

      </div>
    </div>

    <ModalDiaglog :show="showAddEditWeldDiaglog" size="xl" :onClose="()=>{showAddEditWeldDiaglog=false}" :title="isEditWeldObj?'Edit Welder':'Add Welder'"
      :hasFooter="false">
      <div v-if="showAddEditWeldDiaglog">
        <AddEditRTReportWelder :isEdit="isEditWeldObj" :rtReportOid="oid" :dataObj="addEditWelderObj"
        :reportType="dataObj.reportType" @onCancel="cancelAddEditWelder" @onSubmit="submitAddEditWelder" />
      </div>
    </ModalDiaglog>
  </div>
  <div v-else>
    <h3 class="text-danger text-center">No Authorized!</h3>
  </div>
</div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Pickers from '@/components/custom/pickers';
import * as Forms from '@/components/custom/forms';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import AddEditRTReportWelder from '@/components/views/rtreport/AddEditRTReportWelder';
import perCode from '@/common/permissionCodes';

export default {
  name: 'EditRTReport',
  components: {
    ...Forms,
    ...Pickers,
    ModalDiaglog,
    AddEditRTReportWelder
  },
  data() {
    return {
      oid: 0,
      dataObj: {
        welders: []
      },
      addEditWelderObj: {
        sections: []
      },
      error: {},
      availableDates: {},
      isEditWeldObj: false,
      performanceDate: null,

      industries: [],
      jobCategories: [],
      locatoins: [],
      contractors: [],
      testCentres: [],

      showAddEditWeldDiaglog: false,

      hasManagePermission: false,
      isShowOtherIndustry:false,
      isShowOtherTestCenter:false,
      otherIndustry:'',
      otherTestCentre:'',
      isPerformanceIndustry:true,
    }
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }

    this.availableDates = { end: new Date() };
    this.hasManagePermission = this.isAuthorizedPermission(perCode.manageRTReport);
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Performance Welder Report', url: '/rtreportlist' }, { name: 'Edit' }
    ]);

    this.jobCategories = [
      { value: this.$data._jobCategory.all.value, text: this.$data._jobCategory.all.text },
      { value: this.$data._jobCategory.construction.value, text: this.$data._jobCategory.construction.text },
      { value: this.$data._jobCategory.maintenance.value, text: this.$data._jobCategory.maintenance.text },
      { value: this.$data._jobCategory.shutdown.value, text: this.$data._jobCategory.shutdown.text },
    ];
    let currentUser = this.getLoggedInUser();
    api.getAllCorporates(null).then(response => {
      //if welding inspector, show only their own corporate
      if(currentUser.userGroupType == this.$data._userGroupType.weldingInsperctor.value){
        if(currentUser.wiType == this.$data._corporateType.industry.value){
          this.industries = response.data.filter(x => x.oid == currentUser.corporateOid &&  x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
          this.dataObj.industryOid = currentUser.corporateOid;

          this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
          this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
          this.testCentres.push({"oid":0,"corpName":'Other'});
        }else if (currentUser.wiType == this.$data._corporateType.testCenter.value){
          this.testCentres = response.data.filter(x => x.oid == currentUser.corporateOid && x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
          this.dataObj.testCentreOid = this.getLoggedInUser().corporateOid;

          this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
          this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
           this.industries.push({"oid":0,"corpName":'Other'});
        }
      }else{
         this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
         this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
         this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
      }
    });

    api.regularGet(apiUrl.site.getList).then(res => {
      this.locatoins = res.data;
    });

    if (this.oid > 0) {
      this.getDetails();
    }
    
  },
  methods: {
    checkReportType(){
      if(this.dataObj.reportType != this.$data._rtReportType.performanceWorksite.value){
        this.isPerformanceIndustry = false;
        this.dataObj.industryOid = 0;
        this.dataObj.jobCategory = '';
        this.dataObj.siteOid =0;
      }else{
        this.isPerformanceIndustry = true;
      }
    },
    changeIndustry(e){
      console.log(e);
      if(e == 0){
        this.isShowOtherIndustry = true;
      }else{
        this.isShowOtherIndustry = false;
      }
      this.dataObj.industryOid = e;
    },
    changeTestCentre(e){
      if(e == 0){
        this.isShowOtherTestCenter = true;
      }else{
        this.isShowOtherTestCenter = false;
      }
      this.dataObj.testCentreOid = e;
    },
    getDetails() {
      api.regularGet(apiUrl.performance.getRTReportByOid, { oid: this.oid }).then(res => {
        this.dataObj = res.data;
        if (this.dataObj.performanceDate) {
          this.performanceDate = this.convertDateStrToDateObj(this.dataObj.performanceDate);
          if(!this.dataObj.testCentreOid){
            this.dataObj.testCentreOid= 0
            this.isShowOtherTestCenter = true;
          }
          if(!this.dataObj.industryOid){
            this.dataObj.industryOid= 0
            this.isShowOtherIndustry = true;
          }
          if(this.dataObj.reportType != this.$data._rtReportType.performanceWorksite.value){
            this.isPerformanceIndustry = false;
          }
        }
        this.checkReportType();
      });
    },

    cancelAddEditWelder() {
      this.showAddEditWeldDiaglog = false;
    },

    submitAddEditWelder() {
      this.showAddEditWeldDiaglog = false;
      if (this.oid > 0)
        this.getDetails();
    },
    clickAddWelder() {
      this.addEditWelderObj = {
        sections: []
      };
      this.isEditWeldObj = false;
      this.showAddEditWeldDiaglog = true;
    },

    clickEditWelder(welder) {
      this.addEditWelderObj = welder;
      this.isEditWeldObj = true;
      this.showAddEditWeldDiaglog = true;
    },

    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      console.log(this.dataObj.testCentreOid);
      if (!this.dataObj.performanceDate) { this.error.performanceDate = true; isValid = false; }
      if (!this.dataObj.industryOid && this.dataObj.reportType == this.$data._rtReportType.performanceWorksite.value) { if(!this.dataObj.otherIndustry) {this.error.industryOid = true; isValid = false;} }
      if (!this.dataObj.jobCategory && this.dataObj.reportType == this.$data._rtReportType.performanceWorksite.value) { this.error.jobCategory = true; isValid = false; }
      if (!this.dataObj.siteOid && this.dataObj.reportType == this.$data._rtReportType.performanceWorksite.value) 
      { 
        console.log(this.dataObj.reportType);
        this.error.siteOid = true; isValid = false; 
        }
      if (!this.dataObj.contractorOid) { this.error.contractorOid = true; isValid = false; }
      if (!this.dataObj.testCentreOid) {if(!this.dataObj.otherTestCentre) {this.error.testCentreOid = true; isValid = false;} }
      if (!this.dataObj.reportNo) { this.error.reportNo = true; isValid = false; }
      if (!this.dataObj.radiographer) { this.error.radiographer = true; isValid = false; }
      if (!this.dataObj.interpreter) { this.error.interpreter = true; isValid = false; }

      this.$forceUpdate();
      if (!isValid) {
        this.showMessage("Please input mandatory fields.", "warning");
      }
      return isValid;
    },

    clickUpdateReport() {
      if (this.validate()) {
        this.showLoadingScreen();
        api.regularPost(apiUrl.performance.updateRTReport, this.dataObj).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.showMessage("Successfully Submitted.", "success");
            this.$router.push({ name: "RTReportList" });
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });
      }
    },

    changePerformanceDate(date) {
      this.dataObj.performanceDate = this.convertDateObjToDateStr(date);
    },

    clickDeleteReport() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove this RT report?',
          submitFunction: () => {
            this.showLoadingScreen();
            api.regularPost(apiUrl.performance.deleteRTReport, this.dataObj).then((response) => {
              this.hideLoadingScreen();
              if (response.status == 200) {
                this.showMessage("Successfully deleted.", "success");
                this.$router.push({ name: "RTReportList" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },

    clickDeleteWelder(welder) {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove this Welder ' + welder.name + '?',
          submitFunction: () => {

            var delIdx = -1;
            for (let i = 0; i < this.dataObj.welders.length; i++) {
              if (this.dataObj.welders[i].oid == welder.oid) {
                delIdx = i;
                break;
              }
            }

            this.showLoadingScreen();
            api.regularPost(apiUrl.performance.deleteRTReportWeld, this.dataObj).then((response) => {
              this.hideLoadingScreen();
              if (response.status == 200) {
                this.showMessage("Successfully deleted welder.", "success");
                this.dataObj.welders.splice(delIdx, 1);
                this.$forceUpdate();
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },

    isShowRadiographyLength(testNo) {
      if (testNo && testNo[0].toUpperCase() == 'P') {
        return false;
      }
      else {
        return true;
      }
    },

    clickCheckAssignmentDetails(welderId) {
      let routeData = this.$router.resolve({ name: 'CheckWelderSummary', params: { welderId: welderId } });
      window.open(routeData.href, '_blank');
    },

  }
}
</script>
