<template>
<div>
  <div>
    
  </div>
  <div>
     <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Year
              </div>
              <div class="col-sm-6">
                <FormSelect :list="years" :existing="tableParam.year" value="value" text="text" @change="changeFilterYear" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable">Search</button>
          </div>
          
        </div>
      </div>
    </div>
    <div class="my-tab">
        <div class="my-tab-item" :class="currentTab=='ViewPendingApplication' && 'active'" @click="clickTab('ViewPendingApplication')">Application(s) For Approval</div>
        <div class="my-tab-item" :class="currentTab=='ViewRejectedApplication' && 'active'" @click="clickTab('ViewRejectedApplication')">View Rejected Application(s)</div>
        <div class="my-tab-item" :class="currentTab=='ViewApprovedApplication' && 'active'" @click="clickTab('ViewApprovedApplication')">View Approved Application(s)</div>
        <div v-if="isMOM" class="my-tab-item" :class="currentTab=='ViewMNCPendingApplication' && 'active'" @click="clickTab('ViewMNCPendingApplication')">View MNC Pending Application(s)</div>
      </div>
    <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <SortColumn @click="sortColumn" sortBy="quotaApplicationNo" colGroup="tbl1">Quota Application No</SortColumn>
          <SortColumn @click="sortColumn" sortBy="applicant" colGroup="tbl1">Applicant</SortColumn>
          <SortColumn @click="sortColumn" sortBy="mncName" colGroup="tbl1">MNC Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="mainContractorName" colGroup="tbl1">Main Contractor Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="subContractorName" colGroup="tbl1">Sub Contractor Name</SortColumn>
          <th>Last Modified Date</th>
          <th>Status</th>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.quotaApplicationNo}}</td>
          <td>{{i.nameOfCompany}}</td>
          <td>{{i.mncName}}</td>
          <td>{{i.mainContractorName}}</td>
          <td>{{i.subContractorName}}</td>
          <td>{{i.modifiedDate}}</td>
          <td>
           {{i.statusDisplay}}
          </td>
          <td>
             <button v-if="i.canEdit" class="btn btn-sm btn-primary" @click="clickEdit(i)">
              <span>Edit</span>
            </button>
            <button v-else class="btn btn-sm btn-primary" @click="clickDetails(i)">
              <span>View</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    </div>
    
    <div class="row" v-if="currentTab=='ViewMNCPendingApplication'">
      <div class="col-sm-12">
        Remarks:<br/>
        <span class="text-danger">Submitted to MNC </span><br/>
        - Quota application(s) submitted by the main contractor has been channeled to MNC for approval.<br/><br/>
        <span class="text-danger">Endorsed by Main Contractor</span><br/>
        - Quota application(s) submitted by the sub-contractor has been approved by the main contractor. The quota has been channeled to the MNC(s) for approval. 
      </div>
    </div>
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'FromContractor',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: [],
      years:[],
      currentTab: 'ViewPendingApplication',
      isMOM:false
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'From Contractors' }
    ]);
    this.years = [];

    let currentYear = new Date().getFullYear();
    this.years.push({ value: "", text: "All" });
    this.years.push({ value: currentYear, text: currentYear });
    for (let index = 1; index < 11; index++) {
      var year = currentYear-index;
      this.years.push({ value: year, text: year });
    }

    this.statuses = [
      { value: "", text: "All" },
      { value: "ViewRejectedApplication", text: "View Rejected Application" },
       { value: "ViewApprovedApplication", text: "View Approved Application" },
        { value: "ViewPendingApplication", text: "View Pending Application" },
    ];
    this.tableParam.status = "ViewPendingApplication";
    this.tableParam.year = "";
    this.tableParam.type = "fromContractors";
    this.callTable();
    if (this.getLoggedInUser().userGroupType == this.$data._userGroupType.mom.value) {
        console.log(this.getLoggedInUser().userGroupType);
        this.isMOM = true;
    }
  },
  methods: {
    clickTab(e){
      this.currentTab = e;
      this.tableParam.status = e;
      this.callTable();
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.quota.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDetails(data) {
      let routeData = this.$router.resolve({ name: 'ViewQuota', params: { id: data.oid } });
      window.open(routeData.href, '_blank');
    },
    clickEdit(data) {
      let routeData = this.$router.resolve({ name: 'FormA', params: { id: data.oid } });
       window.open(routeData.href, '_blank');
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    },
    changeFilterYear(e) {
      this.tableParam.year = e;
      this.callTable();
    }
  }
}
</script>
