<template>
  <div>
    <h4>Test Application</h4>
    <h5>CWQS Test</h5>

    <div v-if="!isNew" class="row mb-2">
      <div class="col-md-3 text-secondary">
        Application No
      </div>
      <div class="col-md-3">
        {{dataObj.testApplicationId}}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Centre<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <v-select label="corpName" :class="error.testCentreOid && 'control-error'" v-model="selectedTestCentre" :options="testCentres" @input="changeTestCentre"></v-select>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Date<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <DatePicker :availableDates="availableDates" @updatedate="changeTestDate" :cssClass="error.testDate && 'control-error'" :existing="testDate" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Time<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :cssClass="error.testTime && 'control-error'" :list="testTimes" value="value" text="value" :existing="dataObj.testTime" @change="dataObj.testTime=$event" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Location<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :cssClass="error.testLocation && 'control-error'" :list="testLocations" value="value" text="value" :existing="dataObj.testLocation" @change="dataObj.testLocation=$event" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Location Address
      </div> 
      <div class="col-md-3">
        <textarea class="form-control" :cssClass="error.testLocationAddress" v-model="dataObj.testLocationAddress">
        </textarea>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contact Person
      </div> 
      <div class="col-md-3">
        <input type="text" class="form-control" :cssClass="error.testContactPerson" v-model="dataObj.testContactPerson" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Contact Number
      </div> 
      <div class="col-md-3">
        <input type="text" class="form-control" :cssClass="error.testContactNumber" v-model="dataObj.testContactNumber" />
      </div>
    </div>

    <div class="mb-4 text-end">
      <button class="btn btn-primary" @click="addWorker">Add Worker</button>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <th>Name</th>
          <th>NRIC/FIN</th>
          <th>Work Permit No.</th>
          <th>Welder ID</th>
          <th>Year Exp.</th>
          <th>Component</th>
          <th>Medium</th>
          <th style="width:200px;">Test No</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in selectedWorkers" :key="idx">
          <td>{{i.workerName}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.welderId}}</td>
          <td>
            <FormSelect :list="yearExps" :cssClass="i.errorYearExperience && 'control-error'" value="value" text="value"
              :existing="i.yearExperience" @change="i.yearExperience=$event" />
          </td>
          <td>
            <FormSelect :list="components" :cssClass="i.errorTestComponent && 'control-error'" value="value" text="value"
              :existing="i.testComponent" @change="i.testComponent=$event" />
          </td>
          <td>
            <FormSelect :list="mediumList" :cssClass="i.errorTestMediumOid && 'control-error'" value="oid" text="value"
              :existing="i.testMediumOid" @change="i.testMediumOid=$event" />
          </td>
          <td>
            <v-select label="testNumber" :class="i.errorTestNumber && 'control-error'" :multiple="true" v-model="i.testNumbers"
              :options="weldingSkillTests" ></v-select>
          </td>
          <td>
            <i class="bi bi-trash hover" @click="clickRemoveItem(i)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    

    <div class="text-danger small">* Denotes Mandatory Field</div>
    <div class="text-end">
      <button class="btn btn-secondary m-1" @click="clickBack">Back</button>
      <button v-if="!isNew" class="btn btn-warning m-1" @click="clickCancelSubmit">Cancel Test Application</button>
      <button class="btn btn-success m-1" @click="clickSave">Submit</button>
    </div>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <ModalDiaglog 
    :show="showWorkerList" 
    size="xl"
    :onClose="()=>{showWorkerList = false;}"
    :title="'Select Worker'"
    :onSubmit1="selectWorker"
    :submitBtn1Text="'Select'"
    >
      <div>
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-1">
                  <div class="col-sm-5">
                    NRIC/FIN
                  </div>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="tableParam.nricFin" />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-sm-5">
                    Work Permit No.
                  </div>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="tableParam.workPermit" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row mb-1">
                  <div class="col-sm-5">
                    Welder ID
                  </div>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="tableParam.welderId" />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-sm-5">
                    Name
                  </div>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="tableParam.name" />
                  </div>
                </div>
                <div class="text-end">
                  <button class="btn btn-primary me-1" @click="clickSearchWorker">Search</button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
    <div style="overflow-x: auto;">
       <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <th></th>
          <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="nricFin" colGroup="tbl1">NRIC/FIN</SortColumn>
          <SortColumn @click="sortColumn" sortBy="workPermit" colGroup="tbl1">Work Permit No.</SortColumn>
          <SortColumn @click="sortColumn" sortBy="welderId" colGroup="tbl1">Welder ID</SortColumn>
          <!-- <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">          
          <td><input v-if="checkAbleToAdd(i)" type="checkbox" v-model="i.selected" class="hover" /></td>
          <td>{{i.name}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.welderId}}</td>
          <!-- <td>
            <span class="badge bg-success" v-if="i.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
            <span class="badge bg-danger" v-else-if="i.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
            <span class="badge" v-else>{{i.statusDisplay}}</span>
          </td> -->
        </tr>
      </tbody>
    </table>    
    </div>
       
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />

      </div>
    </ModalDiaglog>

  </div>
</template>

<script>
import ModalDiaglog from "@/components/custom/ModalDiaglog";
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'AddEditTestApplication',
  components: {
    ...Pickers,
    ...Forms,
    ModalDiaglog,
    ...TableAddons,
  },
  data() {
    return {
      oid: 0,
      isNew: true,
      dataObj: {},
      error: {},
      availableDates: {},

      testCentres: [],
      testTimes: [],
      testLocations: [],
      yearExps: [],
      components: [],
      mediumList: [],
      weldingSkillTests: [],
      selectedWorkers: [],

      selectedTestCentre: {},
      testDate: null,

      showWorkerList: false,
      tableParam: this.dtoPaging()
    }
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }

    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    this.availableDates = { start: minDate };
    this.tableParam.rowPerPage = 10;
  },
  mounted() {
    let pageName = this.isNew ? 'New' : 'Edit';
    this.setBreadcrumb([
      { name: 'Test Applications', url: '/testapplicationlist' }, { name: pageName }
    ]);

    api.getAllCorporates(null).then(response => {
      this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);

      if (this.oid > 0) {
        this.getDetails();
      }

    });

    this.testTimes = [{ value: "08:00" }, { value: "09:00" }, { value: "10:00" }, { value: "11:00" }, { value: "12:00" }, { value: "13:00" }, { value: "14:00" }, { value: "15:00" }, { value: "16:00" }, { value: "17:00" }, { value: "18:00" }]
    this.testLocations = [
      { value: this.$data._testLocation.atTestCentre.value },
      { value: this.$data._testLocation.atWorkShop.value },
      { value: this.$data._testLocation.atOther.value },
    ];
    for (let i = 1; i <= 50; i++) {
      this.yearExps.push({ value: i });
    }
    this.components = [
      { value: this.$data._testComponent.practical },
      { value: this.$data._testComponent.theory },
      { value: this.$data._testComponent.both },
    ];

    let currentUser = this.getLoggedInUser();
    this.dataObj.testContactPerson = currentUser.fullName;
    this.dataObj.testContactNumber = currentUser.mobileNumber;

    api.getSettingsByGroup(this.$data._settingGroup.testMedium).then(response => {
      this.mediumList = response.data;
    });

    api.regularGet(apiUrl.workerSkill.getAllWeldingSkillTests, null).then((response) => {
      this.weldingSkillTests = response.data.map(x => x.testNumber);
    });

    this.tableParam.isOnlyWelder = true;

    api.regularGet(apiUrl.corporate.isSwsMember).then(response => {
        if (!response.data) {
          this.showMessage("Only SWS members can book CWQS test for their workers.\n Please contact SWS for membership renewal.", "error");
          this.$router.push({ name: "TestApplicationList" });
        }
      });
  },
  methods: {
    getDetails() {
      let url = apiUrl.testApplication.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        if(!response.data){
          this.showMessage("You have no permission to view this record.", "error");
          this.$router.push({ name: "TestApplicationList" });
        }
        this.dataObj = response.data;
        //commentted by yma 12 Jul 2022
        // if(!this.dataObj.canEdit){
        //   this.showMessage("Test Date expired.", "error");
        //   this.$router.push({ name: "TestApplicationList" });
        // }
        this.selectedTestCentre = this.testCentres.find(x => x.oid == this.dataObj.testCentreOid);
        if (this.dataObj.testDate)
          this.testDate = this.convertDateStrToDateObj(this.dataObj.testDate);

        this.dataObj.candidates.forEach(w => {
          this.selectedWorkers.push(w);

        });

      });
    },
    changeTestCentre(obj) {
      if (obj)
        this.dataObj.testCentreOid = obj.oid;
      else
        this.dataObj.testCentreOid = null;

    },
    changeTestDate(date) {
      this.dataObj.testDate = this.convertDateObjToDateStr(date);
    },
    callWorkerTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      this.tableParam.isOnlyWelder = true;
      this.tableParam.isValidWorkPermit = true;
      api.regularPost(apiUrl.worker.getList, this.tableParam).then(response => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callWorkerTable();
    },
    addWorker() {
      let isValid = true;
      if (!this.dataObj.testDate) {
        this.showMessage('Please select test date.', 'warning');
        isValid = false;
      }
      if (!this.dataObj.testCentreOid) {
        this.showMessage('Please select test centre.', 'warning');
        isValid = false;
      }
      if (!isValid)
        return;

      this.showWorkerList = true;
      this.callWorkerTable();
    },
    changeTestNumber(selectedObjs, item) {
      item.testNumbers = [];
      for (let i = 0; i < selectedObjs.length; i++) {
        item.testNumbers.push(selectedObjs[i]);
      }
    },
    clickSearchWorker() {
      this.callWorkerTable();
    },
    selectWorker() {
      let checkParam = {
        testDate: this.dataObj.testDate,
        testCentreOid: this.dataObj.testCentreOid,
        workerOids: []
      };

      this.tableParam.listData.filter(x => x.selected).forEach(w => {
        if (!this.selectedWorkers.some(x => x.oid == w.oid)) {
          checkParam.workerOids.push(w.oid);
        }
      });

      if ((checkParam.workerOids.length + this.selectedWorkers.length) > 10) {
        this.showMessage("Cannot select more than 10 workers.", "warning");
        return;
      }

      this.showLoadingScreen();
      api.regularPost(apiUrl.testApplication.checkWorkerDuplicateTestDate, checkParam).then((res) => {
        this.hideLoadingScreen();
        if (res.data.isSuccess) {
          this.tableParam.listData.filter(x => x.selected).forEach(w => {
            if (!this.selectedWorkers.some(x => x.oid == w.oid)) {
              this.selectedWorkers.push({
                workerName: w.name,
                nricFin: w.nricFin,
                workPermit: w.workPermit,
                welderId: w.welderId,
                workerOid: w.oid,
                testComponent: this.components[0].value,
                yearExperience: this.yearExps[0].value,
                testMediumOid: this.mediumList[1].oid,
                testNumbers: [],
              });
            }
          });
          this.showWorkerList = false;
        }
        else {
          this.showMessage(res.data.message, 'warning');
        }
      });
    },
    clickRemoveItem(obj) {
      let idx = -1;
      for (let i = 0; i < this.selectedWorkers.length; i++) {
        if (this.selectedWorkers[i].workerOid == obj.workerOid) {
          idx = i;
          break;
        }
      }
      if (idx > -1)
        this.selectedWorkers.splice(idx, 1);
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.testApplication.insert;
      }
      else {
        subUrl = apiUrl.testApplication.update;
      }

      this.dataObj.candidates = this.selectedWorkers;
      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Test application submitted, pending for confirmation by Test Centre.", "success");
          if (this.isNew) {
            this.$router.push({ name: 'ViewTestApplication', params: { id: response.data.data, isAfterInsert: true } });
          }
          else {
             this.showMessage("Successfully updated.", "success");
             this.$router.push({ name: 'ViewTestApplication', params: { id: this.dataObj.oid } });
             //this.$router.push({ name: "TestApplicationList" });
          }
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      if (!this.dataObj.testCentreOid) { this.error.testCentreOid = true; isValid = false; }
      if (!this.dataObj.testDate) { this.error.testDate = true; isValid = false; }
      if (!this.dataObj.testTime) { this.error.testTime = true; isValid = false; }
      if (!this.dataObj.testLocation) { this.error.testLocation = true; isValid = false; }

      if (this.selectedWorkers.length == 0) {
        isValid = false;
        this.showMessage("Please select workers.", "warning");
      }

      for (let i = 0; i < this.selectedWorkers.length; i++) {
        this.selectedWorkers[i].errorYearExperience = false;
        this.selectedWorkers[i].errorTestComponent = false;
        this.selectedWorkers[i].errorTestMediumOid = false;
        this.selectedWorkers[i].errorTestNumber = false;

        if (!this.selectedWorkers[i].yearExperience) { this.selectedWorkers[i].errorYearExperience = true; isValid = false; }
        if (!this.selectedWorkers[i].testComponent) { this.selectedWorkers[i].errorTestComponent = true; isValid = false; }
        if (!this.selectedWorkers[i].testMediumOid) { this.selectedWorkers[i].errorTestMediumOid = true; isValid = false; }
        if (this.selectedWorkers[i].testNumbers.length == 0) { this.selectedWorkers[i].errorTestNumber = true; isValid = false; }
      }

      this.$forceUpdate();
      return isValid;
    },
    clickBack() {
      history.back();
    },
    clickCancelSubmit() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: "Are you sure to cancel this test application?",
          submitFunction: () => {
            this.showLoadingScreen();
            api.regularPost(apiUrl.testApplication.cancelTestApplication, { oid: this.oid }).then((response) => {
              this.hideLoadingScreen();
              if (response.status == 200) {
                this.showMessage("Successfully Cancelled.", "success");
                this.$router.push({ name: "TestApplicationList" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });

          }
        });
    },

    checkAbleToAdd(worker) {
      //Check worker is terminating before test date
      if (worker.terminatedDate && this.dataObj.testDate) {
        let terminatedDate = this.convertDateStrToDateObj(worker.terminatedDate);
        let testDate = this.convertDateStrToDateObj(this.dataObj.testDate);
        if (testDate.getTime() > terminatedDate.getTime()) {
          return false;
        }
      }
      return true;
    },

  }
}
</script>
