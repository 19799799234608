<template>
  <div>
    <div v-if="!isNew" class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Full Name:
              </div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="dataObj.fullName"
                   :class="error.fullName && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                User Name:
                <div class="txt-small">(Must be valid email)</div>
              </div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="dataObj.userName"
                   :class="error.userName && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3" v-if="isNew">
              <div class="col-sm-6 text-secondary">Password:</div>
              <div class="col-sm-6">
                <input
                  type="password"
                  class="form-control"
                  v-model="dataObj.password"
                   :class="error.password && 'control-error'"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Corporate:</div>
              <div class="col-sm-6">
                <FormSelect :disabled="!isCurrentUserAdmin" :list="corporates" :cssClass="error.corporateOid && 'control-error'" value="oid" text="corpName" :existing="dataObj.corporateOid" @change="changeCorporate" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">User Group Type:</div>
              <div class="col-sm-6">
                <FormSelect :disabled="!isCurrentUserAdmin" :list="userGroupTypes" :cssClass="error.userGroupType && 'control-error'" value="value" text="text" :existing="dataObj.userGroupType" @change="changeUserGroupType" />
              </div>
            </div>
            <div class="row mb-3" v-show="showWeldingInspectorDdl">
              <div class="col-sm-6 text-secondary">Welding Inspector:</div>
              <div class="col-sm-6">
                <FormSelect :list="weldingInspectors" :cssClass="error.userGroupType && 'control-error'" value="oid" text="name" 
                :existing="dataObj.weldingInspectorOid" @change="dataObj.weldingInspectorOid=$event" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">Active:</div>
              <div class="col-sm-3">
                <input type="checkbox" v-model="dataObj.isActive" />
              </div>
              <div class="col-sm-3 text-secondary">Lock:</div>
              <div class="col-sm-3">
                <input type="checkbox" v-model="dataObj.isLock" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">Roles:</div>
              <div class="col-sm-9">
                <div v-for="r in roles" :key="r.oid" class="col-md-4">
                    <input type="checkbox" v-model="r.checked" name="roles"/> <label class="control-label">{{r.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Notes:</div>
              <div class="col-sm-6">
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="dataObj.notes"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">Mobile No:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="dataObj.mobileNumber"
                />
              </div>
            </div>
            <!-- <div class="row mb-3">
              <div class="col-sm-6">Email:</div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="dataObj.emailAddress"
                />
              </div>
            </div> -->
          </div>
        </div>
        <!-- <h5>User Roles</h5>
        <div class="row">
          
        </div> -->
        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">
          Cancel
        </button>
      </div>
    </div>
     <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="User" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      
  </div>
</template>

<script>
import apiUrl from "@/common/apiurl";
import api from "@/common/api";
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
export default {
  name: "AddEditUser",

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms
  },

  props: {},

  data() {
    return {
      basicLogInfoUrl: apiUrl.users.getBasicLogInfo,
      oid: 0,
      isNew: true,
      dataObj: {},
      timezone: api.TIMEZONE,
      roles: [],
      showAuditModal: false,
      tableParam: this.dtoPaging(),
      corporates: [],
      userGroupTypes: [],
      allRoles: [],
      isCurrentUserAdmin: false,
      weldingInspectors: [],
      allWeldingInspectors: [],
      showWeldingInspectorDdl: false,
      corporateName:'',
      userGroupType:'',
      error: {}
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = this.isNew ? 'New User' : 'Edit User';
    this.setBreadcrumb([
      { name: 'Users', url: '/users' }, { name: pageName }
    ]);

    if (this.getLoggedInUser().userGroupType == this.$data._userGroupType.admin.value) {
      this.isCurrentUserAdmin = true;
    }

    api.getAllCorporates(null).then(response => {
      this.corporates = response.data;

      if (this.isNew && !this.isCurrentUserAdmin) {
        this.dataObj.corporateOid = this.getLoggedInUser().corporateOid;
        this.changeCorporate(this.dataObj.corporateOid, true);
        this.dataObj.userGroupType = this.getLoggedInUser().userGroupType;
      }

    });

    this.loadRoles(() => {
      if (this.isNew) {
        this.dataObj.isActive = true;
      }
      if (this.oid > 0) {
        this.getData();
      }
    });

    api.regularGet(apiUrl.weldinginspector.getAll, null).then((response) => {
      this.allWeldingInspectors = response.data;
    });

  },

  methods: {
    loadRoles(callBack) {
      let url = apiUrl.roles.getAll;
      api.regularPost(url).then((response) => {
        if (response.status == 200) {
          this.allRoles = response.data;
        }
        callBack();
      });
    },
    bindRoles(corporateType) {
      this.roles = this.allRoles.filter(x => x.corporateType == corporateType);
      for (let i = 0; i < this.roles.length; i++) {
        //render checked
        if (!this.isNew && this.dataObj.userRoles) {
          let per = this.dataObj.userRoles.find(x => x.roleOid == this.roles[i].oid)
          if (per)
            this.roles[i].checked = true;
        }
      }
    },
    getData() {
      let url = apiUrl.users.getByOid + "/" + this.oid;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.changeCorporate(response.data.corporateOid, true);
      });
    },
    clickSave() {
      let roleOids = [];
      this.roles.forEach(r => {
        if (r.checked) {
          roleOids.push(r.oid);
        }
      });
      this.dataObj.roleOids = roleOids;

      if (!this.validate())
        return;

      let subUrl = "";
      if (this.isNew) {
        subUrl = apiUrl.users.insert;
      } else {
        subUrl = apiUrl.users.update;
      }

      this.showLoadingScreen();
      api
        .regularPost(subUrl, this.dataObj, this.$store.state.token)
        .then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.showMessage("Successfully saved.", "success");
            this.$router.push({ name: "ManageUsers" });
          } else if (
            response.status == 400 ||
            response.errorText.response.status == 400
          ) {
            this.showMessage(response.errorText.response.data.message, "error");
          } else {
            this.showMessage(window.message.unable_proceed, "error");
          }
        });
    },
    clickCancel() {
      this.$router.push({ name: "ManageUsers" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.userName) { this.error.userName = true; isValid = false; }
      if (!this.isValidEmail(this.dataObj.userName)) { this.error.userName = true; isValid = false; }
      if (!this.dataObj.fullName) { this.error.fullName = true; isValid = false; }
      if (this.isNew && !this.dataObj.password) { this.error.password = true; isValid = false; }
      if (!this.dataObj.corporateOid) { this.error.corporateOid = true; isValid = false; }
      if (!this.dataObj.userGroupType) { this.error.userGroupType = true; isValid = false; }

      this.$forceUpdate();
      return isValid;

    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    confirmRemove() {
      let param = {
        oid:this.dataObj.oid
      };
      this.showConfirmDiaglog({
        show: true,
        message: "Are you sure to remove " + this.dataObj.userName + "?",
        submitFunction: () => {
          api
            .regularPost(
              apiUrl.users.delete,
              param,
              this.$store.state.token
            )
            .then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "ManageUsers" });
              } else if (
                response.status == 400 ||
                response.errorText.response.status == 400
              ) {
                this.showMessage(
                  response.errorText.response.data.message,
                  "error"
                );
              } else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
        },
      });
    },
    showAudit() {
      this.showAuditDialog(true);
    },
    changeCorporate(e, isPageLoad) {
      this.dataObj.corporateOid = e;
      let corp = this.corporates.find(x => x.oid == e);
      this.userGroupTypes = [];

      if (!isPageLoad)
        this.dataObj.userGroupType = "";

      if (corp) {
        switch (corp.type) {
          case this.$data._corporateType.industry.value: {
            this.userGroupTypes = [
              { value: this.$data._userGroupType.industry.value, text: this.$data._userGroupType.industry.text },
              { value: this.$data._userGroupType.weldingInsperctor.value, text: this.$data._userGroupType.weldingInsperctor.text },
            ];
            break;
          }
          case this.$data._corporateType.contractor.value: {
            this.userGroupTypes = [
              { value: this.$data._userGroupType.contractor.value, text: this.$data._userGroupType.contractor.text },
            ];
            break;
          }
          case this.$data._corporateType.testCenter.value: {
            this.userGroupTypes = [
              { value: this.$data._userGroupType.testCenter.value, text: this.$data._userGroupType.testCenter.text },
              { value: this.$data._userGroupType.weldingInsperctor.value, text: this.$data._userGroupType.weldingInsperctor.text },
            ];
            break;
          }
          case this.$data._corporateType.admin.value: {
            this.userGroupTypes = [
              { value: this.$data._userGroupType.admin.value, text: this.$data._userGroupType.admin.text },
            ];
            break;
          }
          case this.$data._corporateType.sws.value: {
            this.userGroupTypes = [
              { value: this.$data._userGroupType.sws.value, text: this.$data._userGroupType.sws.text },
            ];
            break;
          }
          case this.$data._corporateType.mom.value: {
            this.userGroupTypes = [
              { value: this.$data._userGroupType.mom.value, text: this.$data._userGroupType.mom.text },
            ];
            break;
          }
        }

        this.bindRoles(corp.type);
      }
      this.checkWeldingInspector(isPageLoad);
    },
    changeUserGroupType(e) {
      this.dataObj.userGroupType = e;
      this.checkWeldingInspector();
    },
    checkWeldingInspector(isPageLoad) {
      this.weldingInspectors = [];
      this.showWeldingInspectorDdl = false;
      if (!isPageLoad){
        this.dataObj.weldingInspectorOid = null;
      }

      if (this.dataObj.userGroupType == this.$data._userGroupType.weldingInsperctor.value) {
        this.weldingInspectors = this.allWeldingInspectors
          .filter(x => x.corporateOid == this.dataObj.corporateOid && (x.oid == this.dataObj.weldingInspectorOid || !x.userOid));
        if (this.weldingInspectors.length > 0)
          this.showWeldingInspectorDdl = true;
      }
    },

  },
};
</script>
