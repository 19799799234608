<template>
<div>
  <div>
    <button v-if="isAuthorizedPermission($data._permissionCodes.manageServiceItem)" class="btn btn-primary float-end mb-2" @click="add">Add Service Item</button>
  </div>  
  <div>
     <TableFilter @changeText="changeTblFilter" />
    <div style="overflow-x: auto;">
      <table class="table table-sm">
      <thead>
      <tr>
          <SortColumn @click="sortColumn" sortBy="serviceCode" colGroup="tbl1">Service Code</SortColumn>
          <SortColumn @click="sortColumn" sortBy="description" colGroup="tbl1">Description</SortColumn>
          <SortColumn @click="sortColumn" sortBy="unitPrice" colGroup="tbl1">Unit Price</SortColumn>
          <SortColumn @click="sortColumn" sortBy="quantity" colGroup="tbl1">Quantity</SortColumn>
          <SortColumn @click="sortColumn" sortBy="forCorporateType" colGroup="tbl1">Corporate Type</SortColumn>
          <SortColumn v-if="isAuthorizedPermission($data._permissionCodes.manageServiceItem)" @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.serviceCode}}</td>
          <td>{{i.description}}</td>
          <td>{{i.unitPrice}}</td>
           <td>{{i.quantity}}</td>
            <td>{{i.forCorporateTypeDisplay}}</td>
             <td>
                 <span v-if="i.status == $data._status.active.value" class="badge bg-success">{{i.statusDisplay}}</span>
                 <span v-else-if="i.status == $data._status.deleted.value" class="badge bg-danger">{{i.statusDisplay}}</span>
                 <span v-else>{{i.statusDisplay}}</span>
            </td>
          <td><button v-if="isAuthorizedPermission($data._permissionCodes.manageServiceItem)" class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button></td>
        </tr>
      </tbody>
    </table>    
    </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'ServiceItem',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Service Items' }
    ]);
    this.callTable();
  },
  methods: {
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.serviceItem.getList, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditServiceItem', params: { id: data.oid } });
    },
    add() {
      this.$router.push({ name: 'AddEditServiceItem' });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text) {
      this.tableParam.searchtext = text;
      this.callTable();
    },
    checkPermission(permissionCode) {
      let authorizedPermissions = this.getAuthorizedPermissions();
      if (authorizedPermissions.indexOf(permissionCode) > -1)
        return true;
      else
        return false;
    }
  }
}
</script>
