<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6"> 
                Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterName" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-6">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text" @change="changeFilterStatus" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-2">
              <div class="col-sm-6">
                Corporation
              </div>
              <div class="col-sm-6">
                <FormSelect  :list="corporates" value="oid" text="corpName" :existing="tableParam.corp" @change="changeFilterCorp"/>
              </div>
            </div>
              <div class="row mb-2">
              <div>
                <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="download">Download</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: auto;">
      <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="corporate" colGroup="tbl1">Corporate</SortColumn>
          <SortColumn @click="sortColumn" sortBy="designation" colGroup="tbl1">Designation</SortColumn>
          <SortColumn @click="sortColumn" sortBy="telephone" colGroup="tbl1">Telephone</SortColumn>
          <SortColumn @click="sortColumn" sortBy="email" colGroup="tbl1">Email</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody> 
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.name}}</td>
          <td>{{i.corporName}}</td>
          <td>{{i.designation}}</td>
          <td>{{i.telephone}}</td>
          <td>{{i.email}}</td>
           <td>
            <span v-if="i.statusText == $data._corporateStatus.active.text" class="badge bg-success">{{i.statusText}}</span>
            <span v-else class="badge bg-danger">{{i.statusText}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-primary" @click="clickView(i)">View</button> 
            <button v-if="isEditPermission" style="margin-left:5px;"  class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button>
            
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import _ from "lodash";

export default {
  name: 'WeldingInspectors',
  components: {
    ...TableAddons,
    ...Forms
  },
  props:{
    type: String
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: [],
      corporates:[],
      isEditPermission:false
    }
  },
  beforeMount(){
  },
   destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.isEditPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageCorporate)
     this.setBreadcrumb([
      { name: this.getCorporateTypeTextByValue(this.$data._corporateType.weldingInspector.value) }
    ]);
    this.tableParam.type = this.$data._corporateType.weldingInspector.value;

    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];
    
    this.tableParam.status = this.$data._corporateStatus.active.value;
    this.tableParam.designation = "";
    this.getDropdowns();
    this.callTable();
  },
  methods: {
    download() {
      let data = {
        corporateOid: this.tableParam.swsOid,
        status: this.tableParam.status,
        name: this.tableParam.name
      }
      api.downloadFile(apiUrl.weldinginspector.download,data,"weldinginspector.xlsx",() =>{
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    getDropdowns() {
      let url = apiUrl.regWeldingInspector.dropdowns
      api.regularGet(url, null).then((response) => {
        let dropdowns = response.data;
        this.corporates = dropdowns.corporates;
      });
    },
    callTable() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.weldinginspector.getList, this.tableParam).then((response) => {
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'EditWeldingInspector', params: { id: data.oid, type: this.$data._corporateType.weldingInspector.value } });
    },
    clickView(data) {
      this.$router.push({ name: 'ViewWeldingInspector', params: { id: data.oid, type: this.type } });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    // changeTblFilter(text){
    //   this.tableParam.searchtext = text;
    //   this.callTable();
    // },

    changeFilterName: _.debounce(function (e) {
      this.tableParam.name = e.target.value;
      this.callTable();
    }, 500),

    changeFilterDesignation(e) {
      this.tableParam.designation = e;
      this.callTable();
    },

    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    },
    changeFilterCorp(e) {
      this.tableParam.corp = e;
      this.callTable();
    }

  }
}
</script>
