<template>
  <div>
    <!-- <table class="table mb-4">
      <thead>
        <tr>
          <th>Skill</th>
          <th>Cert No</th>
          <th>Cert Issue Date</th>
          <th>Test No.</th>
          <th>Test Centre</th>
          <th>Test Date</th>
          <th>Perf Date</th>
          <th>Status</th>
          <th>File</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l,i) in skillTestList" :key="i">
          <td>{{l.skill}}</td>
          <td>{{l.certNum}}</td>
          <td>{{l.issueDateDisplay}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testCentre}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{l.perfDateDisplay}}</td>
          <td>
            <span v-if="l.status==$data._workerSkillTestStatus.active.value" class="badge bg-success">{{$data._workerSkillTestStatus.active.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.rejected.value" class="badge bg-danger">{{$data._workerSkillTestStatus.rejected.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.pending.value" class="badge bg-secondary">{{$data._workerSkillTestStatus.pending.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.suspended.value" class="badge bg-warning">{{$data._workerSkillTestStatus.suspended.text}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-secondary" title="download" v-if="l.fileName" @click="clickDownloadFile(l.oid,l.fileName)">
              <i class="bi bi-cloud-download"></i>
            </button>
            <button class="btn btn-sm btn-primary" v-if="l.testApplicationOid > 0" @click="clickViewTestResultDetails(l)">View</button>
          </td>
        </tr>
      </tbody>
    </table> -->
    
    <table class="table mb-4">
      <thead>
        <tr>
          <th>Skill</th>
          <th>Test No</th>
          <th>Test Position</th>
          <th>Description</th>
          <th>Test Date</th>
          <th>Result</th>
          <th>Expiry Date</th>
          <th>Last Perf Update</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l,i) in skillTestList" :key="i">
          <td>{{l.skill}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testPosition}}</td>
          <td>{{l.weldingProcess}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{getWorkerSkillTestResultTextByValue(l.result)}}</td>
          <td>{{l.expiryDateDisplay}}</td>
          <td>{{l.lastPerfDateDisplay}}</td>
          <td>
            <span v-if="l.status==$data._workerSkillTestStatus.active.value" class="badge bg-success">{{$data._workerSkillTestStatus.active.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.rejected.value" class="badge bg-danger">{{$data._workerSkillTestStatus.rejected.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.pending.value" class="badge bg-secondary">{{$data._workerSkillTestStatus.pending.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.suspended.value" class="badge bg-warning">{{$data._workerSkillTestStatus.suspended.text}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-primary" v-if="l.testApplicationOid > 0" @click="clickViewTestResultDetails(l)">View</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'ViewTestResult',
  props: {
    workerOid: Number
  },
  components: {
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      skillTestList: [],
      dataObj: {},

      skills: [],
      testNumbers: [],
      testCentres: [],

      testDate: null,
      certIssudDate: null,
      perfUpd: null,

      error: {}
    }
  },
  mounted() {
    if (this.workerOid && this.workerOid > 0) {
      this.getWorkerSkillTestList();

      api.getAllCorporates(null).then(response => {
        this.testCentres = response.data;
      });

      api.regularGet(apiUrl.workerSkill.getWorkerSkillSetsByWorkerOid + '?workerOid=' + this.workerOid, null).then((response) => {
        this.skills = response.data;
        if (this.skills.length > 0)
          this.dataObj.skillOid = this.skills[0].oid;
      });

    }
  },

  methods: {

    getWorkerSkillTestList() {
      this.showLoadingScreen();
      this.skillTestList = [];
      api.regularGet(apiUrl.workerSkill.getWorkerSkillTests + '/' + this.workerOid, null).then((response) => {
        this.hideLoadingScreen();
        this.skillTestList = response.data;
        console.log(this.skillTestList);
        this.skillTestList = this.skillTestList.filter(x => x.result != this.$data._workerSkillTestResult.absent.value);
      });
    },
    clickDownloadFile(oid, fileName) {
      api.downloadFile(apiUrl.workerSkill.downloadWorkerSkillTestFile, { oid }, fileName, () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },

    clickViewTestResultDetails(obj) {

      // this.$router.push({
      //   name: 'ViewTestApplicationResult', params:
      //   {
      //     workerOid: obj.workerOid,
      //     skillOid: obj.skillOid,
      //     testDate: obj.testDate,
      //     testNumber: obj.testNumber,
      //     applicationOid: obj.testApplicationOid,
      //     candidateOid: 0
      //   }
      // });
      let routeData = this.$router.resolve({
        name: 'ViewTestApplicationResult', params:
        {
          workerOid: obj.workerOid,
          skillOid: obj.skillOid,
          testDate: obj.testDate,
          testNumber: obj.testNumber,
          applicationOid: obj.testApplicationOid,
          candidateOid: 0
        }
      });
      window.open(routeData.href, '_blank');
    },

  }
}
</script>
