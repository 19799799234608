<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-8">
        <h4>Performance Welder Report</h4>
      </div>
      <div class="col-md-4 text-end" v-if="hasManagePermission">
        <button class="btn btn-success" @click="clickNewRTReport">New RT Report</button>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-header bg-secondary text-white hover" data-bs-toggle="collapse" href="#div_filter">
        Filter
      </div>
      <div class="card-body">
        <div class="collapse" id="div_filter">
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Report No.
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.reportNo" />
            </div>
            <div class="col-md-3 text-secondary">
              Industry
            </div>
            <div class="col-md-3">
              <FormSelect :list="industries" value="oid" text="corpName" @change="tableParam.industryOid=$event" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Job Category
            </div>
            <div class="col-md-3">
              <FormSelect :list="jobCategories" value="value" text="text" @change="tableParam.jobCategory=$event" />
            </div>
            <div class="col-md-3 text-secondary">
              Location
            </div>
            <div class="col-md-3">
              <FormSelect :list="locations" value="oid" text="name" @change="tableParam.siteOid=$event" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3 text-secondary">
              Contractor
            </div>
            <div class="col-md-3">
              <FormSelect :list="contractors" value="oid" text="corpName" @change="tableParam.contractorOid=$event" />
            </div>
            <div class="col-md-3 text-secondary">
              Test Centre
            </div>
            <div class="col-md-3">
              <FormSelect :list="testCentres" value="oid" text="corpName" @change="tableParam.testCentreOid=$event" />
            </div>
          </div>
          <div class="text-end">
            <button class="btn btn-primary" @click="clickSearch">Search</button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <th>Report No.</th>
          <th>Performance Date</th>
          <th>Industry</th>
          <th>Job Category</th>
          <th>Location</th>
          <th>Contractor</th>
          <th>Test Centre</th>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.reportNo}}</td>
          <td>{{i.performanceDateDisplay}}</td>
          <td>{{i.industryName}}<span v-if="!i.industryName">{{i.otherIndustry}}</span></td>
          <td>{{getJobCategoryTextByValue(i.jobCategory)}}</td>
          <td>{{i.siteName}}</td>
          <td>{{i.contractorName}}</td>
          <td>{{i.testCentreName}}<span v-if="!i.testCentreName">{{i.otherTestCentre}}</span></td>
          <td>
            <button class="btn btn-sm btn-primary btn-sm me-1" @click="clickView(i)">View</button>
            <button class="btn btn-sm btn-primary btn-sm" @click="clickEdit(i)" v-if="hasManagePermission">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>
</template>

<script>

import api from '@/common/api';
import apiUrl from '@/common/apiurl';
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import perCode from '@/common/permissionCodes';

export default {
  name: 'RTReportList',
  components: {
    ...TableAddons,
    ...Forms,
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      industries: [],
      jobCategories: [],
      locations: [],
      contractors: [],
      testCentres: [],

      hasManagePermission: false
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Performance Welder Report' }
    ]);

    this.hasManagePermission = this.isAuthorizedPermission(perCode.manageRTReport);

    this.jobCategories = [
      { value: null, text: '--All--' },
      //{ value: this.$data._jobCategory.all.value, text: this.$data._jobCategory.all.text },
      { value: null, text: '<All>' },
      { value: this.$data._jobCategory.construction.value, text: this.$data._jobCategory.construction.text },
      { value: this.$data._jobCategory.maintenance.value, text: this.$data._jobCategory.maintenance.text },
      { value: this.$data._jobCategory.shutdown.value, text: this.$data._jobCategory.shutdown.text },
    ];

    api.getAllCorporates(null).then(response => {      
      this.industries = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.industry.value);
      this.industries.unshift({oid: null, corpName: '--All--'});
      
      this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      this.contractors.unshift({oid: null, corpName: '--All--'});

      this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
      this.testCentres.unshift({oid: null, corpName: '--All--'});
    });

    api.regularGet(apiUrl.site.getList).then(res => {
      this.locations = res.data;
      this.locations.unshift({oid: null, name: '--All--'});
    });

    this.tableParam.jobCategory = null;
    this.tableParam.industyOid = null;
    this.tableParam.contractorOid = null;
    this.tableParam.textCentreOid = null;
    this.tableParam.siteOid = null;

    this.callTable();

  },
  methods: {
    clickNewRTReport() {
      this.$router.push({ name: 'AddRTReport' });
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.performance.rtReportList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickSearch() {
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    clickEdit(i){
      this.$router.push({ name: "EditRTReport", params: { id: i.oid } });
    },
    clickView(i){
      this.$router.push({ name: "ViewRTReport", params: { id: i.oid } });
    },
  }
}
</script>
