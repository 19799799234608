<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="param.status" value="value" text="text"
                  @change="changeFilterStatus" />
              </div>
            </div>
          </div>
           <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Corporates
              </div>
              <div class="col-sm-6">
                <v-select label="corpName" v-model="selectedCorporate" :options="corporates" @input="changeCorporate"></v-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row mb-2">
              <div v-if="param.status != $data._invoiceStatus.paid.value" class="col-sm-4">
                Invoice Date
              </div>
              <div v-else  class="col-sm-4">
                Payment Date
              </div>
              <div class="col-sm-8">
                <DateRangePicker @updatedaterange="dateRangeChangeInvoice" :existing="filterDate" :defaultSelect="'thisMonth'"></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div v-if="param.status == $data._invoiceStatus.paid.value" class="row">
          <div class="col-sm-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Payment Type:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="paymentTypes" :existing="param.paymentType" value="value" text="text"
                  @change="changeFilterPaymentType" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable()">Generate</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
          </div>
        </div>
        <div v-else  class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable()">Generate</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-1">
      <div class="card-body">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th>Invoice Number</th>
              <th>Invoice Date</th>
              <th>Corporate Name</th>
              <th>Entity Type</th>
              <th style="text-align: right;">Amount</th>
              <th>For Month
              </th>
              <th>Status</th>
              <th>Payment</th>
              <th>Payment Date</th>
              <th>Cheque Number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in param.listData" :key="idx">
              <td>{{i.invoiceNumber}}</td>
              <td>{{i.invoiceDate}}</td>
              <td>{{i.corporateName}}</td>
              <td>{{i.corporateTypeDisplay}}</td>
              <td style="text-align: right;">{{numberWithCommas(Number(i.totalAmount).toFixed(2))}}</td>
              <td style="text-align: center;">{{i.forMonth}}</td>
              <td>
                <span v-if="i.status == $data._invoiceStatus.new.value" class="badge bg-warning">{{i.statusDisplay}}
                </span>
                <span v-else-if="i.status == $data._invoiceStatus.confirmed.value" class="badge bg-info text-dark">{{i.statusDisplay}}
                </span>
                 <span v-else-if="i.status == $data._invoiceStatus.chequeProcessing.value " class="badge bg-info text-white" >{{i.statusDisplay}}
                </span>
                 <span v-else-if="i.status == $data._invoiceStatus.paid.value" class="badge bg-success text-white" >{{i.statusDisplay}}
                </span>
                <span v-else class="badge bg-secondary">{{i.statusDisplay}}</span>
              </td>
              <td v-if="i.status == $data._invoiceStatus.paid.value && i.chequeNo">
                By Cheque/Bank Transfer
              </td>
              <td v-else-if="i.status == $data._invoiceStatus.paid.value && !i.chequeNo">
                By Giro
              </td>
              <td v-else>

              </td>
              <td>{{i.paymentDate}}</td>
              <td >{{i.chequeNo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'InvoiceList',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      param:{
          status:'',
          listData:[]
      },
      statuses: [],
      paymentTypes:[],
      pickDate:null,
      pDate:null,
      selectedCorporate:null,
      corporates:[],
      filterDate:{
        start: new Date(),
        end: new Date()
      }
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Invoices' }
    ]);
    let url = apiUrl.corporate.getAll 
        api.regularGet(url,null ).then((response) => {
          this.corporates = response.data;
        });
    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._invoiceStatus.new.value, text: this.$data._invoiceStatus.new.text },
      { value: this.$data._invoiceStatus.paid.value, text: this.$data._invoiceStatus.paid.text },
      { value: this.$data._invoiceStatus.confirmed.value, text: this.$data._invoiceStatus.confirmed.text },
      { value: this.$data._invoiceStatus.giroProcessing.value, text: this.$data._invoiceStatus.giroProcessing.text },
      { value: this.$data._invoiceStatus.giroError.value, text: this.$data._invoiceStatus.giroError.text },
      { value: this.$data._invoiceStatus.badDebt.value, text: this.$data._invoiceStatus.badDebt.text },
       { value: this.$data._invoiceStatus.chequeProcessing.value, text: this.$data._invoiceStatus.chequeProcessing.text },
    ];
    this.paymentTypes = [
      { value: "", text: "All" },
      { value: this.$data._paymentTypes.byChequeBankTransfer, text: this.$data._paymentTypes.byChequeBankTransfer },
      { value: this.$data._paymentTypes.byGiro, text: this.$data._paymentTypes.byGiro },
    ];
    this.param.status = '';
    
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(firstDay);
    lastDay.setMonth(lastDay.getMonth() + 1);
    lastDay.setDate(lastDay.getDate() -1);
    this.filterDate.start = firstDay;
    this.filterDate.end = lastDay;

    this.param.invoice_Start = this.convertDateObjToDateStr(this.filterDate.start);
    this.param.invoice_End = this.convertDateObjToDateStr(this.filterDate.end);
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateRangeChangeInvoice(dateRange) {
      this.param.invoice_Start = this.convertDateObjToDateStr(dateRange.start);
      this.param.invoice_End = this.convertDateObjToDateStr(dateRange.end);
    },
    callTable() {
      this.showLoadingScreen();
      this.param.listData = [];
      console.log(apiUrl.invoice.report,);
      api.regularPost(apiUrl.invoice.report, this.param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          console.log(response);
          this.param.listData = response.data;
        }
      });
    },
    changeFilterStatus(e) {
      this.param.status = e;
      console.log(this.param.status);
    },
    changeFilterPaymentType(e){
      this.param.paymentType = e;
    },
    changeCorporate(obj) {
      if(obj){
          this.param.CorpOid = obj.oid;
      }else{
          this.param.CorpOid = 0;
      }
    },
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.invoice.download,this.param,"invoice_detail.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
  }
}
</script>
