<template>
  <div>
    <h5>Welder Test Result Update</h5>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Application No.:
      </div>
      <div class="col-md-6">
        {{dataObj.applicationNo}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Application Date:
      </div>
      <div class="col-md-6">
        {{dataObj.applicationDate}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Test Centre:
      </div>
      <div class="col-md-6">
        {{dataObj.testCentre}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Contractor:
      </div>
      <div class="col-md-6">
        {{dataObj.contractor}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Skill Test:
      </div>
      <div class="col-md-6">
        {{dataObj.skill}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Test Date:
      </div>
      <div class="col-md-6">
        {{dataObj.testDateDisplay}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3 text-secondary">
        Inspector:
      </div>
      <div class="col-md-6">
        {{dataObj.inspector}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Worker Name:
      </div>
      <div class="col-md-6">
        {{dataObj.workerName}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        NRIC/FIN:
      </div>
      <div class="col-md-6">
        {{dataObj.nricFin}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Work Permit:
      </div>
      <div class="col-md-6">
        {{dataObj.workPermit}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Welder ID:
      </div>
      <div class="col-md-6">
        {{dataObj.swsId}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Test No.:
      </div>
      <div class="col-md-6">
        {{dataObj.testNumber}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Work Exp (Yrs):
      </div>
      <div class="col-md-6">
        {{dataObj.yearExperience}}
      </div>
    </div>
    <hr/>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Final Result:
      </div>
      <div class="col-md-6">
        <span v-if="dataObj.result==$data._workerSkillTestResult.pass.value" class="badge bg-success">
          {{$data._workerSkillTestResult.pass.text}}
        </span>
        <span v-else-if="dataObj.result==$data._workerSkillTestResult.fail.value" class="badge bg-danger">
          {{$data._workerSkillTestResult.fail.text}}
        </span>
        <span v-else-if="dataObj.result==$data._workerSkillTestResult.absent.value">
          {{$data._workerSkillTestResult.absent.text}}
        </span>
        <span v-else>
          {{dataObj.result}}
        </span>

        <button v-if="dataObj.oid && dataObj.result==$data._workerSkillTestResult.pass.value" class="btn btn-primary btn-xs ms-2" @click="clickPrintCert">Print Cert</button>

      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Status:
      </div>
      <div class="col-md-6">
        {{getWorkerSkillTestStatusTextByValue(dataObj.status)}}
      </div>
    </div>
    <hr/>
    <div class="text-center mb-3"><b>WELDING DATA</b></div>

    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Welding Process(es)
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.weldingProcess" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Welding Position<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :cssClass="error.testPosition && 'control-error'" :list="weldingPositions" value="testPosition" text="testPosition" 
        :existing="dataObj.testPosition" @change="dataObj.testPosition=$event" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Welding Progression
      </div>
      <div class="col-md-3">
        <input maxlength="50"  type="text" class="form-control" v-model="dataObj.progression" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Type of Backing
      </div>
      <div class="col-md-3">
        <input maxlength="50"  type="text" class="form-control" v-model="dataObj.backing" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Welding Voltage/Polarity
      </div>
      <div class="col-md-3">
        <input maxlength="50"  type="text" class="form-control" v-model="dataObj.voltage" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Welding Current
      </div>
      <div class="col-md-3">
        <input maxlength="50"  type="text" class="form-control" v-model="dataObj.weldingCurrent" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Welding Voltage/Polarity(2)
      </div>
      <div class="col-md-3">
        <input maxlength="50"  type="text" class="form-control" v-model="dataObj.voltage2" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Welding Current(2)
      </div>
      <div class="col-md-3">
        <input maxlength="50"  type="text" class="form-control" v-model="dataObj.weldingCurrent2" :disabled="notEditable" />
      </div>
    </div>
    <div style="border:1px solid #dadada; padding:8px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Electrode Type/Size
        </div>
        <div class="col-md-3">
          <input maxlength="50" type="text" class="form-control" v-model="dataObj.electrode" :disabled="notEditable" />
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-sm-5 text-secondary">
              F No.
            </div>
            <div class="col-sm-7">
              <input maxlength="20" type="text" class="form-control" v-model="dataObj.electrodeFno" :disabled="notEditable" />
            </div>
          </div>        
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-sm-5 text-secondary">
              A No.
            </div>
            <div class="col-sm-7">
              <input maxlength="20" type="text" class="form-control" v-model="dataObj.electrodeAno" :disabled="notEditable" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 text-secondary">
          Electrode Type/Size(2)
        </div>
        <div class="col-md-3">
          <input maxlength="50" type="text" class="form-control" v-model="dataObj.electrode2" :disabled="notEditable" />
        </div>
      </div>
    </div>
    <div style="border:1px solid #dadada; padding:8px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Filler Material Type/Size
        </div>
        <div class="col-md-3">
          <input maxlength="50" type="text" class="form-control" v-model="dataObj.fillerMaterial" :disabled="notEditable" />
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-sm-5 text-secondary">
              F No.
            </div>
            <div class="col-sm-7">
              <input maxlength="20" type="text" class="form-control" v-model="dataObj.fillerMaterialFno" :disabled="notEditable" />
            </div>
          </div>        
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-sm-5 text-secondary">
              A No.
            </div>
            <div class="col-sm-7">
              <input maxlength="20" type="text" class="form-control" v-model="dataObj.fillerMaterialAno" :disabled="notEditable" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 text-secondary">
          Filler Material Type/Size(2)
        </div>
        <div class="col-md-3">
            <input maxlength="50" type="text" class="form-control" v-model="dataObj.fillerMaterial2" :disabled="notEditable" />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Electrode Covering/Flux
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.electrodeCovering" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        
      </div>
      <div class="col-md-3">
        
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Sheilding Gas Composition
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.sheildingGasComposition" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Flow Rate
      </div>
      <div class="col-md-3">
        <input maxlength="20" type="text" class="form-control" v-model="dataObj.sheildingGasCompositionFlowRate" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Backing Gas Composition
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.backingGasComposition" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Flow Rate
      </div>
      <div class="col-md-3">
        <input maxlength="20" type="text" class="form-control" v-model="dataObj.backingGasCompositionFlowRate" :disabled="notEditable" />
      </div>
    </div>
    <div style="border:1px solid #dadada; padding:8px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Base Material From
        </div>
        <div class="col-md-3">
          <input maxlength="50" type="text" class="form-control" v-model="dataObj.baseMaterialFrom" :disabled="notEditable" />
        </div>
        <div class="col-md-3 text-secondary">
          Base Material To
        </div>
        <div class="col-md-3">
          <input maxlength="50" type="text" class="form-control" v-model="dataObj.baseMaterialTo" :disabled="notEditable" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 text-secondary">
          Base Material From P No.
        </div>
        <div class="col-md-3">
          <input maxlength="20" type="text" class="form-control" v-model="dataObj.baseMaterialPnoFrom" :disabled="notEditable" />
        </div>
        <div class="col-md-3 text-secondary">
          Base Material To P No.
        </div>
        <div class="col-md-3">
          <input maxlength="20" type="text" class="form-control" v-model="dataObj.baseMaterialPnoTo" :disabled="notEditable" />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Base Material Thickness
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.baseMaterialThickness" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        
      </div>
      <div class="col-md-3">
        
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Weld Thickness
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.weldThickness" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Pipe Diameter
      </div>
      <div class="col-md-3">
        <input maxlength="20" type="text" class="form-control" v-model="dataObj.pipeDiameter" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Weld Thickness(2)
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.weldThickness2" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Plate Size
      </div>
      <div class="col-md-3">
        <input maxlength="20" type="text" class="form-control" v-model="dataObj.plateSize" :disabled="notEditable" />
      </div>
    </div>
    <hr/>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Visual Test<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :cssClass="error.visualResult && 'control-error'" :list="visualTests" value="value" text="text" 
        :existing="dataObj.visualResult" @change="changeVisualTest" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Radiograph Test<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :cssClass="error.radioResult && 'control-error'" :list="radiographTests" value="value" text="text" 
        :existing="dataObj.radioResult" @change="changeRadiographTest" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        RT Reports<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <input maxlength="150" type="text" class="form-control" :class="error.rt && 'control-error'" v-model="dataObj.rt" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Mechanical Test<span class="text-danger">*</span>
      </div>
      <div class="col-md-3">
        <FormSelect :cssClass="error.mechanicalResult && 'control-error'" :list="mechanicalTests" value="value" text="text" 
        :existing="dataObj.mechanicalResult" @change="changeMechanicalTest" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Test Witnessed By
      </div>
      <div class="col-md-3">
        {{dataObj.witness}}
      </div>
      <div class="col-md-3 text-secondary">
        Date of Test
      </div>
      <div class="col-md-3">
        <DatePicker @updatedate="changeDateOfTest" 
        :cssClass="error.testDate && 'control-error'" :existing="dateOfTest" :disabled="notEditable" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Officer Title
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.officerTitle" :disabled="notEditable" />
      </div>
      <div class="col-md-3 text-secondary">
        Officer Name
      </div>
      <div class="col-md-3">
        <input maxlength="50" type="text" class="form-control" v-model="dataObj.officerName" :disabled="notEditable" />
      </div>
    </div>
    
    <div>
      <span class="text-danger small">* Denotes Mandatory Field</span>
    </div>

    <div class="mt-3 text-end">
      <button class="btn btn-secondary m-1" @click="clickBack">Back</button>
      <button v-if="!notEditable" class="btn btn-danger m-1" @click="clickAbsent">Absent</button>
      <button v-if="!notEditable" class="btn btn-success m-1" @click="clickSaveAsDraft">Save Draft</button>
      <button v-if="!notEditable" class="btn btn-success m-1" @click="clickSubmit">Submit</button>
    </div>

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'TestApplicationResultUpdate',
  components: {
    ...Pickers,
    ...Forms,
  },
  data() {
    return {
      workerOid: 0,
      skillOid: 0,
      testDate: '',
      testNumber: '',
      applicationOid: 0,
      candidateOid: 0,

      weldingPositions: [],
      visualTests: [],
      radiographTests: [],
      mechanicalTests: [],
      dateOfTest: null,

      dataObj: {},
      error: {},

      notEditable: false,
    }
  },
  beforeMount() {
    this.workerOid = parseInt(this.$route.params.workerOid);
    this.skillOid = parseInt(this.$route.params.skillOid);
    this.testDate = this.$route.params.testDate;
    this.testNumber = this.$route.params.testNumber;
    this.applicationOid = parseInt(this.$route.params.applicationOid);
    this.candidateOid = parseInt(this.$route.params.candidateOid);

  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = 'Result Update';
    this.setBreadcrumb([
      { name: 'Test Applications', url: '/testapplicationupdatelist' }, { name: pageName }
    ]);

    this.visualTests = [
      { value: this.$data._visualTest.pass.value, text: this.$data._visualTest.pass.text },
      { value: this.$data._visualTest.fail.value, text: this.$data._visualTest.fail.text }
    ];
    this.radiographTests = [
      { value: this.$data._radiographTest.pass.value, text: this.$data._radiographTest.pass.text },
      { value: this.$data._radiographTest.fail.value, text: this.$data._radiographTest.fail.text },
      { value: this.$data._radiographTest.notApplicable.value, text: this.$data._radiographTest.notApplicable.text }
    ];
    this.mechanicalTests = [
      { value: this.$data._mechanicalTest.pass.value, text: this.$data._mechanicalTest.pass.text },
      { value: this.$data._mechanicalTest.fail.value, text: this.$data._mechanicalTest.fail.text },
      { value: this.$data._mechanicalTest.notApplicable.value, text: this.$data._mechanicalTest.notApplicable.text }
    ];

    this.loadPositions();
    this.getDetails();
  },
  methods: {
    getDetails() {
      let url = apiUrl.testApplication.getTestCandidateDetails;
      let param = {
        workerOid: this.workerOid,
        skillOid: this.skillOid,
        testDate: this.testDate,
        testNumber: this.testNumber,
        applicationOid: this.applicationOid,
        candidateOid: this.candidateOid
      };
      
      api.regularGet(url, param).then((response) => {
        this.dataObj = response.data;
        if (this.dataObj.dateTest) {
          this.dateOfTest = this.convertDateStrToDateObj(this.dataObj.dateTest);
        }
        else if (this.dataObj.testDate) {
          this.dateOfTest = this.convertDateStrToDateObj(this.dataObj.testDate.split(' ')[0]);
        }

        if (!this.dataObj.witness) {
          this.dataObj.witness = this.dataObj.inspector;
        }

        if (this.dataObj.oid && this.dataObj.status == this.$data._workerSkillTestStatus.active.value) {
          this.notEditable = true;
        }

        //Check future date
        let today = new Date();
        let testDate = this.convertDateTimeStrToDateObj(this.dataObj.testDate);
        if (testDate.getTime() > today.getTime()) {
          this.notEditable = true;
        }

        if(this.dataObj.oid && (this.dataObj.result==this.$data._workerSkillTestResult.fail.value || this.dataObj.result==this.$data._workerSkillTestResult.absent.value)){
          this.notEditable = false;
        }

      });
    },
    loadPositions() {
      this.weldingPositions = [];
      this.dataObj.testPosition = '';
      api.regularGet(apiUrl.testApplication.getSkillTestPositions, { testNumber: this.testNumber }).then((response) => {
        this.weldingPositions = response.data;
      });
    },
    changeDateOfTest(date) {
      this.dataObj.dateTest = this.convertDateObjToDateStr(date);
    },
    changeVisualTest(val) {
      this.dataObj.visualResult = val;
      this.checkFinalResult();
    },
    changeRadiographTest(val) {
      this.dataObj.radioResult = val;
      this.checkFinalResult();
    },
    changeMechanicalTest(val) {
      this.dataObj.mechanicalResult = val;
      this.checkFinalResult();
    },
    checkFinalResult() {
      if (this.dataObj.visualResult == this.$data._visualTest.fail.value ||
        this.dataObj.radioResult == this.$data._radiographTest.fail.value ||
        this.dataObj.mechanicalResult == this.$data._mechanicalTest.fail.value) {
        this.dataObj.result = this.$data._workerSkillTestResult.fail.value;
      }
      else {
        let passCount = 0;
        if (this.dataObj.visualResult == this.$data._visualTest.pass.value)
          passCount++;
        if (this.dataObj.radioResult == this.$data._radiographTest.pass.value)
          passCount++;
        if (this.dataObj.mechanicalResult == this.$data._mechanicalTest.pass.value)
          passCount++;

        if (passCount >= 2)
          this.dataObj.result = this.$data._workerSkillTestResult.pass.value;
        else
          this.dataObj.result = this.$data._workerSkillTestResult.fail.value;
      }


    },
    clickBack() {
      history.back();
    },
    clickAbsent() {
      this.dataObj.result = this._data._workerSkillTestResult.absent.value;
      this.dataObj.isSaveAsDraft = false;
      this.callSubmit();
    },
    clickSaveAsDraft() {
      this.dataObj.isSaveAsDraft = true;
      this.checkFinalResult();
      this.callSubmit(true);
    },
    clickSubmit() {
      this.dataObj.isSaveAsDraft = false;
      this.checkFinalResult();
      this.callSubmit();
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.testPosition) { this.error.testPosition = true; isValid = false; }
      if (!this.dataObj.visualResult) { this.error.visualResult = true; isValid = false; }
      if (!this.dataObj.radioResult) { this.error.radioResult = true; isValid = false; }
      if (!this.dataObj.mechanicalResult) { this.error.mechanicalResult = true; isValid = false; }
      if (!this.dataObj.rt) { this.error.rt = true; isValid = false; }

      this.$forceUpdate();
      return isValid;
    },
    callSubmit(isDraft) {
      let resultText = this.dataObj.result;

      if (this.dataObj.result != this._data._workerSkillTestResult.absent.value) {
        if (!this.validate())
          return;
      }

      switch (this.dataObj.result) {
        case this.$data._workerSkillTestResult.pass.value: resultText = this.$data._workerSkillTestResult.pass.text; break;
        case this.$data._workerSkillTestResult.fail.value: resultText = this.$data._workerSkillTestResult.fail.text; break;
        case this.$data._workerSkillTestResult.absent.value: resultText = this.$data._workerSkillTestResult.absent.text; break;
        default: resultText = 'Unknown'
      }

      let url = apiUrl.testApplication.insertTestApplicationResult;
      if (this.dataObj.oid > 0)
        url = apiUrl.testApplication.updateTestApplicationResult;

      var message = 'Result: <b>' + resultText + '</b>.<br/><br/>Are you sure you want to submit this result?<br/>A fee of S$5.00 (per certificate) will be imposed for any request to amend the certificate.';
      if (isDraft) {
        message = "Are you sure to save as draft?";
      }
      this.showConfirmDiaglog(
        {
          show: true,
          message: message,
          submitFunction: () => {
            this.showLoadingScreen();
            api.regularPost(url, this.dataObj).then((response) => {
              this.hideLoadingScreen();
              if (response.status == 200) {
                this.showMessage("Successfully Submitted.", "success");
                this.clickBack();
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });

          }
        });
    },

    clickPrintCert() {
      this.showLoadingScreen();
      api.downloadFile(apiUrl.pdf.testApplicationResultPrintCert, { oid: this.dataObj.oid }, 'TestApplicationResultCert.pdf', () => {
        this.hideLoadingScreen();
        this.showMessage('Downloaded successfully.', 'success');
      });
    },

  }
}
</script>
