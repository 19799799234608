
export default {
  testPermission: "p1",

  viewUser: "P010010",
  manageUser: "P010020",

  viewRole: "P020010",
  manageRole: "P020020",

  viewTenant: "P030010",
  manageTenant: "P030020",

  viewLocation: "P040010",
  manageLocation: "P040020",

  viewCorporate: "P050010",
  manageCorporate: "P050020",

  viewSws: "P060010",
  manageSws: "P060020",

  viewWorker: "P070010",
  manageWorker: "P070020",

  viewSetting: "P080010",
  manageSetting: "P080020",

  viewFormA: "P100010",
  manageFormA: "P100020",
  newFormA: "P100030",
  fromContractor: "P100040",
  formAList: "P100050",

  viewTestApplication: "P090010",
  manageTestApplication: "P090020",

  viewWelderTestExpiryExtend: "P090030",
  manageWelderTestExpiryExtend: "P090040",

  preQualificationConfirm: "P090050",
  testApplicationConfirm: "P090060",
  testApplicationUpdate: "P090061",
  newTestApplication: "P090062",
  testCandidateHistory: "P090063",
  
  viewWorkerAssignment: "P110010",
  manageWorkerAssignment: "P110020",
  viewIndustryWorker: "P110030",  
  newAssignment: "P110040",
  assignmentListing: "P110041",
  assignmentApprove: "P110042",
  assignmentListingByWeldingInspector: "P110043",

  viewInvoice: "P120010",
  manageInvoice: "P120020",
  viewServiceItem: "P120030",
  manageServiceItem: "P120040",
  downloadInvoice: "P120050",

  viewIndustryPreference: "P130010",
  manageIndustryPreference: "P130020",

  viewRTReport: "P140010",
  manageRTReport: "P140020",

  viewReport: "P150000",
  revenueReport: "P150010",
  workerListingReportItem: "P150011",
  invoiceDetailListing: "P150012",
  creditNoteDetailListing: "P150013",
  portalSummary: "P150014",
  welder: "P150015",
  systemActivityLog: "P150016",
  workerAssignmentReport: "P150017",
  welderQualificationReport: "P150018",
  onlineQuotaReport: "P150019",
  testStatisticsReport: "P150020",
  workerSkillInformationReport: "P150021",

  viewTestCentreDelegation:"P160010",
  manageTestCentreDelegation: "P160020",

  viewWorkerAssessment:"P170010",
  manageWorkerAssessment: "P170020",

  viewPerformanceUpdate: "P180010",

  viewNewsAnnouncement:"P170030",
  manageNewsAnnouncement: "P170040",

  viewLink:"P180020",
  manageLink: "P180030",

  viewCommonFile:"P190010",
  manageCommonFile: "P190020",

  corporateProfile: "P050030",

  viewSkillTest: "P080010",
  manageSkillTest: "P080011",

  endosementHistory: "P110022",
  endosementHistoryContractor: "P110023",

  viewStaff: "P190030",
  manageStaff: "P190031",
  batchUploadStaff: "P190032",

  welderQualificationExpiry: "P150022",
};