<template>
<div>
  <div v-if="isAbleToUpdate">
    <h5>Welder Test Result Update</h5>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Application No.:
      </div>
      <div class="col-md-6">
        {{dataObj.applicationNo}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Application Date:
      </div>
      <div class="col-md-6">
        {{dataObj.applicationDate}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Test Centre:
      </div>
      <div class="col-md-6">
        {{dataObj.testCentre}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Contractor:
      </div>
      <div class="col-md-6">
        {{dataObj.contractor}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Skill Test:
      </div>
      <div class="col-md-6">
        {{dataObj.skill}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Test Date:
      </div>
      <div class="col-md-6">
        {{dataObj.testDateDisplay}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3 text-secondary">
        Inspector:
      </div>
      <div class="col-md-6">
        {{dataObj.inspector}}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 text-secondary">
        Test No.:
      </div>
      <div class="col-md-3">
        <FormSelect :list="testNumbers" value="value" text="value"  @change="changeTestNumber" />
      </div>
    </div>

    <div v-show="selectedTestNumber">
      <hr/>
      <div class="text-center mb-3"><b>WELDING DATA</b></div>

      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Welding Process(es)
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.weldingProcess" />
        </div>
        <div class="col-md-3 text-secondary">
          Welding Position<span class="text-danger">*</span>
        </div>
        <div class="col-md-3">
          <FormSelect :cssClass="error.testPosition && 'control-error'" :list="weldingPositions"  value="testPosition" text="testPosition" 
          :existing="dataObj.testPosition" @change="dataObj.testPosition=$event" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Welding Progression
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.progression" />
        </div>
        <div class="col-md-3 text-secondary">
          Type of Backing
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.backing" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Welding Voltage/Polarity
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.voltage" />
        </div>
        <div class="col-md-3 text-secondary">
          Welding Current
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.weldingCurrent" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Welding Voltage/Polarity(2)
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.voltage2" />
        </div>
        <div class="col-md-3 text-secondary">
          Welding Current(2)
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.weldingCurrent2" />
        </div>
      </div>
      <div style="border:1px solid #dadada; padding:8px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Electrode Type/Size
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.electrode" />
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-sm-5 text-secondary">
                F No.
              </div>
              <div class="col-sm-7">
                <input type="text" class="form-control" v-model="dataObj.electrodeFno" />
              </div>
            </div>        
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-sm-5 text-secondary">
                A No.
              </div>
              <div class="col-sm-7">
                <input type="text" class="form-control" v-model="dataObj.electrodeAno" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 text-secondary">
            Electrode Type/Size(2)
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.electrode2" />
          </div>
        </div>
      </div>
      <div style="border:1px solid #dadada; padding:8px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Filler Material Type/Size
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.fillerMaterial" />
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-sm-5 text-secondary">
                F No.
              </div>
              <div class="col-sm-7">
                <input type="text" class="form-control" v-model="dataObj.fillerMaterialFno" />
              </div>
            </div>        
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-sm-5 text-secondary">
                A No.
              </div>
              <div class="col-sm-7">
                <input type="text" class="form-control" v-model="dataObj.fillerMaterialAno" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 text-secondary">
            Filler Material Type/Size(2)
          </div>
          <div class="col-md-3">
              <input type="text" class="form-control" v-model="dataObj.fillerMaterial2" />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Electrode Covering/Flux
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.electrodeCovering" />
        </div>
        <div class="col-md-3 text-secondary">
          
        </div>
        <div class="col-md-3">
          
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Sheilding Gas Composition
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.sheildingGasComposition" />
        </div>
        <div class="col-md-3 text-secondary">
          Flow Rate
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.sheildingGasCompositionFlowRate" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Backing Gas Composition
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.backingGasComposition" />
        </div>
        <div class="col-md-3 text-secondary">
          Flow Rate
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.backingGasCompositionFlowRate" />
        </div>
      </div>
      <div style="border:1px solid #dadada; padding:8px; margin-left:-8px; margin-right:-8px;margin-bottom:10px;">
        <div class="row mb-2">
          <div class="col-md-3 text-secondary">
            Base Material From
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.baseMaterialFrom" />
          </div>
          <div class="col-md-3 text-secondary">
            Base Material To
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.baseMaterialTo" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 text-secondary">
            Base Material From P No.
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.baseMaterialPnoFrom" />
          </div>
          <div class="col-md-3 text-secondary">
            Base Material To P No.
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" v-model="dataObj.baseMaterialPnoTo" />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Base Material Thickness
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.baseMaterialThickness" />
        </div>
        <div class="col-md-3 text-secondary">
          
        </div>
        <div class="col-md-3">
          
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Weld Thickness
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.weldThickness" />
        </div>
        <div class="col-md-3 text-secondary">
          Pipe Diameter
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.pipeDiameter" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Weld Thickness(2)
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.weldThickness2" />
        </div>
        <div class="col-md-3 text-secondary">
          Plate Size
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.plateSize" />
        </div>
      </div>
      <hr/>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Test Witnessed By
        </div>
        <div class="col-md-3">
          {{dataObj.witness}}
        </div>
        <div class="col-md-3 text-secondary">
          Date of Test<span class="text-danger">*</span>
        </div>
        <div class="col-md-3">
          <DatePicker @updatedate="changeDateOfTest" 
          :cssClass="error.dateTest && 'control-error'" :existing="dateOfTest" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Officer Title
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.officerTitle" />
        </div>
        <div class="col-md-3 text-secondary">
          Officer Name
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="dataObj.officerName" />
        </div>
      </div>

      <div style="overflow-x: auto;">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Test Candidate</th>
              <th>Visual Test</th>
              <th>Radiograph Test</th>
              <th>Mechanical Test</th>
              <th>Final Result</th>
              <th>RT Reports</th>
              <th><input type="checkbox" class="hover" @click="clickSelectAllItems" /></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itm, i) in items" :key="i">
              <td>
                <div>{{itm.nricFin}}</div>
                <div>{{itm.workerName}}</div>
              </td>
              <td>
                <FormSelect :cssClass="itm.errorVisualResult && 'control-error'" :list="visualTests" value="value" text="text" 
                   @change="changeVisualTest(itm, $event)" />
              </td>
              <td>
                <FormSelect :cssClass="itm.errorRadioResult && 'control-error'" :list="radiographTests" value="value" text="text" 
                   @change="changeRadiographTest(itm, $event)" />
              </td>
              <td>
                <FormSelect :cssClass="itm.errorMechanicalResult && 'control-error'" :list="mechanicalTests" value="value" text="text" 
                  @change="changeMechanicalTest(itm, $event)" />
              </td>
              <td>
                <span v-if="itm.result==$data._workerSkillTestResult.pass.value" class="badge bg-success">
                  {{$data._workerSkillTestResult.pass.text}}
                </span>
                <span v-else-if="itm.result==$data._workerSkillTestResult.fail.value" class="badge bg-danger">
                  {{$data._workerSkillTestResult.fail.text}}
                </span>
                <span v-else-if="itm.result==$data._workerSkillTestResult.absent.value">
                  {{$data._workerSkillTestResult.absent.text}}
                </span>
                <span v-else>
                  {{itm.result}}
                </span>
              </td>
              <td>
                <input type="text" class="form-control" :class="itm.errorRt && 'control-error'" v-model="itm.rt" />
              </td>
              <td>
                <input type="checkbox" class="hover" v-model="itm.isSelected" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div>
        <span class="text-danger small">* Denotes Mandatory Field</span>
      </div>

      <div class="mt-3 text-end">
        <button class="btn btn-secondary m-1" @click="clickBack">Back</button>
        <!-- <button class="btn btn-danger m-1" @click="clickAbsent">Absent</button> -->
      <button class="btn btn-success m-1" @click="clickSaveAsDraft">Save Draft</button>
        <button class="btn btn-success m-1" @click="clickSubmit">Submit</button>
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="text-danger text-center">[Data is no able to update!]</h3>
  </div>
</div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'TestApplicationResultUpdate',
  components: {
    ...Pickers,
    ...Forms,
  },
  data() {
    return {
      applicationOid: 0,
      selectedTestNumber: "",
      items: [],

      testNumbers: [],
      weldingPositions: [],
      visualTests: [],
      radiographTests: [],
      mechanicalTests: [],
      dateOfTest: null,

      dataObj: {},
      error: {},
      isAbleToUpdate: true,
    }
  },
  beforeMount() {
    this.applicationOid = parseInt(this.$route.params.applicationOid);

  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = 'Result Update';
    this.setBreadcrumb([
      { name: 'Test Applications', url: '/testapplicationupdatelist' }, { name: pageName }
    ]);

    this.visualTests = [
      { value: this.$data._visualTest.pass.value, text: this.$data._visualTest.pass.text },
      { value: this.$data._visualTest.fail.value, text: this.$data._visualTest.fail.text }
    ];
    this.radiographTests = [
      { value: this.$data._radiographTest.pass.value, text: this.$data._radiographTest.pass.text },
      { value: this.$data._radiographTest.fail.value, text: this.$data._radiographTest.fail.text },
      { value: this.$data._radiographTest.notApplicable.value, text: this.$data._radiographTest.notApplicable.text }
    ];
    this.mechanicalTests = [
      { value: this.$data._mechanicalTest.pass.value, text: this.$data._mechanicalTest.pass.text },
      { value: this.$data._mechanicalTest.fail.value, text: this.$data._mechanicalTest.fail.text },
      { value: this.$data._mechanicalTest.notApplicable.value, text: this.$data._mechanicalTest.notApplicable.text }
    ];

    this.getDetails();
  },
  methods: {
    getDetails() {
      let url = apiUrl.testApplication.getTestCandidateDetailsByBatch;
      let param = {
        applicationOid: this.applicationOid,
        testNumber: this.selectedTestNumber,
      };
      api.regularGet(url, param).then((response) => {
        this.dataObj = response.data;
        if (!this.dataObj.witness) {
          this.dataObj.witness = this.dataObj.inspector;
        }

        if (this.dataObj.testDate) {
          this.dateOfTest = this.convertDateStrToDateObj(this.dataObj.testDate.split(' ')[0]);
        }

        this.testNumbers = [];
        this.dataObj.candidates.forEach(x => {
          if (!this.testNumbers.some(t => t.value == x.testNumber)) {
            this.testNumbers.push({ value: x.testNumber });
          }
        });

        //Check future date
        let today = new Date();
        let testDate = this.convertDateTimeStrToDateObj(this.dataObj.testDate);
        if (testDate.getTime() > today.getTime()) {
          //this.isAbleToUpdate = false;
        }

      });
    },
    loadPositions() {
      this.weldingPositions = [];
      this.dataObj.testPosition = '';
      api.regularGet(apiUrl.testApplication.getSkillTestPositions, { testNumber: this.selectedTestNumber }).then((response) => {
        this.weldingPositions = response.data;
      });
    },
    changeTestNumber(testNum) {
      this.selectedTestNumber = testNum;
      this.items = [];
      this.items = this.dataObj.candidates.filter(x => x.testNumber == this.selectedTestNumber);
      this.loadPositions();
      this.getDetails();
    },
    changeDateOfTest(date) {
      this.dataObj.dateTest = this.convertDateObjToDateStr(date);
    },
    changeVisualTest(itm, val) {
      itm.visualResult = val;
      this.checkFinalResult(itm);
    },
    changeRadiographTest(itm, val) {
      itm.radioResult = val;
      this.checkFinalResult(itm);
    },
    changeMechanicalTest(itm, val) {
      itm.mechanicalResult = val;
      this.checkFinalResult(itm);
    },
    checkFinalResult(itm) {
      if (itm.visualResult == this.$data._visualTest.fail.value ||
        itm.radioResult == this.$data._radiographTest.fail.value ||
        itm.mechanicalResult == this.$data._mechanicalTest.fail.value) {
        itm.result = this.$data._workerSkillTestResult.fail.value;
      }
      else {
        let passCount = 0;
        if (itm.visualResult == this.$data._visualTest.pass.value)
          passCount++;
        if (itm.radioResult == this.$data._radiographTest.pass.value)
          passCount++;
        if (itm.mechanicalResult == this.$data._mechanicalTest.pass.value)
          passCount++;

        if (passCount >= 2)
          itm.result = this.$data._workerSkillTestResult.pass.value;
        else
          itm.result = this.$data._workerSkillTestResult.fail.value;
      }

      this.$forceUpdate();

    },
    clickSelectAllItems(e) {
      if (e.target.checked) {
        this.items.forEach(x => x.isSelected = true);
      }
      else {
        this.items.forEach(x => x.isSelected = false);
      }
      this.$forceUpdate();
    },
    clickBack() {
      history.back();
    },
    clickAbsent() {
      this.dataObj.isSaveAsDraft = false;
      this.callSubmit(false, true);
    },
    clickSaveAsDraft() {
      this.dataObj.isSaveAsDraft = true;
      this.callSubmit(true, false);
    },
    clickSubmit() {
      this.dataObj.isSaveAsDraft = false;
      this.callSubmit(false, false);
    },
    validate() {
      let isValid = true;
      let errMsg = '';
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.testPosition) { this.error.testPosition = true; isValid = false; }
      if (!this.dataObj.dateTest) { this.error.dateTest = true; isValid = false; }

      let selectedItems = this.items.filter(x => x.isSelected);
      if (selectedItems.length == 0) {
        errMsg = "Please select candidates.";
        isValid = false;
      }

      selectedItems.forEach(itm => {
        itm.errorVisualResult = false;
        itm.errorRadioResult = false;
        itm.errorMechanicalResult = false;
        itm.errorRt = false;

        if (!itm.visualResult) { itm.errorVisualResult = true; isValid = false; }
        if (!itm.radioResult) { itm.errorRadioResult = true; isValid = false; }
        if (!itm.mechanicalResult) { itm.errorMechanicalResult = true; isValid = false; }
        if (!itm.rt) { itm.errorRt = true; isValid = false; }
      });


      this.$forceUpdate();
      if (!isValid) {
        if (!errMsg)
          errMsg = "Please input the mandatory fields.";
        this.showMessage(errMsg, "warning");
      }
      return isValid;
    },
    callSubmit(isDraft, isAllAbsent) {
      if (!this.validate())
        return;

      let url = apiUrl.testApplication.insertTestApplicationResultByBatch;

      let msg = 'Are you sure you want to submit this result?<br/>A fee of S$5.00 (per certificate) will be imposed for any request to amend the certificate.';
      if (isDraft) {
        msg = "Are you sure to save as draft?";
      }
      else if (isAllAbsent) {
        msg = "Are you sure that candidates are absent?";
      }


      this.showConfirmDiaglog(
        {
          show: true,
          message: msg,
          submitFunction: () => {
            this.showLoadingScreen();

            let postData = { ...this.dataObj };
            delete postData.candidates;
            postData.items = this.items.filter(x => x.isSelected);
            if (isAllAbsent) {
              for (let i = 0; i < postData.items.length; i++) {
                postData.items[i].result = this._data._workerSkillTestResult.absent.value;
              }
            }

            api.regularPost(url, postData).then((response) => {
              this.hideLoadingScreen();
              if (response.status == 200) {
                this.showMessage("Successfully Submitted.", "success");
                this.clickBack();
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });

          }
        });
    }
  }
}
</script>
