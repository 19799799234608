<template>
<div>
  <h4>Welder Maintenance</h4>
  <div class="row mb-2">
    <div class="col-md-2 text-secondary">Contractor</div>
    <div class="col-md-2">
      <FormSelect :list="contractors" value="oid" text="corpName" :existing="tableParam.corporateOid" @change="tableParam.corporateOid=$event" />
    </div>
    <div class="col-md-2 text-secondary">Welder Id</div>
    <div class="col-md-2">
      <input type="text" class="form-control" v-model="tableParam.welderId" />
    </div>
    <div class="col-md-4 text-end">
      <button class="btn btn-primary" @click="getList">Search</button>
    </div>
  </div>
  <hr/>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>NRIC/FIN</th>
          <th>Work Permit</th>
          <th>Welder Id</th>
          <th>Name</th>
          <th>Corporate</th>
          <th>Test No.</th>
          <th>Test Date</th>
          <th>Last Perf Date</th>
          <th>Expiry Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in tableParam.listData" :key="i">
          <td>{{l.nricFin}}</td>
          <td>{{l.workPermit}}</td>
          <td>{{l.welderId}}</td>
          <td>{{l.name}}</td>
          <td>{{l.corporate}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{l.lastPerfDateDisplay}}</td>
          <td>{{l.expiryDate}}</td>
          <td><button class="btn btn-sm btn-primary" @click="clickUpdate(l)">Update</button></td>
        </tr>
      </tbody>
    </table> 
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>

  <div>
    <ModalDiaglog 
    :show="showUpdate" 
    :onClose="closeDiaglog"
    :title="'Welder Maintenance - Update'"
    :onSubmit1="updateConfirm"
    :submitBtn1Text="'Update'"
    >
      <div v-if="showUpdate">
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">NRIC</div>
          <div class="col-md-6">{{updateData.nricFin}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Name</div>
          <div class="col-md-6">{{updateData.name}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Work Permit</div>
          <div class="col-md-6">{{updateData.workPermit}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Welder ID</div>
          <div class="col-md-6">{{updateData.welderId}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Corporate</div>
          <div class="col-md-6">{{updateData.corporate}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Test Number</div>
          <div class="col-md-6">{{updateData.testNumber}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Test Date</div>
          <div class="col-md-6">{{updateData.testDateDisplay}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Last Performance Date</div>
          <div class="col-md-6">{{updateData.lastPerfDateDisplay}}</div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6 text-secondary">Expiry Date</div>
          <div class="col-md-6">
            <DatePicker :existing="updateExpiryDate" @updatedate="changeExpiryDate" />
          </div>
        </div>
      </div>
    </ModalDiaglog>
  </div>
</div>
</template>

<script>
import ModalDiaglog from "@/components/custom/ModalDiaglog";
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
import * as TableAddons from "@/components/custom/table_addons";
export default {
  name: 'WelderTestListForExpiryExtend',
  components: {
    ...Pickers,
    ...Forms,
    ModalDiaglog,
    ...TableAddons
  },
  data() {
    return {
      corporateOid: null,
      contractors: [],
      tableParam: this.dtoPaging(),
      showUpdate: false,
      updateData: {},
      updateExpiryDate: null,
    }
  },
  beforeMount() {

  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Welder Maintenance' }
    ]);

    api.getAllCorporates(null).then(response => {
      this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      this.contractors.unshift({ oid: 0, corpName: '<All>' });
    });

    this.corporateOid = null;
    this.tableParam.corporateOid = 0;
    this.tableParam.welderId = '';
    this.getList();
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  methods: {
    getList() {
      this.tableParam.listData = [];
      api.regularPost(apiUrl.testApplication.getWelderTestListForExpiryExtend, this.tableParam).then((response) => {
         this.tableParam = response.data;
      });
    },
    clickUpdate(item) {
      this.updateData = { ...item };
      if (item.expiryDate)
        this.updateExpiryDate = this.convertDateStrToDateObj(item.expiryDate);
      else
        this.updateExpiryDate = null;
      this.showUpdate = true;
    },
    updateConfirm() {
      if (this.updateData.expiryDate) {
        let postData = {
          workerSkillTestOid: this.updateData.workerSkillTestOid,
          expiryDate: this.updateData.expiryDate
        };

        this.showLoadingScreen();
        api.regularPost(apiUrl.testApplication.welderTestExpiryExtend, postData).then((response) => {
          this.hideLoadingScreen();
          if (response.status == 200) {
            this.showMessage("Successfully Updated.", "success");
            this.tableParam.listData.find(x => x.workerSkillTestOid == this.updateData.workerSkillTestOid).expiryDate = this.updateData.expiryDate;
            this.updateExpiryDate = null;
            this.updateData = {};
            this.showUpdate = false;
          }
          else if (response.status == 400 || response.errorText.response.status == 400) {
            this.showMessage(response.errorText.response.data.message, "error");
          }
          else {
            this.showMessage(window.message.doctors.unable_delete, "error");
          }
        });
      }
    },
    changeExpiryDate(date) {
      this.updateData.expiryDate = this.convertDateObjToDateStr(date);
    },
    closeDiaglog() {
      this.showUpdate = false;
      this.updateExpiryDate = null;
      this.$forceUpdate();
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.getList();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.getList();
    }
  }
}
</script>
