<template>
<div>
  <div>
    
  </div>
  <div>
     <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Name
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterName" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                Sws ID
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" @keyup="changeFilterSwsId" />
              </div>
            </div>
             <div class="row mb-2">
              <div class="col-sm-4">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text" @change="changeFilterStatus" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
              <div class="row mb-2">
              <div class="col-sm-4">
                 Expiry Date
              </div>
              <div class="col-sm-8">
                 <DateRangePicker @updatedaterange="dateRangeChangeExpiry"></DateRangePicker>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                Terminated Date
              </div>
              <div class="col-sm-8">
                 <DateRangePicker @updatedaterange="dateRangeChangeTerminate"></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end mb-2" @click="addNew">Add New</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable">Search</button>
          </div>
          
        </div>
      </div>
    </div>
    <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <SortColumn @click="sortColumn" sortBy="company" colGroup="tbl1">Company</SortColumn>
          <SortColumn @click="sortColumn" sortBy="swsid" colGroup="tbl1">SWS ID</SortColumn>
          <SortColumn @click="sortColumn" sortBy="expiryDate" colGroup="tbl1">Expiry Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="terminateDate" colGroup="tbl1">Terminate Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.company}}</td>
          <td>{{i.swsid}}</td>
          <td>{{i.expiryDateDisplay}}</td>
          <td>{{i.terminateDateDisplay}}</td>
          <td>
            <span v-if="i.statusText == $data._status.active.text" class="badge bg-success">{{i.statusText}}</span>
            <span v-else class="badge bg-danger">{{i.statusText}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-primary" @click="clickDetails(i)">
              <span v-if="isAuthorizedPermission($data._permissionCodes.manageSws)">Edit</span>
              <span v-else>View</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import _ from "lodash";
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'SwsList',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: []
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'SWS Mgmt' }
    ]);

    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._status.active.value, text: this.$data._status.active.text },
      { value: this.$data._status.deleted.value, text: this.$data._status.deleted.text },
    ];
    this.tableParam.status = this.$data._status.active.value;
    this.tableParam.expiredDate_Start = null;
    this.tableParam.expiredDate_End = null;
    this.tableParam.terminatedDate_Start = null;
    this.tableParam.terminatedDate_End = null;
    this.tableParam.company = "";
    this.tableParam.swsId = "";
    this.callTable();
  },
  methods: {
     downloadSetting() {
      this.showLoadingScreen();
      let data = {
        expiredDate_Start: this.tableParam.expiredDate_Start,
        expiredDate_End: this.tableParam.expiredDate_End,
        terminatedDate_Start: this.tableParam.terminatedDate_Start,
        terminatedDate_End: this.tableParam.terminatedDate_End,
        company: this.tableParam.company,
        swsId:  this.tableParam.swsId,
        status: this.tableParam.status
      }
      api.downloadFile(apiUrl.sws.download,data,"sws.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    dateRangeChangeExpiry(dateRange) {
      this.tableParam.expiredDate_Start = this.convertDateObjToDateStr(dateRange.start);
      this.tableParam.expiredDate_End = this.convertDateObjToDateStr(dateRange.end);
    },
     dateRangeChangeTerminate(dateRange) {
      this.tableParam.terminatedDate_Start = this.convertDateObjToDateStr(dateRange.start);
      this.tableParam.terminatedDate_End = this.convertDateObjToDateStr(dateRange.end);
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.sws.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDetails(data) {
      this.$router.push({ name: 'AddEditSWS', params: { id: data.oid } });
    },
    addNew() {
      this.$router.push({ name: 'AddEditSWS' });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text){
      this.tableParam.searchtext = text;
      this.callTable();
    },
    changeFilterName: _.debounce(function (e) {
      this.tableParam.company = e.target.value;
    }, 500),
    changeFilterSwsId: _.debounce(function (e) {
      this.tableParam.swsId = e.target.value;
    }, 500),
    changeFilterStatus(e) {
      this.tableParam.status = e;
    }
  }
}
</script>
