<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col-md-10">
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Corporate Name:</div>
                <div class="col-sm-6">
                 
                  <span v-if="isDefaultAccount">{{dataObj.corporateName}}</span>
                   <a v-else href="javascript:;" @click="goToProfile">{{dataObj.corporateName}}</a>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Corporate Type:</div>
                <div class="col-sm-6">
                  {{dataObj.corporateTypeDisplay}}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Full Name:<span class="text-danger">*</span></div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.fullName"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Email:</div>
                <div class="col-sm-6">
                  {{dataObj.userName}}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Mobile No:</div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="dataObj.mobileNumber"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Enable 2 Factor Authentication:</div>
                <div class="col-sm-6">
                  <input
                    type="checkbox"
                    class="hover"
                    v-model="dataObj.enable2FactorAuth"
                  />
                </div>
              </div>
              <div v-if="wiType" class="row mb-3">
                <div class="col-sm-4 text-secondary">Welding Inspector Type:</div>
                <div class="col-sm-6">
                 {{wiType}} 
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-success m-1 float-end" @click="clickSave">
            Save Profile
          </button>
        </div>
        <div>
          <span class="text-danger small">* Denotes Mandatory Field</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiUrl from "@/common/apiurl";
import api from "@/common/api";

export default {
  name: "Profile",

  components: {},

  props: {},

  data() {
    return {
      oid: 0,
      dataObj: {},
      timezone: api.TIMEZONE,
      isDefaultAccount: true,
      wiType:''
    };
  },
  beforeMount() {

  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Profile' }
    ]);
    this.getProfile();
    let userType = this.getLoggedInUser().userGroupType;
    if(userType != this.$data._userGroupType.admin.value && userType != this.$data._userGroupType.sws.value &&userType != this.$data._userGroupType.mom.value){
      this.isDefaultAccount = false;
    }
    let wi = this.getLoggedInUser().wiType;
    this.wiType = this.getCorporateTypeTextByValue(wi);
     console.log(this.wiType);
  },

  methods: {
    getProfile() {
      let url = apiUrl.users.getUserProfile;
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        console.log(this.dataObj)
      });
    },
    clickSave() {
      let msg = this.validate();
      if (msg) {
        this.showMessage(msg, "warning");
        return;
      }

      let subUrl = "";
      subUrl = apiUrl.users.saveUserProfile;

      

      api
        .regularPost(subUrl, this.dataObj, this.$store.state.token)
        .then((response) => {
          if (response.status == 200) {
            this.showMessage("Successfully saved.", "success"); 
          } else if (
            response.status == 400 ||
            response.errorText.response.status == 400
          ) {
            this.showMessage(response.errorText.response.data.message, "error");
          } else {
            this.showMessage(window.message.unable_proceed, "error");
          }
        });
    },
    validate() {
      let msg = "";
      if (!this.dataObj.userName) msg += "Email cannot be blank!";
      if (!this.dataObj.fullName) msg += "Full Name cannot be blank!";
      return msg;
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    showAudit() {
      this.showAuditDialog(true);
    },
    goToProfile(){
      this.$router.push({ name: 'CorporateProfile', params: { id: this.dataObj.corporateOid, type: this.type } });
    }
  },
};
</script>
