<template>
<div>
  <h5 class="mb-3">Setting - {{type}}</h5>
  <div>
    <button class="btn btn-primary float-end mb-2" @click="addSetting">Add</button>
    <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button>
  </div>
  <div>
     <TableFilter @changeText="changeTblFilter" />
    <table class="table table-sm">
      <thead>
      <tr>
        <SortColumn @click="sortColumn" sortBy="code" colGroup="tbl1">Code</SortColumn>
        <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
        <SortColumn @click="sortColumn" v-show="!IsNameAndValueSame" sortBy="value" colGroup="tbl1">Value</SortColumn>
        <!-- <SortColumn @click="sortColumn" sortBy="group" colGroup="tbl1">Group</SortColumn> -->
        <SortColumn v-show="type == $data._settingGroup.branchCode" @click="sortColumn" sortBy="parent" colGroup="tbl1">Bank</SortColumn>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.code}}</td>
          <td>{{i.name}}</td>
          <td v-show="!IsNameAndValueSame">{{i.value}}</td>
          <!-- <td>{{i.group}}</td> -->
          <td v-show="type == $data._settingGroup.branchCode">{{i.parentSetting}}</td>
          <td><button class="btn btn-sm btn-primary" @click="clickEdit(i)">Edit</button></td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
export default {
  name: 'Settings',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      type:'',
      IsNameAndValueSame:false
    }
  },
  destroyed() {
    this.clearBreadcrumb(); 
  },
  beforeMount() {
    let type = this.$route.params.type;
    if (type) {
      this.type =type;
    }
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Settings', url: '/mainsetting' }, { name: this.type }
    ]);

    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      this.tableParam.group = this.type;
      api.regularPost(apiUrl.settings.list, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditSetting', params: { id: data.oid,type: this.type } });
    },
    addSetting() {
      this.$router.push({ name: 'AddEditSetting', params: {type: this.type } });
    },
    downloadSetting() {
      this.showLoadingScreen();
      let data = {
        groupType:this.type
      }
      api.downloadFile(apiUrl.settings.download,data,this.type+".xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text) {
      this.tableParam.searchtext = text;
      this.callTable();
    },
  }
}
</script>
