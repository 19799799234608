<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div>
    <button class="btn btn-primary float-end mb-2" @click="add">Add</button>
  </div>
        <div style="overflow-x: auto;">
         <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <SortColumn @click="sortColumn" sortBy="jobCategory" colGroup="tbl1">Job Category</SortColumn>
              <SortColumn @click="sortColumn" sortBy="site" colGroup="tbl1">Location</SortColumn>
              <SortColumn @click="sortColumn" sortBy="project" colGroup="tbl1">Project Code</SortColumn>
              <SortColumn @click="sortColumn" sortBy="inCharge" colGroup="tbl1">User In Charge</SortColumn>
              <th style="width:115px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in tableParam.listData" :key="idx">
              <td>{{i.jobCategoryDisplay}}</td>
              <td>{{i.siteDisplay}}</td>
              <td>{{i.projectDisplay}}</td>
             <td>{{i.inChargeDisplay}}</td>
              <td>
                <button class="btn btn-sm btn-primary" @click="clickDetails(i)">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
    <Pagination :pagingParam="tableParam" @updatePaging="updatePaging" />

  </div>
          <ModalDiaglog size="xl" :show="showModal" :onClose="()=>{showModal=false}" :hasHeader="true" :title="title"
          :showSubmitBtn1="false">
          <div v-if="showModal">
            <div class="row">
              <div class="col-sm-12">
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Job Category:
                  </div>
                  <div class="col-sm-8">
                    <v-select label="text" :class="error.jobCategory && 'control-error'" v-model="selectedJobCategory" :options="jobCats" @input="changeJobCategory"></v-select>
                    
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Location:
                  </div>
                  <div class="col-sm-8">
                    <v-select label="name" :class="error.site && 'control-error'" v-model="selectedSite" :options="sites" @input="changeSite"></v-select>
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Project Code:
                  </div>
                  <div class="col-sm-8">
                    <v-select label="projectCode" :class="error.project && 'control-error'" v-model="selectedProject" :options="projects" @input="changeProject"></v-select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    User In-Charge:
                  </div>
                  <div class="col-sm-8">
                    <v-select label="fullName" :class="error.user && 'control-error'" v-model="selectedUser" :options="users" @input="changeUser"></v-select>
                  </div>
                </div>
                <button  class="btn btn-primary m-1 float-end" style="width:100px;" @click="saveItem">Save</button>
                 <button  class="btn btn-danger m-1" style="width:100px;" @click="deleteItem">Delete</button>
              </div>
            </div>
          </div>
        </ModalDiaglog>   
</div>
</template>

<script>

import api from "@/common/api";
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'IndustryPreference',
  components: {
    ModalDiaglog,
    ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      error: {},
      jobCats: [],
      sites: [],
      projects: [],
      users: [],
      selectedJobCategory: null,
      selectedSite: null,
      selectedUser: null,
      selectedProject: null,
      dataObj: {},
      showModal: false,
      title: ''
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Industry Preference' }
    ]);

    this.jobCats = [
      { value: null, text: "<All>" },
      { value: this.$data._jobCategory.construction.value, text: this.$data._jobCategory.construction.text },
      { value: this.$data._jobCategory.maintenance.value, text: this.$data._jobCategory.maintenance.text },
      { value: this.$data._jobCategory.shutdown.value, text: this.$data._jobCategory.shutdown.text }
    ];
    this.getPreparedData();
    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.industryPreference.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    getPreparedData() {
      this.tableParam.listData = [];
      api.regularGet(apiUrl.industryPreference.getPreparedData).then((response) => {
        if (response.status == 200) {
          let data = response.data;
          this.sites.push({ name: 'Any', oid: 0 });
          data.sites.forEach(s => {
            this.sites.push(s);
          });
          this.projects.push({ projectCode: 'Any', oid: 0 });
          data.projects.forEach(s => {
            this.projects.push(s);
          });
          this.users.push({ fullName: 'Any', oid: 0 });
          data.users.forEach(s => {
            this.users.push(s);
          });
          this.users.push({ fullName: '<No Endorsement>', oid: -1 });
        }
      });
    },
    clickDetails(data) {
      this.dataObj = {};
      this.dataObj.oid = data.oid;
      this.dataObj.jobCategory = data.jobCategory;
      this.dataObj.siteOid = data.siteOid;
      this.dataObj.inChargeOid = data.inChargeOid;
      this.dataObj.projectOid = data.projectOid;
      this.selectedJobCategory = this.jobCats.find(x => x.value == data.jobCategory);
      this.selectedSite = this.sites.find(x => x.oid == data.siteOid);
      if (data.isAutoEndorsement) {
        this.selectedUser = this.users.find(x => x.oid == -1);
      }
      else {
        this.selectedUser = this.users.find(x => x.oid == data.inChargeOid);
      }
      this.selectedProject = this.projects.find(x => x.oid == data.projectOid);
      this.showModal = true;
    },
    add() {
      this.selectedJobCategory = null;
      this.selectedSite = null;
      this.selectedUser = null;
      this.selectedProject = null;
      this.dataObj = [];
      this.showModal = true;
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    deleteItem() {
      let data = {
        oid: this.dataObj.oid
      }
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove Ref ' + data.oid + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.industryPreference.delete, data, this.$store.state.token).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.callTable();
                this.showModal = false;
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    saveItem() {
      let subUrl = ''
      let data = {};

      if (this.dataObj.oid) {
        subUrl = apiUrl.industryPreference.update;
        data = {
          oid: this.dataObj.oid,
          jobCategory: this.dataObj.jobCategory,
          siteOid: this.dataObj.siteOid,
          projectOid: this.dataObj.projectOid,
          inChargeOid: this.dataObj.inChargeOid
        };
      } else {
        subUrl = apiUrl.industryPreference.insert;
        data = {
          jobCategory: this.dataObj.jobCategory,
          siteOid: this.dataObj.siteOid,
          projectOid: this.dataObj.projectOid,
          inChargeOid: this.dataObj.inChargeOid
        };
      }
      console.log(data);
      if (!this.validate(data))
        return;
      this.dataObj.corporateOid = 0;
      console.log(this.dataObj);
      this.showLoadingScreen();
      api.regularPost(subUrl, data).then((response) => {
        console.log(response);
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.showModal = false;
          this.callTable();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showModal = false;
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showModal = false;
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    changeJobCategory(obj) {
      if (obj) {
        this.dataObj.jobCategory = obj.value;
      }
    },
    changeSite(obj) {
      if (obj) {
        this.dataObj.siteOid = obj.oid;
      }
    },
    changeProject(obj) {
      if (obj) {
        this.dataObj.projectOid = obj.oid;
      }
    },
    changeUser(obj) {
      if (obj) {
        this.dataObj.inChargeOid = obj.oid;
      }
    },
    validate(data) {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      console.log(data);
      //if (!data.jobCategory) { this.error.jobCategory = true; isValid = false; }
      console.log(data.siteOid);
      if (!data.siteOid && data.siteOid != 0) { this.error.site = true; isValid = false; }
      if (!data.projectOid && data.projectOid != 0) { this.error.project = true; isValid = false; }
      if (!data.inChargeOid && data.inChargeOid != 0) { this.error.user = true; isValid = false; }

      this.$forceUpdate();
      return isValid;
    },
  }
}
</script>
