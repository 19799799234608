<template>
<div>
  <h4>Worker Skill Information - Level 1 Report</h4>
  <div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Place of Assignment
      </div>
      <div class="col-md-3">
        <FormSelect :list="locations" value="oid" text="desc" :existing="locationOid"
        @change="locationOid=$event" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">
        Assignment Date
      </div>
      <div class="col-md-3">
        <DatePicker @updatedate="changeAssignDate" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3 text-secondary">

      </div>
      <div class="col-md-3 text-end">
        <button class="btn btn-primary" @click="clickSearh">Submit</button>
      </div>
      <div class="col-md-6 text-end">
        <button class="btn btn-success" @click="downloadExcel">Downlaod Excel</button>
      </div>
    </div>
  </div>

  <div class="mb-2 mt-2" v-for="(i, idx) in reportData" :key="idx">
    <hr/>
    <div class="mb-2"><strong>{{i.corporate}}</strong></div>
    <div class="mb-2 table-responsive">
      <table class="table">
        <thead>
          <th>Skill</th>
          <th># Certified Single Skill Workers</th>
          <th># Certified Multi Skill workers</th>
          <th># UnSkilled Workers</th>
          <th>Total Workers</th>
          <th>% Skilled Workers</th>
        </thead>
        <tbody>
          <tr v-for="(d, idx1) in i.listData" :key="idx1">
            <td><a href="javascript:;" @click="clickDetails(d, i.corporateOid)">{{d.skill}}</a></td>
            <td>{{d.certifiedSingleSkillWorkers}}</td>
            <td>{{d.certifiedMultiSkillWorkers}}</td>
            <td>{{d.certifiedUnSkilledWorkers}}</td>
            <td>{{d.totalWorkers}}</td>
            <td>{{d.skilledWorkers}}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="mt-4">
    # certified single skill workers = workers with only one skill registered, and is certified with that skill.<br/>
    # certified multi skill workers = workers with multi-skill registered, either one of the skill is certified.<br/>
    # unskilled workers = worker with that skill registered but not certified or still under going OJT training.<br/>
  </div>

</div>
</template>

<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'WorkerSkillInformationReport',
  components: {
    ...Forms,
    ...Pickers,
  },
  data() {
    return {
      locationOid: null,
      locations: [],
      assignDate: null,
      assignDateStr: '',
      reportData: [],
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Skill Info Report' }
    ]);

    api.regularGet(apiUrl.site.getList).then((response) => {
      if (response.status == 200) {
        this.locations = response.data.map((x) => { return { oid: x.oid, desc: x.name + '-' + x.corporateName } });
        this.locations.unshift({ oid: null, desc: '<All>' });
      }
    });

    this.locationOid = null;
  },
  methods: {
    getData() {
      if (!this.assignDateStr) {
        this.showMessage('Please select assignment date.', 'warning');
        return;
      }
      this.showLoadingScreen();
      api.regularPost(apiUrl.report.workerSkillInfoReport, { locationOid: this.locationOid, assignDate: this.assignDateStr }).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.reportData = response.data;
        }
      });
    },
    changeAssignDate(date) {
      this.assignDateStr = this.convertDateObjToDateStr(date);
    },
    clickSearh() {
      this.getData();
    },
    downloadExcel() {
      if (!this.assignDateStr) {
        this.showMessage('Please select assignment date.', 'warning');
        return;
      }
      this.showLoadingScreen();
      api.downloadFile(apiUrl.report.workerSkillInfoReportExcel, { locationOid: this.locationOid, assignDate: this.assignDateStr }, 'WorkerSkillInfoReport.xlsx', () => {
        this.showMessage('Downloaded successfully.', 'success');
        this.hideLoadingScreen();
      });
    },

    clickDetails(d, corporateOid) {
      this.$router.push({
        name: 'WorkerSkillInformationDetailsReport',
        params: {
          skillOid: d.skillOid,
          corporateOid: corporateOid,
          locationOid: this.locationOid,
          assignDate: this.assignDateStr
        }
      });
    },

  }
}
</script>
