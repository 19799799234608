<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Corporates
              </div>
              <div class="col-sm-6">
                <v-select multiple label="corpName" v-model="selectedCorporates" :options="corporates" @input="changeCorporate"></v-select>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
              <button class="btn btn-success float-end  mb-2" style="margin-right:10px;" @click="callTable">Generate</button>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb-1">
      <div class="card-body">
         <div style="overflow-x: auto;">
          <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th>Contractor</th>
              <th>Name</th>
              <th>NRIC/FIN</th>
              <th>Welder ID</th>
              <th>Test Number</th>
              <th>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in param.listData" :key="idx">
              <td>{{i.corporateName}}</td>
              <td>{{i.name}}</td>
              <td>{{i.nricFin}}</td>
              <td>{{i.welderId}}</td>
              <td>{{i.testNumber}}</td>
              <td>{{i.skillTestExpiryDateDisplay}}</td>
            </tr>
          </tbody>
        </table>
         </div>
        
      </div>
    </div>

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'Welders',
  components: {
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      param:{
          contractorOids:[],
          listData:[]
      },
      selectedCorporates:[],
      corporates:[]
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Welders' }
    ]);
    let url = apiUrl.report.workerSummaryPreparedReport 
        api.regularGet(url,null ).then((response) => {
          this.corporates = response.data.corporates;
        });
    //this.callTable();
  },
  methods: {
    changeCorporate(list){
      this.param.contractorOids = [];
        list.forEach(e => {
          this.param.contractorOids.push(e.oid);
        });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    callTable() {
      this.showLoadingScreen();
      this.param.listData = [];
      api.regularPost(apiUrl.report.welder, this.param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.param.listData = response.data;
        }
      });
    },
    dateRangeChange(dateRange) {
      this.param.creditNote_Start = this.convertDateObjToDateStr(dateRange.start);
      this.param.creditNote_End = this.convertDateObjToDateStr(dateRange.end);
      this.callTable();
    },
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.report.welderDownload,this.param,"welders.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    }
  }
}
</script>
