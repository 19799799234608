<template>
  <div>
    <h5>Process Construction & Maintenance Scheme - Form A</h5>

    <div class="card">
      <div>
        <button class="btn btn-primary m-1 float-end" @click="clickFormADownload">Download</button>
      </div>
      <div class="card-body">
        <h5 class="card-title">(A) Company Profile</h5>
        <h6>Quota Application No.: {{dataObj.quotaApplicationNo}}</h6>

        <div class="row">
          <div class="col-sm-12">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Name of Company:
              </div>
              <div class="col-sm-8">
                <label v-if="!dataObj.isMOM" class="control-label">{{dataObj.nameOfCompany}}</label>
                <a v-else @click="clickContractor(dataObj.corporateOid)"
                  href="javascript:;">{{dataObj.nameOfCompany}}</a>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Company Address:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.companyAddress}}</label>

              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Contact Person:
              </div>
              <div class="col-sm-4">
                <label class="control-label">{{dataObj.contactPerson}}</label>

              </div>
              <div class="col-sm-4">
                <label class="control-label">{{dataObj.position}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="row mb-3">
              <div class="col-sm-12 text-secondary">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 text-secondary">
                Maintenance CPF A/C No. & Date Approved.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 text-secondary">
                Ownership (for process CPF A/C No.)
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Email:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.email}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                CPF A/C:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.cpfAc}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Local :
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.local}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Phone:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.phone}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Date:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.formDate}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Foreign :
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.foreign}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Fax:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.fax}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Country:
              </div>
              <div class="col-sm-8">
                <label class="control-label">{{dataObj.country}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            Key Process Activities
          </div>
          <div class="col-sm-8">
            <label class="control-label">{{dataObj.keyProcessActivities}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="margin-top:10px;">
      <div class="card-body">
        <h5 class="card-title">(B1) Details of Contract(s) With Sponsoring MNC(s) / Contract(s) between SubCont &
          MainCont.</h5>

        <div class="row" style="margin-top:20px;">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Client:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.clientName}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Contractor or PO Ref. No.:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.contractorOrPorefNo}}</label>
              </div>
            </div>
          </div>
        </div>
        <h6>Duration</h6>
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PO Start Date:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.durationStartDate}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PO End Date:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.durationEndDate}}</label>
              </div>
            </div>
          </div>
        </div>
        <h6>Contract Value for works undertaken by Applicant Company</h6>
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Total ($) per annum:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.applicantTotal}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Labour Cost ($) per annum:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.applicantLabourCost}}</label>
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>Total no. of existing PM workers sponsored by plant owner</h6>
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                PM Workers:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.plantOwerTotal}}</label>
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>No. of workers Applicant Company is applying IPS for</h6>
        <div class="row">
          <div class="col-sm-6">
            <h6>New</h6>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                PRC:
              </div>
              <div class="col-sm-3">
                <label class="control-label">{{dataObj.applicantPrc}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                NTS:
              </div>
              <div class="col-sm-3">
                <label class="control-label">{{dataObj.applicantNts}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h6>Renewal</h6>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                PRC:
              </div>
              <div class="col-sm-3">
                <label class="control-label">{{dataObj.renewalPrc}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                NTS:
              </div>
              <div class="col-sm-3">
                <label class="control-label">{{dataObj.renewalNts}}</label>
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <h6>Contract Summary</h6>
        <div class="row">
          <div class="col-sm-12">
            <label class="control-label">{{dataObj.contractSummary}}</label>
          </div>
        </div>
        <h6 style="margin-top:10px;">Brief Description of Workers’ Job Roles</h6>
        <div class="row">
          <div class="col-sm-12">
            <label class="control-label">{{dataObj.description}}</label>
          </div>
        </div>
        <h6 style="margin-top:10px;">Contract Attachments</h6>
        <div class="row">
          <div class="col-sm-6" style="overflow-x: auto;">
            <table class="table table-sm">
              <thead class="table-light">
                <tr>
                  <th>File Name</th>
                  <th style="width:115px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in dataObj.fileList" :key="idx">
                  <td>{{getFileDisplayName(i)}}</td>
                  <td>
                    <button class="btn btn-sm btn-primary" @click="clickDownload(i)">
                      <span>Download</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="margin-top:10px;">
      <div class="card-body">
        <h5 class="card-title">(B2) Details of additional contract(s)</h5>
        <div class="row">
          <div class="col-sm-12" style="overflow-x: auto;">

            <table class="table table-sm" style="width:100%;text-align:center;">
              <thead class="table-light">
                <tr>
                  <th style="width:100px;" rowspan="2">Plant owner</th>
                  <th style="width:150px;" rowspan="2">Main Contractor (if any)</th>
                  <th rowspan="2">Contract/PO Ref</th>
                  <th rowspan="2">Contract/PO Start Date / End Date</th>
                  <th colspan="2">Contract value for works undertaken by applicant company (to exclude value
                    subcontracted to 3rd party)</th>
                  <th rowspan="2">Contract summary</th>
                  <th rowspan="2">Brief Description of Workers' job Roles</th>
                  <th rowspan="2">No. of existing process maintenance worker sponsored by stated plant owner for stated
                    job</th>
                  <th rowspan="2">Additional Remarks</th>
                </tr>
                <tr>
                  <th>Total ($) - per annum</th>
                  <th>Labour Cost ($) - per annum</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idx) in contractors" :key="idx">
                  <td>{{i.planOwner}}</td>
                  <td>{{i.mainContractorName}}</td>
                  <td>{{i.contractPoref}}</td>
                  <td>{{i.contractPostartDate}} / {{i.contractPoendDate}}</td>
                  <td>{{i.totalAmount}}</td>
                  <td>{{i.labourCost}}</td>
                  <td>{{i.contractSummary}}</td>
                  <td>{{i.description}}</td>
                  <td>{{i.existingProcess}}</td>
                  <td>{{i.additionalRemarks}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card" style="margin-top:10px;">
        <div class="card-body">
          <h5 class="card-title">(C) DECLARATION & ENDORSEMENT</h5>
          <div class="row">
            <div class="col-sm-12">
              <!-- For Contractor -->
              <div class="row">
                <h5 class="card-title">Contractor Declaration (Applicant)</h5>
                <!-- <div class="col-sm-12">
              <div v-if="dataObj.canReject || dataObj.canWithdraw" class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Remarks:
                </div>
                <div class="col-sm-6">
                  <textarea class="form-control" rows="6" :class="error.rejectReason && 'control-error'"
                    v-model="rejectReason"></textarea>
                </div>
              </div>
              <div v-else class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Remarks:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{dataObj.rejectReason}}</label>
                </div>
              </div>
                </div> -->
                <div class="col-sm-12">
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, <label class="control-label">{{dataObj.contactPerson}}</label> , in my capacity as <label
                        class="control-label">{{dataObj.position}}</label>
                      of the Applicant Company mentioned above, hereby declare that my company has been awarded a
                      contract
                      by <label class="control-label">{{dataObj.awardingCompany}}</label>.
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">I declare that the
                      facts
                      stated in this application are true and that the foreign workers requested under this application
                      will serve the Process Industry maintenance activities only.</div>

                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I further declare that the above Prior Approval application made by my company is meant for the
                      recruitment of foreign workers for the purpose of the above contract. My company has read and
                      understood all the work permit conditions in the enclosed guide and agrees to abide by them. We
                      will
                      also comply with the provisions of the Employment Act. I understand that failure to comply with
                      the
                      conditions, provisions and requirements will affect my company's present and future work permit
                      applications. The Work Pass Division also reserves the right to revoke work permits issued if the
                      work permit conditions are not compiled with or the documents submitted in supporting the
                      applications are not valid.
                    </div>
                  </div>
                  <div class="row mb-3" v-if="dataObj.isContractorShow">
                    <div class="col-sm-3 text-secondary">
                      Name:
                    </div>
                    <div class="col-sm-6">
                      <label class="control-label">{{dataObj.contactPerson}}</label>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="dataObj.isContractorShow">
                    <div class="col-sm-3 text-secondary">
                      Email:
                    </div>
                    <div class="col-sm-6">
                      <label class="control-label">{{dataObj.email}}</label>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="dataObj.isContractorShow">
                    <div class="col-sm-3 text-secondary">
                      Fax:
                    </div>
                    <div class="col-sm-6">
                      <label class="control-label">{{dataObj.fax}}</label>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="dataObj.isContractorShow">
                    <div class="col-sm-3 text-secondary">
                      Tel:
                    </div>
                    <div class="col-sm-6">
                      <label class="control-label">{{dataObj.phone}}</label>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <!-- For Sub Contractor -->
              <div class="row">
                <div v-if="dataObj.isSubShowHeader" class="col-sm-12">
                  <h5 class="card-title">Sub Contractor Declaration</h5>

                  <!-- Need to check readonly or not -->
                  <div class="row mb-3" v-if="dataObj.isSubShowInput">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, <input type="text" class="form-control"
                        :class="error.subContractorApprovalPerson && 'control-error'"
                        v-model="dataObj.subContractorApprovalPerson" style="display:inline-block;width:200px;" /> , in
                      my
                      capacity as
                      <input type="text" class="form-control"
                        :class="error.subContractorApprovalPersonDesignation && 'control-error'"
                        v-model="dataObj.subContractorApprovalPersonDesignation"
                        style="display:inline-block;width:200px;" />
                      of the <input type="text" class="form-control"
                        :class="error.subContractorApprovalCorporateName && 'control-error'"
                        v-model="dataObj.subContractorApprovalCorporateName"
                        style="display:inline-block;width:500px;" />
                      hereby declare that {{dataObj.nameOfCompany}} has been awarded a contract by
                      {{dataObj.awardingCompany}} to work on the project and the latter is a bona fide sub-contractor
                      for
                      the project.
                    </div>
                  </div>
                  <!-- Need to check readonly or not -->
                  <div class="row mb-3" v-else>
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, {{dataObj.subContractorApprovalPerson}} , in my capacity as
                      {{dataObj.subContractorApprovalPersonDesignation}}
                      of the {{dataObj.subContractorApprovalCorporateName}} hereby declare that
                      {{dataObj.nameOfCompany}}
                      has been awarded a contract by {{dataObj.awardingCompany}} to work on the project and the latter
                      is
                      a bona fide sub-contractor for the project.
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">My company undertakes
                      to
                      be jointly responsible with the sub-contractor for the foreign workers employed by the
                      sub-contractor under this prior approval, should they be abandoned by the sub-contractor as a
                      result
                      of sub-contractor defaulting levy payments leading to the cancellation of the workers' work
                      permits.
                      Under such circumstances, my company will undertake to accommodate the abandoned workers as soon
                      as
                      possible. However, the responsibility would cease once the project awarded to the subcontractor
                      ends. I understand that if my company fails to do so without valid ground or reasonable cause, my
                      company could be debarred from employing foreign workers of which such period may be decided by
                      the
                      Controller of Work Permits.</div>

                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I further declare that the above Prior Approval application made by my company is meant for the
                      recruitment of foreign workers for the purpose of the above contract. My company has read and
                      understood all the work permit conditions in the enclosed guide and agrees to abide by them. We
                      will
                      also comply with the provisions of the Employment Act. I understand that failure to comply with
                      the
                      conditions, provisions and requirements will affect my company's present and future work permit
                      applications. The Work Pass Division also reserves the right to revoke work permits issued if the
                      work permit conditions are not compiled with or the documents submitted in supporting the
                      applications are not valid.
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12 text-secondary">
                      Sub Contractor Remark <span v-if="dataObj.isSubShowInput">(max 700 characters)</span>
                    </div>
                    <div class="col-sm-12">
                      <textarea v-if="dataObj.isSubShowInput" maxlength="700" class="form-control" rows="6"
                        :class="error.subContractorRemarks && 'control-error'"
                        v-model="dataObj.subContractorRemarks"></textarea>
                      <label v-else class="control-label">{{dataObj.subContractorRemarks}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3" v-if="dataObj.isSubShowInput">
                        <div class="col-sm-6 text-secondary">
                          Name:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isSubShowInput" type="text" class="form-control"
                            :class="error.subContractorApprovalPerson && 'control-error'"
                            v-model="dataObj.subContractorApprovalPerson" />
                          <label v-else class="control-label">{{dataObj.subContractorApprovalPerson}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isSubShowInput">
                        <div class="col-sm-6 text-secondary">
                          Email:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isSubShowInput" type="text" class="form-control"
                            :class="error.subContractorApprovalPersonEmail && 'control-error'"
                            v-model="dataObj.subContractorApprovalPersonEmail" />
                          <label v-else class="control-label">{{dataObj.subContractorApprovalPersonEmail}}</label>
                        </div>
                      </div>
                      

                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3" v-if="dataObj.isSubShowInput">
                        <div class="col-sm-6 text-secondary">
                          Date:
                        </div>
                        <div class="col-sm-6">
                          {{dataObj.todayDate}}
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isSubShowInput">
                        <div class="col-sm-6 text-secondary">
                          Tel:
                        </div>
                        <div class="col-sm-6" v-if="dataObj.isSubShowInput">
                          <input v-if="dataObj.isSubShowInput" type="text" class="form-control"
                            :class="error.subContractorApprovalPersonPhone && 'control-error'"
                            v-model="dataObj.subContractorApprovalPersonPhone" />
                          <label v-else class="control-label">{{dataObj.subContractorApprovalPersonPhone}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isSubShowInput">
                        <div class="col-sm-6 text-secondary">
                          Fax:
                        </div>
                        <div class="col-sm-6" v-if="dataObj.isSubShowInput">
                          <input v-if="dataObj.isSubShowInput" type="text" class="form-control"
                            :class="error.subContractorApprovalPersonFax && 'control-error'"
                            v-model="dataObj.subContractorApprovalPersonFax" />
                          <label v-else class="control-label">{{dataObj.subContractorApprovalPersonFax}}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>

              </div>
              <!-- For Main Contractor -->
              <div class="row">

                <div class="col-sm-12" v-if="dataObj.isMainShowHeader">
                  <h5 class="card-title">Main Contractor Declaration</h5>

                  <!-- Need to check readonly or not -->
                  <div class="row mb-3" v-if="dataObj.isMainShowInput">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, <input type="text" class="form-control"
                        :class="error.mainContractorApprovalPerson && 'control-error'"
                        v-model="dataObj.mainContractorApprovalPerson" style="display:inline-block;width:200px;" /> , in
                      my capacity as
                      <input type="text" class="form-control"
                        :class="error.mainContractorManagerDesignation && 'control-error'"
                        v-model="dataObj.mainContractorManagerDesignation" style="display:inline-block;width:200px;" />
                      of the <input type="text" class="form-control"
                        :class="error.mainContractorApprovalCorporateName && 'control-error'"
                        v-model="dataObj.mainContractorApprovalCorporateName"
                        style="display:inline-block;width:500px;" /> hereby declare that {{dataObj.nameOfCompany}} has
                      been awarded a contract by {{dataObj.awardingCompany}} to work on the project and the latter is a
                      bona fide sub-contractor for the project.
                    </div>
                  </div>
                  <!-- Need to check readonly or not -->
                  <div v-else class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, {{dataObj.mainContractorApprovalPerson}} , in my capacity as
                      {{dataObj.mainContractorManagerDesignation}}
                      of the {{dataObj.mainContractorApprovalCorporateName}} hereby declare that
                      {{dataObj.nameOfCompany}} has been awarded a contract by {{dataObj.awardingCompany}} to work on
                      the project and the latter is a bona fide sub-contractor for the project.
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">My company undertakes
                      to be jointly responsible with the sub-contractor for the foreign workers employed by the
                      sub-contractor under this prior approval, should they be abandoned by the sub-contractor as a
                      result of sub-contractor defaulting levy payments leading to the cancellation of the workers' work
                      permits. Under such circumstances, my company will undertake to accommodate the abandoned workers
                      as soon as possible. However, the responsibility would cease once the project awarded to the
                      subcontractor ends. I understand that if my company fails to do so without valid ground or
                      reasonable cause, my company could be debarred from employing foreign workers of which such period
                      may be decided by the Controller of Work Permits.</div>

                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I further declare that the above Prior Approval application made by my company is meant for the
                      recruitment of foreign workers for the purpose of the above contract. My company has read and
                      understood all the work permit conditions in the enclosed guide and agrees to abide by them. We
                      will
                      also comply with the provisions of the Employment Act. I understand that failure to comply with
                      the
                      conditions, provisions and requirements will affect my company's present and future work permit
                      applications. The Work Pass Division also reserves the right to revoke work permits issued if the
                      work permit conditions are not compiled with or the documents submitted in supporting the
                      applications are not valid.
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-12 text-secondary">
                      Main Contractor Remark <span v-if="dataObj.isMainShowInput">(max 700 characters)</span>
                    </div>
                    <div class="col-sm-12">
                      <textarea v-if="dataObj.isMainShowInput" maxlength="700" class="form-control" rows="6"
                        :class="error.mainContractorRemarks && 'control-error'"
                        v-model="dataObj.mainContractorRemarks"></textarea>
                      <label v-else class="control-label">{{dataObj.mainContractorRemarks}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3" v-if="dataObj.isMainShowInput">
                        <div class="col-sm-6 text-secondary">
                          Name:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMainShowInput" type="text" class="form-control"
                            :class="error.mainContractorApprovalPerson && 'control-error'"
                            v-model="dataObj.mainContractorApprovalPerson" />
                          <label v-else class="control-label">{{dataObj.mainContractorApprovalPerson}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMainShowInput">
                        <div class="col-sm-6 text-secondary">
                          Email:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMainShowInput" type="text" class="form-control"
                            :class="error.mainContractorApprovalPersonEmail && 'control-error'"
                            v-model="dataObj.mainContractorApprovalPersonEmail" />
                          <label v-else class="control-label">{{dataObj.mainContractorApprovalPersonEmail}}</label>
                        </div>
                      </div>

                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3" v-if="dataObj.isMainShowInput">
                        <div class="col-sm-6 text-secondary">
                          Date:
                        </div>
                        <div class="col-sm-6">
                          {{dataObj.todayDate}}
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMainShowInput">
                        <div class="col-sm-6 text-secondary">
                          Tel:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMainShowInput" type="text" class="form-control"
                            :class="error.mainContractorApprovalPersonPhone && 'control-error'"
                            v-model="dataObj.mainContractorApprovalPersonPhone" />
                          <label v-else class="control-label">{{dataObj.mainContractorApprovalPersonPhone}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMainShowInput">
                        <div class="col-sm-6 text-secondary">
                          Fax:
                        </div>
                        <div class="col-sm-6" v-if="dataObj.isMainShowInput">
                          <input v-if="dataObj.isMainShowInput" type="text" class="form-control"
                            :class="error.mainContractorApprovalPersonFax && 'control-error'"
                            v-model="dataObj.mainContractorApprovalPersonFax" />
                          <label v-else class="control-label">{{dataObj.mainContractorApprovalPersonFax}}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>

              </div>
              <!-- For MNC -->
              <div class="row">
                <div v-if="dataObj.isMNCShowHeader" class="col-sm-12">
                   <h5 class="card-title">MNC Declaration</h5>
                  <!-- Need to check readonly or not -->
                  <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, <input type="text" class="form-control" :class="error.mncApprovalPerson && 'control-error'"
                        v-model="dataObj.mncApprovalPerson" style="display:inline-block;width:500px;" /> , in my
                      capacity as
                      <input type="text" class="form-control"
                        :class="error.mncApprovalPersonDesignation && 'control-error'"
                        v-model="dataObj.mncApprovalPersonDesignation" style="display:inline-block;width:200px;" /> of
                      <input type="text" class="form-control" :class="error.mncApprovalCorporateName && 'control-error'"
                        v-model="dataObj.mncApprovalCorporateName" style="display:inline-block;width:500px;" /> hereby
                      support this application based on the contracts in Section (B).
                    </div>
                  </div>
                  <!-- Need to check readonly or not -->
                  <div v-else class="row mb-3">
                    <div class="col-sm-12" style="text-align: justify;text-justify: inter-word;">
                      I, {{dataObj.mncApprovalPerson}} , in my capacity as
                      {{dataObj.mncApprovalPersonDesignation}} of {{dataObj.mncApprovalCorporateName}} hereby support
                      this application based on the contracts in Section (B).
                    </div>
                  </div>
                  <div class="row mb-3">
                   
                    <div class="col-sm-12 text-secondary">
                      MNC Remark <span v-if="dataObj.isMNCShowInput">(max 700 characters)</span>
                    </div>
                    <div class="col-sm-12">
                      <textarea v-if="dataObj.isMNCShowInput" maxlength="500" class="form-control" rows="6"
                        :class="error.mncRemarks && 'control-error'" v-model="dataObj.mncRemarks"></textarea>
                      <label v-else class="control-label">{{dataObj.mncRemarks}}</label>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                        <div class="col-sm-6 text-secondary">
                          MNC's CPF Account No.:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMNCShowInput" type="text" class="form-control"
                            :class="error.mncApprovalPersonCPF && 'control-error'"
                            v-model="dataObj.mncApprovalPersonCPF" />
                          <label v-else class="control-label">{{dataObj.mncApprovalPersonCPF}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                        <div class="col-sm-6 text-secondary">
                          Name:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMNCShowInput" type="text" class="form-control"
                            :class="error.mncApprovalPerson && 'control-error'" v-model="dataObj.mncApprovalPerson" />
                          <label v-else class="control-label">{{dataObj.mncApprovalPerson}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                        <div class="col-sm-6 text-secondary">
                          Email:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMNCShowInput" type="text" class="form-control"
                            :class="error.mncApprovalPersonEmail && 'control-error'"
                            v-model="dataObj.mncApprovalPersonEmail" />
                          <label v-else class="control-label">{{dataObj.mncApprovalPersonEmail}}</label>
                        </div>
                      </div>

                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                        <div class="col-sm-6 text-secondary">
                          Date:
                        </div>
                        <div class="col-sm-6">
                          {{dataObj.todayDate}}
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                        <div class="col-sm-6 text-secondary">
                          Tel:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMNCShowInput" type="text" class="form-control"
                            :class="error.mncApprovalPersonPhone && 'control-error'"
                            v-model="dataObj.mncApprovalPersonPhone" />
                          <label v-else class="control-label">{{dataObj.mncApprovalPersonPhone}}</label>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="dataObj.isMNCShowInput">
                        <div class="col-sm-6 text-secondary">
                          Fax:
                        </div>
                        <div class="col-sm-6">
                          <input v-if="dataObj.isMNCShowInput" type="text" class="form-control"
                            :class="error.mncApprovalPersonFax && 'control-error'"
                            v-model="dataObj.mncApprovalPersonFax" />
                          <label v-else class="control-label">{{dataObj.mncApprovalPersonFax}}</label>
                        </div>
                      </div>                      
                    </div>
                  </div>

                  <hr />
                </div>

              </div>
              <!-- Common -->
              <div class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Submit to Sub Contractor:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{dataObj.subContractorName}}</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Submit to Main Contractor:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{dataObj.mainContractorName}}</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Submit to MNC:
                </div>
                <div class="col-sm-6">
                  <label v-if="!dataObj.isMOM" class="control-label">{{dataObj.mncName}}</label>
                  <a v-else @click="clickMNC(dataObj.industryMncoid)" href="javascript:;">{{dataObj.mncName}}</a>
                </div>
              </div>
              <hr/>
              <div class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Application Status:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{getQuotaTextByValue(dataObj.status)}}</label>
                </div>
              </div>
              <div v-if="dataObj.rejectReason" class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  Reject Reason:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{dataObj.rejectReason}}</label>
                </div>
              </div>
              <div v-if="dataObj.isMOMShowInput && dataObj.isMOMShowHeader" class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  MOM Remarks <span v-if="dataObj.isMainShowInput">(max 700 characters)</span>:
                </div>
                <div class="col-sm-6">
                  <textarea class="form-control" rows="6" :class="error.momRemarks && 'control-error'"
                    v-model="dataObj.momRemarks"></textarea>
                </div>
              </div>
              <div v-else-if="!dataObj.isMOMShowInput && dataObj.isMOMShowHeader && dataObj.status != $data._quotaStatus.Submitted.value" class="row mb-3">
                <div class="col-sm-3 text-secondary">
                  MOM Remarks:
                </div>
                <div class="col-sm-6">
                  <label class="control-label">{{dataObj.momRemarks}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-secondary m-1 float-end" @click="cancel">Cancel</button>
      <button v-if="dataObj.canReject" class="btn btn-success m-1 float-end" @click="click('A')">Approve</button>
      <button v-if="dataObj.canReject" class="btn btn-warning m-1 float-end" @click="click('R')">Reject</button>
      <button v-if="dataObj.canWithdraw" class="btn btn-warning m-1 float-end" @click="click('R')">Withdraw</button>
    </div>

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';
export default {
  name: 'ViewQuota',

  components: {
    ...Forms,
    ...Pickers
  },

  props: {

  },

  data() {
    return {
      todayDate:'',
      file: null,
      filestatus: 'stop',
      groupType:'',
      basicLogInfoUrl: '',
      oid: 0,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      parents: [],
      showAuditModal: false, 
      error: {},
      formDate:null,
      durationStartDate:null,
      durationEndDate:null,
      c1_ContractPostartDate:null,
      c1_ContractPoendDate:null,
      c2_ContractPostartDate:null,
      c2_ContractPoendDate:null,
      c3_ContractPostartDate:null,
      c3_ContractPoendDate:null,
      c4_ContractPostartDate:null,
      c4_ContractPoendDate:null,
      contractor_4:{},
      contractor_3:{},
      contractor_2:{},
      contractor_1:{},
      contractors:[],
      sub_contractors:[],
      main_contractors:[],
      mncList:[]
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.todayDate = dd + '/' + mm + '/' + yyyy;
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = "Online Quota App";
    this.setBreadcrumb([
      { name: 'List', url: '/quotalist'}, { name: pageName }
    ]);
    this.getData();
  },

  methods: {
    getFileDisplayName(original_name){
      let nameByArray = original_name.split('_');
      console.log(nameByArray);
      let displayName = '';
      if(nameByArray.length >= 2 && nameByArray[0] != ''){
        displayName = original_name.replace(nameByArray[0]+"_",'');
      }else{
        displayName = original_name;
      }
      return displayName;
    },
    clickDownload(e){
      this.showLoadingScreen();
      let data = {
        oid: this.dataObj.modifiedBy,
        fileName: e,
        corporateOid: this.dataObj.corporateOid
      }
      let displayFile = this.getFileDisplayName(e);
      api.downloadFile(apiUrl.quota.downloadFile,data,displayFile,() =>{
            this.hideLoadingScreen();
            this.showMessage("Successfully downloaded.", "success");
      });
    },
    clickFormADownload() {
      api.downloadFile(apiUrl.pdf.formA, { oid: this.oid }, 'FormA.pdf', () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    getData() {
      let url = apiUrl.quota.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        if(!response.data){
          this.showMessage("You have no permission to view this record.", "error");
          this.$router.push({ name: "FromContractors" });
        }
        this.dataObj = response.data;
        this.contractors = this.dataObj.nameOfContractorList;
        console.log(this.dataObj.fileList);
      });
    },
    cancel(){
      this.$router.push({ name: "FromContractors" });
    },
    click(e){
      if(e == "R"){
        if((this.dataObj.isSubShowInput && !this.dataObj.subContractorRemarks) ||(this.dataObj.isMainShowInput && !this.dataObj.mainContractorRemarks) || (this.dataObj.isMNCShowInput && !this.dataObj.mncRemarks)|| (this.dataObj.isMOMShowInput && !this.dataObj.momRemarks)){
          this.error.rejectReason = true;
            this.$forceUpdate();
            this.showMessage("Enter remark for reject", "warning");
          return;
        }
      }
      this.dataObj.status = e.value;
      let subUrl = apiUrl.quota.updateStatus;
      let param = {
        oid : this.oid,
        status: e,
        subContractorRemarks: this.dataObj.subContractorRemarks,
        mncRemarks: this.dataObj.mncRemarks,
        momRemarks: this.dataObj.momRemarks,
        mainContractorRemarks: this.dataObj.mainContractorRemarks,

        mncApprovalPerson: this.dataObj.mncApprovalPerson,
        momApprovalPerson: this.dataObj.momApprovalPerson,
        mncApprovalPersonDesignation: this.dataObj.mncApprovalPersonDesignation,
        mncApprovalPersonEmail: this.dataObj.mncApprovalPersonEmail,
        mncApprovalPersonFax: this.dataObj.mncApprovalPersonFax,
        mncApprovalPersonPhone: this.dataObj.mncApprovalPersonPhone,
        mncApprovalCorporateName: this.dataObj.mncApprovalCorporateName,
        mncApprovalPersonCPF: this.dataObj.mncApprovalPersonCPF,
        mainContractorManagerDesignation: this.dataObj.mainContractorManagerDesignation,
        mainContractorApprovalPersonEmail: this.dataObj.mainContractorApprovalPersonEmail,
        mainContractorApprovalPersonFax: this.dataObj.mainContractorApprovalPersonFax,
        mainContractorApprovalPersonPhone: this.dataObj.mainContractorApprovalPersonPhone,
        mainContractorApprovalCorporateName: this.dataObj.mainContractorApprovalCorporateName,
        mainContractorApprovalPersonCPF: this.dataObj.mainContractorApprovalPersonCPF,
        mainContractorApprovalPerson: this.dataObj.mainContractorApprovalPerson,
        subContractorApprovalPerson: this.dataObj.subContractorApprovalPerson,
        subContractorApprovalPersonDesignation: this.dataObj.subContractorApprovalPersonDesignation,
        subContractorApprovalPersonEmail: this.dataObj.subContractorApprovalPersonEmail,
        subContractorApprovalPersonFax: this.dataObj.subContractorApprovalPersonFax,
        subContractorApprovalPersonPhone: this.dataObj.subContractorApprovalPersonPhone,
        subContractorApprovalCorporateName: this.dataObj.subContractorApprovalCorporateName,
        subContractorApprovalPersonCPF: this.dataObj.subContractorApprovalPersonCPF
      }
      this.showLoadingScreen();
      api.regularPost(subUrl, param).then((response) => {
      this.hideLoadingScreen();
      if (response.status == 200) {
      this.showMessage("Successfully saved.", "success");
      this.$router.push({ name: "FromContractors"});
      }
      else if (response.status == 400 || response.errorText.response.status == 400) {
      this.showMessage(response.errorText.response.data.message, "error");
      }
      else {
      this.showMessage(window.message.clinics.unable_save, "error");
      }
      });
    },
    clickContractor(e){
      let routeData = this.$router.resolve({ name: 'ViewCorporate', params: { id: e, type: this.$data._corporateType.contractor.value } });
      window.open(routeData.href, '_blank');
    },
    clickMNC(e){
      let routeData = this.$router.resolve({ name: 'ViewCorporate', params: { id: e, type: this.$data._corporateType.industry.value } });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
