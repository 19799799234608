

export default {
  audit: {
    getActivityLog: '/Audit/GetActivityLog',
    getAuditLog: '/Audit/GetAuditLog',
  },

  account: {
    loginAuthenticateByAdmin: '/Account/LoginAuthenticateByAdmin',
    loginAuthenticate: '/Account/LoginAuthenticate',
    userClaims: '/Account/UserClaims',
    logOut: '/Account/LogOut',
    loginAuthenticateWithOtpCode: '/Account/LoginAuthenticateWithOtpCode',
    resendOTPCode: '/Account/ResendOTPCode',
  },

  users: {
    getUserRoles: '/User/GetUserRoles',
    saveUserRoles: '/User/SaveUserRoles',
    getUserProfile: '/User/GetUserProfile',
    saveUserProfile: '/User/SaveUserProfile',
    saveUserPasswordByAdmin: '/User/SaveUserPasswordByAdmin',
    saveUserPassword: '/User/SaveUserPassword',
    getAll: '/User/List',
    update: '/User/Update',
    insert: '/User/Insert',
    delete: '/User/Delete',
    getByOid: '/User/Details',
    getBasicLogInfo: '/User/GetBasicLogInfo',
    unlock: '/User/Unlock',
    reqestForgotPassword: '/User/ReqestForgotPassword',
    checkForgotPasswordReqestValid: '/User/CheckForgotPasswordReqestValid',
    forgotPasswordChange: '/User/ForgotPasswordChange',
    download: '/User/download'
  },

  tenants: {
    getAll: '/Tenant/List',
    getByOid: '/Tenant/Details',
    insert: '/Tenant/Insert',
    update: '/Tenant/Update',
    delete: '/Tenant/Delete',
    getBasicLogInfo: '/Tenant/GetBasicLogInfo'
  },

  settings: {
    download: '/Setting/Download',
    getAll: '/Setting/GetAll',
    list: '/Setting/List',
    getByFilter: '/Setting/getByFilter',
    getByOid: '/Setting/Details',
    delete: '/Setting/Delete',
    update: '/Setting/Update',
    insert: '/Setting/Insert',
    getByParentOid: '/Setting/GetByParentOid',
    getBasicLogInfo: '/Setting/GetBasicLogInfo'
  },

  tenantLocations: {
    getAll: '/TenantLocation/List',
    getByOid: '/TenantLocation/Details',
    insert: '/TenantLocation/Insert',
    update: '/TenantLocation/Update',
    delete: '/TenantLocation/Delete',
    getBasicLogInfo: '/TenantLocation/GetBasicLogInfo'
  },

  roles: {
    getList: '/Role/List',
    getAll: '/Role/GetAll',
    getByOid: '/Role/Details',
    insert: '/Role/Insert',
    update: '/Role/Update',
    delete: '/Role/Delete',
    getAllPermissions: '/Role/Permissions',
    getBasicLogInfo: '/Role/GetBasicLogInfo'
  },

  tenantSettings: {
    getAll: '/TenantSetting/List',
    getByOid: '/TenantSetting/Details',
    delete: '/TenantSetting/Delete',
    update: '/TenantSetting/Update',
    insert: '/TenantSetting/Insert',
    getBasicLogInfo: '/TenantSetting/GetBasicLogInfo'
  },
  regIndustry: {
    insert: '/CorporateTemp/Insert',
    dropdowns: '/CorporateTemp/Dropdown',
    getByOid: '/CorporateTemp/Details',
    checkGiro: '/CorporateTemp/CheckGiro'
  },
  regWeldingInspector: {
    insert: '/WeldingInspectorTemp/Insert',
    dropdowns: '/WeldingInspectorTemp/Dropdown',
    getByOids: '/WeldingInspectorTemp/GetByOids'
  },
  corporate: {
    downloadTAC: '/Corporate/DownloadTAC',
    getAll: '/Corporate/GetAll',
    getList: '/Corporate/List',
    getByOid: '/Corporate/Details',
    delete: '/Corporate/Delete',
    update: '/Corporate/Update',
    isSwsMember: '/Corporate/IsSwsMember',
    updateProfile: '/Corporate/UpdateProfile',
    getBasicLogInfo: '/Corporate/GetBasicLogInfo',
    download: '/Corporate/Download',
    giro: '/Corporate/giro',
    corpProfile: '/Corporate/CorpProfile',
    invoiceStatement: '/Pdf/InvoiceStatement',
  },
  weldinginspector: {
    getAll: '/weldinginspector/GetAll',
    getList: '/weldinginspector/List',
    getByOid: '/weldinginspector/Details',
    delete: '/weldinginspector/Delete',
    update: '/weldinginspector/Update',
    getBasicLogInfo: '/weldinginspector/GetBasicLogInfo',
    download: '/weldinginspector/Download'
  },
  sws: {
    getList: '/Sws/List',
    getByOid: '/Sws/Details',
    insert: '/Sws/Insert',
    update: '/Sws/Update',
    download: '/Sws/Download',
    delete: '/Sws/Delete',
    getBasicLogInfo: '/Sws/GetBasicLogInfo'
  },
  activatecorporate: {
    getAll: '/ActivateCorporate/GetAll',
    getList: '/ActivateCorporate/List',
    getByOid: '/ActivateCorporate/Details',
    delete: '/ActivateCorporate/Delete',
    activate: '/ActivateCorporate/Activate',
    getBasicLogInfo: '/ActivateCorporate/GetBasicLogInfo'
  },
  activateweldinginspector: {
    getAll: '/weldingInspectortemp/GetAll',
    getList: '/weldingInspectortemp/List',
    getByOid: '/weldingInspectortemp/Details',
    delete: '/weldingInspectortemp/Delete',
    activate: '/weldingInspectortemp/Activate',
  },
  worker: {
    getList: '/Worker/List',
    getListStaff: '/Staff/List',
    getIndustryWorkerList: '/Worker/GetIndustryWorkerList',
    getListForAssignWorkers: '/Worker/GetListForAssignWorkers',
    getByOid: '/Worker/Details',
    getByOidStaff: '/Staff/Details',
    insert: '/Worker/Insert',
    update: '/Worker/Update',
    updateStaff: '/Staff/Update',
    checkNricFinOrWorkPermit: '/Worker/CheckNricFinOrWorkPermit',
    downloadBatchUploadSample: '/Worker/DownloadBatchUploadSample',
    downloadBatchUploadSampleStaff: '/Staff/DownloadBatchUploadSample',
    uploadBatch: '/Worker/UploadBatch',
    uploadBatchStaff: '/Staff/UploadBatch',
    workerEmployHistory: '/Worker/WorkerEmployHistory',
    getBasicLogInfo: '/Worker/GetBasicLogInfo',
    download: '/Worker/Download',
    getTransferRequests: '/Worker/GetTransferRequests',
    getTransferToApproves: '/Worker/GetTransferToApproves',
    getTransferWaitings: '/Worker/GetTransferWaitings',
    confirmWorkerTransferRequest: '/Worker/confirmWorkerTransferRequest',
    confirmWorkerTransferApprove: '/Worker/confirmWorkerTransferApprove',
    reactiveTheTerminatedWorker: '/Worker/ReactiveTheTerminatedWorker',
    getAllWeldingWorkers: '/Worker/GetAllWeldingWorkers',
    getWorkshopSummary: '/Worker/GetWorkshopSummary',
    getWorksiteSummary: '/Worker/GetWorksiteSummary',
    getWorkshopSummaryWelder: '/Worker/GetWorkshopSummaryWelder',
    getWorksiteSummaryWelder: '/Worker/GetWorksiteSummaryWelder',
    getWelderSkillTests: '/Worker/GetWelderSkillTests',
  },
  workerSkill: {
    getWorkerSkillTests: '/WorkerSkill/GetWorkerSkillTests',
    getWorkerSkillTestByOid: '/WorkerSkill/GetWorkerSkillTestByOid',
    getSkillTestsBySkillOid: '/WorkerSkill/GetSkillTestsBySkillOid',
    insertWorkerSkillTest: '/WorkerSkill/InsertWorkerSkillTest',
    updateWorkerSkillTest: '/WorkerSkill/UpdateWorkerSkillTest',
    getWorkerSkillSetsByWorkerOid: '/WorkerSkill/GetWorkerSkillSetsByWorkerOid',
    getWorkerSkillTestsToConfirm: '/WorkerSkill/GetWorkerSkillTestsToConfirm',
    confirmWorkerSkillTests: '/WorkerSkill/ConfirmWorkerSkillTests',
    downloadWorkerSkillTestFile: '/WorkerSkill/DownloadWorkerSkillTestFile',
    getAllWeldingSkillTests: '/WorkerSkill/GetAllWeldingSkillTests',
    getWelderQualificationExpiryReport: '/WorkerSkill/GetWelderQualificationExpiryReport',
    welderExpiryDownload: '/WorkerSkill/WelderExpiryDownload',
  },
  notification: {
    unreadNotificationCount: '/Notification/UnreadNotificationCount',
    getUserNotificationSettings: '/Notification/GetUserNotificationSettings',
    getUserNotificationSettingsByAdmin: '/Notification/GetUserNotificationSettingsByAdmin',
    updateNotificationSetting: '/Notification/UpdateNotificationSetting',
    updateNotificationSettingByAdmin: '/Notification/UpdateNotificationSettingByAdmin',
    getUnreadNotifications: '/Notification/GetUnreadNotifications',
    getReadNotifications: '/Notification/GetReadNotifications',
    deleteNotificatoin: '/Notification/DeleteNotificatoin',
    setAllUnreadAsRead: '/Notification/SetAllUnreadAsRead',

    getNotificationTemplateDetails: '/Notification/GetNotificationTemplateDetails',
    getNotificationTemplateList: '/Notification/GetNotificationTemplateList',
    updateNotificationTemplate: '/Notification/UpdateNotificationTemplate',
    getNotificationTemplateBasicLogInfo: '/Notification/GetNotificationTemplateBasicLogInfo',

  },
  quota: {
    downloadProcessMaintenance: '/QuotaApplication/DownloadProcessMaintenance',
    getList: '/QuotaApplication/List',
    getByOid: '/QuotaApplication/Details',
    insert: '/QuotaApplication/Insert',
    update: '/QuotaApplication/Update',
    download: '/QuotaApplication/Download',
    delete: '/QuotaApplication/Delete',
    getBasicLogInfo: '/QuotaApplication/GetBasicLogInfo',
    prepareData: '/QuotaApplication/PrepareData',
    updateStatus: '/QuotaApplication/UpdateStatus',
    downloadFile: '/QuotaApplication/DownloadFile',
  },
  testApplication: {
    weldingInspectorGetAll: '/TestApplication/WeldingInspectorGetAll',
    getWeldingInspectorsWithTSDelegates: '/TestApplication/GetWeldingInspectorsWithTSDelegates',
    getList: '/TestApplication/List',
    getListGpByDate: '/TestApplication/ListGpByDate',
    getByOid: '/TestApplication/Details',
    insert: '/TestApplication/Insert',
    update: '/TestApplication/Update',
    updateStatus: '/TestApplication/UpdateStatus',
    getCandidatesByDate: '/TestApplication/GetCandidatesByDate',
    getTestCandidateDetails: '/TestApplication/GetTestCandidateDetails',
    insertTestApplicationResult: '/TestApplication/InsertTestApplicationResult',
    updateTestApplicationResult: '/TestApplication/UpdateTestApplicationResult',
    cancelTestApplication: '/TestApplication/CancelTestApplication',
    getTestCandidateDetailsByBatch: '/TestApplication/GetTestCandidateDetailsByBatch',
    insertTestApplicationResultByBatch: '/TestApplication/InsertTestApplicationResultByBatch',
    getSkillTestPositions: '/TestApplication/GetSkillTestPositions',
    getWelderTestListForExpiryExtend: '/TestApplication/GetWelderTestListForExpiryExtend',
    welderTestExpiryExtend: '/TestApplication/WelderTestExpiryExtend',
    checkWorkerDuplicateTestDate: '/TestApplication/CheckWorkerDuplicateTestDate',
  },
  serviceItem: {
    getAll: '/ServiceItem/All',
    getList: '/ServiceItem/List',
    getByOid: '/ServiceItem/Details',
    insert: '/ServiceItem/Insert',
    update: '/ServiceItem/Update',
    delete: '/ServiceItem/Delete',
  },
  pdf: {
    testApplicationResultPrintCert: '/Pdf/TestApplicationResultPrintCert',
    invoice: '/Pdf/Invoice',
    formA: '/Pdf/FormA',
    worker: '/Pdf/Worker',
    rtReport: '/Pdf/RTReport'
  },
  invoice: {
    getWorker: '/Invoice/Workers',
    getPreparedData: '/Invoice/PreparedData',
    getList: '/Invoice/List',
    getByOid: '/Invoice/Details',
    update: '/Invoice/update',
    downloadZip: '/Invoice/DownloadZip',
    delete: '/Invoice/Delete',
    updateStatus: '/Invoice/UpdateStatus',
    sendInvoice: '/Invoice/SendEmail',
    report: '/Invoice/report',
    download: '/Invoice/download',
    revenueReport: '/Invoice/RevenueReport',
    revenueDownload: '/Invoice/revenuedownload'
  },
  industryProject: {
    getList: '/IndustryProject/List',
    getByOid: '/IndustryProject/Details',
    insert: '/IndustryProject/Insert',
    update: '/IndustryProject/Update',

  },
  site: {
    getList: '/Site/List',
    getByOid: '/Site/Details',
    insert: '/Site/Insert',
    update: '/Site/Update',
  },
  workerAssignment: {
    getListByCreator: '/WorkerAssignment/GetListByCreator',
    getListByApprover: '/WorkerAssignment/GetListByApprover',
    getListByWeldingInspector: '/WorkerAssignment/GetListByWeldingInspector',
    insert: '/WorkerAssignment/Insert',
    deassign: '/WorkerAssignment/Deassign',
    extendAssignment: '/WorkerAssignment/ExtendAssignment',
    endorse: '/WorkerAssignment/Endorse',
    changeWeldingInspector: '/WorkerAssignment/ChangeWeldingInspector',
  },
  industryPreference: {
    getPreparedData: '/IndustryPreference/PreparedData',
    getList: '/IndustryPreference/List',
    getByOid: '/IndustryPreference/Details',
    insert: '/IndustryPreference/Insert',
    update: '/IndustryPreference/Update',
    delete: '/IndustryPreference/Delete',
  },
  creditNote: {
    download: '/CreditNote/download',
    report: '/CreditNote/report',
    insert: '/CreditNote/Insert',
    getList: '/CreditNote/List',
    getByOid: '/CreditNote/Details'
  },
  report: {
    portalSummary: '/Report/PortalSummary',
    portalSummarydownload: '/Report/PortalSummaryDownload',
    workerSummaryPreparedReport: '/Report/WorkerSummaryPreparedReport',
    workerSummary: '/Report/WorkerSummary',
    workerSummaryReport: '/Report/WorkerSummaryDownload',
    welder: '/Report/Welders',
    welderDownload: '/Report/WelderDownload',
    systemActivityLog: '/Report/ActivityLogs',
    systemActivityLogDownload: '/Report/ActivityLogDownload',
    getWorkerAssignmentReport: '/Report/GetWorkerAssignmentReport',
    quotaReport: '/Report/QuotaReport',
    quotaDownload: '/Report/QuotaDownload',
    getWorkerQualificationReport: '/Report/GetWorkerQualificationReport',
    getTestStatisticsReport: '/Report/GetTestStatisticsReport',
    workerSkillInfoReport: '/Report/WorkerSkillInfoReport',
    workerSkillInfoReportExcel: '/Report/WorkerSkillInfoReportExcel',
    workerSkillInfoReportDetails: '/Report/WorkerSkillInfoReportDetails',
    workerSkillInfoReportDetailsExcel: '/Report/WorkerSkillInfoReportDetailsExcel',
  },
  performance: {
    getRTReportByOid: '/Performance/GetRTReportByOid',
    rtReportList: '/Performance/RTReportList',
    insertRTReport: '/Performance/InsertRTReport',
    updateRTReport: '/Performance/UpdateRTReport',
    deleteRTReport: '/Performance/DeleteRTReport',

    insertRTReportWeld: '/Performance/InsertRTReportWeld',
    updateRTReportWeld: '/Performance/UpdateRTReportWeld',
    deleteRTReportWeld: '/Performance/DeleteRTReportWeld',

    getAllPerformanceRemarks: '/Performance/GetAllPerformanceRemarks',

    updateTestCentreDelegate: '/Performance/UpdateTestCentreDelegate',
    getTestCentreDelegates: '/Performance/GetTestCentreDelegates',
    getWorkerByWelderId: '/Performance/GetWorkerByWelderId',
  },
  assessment: {
    getAssessmentList: '/Assessment/GetAssessmentList',
    getAssessmentEntryList: '/Assessment/GetAssessmentEntryList',
    endorsementProcess: '/Assessment/EndorsementProcess',
    getEndorsementHistory: '/Assessment/GetEndorsementHistory',
    getEndorsementHistoryDetail: '/Assessment/GetEndosementHistoryByOid',
  },
  dashboard:{
    getSwsCorpTypeCount: '/Dashboard/GetSwsCorpTypeCount',
    getCorpTypeCount: '/Dashboard/GetCorpTypeCount',
    getNotiAction:'/Dashboard/GetNotiAction',
    activityLog:'/Dashboard/ActivityLog',
    quotaApprovedVsRejectedForMOM:'/Dashboard/GetQuotaApprovedVsRejectedForMOM',
    swsMemberExpiringInSixMonthDashboard:'/Dashboard/GetSwsMemberExpiringInSixMonthDashboard',
    workerExpiringWO:'/Dashboard/GetWorkerExpiringWO',
    workerExpiringAWSHPP:'/Dashboard/GetWorkerExpiringAWSHPP',
    quotaStatus:'/Dashboard/GetQuotaStatus',
    pendingQuotaForContractorDashboard:'/Dashboard/GetPendingQuotaForContractorDashboard',
    pendingQuotaForMOM:'/Dashboard/GetPendingQuotaForMOM',
    pendingMNCQuotaForMOM:'/Dashboard/GetPendingMNCQuotaForMOMDashboard',
    pendingQuotaForMNCDashboard:'/Dashboard/GetPendingQuotaForMNCDashboard',
    getWelderTestListForExpiringDashboard:'/Dashboard/GetWelderTestListForExpiringDashboard',
    outstandingInvoiceIndustry:'/Dashboard/GetOutstandingInvoiceIndustry',
    testApplicationResult:'/Dashboard/GetTestApplicationResult',
    testApplicationConfrim:'/Dashboard/GetTestApplicationConfrim',
    testApplicationResultWI:'/Dashboard/GetTestApplicationResultWI',
    GetNoOfWorkerByProjects:'/Dashboard/GetNoOfWorkerByProjects',
  },
  
  newsAnnouncements: {
    getList: '/NewsAnnouncement/List',
    getUnauthorizedList: '/NewsAnnouncement/UnAuthorizedList',
    getAll: '/NewsAnnouncement/GetAll',
    getByOid: '/NewsAnnouncement/Details',
    insert: '/NewsAnnouncement/Insert',
    update: '/NewsAnnouncement/Update',
    delete: '/NewsAnnouncement/Delete',
  },

  links: {
    getList: '/QuickLink/List',
    getUnauthorizedList: '/QuickLink/UnauthorizedList',
    getAll: '/QuickLink/GetAll',
    getByOid: '/QuickLink/Details',
    insert: '/QuickLink/Insert',
    update: '/QuickLink/Update',
    delete: '/QuickLink/Delete',
  },

  commonFiles: {
    getList: '/CommonFile/List',
    getUnauthorizedList: '/CommonFile/UnauthorizedList',
    getAll: '/CommonFile/GetAll',
    getByOid: '/CommonFile/Details',
    insert: '/CommonFile/Insert',
    update: '/CommonFile/Update',
    delete: '/CommonFile/Delete',
    downloadFile: '/CommonFile/DownloadFile2',
  },
  skillTest: {
    getList: '/SkillTest/List',
    getByOid: '/SkillTest/Details',
    insert: '/SkillTest/Insert',
    update: '/SkillTest/Update',
    delete: '/SkillTest/Delete',
  },
}
