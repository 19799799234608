<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Year
              </div>
              <div class="col-sm-6">
                <FormSelect :list="yearList" :existing="year" value="value" text="text"
                  @change="year=$event" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                Status
              </div>
              <div class="col-sm-6">
                <v-select multiple label="text" v-model="selectedStatus" :options="statuses" @input="changeStatus"></v-select>
              </div>
            </div>
          </div>
           <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Month
              </div>
              <div class="col-sm-6">
                 <FormSelect :list="monthList" :existing="month" value="value" text="text"
                  @change="month=$event" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="downloadZip">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'InvoiceList',
  components: {
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      yearList:[],
      monthList:[],
      year:0,
      month:0,
      statuses: [],
      selectedStatus:null,
      statusList:[]
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Invoices Download' }
    ]);
    
    this.statuses = [
      { value: this.$data._invoiceStatus.new.value, text: this.$data._invoiceStatus.new.text },
      { value: this.$data._invoiceStatus.paid.value, text: this.$data._invoiceStatus.paid.text },
      { value: this.$data._invoiceStatus.confirmed.value, text: this.$data._invoiceStatus.confirmed.text },
      { value: this.$data._invoiceStatus.giroProcessing.value, text: this.$data._invoiceStatus.giroProcessing.text },
      { value: this.$data._invoiceStatus.giroError.value, text: this.$data._invoiceStatus.giroError.text },
      { value: this.$data._invoiceStatus.badDebt.value, text: this.$data._invoiceStatus.badDebt.text },
       { value: this.$data._invoiceStatus.chequeProcessing.value, text: this.$data._invoiceStatus.chequeProcessing.text },
    ];

    this.monthList = [
      { value: 1, text: "January" },
      { value: 2, text: "February" },
      { value: 3, text: "March" },
      { value: 4, text: "April" },
      { value: 5, text: "May" },
      { value: 6, text: "June" },
      { value: 7, text: "July" },
      { value: 8, text: "August" },
      { value: 9, text: "September" },
      { value: 10, text: "October" },
      { value: 11, text: "November" },
      { value: 12, text: "December" },
    ];
    let currentYear = new Date().getFullYear();
   this.selectedStatus = { value: this.$data._invoiceStatus.confirmed.value, text: this.$data._invoiceStatus.confirmed.text,status: this.statusList };
     this.statusList.push(this.$data._invoiceStatus.confirmed.value);
    for (let index = 0; index < 4; index++) {
        let yearData = currentYear - index;
         this.yearList.push({ value: yearData, text: yearData });
    }
    this.year = currentYear;
    this.month = (new Date().getMonth()) + 1;
  },
  methods: {
    changeStatus(list){
       this.statusList = [];
        list.forEach(e => {
          this.statusList.push(e.value);
        });
    },
    downloadZip() {
      
      if(!this.year || !this.month){
          this.showMessage('Select Year and Month', 'warning');
          return;
      }
      this.showLoadingScreen();
      console.log(this.year);
      api.downloadFile(apiUrl.invoice.downloadZip, { year: this.year,month:this.month,status:this.statusList }, 'Invoices.zip', () => {
        this.hideLoadingScreen();
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    
  }
}
</script>
