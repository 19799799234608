import url from './apiurl';
import * as axios from "axios";
import constant from './constant';

const api_url = window.api_url;

export default {
  APPNAME: "WebEcoTemplateApp",
  APPTOKENNAME: "WebEcoTemplateAppToken",
  TIMEZONE: "(UTC+08:00) Kuala Lumpur, Singapore",

  setTokenToCookie(token) {
    this.setCookie(this.APPTOKENNAME, token);
  },

  getTokenFromCookie() {
    return this.getCookie(this.APPTOKENNAME);
  },

  deleteTokenFromCookie() {
    this.deleteCookie(this.APPTOKENNAME, '');
  },

  setCookie(cookieName, data) {
    document.cookie = cookieName + '=' + data + '; expires=Thu, 01 Jan 2060 00:00:01 GMT';
  },

  deleteCookie(cookieName, data) {
    document.cookie = cookieName + '=' + data + '; expires=Thu, 01 Jan 1970 00:00:01 GMT';
  },

  getCookie(cookieName) {
    var b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';

    // cookieName = cookieName + "=";
    // var decodedCookie = decodeURIComponent(document.cookie);
    // var ca = decodedCookie.split(';');
    // for (var i = 0; i < ca.length; i++) {
    //     var c = ca[i];
    //     while (c.charAt(0) == ' ') {
    //         c = c.substring(1);
    //     }
    //     if (c.indexOf(cookieName) == 0) {
    //         return c.substring(cookieName.length, c.length);
    //     }
    // }
    // return "";

  },

  getFormattedDate(date, ymd) {
    if (ymd) {
      let day = date.getDate() + "";
      let month = (date.getMonth() + 1) + "";
      let year = date.getFullYear() + "";

      if (day.length < 2) day = "0" + day;
      if (month.length < 2) month = "0" + month;

      return year + "-" + month + "-" + day;
    }
    else
      return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  },

  getDateStringfromYMD(date) {
    let dtArr = (date + "").split("-");
    return dtArr[2] + "/" + dtArr[1] + "/" + dtArr[0];
  },

  getDateStringfromDMYtoYMD(date) {
    let dtArr = (date + "").split("-");
    return dtArr[2] + "-" + dtArr[1] + "-" + dtArr[0];
  },

  getFormattedDateTime(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let datetime = new Date(date);
    let ampm = datetime.getHours() >= 12 ? 'PM' : 'AM';
    let minutes = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
    return datetime.getDate() + "-" + (monthNames[datetime.getMonth() + 1]) + "-" + datetime.getFullYear() + " " + (datetime.getHours() % 12) + ":" + minutes + " " + ampm;
  },

  getFormattedExactDateTime(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let datetime = new Date(date);
    let ampm = datetime.getHours() >= 12 ? 'PM' : 'AM';
    let minutes = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
    return datetime.getDate() + "-" + (monthNames[datetime.getMonth()]) + "-" + datetime.getFullYear() + " " + (datetime.getHours() % 12) + ":" + minutes + " " + ampm;
  },

  async post(url, payload, contenttype = "application/json") {
    var response = {};
    try {
      response = await axios.post(url, payload, {
        headers: {
          'Content-Type': contenttype,
          'Authorization': 'Bearer ' + this.getTokenFromCookie()
        }
      });
    } catch (error) {
      response.statusText = "ERROR";
      response.errorText = error;
    }
    return response;
  },

  async get(url, payload) {
    var response = {};
    try {
      response = await axios.get(url, {
        params: payload,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.getTokenFromCookie()
        }
      });
    } catch (error) {
      response.statusText = "ERROR";
      response.errorText = error;
    }
    return response;
  },

  checkLogin(context) {
    let token = this.getTokenFromCookie();
    if (token) {
      this.userClaims(null).then((response) => {
        if (response.status == 200) {
          context.$store.state.loginUser = response.data;
        }
        else {
          if (context.$route.name != "Login") {
            //context.$router.push("/login");//context.$router.push({ name: "Login" });
            location.href = "/login";
          }
        }
      });

    }
    else {
      if (context.$route.name != "Login") {
        //context.$router.push("/login");//context.$router.push({ name: "Login" });
        location.href = "/login";
      }
    }
  },

  async regularGet(subUrl, payload) {
    let response = await this.get(api_url + subUrl, payload);
    return response;
  },

  async regularPost(subUrl, payload, contenttype) {
    let response = await this.post(api_url + subUrl, payload, contenttype);
    return response;
  },

  async getActivityLog(payload) {
    let response = await this.get(api_url + url.audit.getActivityLog, payload);
    return response;
  },

  async getAuditLog(payload) {
    let response = await this.get(api_url + url.audit.getAuditLog, payload);
    return response;
  },

  async userClaims(payload) {
    let response = await this.get(api_url + url.account.userClaims, payload);
    return response;
  },

  async logOut(payload) {
    let response = await this.get(api_url + url.account.logOut, payload);
    return response;
  },

  async getAllCountries() {
    let response = await this.get(api_url + url.settings.getByFilter, { group: constant.settingGroup.country });
    return response;
  },

  async getUserRoles(payload) {
    let response = await this.get(api_url + url.users.getUserRoles, payload);
    return response;
  },

  async saveUserRoles(payload) {
    let response = await this.post(api_url + url.users.saveUserRoles, payload);
    return response;
  },

  async getUserProfile(payload) {
    let response = await this.get(api_url + url.users.getUserProfile, payload);
    return response;
  },

  async saveUserProfile(payload) {
    let response = await this.post(api_url + url.users.saveUserProfile, payload);
    return response;
  },

  async saveUserPassword(payload) {
    let response = await this.post(api_url + url.users.saveUserPassword, payload);
    return response;
  },

  async getAllUsers(payload) {
    let response = await this.get(api_url + url.users.getAll, payload);
    return response;
  },

  async saveUserProfileAll(payload) {
    let response = await this.post(api_url + url.users.update, payload);
    return response;
  },

  async saveNewUserProfileAll(payload) {
    let response = await this.post(api_url + url.users.insert, payload);
    return response;
  },

  async deleteUser(payload) {
    let response = await this.get(api_url + url.users.delete, payload);
    return response;
  },

  async getAllSettings(payload) {
    let response = await this.get(api_url + url.settings.getAll, payload);
    return response;
  },

  async getSetting(payload) {
    let response = await this.get(api_url + url.settings.getByOid, payload);
    return response;
  },

  async deleteSetting(payload) {
    let response = await this.get(api_url + url.settings.delete, payload);
    return response;
  },

  async saveSetting(payload) {
    let response = await this.post(api_url + url.settings.update, payload);
    return response;
  },

  async saveNewSetting(payload) {
    let response = await this.post(api_url + url.settings.insert, payload);
    return response;
  },

  // async getAllTenantSettings(payload) {
  //   let response = await this.get(api_url + url.tenantSettings.getAll, payload);
  //   return response;
  // },

  async getTenantSetting(payload) {
    let response = await this.get(api_url + url.tenantSettings.getByOid, payload);
    return response;
  },

  async deleteTenantSetting(payload) {
    let response = await this.get(api_url + url.tenantSettings.delete, payload);
    return response;
  },

  async saveTenantSetting(payload) {
    let response = await this.post(api_url + url.tenantSettings.update, payload);
    return response;
  },

  async saveNewTenantSetting(payload) {
    let response = await this.post(api_url + url.tenantSettings.insert, payload);
    return response;
  },

  async getAllRoles(payload) {
    let response = await this.get(api_url + url.roles.getAll, payload);
    return response;
  },

  async getAllPermissions(payload) {
    let response = await this.get(api_url + url.roles.getAllPermissions, payload);
    return response;
  },

  async getAllCorporates(payload) {
    let response = await this.get(api_url + url.corporate.getAll, payload);
    return response;
  },

  async getSettingsByGroup(group) {
    let response = await this.get(api_url + url.settings.getByFilter, { group: group });
    return response;
  },

  async downloadFile(url, modal, filename, callBack) {
    let options = {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' +  this.getTokenFromCookie(),
      }
    }
    axios.post(api_url + url, modal, options)
      .then(response => {
        let blob = new Blob([response.data], {
          type: 'application/octet-stream'
        });
        let link = document.createElement('a');
        let linkId = "download_href" + new Date().getTime()
        link.setAttribute("id", linkId);
        link.href = window.URL.createObjectURL(blob);
        //link.setAttribute("href", 'javascript:alert("hello");');
        //link.download = filename;
        link.setAttribute("download", filename);
        document.getElementById("main_body").append(link);
        console.log(link);
        document.getElementById(linkId).click();
        document.getElementById(linkId).remove();
        //link.click();

        if (callBack)
          callBack();
      }, response => {
        console.warn('error from download_contract');
        this.handelError(response);
        // Manage errors
      });
  },

}

