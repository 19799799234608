<template>
  <div>
    <h4>Worker Endorsement History</h4>

    <div class="card">
      <div class="card-header bg-secondary text-white hover" data-bs-toggle="collapse" href="#div_filter">
        Filter
      </div>
      <div class="card-body">
        <div class="collapse" id="div_filter">
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Test Number
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.testNumber" />
            </div>
            <div class="col-md-3 text-secondary">
              Endorsement Date
            </div>
            <div class="col-md-3">
              <DatePicker :existing="null" @updatedate="changeEndorsementDate" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              NRIC/FIN
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.nricFin" />
            </div>
            <div class="col-md-3 text-secondary">
              Work Permit
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.workPermit" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Welder ID
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.welderId" />
            </div>
            <div class="col-md-3 text-secondary">
              Name
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control" v-model="tableParam.name" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-3 text-secondary">
              Contractor
            </div>
            <div class="col-md-3">
              <FormSelect :list="contractors" value="oid" text="corpName" :existing="tableParam.contractorOid" @change="tableParam.contractorOid=$event" />
            </div>
            <div class="col-md-3 text-secondary">
              
            </div>
            <div class="col-md-3 text-end">
              <button class="btn btn-primary" @click="callTable">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <th>Test No</th>
          <th>Endorsement Date</th>
          <th>NRIC/FIN</th>
          <th>Work Permit</th>
          <th>Welder ID</th>
          <th>Name</th>
          <th>Contractor</th>
          <th>Expiry Date</th>
          <th>RT Report</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.testNumber}}</td>
          <td>{{i.endorsementDate}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.welderId}}</td>
          <td>{{i.workerName}}</td>
          <td>{{i.contractorName}}</td>
          <td>{{i.expiryDate}}</td>
          <td>{{i.rtReportNo}}</td>
          <td>
          <button class="btn btn-sm btn-primary me-2 mb-1" @click="clickView(i)">
              View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination :pagingParam="tableParam" @updatePaging="updatePaging" />
  </div>
</template>

<script>
import api from '@/common/api';
import apiUrl from '@/common/apiurl';
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'AssessmentList',
  components: {
    ...TableAddons,
    ...Forms,
    ...Pickers,
  },
  data() {
    return {
      tableParam: this.dtoPaging(),

      contractors: [],
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Endorsement History' }
    ]);

    this.tableParam.contractorOid = null;

    api.getAllCorporates(null).then(response => {
      this.contractors = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.contractor.value);
      this.contractors.unshift({ oid: null, corpName: '<All>' });
    });

    this.callTable();
  },
  methods:{
    clickView(i){
      this.$router.push({ name: 'EndorsementHistoryDetail', params: { oid: i.oid,isContractor:false } });
    },
    callTable(){
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.assessment.getEndorsementHistory, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeEndorsementDate(date){
      this.tableParam.endorsementDate = this.convertDateObjToDateStr(date);
    },
  }
}
</script>
