<template>
  <div>
    <div class="mb-2" v-if="mainObject">
      <div class="row">
        <div class="col-md-6">
          <strong>Description: </strong>{{mainObject.description}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <strong>Created By: </strong> {{mainObject.createdBy}}
        </div>
        <div class="col-md-6">
          <strong>Created Date: </strong> {{mainObject.createdDateDisplay}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <strong>Last Modified By: </strong> {{mainObject.lastModifiedBy}}
        </div>
        <div class="col-md-6">
          <strong>Last Modified Date: </strong> {{mainObject.lastModifiedDateDisplay}}
        </div>
      </div>
      <hr />
    </div>

    <div class="row mb-2">
      <div class="col-md-6">
        <div class="row" v-if="this.categories && this.categories.length > 0">
          <div class="col-md-4">
            Category
          </div>
          <div class="col-md-7">
            <select class="form-control" @change="categoryChange">
              <option v-for="(i,idx) in categories" :key="idx" :value="i.value">{{i.text}}</option>
            </select>
          </div>
        </div>        
      </div>
      <div class="col-md-6">
        <div class="float-end">
          <DateRangePicker @updatedaterange="dateRangeChange"></DateRangePicker>
        </div>
      </div>      
    </div>

    <div>
      <TableFilter @changeText="changeTblFilter" />
      <table class="table table-hover table-sm">
        <thead class="table-light">
          <tr>
            <SortColumn @click="sortColumn" :sortBy="'auditDate'" :colGroup="'tbl1'">Date Time</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'userName'" :colGroup="'tbl1'">User</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'category'" :colGroup="'tbl1'">Category</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'columnName'" :colGroup="'tbl1'">Column</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'oldValue'" :colGroup="'tbl1'">Old Value</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'newValue'" :colGroup="'tbl1'">New Value</SortColumn>
            <SortColumn @click="sortColumn" :sortBy="'operation'" :colGroup="'tbl1'">Operation</SortColumn>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td>{{getDateTime(item.auditDate)}}</td>
            <td>{{item.userName}}</td>
            <td>{{item.category}}</td>
            <td>{{item.columnName}}</td>
            <td>{{item.oldValue}}</td>
            <td>{{item.newValue}}</td>
            <td>{{item.operation}}</td>
          </tr>
        </tbody>
      </table>
      <PaginationForAudit
        :totalRow="table.rowtotal"
        :startRow="table.startrow"
        :endRow="table.endrow"
        :currentPage="table.currentpage"
        :totalPage="table.pagescount"
        :rowPerPage="table.rowcount"
        @updatePaging="updatePaging"
       />
    </div>

  </div>
</template>

<script>
import * as TableAddons from "@/components/custom/table_addons";
import api from "@/common/api";
import * as Pickers from "@/components/custom/pickers";

export default {
  name: "AuditLog",
  components: {
    ...TableAddons,
    ...Pickers
  },
  props: {
    timezone: String,
    primarykey: Number,
    tablename: String,
    parentprimarykey: Number,
    parenttablename: String,
    option: String,
    categories: Array,
    defaultCategory: String,
    basicLogInfoOid: Number,
    basicLogInfoUrl: String
  },

  data() {
    return {
      show: false,
      items: [],
      vStartDate: new Date(),
      vEndDate: new Date(),
      startDate: "",
      endDate: "",
      table: {
        timezone: this.timezone,
        startdate: '',
        enddate: '',
        primarykey: this.primarykey,
        tablename: this.tablename,
        parentprimarykey: this.parentprimarykey,
        parenttablename: this.parenttablename,
        option: this.option,
        currentpage: 1,
        filter: '',
        pagescount: 1,
        orderby: '',
        isasc: false,
        data: null,
        searchtext: '',
        rowcount: 10,
        rowtotal: 0,
        startrow: 0,
        endrow: 0
      },
      collapseDuration: 0,

      mainObject: null
    };
  },

  methods: {

    categoryChange(e) {
      this.table.filter = e.target.value;
      this.callTable();
    },

    dateRangeChange(dateRange) {
      this.table.startdate = this.convertDateObjToDateStr(dateRange.start);
      this.table.enddate = this.convertDateObjToDateStr(dateRange.end);
      this.callTable();
    },

    callTable() {
      this.table.data = null;
      api.getAuditLog(this.table).then((response) => {
        if (response.status == 200) {
          this.table = response.data;
          this.items = this.table.data;
          this.show = true;
        }
      });
    },

    getDateTime(date) {
      return api.getFormattedDateTime(date);
    },

    loadMainObjectInfo() {
      if (this.basicLogInfoOid && this.basicLogInfoOid > 0 && this.basicLogInfoUrl) {
        api.regularGet(this.basicLogInfoUrl + "/" + this.basicLogInfoOid, null, this.$store.state.token).then((response) => {
          if (response.status == 200) {
            this.mainObject = response.data;
          }
        });
      }
    },

    sortColumn(sortyBy, sortDir) {
      this.table.orderby = sortyBy;
      this.table.isasc = sortDir == 'asc' ? true : false;
      this.callTable();
    },
    updatePaging(paramObj){
      this.table.currentpage = paramObj.currentPage;
      this.table.rowcount = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text){
      this.table.searchtext = text;
      this.callTable();
    },

  },

  mounted() {
    // this.startDate = api.getFormattedDate(this.vStartDate, true);
    // this.endDate = api.getFormattedDate(this.vEndDate, true);
    // this.table.startdate = api.getFormattedDate(this.vStartDate);
    // this.table.enddate = api.getFormattedDate(this.vEndDate);

    if (this.categories && this.categories.length > 0) {
      this.table.filter = this.categories[0];
    }
    else if (this.defaultCategory) {
      this.table.filter = this.defaultCategory;
    }
    else {
      this.table.filter = 'All';
    }
    this.callTable();
    this.loadMainObjectInfo();
  },





};
</script>