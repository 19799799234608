<template>
  <div>

    <button v-show="false" type="button" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#modalDialog_'+id" ref="myShowBtn">
      Launch demo modal
    </button>

    <div class="modal fade" :id="'modalDialog_'+id" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog" :class="size=='xl'?'modal-xl':size=='lg'?'modal-lg':size=='sm'?'modal-sm':''">
        <div class="modal-content">
          <div v-if="hasHeader" class="modal-header">
            <h5 class="modal-title">{{title}}</h5>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div v-show="hasFooter" class="modal-footer">
            <button v-if="showCloseBtn" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal" ref="myCloseBtn">{{closeBtnText}}</button>
            <button v-if="showSubmitBtn1" type="button" class="btn btn-primary" @click="submit1">{{submitBtn1Text}}</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'ModalDiaglog',
  props: {
    show: Boolean,
    hasHeader: {
      type: Boolean,
      default: true
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    size: {
      type: String, // (xl, lg, md, sm)
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    closeBtnText: {
      type: String,
      default: 'Close'
    },
    showSubmitBtn1: {
      type: Boolean,
      default: true
    },
    submitBtn1Text: {
      type: String,
      default: 'Save'
    },
    onClose: {
      type: Function,
      default: () => { }
    },
    onSubmit1: {
      type: Function,
      default: () => { }
    },
  },
  data() {
    return {
      id: 0
    };
  },
  beforeMount(){
    this.id = Math.floor((Math.random() * 100000) + 1);
  },
  watch: {
    show(newVal) {
      if (newVal === true) {
        this.$refs.myShowBtn.click();
      }
      else {
        this.$refs.myCloseBtn.click();
      }
    }
  },
  methods: {
    closeModal() {
      this.onClose();
    },
    submit1() {
      this.onSubmit1();
    }
  }
}
</script>