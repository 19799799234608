<template>
<div>
  <div>
    <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text"
                  @change="changeFilterStatus" />
              </div>
            </div>
          </div>
           <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Application No
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" v-model="tableParam.testApplicationId" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Test Date
              </div>
              <div class="col-sm-8">
                <DateRangePicker @updatedaterange="dateRangeChangeTest"></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- <button class="btn btn-primary float-end mb-2" @click="addNew">Add SWS</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="downloadSetting">Download</button> -->
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="callTable">Search</button>
          </div>
        </div>
  </div>
  <div>
    <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <SortColumn @click="sortColumn" sortBy="testDate" colGroup="tbl1">Test Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="testLocation" colGroup="tbl1">Test Location</SortColumn>
          <SortColumn @click="sortColumn" sortBy="testApplicationId" colGroup="tbl1">Test Application No</SortColumn>
          <SortColumn @click="sortColumn" sortBy="contractor" colGroup="tbl1">Contractor</SortColumn>
          <SortColumn @click="sortColumn" sortBy="noOfWorkers" colGroup="tbl1">No of Worker</SortColumn>
          <SortColumn @click="sortColumn" sortBy="subContractorName" colGroup="tbl1">Status</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.testDate}}</td>
          <td>{{i.testLocation}}</td>
          <td>{{i.testApplicationId}}</td>
          <td>{{i.contractorName}}</td>
          <td>{{i.noOfWorkers}}</td>
          <td>
            <span v-if="i.status == $data._testApplicationStatus.active.value" class="badge bg-success">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.pending.value" class="badge bg-primary">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.rejected.value" class="badge bg-danger">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.modify.value" class="badge bg-warning">{{i.statusDisplay}}</span>
              <span v-else-if="i.status == $data._testApplicationStatus.updated.value" class="badge bg-secondary">{{i.statusDisplay}}</span>
              <span v-else>{{i.statusDisplay}}</span>
          </td>
          <td>
             <button class="btn btn-sm btn-primary" @click="clickEdit(i)">
              <span>Edit</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'TestApplicationConfirmList',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: [],
      years:[],
      status:'',
      applicationNo:''
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedStatus = this.$route.params.status;
    console.log(passedStatus);
    if (passedStatus) {
      this.status = passedStatus;
    }
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Test Application - Confirmation' }
    ]);
    this.years = [];

    let currentYear = new Date().getFullYear();
    this.years.push({ value: "", text: "All" });
    this.years.push({ value: currentYear, text: currentYear });
    for (let index = 1; index < 11; index++) {
      var year = currentYear-index;
      this.years.push({ value: year, text: year });
    }
    this.statuses = [
      { value: "", text: "All" },
      { value: this.$data._testApplicationStatus.active.value, text: this.$data._testApplicationStatus.active.text },
      { value: this.$data._testApplicationStatus.rejected.value, text: this.$data._testApplicationStatus.rejected.text },
      { value: this.$data._testApplicationStatus.pending.value, text: this.$data._testApplicationStatus.pending.text },
      { value: this.$data._testApplicationStatus.modify.value, text: this.$data._testApplicationStatus.modify.text },
      { value: this.$data._testApplicationStatus.updated.value, text: this.$data._testApplicationStatus.updated.text },
      { value: this.$data._testApplicationStatus.cancelled.value, text: this.$data._testApplicationStatus.cancelled.text }
    ];
    // this.tableParam.status = this.$data._status.active.value;
    // this.tableParam.expiredDate_Start = null;
    // this.tableParam.expiredDate_End = null;
    // this.tableParam.terminatedDate_Start = null;
    // this.tableParam.terminatedDate_End = null;
    // this.tableParam.company = "";
    // this.tableParam.swsId = "";
    this.tableParam.years = "";
     this.tableParam.type = "list";
    this.callTable();
  },
  methods: {
    //  downloadSetting() {
    //   this.showLoadingScreen();
    //   let data = {
    //     expiredDate_Start: this.tableParam.expiredDate_Start,
    //     expiredDate_End: this.tableParam.expiredDate_End,
    //     terminatedDate_Start: this.tableParam.terminatedDate_Start,
    //     terminatedDate_End: this.tableParam.terminatedDate_End,
    //     company: this.tableParam.company,
    //     swsId:  this.tableParam.swsId,
    //     status: this.tableParam.status
    //   }
    //   api.downloadFile(apiUrl.sws.download,data,"sws.xlsx",() =>{
    //     this.hideLoadingScreen();
    //     this.showMessage("Successfully downloaded.", "success");
    //   });
    // },
    changeFilterStatus(e) {
      this.status = e;
      this.callTable();
    },
    dateRangeChangeTest(dateRange) {
      this.tableParam.test_Start = this.convertDateObjToDateStr(dateRange.start);
      this.tableParam.test_End = this.convertDateObjToDateStr(dateRange.end);
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      this.tableParam.forTestCentre = true;
      this.tableParam.status = this.status;
      api.regularPost(apiUrl.testApplication.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'TestApplicationConfirm', params: { id: data.oid } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeFilterYear(e) {
      this.tableParam.year = e;
      this.callTable();
    }
  }
}
</script>
