<template>
<div>
    <div class="text-end">
        <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
        <div class="card-body">
            <div>
                <h6 class="card-subtitle mb-2 text-muted">Welder Information</h6>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Corporate Name:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.corporName}}</label>
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Welding Inspector Name:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.name}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Designation:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.designation}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Qualification:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.qualification}}</label>

                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Certificate No.:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.weldCertNo}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Qualification Expiry Date:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{expiryDate}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Experience:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.experience}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Professional Membership:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.profMember}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Telephone No:</div>
                            <div class="col-sm-4">
                                <label class="control-label">{{dataObj.telephone}} / {{dataObj.ext}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Fax:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.fax}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Email Address:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.email}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Mobile Contact No:</div>
                            <div class="col-sm-8">
                                <label class="control-label">{{dataObj.mobileNo}}</label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 text-secondary">Status:</div>
                            <div class="col-sm-8">
                                <label v-if="$data._corporateStatus.active.value" class="control-label"><span
                                        class="badge bg-success">{{$data._corporateStatus.active.text}}</span></label>
                                <label v-else class="control-label"><span
                                        class="badge bg-success">{{$data._corporateStatus.suspended.text}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Back</button>
            </div>
        </div>
    </div>
    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false"
        :showSubmitBtn1="false">
        <div v-if="showAuditModal">
            <AuditLog :timezone="timezone" tablename="WeldingInspector" :primarykey="oid" :categories="[]"
                :defaultCategory="'All'" :basicLogInfoOid="oid" :basicLogInfoUrl="basicLogInfoUrl" />
        </div>
    </ModalDiaglog>
</div>
</template>
<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as Forms from "@/components/custom/forms";
import * as Pickers from "@/components/custom/pickers";
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
export default {
  name: "EditWeldingInspector",
  components: {
      AuditLog,
    ModalDiaglog,
    ...Forms,
    ...Pickers
  },
  props: {
    type: String
  },
  data() {
    return {
      basicLogInfoUrl: apiUrl.weldinginspector.getBasicLogInfo,
      oid: 0,
      expiryDate: null,
      isNew: true,
      confirmObj: {},
      dataObj: { giro: {} },
      timezone: api.TIMEZONE,
      corporates:[],
      qualifications:[],
      statuses: [],
      showAuditModal: false,
      parentUrl: ''
    };
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {
     let pageName =  'Edit ';
    pageName += this.getCorporateTypeTextByValue(this.$route.params.type);
    let prevLink = { name: this.$data._corporateType.weldingInspector.text, url: '/weldinginspectors' };
    this.setBreadcrumb([
      prevLink, { name: pageName }
    ]);
    this.statuses = [
      { value: this.$data._corporateStatus.active.value, text: this.$data._corporateStatus.active.text },
      { value: this.$data._corporateStatus.suspended.value, text: this.$data._corporateStatus.suspended.text },
    ];

    if (this.oid > 0) {
      this.getData();
    }else{
        this.getDropdowns();
    }
    
  },
  methods: {
    submit() {},
    modal() {
      this.showModal = true;
    },
    getData() {
      let url = apiUrl.weldinginspector.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        if (this.dataObj.weldCertExpiryDate_String)
          this.expiryDate = this.convertDateStrToDateObj(this.dataObj.weldCertExpiryDate_String);
        this.getDropdowns();
      });
    },
    getDropdowns() {
      let url = apiUrl.regWeldingInspector.dropdowns
      api.regularGet(url, null).then((response) => {
        let dropdowns = response.data;
        this.corporates = dropdowns.corporates;
        this.qualifications = dropdowns.qualifications;
      });
    },
    clickSave() {
      let msg = this.validate();
      if (msg) {
        this.showMessage(msg, "warning");
        return;
      }else{
        let subUrl = apiUrl.weldinginspector.update;

      api.regularPost(subUrl, this.dataObj).then((response) => {
        if (response.status == 200) {
          this.isMain = false;
          this.showMessage("Successfully updated.", "success");
          this.$router.push({ name: "WeldingInspectors" });
        } else if (
          response.status == 400 ||
          response.errorText.response.status == 400
        ) {
          this.showMessage(response.errorText.response.data.message, "error");
        } else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
      }
      
    },
    clickCancel() {
      this.$router.push({ name: "WeldingInspectors" });
    },
    validate() {
      let msg = "";
      if (!this.dataObj.corporateOid){
        msg += window.message.weldertemp.corp_blank;
      }
      if (!this.dataObj.designation){
        msg += window.message.weldertemp.designation_blank;
      }
      if (!this.dataObj.experience){
        msg += window.message.weldertemp.experience_blank;
      }
      if (!this.dataObj.profMember){
        msg += window.message.weldertemp.membership_blank;
      }
      if (!this.dataObj.telephone){
        msg += window.message.weldertemp.telephone_blank;
      }
      if (!this.dataObj.email){
        msg += window.message.weldertemp.email_blank;
      }
      if (!this.dataObj.mobileNo){
        msg += window.message.weldertemp.mobile_blank;
      }
      return msg;
    },

    changeQualificationExpiryDate(date){
      this.dataObj.weldCertExpiryDate = this.convertDateObjToDateStr(date);
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.name + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.weldinginspector.delete, this.dataObj).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "WeldingInspectors" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    }
  },
}
</script>
