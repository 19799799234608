<template>
  <div>

    <div class="my-tab">
      <div class="my-tab-item" :class="currentTab=='current' && 'active'" @click="currentTab='current'">Current</div>
      <div class="my-tab-item" :class="currentTab=='history' && 'active'" @click="currentTab='history'">History</div>
    </div>

    <div v-show="currentTab=='current'" style="overflow-x: auto;">
      <table class="table table-sm">
        <thead class="table-light">
          <tr> 
            <SortColumn @click="sortColumn" sortBy="testDate" colGroup="tbl1">Test Date</SortColumn>
            <SortColumn @click="sortColumn" sortBy="testLocation" colGroup="tbl1">Test Location</SortColumn>
            <SortColumn @click="sortColumn" sortBy="testApplicationId" colGroup="tbl1">Application ID</SortColumn>
            <SortColumn @click="sortColumn" sortBy="createdDate" colGroup="tbl1">Application Date</SortColumn>
            <SortColumn @click="sortColumn" sortBy="contractorName" colGroup="tbl1">Contractor</SortColumn>
            <th>NoOfWorkers</th>
            <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
            <th style="width:115px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in tableParam.listData" :key="idx">
            <td>{{i.testDateDisplay}}</td>
            <td>{{i.testLocation}}</td>
            <td>{{i.testApplicationId}}</td>
            <td>{{i.createdDate}}</td>
            <td>{{i.contractorName}}</td>
            <td>{{i.noOfWorkers}}</td>
            <td>
              <span v-if="i.status == $data._testApplicationStatus.active.value" class="badge bg-secondary">{{i.statusDisplay}}</span>
              <span v-else>{{i.statusDisplay}}</span>
            </td>
            <td>
              <button v-if="checkAbleToClick(i)" class="btn btn-xs btn-primary" @click="clickUpdate(i)">
                Update
              </button>
              <span v-else class="text-secondary txt-small">[Future date]</span>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
      />
    </div>

    <div v-show="currentTab=='history'">
      <div class="row mb-2">
        <div class="col-md-3 text-secondary">
          Application ID
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" v-model="tableParamHis.testApplicationId" />
        </div>
        <div class="col-md-6 text-end">
          <button class="btn btn-primary" @click="callTableHis">Search</button>
        </div>
      </div>
      <div style="overflow-x: auto;">
        <table class="table table-sm">
        <thead class="table-light">
          <tr> 
            <SortColumn @click="sortColumnHis" sortBy="testDate" colGroup="tbl1">Test Date</SortColumn>
            <SortColumn @click="sortColumnHis" sortBy="testLocation" colGroup="tbl1">Test Location</SortColumn>
            <SortColumn @click="sortColumnHis" sortBy="testApplicationId" colGroup="tbl1">Application ID</SortColumn>
            <SortColumn @click="sortColumnHis" sortBy="createdDate" colGroup="tbl1">Application Date</SortColumn>
            <SortColumn @click="sortColumnHis" sortBy="contractorName" colGroup="tbl1">Contractor</SortColumn>
            <th>NoOfWorkers</th>
            <SortColumn @click="sortColumnHis" sortBy="status" colGroup="tbl1">Status</SortColumn>
            <th style="width:115px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, idx) in tableParamHis.listData" :key="idx">
            <td>{{i.testDateDisplay}}</td>
            <td>{{i.testLocation}}</td>
            <td>{{i.testApplicationId}}</td>
            <td>{{i.createdDate}}</td>
            <td>{{i.contractorName}}</td>
            <td>{{i.noOfWorkers}}</td>
            <td>
              <span v-if="i.status == $data._testApplicationStatus.active.value" class="badge bg-secondary">{{i.statusDisplay}}</span>
              <span v-else>{{i.statusDisplay}}</span>
            </td>
            <td>
              <button class="btn btn-xs btn-primary" @click="clickView(i)">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      
      <Pagination
      :pagingParam="tableParamHis"
      @updatePaging="updatePagingHis"
      />
    </div>
    
  </div>
</template>

<script>
import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'TestApplicationUpdateList',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      tableParamHis: this.dtoPaging(),
      currentTab: 'current'
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Test Applications' }
    ]);

    this.tableParam.orderBy = "TestDate";
    this.tableParam.orderBy = "desc";

    this.tableParamHis.orderBy = "TestDate";
    this.tableParamHis.orderBy = "desc";

    this.callTable();
    this.callTableHis();
  },
  methods: {

    //CURRENT <START>
    callTable() {
      this.showLoadingScreen();
      this.tableParam.status = this.$data._testApplicationStatus.active.value;
      this.tableParam.forTestCentre = true;
      this.tableParam.listData = [];
      api.regularPost(apiUrl.testApplication.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderBy = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj) {
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    clickUpdate(data) {
      this.$router.push({ name: 'ViewTestApplicationToUpdate', params: { id: data.oid } });
    },
    checkAbleToClick(data) {
      let today = new Date();
      let testDate = this.convertDateTimeStrToDateObj(data.testDate);
      console.log(testDate.getTime(), today.getTime());
      if (testDate.getTime() <= today.getTime()) {
        return true;
      }
      else {
        return false;
      }
    },
    //CURRENT <END>

    //HISTORY <START>
    callTableHis() {
      this.showLoadingScreen();
      this.tableParamHis.onlyHistory = true;
      this.tableParamHis.forTestCentre = true;
      this.tableParamHis.listData = [];
      api.regularPost(apiUrl.testApplication.getList, this.tableParamHis).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParamHis = response.data;
        }
      });
    },
    sortColumnHis(sortyBy, sortDir) {
      this.tableParamHis.orderBy = sortyBy;
      this.tableParamHis.orderDir = sortDir;
      this.callTableHis();
    },
    updatePagingHis(paramObj) {
      this.tableParamHis.currentpage = paramObj.currentPage;
      this.tableParamHis.rowcount = paramObj.rowPerPage;
      this.callTableHis();
    },
    clickView(data) {
      this.$router.push({ name: 'ViewTestApplicationToUpdate', params: { id: data.oid } });
    },
    //HISTORY <END>

  }
}
</script>
