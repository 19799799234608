<template>
  <div>
    <div v-if="!isNew" class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Company:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.company && 'control-error'" v-model="dataObj.company" :disabled="!hasPermission" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                SWS ID:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6 text-secondary">
                <input type="text" maxlength="5" class="form-control" :class="error.swsid && 'control-error'" v-model="dataObj.swsid" :disabled="!hasPermission" />
              </div>
            </div>
           <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Expiry Date:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <DatePicker @updatedate="changeExpiryDate" :cssClass="error.expiryDate && 'control-error'" :existing="expiryDate" :disabled="!hasPermission" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Terminate Date:<span class="text-danger">*</span>
              </div>
              <div class="col-sm-6">
                <DatePicker @updatedate="changeTerminateDate" :cssClass="error.terminateDate && 'control-error'" :existing="terminateDate" :disabled="!hasPermission" />
              </div>
            </div>
            
              <div class="row mb-2">
              <div class="col-sm-6 text-secondary">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :cssClass="error.status && 'control-error'" :existing="dataObj.status" value="value" text="text" @change="dataObj.status=$event" :disabled="!hasPermission"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="text-danger small">* Denotes Mandatory Field</span>
        </div>
        <div>
          <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button v-if="this.hasPermission" class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button>
        </div>
        
        
      </div>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Sw" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'AddEditSWS',

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms,
    ...Pickers
  },

  props: {

  },

  data() {
    return {
      basicLogInfoUrl: apiUrl.sws.getBasicLogInfo,
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      showAuditModal: false,
      expiryDate: null,
      terminateDate: null,
      hasPermission: false,
      statuses:[],
      error: {}
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {
    let pageName = this.isNew ? 'New SWS' : 'Edit SWS';
    this.setBreadcrumb([
      { name: 'SWS', url: '/swslist' }, { name: pageName }
    ]);

    if (this.isNew) {
      this.dataObj.isActive = true;
    }
    if (this.oid > 0) {
      this.getData();
    }

    this.statuses = [
      { value: this.$data._status.active.value, text: this.$data._status.active.text },
      { value: this.$data._status.deleted.value, text: this.$data._status.deleted.text },
    ];
    this.hasPermission = this.isAuthorizedPermission(this.$data._permissionCodes.manageSws);
  },

  methods: {
    getData() {
      let url = apiUrl.sws.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        if (this.dataObj.expiryDate)
          this.expiryDate = this.convertDateStrToDateObj(this.dataObj.expiryDate);
        if (this.dataObj.terminateDate)
          this.terminateDate = this.convertDateStrToDateObj(this.dataObj.terminateDate);
      });
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.sws.insert;
      }
      else {
        subUrl = apiUrl.sws.update;
      }

      this.showLoadingScreen();
      api.regularPost(subUrl, this.dataObj).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "ManageSWS" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "ManageSWS" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.company) { this.error.company = true; isValid = false; }
      if (!this.dataObj.swsid) { 
        this.error.swsid = true; isValid = false; 
        }else{
          if(this.dataObj.swsid.length != 5){
            this.error.swsid = true; isValid = false; 
          }
        }
      if (!this.dataObj.expiryDate) { this.error.expiryDate = true; isValid = false; }
      if (!this.dataObj.terminateDate) { this.error.terminateDate = true; isValid = false; }
      if (!this.dataObj.status) { this.error.status = true; isValid = false; }
      this.$forceUpdate();
      return isValid;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.swsid + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.sws.delete, this.dataObj).then((response) => {
              console.log(response);
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "ManageSWS" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    },
    changeExpiryDate(date) {
      this.dataObj.expiryDate = this.convertDateObjToDateStr(date);
    },
    changeTerminateDate(date) {
      this.dataObj.terminateDate = this.convertDateObjToDateStr(date);
    },
  }
}
</script>
