<template>
<div>
  <div>
    
  </div>
  <div>
     <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
             <div class="col-sm-4">
                Skill Set
              </div>
              <div class="col-sm-6">
                <v-select multiple label="name" v-model="selectedSkillSet" :options="skillSets" @input="changeSkillSet"></v-select>
              </div>
            </div>
             <div class="row mb-2">
              <div class="col-sm-4">
                Status
              </div>
              <div class="col-sm-6">
                <FormSelect :list="statuses" :existing="tableParam.status" value="value" text="text" @change="changeFilterStatus" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                Test Number
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :existing="tableParam.testNumber" v-model="tableParam.testNumber"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-primary float-end mb-2" @click="addNew">Add Skill Test</button>
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="callTable">Search</button>
          </div>
          
        </div>
      </div>
    </div>
    <div style="overflow-x: auto;">
        <table class="table table-sm">
      <thead class="table-light">
        <tr> 
          <SortColumn @click="sortColumn" sortBy="skillSetName" colGroup="tbl1">SkillSetName</SortColumn>
          <SortColumn @click="sortColumn" sortBy="testNumber" colGroup="tbl1">TestNumber</SortColumn>
          <SortColumn @click="sortColumn" sortBy="process" colGroup="tbl1">Process</SortColumn>
          <SortColumn @click="sortColumn" sortBy="category" colGroup="tbl1">Category</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.skillSetName}}</td>
          <td>{{i.testNumber}}</td>
          <td>{{i.process}}</td>
          <td>Category {{i.category}}</td>
          <td>
            <span v-if="i.statusDisplay == 'Active'" class="badge bg-success">{{i.statusDisplay}}</span>
            <span v-else class="badge bg-danger">{{i.statusDisplay}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-primary" @click="clickDetails(i)">
              <span>Edit</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
        </div>
    
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'SkillTests',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      tableParam: this.dtoPaging(),
      statuses: [],
      selectedSkillSet:null,
       skillSets:[],
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'Skill Test' }
    ]);

    this.statuses = [
      { value: "", text: "All" },
      { value: "A", text: "Active" },
      { value: "S", text: "Suspended" },
    ];

    let url = apiUrl.report.workerSummaryPreparedReport 
        api.regularGet(url,null ).then((response) => {
          this.skillSets = response.data.skillTests;
        });

    this.tableParam.skillSetOids = [];
    this.tableParam.swsId = "";
    this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.skillTest.getList, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickDetails(data) {
      this.$router.push({ name: 'AddEditSkillTest', params: { id: data.oid } });
    },
    addNew() {
      this.$router.push({ name: 'AddEditSkillTest' });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeSkillSet(list){
       this.tableParam.skillSetOids = [];
        list.forEach(e => {
          this.tableParam.skillSetOids.push(e.oid);
        });
         this.callTable();
    },
    changeFilterStatus(e) {
      this.tableParam.status = e;
      this.callTable();
    }
  }
}
</script>
