<template>
  <div>
    <div class="card border-0">
      <div class="row">
        <div class="col-md-9">
          <div class="row mb-3">
            <div class="col-sm-12">
              <i class="bi bi-bank"></i> <label style="font-weight:700;font-size:30px;"
                class="control-label">{{dataObj.corpName}}</label>
            </div>
            <div class="col-sm-12">
              <label style="font-weight:500;" class="control-label"> {{dataObj.adminContact}} / Telephone No.:
                {{dataObj.telephone}} / Email: {{dataObj.corporateEmail}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-if='getLoggedInUser().userGroupType == $data._userGroupType.admin.value'>
          <div class="dropdown float-end" style="display:inline-block">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              More Actions
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#" @click="()=>{showAuditModal=true;}">Audit</a></li>
              <li><a class="dropdown-item" href="#" @click="clickNewInvoice">New Invoice</a></li>
              <li><a class="dropdown-item" href="#" @click="clickStatement">Generate Statement</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="my-tab">
        <div class="my-tab-item" :class="currentTab=='info' && 'active'" @click="currentTab='info'">Details</div>
        <div v-if="dataObj.isMOM" class="my-tab-item" :class="currentTab=='tab2' && 'active'"
          @click="currentTab='tab2'">{{dataObj.quotaTitle}}</div>
        <div v-if="dataObj.invoiceList && dataObj.invoiceList.length > 0" class="my-tab-item" :class="currentTab=='billing' && 'active'"
          @click="currentTab='billing'">Billing</div>
      </div>
      <div v-show="currentTab=='info'" class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div v-show="isTestCentreCwqs" class="row mb-3">
              <div class="col-sm-6 text-secondary">SAC Certificate Number:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.saccertNumber}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Corporate Name:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.corpName}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Building Name:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.buildingName}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Block No.:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.blkNo}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Unit No.:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.unitNo}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Address:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.address1}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Address 2:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.address2}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Country:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.countryName}}</label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Postcode:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.postalCode}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Administrator Name:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.adminContact }}</label>

              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Administrator Designation:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.adminDesignation}}</label>

              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Telephone No.:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.telephone}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Fax No.:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.fax}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Email:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.corporateEmail}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Mobile Contact No.:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.mobileNo}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Auto Invoice:</div>
              <div class="col-sm-6">
                <label v-if="dataObj.isAutoInvoice" class="control-label">Yes</label>
                <label v-else class="control-label">No</label>
              </div>
            </div>
          </div>
        </div>

        <div v-show="isContractor">
          <hr />

          <div class="row mb-3">
            <div class="col-md-4 text-secondary">
              Ownership(Contractors Only)
            </div>
            <div class="col-md-9">
              <label class="control-label">{{dataObj.ownershipName}}</label>
            </div>
          </div>
          <h6 class="card-subtitle mb-2 text-muted mt-4">
            Principal Activities
          </h6>
          <div class="row mb-1">
            <div class="col-sm-4">
              <ul>
                <li v-if="principaleActivities.plantConstruction">Plant Construction</li>
                <li v-if="principaleActivities.plantMaintenance">Plant Maintenance</li>
                <li v-if="principaleActivities.processDesign">Process Design</li>
                <li v-if="principaleActivities.buildingConstruction">Building Construction</li>
                <li v-if="principaleActivities.equipmentInstallation">Equipment Installation</li>
                <li v-if="principaleActivities.shopFabrication">Shop Fabrication</li>
                <li v-if="principaleActivities.others">{{principaleActivities.others}}</li>
              </ul>
            </div>
          </div>
          <h6 class="card-subtitle mb-2 text-muted mt-4">
            Role of Contractor(Contactors only) 1 or more selections:
          </h6>
          <div class="row mb-3">
            <div class="col-sm-4">
              <ul>
                <li v-if="roleOfContractor.turnkeyContractor">Turnkey Contractor</li>
                <li v-if="roleOfContractor.mainContractor">Main Contractor</li>
                <li v-if="roleOfContractor.subContractor">Sub Contractor</li>
              </ul>
            </div>
          </div>
          <h6 class="card-subtitle mb-2 text-muted mt-4">
            Employment Strength (Contractors Only)
          </h6>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">
              Total company strength:
            </div>
            <div class="col-md-9">
              <label class="control-label">{{dataObj.companyStrength}}</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">
              No. of management staff:
            </div>
            <div class="col-md-9">
              <label class="control-label">{{dataObj.managementStaff}}</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">
              No. of foreigner:
            </div>
            <div class="col-md-9">
              <label class="control-label">{{dataObj.foreignerWorker}}</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">
              No. of local:
            </div>
            <div class="col-md-9">
              <label class="control-label">{{dataObj.localWorker}}</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-3 text-secondary">
              Training budget(%):
            </div>
            <div class="col-md-9">
              <label class="control-label">{{dataObj.trainingBudget}}</label>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6">
             <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Customer Account No:</div>
              <div class="col-sm-6">
                {{dataObj.refGiro}}
              </div>
            </div>
            <div  v-show="isContractor" class="row mb-3">
              <div class="col-sm-4 text-secondary">
                CPF Account No.:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.cpfAccountNo}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                SWS Member Name:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.swsName}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 text-secondary">Payment Method:</div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.paymentMethodName}}</label>
              </div>
            </div>
          </div>
        </div>

        <div v-show="isGiro">
          <hr />
          <h5>GIRO Information</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Name of Financial Institution:</div>
                <div class="col-sm-6">
                  <label class="control-label">{{giro.bankName}}</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Branch:</div>
                <div class="col-sm-6">
                  <label class="control-label">{{giro.branchCode}}</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Account Number:</div>
                <div class="col-sm-6">
                  <label class="control-label">{{giro.bankAccountNum}}</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Account Holder Name:</div>
                <div class="col-sm-6">
                  <label class="control-label">{{giro.name}}</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4 text-secondary">Account Holder Contact (Tel/Fax):</div>
                <div class="col-sm-6">
                  <label class="control-label">{{giro.telephone}}</label>
                </div>
              </div>
              <div class="row mb-3">
              <div class="col-sm-4 text-secondary">
                Giro Status:
              </div>
              <div class="col-sm-6">
                 <label class="control-label">{{dataObj.giroStatusDisplay}}</label>
              </div>
            </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-4">Status:</div>
              <div class="col-sm-6">
                <label v-if="dataObj.status==$data._corporateStatus.active.value" class="control-label"><span
                    class="badge bg-success">{{$data._corporateStatus.active.text}}</span></label>
                <label v-if="dataObj.status==$data._corporateStatus.suspended.value" class="control-label"><span
                    class="badge bg-danger">{{$data._corporateStatus.suspended.text}}</span></label>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">
          Back
        </button>
        <!-- <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button> -->
      </div>
      <div v-show="currentTab=='tab2'" class="card-body">
        <div style="overflow-x: auto;">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th>Quota Application No</th>
              <th>MNC Name</th>
              <th>Main Contractor Name</th>
              <th>Sub Contractor Name</th>
              <th>Last Modified Date</th>
              <th>Status</th>
              <th>Approved Date</th>
              <th style="width:115px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idx) in quotaList" :key="idx">
              <td>{{i.quotaApplicationNo}}</td>
              <td>{{i.mncName}}</td>
              <td>{{i.mainContractorName}}</td>
              <td>{{i.subContractorName}}</td>
               <td>{{i.modifiedDate}}</td>
              <td>
                {{getQuotaTextByValue(i.status)}}
              </td>
              <td>{{i.approvedByIPSDate}}</td>
              <td>
                <button class="btn btn-sm btn-primary" @click="clickQuota(i)">
                  <span>View</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        
      </div>
      <div v-show="currentTab=='billing'" class="card-body">
        <div class="my-tab">
          <div class="my-tab-item" :class="childDurrentTab=='invoice' && 'active'" @click="childDurrentTab='invoice'">Invoices
          </div>
          <div class="my-tab-item" :class="childDurrentTab=='credit' && 'active'"
            @click="childDurrentTab='credit'">Credit Notes</div>
        </div>
        <div v-show="childDurrentTab=='invoice'" class="card-body">
          <table class="table table-sm">
            <thead class="table-light">
              <tr>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <!-- <th>Corporate Name</th>
            <th>Entity Type</th> -->
                <th>Amount</th>
                <th>For Month</th>
                <th>Status</th>
                <th>Payment Date</th>
                <th>Cheque Number</th>
                <th style="width:115px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, idx) in dataObj.invoiceList" :key="idx">
                <td>{{i.invoiceNumber}}</td>
                <td>{{i.invoiceDate}}</td>
                <!-- <td>{{i.corporateName}}</td>
            <td>{{i.corporateTypeDisplay}}</td> -->
                <td>{{i.totalAmount}}</td>
                <td>{{i.forMonth}}</td>
                <td>
                  <span v-if="i.status == $data._invoiceStatus.new.value" class="badge bg-success">{{i.statusDisplay}}
                  </span>
                  <span v-else class="badge bg-secondary">{{i.statusDisplay}}</span>
                </td>
                <td>{{i.paymentDate}}</td>
                <td>{{i.chequeNo}}</td>
                <td>
                  <button class="btn btn-sm btn-primary" @click="clickInvoice(i)">
                    <span>View</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="
        () => {
          showAuditModal = false;
        }
      " :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog :timezone="timezone" tablename="Corporate" :primarykey="oid" :categories="[]" :defaultCategory="'All'"
          :basicLogInfoOid="oid" :basicLogInfoUrl="basicLogInfoUrl" />
      </div>
    </ModalDiaglog>

    <ModalDiaglog size="xl" :show="showModal" :onClose="()=>{showModal=false}" :hasHeader="true" :title="title"
          :showSubmitBtn1="false">
          <div v-if="showModal">
            <div class="row">
              <div class="col-sm-12">
                <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Name:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.name && 'control-error'"
                      v-model="statementParam.name" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Unit No:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.unitNo && 'control-error'"
                      v-model="statementParam.unitNo" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Block No:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.blockNo && 'control-error'"
                      v-model="statementParam.blockNo" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    BuildingName:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.buildingName && 'control-error'"
                      v-model="statementParam.buildingName" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Address 1:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.address1 && 'control-error'"
                      v-model="statementParam.address1" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                    Address 2:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.address2 && 'control-error'"
                      v-model="statementParam.address2" />
                  </div>
                </div>
                 <div class="row mb-3">
                  <div class="col-sm-4 text-secondary">
                   Postal Code:
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" :class="error.postalCode && 'control-error'"
                      v-model="statementParam.postalCode" />
                  </div>
                </div>
                <button class="btn btn-primary m-1 float-end" style="width:100px;" @click="generateStatement">Generate</button>
              </div>
              
            </div>
          </div>
        </ModalDiaglog>   
  </div>
</template>

<script>
  import apiUrl from "@/common/apiurl";
  import api from "@/common/api";
  import AuditLog from "@/components/custom/AuditLog";
  import ModalDiaglog from "@/components/custom/ModalDiaglog";
  import * as Forms from "@/components/custom/forms";
  export default {
    name: "EditCorporate",
    components: {
      AuditLog,
      ModalDiaglog,
      ...Forms,
    },
    props: {
      type: String,
    },
    data() {
      return {
        title:'',
        showModal:false,
        statementParam:{},
        basicLogInfoUrl: apiUrl.corporate.getBasicLogInfo,
        oid: 0,
        isNew: true,
        confirmObj: {},
        dataObj: {
          invoiceList:[]
        },
        giro: {},
        principaleActivities: {
          plantConstruction: false,
          plantMaintenance: false,
          processDesign: false,
          buildingConstruction: false,
          equipmentInstallation: false,
          shopFabrication: false,
          others: "",
        },
        roleOfContractor: {
          turnkeyContractor: false,
          mainContractor: false,
          subContractor: false,
        },
        timezone: api.TIMEZONE,
        countries: [],
        swsMembers: [],
        ownerships: [],
        banks: [],
        branches: [],
        bankBranches: [],
        sac: [],
        payments: [],
        statuses: [],
        showAuditModal: false,
        parentUrl: "",
        error: {},
        isContractor: false,
        isTestCentreCwqs: false,
        isGiro: false,
        currentTab: 'info',
        childDurrentTab: 'invoice',
        quotaList:[]
      };
    },
    destroyed() {
      this.clearBreadcrumb();
    },
    beforeMount() {
      let passedId = this.$route.params.id;
      if (passedId && passedId > 0) {
        this.oid = parseInt(passedId);
        this.isNew = false;
      }
    },
    mounted() {
      //breadcrumbs
      let pageName = "View ";
      pageName += this.getCorporateTypeTextByValue(this.$route.params.type);
      let prevLink = {};
      switch (this.$route.params.type) {
        case this.$data._corporateType.industry.value: {
          prevLink = {
            name: this.$data._corporateType.industry.text,
            url: "/industries",
          };
          break;
        }
        case this.$data._corporateType.contractor.value: {
          prevLink = {
            name: this.$data._corporateType.contractor.text,
            url: "/contractors",
          };
          break;
        }
        case this.$data._corporateType.testCenter.value: {
          prevLink = {
            name: this.$data._corporateType.testCenter.text,
            url: "/testcentrecwqs",
          };
          break;
        }
      }
      this.setBreadcrumb([prevLink, {
        name: pageName
      }]);
      api.regularGet(apiUrl.regIndustry.dropdowns, null).then((response) => {
        this.countries = response.data.countries;
        this.swsMembers = response.data.sws;
        this.banks = response.data.banks;
        this.branches = response.data.branches;
        this.ownerships = response.data.ownerships;
        this.sac = response.data.sac;
        this.payments = response.data.payments;
        if (this.oid > 0) {
          this.getData();
        }
      });
      this.statuses = [{
          value: this.$data._corporateStatus.active.value,
          text: this.$data._corporateStatus.active.text,
        },
        {
          value: this.$data._corporateStatus.suspended.value,
          text: this.$data._corporateStatus.suspended.text,
        },
      ];
    },
    methods: {
      getData() {
        let url = apiUrl.corporate.getByOid + "/" + this.oid;
        console.log(url);
        api.regularGet(url).then((response) => {
          console.log(response.data);
          this.dataObj = response.data;
          if(this.dataObj.isMOM){
            this.quotaList = this.dataObj.quotaList
          }
          this.changePayment(this.dataObj.paymentMethodOid);
          if (this.dataObj.giro) {
            this.giro = this.dataObj.giro;
            this.changeBank(this.giro.bankOid);
          }
          switch (this.dataObj.type) {
            case this.$data._corporateType.industry.value: {
              this.parentUrl = "Industries";
              break;
            }
            case this.$data._corporateType.contractor.value: {
              this.parentUrl = "Contractors";
              this.isContractor = true;
              if (this.dataObj.principalActivities) {
                this.principaleActivities = JSON.parse(
                  this.dataObj.principalActivities
                );
              }
              if (this.dataObj.roleOfContractor) {
                this.roleOfContractor = JSON.parse(this.dataObj.roleOfContractor);
              }
              break;
            }
            case this.$data._corporateType.testCenter.value: {
              this.parentUrl = "TestCentreCWQS";
              this.isTestCentreCwqs = true;
              break;
            }
          }
        });
      },
      clickSave() {
        if (!this.validate()) return;
        if (this.isContractor) {
          this.dataObj.principalActivities = JSON.stringify(
            this.principaleActivities
          );
          this.dataObj.roleOfContractor = JSON.stringify(this.roleOfContractor);
        }
        let subUrl = "";
        if (this.isNew) {
          subUrl = apiUrl.corporate.insert;
        } else {
          subUrl = apiUrl.corporate.update;
        }
        if (this.isPaymentMethodGiro(this.dataObj.paymentMethodOid)) {
          this.dataObj.giro = this.giro;
        } else {
          this.dataObj.giro = null;
        }
        api.regularPost(subUrl, this.dataObj).then((response) => {
          if (response.status == 200) {
            this.showMessage("Successfully saved.", "success");
            this.$router.push({
              name: this.parentUrl
            });
          } else if (
            response.status == 400 ||
            response.errorText.response.status == 400
          ) {
            this.showMessage(response.errorText.response.data.message, "error");
          } else {
            this.showMessage(window.message.unable_proceed, "error");
          }
        });
      },
      clickCancel() {
        this.$router.push({
          name: "Dashboard"
        });
      },
      validate() {
        let isValid = true;
        Object.keys(this.error).forEach((k) => (this.error[k] = false));
        if (!this.dataObj.corpName) {
          this.error.corpName = true;
          isValid = false;
        }
        if (!this.dataObj.countryOid) {
          this.error.countryOid = true;
          isValid = false;
        }
        if (!this.dataObj.swsoid && this.isTestCentreCwqs) {
          this.error.swsoid = true;
          isValid = false;
        }
        this.$forceUpdate();
        return isValid;
      },
      confirmRemove() {
        this.showConfirmDiaglog({
          show: true,
          message: "Are you sure to remove " + this.dataObj.name + "?",
          submitFunction: () => {
            api
              .regularPost(apiUrl.corporate.delete, this.dataObj)
              .then((response) => {
                console.log(response);
                if (response.status == 200) {
                  this.showMessage("Successfully Deleted.", "success");
                  this.$router.push({
                    name: this.parentUrl
                  });
                } else if (
                  response.status == 400 ||
                  response.errorText.response.status == 400
                ) {
                  this.showMessage(
                    response.errorText.response.data.message,
                    "error"
                  );
                } else {
                  this.showMessage(window.message.doctors.unable_delete, "error");
                }
              });
          },
        });
      },
      changePayment(e) {
        this.dataObj.paymentMethodOid = e;
        let isPaymentGiro = this.isPaymentMethodGiro(
          this.dataObj.paymentMethodOid
        );
        if (isPaymentGiro) {
          this.isGiro = true;
        } else {
          this.isGiro = false;
        }
      },
      showAudit() {
        this.showAuditDialog(true);
      },
      isPaymentMethodGiro(oid) {
        let tmpObj = this.payments.find((x) => x.oid == oid);
        if (tmpObj && tmpObj.name.toLowerCase() == "giro") {
          return true;
        } else {
          return false;
        }
      },
      changeBank(e) {
        this.giro.bankOid = e;
        this.bankBranches = this.branches.filter((x) => x.parentSettingOid == e);
      },
      clickQuota(data){
        this.$router.push({ name: 'ViewQuota', params: { id: data.oid } });
      },
      clickNewInvoice(){
        this.$router.push({ name: 'EditInvoice', params: {id: 0, corpOid: this.dataObj.oid } });
      },
      clickInvoice(i){
        this.$router.push({ name: 'EditInvoice', params: { id: i.oid } });
      },
      clickStatement(){
      this.statementParam.corpOid = this.oid;
      this.statementParam.name = this.dataObj.corpName;
      this.statementParam.unitNo = this.dataObj.unitNo;
      this.statementParam.blockNo = this.dataObj.blkNo;
      this.statementParam.buildingName = this.dataObj.buildingName;
      this.statementParam.address1 = this.dataObj.address1;
      this.statementParam.address2 = this.dataObj.address2;
      this.statementParam.postalCode = this.dataObj.postalCode;
      this.title = "Corporate Info";
      this.showModal = true;
    },
    generateStatement(){
      this.showLoadingScreen();
      api.downloadFile(apiUrl.corporate.invoiceStatement, this.statementParam, 'Statement.pdf', () => {
        this.hideLoadingScreen();
        this.showMessage('Downloaded successfully.', 'success');
        this.showModal = false;
      });
    }
    },
  };
</script>