<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->

    <div class="row mb-2">
      <div class="col-md-6">
        <div class="row mb-1">
          <div class="col-sm-5">
            NRIC/FIN
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.nricFin" />
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-sm-5">
            Work Permit No.
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.workPermit" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row mb-1">
          <div class="col-sm-5">
            Occupation
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" v-model="tableParam.occupation" />
          </div>
        </div>
        <div class="text-end">
           
          <button class="btn btn-primary me-1" @click="clickSearch">Search</button>
          <!-- <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;" @click="download">Download</button> -->
        </div>
      </div>      
    </div>
    
    <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <SortColumn @click="sortColumn" sortBy="name" colGroup="tbl1">Name</SortColumn>
          <SortColumn @click="sortColumn" sortBy="nricFin" colGroup="tbl1">NRIC/FIN</SortColumn>
          <SortColumn @click="sortColumn" sortBy="workPermit" colGroup="tbl1">Work Permit No.</SortColumn>
          <SortColumn @click="sortColumn" sortBy="occupationOid" colGroup="tbl1">Occupation</SortColumn>
          <SortColumn @click="sortColumn" sortBy="workPermitExpiryDate" colGroup="tbl1">Work Permit Expiry Date</SortColumn>
          <SortColumn @click="sortColumn" sortBy="status" colGroup="tbl1">Status</SortColumn>
          <th style="width:115px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in tableParam.listData" :key="idx">
          <td>{{i.name}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.occupationDisplay}}</td>
          <td>{{i.workPermitExpiryDateDisplay}}</td>
          <td>{{i.statusDisplay}}</td>
          <td>
            <span class="badge bg-success" v-if="i.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
            <span class="badge bg-danger" v-else-if="i.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
            <span class="badge" v-else>{{i.statusDisplay}}</span>
          </td>
          <td>
            <button v-if="isAuthorizedPermission($data._permissionCodes.viewWorker)" class="btn btn-sm btn-primary me-2 mb-1" @click="clickView(i)">
              View
            </button>
            <button v-if="isAuthorizedPermission($data._permissionCodes.manageWorker)" class="btn btn-sm btn-primary mb-1" @click="clickEdit(i)">
              Edit
            </button>            
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :pagingParam="tableParam"
      @updatePaging="updatePaging"
    />
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'WorkerList',
  components: {
    ...TableAddons
  },
  data() {
    return {
      tableParam: this.dtoPaging()
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
     let passedValue = this.$route.params.filter;
     if(passedValue){
       this.tableParam.filterType = passedValue;
     }
  }, 
  mounted() {
    this.setBreadcrumb([
      { name: 'Workers' }
    ]);
    this.callTable();
  },
  methods: {
    download() {
      let data = {
        nric: this.tableParam.nricFin,
        welderId: this.tableParam.welderId,
        workPermitNo: this.tableParam.workPermit,
      }
      api.downloadFile(apiUrl.worker.download,data,"worker.xlsx",() =>{
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    callTable() {
      this.showLoadingScreen();
      this.tableParam.listData = [];
      api.regularPost(apiUrl.worker.getListStaff, this.tableParam).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.tableParam = response.data;
        }
      });
    },
    clickEdit(data) {
      this.$router.push({ name: 'AddEditStaff', params: { id: data.oid, corpName: data.corporateName, nricFin: data.nricFin, workPermitNo: data.workPermit } });
    },
    clickView(data){
      this.$router.push({ name: 'ViewStaff', params: { id: data.oid, corpName: data.corporateName, nricFin: data.nricFin, workPermitNo: data.workPermit } });
    },

    sortColumn(sortyBy, sortDir) {
      this.tableParam.orderby = sortyBy;
      this.tableParam.orderDir = sortDir;
      this.callTable();
    },
    updatePaging(paramObj){
      this.tableParam.currentpage = paramObj.currentPage;
      this.tableParam.rowPerPage = paramObj.rowPerPage;
      this.callTable();
    },
    changeTblFilter(text){
      this.tableParam.searchtext = text;
      this.callTable();
    },
    clickSearch(){
      this.callTable();
    }

  }
}
</script>
