<template>
<div>
  <div>
    <!-- <TableFilter @changeText="changeTblFilter" /> -->

    <table class="table table-sm">
      <thead class="table-light">
        <tr>
          <th></th>
          <th>Name</th>
          <th>NRIC/FIN</th>
          <th>Work Permit No.</th>
          <th>Welder ID</th>
          <th>Work Permit Expiry Date</th>
          <th>AWSHPP Expiry Date</th>
          <th v-if="isShowTerminatedDateAndStatus">Terminated Date</th> 
          <th v-if="isShowTerminatedDateAndStatus">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in workers" :key="idx">
          <td>{{idx + 1}}</td>
          <td>{{i.name}}</td>
          <td>{{i.nricFin}}</td>
          <td>{{i.workPermit}}</td>
          <td>{{i.welderId}}</td>
          <td>{{i.workPermitExpiryDateDisplay}}</td>
          <td>{{i.awshppExpiryDateDisplay}}</td>
          <td v-if="isShowTerminatedDateAndStatus">{{i.terminatedDateDisplay}}</td>
          <td v-if="isShowTerminatedDateAndStatus">
            <span class="badge bg-success" v-if="i.status==$data._workerStatus.active.value">{{$data._workerStatus.active.text}}</span>
            <span class="badge bg-danger" v-else-if="i.status==$data._workerStatus.terminated.value">{{$data._workerStatus.terminated.text}}</span>
            <span class="badge" v-else>{{i.statusDisplay}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    
  </div>
</div>  
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";

export default {
  name: 'InvoiceWorker',
  components: {
    ...TableAddons
  },
  data() {
    return {
        oid:0,
        workers:[],
        isShowTerminatedDateAndStatus:true
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount(){
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  mounted() {
    let pageName = 'Invoice Worker';
    this.setBreadcrumb([
      { name: 'List', url: '/invoices' }, { name: pageName }
    ]);
    this.callTable();
    let currentUser = this.getLoggedInUser();
    if(currentUser.userGroupType == this.$data._userGroupType.contractor.value){
      this.isShowTerminatedDateAndStatus = false;
    }
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      api.regularGet(apiUrl.invoice.getWorker + "/" + this.oid).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.workers = response.data;
        }
      });
    }
  }
}
</script>
