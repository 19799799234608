<template>
<div>
  <div>

  </div>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Skill Set
              </div>
              <div class="col-sm-6">
                <v-select label="name" v-model="selectedSkillSet" :options="skillSets" @input="changeSkillSet"></v-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                Corporates
              </div>
              <div class="col-sm-6">
                <v-select multiple label="corpName" v-model="selectedCorporates" :options="corporates" @input="changeCorporate"></v-select>
              </div>
            </div>
             <div class="row mb-2">
              <div class="col-sm-4">
                Registration Date
              </div>
              <div class="col-sm-6">
                <DateRangePicker @updatedaterange="dateRangeChangeRegister"></DateRangePicker>
              </div>
            </div>
            
          </div>
          <div class="col-lg-6">
            <div class="row mb-2">
              <div class="col-sm-4">
                Nationality
              </div>
              <div class="col-sm-6">
                <v-select multiple label="name" v-model="selectedNationality" :options="nationalities" @input="changeNationality"></v-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                Sponsor
              </div>
              <div class="col-sm-6">
                <input
                        type="text"
                        class="form-control"
                        v-model="sponsor"
                      />
              </div>
            </div>
             <div class="row mb-2">
              <div class="col-sm-4">
                Commencement Date
              </div>
              <div class="col-sm-6">
                <DateRangePicker @updatedaterange="dateRangeCommence"></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            
            <button class="btn btn-primary float-end  mb-2" style="margin-right:10px;"
              @click="download">Download Excel</button>
            <button class="btn btn-success float-end  mb-2" style="margin-right:10px;"
              @click="callTable">Generate</button>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb-1">
      
      <div class="card-body" v-for="(x, MIdx) in param.listData" :key="MIdx">
        <h5 class="card-title">{{x.corporateName}}</h5>

        <div class="table-responsive">
          <table class="table table-sm">
            <thead class="table-light">
              <tr>
                <th>NRIC/FIN</th>
                <th>Work Permit No.</th>
                <th>WelderID</th>
                <th>Name</th>
                <th>Age</th>
                <th>Nationality</th>
                <th>Skillset</th>
                <th>Education Level</th>
                <th>Technical Skill</th>
                <th>Work Permit Expiry Date</th>
                <th>AWSHPP Expiry Date</th>
                <th>Sponsor</th>
                <th>Subcontractor</th>
                <th>Commencement Date</th>
                <th>Registration Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, idx) in x.workerList" :key="idx">
                <td>{{i.nricFin}}</td>
                <td>{{i.workPermit}}</td>
                <td>{{i.welderId}}</td>
                <td>{{i.name}}</td>
                <td>{{i.age}}</td>
                <td>{{i.nationality}}</td>
                <td>{{i.skillSetDisplay}}</td>
                <td>{{i.education}}</td>
                <td>{{i.techSkill}}</td>
                <td>{{i.workPermitExpiryDateDisplay}}</td>
                <td>{{i.awshppExpiryDateDisplay}}</td>
                <td>{{i.sponsor}}</td>
                <td>{{i.subContractor}}</td>
                <td>{{i.commenceDate}}</td>
                <td>{{i.createdDateDisplay}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive">
          <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th colspan="2">Statistics</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total number of workers processed</td>
              <td>{{x.totalNumberOfWorkersProcessed}}</td>
            </tr>
            <tr>
              <td>Total number of local workers</td>
              <td>{{x.totalNumberOfLocalWorkers}}</td>
            </tr>
            <tr>
              <td>Total number of foreign workers</td>
              <td>{{x.totalNumberOfForeignWorkers}}</td>
            </tr>
            <tr>
              <td>Number of workers > 40 year-old</td>
              <td>{{x.totalNumberOfWorkersOverForty}}</td>
            </tr>
            <tr>
              <td>Number of workers less than 41 year-old</td>
              <td>{{x.totalNumberOfWorkersUnderForty}}</td>
            </tr>
            <tr>
                <td colspan="2" style="font-weight: bold;">Number of workers by each Nationality</td>
            </tr>
            <tr v-for="(a, MIdx) in x.workersByNationality" :key="'idx1' + MIdx">
              <td>{{a.type}}</td>
              <td>{{a.workerCount}}</td>
            </tr>
             <tr>
                <td colspan="2" style="font-weight: bold;">Number of workers by each Skillset</td>
            </tr>
            <tr v-for="(b, MIdx) in x.workersBySkillSet" :key="'idx2' + MIdx">
              <td>{{b.type}}</td>
              <td>{{b.workerCount}}</td>
            </tr>
             <tr>
                <td colspan="2" style="font-weight: bold;">Number of workers by each Education Level</td>
            </tr>
            <tr v-for="(c, MIdx) in x.workersByEduLevel" :key="'idx3'+MIdx">
              <td>{{c.type}}</td>
              <td>{{c.workerCount}}</td>
            </tr>
            <tr>
              <td>Number of workers without certification</td>
              <td>{{x.totalNumberOfWorkersWithoutCertification}}</td>
            </tr>
            <tr>
              <td>Number of workers with valid certification</td>
              <td>{{x.totalNumberOfWorkersWithValidCertification}}</td>
            </tr>
            <tr>
              <td>Number of welders with certification expired</td>
              <td>{{x.totalNumberOfWorkersWithExpiredCertification}}</td>
            </tr>
            <tr>
              <td>Number of workers with WP expiring within 1 month</td>
              <td>{{x.totalNumberOfWorkersWithExpiredWPOneMonth}}</td>
            </tr>
            <tr>
              <td>Number of workers with WP expiring within the 2nd month</td>
              <td>{{x.totalNumberOfWorkersWithExpiringWPOneMonth}}</td>
            </tr>
            <tr>
              <td>Number of workers with WP expired within 1 month</td>
              <td>{{x.totalNumberOfWorkersWithExpiredWPOneMonth}}</td>
            </tr>
            <tr>
              <td>Number of workers with AWSHPP expiring within 1 month</td>
              <td>{{x.totalNumberOfWorkersWithExpiringAWSHPPOneMonth}}</td>
            </tr>
            <tr>
              <td>Number of workers with AWSHPP expiring within the 2nd month</td>
              <td>{{x.totalNumberOfWorkersWithExpiringAWSHPPTwoMonth}}</td>
            </tr>
            <tr>
              <td>Number of workers with AWSHPP expired within 1 month</td>
              <td>{{x.totalNumberOfLocalWorkers}}</td>
            </tr>
            <tr>
              <td>Number of workers with AWSHPP expired for > 1 month</td>
              <td>{{x.totalNumberOfWorkersWithExpiredAWSHPPOneMonth}}</td>
            </tr>
             <tr>
                <td colspan="2" style="font-weight: bold;">Number of workers by each Subcontractor</td>
            </tr>
            <tr v-for="(d, idx4) in x.workersBySubContractor" :key="idx4">
              <td>{{d.type}}</td>
              <td>{{d.workerCount}}</td>
            </tr>
             <tr>
                <td colspan="2" style="font-weight: bold;">Number of workers by each Sponsor</td>
            </tr>
            <tr v-for="(e, MIdx) in x.workersBySponsor" :key="'idx5'+MIdx">
              <td>{{e.type}}</td>
              <td>{{e.workerCount}}</td>
            </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>

import api from "@/common/api";
import apiUrl from "@/common/apiurl";
import * as TableAddons from "@/components/custom/table_addons";
import * as Forms from '@/components/custom/forms';
import * as Pickers from "@/components/custom/pickers";
export default {
  name: 'WorkerProfileReport',
  components: {
     ...TableAddons,
    ...Forms,
    ...Pickers
  },
  data() {
    return {
      param:{
          skill:0,
          contractorOids:[],
          listData:[]
      },
      selectedNationality:null,
      nationalities:[],
      sponsor:'',
      statuses: [],
      pickDate:null,
      pDate:null,
      skillSets:[],
      selectedSkillSet:null,
      selectedCorporates:[],
      corporates:[]
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {    
    this.setBreadcrumb([
      { name: 'All Worker Profile' }
    ]);
    let url = apiUrl.report.workerSummaryPreparedReport 
        api.regularGet(url,null ).then((response) => {
          console.log(response);
          this.skillSets = response.data.skillTests;
          this.corporates = response.data.corporates;
          this.nationalities = response.data.nationalities;
        });
    
    this.param.skill = '';
    this.param.contractorOids = [];
    //this.callTable();
  },
  methods: {
    callTable() {
      this.showLoadingScreen();
      this.param.listData = [];
      api.regularPost(apiUrl.report.workerSummary, this.param).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.param.listData = response.data;
        }
      });
    },
    download() {
      this.showLoadingScreen();
      
      api.downloadFile(apiUrl.report.workerSummaryReport,this.param,"workersummary_detail.xlsx",() =>{
        this.hideLoadingScreen();
        this.showMessage("Successfully downloaded.", "success");
      });
    },
    changeSkillSet(e){
        if(e){
          this.param.skill ="'" +e.oid+"'";
      }else{
          this.param.skill = 0;
      }
      console.log(this.param.skill);
      this.callTable();
    },
    changeCorporate(list){
      this.param.contractorOids = [];
        list.forEach(e => {
          this.param.contractorOids.push(e.oid);
        });
    },
    changeNationality(list){
      this.param.nationality = [];
        list.forEach(e => {
          this.param.nationality.push(e.oid);
        });
    },
    dateRangeChangeRegister(dateRange) {
      this.param.regDateStart = this.convertDateObjToDateStr(dateRange.start);
      this.param.regDateEnd = this.convertDateObjToDateStr(dateRange.end);
    },
    dateRangeCommence(dateRange) {
      this.param.commencementDateStart = this.convertDateObjToDateStr(dateRange.start);
      this.param.commencementDateEnd = this.convertDateObjToDateStr(dateRange.end);
    },
  }
}
</script>
