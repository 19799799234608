<template>
  <div v-html="htmlContent">
    
  </div>
</template>
<script>

export default {
  name: 'Pages',
  data() {
    return {
      htmlContent: '',
    }
  },
  mounted() {
    let homeMenuObj = window.homePagesMenu.find(x => x.name == this.$route.params.name);

    fetch('/pages/' + homeMenuObj.html)
      .then(data => data.text())
      .then(html => this.htmlContent = html);
  }
}
</script>
