<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Credit Note No:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.creditNoteNumber}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Credit Note Date:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.creditNoteDate}}</label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Entity Name:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.corporateName}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Entity Type:
              </div>
              <div class="col-sm-6">
                <label class="control-label">{{dataObj.corporateDisplay}}</label>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Invoice No:
              </div>
              <div class="col-sm-6">
                 <a href="javascript:;" @click="gotoInvoiceDetail">{{dataObj.invoiceNo}}</a>
              </div>
            </div>
            <div class="row mb-3">
              <h5>Item Details</h5>
              <div class="col-sm-12" style="overflow-x: auto;">
                <table class="table table-sm">
                  <thead class="table-light">
                    <tr>
                      <th>Description</th>
                      <th style="text-align: right;">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, idx) in creditNoteItems" :key="idx">
                      <td>{{i.itemDescription}}</td>
                      <td style="text-align: right;">{{i.creditAmount}}</td>
                    </tr>
                    <tr>
                      <td><span class="fw-bold text-info">Total Amount:</span> </td>
                      <td style="text-align: right;">{{numberWithCommas(Number(allTotalAmount).toFixed(2))}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
          <!-- <button v-if="canEdit && !isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
export default {
  name: 'ViewCreditNote',

  components: {
    ...Forms
  },
  props: {

  },
  
  data() {
    return {
      oid: 0,
      dataObj: {},
      creditNoteItems:[],
      allTotalAmount:0,
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
    }
  },
  mounted() {
    let pageName = 'Credit Note Details';
    this.setBreadcrumb([
      { name: 'List', url: '/creditnotes' }, { name: pageName }
    ]);
    if (this.oid > 0) {
      this.getData();
    }
   
  },

  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getData() {
      let url = apiUrl.creditNote.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.creditNoteItems = this.dataObj.creditNoteItems;
        this.allTotalAmount = this.dataObj.totalAmount;
      });
    },
    clickCancel() {
      this.$router.push({ name: "CreditNotes" });
    },
    gotoInvoiceDetail(){
      this.$router.push({ name: 'EditInvoice', params: { id: this.dataObj.invoiceOid } });
    }
  }
}
</script>
