import perCode from '@/common/permissionCodes'

import Login from '@/components/views/Login.vue'

import Link from '@/components/views/Link.vue'

import Home from '@/components/views/Home.vue'
import Dashboard from '@/components/views/dashboard/Dashboard.vue'
import Profile from '@/components/views/account/Profile.vue'
import Password from '@/components/views/account/Password.vue'
import ActivityLog from '@/components/views/ActivityLog'

// import Tenants from '@/components/views/tenant/Tenants'
// import AddEditTenant from '@/components/views/tenant/AddEditTenant'

// import TenantLocations from '@/components/views/tenant_location/TenantLocations'
// import AddEditTenantLocation from '@/components/views/tenant_location/AddEditTenantLocation'

// import TenantSettings from '@/components/views/tenant_setting/TenantSettings'
// import AddEditTenantSetting from '@/components/views/tenant_setting/AddEditTenantSetting'

//User
import Users from '@/components/views/user/Users'
import AddEditUser from '@/components/views/user/AddEditUser'
import ViewUser from '@/components/views/user/ViewUser'
//Roles
import Roles from '@/components/views/role/Roles'
import AddEditRole from '@/components/views/role/AddEditRole'
//Settings
import SettingMain from '@/components/views/setting/Main'
import Settings from '@/components/views/setting/Settings'
import AddEditSetting from '@/components/views/setting/AddEditSetting'
//Coroprate
import Industries from '@/components/views/corporate/Industries'
import Contractors from '@/components/views/corporate/Contractors'
import TestCentreCwqs from '@/components/views/corporate/TestCentreCwqs'
import EditCorporate from '@/components/views/corporate/EditCorporate'
import ViewCorporate from '@/components/views/corporate/ViewCorporate'
import WeldingInspectors from '@/components/views/corporate/WeldingInspectors'
import EditWeldingInspector from '@/components/views/corporate/EditWeldingInspector'
import ViewWeldingInspector from '@/components/views/corporate/ViewWeldingInspector'
import CorporateGiro from '@/components/views/corporate/GiroForm'
import CorporateProfile from '@/components/views/corporate/CorporateProfile'
//Contractor
import OnlineQuotaApp from '@/components/views/contractor/OnlineQuotaApp'
import FormAListing from '@/components/views/contractor/FormAListing'
import ViewQuota from '@/components/views/contractor/ViewQuota'
import FromContractors from '@/components/views/contractor/FromContractors'
//Register Corporate
import RegIndustry from '@/components/views/register/industry.vue'
import RegContractor from '@/components/views/register/contractor.vue'
import RegTestCenter from '@/components/views/register/testcenter.vue'
import MainReg from '@/components/views/register/main.vue'
import RegWeldingInspector from '@/components/views/register/weldinginspector.vue'
import ApplicationForm from '@/components/views/register/applicationform.vue'
import WIApplicationForm from '@/components/views/register/wiapplicationform.vue'
import GiroForm from '@/components/views/register/giroform.vue'
import CompleteRegister from '@/components/views/register/completeregister.vue'
//SWS
import SwsList from '@/components/views/sws/SwsList'
import AddEditSws from '@/components/views/sws/AddEditSws'
//Activate Coporate
import ActivateIndustries from '@/components/views/activate/ActivateIndustries'
import ActivateTestCenters from '@/components/views/activate/ActivateTestCenters'
import ActivateContractors from '@/components/views/activate/ActivateContractors'
import ActivateWeldingInspectors from '@/components/views/activate/ActivateWeldingInspectors'
import ActivateCorporate from '@/components/views/activate/ActivateCorporate'
import ActivateWeldingInspector from '@/components/views/activate/ActivateWeldingInspector'
import CheckWorkerToAdd from '@/components/views/worker/CheckWorkerToAdd'
//Worker
import WorkerList from '@/components/views/worker/WorkerList'
import StaffList from '@/components/views/worker/StaffList'
import IndustryWorkerList from '@/components/views/worker/IndustryWorkerList'
import AddEditWorker from '@/components/views/worker/AddEditWorker'
import AddEditStaff from '@/components/views/worker/AddEditStaff'
import ViewStaff from '@/components/views/worker/ViewStaff'
import ViewWorker from '@/components/views/worker/ViewWorker'
import BatchUploadWorker from '@/components/views/worker/BatchUploadWorker'
import BatchUploadStaff from '@/components/views/worker/BatchUploadStaff'
import TerminatedWorkerList from '@/components/views/worker/TerminatedWorkerList'
import WorkerTransferList from '@/components/views/worker/WorkerTransferList'
import PreQualificationConfirm from '@/components/views/test_management/PreQualificationConfirm'
import AddEditTestApplication from '@/components/views/test_application/AddEditTestApplication'
import TestApplicationList from '@/components/views/test_application/TestApplicationList'
import TestCandidateHistory from '@/components/views/test_application/TestCandidateHistory'
import ViewTestApplication from '@/components/views/test_application/ViewTestApplication'
import ViewTestApplicationToUpdate from '@/components/views/test_application/ViewTestApplicationToUpdate'
import TestApplicationConfirmList from '@/components/views/test_application/TestApplicationConfirmList'
import TestApplicationConfirm from '@/components/views/test_application/TestApplicationConfirm'
import TestApplicationCandidatesByDate from '@/components/views/test_application/TestApplicationCandidatesByDate'
import TestApplicationCandidatesByDatePrint from '@/components/views/test_application/TestApplicationCandidatesByDatePrint'
import TestApplicationUpdateList from '@/components/views/test_application/TestApplicationUpdateList'
import TestApplicationResultUpdate from '@/components/views/test_application/TestApplicationResultUpdate'
import TestApplicationResultUpdateBatch from '@/components/views/test_application/TestApplicationResultUpdateBatch'
import ViewTestApplicationResult from '@/components/views/test_application/ViewTestApplicationResult'
import WelderTestListForExpiryExtend from '@/components/views/test_application/WelderTestListForExpiryExtend'
import ViewTestApplicationPrint from '@/components/views/test_application/ViewTestApplicationPrint'

import Pages from '@/components/views/Pages.vue'

//Notification
import UserNotification from '@/components/views/notification/UserNotification'
import UserNotificationSetting from '@/components/views/notification/UserNotificationSetting'
import UserNotificationSettingByAdmin from '@/components/views/notification/UserNotificationSettingByAdmin'
import NotificationTemplates from '@/components/views/notification/NotificationTemplates'
import EditNotificationTemplate from '@/components/views/notification/EditNotificationTemplate'

//Forgot Password
import ForgotPassword from '@/components/views/ForgotPassword.vue'
import ChangePassword from '@/components/views/ChangePassword.vue'


//Roles
import ServiceItemList from '@/components/views/billing/ServiceItemList'
import AddEditServiceItem from '@/components/views/billing/AddEditServiceItem'

//Worker Assignment
import WorkerAssignments from '@/components/views/worker_assignment/WorkerAssignments'
import AddWorkerAssignment from '@/components/views/worker_assignment/AddWorkerAssignment'
import WorkerAssignmentsApprove from '@/components/views/worker_assignment/WorkerAssignmentsApprove'
import WorkerAssignmentsByWI from '@/components/views/worker_assignment/WorkerAssignmentsByWI'

//Invoice
import Invoices from '@/components/views/invoice/Invoices'
import EditInvoice from '@/components/views/invoice/EditInvoice'
import InvoiceWorker from '@/components/views/invoice/InvoiceWorker'
import InvoiceZipDownload from '@/components/views/invoice/InvoiceZipDownload'
//
import IndustryPreference from '@/components/views/IndustryPreference/IndustryPreference'
//Credit Notes
import CreditNotes from '@/components/views/creditNote/CreditNotes'
import ViewCreditNote from '@/components/views/creditNote/ViewCreditNote'

//Report
import PortalSummary from '@/components/views/report/PortalSummary'
import InvoiceDetailReport from '@/components/views/report/InvoiceDetailReport'
import CreditNoteReport from '@/components/views/report/CreditNoteReport'
import RevenueReport from '@/components/views/report/RevenueReport'
import WorkerProfileReport from '@/components/views/report/WorkerProfileReport'
import Welders from '@/components/views/report/Welders'
import ActivityLogReport from '@/components/views/report/ActivityLog'
import WorkerAssignmentReport from '@/components/views/report/WorkerAssignmentReport'
import WorkerQualificationReport from '@/components/views/report/WorkerQualificationReport'
import OnlineQuotaReport from '@/components/views/report/OnlineQuotaReport'
import TestStatisticsReport from '@/components/views/report/TestStatisticsReport'
import WorkerSkillInformationReport from '@/components/views/report/WorkerSkillInformationReport'
import WorkerSkillInformationDetailsReport from '@/components/views/report/WorkerSkillInformationDetailsReport'

//RT Report
import RTReportList from '@/components/views/rtreport/RTReportList'
import AddRTReport from '@/components/views/rtreport/AddRTReport'
import EditRTReport from '@/components/views/rtreport/EditRTReport'
import ViewRTReport from '@/components/views/rtreport/ViewRTReport'
import CheckWelderSummary from '@/components/views/rtreport/CheckWelderSummary'

//TestCentre Delegation
import UpdateTestCentreDelegate from '@/components/views/testcentre_delegation/UpdateTestCentreDelegate'

//News Announcement
import NewsAnnouncements from '@/components/views/newsannouncement/NewsAnnouncements'
import AddEditNewsAnnouncement from '@/components/views/newsannouncement/AddEditNewsAnnouncement'
//Worker Assessment
import AssessmentList from '@/components/views/worker_assessment/AssessmentList'
import AssessmentEntry from '@/components/views/worker_assessment/AssessmentEntry'
import EndorsementHistory from '@/components/views/worker_assessment/EndorsementHistory'
import EndorsementHistoryContractor from '@/components/views/worker_assessment/EndorsementHistoryContractor'
import EndorsementHistoryDetail from '@/components/views/worker_assessment/EndorsementHistoryDetail'

//Link
import Links from '@/components/views/link/Links'
import AddEditLink from '@/components/views/link/AddEditLink'

//Common File
import CommonFiles from '@/components/views/commonfile/CommonFiles'
import AddEditCommonFile from '@/components/views/commonfile/AddEditCommonFile'

//SkillTests
import SkillTests from '@/components/views/skilltest/SkillTests'
import AddEditSkillTest from '@/components/views/skilltest/AddEditSkillTest'

import WelderQualificationExpiryReport from '@/components/views/report/WelderQualificationExpiryReport'


export default [
  {
    isMenu: false, name: 'Home', url: '/', component: Home, noAuthorize: true
  },
  {
    isMenu: false, name: 'Dashboard', url: '/dashboard', component: Dashboard
  },
  {
    isMenu: false, name: 'Login', url: '/login', component: Login, noAuthorize: true
  },
  {
    isMenu: false, name: 'Link', url: '/pages/link', component: Link, noAuthorize: true
  },
  {
    isMenu: false, name: 'CorporateProfile', url: '/CorporateProfile', component: CorporateProfile, noAuthorize: false
  },
  //Forgot Password
  {
    isMenu: false, name: 'ForgotPassword', url: '/forgotpassword', component: ForgotPassword, noAuthorize: true
  },
  {
    isMenu: false, name: 'ChangePassword', url: '/changepassword', component: ChangePassword, noAuthorize: true
  },
  {
    isMenu: false, name: 'RegisterIndustry', url: '/regindustry', component: RegIndustry, noAuthorize: true
  },
  {
    isMenu: false, name: 'RegisterMain', url: '/regmain', component: MainReg, noAuthorize: true
  },
  {
    isMenu: false, name: 'RegisterContractor', url: '/regContractor', component: RegContractor, noAuthorize: true
  },
  {
    isMenu: false, name: 'RegisterTestCenter', url: '/regTestCenter', component: RegTestCenter, noAuthorize: true
  },
  {
    isMenu: false, name: 'RegisterWeldingInspector', url: '/regWeldingInspector', component: RegWeldingInspector, noAuthorize: true
  },
  {
    isMenu: false, name: 'ApplicationForm', url: '/applicationform', component: ApplicationForm, noAuthorize: true, noNavi: true
  },
  {
    isMenu: false, name: 'GiroForm', url: '/giroform', component: GiroForm, noAuthorize: true, noNavi: true
  },
  { isMenu: false, name: 'ViewTestApplicationResult', url: '/viewtestapplicationresult/:workerOid/:skillOid/:testDate/:testNumber/:applicationOid/:candidateOid', component: ViewTestApplicationResult, permissionCode: perCode.viewTestApplication, noNavi: true }
  ,
  { isMenu: false, name: 'TestApplicationCandidatesByDatePrint', url: '/TestApplicationCandidatesByDatePrint/:testDate/:testCentreOid/:skillOid', component: TestApplicationCandidatesByDatePrint, permissionCode: perCode.testApplicationConfirm, noNavi: true }
  ,
  { isMenu: false, name: 'ViewTestApplicationPrint', url: '/viewTestApplicationPrint/:id?', component: ViewTestApplicationPrint, permissionCode: perCode.viewTestApplication, noNavi: true }
  ,
  {
    isMenu: false, name: 'CorpGiro', url: '/corpgiro', component: CorporateGiro, noAuthorize: true, noNavi: true
  },
  {
    isMenu: false, name: 'CompleteRegister', url: '/completeregister', component: CompleteRegister, noAuthorize: true
  },
  {
    isMenu: false, name: 'WIApplicationForm', url: '/wiapplicationform', component: WIApplicationForm, noAuthorize: true, noNavi: true
  },
  {
    isMenu: false, name: 'Pages', url: '/pages/:name?', component: Pages, noAuthorize: true
  },
  {
    isMenu: false, name: 'UserNotification', url: '/usernotification', component: UserNotification
  },
  {
    isMenu: false, name: 'UserNotificationSetting', url: '/usernotificationsetting', component: UserNotificationSetting
  },
  {
    isMenu: false, name: 'UserNotificationSettingByAdmin', url: '/usernotificationsettingbyadmin/:id?', component: UserNotificationSettingByAdmin
  },
  {
    isMenu: false, name: 'NotificationTemplates', url: '/notificationtemplates', component: NotificationTemplates
  },
  {
    isMenu: false, name: 'EditNotificationTemplate', url: '/editnotificationtemplate/:id?', component: EditNotificationTemplate
  },
  {
    id: "mainmenu_ac",
    mainObject: { isMenu: true, name: 'Account', displayName: 'Account', icon: 'bi-person' },
    childObject: [
      { isMenu: true, name: 'Profile', displayName: 'Profile', url: '/profile', component: Profile },
      { isMenu: true, name: 'Password', displayName: 'Password', url: '/password', component: Password }
    ]
  },
  {
    id: "mainmenu_at",
    mainObject: { isMenu: true, name: 'Activities', displayName: 'Activities', icon: 'bi-file' },
    childObject: [
      { isMenu: true, name: 'ActivityLog', displayName: 'Activity Log', url: '/activitylog', component: ActivityLog }
    ]
  },  
  // {
  //   id: "mainmenu_ts",
  //   mainObject: { isMenu: true, name: 'TenantSettings', displayName: 'Tenant Settings', icon: 'bi-gear-wide' },
  //   childObject: [
  //     { isMenu: true, name: 'ManageTenantSettings', displayName: 'Manage Tenant Settings', url: '/tenantsettings', component: TenantSettings },
  //     { isMenu: false, name: 'AddEditTenantSetting', url: '/addedittenantsetting/:id?', component: AddEditTenantSetting }
  //   ]
  // },
  // {
  //   id: "mainmenu_tn",
  //   mainObject: { isMenu: true, name: 'Tenants', displayName: 'Tenants', icon: 'bi-building' },
  //   childObject: [
  //     { isMenu: true, name: 'ManageTenants', displayName: 'Manage Tenants', url: '/tenants', component: Tenants },
  //     { isMenu: false, name: 'AddEditTenant', url: '/addedittenant/:id?', component: AddEditTenant }
  //   ]
  // },
  // {
  //   id: "mainmenu_tl",
  //   mainObject: { isMenu: true, name: 'TenantLocations', displayName: 'Tenant Locations', icon: 'bi-pin-map' },
  //   childObject: [
  //     { isMenu: true, name: 'ManageTenantLocations', displayName: 'Manage Tenant Locations', url: '/tenantlocations', component: TenantLocations },
  //     { isMenu: false, name: 'AddEditTenantLocation', url: '/addedittenantlocation/:id?', component: AddEditTenantLocation }
  //   ]
  // }, 
  {
    id: "mainmenu_co",
    mainObject: { isMenu: true, name: 'Corporate', displayName: 'Active Accounts', icon: 'bi-bank' },
    childObject: [
      { isMenu: true, name: 'Industries', displayName: 'Industries', url: '/industries', component: Industries, permissionCode: perCode.viewCorporate },
      { isMenu: true, name: 'Contractors', displayName: 'Contractors', url: '/contractors', component: Contractors, permissionCode: perCode.viewCorporate },
      { isMenu: true, name: 'WeldingInspectors', displayName: 'Welding Inspectors', url: '/weldinginspectors', component: WeldingInspectors, permissionCode: perCode.viewCorporate },
      { isMenu: true, name: 'TestCentreCWQS', displayName: 'Test Centre CWQS', url: '/testcentrecwqs', component: TestCentreCwqs, permissionCode: perCode.viewCorporate },
      { isMenu: false, name: 'EditCorporate', url: '/editcorporate/:id?', component: EditCorporate },
      { isMenu: false, name: 'EditWeldingInspector', url: '/editweldingInspector/:id?', component: EditWeldingInspector },
      { isMenu: false, name: 'ViewCorporate', url: '/viewcorporate/:id?', component: ViewCorporate },
      { isMenu: false, name: 'ViewWeldingInspector', url: '/viewweldingInspector/:id?', component: ViewWeldingInspector },
      
    ]
  },
  {
    id: "mainmenu_quota",
    mainObject: { isMenu: true, name: 'OnlineQuota', displayName: 'Online Quota', icon: 'bi-bank' },
    childObject: [
      { isMenu: true, name: 'FormA',displayName: 'Form A', url: '/onlinequotaapp/:id?', component: OnlineQuotaApp, permissionCode: perCode.newFormA },
      { isMenu: true, name: 'FormAListing',displayName: 'Listing', url: '/quotalist', component: FormAListing, permissionCode: perCode.formAList },
      { isMenu: true, name: 'FromContractors',displayName: 'From Contractors', url: '/fromcontractors', component: FromContractors, permissionCode: perCode.fromContractor },
      { isMenu: false, name: 'ViewQuota',displayName: 'Listing', url: '/viewquota/:id?', component: ViewQuota, permissionCode: perCode.viewFormA },
    ]
  },
  {
    id: "mainmenu_act",
    mainObject: { isMenu: true, name: 'Activate Corporates', displayName: 'Unregistered Accounts', icon: 'bi-building' },
    childObject: [
      { isMenu: true, name: 'Activate Contractors', displayName: 'Contractors', url: '/activatecontractors', component: ActivateContractors, permissionCode: perCode.manageCorporate },
      { isMenu: true, name: 'Activate Industries', displayName: 'Industries', url: '/activateindustries', component: ActivateIndustries, permissionCode: perCode.manageCorporate },
      { isMenu: true, name: 'Activate Test Centers', displayName: 'Test Centers', url: '/activatetestcenters', component: ActivateTestCenters, permissionCode: perCode.manageCorporate },
      { isMenu: true, name: 'Activate Welding Inspectors', displayName: 'Welding Inspectors', url: '/activateweldinginspectors', component: ActivateWeldingInspectors, permissionCode: perCode.manageCorporate },
      { isMenu: false, name: 'Activate Welding Inspector', displayName: 'Activate Welding Inspector', url: '/activateweldinginspector/:id?', component: ActivateWeldingInspector },
      { isMenu: false, name: 'Activate Corporate', url: '/activatecorporate/:id?', component: ActivateCorporate }
    ]
  },
  {
    id: "mainmenu_worker",
    mainObject: { isMenu: true, name: 'Worker', displayName: 'Worker', icon: 'bi-person-badge' },
    childObject: [
      { isMenu: true, name: 'WorkerAdministration', displayName: 'Worker Administration', url: '/workeradministration', component: CheckWorkerToAdd, permissionCode: perCode.manageWorker },
      { isMenu: true, name: 'Workers', displayName: 'Worker List', url: '/workers', component: WorkerList, permissionCode: perCode.viewWorker },
      
      { isMenu: false, name: 'AddEditWorker', url: '/addeditworker/:id?', component: AddEditWorker },
      
      { isMenu: false, name: 'ViewWorker', url: '/viewworker/:id?', component: ViewWorker },
      
      { isMenu: true, name: 'BatchUpload', displayName: 'Batch Upload', url: '/batchuploadworker', component: BatchUploadWorker, permissionCode: perCode.manageWorker }, 
     
      { isMenu: true, name: 'WorkerTransferList', displayName: 'Transfer', url: '/trasferlist', component: WorkerTransferList, permissionCode: perCode.manageWorker },
      { isMenu: true, name: 'TerminatedWorkerList', displayName: 'Terminated Worker Pool', url: '/terminatedworkers', component: TerminatedWorkerList, permissionCode: perCode.viewWorker },
      { isMenu: true, name: 'WelderTestListForExpiryExtend', displayName: 'Welder Maintenance', url: '/weldermaintenance', component: WelderTestListForExpiryExtend, permissionCode: perCode.viewWelderTestExpiryExtend },
    ]
  },
  {
    id: "mainmenu_staff",
    mainObject: { isMenu: true, name: 'Staff', displayName: 'Staff', icon: 'bi-person-badge' },
    childObject: [
      
      { isMenu: true, name: 'StaffList', displayName: 'Staff List', url: '/staff', component: StaffList, permissionCode: perCode.viewStaff },
      { isMenu: false, name: 'AddEditStaff', url: '/addeditstaff/:id?', component: AddEditStaff },
      { isMenu: false, name: 'ViewStaff', url: '/viewstaff/:id?', component: ViewStaff },
      { isMenu: true, name: 'BatchUploadStaff', displayName: 'Batch Upload Staff', url: '/batchuploadstaff', component: BatchUploadStaff, permissionCode: perCode.manageStaff }    
      
    ]
  },
  {
    id:"mainmenu_tm",
    mainObject: {isMenu:true, name:'TestManagement', displayName:'Test Management', icon: 'bi-file-earmark-text' },
    childObject:[
      { isMenu: true, name: 'TestApplicationList', displayName: 'Test Applications', url: '/testapplicationlist', component: TestApplicationList, permissionCode: perCode.viewTestApplication },
      { isMenu: true, name: 'TestCandidateHistory', displayName: 'Test Candidates', url: '/testcandidatehistory', component: TestCandidateHistory, permissionCode: perCode.testCandidateHistory },
      { isMenu: true, name: 'PreQualificationConfirm', displayName: 'Pre-Qualification-Confirm', url: '/prequalificationconfirm', component: PreQualificationConfirm, permissionCode: perCode.preQualificationConfirm },      
      
      { isMenu: false, name: 'AddEditTestApplication', url: '/addedittestapplication/:id?', component: AddEditTestApplication, permissionCode: perCode.manageTestApplication },
      { isMenu: false, name: 'ViewTestApplication', url: '/viewtestapplication/:id?', component: ViewTestApplication, permissionCode: perCode.viewTestApplication },
      { isMenu: false, name: 'ViewTestApplicationToUpdate', url: '/viewtestapplicationtoupdate/:id?', component: ViewTestApplicationToUpdate, permissionCode: perCode.testApplicationUpdate },
      { isMenu: false, name: 'TestApplicationResultUpdate', url: '/testapplicationresultupdate/:workerOid/:skillOid/:testDate/:testNumber/:applicationOid/:candidateOid', component: TestApplicationResultUpdate, permissionCode: perCode.testApplicationUpdate },
      { isMenu: false, name: 'TestApplicationResultUpdateBatch', url: '/testapplicationresultupdatebatch/:applicationOid', component: TestApplicationResultUpdateBatch, permissionCode: perCode.testApplicationUpdate },
      

      { isMenu: true, name: 'TestApplicationConfirmList', displayName: 'Test Applications-Confirm', url: '/testapplicationconfirmlist', component: TestApplicationConfirmList, permissionCode: perCode.testApplicationConfirm },
      { isMenu: false, name: 'TestApplicationConfirm', url: '/testapplicationconfirm/:id?', component: TestApplicationConfirm, permissionCode: perCode.testApplicationConfirm },
      { isMenu: false, name: 'TestApplicationCandidatesByDate', url: '/testappcandidatesbydate/:testDate/:testCentreOid/:skillOid', component: TestApplicationCandidatesByDate, permissionCode: perCode.testApplicationConfirm },

      { isMenu: true, name: 'TestApplicationUpdateList', displayName: 'Test Result Update', url: '/testapplicationupdatelist', component: TestApplicationUpdateList, permissionCode: perCode.testApplicationUpdate },
    ]
  },
  {
    id:"mainmenu_wa",
    mainObject: {isMenu:true, name:'WorkerAssignment', displayName:'Worker Assignment', icon: 'bi-person-workspace' },
    childObject:[
      { isMenu: true, name: 'AddWorkerAssignment', displayName: 'New Assignment', url: '/addworkerassignment', component: AddWorkerAssignment, permissionCode: perCode.newAssignment },
      { isMenu: true, name: 'WorkerAssignmentList', displayName: 'Listing', url: '/workerassignments', component: WorkerAssignments, permissionCode: perCode.assignmentListing },
      { isMenu: true, name: 'WorkerAssignmentsByWI', displayName: 'Assignment From Contractor', url: '/workerassignmentsbywi', component: WorkerAssignmentsByWI, permissionCode: perCode.assignmentListingByWeldingInspector },
      { isMenu: true, name: 'WorkerAssignmentApprove', displayName: 'For Approval', url: '/workerassignmentsapprove', component: WorkerAssignmentsApprove, permissionCode: perCode.assignmentApprove },      
      { isMenu: true, name: 'IndustryWorkerList', displayName: 'Industry Worker List', url: '/industryworkerlist', component: IndustryWorkerList, permissionCode: perCode.viewIndustryWorker },
      { isMenu: true, name: 'IndustryPreference', displayName: 'Industry Preference', url: '/IndustryPreference', component: IndustryPreference, permissionCode: perCode.viewIndustryPreference }
    ]
  },
  {
    id:"mainmenu_pu",
    mainObject: {isMenu:true, name:'PerformanceUpdate', displayName:'Performance Update', icon: 'bi-person-workspace' },
    childObject:[
      { isMenu: true, name: 'RTReportList', displayName: 'RT Report', url: '/rtreportlist', component: RTReportList, permissionCode: perCode.viewRTReport },
      { isMenu: false, name: 'AddRTReport', url: '/addrtreport', component: AddRTReport, permissionCode: perCode.manageRTReport },
      { isMenu: false, name: 'EditRTReport', url: '/editrtreport/:id?', component: EditRTReport, permissionCode: perCode.manageRTReport },
      { isMenu: false, name: 'ViewRTReport', url: '/viewrtreport/:id?', component: ViewRTReport, permissionCode: perCode.viewRTReport },
      { isMenu: true, name: 'UpdateTestCentreDelegate', displayName: 'Test Centre Delegation', url: '/updatetestcentredelegation', component: UpdateTestCentreDelegate, permissionCode: perCode.viewTestCentreDelegation },
      { isMenu: true, name: 'AssessmentList', displayName: 'Worker Assessment', url: '/workerassessment', component: AssessmentList, permissionCode: perCode.viewWorkerAssessment },
      { isMenu: false, name: 'AssessmentEntry', url: '/assessmententry/:blockId/:workerOid/:rtReportOid', component: AssessmentEntry, permissionCode: perCode.manageWorkerAssessment },
      { isMenu: true, name: 'EndorsementHistory', displayName: 'Endorsement History', url: '/endorsementhistory', component: EndorsementHistory, permissionCode: perCode.endosementHistory },
      { isMenu: true, name: 'EndorsementHistoryContractor', displayName: 'Endorsement History', url: '/endorsementhistorycontractor', component: EndorsementHistoryContractor, permissionCode: perCode.endosementHistoryContractor },
      { isMenu: false, name: 'EndorsementHistoryDetail', displayName: 'Endorsement History Detail', url: '/endorsementhistory/:oid', component: EndorsementHistoryDetail, permissionCode: perCode.endosementHistory },
      { isMenu: false, name: 'CheckWelderSummary', url: '/checkweldersummary/:welderId', component: CheckWelderSummary, permissionCode: perCode.viewRTReport },
    ]
  },
  {
    id: "mainmenu_sws",
    mainObject: { isMenu: true, name: 'ManageSWS', displayName: 'SWS Mgmt', icon: 'bi-person-workspace', url: '/swslist', component: SwsList, permissionCode: perCode.viewSws },
    childObject: [
      // { isMenu: true, name: 'ManageSWS', displayName: 'Manage SWS', url: '/swslist', component: SwsList, permissionCode: perCode.viewSws },
      { isMenu: false, name: 'AddEditSWS', url: '/addeditsws/:id?', component: AddEditSws }
    ]
  },
  {
    id: "mainmenu_us",
    mainObject: { isMenu: true, name: 'ManageUsers', displayName: 'Users', icon: 'bi-people', url: '/users', component: Users, permissionCode: perCode.viewUser },
    childObject: [
      // { isMenu: false, name: 'ManageUsers', displayName: 'Manage Users', url: '/users', component: Users, permissionCode: perCode.viewUser },
      { isMenu: false, name: 'AddEditUser', url: '/addedituser/:id?', component: AddEditUser },
      { isMenu: false, name: 'ViewUser', url: '/viewuser/:id?', component: ViewUser }
    ]
  },  
  {
    id: "mainmenu_rr",
    mainObject: { isMenu: true, name: 'ManageRoles', displayName: 'Roles', icon: 'bi-person-check', url: '/roles', component: Roles, permissionCode: perCode.viewRole },
    childObject: [
      // { isMenu: true, name: 'ManageRoles', displayName: 'Manage Roles', url: '/roles', component: Roles, permissionCode: perCode.viewRole },
      { isMenu: false, name: 'AddEditRole', url: '/addeditrole/:id?', component: AddEditRole }
    ]
  },
  {
    id: "mainmenu_st",
    mainObject: { isMenu: true, name: 'ManageSettings', displayName: 'Settings', icon: 'bi-gear', url: '/mainsetting', component: SettingMain, permissionCode: perCode.viewSetting },
    childObject: [
      // { isMenu: false, name: 'MainSettings', displayName: 'Main Settings', url: '/mainsetting', component: SettingMain, permissionCode: perCode.viewSetting },
      { isMenu: false, name: 'Manage Settings', displayName: 'Manage Settings', url: '/settings/:type?', component: Settings },
      { isMenu: false, name: 'AddEditSetting', url: '/addeditsetting/:id?/:type?', component: AddEditSetting },
      { isMenu: false, name: 'SkillTests', url: '/SkillTests', component: SkillTests },
      { isMenu: false, name: 'AddEditSkillTest', url: '/addeditskilltest/:id?', component: AddEditSkillTest }
    ]
  },
  {
    id: "mainmenu_si",
    mainObject: { isMenu: true, name: 'Billing', displayName: 'Billing', icon: 'bi-building', permissionCode: perCode.viewInvoice },
    childObject: [
      { isMenu: true, name: 'ManageServiceItem', displayName: 'Manage Service Item', url: '/manageserviceitem', component: ServiceItemList, permissionCode: perCode.viewServiceItem  },
      { isMenu: false, name: 'AddEditServiceItem', url: '/addeditserviceitem/:id?', component: AddEditServiceItem, permissionCode: perCode.manageServiceItem  },
      { isMenu: true, name: 'Invoices',displayName: 'Invoices', url: '/invoices', component: Invoices, permissionCode: perCode.viewInvoice },
      { isMenu: true, name: 'InvoiceZipDownload',displayName: 'Invoices Download', url: '/invoiceszip', component: InvoiceZipDownload, permissionCode: perCode.downloadInvoice },
      { isMenu: false, name: 'EditInvoice',displayName: 'EditInvoice', url: '/invoices/:id?', component: EditInvoice, permissionCode: perCode.manageInvoice },
      { isMenu: false, name: 'InvoiceWorker',displayName: 'InvoiceWorker', url: '/invoiceworker/:id?', component: InvoiceWorker, permissionCode: perCode.viewInvoice },
      { isMenu: true, name: 'CreditNotes',displayName: 'Credit Notes', url: '/creditnotes', component: CreditNotes, permissionCode: perCode.viewInvoice },
      { isMenu: false, name: 'ViewCreditNote',displayName: 'ViewCreditNote', url: '/creditnotes/:id?', component: ViewCreditNote, permissionCode: perCode.viewInvoice },
    ]
  },
  // {
  //   id:"mainmenu_ipre",
  //   mainObject: { isMenu: true, name: 'IndustryPreference', displayName: 'Industry Preference', icon: 'bi-person-workspace', url: '/IndustryPreference', component: IndustryPreference, permissionCode: perCode.viewIndustryPreference },
  //   childObject:[
      
  //   ]
  // },
  {
    id:"mainmenu_report",
    mainObject: { isMenu: true, name: 'Report', displayName: 'Report', icon: 'bi-file-earmark-bar-graph', permissionCode: perCode.viewReport },
    childObject: [
      { isMenu: true, name: 'PortalSummary', displayName: 'Portal Summary', url: '/portalSummary', component: PortalSummary, permissionCode: perCode.portalSummary  },
      { isMenu: true, name: 'InvoiceDetailReport', displayName: 'Invoice Detail Report', url: '/invoicedetailreport', component: InvoiceDetailReport, permissionCode: perCode.invoiceDetailListing  },
      { isMenu: true, name: 'CreditNoteReport', displayName: 'Credit Note Report', url: '/creditnotereport', component: CreditNoteReport, permissionCode: perCode.creditNoteDetailListing  },
      { isMenu: true, name: 'RevenueReport', displayName: 'Revenue Report', url: '/revenuereport', component: RevenueReport, permissionCode: perCode.revenueReport  },
      { isMenu: true, name: 'AllWorkerProfile', displayName: 'Worker Listing Report', url: '/allWorkerProfile', component: WorkerProfileReport, permissionCode: perCode.workerListingReportItem  },
      { isMenu: true, name: 'Welders', displayName: 'Welders', url: '/welders', component: Welders, permissionCode: perCode.welder  },
      { isMenu: true, name: 'ActivityLogReport', displayName: 'Activity Log', url: '/ActivityLogReport', component: ActivityLogReport, permissionCode: perCode.systemActivityLog  },
      { isMenu: true, name: 'WorkerAssignmentReport', displayName: 'Worker Assignment Report', url: '/workerassignmentreport', component: WorkerAssignmentReport, permissionCode: perCode.workerAssignmentReport  },
      { isMenu: true, name: 'WorkerQualificationReport', displayName: 'Welder Qualification Report', url: '/workerqualificationreport', component: WorkerQualificationReport, permissionCode: perCode.welderQualificationReport  },
      { isMenu: true, name: 'OnlineQuotaReport', displayName: 'Online Quota Forms', url: '/onlinequotereport', component: OnlineQuotaReport, permissionCode: perCode.onlineQuotaReport  },
      { isMenu: true, name: 'TestStatisticsReport', displayName: 'Test Statistics', url: '/teststatisticsreport', component: TestStatisticsReport, permissionCode: perCode.testStatisticsReport  },
      { isMenu: true, name: 'WorkerSkillInformationReport', displayName: 'Worker Skill Information', url: '/workerskillinformationreport', component: WorkerSkillInformationReport, permissionCode: perCode.workerSkillInformationReport  },
      { isMenu: false, name: 'WorkerSkillInformationDetailsReport', url: '/workerskillinformationdetailsreport/:locationOid?/:assignDate/:skillOid/:corporateOid/', component: WorkerSkillInformationDetailsReport},
      { isMenu: true, name: 'WelderQualificationExpiryReport',displayName: 'Welder Qualification Expiry', url: '/welderQualificationExpiryReport', component: WelderQualificationExpiryReport, permissionCode: perCode.welderQualificationExpiry},
    ]
  },
  {
    id: "mainmenu_na",
    mainObject: { isMenu: true, name: 'ManageNewsAnnouncements', displayName: 'News Announcements', icon: 'bi-megaphone-fill', url: '/newsannouncements', component: NewsAnnouncements, permissionCode: perCode.viewNewsAnnouncement },
    childObject: [
      // { isMenu: true, name: 'ManageRoles', displayName: 'Manage Roles', url: '/roles', component: Roles, permissionCode: perCode.viewRole },
      { isMenu: false, name: 'AddEditNewsAnnouncement', url: '/addeditnewsannouncement/:id?', component: AddEditNewsAnnouncement }
    ]
  },
  {
    id: "mainmenu_link",
    mainObject: { isMenu: true, name: 'ManageLinks', displayName: 'Links', icon: 'bi-link', url: '/links', component: Links, permissionCode: perCode.viewLink },
    childObject: [
      { isMenu: false, name: 'AddEditLink', url: '/addeditlink/:id?', component: AddEditLink }
    ]
  },
  {
    id: "mainmenu_cf",
    mainObject: { isMenu: true, name: 'ManageCommonFiles', displayName: 'Common Files', icon: 'bi-file-earmark-text-fill', url: '/commonfiles', component: CommonFiles, permissionCode: perCode.viewCommonFile },
    childObject: [
      { isMenu: false, name: 'AddEditCommonFile', url: '/addeditcommonfile/:id?', component: AddEditCommonFile }
    ]
  },
];