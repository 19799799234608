<template>
  <select class="form-select" :class="cssClass" v-model="existingValue" @change="change" :disabled="disabled">
    <option v-for="(i, idx) in list" :key="idx" :value="i[value]">{{i[text]}}</option>
  </select>
</template>

<script>

export default {
  name: 'FormSelect',
  props: {
    existing: [Number, String, Boolean],
    list: Array,
    value: [Number, String, Boolean],
    text: [Number, String],
    cssClass: [String, Boolean],
    disabled: Boolean
  },

  data() {
    return {
      existingValue: null
    }
  },

  watch: {
    existing(newVal) {
      this.existingValue = newVal;
    }
  },

  mounted() {
    if (this.existing) {
      this.existingValue = this.existing;
    }
  },

  methods: {
    change(e) {
      this.$emit('change', e.target.value);
    }
  }
}
</script>
