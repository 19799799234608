<template>
  <div>
    <div class="my-tab">
      <div class="my-tab-item" :class="currentTab=='request' && 'active'" @click="clickTab('request')">Transfer Request in Draft</div>
      <div class="my-tab-item" :class="currentTab=='approve' && 'active'" @click="clickTab('approve')">For Your Approval</div>
      <div class="my-tab-item" :class="currentTab=='waiting' && 'active'" @click="clickTab('waiting')">WT Waiting List</div>
    </div>
    <div v-show="currentTab=='request'">
      <table class="table">
        <thead>
          <tr>
            <th>NRIC/FIN</th>
            <th>Work Permit</th>
            <th>Worker Name</th>
            <th>Approving Contractor</th>
            <th>Employment Date</th>
            <th><label class="hover"><input class="hover" type="checkbox" @change="chkChangeReqRequestAll" v-model="isReqRequestAll" /> Request</label></th>
            <th><label class="hover"><input class="hover" type="checkbox" @change="chkChangeReqRejectAll" v-model="isReqRejectAll" /> Reject</label></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,i) in requestList" :key="i">
            <td>{{l.nricFin}}</td>
            <td>{{l.workPermit}}</td>
            <td>{{l.workerName}}</td>
            <td>{{l.approvingContractor}}</td>
            <td><DatePicker :availableDates="availableDates" @updatedate="changeReqEmpDate($event,l)" :existing="l.employDateObj" /></td>
            <td><input class="hover" type="checkbox" v-model="l.isConfirm" @change="chkChangeReqRequest(l)" /></td>
            <td><input class="hover" type="checkbox" v-model="l.isReject" @change="chkChangeReqReject(l)" /></td>
          </tr>
        </tbody>
      </table>
      <button v-show="isShowReqSubmitBtn" class="btn btn-primary float-end" @click="clickRequestSubmit">Submit</button>
    </div>
    <div v-show="currentTab=='approve'">
      <table class="table">
        <thead>
          <tr>
            <th>NRIC/FIN</th>
            <th>Work Permit</th>
            <th>Worker Name</th>
            <th>Requesting Contractor</th>
            <th>Release Date</th>
            <th><label class="hover"><input class="hover" type="checkbox" @change="chkChangeAppApproveAll" v-model="isAppApproveAll" /> Approve</label></th>
            <th><label class="hover"><input class="hover" type="checkbox" @change="chkChangeAppRejectAll" v-model="isAppRejectAll" /> Reject</label></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,i) in approveList" :key="i">
            <td>{{l.nricFin}}</td>
            <td>{{l.workPermit}}</td>
            <td>{{l.workerName}}</td>
            <td>{{l.requestingContractor}}</td>
            <td><DatePicker :availableDates="availableApprovalDates" @updatedate="changeAppEmpDate($event,l)" :existing="l.employDateObj" /></td>
            <td><input class="hover" type="checkbox" v-model="l.isConfirm" @change="chkChangeAppApprove(l)" /></td>
            <td><input class="hover" type="checkbox" v-model="l.isReject" @change="chkChangeAppReject(l)" /></td>
          </tr>
        </tbody>
      </table>
      <button v-show="isShowAppSubmitBtn" class="btn btn-primary float-end" @click="clickApproveSubmit">Submit</button>
    </div>
    <div v-show="currentTab=='waiting'">
      <table class="table">
        <thead>
          <tr>
            <th>NRIC/FIN</th>
            <th>Work Permit</th>
            <th>Worker Name</th>
            <th>Approving Contractor</th>
            <th>Request Date</th>
            <th>Employment Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l,i) in waitingList" :key="i">
            <td>{{l.nricFin}}</td>
            <td>{{l.workPermit}}</td>
            <td>{{l.workerName}}</td>
            <td>{{l.approvingContractor}}</td>
            <td>{{l.requestDateDisplay}}</td>
            <td>{{l.employDateDisplay}}</td>
            <td>
              <span class="badge bg-secondary" v-if="l.status==$data._workerTransferStatus.pending.value">{{$data._workerTransferStatus.pending.text}}</span>
              <span class="badge bg-warning" v-else-if="l.status==$data._workerTransferStatus.request.value">{{$data._workerTransferStatus.request.text}}</span>
              <span class="badge bg-success" v-else-if="l.status==$data._workerTransferStatus.approved.value">{{$data._workerTransferStatus.approved.text}}</span>
              <span class="badge bg-dark" v-else-if="l.status==$data._workerTransferStatus.deleted.value">{{$data._workerTransferStatus.deleted.text}}</span>
              <span class="badge bg-dark" v-else-if="l.status==$data._workerTransferStatus.rejected.value">{{$data._workerTransferStatus.rejected.text}}</span>
              <span class="badge" v-else>{{l.status}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Pickers from '@/components/custom/pickers';


export default {
  name: 'WorkerTransferList',
  components: {
    ...Pickers,
  },
  data() {
    return {
      currentTab: 'request',

      requestList: [],
      approveList: [],
      waitingList: [],

      isReqRequestAll: false,
      isReqRejectAll: false,
      isShowReqSubmitBtn: true,

      isAppApproveAll: false,
      isAppRejectAll: false,
      isShowAppSubmitBtn: true,

      availableDates: {},
      availableApprovalDates:{},
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + window.appSetting.workerTransferMinDays);
    let maxDate = new Date(minDate);
    maxDate.setDate(maxDate.getDate() + window.appSetting.workerTransferMaxDays);
    this.availableDates = { start: minDate, end: maxDate };
    let minApprovalDate = new Date();
    minApprovalDate.setDate(minApprovalDate.getDate() + 1)
    let maxApprovalDate = new Date(minApprovalDate);
    maxApprovalDate.setDate(maxApprovalDate.getDate() + 21);
    console.log(minApprovalDate);
    console.log(maxApprovalDate);
    this.availableApprovalDates = { start: minApprovalDate, end: maxApprovalDate };
  },
  mounted() {
    this.setBreadcrumb([
      { name: 'Worker Transfer' }
    ]);
    this.clickTab(this.currentTab);
  },
  methods: {
    clickTab(tab) {
      this.currentTab = tab;
      if (this.currentTab == 'request') {
        this.loadRequestList();
      }
      else if (this.currentTab == 'approve') {
        this.loadApproveList();
      }
      else if (this.currentTab == "waiting") {
        this.loadWaitingList();
      }
    },
    loadRequestList() {
      this.requestList = [];
      this.showLoadingScreen();
      api.regularGet(apiUrl.worker.getTransferRequests, null).then((res) => {
        this.hideLoadingScreen();
        for (let i = 0; i < res.data.length; i++) {
          let d = {
            ...res.data[i],
            isConfirm: false,
            isReject: false
          };
          if (d.employDate) {
            d.employDateObj = this.convertDateStrToDateObj(d.employDate);
          }
          this.requestList.push(d)
        }
      });
    },
    loadApproveList() {
      this.approveList = [];
      this.showLoadingScreen();
      api.regularGet(apiUrl.worker.getTransferToApproves, null).then((res) => {
        this.hideLoadingScreen();
        for (let i = 0; i < res.data.length; i++) {
          let d = {
            ...res.data[i],
            isConfirm: false,
            isReject: false
          };
          if (d.employDate) {
            d.employDateObj = this.convertDateStrToDateObj(d.employDate);
          }
          this.approveList.push(d)
        }
      });
    },
    loadWaitingList() {
      this.waitingList = [];
      this.showLoadingScreen();
      api.regularGet(apiUrl.worker.getTransferWaitings, null).then((res) => {
        this.hideLoadingScreen();
        this.waitingList = res.data;
      });
    },


    //#region Request
    chkChangeReqRequest(d) {
      if (d.isConfirm)
        d.isReject = false;
      this.isReqRequestAll = false;
    },
    chkChangeReqReject(d) {
      if (d.isReject)
        d.isConfirm = false;
      this.isReqRejectAll = false;
    },
    chkChangeReqRequestAll(e) {
      for (let i = 0; i < this.requestList.length; i++) {
        this.requestList[i].isConfirm = e.target.checked;
        if (e.target.checked) {
          this.requestList[i].isReject = false;
          this.isReqRejectAll = false;
        }
      }
    },
    chkChangeReqRejectAll(e) {
      for (let i = 0; i < this.requestList.length; i++) {
        this.requestList[i].isReject = e.target.checked;
        if (e.target.checked) {
          this.requestList[i].isConfirm = false;
          this.isReqRequestAll = false;
        }
      }
    },
    changeReqEmpDate(date, d) {
      d.employDate = this.convertDateObjToDateStr(date);
    },
    clickRequestSubmit() {
      let data = [];
      for (let i = 0; i < this.requestList.length; i++) {
        let d = this.requestList[i]
        if (d.isConfirm || d.isReject) {
          data.push({
            transferRequestOid: d.oid,
            isConfirm: d.isConfirm,
            employDate: d.employDate,
          });
        }
      }

      this.showLoadingScreen();
      api.regularPost(apiUrl.worker.confirmWorkerTransferRequest, data).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully Submitted.", "success");
          this.loadRequestList();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.doctors.unable_delete, "error");
        }
      });
    },
    //#endregion 


    //#region Approve
    chkChangeAppApprove(d) {
      if (d.isConfirm)
        d.isReject = false;
      this.isAppApproveAll = false;
    },
    chkChangeAppReject(d) {
      if (d.isReject)
        d.isConfirm = false;
      this.isAppRejectAll = false;
    },
    chkChangeAppApproveAll(e) {
      for (let i = 0; i < this.approveList.length; i++) {
        this.approveList[i].isConfirm = e.target.checked;
        if (e.target.checked) {
          this.approveList[i].isReject = false;
          this.isAppRejectAll = false;
        }
      }
    },
    chkChangeAppRejectAll(e) {
      for (let i = 0; i < this.approveList.length; i++) {
        this.approveList[i].isReject = e.target.checked;
        if (e.target.checked) {
          this.approveList[i].isConfirm = false;
          this.isAppApproveAll = false;
        }
      }
    },
    changeAppEmpDate(date, d) {
      d.employDate = this.convertDateObjToDateStr(date);
    },
    clickApproveSubmit() {
      let data = [];
      for (let i = 0; i < this.approveList.length; i++) {
        let d = this.approveList[i]
        if (d.isConfirm || d.isReject) {
          data.push({
            TransferRequestOid: d.oid,
            IsConfirm: d.isConfirm,
            employDate: d.employDate,
          });
        }
      }

      this.showLoadingScreen();
      api.regularPost(apiUrl.worker.confirmWorkerTransferApprove, data).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully Submitted.", "success");
          this.loadApproveList();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.doctors.unable_delete, "error");
        }
      });
    },
    //#endregion


  }
}
</script>
