<template>
  <div>
    <div class="mb-2">
      <button class="btn btn-primary float-end" @click="clickAddNewPreQuali">Add Pre-Qualification</button>
    </div>
    <!-- <table class="table mb-4">
      <thead>
        <tr>
          <th>Skill</th>
          <th>Cert No</th>
          <th>Cert Issue Date</th>
          <th>Test No.</th>
          <th>Test Centre</th>
          <th>Test Date</th>
          <th>Perf Date</th>
          <th>Status</th>
          <th>File</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l,i) in skillTestList" :key="i">
          <td>{{l.skill}}</td>
          <td>{{l.certNum}}</td>
          <td>{{l.issueDateDisplay}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testCentre}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{l.perfDateDisplay}}</td>
          <td>
            <span v-if="l.status==$data._workerSkillTestStatus.active.value" class="badge bg-success">{{$data._workerSkillTestStatus.active.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.rejected.value" class="badge bg-danger">{{$data._workerSkillTestStatus.rejected.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.pending.value" class="badge bg-secondary">{{$data._workerSkillTestStatus.pending.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.suspended.value" class="badge bg-warning">{{$data._workerSkillTestStatus.suspended.text}}</span>
          </td>
          <td>
            <button class="btn btn-sm btn-secondary" title="download" v-if="l.fileName" @click="clickDownloadFile(l.oid,l.fileName)">
              <i class="bi bi-cloud-download"></i>
            </button>
          </td>
          <td><button v-if="l.status == $data._workerSkillTestStatus.pending.value" class="btn btn-primary btn-sm" @click="clickEditPreQuali(l.oid)">Edit</button></td>
        </tr>
      </tbody>
    </table> -->

    <table class="table mb-4">
      <thead>
        <tr>
          <th>Skill</th>
          <th>Test No</th>
          <th>Test Position</th>
          <th>Description</th>
          <th>Test Date</th>
          <th>Result</th>
          <th>Expiry Date</th>
          <th>Last Perf Update</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l,i) in skillTestList" :key="i">
          <td>{{l.skill}}</td>
          <td>{{l.testNumber}}</td>
          <td>{{l.testPosition}}</td>
          <td>{{l.weldingProcess}}</td>
          <td>{{l.testDateDisplay}}</td>
          <td>{{getWorkerSkillTestResultTextByValue(l.result)}}</td>
          <td>{{l.expiryDateDisplay}}</td>
          <td>{{l.lastPerfDateDisplay}}</td>
          <td>
            <span v-if="l.status==$data._workerSkillTestStatus.active.value" class="badge bg-success">{{$data._workerSkillTestStatus.active.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.rejected.value" class="badge bg-danger">{{$data._workerSkillTestStatus.rejected.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.pending.value" class="badge bg-secondary">{{$data._workerSkillTestStatus.pending.text}}</span>
            <span v-if="l.status==$data._workerSkillTestStatus.suspended.value" class="badge bg-warning">{{$data._workerSkillTestStatus.suspended.text}}</span>
          </td>
          <!-- <td>
            <button class="btn btn-sm btn-secondary" title="download" v-if="l.fileName" @click="clickDownloadFile(l.oid,l.fileName)">
              <i class="bi bi-cloud-download"></i>
            </button>
          </td> -->
          <td><button v-if="l.status == $data._workerSkillTestStatus.pending.value" class="btn btn-primary btn-sm" @click="clickEditPreQuali(l.oid)">Edit</button></td>
        </tr>
      </tbody>
    </table>
    
    <ModalDiaglog 
    :show="showQualiForm" 
    size="xl"
    :onClose="()=>{showQualiForm = false;}"
    :title="isNew?'New Pre-Qualification':'Edit Pre-Qualification'"
    :onSubmit1="clickSave"
    submitBtn1Text="Submit"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="row mb-2">
            <div class="col-md-6 text-secondary">
              Skill<span class="text-danger">*</span>
            </div>
            <div class="col-md-6">
              <FormSelect :cssClass="error.skillOid && 'control-error'" :list="skills" value="oid" text="value"
                :existing="dataObj.skillOid" @change="changeSkill" />
            </div>
          </div>
          <div v-if="isWelding" class="row mb-2">
            <div class="col-md-6 text-secondary">
              Test Centre
            </div>
            <div class="col-md-6">
              <FormSelect :cssClass="error.testCentreOid && 'control-error'" :list="testCentres" value="oid" text="corpName"
                    :existing="dataObj.testCentreOid" @change="changeTestCentre" />
            </div>
          </div>
          <div v-else class="row mb-2">
            <div class="col-md-6 text-secondary">
              Test Centre
            </div>
            <div class="col-md-6 fw-bold">
              PRiMOS (on-behalf ASPRI)
            </div>
          </div>
          <div v-if="showOtherTestCentre" class="row mb-2">
            <div class="col-md-6 text-secondary">
              Other Test Centre<span class="text-danger">*</span>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" :class="error.otherTestCentre && 'control-error'"
                  v-model="dataObj.otherTestCentre" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6 text-secondary">
              Cert No.<span class="text-danger">*</span>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control" :class="error.certNum && 'control-error'"
                  v-model="dataObj.certNum" />
            </div>
          </div>
          <div v-if="isWelding" class="row mb-2">
            <div class="col-md-6 text-secondary">
              Last Perf Upd
            </div>
            <div class="col-md-6">
              <DatePicker @updatedate="changePerfDate" :cssClass="error.perfDate && 'control-error'" :existing="perfDate" />
            </div>
          </div>
          <div class="row mb-2" v-if="dataObj.fileName && !showFileUploder">
            <div class="col-md-6 text-secondary">
              File
            </div>
            <div class="col-md-6">
              <b>{{dataObj.fileName}}</b>
                <button class="btn btn-sm btn-danger float-end" @click="clickRemoveFile" title="Remove and Upload New"><i class="bi bi-trash"></i></button>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mb-2">
            <div class="col-md-6 text-secondary">
              Test No.<span class="text-danger">*</span>
            </div>
            <div class="col-md-6">
              <FormSelect :cssClass="error.testNumber && 'control-error'" :list="testNumbers" value="value" text="value"
                  :existing="dataObj.testNumber" @change="dataObj.testNumber=$event" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6 text-secondary">
              Test Date<span class="text-danger">*</span>
            </div>
            <div class="col-md-6">
              <DatePicker :availableDates="availableDates" @updatedate="changeTestDate" :cssClass="error.testDate && 'control-error'" :existing="testDate" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6 text-secondary">
              Cert Issue Date<span class="text-danger">*</span>
            </div>
            <div class="col-md-6">
              <DatePicker :availableDates="availableDates" @updatedate="changeCertIssueDate" :cssClass="error.issueDate && 'control-error'" :existing="certIssudDate" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isNew || showFileUploder" class="mt-2 mb-3">
        <div class="text-secondary">
          Attachment File
        </div>
        <div>
          <file-input :status="filestatus" @selectFile="selectFile" @done="filestatus='stop'" accept=".docx,.xlsx,.pdf" />
        </div>
      </div>

      <div class="mt-2">
        <div class="text-danger small">* maximum 5 MB file size</div> 
        <div class="text-danger small">* Denotes Mandatory Field</div>
        <div class="text-danger small">
          - If a Pre-Qualification with the same Test Number already exist, it will be overwritten by the new Pre-Qualification submitted, regardless of date.
          <br/>
          - If a qualification is updated by Test Centre through the PRiMOS Test Application Result Update, it will not be overwritten by any Pre-Qualification submmited even though with the same Test Number.
        </div>
      </div>
    </ModalDiaglog>
  </div>
</template>

<script>
import ModalDiaglog from "@/components/custom/ModalDiaglog";
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import * as Forms from '@/components/custom/forms';
import * as Pickers from '@/components/custom/pickers';

export default {
  name: 'AddEditTestResult',
  props: {
    workerOid: Number
  },
  components: {
    ...Forms,
    ...Pickers,
    ModalDiaglog
  },
  data() {
    return {
      isNew: false,
      skillTestList: [],
      dataObj: {},
      file: null,
      filestatus: 'stop',

      skills: [],
      testNumbers: [],
      testCentres: [],

      testDate: null,
      certIssudDate: null,
      perfDate: null,
      isWelding: false,
      weldingOid: 0,
      error: {},
      showOtherTestCentre: false,
      showQualiForm: false,
      showFileUploder: false,

      availableDates: {}
    }
  },
  beforeMount() {
    this.availableDates = { end: new Date() };
  },
  mounted() {
    if (this.workerOid && this.workerOid > 0) {
      this.getWorkerSkillTestList();

      api.getAllCorporates(null).then(response => {
        this.testCentres = response.data.filter(x => x.status == this.$data._corporateStatus.active.value && x.type == this.$data._corporateType.testCenter.value);
        this.testCentres.push({ oid: 0, corpName: 'Other' });
        this.testCentres.unshift({ oid: -1, corpName: '<None>' });
        this.dataObj.testCentreOid = -1;
      });

      api.regularGet(apiUrl.workerSkill.getWorkerSkillSetsByWorkerOid + '?workerOid=' + this.workerOid, null).then((response) => {
        this.skills = response.data;
        if (this.skills.length > 0)
          this.dataObj.skillOid = this.skills[0].oid;

        this.weldingOid = this.skills.filter(i => i.value == "Welding")[0].oid;
        if (this.skills[0].oid == this.weldingOid) {
          this.isWelding = true;
        }
        this.bindTestNumbers();
      });

      this.dataObj.otherTestCentre = '';

    }

  },

  methods: {

    getWorkerSkillTestList() {
      this.showLoadingScreen();
      this.skillTestList = [];
      api.regularGet(apiUrl.workerSkill.getWorkerSkillTests + '/' + this.workerOid, null).then((response) => {
        this.hideLoadingScreen();
        this.skillTestList = response.data;
      });
    },

    clickSave() {

      if (!this.validate())
        return;


      this.dataObj.workerOid = this.workerOid;
      let data = { ...this.dataObj };

      let formdata = new FormData();
      if (this.file == null && !this.dataObj.fileName) {
        this.showMessage("Upload attachment file", "error");
        return;
      }

      if (this.file != null) {
        formdata.append("File", this.file);
      }
      formdata.append("testCentreOid", data.testCentreOid);
      formdata.append("workerOid", data.workerOid);
      formdata.append("workerOid", data.workerOid);
      formdata.append("skillOid", data.skillOid);
      formdata.append("otherTestCentre", data.otherTestCentre);
      formdata.append("certNum", data.certNum);
      formdata.append("testNumber", data.testNumber);
      formdata.append("testDate", data.testDate);
      formdata.append("issueDate", data.issueDate);
      formdata.append("isPrequalification", true);
      if (data.perfDate)
        formdata.append("perfDate", data.perfDate);

      let url = apiUrl.workerSkill.insertWorkerSkillTest;
      if (!this.isNew) {
        formdata.append("oid", data.oid);
        formdata.append("filePath", data.filePath);
        url = apiUrl.workerSkill.updateWorkerSkillTest
      }

      this.showLoadingScreen();
      api.regularPost(url, formdata, "multipart/form-data").then(response => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          if (this.isNew) {
            this.showMessage("Pre-Qualification entry has been submitted successfully to the relevant party for approval.", "success");
          }
          else {
            this.showMessage("Successfully updated.", "success");
          }
          this.getWorkerSkillTestList();
          this.clearForm();
          this.showQualiForm = false;
          this.filestatus = "reset";
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);
      if (!this.dataObj.skillOid) { this.error.skillOid = true; isValid = false; }

      //let skillName = this.skills.find(x => x.oid == this.dataObj.skillOid).value;
      // if (skillName != 'Welding') {
      //   if (!this.dataObj.testCentreOid || this.dataObj.testCentreOid < 0) { this.error.testCentreOid = true; isValid = false; }
      // }
      if (this.showOtherTestCentre && !this.dataObj.otherTestCentre) { this.error.otherTestCentre = true; isValid = false; }
      if (!this.dataObj.testNumber) { this.error.testNumber = true; isValid = false; }
      if (!this.dataObj.testDate) { this.error.testDate = true; isValid = false; }
      if (!this.dataObj.certNum) { this.error.certNum = true; isValid = false; }
      if (!this.dataObj.issueDate) { this.error.issueDate = true; isValid = false; }

      this.$forceUpdate();
      return isValid;
    },
    bindTestNumbers() {
      this.dataObj.testNumber = '';
      var s = this.skills.find(x => x.oid == this.dataObj.skillOid)
      if (s) {
        this.testNumbers = s.skillTests.map(x => { return { value: x.testNumber } });
      }
    },
    changeSkill(e) {
      this.dataObj.skillOid = e;
      if (e == this.weldingOid) {
        this.isWelding = true;
      }
      else {
        this.isWelding = false;
        this.dataObj.testCentreOid = -1;
        this.changeTestCentre(-1);
      }
      this.bindTestNumbers();
    },
    changeTestDate(date) {
      this.dataObj.testDate = this.convertDateObjToDateStr(date);
    },
    changeCertIssueDate(date) {
      this.dataObj.issueDate = this.convertDateObjToDateStr(date);
    },
    changePerfDate(date) {
      this.dataObj.perfDate = this.convertDateObjToDateStr(date);
    },
    clearForm() {
      this.dataObj = {};
      this.testDate = null;
      this.certIssudDate = null;
      this.perfDate = null;
    },
    changeTestCentre(oid) {
      this.dataObj.testCentreOid = oid;
      if (oid == 0) {
        this.showOtherTestCentre = true;
      }
      else {
        this.dataObj.otherTestCentre = "";
        this.showOtherTestCentre = false;
      }
    },
    clickAddNewPreQuali() {
      this.showQualiForm = true;
      this.isNew = true;
      this.dataObj = {};
    },
    clickEditPreQuali(oid) {
      api.regularGet(apiUrl.workerSkill.getWorkerSkillTestByOid + '?oid=' + oid).then(response => {
        this.dataObj = { ...response.data };
        console.log("A", this.dataObj.testCentreOid);
        this.changeSkill(this.dataObj.skillOid);
        console.log("B", this.dataObj.testCentreOid);
        this.dataObj.testNumber = response.data.testNumber;
        if (this.dataObj.testCentreOid == null) {
          if (this.dataObj.otherTestCentre) {
            this.dataObj.testCentreOid = "0"; //Other
          }
          else {
            this.dataObj.testCentreOid = "-1"; //None
          }
        }
        console.log("C", this.dataObj.testCentreOid);
        this.changeTestCentre(this.dataObj.testCentreOid);
        console.log("D", this.dataObj.testCentreOid);
        if (this.dataObj.testDate)
          this.testDate = this.convertDateStrToDateObj(this.dataObj.testDate);
        if (this.dataObj.issueDate)
          this.certIssudDate = this.convertDateStrToDateObj(this.dataObj.issueDate);
        if (this.dataObj.perfDate)
          this.perfDate = this.convertDateStrToDateObj(this.dataObj.perfDate);
        console.log("E", this.dataObj.testCentreOid);
        this.$forceUpdate();
      });
      this.showQualiForm = true;
      this.isNew = false;
      if (this.dataObj.fileName)
        this.showFileUploder = false;
    },
    clickDownloadFile(oid, fileName) {
      api.downloadFile(apiUrl.workerSkill.downloadWorkerSkillTestFile, { oid }, fileName, () => {
        this.showMessage('Downloaded successfully.', 'success');
      });
    },
    clickRemoveFile() {
      this.showFileUploder = true;
      this.dataObj.isRemoveFile = true;
    },
    selectFile(file) {
      this.file = file;
      this.dataObj.isRemoveFile = false;
    }
  }
}
</script>
