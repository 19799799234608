<template>
  <div>
    <div v-if="!isNew" class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Code:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.code && 'control-error'" v-model="dataObj.code" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Name:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.name && 'control-error'" v-model="dataObj.name" />
              </div>
            </div>
            <div v-show="IsNameAndValueDifferent" class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Value:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.value && 'control-error'" v-model="dataObj.value" />
              </div>
            </div>
             <div v-show="IsParentShowed" class="row mb-3">
              <div class="col-sm-6 text-secondary">
                Parent:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="parents" :cssClass="error.parentSettingOid && 'control-error'" value="oid" text="name" :existing="dataObj.parentSettingOid" @change="dataObj.parentSettingOid=$event"/>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-sm-6">
                Group:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.group && 'control-error'" v-model="dataObj.group" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Parent:
              </div>
              <div class="col-sm-6">
                <FormSelect :list="parents" :cssClass="error.parentSettingOid && 'control-error'" value="oid" text="name" :existing="dataObj.parentSettingOid" @change="dataObj.parentSettingOid=$event"/>
              </div>
            </div>
          </div> -->
        </div>
        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button>
      </div>
    </div>

    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Setting" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      

  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
export default {
  name: 'AddEditTenant',

  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms
  },

  props: {

  },

  data() {
    return {
      groupType:'',
      basicLogInfoUrl: '',
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      parents: [],
      showAuditModal: false, 
      IsNameAndValueDifferent:true,
      error: {},
      IsParentShowed:false
    };
  },
  beforeMount() {
    let passedGroupType = this.$route.params.type;
    this.groupType = passedGroupType;
    console.log(this.groupType);
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  mounted() {
    let pageName = this.isNew ? 'New '+this.groupType : 'Edit '+this.groupType;
    this.setBreadcrumb([
      { name: 'Settings', url: '/mainsetting'}, { name: pageName }
    ]);

    if (this.isNew) {
      this.dataObj.isActive = true;
      this.getParents();
    }
    if (this.oid > 0) {
      this.getData();
      this.getParents();
    }
  },

  methods: {
    getParents() {
      if(this.groupType == this.$data._settingGroup.branchCode){
        this.IsParentShowed = true;
        let url = apiUrl.settings.getAll +"/" + this.$data._settingGroup.bank
        api.regularGet(url,null ).then((response) => {
          this.parents = response.data;
        });
      }
    },
    getData() {
      let url = apiUrl.settings.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
        this.getParents();
      });
    },
    redirectToParent(){
      if(this.dataObj.group == this.$data._settingGroup.bank){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.bank } });
          }else if(this.dataObj.group == this.$data._settingGroup.ownership){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.ownership } });
          }else if(this.dataObj.group == this.$data._settingGroup.paymentMethod){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.paymentMethod } });
          }else if(this.dataObj.group == this.$data._settingGroup.country){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.country } });
          }else if(this.dataObj.group == this.$data._settingGroup.branchCode){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.branchCode } });
          }else if(this.dataObj.group == this.$data._settingGroup.sac){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.sac } });
          }else if(this.dataObj.group == this.$data._settingGroup.qualification){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.qualification } });
          }else if(this.dataObj.group == this.$data._settingGroup.emailSetting){
            this.$router.push({ name: "ManageSettings", params: { type: this.$data._settingGroup.emailSetting } });
          }
    },
    clickSave() {
      if (!this.validate())
        return;

      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.settings.insert;
      }
      else {
        subUrl = apiUrl.settings.update;
      }
      if(!this.IsNameAndValueDifferent){
        this.dataObj.value = this.dataObj.name;
      }
      this.dataObj.group = this.groupType;
      api.regularPost(subUrl, this.dataObj, this.$store.state.token).then((response) => {
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.redirectToParent();
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.redirectToParent();
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.code) { this.error.code = true; isValid = false; }

      this.$forceUpdate();
      return isValid;
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.name + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.settings.delete, this.dataObj, this.$store.state.token).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.redirectToParent();
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.doctors.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    }
  }
}
</script>
