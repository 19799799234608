<template>
  <div>
    <div v-if="!isNew" class="text-end">
      <button class="btn btn-light mb-2" @click="()=>{showAuditModal=true;}">Audit</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Title:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.title && 'control-error'" v-model="dataObj.title" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Website Url:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.url && 'control-error'" v-model="dataObj.url" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">
                Image Url:
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" :class="error.url && 'control-error'" v-model="dataObj.imageUrl" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3 text-secondary">Active:</div>
              <div class="col-sm-3">
                <input type="checkbox" v-model="dataObj.status" checked/>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-secondary m-1 float-end" @click="clickCancel">Cancel</button>
        <button class="btn btn-success m-1 float-end" @click="clickSave">Save</button>
        <button v-if="!isNew" class="btn btn-danger m-1" @click="confirmRemove">Delete</button>
      </div>
    </div>
    <ModalDiaglog size="xl" :show="showAuditModal" :onClose="()=>{showAuditModal=false}" :hasHeader="false" :showSubmitBtn1="false">
      <div v-if="showAuditModal">
        <AuditLog 
        :timezone="timezone" 
        tablename="Link" 
        :primarykey="oid" 
        :categories="[]"
        :defaultCategory="'All'" 
        :basicLogInfoOid="oid" 
        :basicLogInfoUrl="basicLogInfoUrl" 
        />
      </div>
    </ModalDiaglog>      
  </div>
</template>

<script>
import apiUrl from '@/common/apiurl';
import api from '@/common/api';
import _ from 'lodash';
import AuditLog from '@/components/custom/AuditLog';
import ModalDiaglog from '@/components/custom/ModalDiaglog';
import * as Forms from '@/components/custom/forms';
export default {
  name: 'AddEditLink',
  components: {
    AuditLog,
    ModalDiaglog,
    ...Forms
  },

  props: {

  },

  data() {
    return {
      basicLogInfoUrl: apiUrl.roles.getBasicLogInfo,
      oid: 0,
      isNew: true,
      confirmObj: {},
      dataObj: {},
      timezone: api.TIMEZONE,
      alertCounter: 0,
      alertMessage: "",
      permissions: [],
      groupPermissions: {},
      showAuditModal: false,
      corporateTypes: [],

      error: {}
    };
  },
  destroyed() {
    this.clearBreadcrumb();
  },
  beforeMount() {
    let passedId = this.$route.params.id;
    if (passedId && passedId > 0) {
      this.oid = parseInt(passedId);
      this.isNew = false;
    }
  },
  mounted() {

    let pageName = this.isNew ? 'New Link' : 'Edit Link';
    this.setBreadcrumb([
      { name: 'Links', url: '/links' }, { name: pageName }
    ]);

    // if (this.isNew) {
    //   this.dataObj.isActive = true;
    // }
    if (this.oid > 0) {
      this.getData();
    } 

  },

  methods: {
    getData() {
      let url = apiUrl.links.getByOid + '/' + this.oid
      api.regularGet(url, null).then((response) => {
        this.dataObj = response.data;
      });
    },
    
    getPermissions() {
      api.getAllPermissions(null, this.$store.state.token).then((response) => {
        if (response.status == 200) {
          this.permissions = response.data;
          this.permissions = this.permissions.sort(x => x.headerGroup);

          for (let i = 0; i < this.permissions.length; i++) {

            //render checked
            if (!this.isNew && this.dataObj.permissions) {
              let per = this.dataObj.permissions.find(x => x.oid == this.permissions[i].oid)
              if (per)
                this.permissions[i].checked = true;
            }
          }
          let headerList = _.uniqBy(this.permissions, "headerGroup").map(x => x.headerGroup);
          this.groupPermissions = [];
          for (let h = 0; h < headerList.length; h++) {
            let obj = {
              header: headerList[h],
              permissions: this.permissions.filter(x => x.headerGroup == headerList[h])
            };
            this.groupPermissions.push(obj);
          }
        }
      });
    },
    clickSave() {
      if (!this.validate())
        return;
      let subUrl = '';
      if (this.isNew) {
        subUrl = apiUrl.links.insert;
      }
      else {
        subUrl = apiUrl.links.update;
      }

      this.showLoadingScreen();
      console.log(subUrl);
      api.regularPost(subUrl, this.dataObj, this.$store.state.token).then((response) => {
        this.hideLoadingScreen();
        if (response.status == 200) {
          this.showMessage("Successfully saved.", "success");
          this.$router.push({ name: "ManageLinks" });
        }
        else if (response.status == 400 || response.errorText.response.status == 400) {
          this.showMessage(response.errorText.response.data.message, "error");
        }
        else {
          this.showMessage(window.message.unable_proceed, "error");
        }
      });

    },
    clickCancel() {
      this.$router.push({ name: "ManageLinks" });
    },
    validate() {
      let isValid = true;
      Object.keys(this.error).forEach(k => this.error[k] = false);

      if (!this.dataObj.title) { this.error.title = true; isValid = false; }

      this.$forceUpdate();
      return isValid;
    },
    showErrorMessage(msg) {
      this.alertCounter = 5;
      this.alertMessage = msg;
    },
    confirmRemove() {
      this.showConfirmDiaglog(
        {
          show: true,
          message: 'Are you sure to remove ' + this.dataObj.title + '?',
          submitFunction: () => {
            api.regularPost(apiUrl.links.delete, this.dataObj, this.$store.state.token).then((response) => {
              if (response.status == 200) {
                this.showMessage("Successfully Deleted.", "success");
                this.$router.push({ name: "ManageLinks" });
              }
              else if (response.status == 400 || response.errorText.response.status == 400) {
                this.showMessage(response.errorText.response.data.message, "error");
              }
              else {
                this.showMessage(window.message.link.unable_delete, "error");
              }
            });
          }
        });
    },
    showAudit() {
      this.showAuditDialog(true);
    }
  }
}
</script>
